import { ReactNode } from 'react';
import tw from 'twin.macro';

import { CopyToClipboard } from './CopyToClipboard';

interface Props {
  copy?: string;
  inline?: boolean;
  label: string;
  children: ReactNode;
}

export const Instruction = ({ copy, inline, ...props }: Props) => {
  return (
    <div css={[tw`flex flex-col`, inline && tw`md:flex-row`]}>
      <h3 css={[tw`text-gray-400`, inline && tw`md:w-2/5`]}>{props.label}</h3>
      <div tw="flex mt-1 md:mt-0">
        <p tw="font-medium">{props.children}</p>
        {copy && <CopyToClipboard value={copy} gray />}
      </div>
    </div>
  );
};
