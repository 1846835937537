import { useLocation, useSearchParams } from 'react-router-dom';

import { apiRoot } from '~/api';
import { trackNewSignUp } from '~/utils/tracking-analytics';

import { appleLogin, getUserInfo, googleLogin } from './api';
import { QueryParam, StorageKey } from './types';

type LoginMethodProps = {
  nextUrl?: string;
};

export const useNext = ({ nextUrl }: LoginMethodProps = {}): string => {
  const [query] = useSearchParams();
  const { hash } = useLocation();

  const next = nextUrl ?? (query.get(QueryParam.NEXT) || '/');

  return `${next}${hash}`;
};

export const useGoogleLogin = (props: LoginMethodProps = {}) => {
  const next = useNext(props);
  const [query] = useSearchParams();

  return async () => {
    const { accessToken } = await googleLogin();
    const userInfo = await getUserInfo(accessToken as string);

    // eslint-disable-next-line
    // @ts-ignore
    if (userInfo['http://grunfin.com/claims/login_count'] === 1) {
      trackNewSignUp();
    }

    localStorage.setItem(StorageKey.NEXT, next);
    query.set(QueryParam.NEXT, next);
    window.location.href = `${apiRoot}/login`;
  };
};

export const useAppleLogin = (props: LoginMethodProps = {}) => {
  const next = useNext(props);
  const [query] = useSearchParams();

  return async () => {
    const { accessToken } = await appleLogin();
    const userInfo = await getUserInfo(accessToken as string);

    // eslint-disable-next-line
    // @ts-ignore
    if (userInfo['http://grunfin.com/claims/login_count'] === 1) {
      trackNewSignUp();
    }

    localStorage.setItem(StorageKey.NEXT, next);
    query.set(QueryParam.NEXT, next);
    window.location.href = `${apiRoot}/login`;
  };
};
