var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgInsiderIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 108, height: 16, viewBox: "0 0 108 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.848145 0.252684H3.48837V15.7477H0.848145V0.252684ZM22.2392 10.1623L11.5134 0.0138268V15.7473H14.052V5.6669L24.7778 15.9869V0.252684H22.2392V10.1623ZM38.5565 7.11664L36.0439 6.20788C34.749 5.75436 34.0124 5.29946 34.0124 4.16428C34.0124 2.90259 35.1807 2.29663 36.7542 2.29663C38.5818 2.29663 40.0038 2.97794 41.298 3.96241V1.26169C40.0547 0.454555 38.4552 0 36.7034 0C33.8347 0 31.4485 1.4888 31.4485 4.2144C31.4485 6.76302 33.0224 7.87366 35.104 8.63067L37.3633 9.4385C38.7594 9.96841 39.5465 10.4482 39.5465 11.6591C39.5465 13.0469 38.3025 13.6781 36.5254 13.6781C34.6982 13.6781 32.9968 13.0224 31.575 11.8862V14.637C32.667 15.3944 34.4438 16 36.5254 16C39.6993 16 42.1104 14.4348 42.1104 11.6086C42.1104 9.3628 40.9938 7.99983 38.5565 7.11664ZM48.0583 0.252684H50.6982V15.7477H48.0583V0.252684ZM64.8333 0.25303H58.8546V15.7477H64.8333C69.4283 15.7477 72.3986 12.7193 72.3986 7.99983C72.3986 3.28109 69.4279 0.25303 64.8333 0.25303ZM64.7317 13.4013H61.4951V2.57455H64.7317C68.0055 2.57455 69.7577 4.66896 69.7577 7.99983C69.7577 11.3055 68.0058 13.4013 64.7317 13.4013ZM78.3904 15.7477H87.8459V13.4013H80.9795V8.59922H87.1348V6.30259H80.9795V2.5742H87.8459V0.252684H78.3904V15.7477ZM101.548 9.3628C104.01 8.98429 105.172 7.49515 105.172 4.89572C105.172 1.86765 103.09 0.252684 99.8658 0.252684H94.2114V15.7477H96.8512V9.46374H98.73L104.156 15.7477H107.152L101.548 9.3628ZM96.8516 7.26804V2.52373H99.815C101.567 2.52373 102.557 3.45739 102.557 4.89572C102.557 6.33439 101.567 7.26804 99.815 7.26804H96.8516Z", fill: "#8A8987" }))); };
var ForwardRef = forwardRef(SvgInsiderIcon);
var Memo = memo(ForwardRef);
export default Memo;
