import 'twin.macro';

import { AppleIcon, EmailIcon, GoogleIcon, useTranslation } from '@grunfin/ui-kit';
import { useSession } from '~/modules/auth/SessionProvider';
import { useDocumentTitle } from '~/utils';
import { AuthMethod } from '../auth/types';

export function AccountGeneralView() {
  const { t } = useTranslation('account');
  useDocumentTitle(t('general.document_title'));
  const session = useSession();

  const verificationStatus = () => {
    const status = session?.verification?.toLowerCase();
    return status && t('verification.' + status);
  };

  return (
    <>
      <div tw="m-6 md:m-12">
        <div tw="text-alps-blue-700 text-lg md:text-xl mb-8 md:mb-12 font-bold text-center md:text-left md:font-medium">
          {t('general.title')}
        </div>

        <div tw="grid md:grid-cols-6 gap-x-6 gap-y-2 w-full items-baseline mb-8 md:mb-6">
          <div tw="font-medium text-gray-900 md:col-span-2">{t('general.email')}</div>
          <div tw="text-gray-400 md:col-span-2">{session.email}</div>
        </div>
        <div tw="grid md:grid-cols-6 gap-x-6 gap-y-2 w-full items-baseline mb-8 md:mb-6">
          <div tw="font-medium text-gray-900 md:col-span-2">{t('general.password')}</div>
          <div tw="text-gray-400 md:col-span-2">
            <div>••••••••••••••••</div>
            {/*<div tw="text-alps-blue-400 md:col-span-2">Change Password</div>*/}
          </div>
        </div>
        <div tw="grid md:grid-cols-6 gap-x-6 gap-y-2 w-full items-center mb-8 md:mb-6">
          <div tw="font-medium text-gray-900 md:col-span-2">{t('general.linked_accounts.title')}</div>
          {session.authMethods?.includes(AuthMethod.GOOGLE) && (
            <div tw="text-gray-400 md:col-span-2 flex flex-row items-center">
              <GoogleIcon tw="mr-4" /> {t('general.linked_accounts.google')}
            </div>
          )}
          {session.authMethods?.includes(AuthMethod.EMAIL) && (
            <div tw="text-gray-400 md:col-span-2 flex flex-row items-center">
              <EmailIcon tw="mr-4" /> {t('general.linked_accounts.email')}
            </div>
          )}
          {session.authMethods?.includes(AuthMethod.APPLE) && (
            <div tw="text-gray-400 md:col-span-2 flex flex-row items-center">
              <AppleIcon tw="mr-4" /> {t('general.linked_accounts.apple')}
            </div>
          )}
        </div>
        <div tw="grid md:grid-cols-6 gap-x-6 gap-y-2 w-full items-baseline mb-8 md:mb-6">
          <div tw="font-medium text-gray-900 md:col-span-2">{t('general.identity_verification')}</div>
          <div tw="text-alps-blue-400 md:col-span-2">{verificationStatus()}</div>
        </div>
      </div>
      <div tw="border-t-2 border-gray-50 px-6 md:px-12 md:py-10 grid md:grid-cols-6 gap-x-6 gap-y-2 w-full items-baseline ">
        <div tw="font-medium text-gray-900 md:col-span-2">{t('general.delete')}</div>
        <a tw="text-alps-blue-400 md:col-span-2" href="mailto: support@grunfin.com">
          {t('general.contact_support')}
        </a>
      </div>
    </>
  );
}
