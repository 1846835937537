var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgDocumentIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m7 12c0-1.1046.89543-2 2-2h14c1.1046 0 2 .8954 2 2v19.9c0 .0552-.0448.1-.1.1h-17.8c-.05523 0-.1-.0448-.1-.1zm3 3.5c0-.2761.2239-.5.5-.5h8c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-8c-.2761 0-.5-.2239-.5-.5zm.5 8.5c-.2761 0-.5.2239-.5.5s.2239.5.5.5h8c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5zm-.5-2.5c0-.2761.2239-.5.5-.5h5c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-5c-.2761 0-.5-.2239-.5-.5zm.5-3.5c-.2761 0-.5.2239-.5.5s.2239.5.5.5h11c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgDocumentIcon);
var Memo = memo(ForwardRef);
export default Memo;
