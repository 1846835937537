import { BeneficiaryType, formatCurrency, Spinner, useTranslation } from '@grunfin/ui-kit';
import tw from 'twin.macro';

import { usePortfolio } from '~/modules/portfolio/hooks';
import { useGetPortfolioStandingOrderInstruction } from '~/modules/portfolio/queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { Instruction } from './Instruction';

interface Props {
  amount?: boolean;
  compact?: boolean;
  horizontal?: boolean;
}

export const Instructions = ({ amount = true, compact, horizontal }: Props) => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const instructions = useGetPortfolioStandingOrderInstruction(portfolio.id);

  if (instructions.isIdle || instructions.isLoading) {
    return (
      <div tw="flex w-1/3 items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (instructions.isError) {
    return (
      <ContactSupportOverlay title={t('details.error')} onClose={instructions.refetch} error={instructions.error} />
    );
  }

  const { beneficiaryName, accountNumber, referenceNumber, totalMonthlyAmount, monthlyFee } = instructions.data;
  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;

  return (
    <div css={[horizontal && !compact ? tw`md:space-y-10 space-y-4` : tw`space-y-4`]}>
      <Instruction label={t('payment.beneficiary_name')} copy={beneficiaryName} inline={horizontal}>
        {beneficiaryName}
      </Instruction>
      <Instruction label={t('payment.account_number')} copy={accountNumber} inline={horizontal}>
        {accountNumber}
      </Instruction>
      <Instruction label={t('payment.reference_number')} copy={referenceNumber} inline={horizontal}>
        {referenceNumber}
      </Instruction>
      {amount && !isCorporate && (
        <Instruction label={t('sum', { ns: 'general' })} inline={horizontal}>
          {formatCurrency(totalMonthlyAmount, { digits: 2 })}
          {!!monthlyFee && monthlyFee > 0 && (
            <>
              <br />
              <span tw="text-gray-400">
                {t('payment.monthly_payment_plus_fee', {
                  fee: formatCurrency(monthlyFee, { digits: 2 }),
                })}
              </span>
            </>
          )}
        </Instruction>
      )}
    </div>
  );
};
