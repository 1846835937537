import { styled } from 'twin.macro';

export const Loader = () => (
  <div tw="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-white">
    <Logo src="/logo.svg" tw="h-32 md:h-64" />
  </div>
);

const Logo = styled.img`
  height: 256px;
  filter: grayscale() opacity(16%);
`;
