import 'twin.macro';
import React, { ReactNode, useEffect } from 'react';
import { Button, ButtonVariant, Spinner, useTranslation } from '@grunfin/ui-kit';
import * as veriff from '@veriff/incontext-sdk';
import { useQueryClient } from 'react-query';
import { useGetIdentityVerificationStatus } from '~/modules/portfolio/queries';
import { IdentityVerificationStatus, VerificationState } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { matchPath, useLocation, useOutletContext } from 'react-router-dom';
import { activationMatchHash, ActivationViewType } from '~/modules/portfolio/ActivateView/ActivationView';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';

interface Props {
  onNext: () => void;
}

const VerificationForm = ({ onNext }: Props) => {
  const { t } = useTranslation('portfolio');
  const resolvedLanguage = useTranslation().i18n.resolvedLanguage;
  const queryClient = useQueryClient();
  const getStatus = useGetIdentityVerificationStatus();
  const refreshStatus = () => setTimeout(getStatus.refetch, 3000);
  const { pathname } = useLocation();
  const cantSkip =
    matchPath(activationMatchHash[ActivationViewType.COMPANY_UBO], pathname) ||
    matchPath(activationMatchHash[ActivationViewType.VERIFICATION], pathname);

  useEffect(() => {
    if (getStatus.data?.verificationState == VerificationState.UPLOADED_AND_PENDING) refreshStatus();
  }, [getStatus]);

  if (getStatus.isError) {
    return (
      <ContactSupportOverlay
        title={t('activation.verification.error')}
        onClose={getStatus.refetch}
        error={getStatus.error}
      />
    );
  }

  if (getStatus.isLoading || getStatus.isIdle)
    return (
      <div tw="mx-auto">
        <Spinner />
      </div>
    );
  const { verificationState, veriffSessionUrl } = getStatus.data;

  const startVeriffFlow = () => {
    veriff.createVeriffFrame({
      url: veriffSessionUrl,
      lang: resolvedLanguage,
      onEvent(msg) {
        //TODO: Handle more events in the future?
        if (msg !== veriff.MESSAGES.FINISHED) return;
        queryClient.setQueryData<IdentityVerificationStatus>('verification-status', (old) => {
          if (!old) throw new Error('Verification status not yet loaded');
          return { ...old, verificationState: VerificationState.UPLOADED_AND_PENDING };
        });
        refreshStatus();
      },
    });
  };

  return (
    <div tw="max-w-lg">
      {verificationState == VerificationState.WAITING_FOR_UPLOAD && (
        <VerificationStatus
          title={t('activation.verification.start.subtitle')}
          subtitle={t('activation.verification.start.description')}
          buttons={
            <>
              {!cantSkip && (
                <div>
                  <Button variant={ButtonVariant.NEW_SECONDARY} onClick={onNext} data-test-id="skip-verification">
                    {t('activation.verification.start.skip')}
                  </Button>
                </div>
              )}
              <div tw="ml-auto">
                <Button variant={ButtonVariant.NEW_PRIMARY} onClick={startVeriffFlow}>
                  {t('activation.verification.start.proceed')}
                </Button>
              </div>
            </>
          }
        />
      )}

      {verificationState == VerificationState.APPROVED && (
        <VerificationStatus
          title={
            t('activation.verification.approved.title') + ' ' + t('activation.verification.approved.subtitle') + ' '
          }
          subtitle={t('activation.verification.approved.description')}
          buttons={
            <Button variant={ButtonVariant.NEW_PRIMARY} onClick={onNext} tw="ml-auto">
              {t('activation.verification.approved.proceed')}
            </Button>
          }
        />
      )}

      {verificationState == VerificationState.UPLOADED_AND_PENDING && (
        <VerificationStatus
          title={t('activation.verification.pending.title') + ' ' + t('activation.verification.pending.subtitle') + ' '}
          subtitle={t('activation.verification.pending.description')}
        />
      )}

      {verificationState == VerificationState.PROBLEMS && (
        <VerificationStatus
          title={
            t('activation.verification.problems.title') + ' ' + t('activation.verification.problems.subtitle') + ' '
          }
          subtitle={t('activation.verification.problems.description')}
          buttons={
            <Button variant={ButtonVariant.NEW_PRIMARY} onClick={onNext} tw="ml-auto">
              {t('activation.verification.problems.proceed')}
            </Button>
          }
        />
      )}
    </div>
  );
};

interface VerificationStatusProps {
  title?: string;
  subtitle: string;
  buttons?: ReactNode;
}

export const VerificationStatus = ({ title, subtitle, buttons }: VerificationStatusProps) => {
  const { t } = useTranslation('portfolio');
  const context = useOutletContext<OnboardingOutletContext>();
  const { onBack } = context ?? {};

  return (
    <div tw="flex flex-col gap-8">
      <div tw="flex flex-col gap-2">
        <p tw="text-lg font-medium">
          <span tw="text-green-900">{title} </span>
          <span tw="text-green-500">{subtitle}</span>
        </p>
      </div>
      <div tw="flex flex-row gap-2 md:gap-2 justify-start">
        <div>
          <Button type="submit" variant={ButtonVariant.NEW_SECONDARY} onClick={onBack}>
            {t('back', { ns: 'general' })}
          </Button>
        </div>
        {buttons}
      </div>
    </div>
  );
};

export default VerificationForm;
