var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgSuitcaseIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.75 8V8.25H13.25V8C13.25 7.44772 12.8023 7 12.25 7H11.75C11.1977 7 10.75 7.44772 10.75 8ZM6.5 8.25H8.75V8C8.75 6.34315 10.0931 5 11.75 5H12.25C13.9069 5 15.25 6.34315 15.25 8V8.25H17.5C18.6046 8.25 19.5 9.14543 19.5 10.25V11H15.7073C15.6044 10.7087 15.3266 10.5 15 10.5C14.6734 10.5 14.3956 10.7087 14.2927 11H9.70732C9.60436 10.7087 9.32655 10.5 9 10.5C8.67345 10.5 8.39564 10.7087 8.29268 11H4.5V10.25C4.5 9.14543 5.39543 8.25 6.5 8.25ZM4.5 12V16C4.5 17.1046 5.39543 18 6.5 18H17.5C18.6046 18 19.5 17.1046 19.5 16V12H15.75C15.75 12.4142 15.4142 12.75 15 12.75C14.5858 12.75 14.25 12.4142 14.25 12H9.75C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12H4.5Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgSuitcaseIcon);
var Memo = memo(ForwardRef);
export default Memo;
