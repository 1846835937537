import { Button, ButtonVariant, UploadIcon, useTranslation } from '@grunfin/ui-kit';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';
import { createAndDownloadCSVFile, useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '../application/Navigation';
import PaymentsTable from './components/Table/PaymentsTable';
import { getCompanyPayments } from './queries';
import { Transaction } from './types';

export function CompanyPaymentsView() {
  const { t } = useTranslation('company');
  const navigate = useNavigate();
  const { companyId, benefitId } = useParams();
  const baseUrl = `/company/${companyId}/benefit/${benefitId}`;
  useNavigationTitle([
    [t('navigation_title'), baseUrl],
    [t('payments.navigation_title'), `${baseUrl}/payments`],
  ]);
  useDocumentTitle(t('payments.document_title'));

  const getAllPayments = async () => {
    let payments: Transaction[] = [];
    if (!benefitId) return payments;

    const fetchPayments = async (page: number) => {
      try {
        const response = await getCompanyPayments({
          companyBenefitId: benefitId,
          page,
          size: 1000,
          sort: ['createdAt,desc'],
        });
        if ('content' in response && Array.isArray(response.content) && response.content.length > 0)
          payments = [...payments, ...response.content];
        // if it's not the last page, fetch next page
        if ('last' in response && !response.last) await fetchPayments(page + 1);
      } catch (err) {
        captureException(err);
      }
    };

    await fetchPayments(0);
    return payments;
  };

  const handleDownload = async () => {
    const rows = await getAllPayments();
    if (rows.length === 0) return;
    createAndDownloadCSVFile(rows, t('payments.file_name'));
  };

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-row flex-wrap items-center justify-between gap-4">
        <h2 tw="text-alps-blue-700 text-lg md:text-xl font-bold">{t('payments.title')}</h2>
        <div tw="flex flex-row flex-wrap gap-4">
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={() => navigate(`${baseUrl}/payments/upload`)}>
            <UploadIcon /> {t('payments.upload.document_title')}
          </Button>
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={handleDownload}>
            <UploadIcon tw="rotate-180" /> {t('payments.upload.download_csv')}
          </Button>
        </div>
      </div>
      <div tw="flex flex-col-reverse gap-8 lg:flex-row items-start">
        <PaymentsTable />
      </div>
    </div>
  );
}
