import 'twin.macro';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon, Button, ButtonSize, ButtonVariant, Input, Trans, useTranslation } from '@grunfin/ui-kit';

import { useCreateQuestionnaire, useSubmitQuestionnaire } from '~/modules/onboarding/queries';
import { setBackendValidationErrors } from '~/utils';
import { trackRedeem } from '~/utils/tracking-analytics';

import { useValidateVoucher } from './queries';
import redeemBg from '~/assets/output/voucher-redeem-bg.webp';
import logo from '~/assets/output/white-logo.svg';

type Values = { voucherCode: string };

export const VOUCHER_KEY = 'VOUCHER_CODE';

export const VoucherRedeem = () => {
  const { t, i18n } = useTranslation('gift');
  const navigate = useNavigate();
  const validate = useValidateVoucher();
  const submitAnswers = useSubmitQuestionnaire();
  const createQuestionnaire = useCreateQuestionnaire();
  const { register, handleSubmit, formState, setError } = useForm<Values>({ mode: 'onChange' });
  const { errors, isValid, isSubmitting } = formState;
  const canSubmit = isValid && !isSubmitting;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const onSubmit = async ({ voucherCode }: Values, isSignUp: boolean) => {
    try {
      await validate.mutateAsync(voucherCode);
      if (isSignUp) {
        const questionnaire = await createQuestionnaire.mutateAsync({ type: 'portfolio' });
        await submitAnswers.mutateAsync({ type: 'portfolio', id: questionnaire.id, body: { voucherCode } });
      }
      localStorage.setItem(VOUCHER_KEY, voucherCode);
      trackRedeem(voucherCode);
      isSignUp ? navigate('/onboarding') : navigate('/login');
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  const steps = [
    t('voucher.redeem.step.customise_portfolio'),
    t('voucher.redeem.step.add_details'),
    t('voucher.redeem.step.finish_setup'),
    t('voucher.redeem.step.add_payments'),
  ];

  return (
    <div tw="fixed top-0 left-0 w-full h-full flex items-center justify-center overflow-x-hidden overflow-y-auto">
      <img
        src={logo}
        tw="absolute left-1/2 -translate-x-1/2 md:left-10 md:-translate-x-0 top-10 z-10"
        alt={t('voucher.redeem.logo')}
      />

      <div tw="z-10 flex flex-col h-full mx-6 mt-8">
        <div tw="mt-28 hidden md:flex">
          {steps.map((step, index) => (
            <div tw="text-alps-blue-700 flex flex-row items-center" key={index}>
              <div tw="bg-white rounded-full h-8 w-8 flex items-center justify-center font-bold text-base">
                {++index}
              </div>
              <div tw="ml-4 text-white text-sm">{step}</div>
              {index < 4 && <ArrowIcon key={1} tw="mx-6 text-gray-100" />}
            </div>
          ))}
        </div>

        <div tw="grid grid-cols-1 md:grid-cols-2 md:gap-x-32 justify-items-center md:px-16 mb-16">
          <div tw="mt-28 md:mt-20 bg-white rounded max-w-sm">
            <form onSubmit={handleSubmit((values) => onSubmit(values, true))}>
              <div tw="max-h-full p-12">
                <div tw="text-alps-blue-600 text-lg font-bold text-left">{t('voucher.redeem.heading')}</div>
                <div tw="text-gray-500 text-lg mt-4">{t('voucher.redeem.code.label')}</div>
                <div tw="mt-12">
                  <Input
                    {...register('voucherCode', {
                      required: t('required', { ns: 'general' }) as string,
                      minLength: 9,
                      maxLength: 9,
                    })}
                    error={'voucherCode' in errors}
                    key="name"
                    placeholder={t('voucher.redeem.code.placeholder')}
                    minLength={9}
                    maxLength={9}
                  />
                  {errors.voucherCode && <div tw="text-sm text-dawn-300 mt-2">{errors.voucherCode.message}</div>}
                </div>
                <div tw="mt-4 w-full whitespace-nowrap">
                  <Button type="submit" disabled={!canSubmit} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
                    {t('voucher.redeem.create_portfolio')}
                  </Button>
                </div>
                <div tw="mt-4 w-full whitespace-nowrap">
                  <Button
                    onClick={handleSubmit((values) => onSubmit(values, false))}
                    disabled={!canSubmit}
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.LARGE}
                  >
                    {t('voucher.redeem.use_existing_portfolio')}
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div tw="mt-16 md:mt-20 max-w-sm text-gray-900">
            <div tw="text-lg">
              <div>{t('voucher.redeem.introduction')}</div>
              <div tw="mt-4">
                <Trans i18nKey="voucher.redeem.goals" t={t} />
              </div>
            </div>
            <div tw="mt-8">{t('voucher.redeem.experience')}</div>
            <div tw="mt-2">{t('voucher.redeem.simplicity')}</div>
            <div tw="mt-2 mb-6">
              <Trans
                i18nKey="voucher.redeem.questions"
                t={t}
                components={[
                  <a
                    key="0"
                    tw="text-alps-blue-600"
                    target="_blank"
                    href="mailto:support@grunfin.com"
                    rel="noreferrer"
                  />,
                  <a
                    key="1"
                    href={`https://faq.grunfin.com/${i18n.resolvedLanguage}`}
                    tw="text-alps-blue-600"
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <img tw="fixed w-full h-full object-cover object-center" src={redeemBg} alt={t('voucher.redeem.background')} />
    </div>
  );
};
