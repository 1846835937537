import { Button, ButtonVariant, UploadIcon, useTranslation } from '@grunfin/ui-kit';
import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import 'twin.macro';
import { createAndDownloadCSVFile, useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '../application/Navigation';
import FeesTable from './components/Table/FeesTable';
import { getCompanyFees, useGetCompany } from './queries';
import { CompanyFee } from './types';

export function CompanyFeesView() {
  const { t } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  const company = useGetCompany(companyId);
  const baseUrl = `/company/${companyId}/benefit/${benefitId}`;
  useNavigationTitle([
    [t('navigation_title'), baseUrl],
    [t('fees.navigation_title'), `${baseUrl}/fees`],
  ]);
  useDocumentTitle(t('fees.document_title'));

  const getAllFees = async () => {
    let fees: CompanyFee[] = [];
    if (!benefitId) return fees;

    const fetchFees = async (page: number) => {
      try {
        const response = await getCompanyFees({ companyBenefitId: benefitId, page, size: 1000, sort: [''] });
        if ('content' in response && Array.isArray(response.content) && response.content.length > 0)
          fees = [...fees, ...response.content];
        // if it's not the last page, fetch next page
        if ('last' in response && !response.last) await fetchFees(page + 1);
      } catch (err) {
        captureException(err);
      }
    };

    await fetchFees(0);
    return fees;
  };

  const handleDownload = async () => {
    const rows = await getAllFees();
    if (rows.length === 0) return;
    await createAndDownloadCSVFile(rows, t('fees.file_name'));
  };

  return (
    <div tw="flex flex-col gap-6 lg:gap-8 bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-row flex-wrap items-center justify-between">
        <h2 tw="text-alps-blue-700 text-lg md:text-xl font-bold">{t('fees.title')}</h2>
        <div tw="flex flex-row flex-wrap">
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={handleDownload}>
            <UploadIcon tw="rotate-180" /> {t('fees.download_csv')}
          </Button>
        </div>
      </div>
      <div tw="flex flex-col justify-between gap-2 ml-1">
        <div tw="text-gray-600">{company.data?.name}</div>
        <div tw="text-gray-600">{company.data?.registrationNumber}</div>
      </div>
      <div tw="flex flex-col-reverse gap-8 lg:flex-row items-start">
        <FeesTable />
      </div>
    </div>
  );
}
