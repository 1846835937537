import {
  Button,
  ButtonVariant,
  CheckIcon,
  Spinner,
  Tooltip,
  UploadIcon,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { captureException, captureMessage } from '@sentry/react';
import { ParseError } from 'papaparse';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { createAndDownloadCSVFile, useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '../application/Navigation';
import CSVImporter, { DataPoint } from './components/CSV/CSVImporter';
import CSVUploadErrors from './components/CSV/CSVUploadErrors';
import MemberUploadSuccess from './components/MemberUploadSuccess';
import StepHeader from './components/StepHeader';
import { getCustomerCompanyMembersByEmployerSideIds, useSubmitCompanyMembers } from './queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { AddEmployee } from '~/modules/company/types';
import MemberList from '~/modules/company/components/MemberList';
import AddEmployeeForm from '~/modules/company/components/AddEmployeeForm';

export function CompanyMemberUploadView() {
  const { t } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  useNavigationTitle([
    [t('navigation_title'), `/company/${companyId}/benefit/${benefitId}`],
    [t('employees.navigation_title'), `/company/${companyId}/benefit/${benefitId}`],
    t('employees.upload.navigation_title'),
  ]);
  useDocumentTitle(t('employees.upload.document_title'));

  if (companyId && benefitId) {
    return (
      <div tw="flex flex-col gap-8">
        <MemberForm companyId={companyId} benefitId={benefitId} />
      </div>
    );
  }

  return null;
}

const MemberForm = ({ companyId, benefitId }: { companyId: string; benefitId: string }) => {
  const { t } = useTranslation('company');
  const [currentStep, setCurrentStep] = useState(0);
  const [rows, setRows] = useState<AddEmployee[]>([]);
  const [invalidRows, setInvalidRows] = useState<DataPoint<AddEmployee>[]>([]);
  const [uploadErrors, setUploadErrors] = useState<ParseError[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const submitMembers = useSubmitCompanyMembers(benefitId);

  const fetchEmployees = async (csvRows: AddEmployee[]) => {
    const employerSideIds = csvRows.map((row) => row.employerSideId);
    try {
      return await getCustomerCompanyMembersByEmployerSideIds({
        companyBenefitId: benefitId,
        employerSideIds,
        page: 0,
        size: 1000,
        sort: [],
      });
    } catch (err) {
      captureException(err);
    }
  };

  const handleUpload = async (data_points: DataPoint<AddEmployee>[], errors: ParseError[]) => {
    // reset the state
    setRows([]);
    setInvalidRows([]);
    setUploadErrors([]);

    if (errors.length > 0) {
      setUploadErrors(errors);
      return;
    }

    // check if all rows are valid
    const allValid = data_points.length > 0 && data_points.every((dataPoint) => dataPoint.valid);

    // if all rows are not valid, set the invalid rows
    if (!allValid) {
      const invalidRows = data_points.filter((point) => !point.valid);
      if (invalidRows.length > 0) setInvalidRows(invalidRows);

      // do not continue
      return;
    }

    const customers = data_points.map((dataPoint) => dataPoint.row);

    // check if there are any duplicate entries
    const duplicateEntries = customers.filter(
      (entry, index, self) =>
        index !==
        self.findIndex(
          (t) => t.employerSideId.trim() === entry.employerSideId.trim() || t.email.trim() === entry.email.trim(),
        ),
    );

    if (duplicateEntries.length > 0) {
      // if there are duplicate entries, set them as invalid rows
      setInvalidRows(
        duplicateEntries.map((entry) => {
          let name = entry.givenName;
          if (name) {
            if (entry.lastName) name += ` ${entry.lastName}`;
          } else if (entry.employerSideId) name = entry.employerSideId;
          return {
            row: entry,
            valid: false,
            missingHeaders: [t('general.errors.parse_failed_employee_duplicate', { row: name })],
            invalidFields: [],
          };
        }),
      );

      // do not continue
      return;
    }

    // we have to check if there are any employees already uploaded
    const query = await fetchEmployees(customers);
    if (!query?.content || !Array.isArray(query.content)) {
      // this should not happen
      captureMessage(`Failed to fetch employees for company ${companyId} with stack ${JSON.stringify(query)}}`);

      // do not continue
      return;
    }

    if (query.content.length > 0) {
      // there are employees already uploaded
      setInvalidRows(
        query.content.map((member) => {
          let name = member.givenName;
          if (name) {
            if (member.lastName) name += ` ${member.lastName}`;
          } else if (member.employerSideId) name = member.employerSideId;
          return {
            row: member,
            valid: false,
            missingHeaders: [t('general.errors.parse_failed_existing_employee', { row: name })],
            invalidFields: ['employerSideId'],
          };
        }),
      );
      // do not continue
      return;
    }

    setRows(data_points.map((point) => point.row));
    setCurrentStep(2);
  };

  const submitPeople = async () => {
    setIsSubmitting(true);
    if (rows.length > 0) {
      try {
        await submitMembers.mutateAsync(rows);
        setIsSuccess(true);
      } catch (err) {
        console.error(err);
        captureException(err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (submitMembers.isError) {
    console.log(submitMembers.error);
    return (
      <ContactSupportOverlay
        title={t('general.errors.upload')}
        onClose={submitMembers.reset}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        error={submitMembers.error}
      />
    );
  }

  const handleDownloadCSVTemplate = () => {
    // we only suggest uploading most basic fields
    const headers: (keyof AddEmployee)[] = ['employerSideId', 'email', 'givenName', 'lastName'];

    const exampleRow: Partial<AddEmployee> = {
      employerSideId: '999',
      email: 'john.doe@personalemail.com',
      givenName: 'John',
      lastName: 'Doe',
    };

    createAndDownloadCSVFile([exampleRow], t('employees.file_name'), headers);
  };

  const instructions = t('employees.upload.tooltip.instructions', { returnObjects: true });

  const HelpButton = () =>
    currentStep === 2 || currentStep === 1 ? (
      <div tw="ml-auto">
        <Tooltip
          showOnHover={false}
          content={
            <div tw="flex flex-col gap-4 p-4 max-w-md">
              <p>{t('employees.upload.tooltip.description')}</p>
              <ol tw="flex flex-col gap-2">
                {Array.isArray(instructions) &&
                  instructions.map((item, idx) => (
                    <li key={item}>
                      <strong>{idx + 1}.</strong> {item}
                    </li>
                  ))}
              </ol>
              <Button variant={ButtonVariant.PRIMARY} onClick={handleDownloadCSVTemplate} tw="max-w-max">
                {t('employees.upload.tooltip.download')}
              </Button>
            </div>
          }
        >
          <Button variant={ButtonVariant.SECONDARY}>{t('employees.upload.tooltip.title')}</Button>
        </Tooltip>
      </div>
    ) : null;

  const steps = () => {
    const steps = [
      {
        index: 0,
        title: t('employees.upload.steps.step_0'),
        icon: <CheckIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
        activeIcon: <CheckIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />,
      },
    ];
    if (currentStep === 1 || currentStep === 2) {
      steps.push(
        ...[
          {
            index: 1,
            title: t('employees.upload.steps.step_1'),
            icon: <UserIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
            activeIcon: <UserIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />,
          },
          {
            index: 2,
            title: t('employees.upload.steps.step_2'),
            icon: <CheckIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
            activeIcon: <CheckIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-100 rounded" />,
          },
        ],
      );
    } else if (currentStep === 3) {
      steps.push({
        index: 3,
        title: t('employees.upload.steps.step_3'),
        icon: <UserIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
        activeIcon: <UserIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-100 rounded" />,
      });
    }
    return steps;
  };

  const ValidateUpload = () => (
    <>
      <MemberList rows={rows} />
      <div tw="flex flex-row flex-wrap justify-between items-center">
        <div>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={() => setCurrentStep((p) => p - 1)}
            disabled={isSubmitting}
          >
            {t('back', { ns: 'general' })}
          </Button>
        </div>
        <div>
          <Button variant={ButtonVariant.PRIMARY} onClick={submitPeople} disabled={isSubmitting}>
            <span css={[isSubmitting && tw`opacity-0`]}>{t('confirm', { ns: 'general' })}</span>
            {isSubmitting && (
              <div tw="absolute">
                <Spinner />
              </div>
            )}
          </Button>
        </div>
      </div>
    </>
  );

  const CSVUploadStep = () => (
    <CSVImporter
      title={t('employees.upload.dropzone')}
      icon={<UserIcon width={64} height={64} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />}
      handleTemplateDownload={handleDownloadCSVTemplate}
      onComplete={handleUpload}
      requiredHeaders={['employerSideId', 'email', 'givenName', 'lastName']}
    />
  );

  const ChooseMethod = ({ setCurrentStep }: { setCurrentStep: Dispatch<SetStateAction<number>> }) => {
    const { t } = useTranslation('company');

    return (
      <div tw="grid grid-cols-2 gap-6 lg:gap-12">
        <div
          tw="col-span-1 border-2 border-gray-200 border rounded md:rounded-2xl cursor-pointer"
          onClick={() => setCurrentStep(() => 3)}
        >
          <div tw="flex flex-col items-center justify-center gap-6 p-4 md:py-8 lg:py-12">
            <UserIcon width={64} height={64} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />
            <h2 tw="text-gray-400 text-center text-base md:text-lg">{t('employees.upload.steps.step_3')}</h2>
          </div>
        </div>
        <div
          tw="col-span-1 w-full border-2 border-gray-200 border rounded md:rounded-2xl cursor-pointer"
          onClick={() => setCurrentStep(() => 1)}
        >
          <div tw="flex flex-col items-center justify-center gap-6 p-4 md:py-8 lg:py-12">
            <UploadIcon width={64} height={64} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />
            <h2 tw="text-gray-400 text-center text-base md:text-lg">{t('employees.upload.steps.step_1')}</h2>
          </div>
        </div>
      </div>
    );
  };

  if (isSuccess) return <MemberUploadSuccess />;

  return (
    <div tw="bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-col gap-8">
        <div tw="flex flex-row flex-wrap gap-4 items-center justify-between">
          <StepHeader currentStep={currentStep} onCurrentStepChange={setCurrentStep} steps={steps()} />
          <HelpButton />
        </div>

        <CSVUploadErrors data_points={invalidRows} errors={uploadErrors} />

        {currentStep === 0 && <ChooseMethod setCurrentStep={setCurrentStep} />}

        <div css={[currentStep !== 1 && tw`hidden`]}>
          <CSVUploadStep />
        </div>

        <div css={[currentStep !== 2 && tw`hidden`]}>
          <ValidateUpload />
        </div>

        {currentStep === 3 && (
          <AddEmployeeForm setCurrentStep={setCurrentStep} submitMembers={submitMembers} setIsSuccess={setIsSuccess} />
        )}
      </div>
    </div>
  );
};
