import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/de';
import 'dayjs/locale/et';
import 'dayjs/locale/en';
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
export { dayjs };
/**
 * Create a range of Day.js dates between a start and end date.
 *
 * dayjsRange(dayjs('2021-04-03'), dayjs('2021-04-05'), 'day');
 * // => [dayjs('2021-04-03'), dayjs('2021-04-04'), dayjs('2021-04-05')]
 */
export function dayjsRange(start, end, unit) {
    var range = [];
    var current = start;
    while (!current.isAfter(end)) {
        range.push(current);
        current = current.add(1, unit);
    }
    return range;
}
export var differenceInMonths = function (date1, date2) {
    var d1 = dayjs(date1);
    return d1.diff(date2, 'month');
};
export var differenceInYears = function (date1, date2) {
    var d1 = dayjs(date1);
    return d1.diff(date2, 'year');
};
/*
 * If the locale assigns Sunday as the first day of the week, dayjs().weekday(0) will be Sunday.
 * If Monday is the first day of the week, dayjs().weekday(0) will be Monday.
 */
export var getFirstDayStartOfWeekByLocale = function (date) { return dayjs(date).weekday(0).get('day'); };
export var formatDate = function (date, formatString) {
    return dayjs(new Date(date)).format(formatString !== null && formatString !== void 0 ? formatString : 'L');
};
