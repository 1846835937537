import {
  AlertDialog,
  Button,
  ButtonSize,
  ButtonVariant,
  DecisionStatus,
  FocusArea,
  PortfolioStatus,
  toast,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';

import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { usePortfolio } from '../../hooks';
import { useExitPortfolio, useGetExitStatus, useUpdatePortfolio } from '../../queries';
import { UpdatePortfolio } from '../../types';
import { SettingsForm } from './SettingsForm';
import React from 'react';
import { trackPortfolioExit } from '~/utils/tracking-analytics';

export const Settings = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const navigate = useNavigate();
  const updatePortfolio = useUpdatePortfolio(portfolio.id);
  const exit = useExitPortfolio(portfolio.id);
  const exitStatus = useGetExitStatus(portfolio.id);
  const hasPendingExit = exitStatus?.data?.some((r) => [DecisionStatus.CREATED, DecisionStatus.APPROVED].includes(r));
  // Don't allow exited portfolios to access this route
  if (portfolio.status === PortfolioStatus.EXITED) {
    navigate('/', { replace: true });
    return null;
  }

  if (updatePortfolio.isError) {
    return <ContactSupportOverlay title={t('error')} onClose={updatePortfolio.reset} error={updatePortfolio.error} />;
  }

  const handlePortfolioExit = () => {
    trackPortfolioExit(portfolio.id);

    toast
      .promise(
        exit.mutateAsync(),
        {
          loading: t('exit.loading'),
          success: t('exit.success'),
          error: t('exit.error'),
        },
        { style: { minWidth: '184px' } },
      )
      .then(() => exitStatus.refetch());
  };

  const onSubmit = async (values: UpdatePortfolio, setError: UseFormSetError<UpdatePortfolio>) => {
    try {
      await updatePortfolio.mutateAsync({
        ...values,
        areaClimateChange: values.focusAreas.includes(FocusArea.CLIMATE_CHANGE),
        areaGenderEquality: values.focusAreas.includes(FocusArea.GENDER_EQUALITY),
        areaHealth: values.focusAreas.includes(FocusArea.HEALTH),
        areaSP500: values.focusAreas.includes(FocusArea.SP500),
      });
      toast.success(t('update.success'));
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };
  const Separator = () => <hr className="border-neutral-100 my-10 px-6" />;

  return (
    <div tw="flex flex-col md:w-3/5 md:self-center pt-8 pb-16">
      <Heading primary>{t('edit')}</Heading>
      <div tw="px-6">
        <SettingsForm portfolio={portfolio} onSubmit={onSubmit} />
      </div>
      <Separator />
      <Heading primary noMargin>
        {t('exit.title')}
      </Heading>
      <div tw="px-6">
        <p tw="text-neutral-500 mb-8">
          <Trans
            i18nKey="portfolio:exit.description"
            components={[<a key="0" tw="underline" target="_blank" rel="noreferrer" href={t('exit.faq_link')} />]}
          />
        </p>
        <AlertDialog
          title={t('exit.prompt.title')}
          description={t('exit.prompt.description')}
          actions={(closeFn: () => void) => (
            <div tw="flex flex-col md:flex-row gap-y-2 md:whitespace-nowrap">
              <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={closeFn}>
                {t('exit.prompt.cancel')}
              </Button>
              <div tw="my-2 md:mx-2" />
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                onClick={() => {
                  handlePortfolioExit();
                  closeFn();
                }}
              >
                {t('exit.prompt.confirm')}
              </Button>
            </div>
          )}
        >
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.LARGE}
            disabled={hasPendingExit || portfolio.status !== PortfolioStatus.ACTIVE}
          >
            {t('exit.request_button')}
          </Button>
        </AlertDialog>
        <p tw="mt-2 text-gray-400 text-sm">{hasPendingExit && t('exit.in_process')}</p>
      </div>
    </div>
  );
};
