import { ReactNode } from 'react';
import 'twin.macro';
import { Portfolio } from '@grunfin/ui-kit';

import { PortfolioStatus } from '../types';
import { ActiveItem, ExitedItem } from './ActiveItem';
import { DraftItem } from './DraftItem';

interface Props {
  onboarding?: boolean;
  portfolio: Portfolio;
}

export const PortfolioItem = ({ portfolio, onboarding }: Props) => {
  let contents: ReactNode;

  if (portfolio.status === PortfolioStatus.NEW) {
    contents = <DraftItem portfolio={portfolio} onboarding={onboarding} />;
  } else if (
    [PortfolioStatus.ACTIVE, PortfolioStatus.PENDING_ACTIVATION, PortfolioStatus.SUSPENDED].includes(portfolio.status)
  ) {
    contents = <ActiveItem portfolio={portfolio} />;
  } else if (portfolio.status === PortfolioStatus.EXITED) {
    contents = <ExitedItem portfolio={portfolio} />;
  } else return null;

  return <div tw="rounded-2xl shadow-md mb-10 mx-2 md:mx-0 bg-white relative z-0">{contents}</div>;
};
