import 'twin.macro';
import { Button, ButtonVariant, CheckIcon, ClipboardIcon, toast, Trans, useTranslation } from '@grunfin/ui-kit';
import { useEffect } from 'react';
import { matchPath, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { trackActivationSteps, trackReferralShare } from '~/utils/tracking-analytics';
import { OnboardingOutletContext } from '..';
import {
  ActivationLink,
  activationMatchHash,
  ActivationViewType,
} from '~/modules/portfolio/ActivateView/ActivationView';
import { useReferralLinks } from '~/modules/account/components/ReferralLink';
import { useSession } from '~/modules/auth/SessionProvider';
import { Disclaimers, FooterVariant } from '../../Footer';

const CompletedScreen = () => {
  const { t } = useTranslation('onboarding');
  const context = useOutletContext<OnboardingOutletContext>();
  const { onContinue } = context ?? {};
  const session = useSession();
  const referralLink = `${window.location.origin}/referral/${session.referralCode}`;
  const links = useReferralLinks();
  const params = useParams();
  const { id } = params ?? {};
  const { pathname } = useLocation();
  const isKYCVerification = !!matchPath('/kyc/*', pathname);
  const isUBOVerification = !!matchPath(activationMatchHash[ActivationViewType.COMPANY_UBO], pathname);
  const isVerification = isUBOVerification || isKYCVerification;

  useEffect(() => {
    if (id) trackActivationSteps(ActivationLink.COMPLETED, id || '');
  }, [id]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        typeof onContinue !== 'undefined' && onContinue();
      }}
      tw="flex flex-col flex-1 gap-28 p-4 items-center w-full justify-center mt-6 sm:mt-28"
    >
      <div tw="flex flex-col gap-28 justify-evenly items-center">
        <div tw="flex flex-col gap-10 items-center text-center text-white max-w-xs font-medium text-lg">
          <div tw="rounded-full p-2 bg-white">
            <CheckIcon width={80} height={80} tw="text-green-700" />
          </div>
          <h2 tw="font-bold text-lg md:text-xl">
            {isVerification ? t('completed.ubo_completed') : t('completed.title')}
          </h2>
          {!isUBOVerification && (
            <div tw="w-max">
              <Button variant={ButtonVariant.NEW_PRIMARY} type="submit" data-test-id="onboarding-continue">
                {t('completed.continue')}
              </Button>
            </div>
          )}
        </div>
        {!isVerification && (
          <div tw="flex flex-col sm:items-center gap-8 max-w-xl">
            <p tw="text-base text-center text-green-200 sm:text-center">
              <Trans
                i18nKey="onboarding:completed.referral.description"
                components={[<span key="0" tw="text-white" />]}
              />
            </p>
            <div tw="flex flex-row flex-wrap gap-10 mx-2">
              <ol tw="inline-flex flex-wrap gap-6">
                {links.map((link) => {
                  return (
                    <li key={link.href}>
                      <a
                        tw="flex flex-row gap-3 items-center text-sm"
                        target="_blank"
                        rel="noreferrer"
                        href={link.href}
                        onClick={link.onClick}
                      >
                        <span tw="bg-green-200 text-green-800 rounded-lg w-8 h-8 flex items-center justify-center">
                          {link.icon}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ol>
              <div tw="inline-flex gap-4 items-center text-green-200">
                <ClipboardIcon
                  tw="bg-green-200 rounded-lg cursor-pointer text-green-800"
                  onClick={() => {
                    navigator.clipboard.writeText(referralLink);
                    toast.success(t('copied', { ns: 'general' }));
                    trackReferralShare('copied link');
                  }}
                />
                <p>{t('completed.referral.copy')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div tw="w-full max-w-[1032px] px-6 mx-auto sm:my-12 sm:bottom-0">
        <Disclaimers variant={FooterVariant.DARK} />
      </div>
    </form>
  );
};

export default CompletedScreen;
