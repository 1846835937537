import { Message, MessageLevel, Trans, useTranslation } from '@grunfin/ui-kit';
import { ParseError } from 'papaparse';
import 'twin.macro';

const CSVUploadErrors = ({ data_points, errors }: { data_points: any[]; errors: ParseError[] }) => {
  const { t } = useTranslation('company');
  if (errors.length <= 0 && data_points.length <= 0) return null;
  return (
    <div tw="flex flex-col gap-4">
      {errors.length > 0 && (
        <Message level={MessageLevel.WARN}>
          <div tw="flex flex-col gap-2">
            <p tw="font-semibold text-sky-blue-700 text-base">{t('general.errors.parse')}</p>
            {errors.map((error, index) => (
              <p key={index}>
                <strong>{error.code}: </strong>
                <Trans
                  i18nKey="company:general.errors.parse_failed_row"
                  values={{
                    row: error.row,
                    error: error.message,
                  }}
                />
              </p>
            ))}
          </div>
        </Message>
      )}
      {data_points.length > 0 && (
        <Message level={MessageLevel.WARN}>
          <div tw="flex flex-col gap-2">
            <p tw="font-semibold text-sky-blue-700 text-base">{t('general.errors.upload')}</p>
            {data_points.map((dataPoint, index) => (
              <p key={index}>
                <Trans
                  i18nKey="company:general.errors.parse_failed_headers"
                  values={{
                    row: dataPoint.row?.employerSideId || dataPoint.row?.id || dataPoint.row?.portfolioRefNumber,
                  }}
                />
                <strong> [{dataPoint.missingHeaders.concat(dataPoint.invalidFields).join(', ')}]</strong>
              </p>
            ))}
          </div>
        </Message>
      )}
    </div>
  );
};

export default CSVUploadErrors;
