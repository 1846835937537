var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGrunfinLightLogo = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9912 12.4649C23.997 12.3107 24 12.1557 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 16.4894 2.46533 20.403 6.11603 22.4609C7.8546 23.4409 9.86201 24 12 24C18.2132 24 23.3235 19.278 23.938 13.2269C23.9636 12.9751 23.9814 12.7211 23.9912 12.4649ZM7.06819 20.9306L14.896 8.35025C15.5535 7.29357 16.9805 7.03729 17.9645 7.79916L22.1558 11.044C21.6738 5.85897 17.3109 1.8 12 1.8C6.3667 1.8 1.8 6.3667 1.8 12C1.8 15.8445 3.92689 19.1921 7.06819 20.9306Z", fill: "white" }),
    React.createElement("path", { d: "M13.2 6.90001C13.2 8.05981 12.2598 9.00001 11.1 9.00001C9.9402 9.00001 9 8.05981 9 6.90001C9 5.74021 9.9402 4.80001 11.1 4.80001C12.2598 4.80001 13.2 5.74021 13.2 6.90001Z", fill: "white" }),
    React.createElement("path", { opacity: 0.56, d: "M4.33147 21.2312C2.29678 19.5389 0.830535 17.1866 0.261353 14.5028L6.2907 10.3286C6.77242 9.99513 7.42453 10.0582 7.83335 10.4779L9.90017 12.5997L4.33147 21.2312Z", fill: "white" }))); };
var ForwardRef = forwardRef(SvgGrunfinLightLogo);
var Memo = memo(ForwardRef);
export default Memo;
