var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgMinusIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "m8 16h16", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2 }))); };
var ForwardRef = forwardRef(SvgMinusIcon);
var Memo = memo(ForwardRef);
export default Memo;
