export var BeneficiaryType;
(function (BeneficiaryType) {
    BeneficiaryType["CHILD"] = "CHILD";
    BeneficiaryType["SELF"] = "SELF";
    BeneficiaryType["EMPLOYEE"] = "EMPLOYEE";
    BeneficiaryType["COMPANY"] = "COMPANY";
})(BeneficiaryType || (BeneficiaryType = {}));
export var PricingScheme;
(function (PricingScheme) {
    PricingScheme["RETAIL_AUM"] = "RETAIL_AUM";
    PricingScheme["RETAIL_LEGACY_FLAT"] = "RETAIL_LEGACY_FLAT";
    PricingScheme["COMPANY_AUM"] = "COMPANY_AUM";
    PricingScheme["EMPLOYER_FREE"] = "EMPLOYER_FREE";
    PricingScheme["EMPLOYER_AUM"] = "EMPLOYER_AUM";
})(PricingScheme || (PricingScheme = {}));
export var PortfolioTransactionType;
(function (PortfolioTransactionType) {
    PortfolioTransactionType["FEE"] = "FEE";
    PortfolioTransactionType["PAYMENT"] = "PAYMENT";
})(PortfolioTransactionType || (PortfolioTransactionType = {}));
export var PortfolioFeeStatus;
(function (PortfolioFeeStatus) {
    PortfolioFeeStatus["PAID"] = "PAID";
    PortfolioFeeStatus["WAITING_PAYMENT"] = "WAITING_PAYMENT";
})(PortfolioFeeStatus || (PortfolioFeeStatus = {}));
export var PortfolioAssetClass;
(function (PortfolioAssetClass) {
    PortfolioAssetClass["STOCK"] = "STOCK";
    PortfolioAssetClass["BOND"] = "BOND";
})(PortfolioAssetClass || (PortfolioAssetClass = {}));
export var PortfolioAssetOwner;
(function (PortfolioAssetOwner) {
    PortfolioAssetOwner["CUSTOMER"] = "CUSTOMER";
    PortfolioAssetOwner["EMPLOYER"] = "EMPLOYER";
})(PortfolioAssetOwner || (PortfolioAssetOwner = {}));
export var PortfolioStatus;
(function (PortfolioStatus) {
    PortfolioStatus["NEW"] = "NEW";
    PortfolioStatus["PENDING_ACTIVATION"] = "PENDING_ACTIVATION";
    PortfolioStatus["ACTIVE"] = "ACTIVE";
    PortfolioStatus["SUSPENDED"] = "SUSPENDED";
    PortfolioStatus["CLOSED"] = "CLOSED";
    PortfolioStatus["ARCHIVED"] = "ARCHIVED";
    PortfolioStatus["EXITED"] = "EXITED";
})(PortfolioStatus || (PortfolioStatus = {}));
export var portfolioStatusInvited = 'INVITED';
export var ContractStatus;
(function (ContractStatus) {
    ContractStatus["SIGNED"] = "SIGNED";
    ContractStatus["PENDING"] = "PENDING";
    ContractStatus["IN_REVIEW"] = "IN_REVIEW";
    ContractStatus["NOT_SIGNED"] = "NOT_SIGNED";
})(ContractStatus || (ContractStatus = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["NOT_STARTED"] = "NOT_STARTED";
    PaymentStatus["SET_UP"] = "SET_UP";
    PaymentStatus["RECEIVED"] = "RECEIVED";
})(PaymentStatus || (PaymentStatus = {}));
