import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';
import type { DraftPortfolio } from '~/modules/portfolio/types';
import { QuestionnaireInvestments } from '~/modules/onboarding/types';
import { REFERRAL_CODE_KEY } from '~/modules/auth/SessionProvider';
import { VOUCHER_KEY } from '~/modules/gift/voucher/VoucherRedeem';
import { isDev } from '.';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import { BeneficiaryType, differenceInMonths, Portfolio } from '@grunfin/ui-kit';

export const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
const gaMeasurementId = import.meta.env.VITE_GA_TOKEN;

export const initMixpanel = () => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, { api_host: 'https://api-eu.mixpanel.com', ignore_dnt: true });
    // We add a session token to make sure that mixpanel has been initialized
    // Mixpanel crashes when 'mixpanel.track' has been called without calling 'mixpanel.init' first
    sessionStorage.setItem('mixpanel_init', 'true');
  } else if (isDev) console.log('Could not initialize mixpanel because token is missing');
};

const gtagDisabled = () => !('gtag' in window) || !window.gtag;

const track = (event: string, props?: { [key: string]: any }) => {
  // Only allow mixpanel tracking when user has consented
  const consentCookie = Cookies.get('CookieScriptConsent');
  try {
    const cookie = JSON.parse(consentCookie ?? '{}');
    // Only allow tracking if mixpanel is initialized
    const mixpanel_init = sessionStorage.getItem('mixpanel_init');
    try {
      if (cookie && mixpanelToken && 'action' in cookie && cookie.action === 'accept' && mixpanel_init === 'true')
        mixpanel.track(event, props);
      else if (mixpanelToken == null && isDev) console.log('Sending MP event: ', event, props);
    } catch (err) {
      // essentially, when we arrive here, it means that the cookie flow has broken
      captureException(err);
    }
  } catch (e) {
    Sentry.captureMessage(e instanceof Error ? e.message : 'error parsing consent cookie', {
      extra: { consentCookie },
      level: 'warning',
    });
    Cookies.remove('CookieScriptConsent');
  }
};
export const trackPWAInstallPrompt = () => {
  if (gtagDisabled()) return;

  window.gtag('event', 'pwa_prompt', {});
  track('Browser triggered pwa install prompt', {});
};
export const trackAppInstall = (outcome: any) => {
  if (gtagDisabled()) return;

  window.gtag('event', 'add_to_homescreen', outcome);
  track('User added App to home screen', outcome);
};

export const trackAppInstalled = () => {
  if (gtagDisabled()) return;

  window.gtag('event', 'app_installed');
  track('Browser triggered app installed event');
};
export const trackLoginInitiated = (type: 'Email' | 'Google' | 'Apple') => track('User initiated login', { type });
export const trackSignupInitiated = (type: 'Email' | 'Google' | 'Apple') =>
  track('User initiated signup with: ' + type, { type });

export const trackException = (error: string) => {
  if (gtagDisabled()) return;

  const message = 'exception ' + error;
  window.gtag('event', message, {
    description: error,
    fatal: false,
  });
  track(message, { error });
};

export const identifyUser = (userId: string) => {
  if (gtagDisabled() || !gaMeasurementId) return;

  window.gtag('config', gaMeasurementId, {
    user_id: userId,
  });

  try {
    mixpanelToken && mixpanel.identify(userId);
  } catch (err) {
    if (isDev) console.error('Error identifying user', err);
  }
};

export const trackLogout = () => track('User logged out');

export const trackQuestionnaireAnswer = (
  field: string,
  type: string,
  value: string | string[] | boolean | QuestionnaireInvestments,
  page: number,
) => {
  track(`Customer answered question: ${field}`, {
    field,
    type,
    value: typeof value === 'object' ? JSON.stringify(value) : value?.toString(),
    page,
  });
};

export const trackPlaygroundUpdate = (values: DraftPortfolio) =>
  track('Customer updated playground values', {
    monthlyInvestmentAmountEur: values.monthlyInvestmentAmountEur,
    periodInYears: values.periodInYears?.toString(),
    upfrontInvestmentAmountEur: values.upfrontInvestmentAmountEur,
    preferredRisk: values.preferredRisk,
  });

export const trackPortfolioDelete = (portfolioId: string) => track('Customer deleted portfolio', { portfolioId });

export const trackPortfolioExit = (portfolioId: string) => track('Customer initiated portfolio exit', { portfolioId });

export const trackActivationSteps = (step: string, portfolioId: string) =>
  track(`Customer entered activation flow step: ${step}`, { step, portfolioId });

export const trackContractSigning = () => track(`Customer initiated signing with: Click Accept`);
export const trackEmployeeContractSigning = (membershipId: string) =>
  track(`Customer initiated signing employee contract`, { membershipId });

export const trackReferralShare = (method: string) =>
  track(`Customer initiated referring by method: ${method}`, { method });

export const trackContributionShare = (method: string) =>
  track(`Customer initiated contribution sharing by method: ${method}`, { method });

export const trackWhitepaperOpen = (link: string) => track(`Customer opened ETF whitepaper: ${link}`, { link });

export const trackEmailCollection = (email: string) =>
  track(`Customer email was successfully collected in onboarding`, { email });

export const trackNavigation = (url: string) => {
  const lastPathElement = url?.split('/').pop();
  track(`User navigated to app path`, { lastPathElement, url });

  if (gtagDisabled() || !gaMeasurementId) return;
  window.gtag('config', gaMeasurementId, { page_location: url });
  window.gtag('event', 'page_view', { page_location: url });

  if (!('fbq' in window)) return;
  window.fbq('track', 'PageView');
};

export const trackPortfolioActivation = (portfolioId: string) =>
  track('Customer clicked activate button', { portfolioId });

export const trackDirectDebitRevoke = (portfolioId: string, reason: string) =>
  track('Customer clicked revoke direct debit button', { portfolioId, reason });

export const trackRedeem = (voucherCode: string) => track('Customer redeemed voucher', { voucherCode });
export const trackRedeemReferral = (referralCode: string) => track('Customer redeemed referral', { referralCode });

export const trackStartEmployeeEnrollment = (membershipId: string) =>
  track('Customer initiated employee enrollment flow', { membershipId });

const calculateFees = (portfolio: Portfolio) => {
  if (portfolio.endDate == null || portfolio.upfrontInvestmentAmountEur == null) return undefined;
  let months = differenceInMonths(Date.parse(portfolio.endDate), Date.now());
  months = portfolio.upfrontInvestmentAmountEur < 1000 ? months - 12 : months;
  const revenue = months * 3.9;
  return revenue > 0 ? revenue : 0;
};

export const trackAddToCart = (portfolio?: Portfolio) => {
  if ('gtag' in window && portfolio && !!window.gtag) {
    const body = generateGtagBody(portfolio);
    window.gtag('event', 'add_to_cart', body);
    window.gtag('event', 'conversion', {
      send_to: 'AW-10812191418/vvMFCPGgv6ADELrl06Mo',
      value: body.value != null ? (body.value > 2000 ? 500 : body.value) : undefined, //Don't send large value for people who are testing big numbers
      currency: 'EUR',
    });
  }

  if ('fbq' in window && portfolio) {
    window.fbq('track', 'AddToCart', generateFbBody(portfolio));
  }
};

export const trackBeginCheckout = (portfolio?: Portfolio) => {
  if (portfolio?.beneficiaryType !== BeneficiaryType.EMPLOYEE) trackActivationSteps('details', portfolio?.id || '');

  if (gtagDisabled() || !portfolio) return;

  const body = generateGtagBody(portfolio);
  window.gtag('event', 'begin_checkout', body);
  window.gtag('event', 'conversion', {
    send_to: 'AW-10812191418/Zi2ZCMvUx6ADELrl06Mo',
    value: body.value,
    currency: 'EUR',
  });

  if (!('fbq' in window)) return;
  window.fbq('track', 'InitiateCheckout', generateFbBody(portfolio));
};

export const trackPurchase = (portfolio?: Portfolio) => {
  if (!portfolio) return;

  if (!gtagDisabled()) {
    'atag' in window &&
      window.atag('track', 'conversion', {
        order_id: portfolio?.id,
      });
  }

  if (!gtagDisabled()) {
    const body = generateGtagBody(portfolio);
    window.gtag('event', 'purchase', body);
    window.gtag('event', 'conversion', {
      send_to: 'AW-10812191418/gJeNCI7cx6ADELrl06Mo',
      value: body.value,
      transaction_id: body.transaction_id,
      currency: 'EUR',
    });
  }

  if ('fbq' in window) window.fbq('track', 'Purchase', generateFbBody(portfolio));
};

export const trackNewSignUp = () => {
  if (gtagDisabled()) return;

  window.gtag('event', 'sign_up');
  window.gtag('event', 'conversion', {
    send_to: 'AW-10812191418/TCo-CP3T7YYDELrl06Mo',
  });

  if (!('fbq' in window)) return;
  window.fbq('track', 'CompleteRegistration');
};

function generateFbBody(portfolio: Portfolio) {
  return {
    content_type: 'portfolio',
    content_name: portfolio.focus + ' - ' + portfolio.beneficiaryType,
    content_ids: [portfolio.id],
    value: calculateFees(portfolio),
    currency: 'EUR',
  };
}

const generateGtagBody = (portfolio: Portfolio) => {
  const referralCode = localStorage.getItem(REFERRAL_CODE_KEY) || localStorage.getItem(VOUCHER_KEY);
  return {
    currency: 'EUR',
    transaction_id: portfolio.id,
    value: calculateFees(portfolio),
    items: [
      {
        item_id: portfolio.id,
        coupon: referralCode,
        quantity: 1,
        price: calculateFees(portfolio),
        item_variant: portfolio.focus,
      },
    ],
  };
};
