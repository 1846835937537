import {
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  FocusArea,
  Message,
  MessageLevel,
  Portfolio,
  PortfolioStatus,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import 'twin.macro';
import { VOUCHER_KEY } from '~/modules/gift/voucher/VoucherRedeem';
import { useSubmitQuestionnaire } from '~/modules/onboarding/queries';
import { Answers, MifidQuestionnaire, PortfolioQuestionnaire } from '~/modules/onboarding/types';
import { useCreatePortfolio } from '~/modules/portfolio/queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { trackAddToCart } from '~/utils/tracking-analytics';
import { EmployeeOnboardingOutletContext } from './EmployeeOnboardingView';
import useQuestions from './useQuestions';

const isBooleanString = (input: string) => input === 'true' || input === 'false';
const stringToBoolean = (input: string) => input === 'true';

export type EmployeeQuestionnaire = Partial<MifidQuestionnaire> & Partial<PortfolioQuestionnaire>;

interface QuestionnaireProps {
  answers: Answers;
}

const requiredFields = [
  'investmentTerm',
  ['experienceFunds', 'experienceShares', 'experienceNoInvestmentExperience'],
  'pastPerformance',
  'wouldWithdrawOnValueDrop',
  'preferredRisk',
  'moreDebtThanAssets',
];

const checkIsReadyToSubmit = (values: EmployeeQuestionnaire) => {
  let isReadyToSubmit = true;
  requiredFields.forEach((key) => {
    if (Array.isArray(key)) {
      if (!key.some((k) => values[k as keyof EmployeeQuestionnaire] === true)) isReadyToSubmit = false;
    } else if (
      values[key as keyof EmployeeQuestionnaire] == null ||
      values[key as keyof EmployeeQuestionnaire] == undefined
    )
      isReadyToSubmit = false;
  });
  return isReadyToSubmit;
};

const EmployeeOnboardingQuestionnaire = (props: QuestionnaireProps) => {
  const { answers } = props;
  const context = useOutletContext<EmployeeOnboardingOutletContext>();
  const { onContinue, setCreatedPortfolioId } = context ?? {};
  const { t } = useTranslation('onboarding');
  const createPortfolio = useCreatePortfolio('employee-portfolio');
  const submitAnswers = useSubmitQuestionnaire();
  const [values, setValues] = useState<EmployeeQuestionnaire>(answers.mifid ?? {});
  const isReadyToSubmit = checkIsReadyToSubmit(values);

  const handleKeyValueUpdate = (key: string, value: any) => {
    const val = isBooleanString(value) ? stringToBoolean(value) : value;
    setValues((prevState) => ({ ...prevState, [key]: val }));
  };

  const handleTargetValueUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleKeyValueUpdate(name, value);
  };

  // investmentTerm and preferredRisk can be set from /enroll request

  // Anything from context to values
  // This prevents local state not being set as when the state is updated from context
  const { investmentTerm: ctxInvestmentTerm } = context;
  useEffect(() => {
    if (ctxInvestmentTerm != null) handleKeyValueUpdate('investmentTerm', ctxInvestmentTerm);
  }, [ctxInvestmentTerm]);

  const { preferredRisk: answersPreferredRisk } = answers.portfolio;
  useEffect(() => {
    if (answersPreferredRisk != null) handleKeyValueUpdate('preferredRisk', answersPreferredRisk);
  }, [answersPreferredRisk]);

  const questions = useQuestions({ values, setValues, handleTargetValueUpdate });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isReadyToSubmit) return;

    const portfolioBody: PortfolioQuestionnaire = {
      ...answers.portfolio,
      investmentTerm: values.investmentTerm ?? null,
      preferredRisk: values.preferredRisk ?? null,
      portfolioType: BeneficiaryType.EMPLOYEE,
      // Focus area comes from backend request 'enroll'
      areaClimateChange: (context.focuses ?? []).some((el) => [FocusArea.CLIMATE_CHANGE, 'CLIMATE'].includes(el)),
      areaGenderEquality: (context.focuses ?? []).some((el) =>
        [FocusArea.GENDER_EQUALITY, 'GENDER_EQUALITY'].includes(el),
      ),
      areaHealth: (context.focuses ?? []).some((el) => [FocusArea.HEALTH, 'HEALTH'].includes(el)),
      areaSP500: (context.focuses ?? []).some((el) => [FocusArea.SP500, 'SP500'].includes(el)),
    };
    await submitAnswers.mutateAsync({ type: 'mifid', id: answers.mifid.id, body: values });
    await submitAnswers.mutateAsync({ type: 'employee-portfolio', id: answers.portfolio?.id, body: portfolioBody });
    let portfolio: Portfolio | null = null;

    // Only create portfolio if there is no portfolio status or it is NEW
    if (context.portfolioStatus == null || context.portfolioStatus === PortfolioStatus.NEW)
      portfolio = await createPortfolio.mutateAsync();
    localStorage.removeItem(VOUCHER_KEY);

    if (portfolio != null) trackAddToCart(portfolio);
    if (typeof setCreatedPortfolioId === 'function' && portfolio != null) setCreatedPortfolioId(portfolio.id);
    if (typeof onContinue === 'function') onContinue();
  };

  return (
    <main>
      <form onSubmit={handleSubmit} tw="flex flex-col gap-12 md:gap-20 max-w-xl">
        {questions.map((question, index) => {
          return (
            <section key={index} tw="flex flex-col gap-4">
              <div tw="flex flex-col gap-4">
                <h2 tw="text-base md:text-lg text-gray-900">
                  <Trans
                    value={question.title}
                    t={t}
                    components={[<span key="0" tw="text-sky-blue-400" />, <span key="1" tw="text-sky-blue-400" />]}
                  >
                    {question.title}
                  </Trans>
                </h2>
                {question.description && <p tw="text-gray-900 text-sm">{question.description}</p>}
                {question.warningMessage && typeof question.warningMessage === 'object' && question.warningMessage}
                {question.warningMessage && typeof question.warningMessage === 'string' && (
                  <Message level={MessageLevel.WARN}>{question.warningMessage}</Message>
                )}
              </div>
              {question.component}
            </section>
          );
        })}
        {submitAnswers.isError && (
          <ContactSupportOverlay
            title={t('questionnaire.submit_answer_error')}
            buttonText={t('close', { ns: 'general' })}
            onClose={submitAnswers.reset}
            error={submitAnswers.error}
          />
        )}
        {createPortfolio.isError && (
          <ContactSupportOverlay
            title={t('questionnaire.draft_portfolio_error')}
            buttonText={t('close', { ns: 'general' })}
            onClose={createPortfolio.reset}
            error={createPortfolio.error}
          />
        )}
        <div tw="max-w-max">
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            type="submit"
            disabled={!isReadyToSubmit}
            data-test-id="onboarding-continue"
          >
            {t('continue', { ns: 'general' })}
          </Button>
        </div>
      </form>
    </main>
  );
};

export default EmployeeOnboardingQuestionnaire;
