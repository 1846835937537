export const downloadFileFromObjectUrl = (objectUrl: string, filename: string) => {
  const a = document.createElement('a');
  a.href = objectUrl;
  a.download = filename;
  document.body.appendChild(a); // append the element to the dom, otherwise it won't work in Firefox
  a.click();
  a.remove(); // afterwards, remove the element
};

export const getInitialTaxResidency = (langCode: string) => {
  if (langCode === 'et') return 'EE';
  if (langCode === 'de') return langCode.toUpperCase();
  return '';
};
