import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import 'twin.macro';

import { DirectDebit } from './DirectDebit';
import { StandingOrder } from './StandingOrder';

export const SetUp = () => {
  return (
    <div tw="flex flex-col md:w-3/5 md:self-center">
      <Outlet />
      <Routes>
        <Route path="direct-debit" element={<DirectDebit />} />
        <Route path="standing-order" element={<StandingOrder />} />
        <Route path="*" element={<Navigate to="standing-order" replace />} />
      </Routes>
    </div>
  );
};
