var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGoogleIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 18, height: 18, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M8.99996 3.5625C10.3275 3.5625 11.5162 4.02 12.4537 4.9125L15.0225 2.34375C13.4625 0.8925 11.4262 0 8.99996 0C5.48246 0 2.44121 2.0175 0.959961 4.9575L3.95246 7.27875C4.66121 5.145 6.65246 3.5625 8.99996 3.5625Z", fill: "#EA4335" }),
    React.createElement("path", { d: "M17.6175 9.20625C17.6175 8.6175 17.5612 8.0475 17.475 7.5H9V10.8825H13.8525C13.635 11.9925 13.005 12.9375 12.06 13.575L14.9588 15.825C16.65 14.2575 17.6175 11.94 17.6175 9.20625Z", fill: "#4285F4" }),
    React.createElement("path", { d: "M3.94875 10.7208C3.76875 10.177 3.66375 9.59953 3.66375 8.99953C3.66375 8.39953 3.765 7.82203 3.94875 7.27828L0.95625 4.95703C0.345 6.17203 0 7.54453 0 8.99953C0 10.4545 0.345 11.827 0.96 13.042L3.94875 10.7208Z", fill: "#FBBC05" }),
    React.createElement("path", { d: "M8.99981 18.0003C11.4298 18.0003 13.4736 17.2015 14.9586 15.8215L12.0598 13.5715C11.2536 14.1153 10.2148 14.434 8.99981 14.434C6.6523 14.434 4.66105 12.8515 3.94855 10.7178L0.956055 13.039C2.44105 15.9828 5.48231 18.0003 8.99981 18.0003Z", fill: "#34A853" }))); };
var ForwardRef = forwardRef(SvgGoogleIcon);
var Memo = memo(ForwardRef);
export default Memo;
