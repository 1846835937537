import { BankButtons, Button, ButtonVariant, ChevronIcon, Spinner, Trans, useTranslation } from '@grunfin/ui-kit';
import { ReactNode, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation, useMatch, useOutletContext } from 'react-router-dom';
import 'twin.macro';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from '~/modules/auth/SessionProvider';
import {
  ActivationLink,
  activationMatchHash,
  ActivationViewType,
  getBaseRouteWithParams,
} from '~/modules/portfolio/ActivateView/ActivationView';
import { StandingOrderInstruction } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { PaymentInformation } from './PaymentInformation';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';

interface InitialPaymentMethodProps {
  orderInstructions: UseQueryResult<StandingOrderInstruction, Error>;
  onNext: () => void;
}

enum ListItemIdentifier {
  DETAILS = 'DETAILS',
  NOTICE = 'NOTICE',
}

type ListItem = {
  id: ListItemIdentifier;
  title?: string;
  component?: ReactNode;
};

export const InitialPaymentMethod = ({ orderInstructions, onNext }: InitialPaymentMethodProps) => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onBack } = context ?? {};
  const { t, i18n } = useTranslation('portfolio');
  const session = useSession();
  const [isOpen, setIsOpen] = useState(i18n.language === 'de');
  const { pathname } = useLocation();
  const match = useMatch(activationMatchHash[ActivationViewType.PORTFOLIO]);
  // Only redirect to monthly payment if the customer has set a monthly contribution
  const successRedirectStep =
    orderInstructions.data?.monthlyInvestmentAmount != null && orderInstructions.data?.monthlyInvestmentAmount > 0
      ? ActivationLink.MONTHLY_PAYMENT
      : ActivationLink.COMPLETED;
  const origin = window.location.origin;
  const href = window.location.href;

  const successUrl =
    match?.params.id != null
      ? origin + getBaseRouteWithParams(pathname, match?.params.id as string, successRedirectStep)
      : href.split('?')?.[0];
  const cancelUrl =
    match?.params.id != null
      ? origin + getBaseRouteWithParams(pathname, match?.params.id as string, ActivationLink.INITIAL_PAYMENT)
      : href.split('?')?.[0];

  if (orderInstructions.isIdle || orderInstructions.isLoading) {
    return (
      <div tw="flex items-center justify-center text-alps-blue-700" css={{ minHeight: 220 }}>
        <Spinner width="64" height="64" />
      </div>
    );
  }

  if (orderInstructions.isError) {
    return (
      <ContactSupportOverlay
        title={t('activation.payment.initial_payment.error')}
        onClose={orderInstructions.refetch}
        error={orderInstructions.error}
      />
    );
  }

  const BankDetails = () => (
    <>
      <div
        tw="flex items-center my-4 cursor-pointer select-none"
        onClick={() => setIsOpen(!isOpen)}
        data-test-id="not_listed"
      >
        <ChevronIcon key="arrow" width={16} height={16} tw="rounded-full min-w-[16px] -rotate-90 bg-gray-50" />
        <p tw="ml-4">{t('activation.payment.initial_payment.not_listed')}</p>
      </div>

      {isOpen && orderInstructions.data && (
        <div>
          <div tw="rounded-lg my-4 bg-green-50 p-4 md:p-8 max-w-lg transition-transform">
            <p tw="font-bold mb-6">{t('activation.payment.initial_payment.manual')}</p>
            <PaymentInformation {...orderInstructions.data} isUpfront={true} />
          </div>
          <div tw="mt-12">
            <div tw="flex flex-row w-full">
              <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
                {t('back', { ns: 'general' })}
              </Button>
              <Button
                variant={ButtonVariant.NEW_PRIMARY}
                onClick={onNext}
                data-test-id="payment-done"
                tw="ml-auto max-w-max"
              >
                {t('activation.payment.initial_payment.proceed')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const defaultList: ListItem[] = [
    {
      id: ListItemIdentifier.DETAILS,
      component: (
        <div style={{ maxWidth: 500 }}>
          {i18n.language !== 'de' && (
            <BankButtons
              referenceNumber={orderInstructions?.data?.referenceNumber}
              paymentCode={'Portfolio: ' + orderInstructions?.data?.referenceNumber + ', id: ' + uuidv4()}
              email={session?.email || ''}
              amount={orderInstructions?.data?.upfrontInvestmentAmount}
              language={i18n.language}
              redirectUrl={successUrl}
              cancelUrl={cancelUrl}
            />
          )}

          <BankDetails />
        </div>
      ),
    },
  ];

  return (
    <div tw="flex flex-col gap-4 mt-9">
      <ol tw="flex flex-col gap-8">
        {defaultList.map((item, idx) => {
          return (
            <li key={idx} tw="flex flex-col gap-6">
              {item.title && (
                <div tw="flex flex-row items-start gap-4">
                  <h3 tw="text-lg text-gray-900 select-none">
                    <Trans i18nKey={item.title} />
                  </h3>
                </div>
              )}
              {item.component != null && item.component}
            </li>
          );
        })}
      </ol>
    </div>
  );
};
