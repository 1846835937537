var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { ErrorOverlay, formatError } from './ErrorOverlay';
//import { trackException } from '@grunfin/app/src/utils/tracking-analytics';
import * as Sentry from '@sentry/browser';
/**
 * Error boundary wrapper
 */
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { error: undefined };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return { error: error };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error /* , info: { componentStack: string } */) {
        Sentry && (Sentry === null || Sentry === void 0 ? void 0 : Sentry.captureMessage('Error page - ' + window.location.href + ' Error: ' + formatError(error)));
        //trackException(formatError(error));
    };
    ErrorBoundary.prototype.render = function () {
        var error = this.state.error;
        var _a = this.props, children = _a.children, fallback = _a.fallback, _b = _a.title, title = _b === void 0 ? 'An unexpected error has occurred.' : _b, _c = _a.message, message = _c === void 0 ? 'If this problem persists, please contact support.' : _c;
        if (!error)
            return children;
        if (typeof fallback === 'function')
            return fallback(error);
        if (fallback)
            return fallback;
        return (React.createElement(ErrorOverlay, { title: title, buttonText: "Reload", error: error, onClose: function () { return window.location.reload(); } }, message));
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
