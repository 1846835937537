import tw from 'twin.macro';

const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// for some reason using tailwind classes here doesn't work here
// so i replaced them with plain color strings that are copied straight from 'tailwind-preset'
const backgrounds: { [key: string]: string } = {
  A: '#99C5C8',
  B: '#76A8B0',
  C: '#8FD1E0',
  D: '#4BB6CD',
  E: '#A9CBA8',
  F: '#92BA92',
  G: '#EEB4B0',
  H: '#E6948E',
  I: '#E4B816',
  J: '#E09431',
  K: '#4D878F',
  L: '#3187A0',
  M: '#749B74',
  N: '#C06A64',
  O: '#DC704B',
  P: '#213421',
  Q: '#A14741',
  R: '#81231D',
  S: '#611B16',
  T: '#41120F',
  U: '#D54C43',
  V: '#AB4137',
  W: '#6B3124',
  X: '#362917',
  Y: '#D9A64A',
  Z: '#B58A3D',
  DEFAULT: '#000',
};

export enum AvatarVariant {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

const Employee = ({ name, variant = AvatarVariant.SMALL }: { name: string; variant?: AvatarVariant }) => {
  const initials = getInitials(name);

  // select a color based on the initials
  let background;
  if (initials) {
    const [firstChar, secondChar] = initials.split('');
    if (firstChar) background = backgrounds[firstChar] ?? backgrounds.DEFAULT;
    else if (secondChar) background = backgrounds[secondChar] ?? backgrounds.DEFAULT;
    else background = backgrounds.DEFAULT;
  }

  return (
    <div
      tw="flex items-center"
      css={[
        variant === AvatarVariant.LARGE && tw`flex-col gap-4`,
        variant === AvatarVariant.SMALL && tw`flex-row gap-2`,
      ]}
    >
      <span
        style={{ background /* width: 24, height: 24, minWidth: 24, minHeight: 24 */ }}
        tw="text-white flex items-center justify-center rounded-full"
        css={[
          variant === AvatarVariant.LARGE && tw`text-4xl w-24 h-24 font-light`,
          variant === AvatarVariant.SMALL && tw`text-sm w-6 h-6`,
        ]}
      >
        {initials}
      </span>
      <p
        css={[
          variant === AvatarVariant.LARGE && tw`text-lg font-semibold text-sky-blue-700`,
          variant === AvatarVariant.SMALL && tw`text-base text-gray-900`,
        ]}
      >
        {name}
      </p>
    </div>
  );
};

export default Employee;
