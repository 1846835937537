var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { twMerge } from 'tailwind-merge';
/**
 * Grouping helper for radio buttons
 */
export var RadioGroup = function (_a) {
    var className = _a.className, name = _a.name, children = _a.children, onChange = _a.onChange, disabled = _a.disabled, selectedValue = _a.selectedValue;
    return (React.createElement("div", { role: "radiogroup", className: className }, React.Children.map(children, function (child) {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, __assign(__assign({}, child.props), { name: name, onChange: onChange, checked: child.props.value === selectedValue, disabled: disabled || child.props.disabled }));
        }
    })));
};
/**
 * Radio button
 */
export var Radio = React.forwardRef(function (_a, ref) {
    var label = _a.label, children = _a.children, disabled = _a.disabled, error = _a.error, props = __rest(_a, ["label", "children", "disabled", "error"]);
    var labelClass = twMerge("flex items-center my-4 font-semibold cursor-pointer", disabled && 'cursor-not-allowed');
    var inputClass = twMerge("appearance-none outline-none rounded-full flex-shrink-0 border-8 w-7 h-7 m-0.5 mr-4 cursor-pointer disabled:cursor-not-allowed border-white ring-2 ring-gray-200 focus:ring-alps-blue-200 checked:bg-gray-900", disabled && 'checked:bg-white checked:border-gray-200', error && 'ring-dawn-200 focus:ring-dawn-400');
    return (React.createElement("label", { className: labelClass },
        React.createElement("input", __assign({ ref: ref, disabled: disabled, className: inputClass }, props, { role: "radio", type: "radio" })),
        label,
        children));
});
Radio.displayName = 'Radio';
export var BooleanRadio = React.forwardRef(function (_a, ref) {
    var value = _a.value, onChange = _a.onChange, _b = _a.labelTrue, labelTrue = _b === void 0 ? 'Yes' : _b, _c = _a.labelFalse, labelFalse = _c === void 0 ? 'No' : _c, props = __rest(_a, ["value", "onChange", "labelTrue", "labelFalse"]);
    return (React.createElement("div", { className: "flex flex-row flex-wrap gap-4 md:gap-8" },
        React.createElement(Radio, __assign({}, props, { ref: value !== false ? ref : undefined, label: labelTrue, checked: value === true, onChange: function () { return onChange(true); } })),
        React.createElement(Radio, __assign({}, props, { ref: value === false ? ref : undefined, label: labelFalse, checked: value === false, onChange: function () { return onChange(false); } }))));
});
BooleanRadio.displayName = 'BooleanRadio';
