import { Spinner, TimeIcon, toast, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';

import { PersonalDetailsForm } from './PersonalDetailsForm';
import { useGetCustomer, useSubmitCustomer } from './queries';
import { useSessionQuery } from '~/modules/auth/queries';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';

export function AccountPersonalDetailsView() {
  const { t } = useTranslation('account');
  const submitCustomer = useSubmitCustomer();
  const getCustomer = useGetCustomer();
  const session = useSessionQuery();
  const queryClient = useQueryClient();

  if (getCustomer.isIdle || getCustomer.isLoading) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (getCustomer.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={getCustomer.refetch} error={getCustomer.error} />;
  }

  return (
    <div tw="m-6 md:m-12 font-medium relative">
      <div tw="text-alps-blue-700 text-lg md:text-xl mb-8 md:mb-12 font-bold text-center md:text-left md:font-medium">
        {t('details.title')}
      </div>
      {submitCustomer.isLoading && (
        <div tw="absolute bg-white opacity-80 w-full h-full top-0 left-0 z-10">
          <Spinner />
        </div>
      )}
      {session.data && dayjs(session.data.updatedAt).isBefore(dayjs().subtract(1, 'year')) && (
        <div tw="flex-row md:flex items-center md:justify-between gap-4 rounded shadow-md p-4 md:p-6 mb-8 md:mb-12">
          <div tw="inline-flex items-center">
            <TimeIcon width={36} height={36} tw="bg-alps-blue-50 text-alps-blue-700 rounded" />
            <p tw=" ml-3">{t('details.update.notification')}</p>
          </div>
        </div>
      )}
      <PersonalDetailsForm
        defaultValues={getCustomer.data}
        onSubmit={async (values, setError) => {
          try {
            await submitCustomer.mutateAsync(values);
            await queryClient.invalidateQueries('session'); //Clear session, to get new updatedAt date
            toast.success(t('details.update.success'));
          } catch (err) {
            if (err instanceof Error) setBackendValidationErrors(err, setError);
          }
        }}
      />
    </div>
  );
}
