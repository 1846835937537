import {
  BankIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  FAQ,
  FAQQuestion,
  formatCurrency,
  Reviews,
  RocketIcon,
  Trans,
  TreesIcon,
  TrustBlock,
  useMedia,
  useTranslation,
} from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { UseQueryResult } from 'react-query';
import { ReferralProgramInfo } from '~/modules/referral/types';
import mobileHeroImage from '~/assets/output/mobile-referral-hero.webp';
import heroImage from '~/assets/output/referral_personal_hero.webp';

type Props = {
  referralInfo?: UseQueryResult<ReferralProgramInfo, Error>;
};

export const PersonalReferral = ({ referralInfo }: Props) => {
  const resolvedLanguage = useTranslation().i18n.resolvedLanguage;
  const { t } = useTranslation('referral');
  const navigate = useNavigate();
  const referrerName = referralInfo?.data?.creatorGivenName;
  const reward = referralInfo?.data?.reward;
  const productShot = new URL(`../../assets/output/product-tilted-${resolvedLanguage}.webp`, import.meta.url).href;
  const disabled = referralInfo?.isError || !referralInfo?.data?.canJoin;
  const questions = t('questions', { returnObjects: true }) as FAQQuestion[];
  const isMobile = useMedia('(max-width: 768px)');

  const SetUp = () => (
    <div tw="mt-10 md:w-full max-w-md md:max-w-min whitespace-nowrap">
      <Button
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={() => navigate('/onboarding')}
        disabled={disabled}
        tw="text-alps-blue-100 rounded-2xl"
      >
        {disabled ? t('invalid_code') : t('personal.cta', { reward: formatCurrency(parseInt(reward || '')) })}
      </Button>
    </div>
  );

  const HeroContent = () => (
    <div tw=" text-center md:text-left pb-2 md:pb-0 mx-6 md:mx-0 mt-2 md:mt-0">
      <h1 tw="text-5xl font-light mb-10 text-alps-blue-600 md:text-white">
        {referrerName} {t('personal.title')}
      </h1>

      <p tw="text-alps-blue-700 md:text-white font-semibold">{t('personal.description_1')}</p>
      <p tw="text-alps-blue-700 md:text-white font-semibold">
        {t('personal.description_2', { reward: formatCurrency(parseInt(reward ?? '')) })}
      </p>
    </div>
  );

  const getStatementBlock = (background: string, title: string, description: string, icon: JSX.Element) => (
    <div tw="flex flex-row-reverse md:col-span-2 md:block relative justify-center px-6 mb-14 md:mb-24">
      <div tw="absolute z-10 right-[13%] bottom-[30%] md:left-[41%] md:top-[24%]">{icon}</div>
      <div tw="flex justify-center max-w-5xl md:pt-[75%] relative blur-2xl min-w-[30%]" style={{ background }} />
      <div tw="mr-8">
        <h3 tw="text-lg md:text-xl text-left md:text-center text-alps-blue-600 font-semibold md:pt-16">{t(title)}</h3>
        <p tw="text-left md:text-center font-medium mt-3">{t(description)}</p>
      </div>
    </div>
  );

  return (
    <div tw="flex flex-col">
      <div tw="md:hidden pb-8">
        <HeroContent />
      </div>
      <div tw="flex justify-center md:justify-start mt-20 md:mt-0">
        <div
          tw="flex justify-center md:justify-start px-32 relative h-[320px] w-[320px] md:h-auto md:w-auto"
          style={{ backgroundImage: `url('${isMobile ? mobileHeroImage : heroImage}')`, borderRadius: '240px' }}
        >
          <img src={productShot} tw="max-h-80 -mt-20 md:-mt-28 absolute min-h-[140%]" alt={t('product_image')} />
          <div tw="ml-72 py-20 hidden md:block">
            <HeroContent />
            <div tw="hidden md:block">
              <SetUp />
            </div>
          </div>
        </div>
      </div>

      <div tw="md:hidden flex justify-center pt-16">
        <SetUp />
      </div>

      <div tw="mt-16 md:mt-32 md:mx-12">
        <TrustBlock reverse />
      </div>

      <h2 tw="text-4xl md:text-6xl text-alps-blue-600 text-center my-32 md:my-48 mx-12 font-light">
        <Trans i18nKey="impact_title" components={[<span tw="text-alps-blue-300" key="0" />]} t={t} />
      </h2>
      <div tw="grid md:grid-cols-6 gap-x-12 md:mb-24">
        {getStatementBlock(
          'linear-gradient(180deg, #CFE7CC 0%, #F7EBAB 100%)',
          'statements.title_one',
          'statements.description_one',
          <TreesIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #F7EBAB 0%, #F2C7C5 100%)',
          'statements.title_two',
          'statements.description_two',
          <BankIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #F7DBDA 0%, #C7E8F0 100%)',
          'statements.title_three',
          'statements.description_three',
          <RocketIcon width="48" height="48" />,
        )}
      </div>

      <div tw="flex flex-col justify-center items-center px-4">
        <FAQ questions={questions} />
      </div>

      <div tw="flex flex-col justify-center items-center text-sm mt-10 mb-24">
        <Button
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          className="rounded-full"
          onClick={() => navigate('/onboarding')}
        >
          {t('block_cta', { reward: formatCurrency(parseInt(reward || '')) })}
        </Button>
        <p tw="mt-6 px-6 text-center">{t('cta_description', { amount: formatCurrency(20) })}</p>
      </div>

      <Reviews />

      <div tw="flex justify-center pt-8 md:mt-12 mb-14 px-6">
        <div tw="text-sm text-gray-400 text-center max-w-md md:max-w-2xl">
          <Trans
            i18nKey="review_terms"
            components={[
              <a key="0" tw="underline" href={t('general_terms_link')} target="_blank" rel="noreferrer" />,
              <a key="1" tw="underline" href={t('referral_terms_link')} target="_blank" rel="noreferrer" />,
            ]}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};
