import {
  Button,
  ButtonVariant,
  ClipboardIcon,
  EmailIcon,
  FacebookIcon,
  InfoIcon,
  Input,
  Spinner,
  toast,
  Tooltip,
  TwitterIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { ChangeEvent, useEffect, useState } from 'react';
import 'twin.macro';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';

import { trackContributionShare } from '~/utils/tracking-analytics';
import { Heading } from '../components/Heading';
import { usePortfolio } from '../hooks';
import { useGetPortfolioContributionSettings, useUpdatePortfolioContributionSettings } from '../queries';
import giftBg from '~/assets/output/get_gifts_bg.webp';

export const ContributionSettings = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const getSettings = useGetPortfolioContributionSettings(portfolio.id);
  const useSettings = useUpdatePortfolioContributionSettings(portfolio.id);
  const defaultMessage = t('get_gifts.default_message');
  const [message, setMessage] = useState<string>();

  const steps = t('get_gifts.steps', { returnObjects: true });

  useEffect(() => {
    const update = setTimeout(async () => {
      message && (await useSettings.mutateAsync({ message }));
    }, 1000);

    return () => clearTimeout(update);
  }, [message]);

  useEffect(() => {
    if (getSettings.data) {
      setMessage(getSettings.data.message || defaultMessage);
    }
  }, [getSettings?.data?.message]);

  if (getSettings.isIdle || getSettings.isLoading) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (getSettings.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={getSettings.refetch} error={getSettings.error} />;
  }

  const createContribution = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const contributeLink = window.location.origin + '/contribute/' + portfolio.id;

  const copyLink = () => {
    navigator.clipboard.writeText(contributeLink);
    toast.success(t('copied', { ns: 'general' }));
    trackContributionShare('Link copy');
  };

  return (
    <div tw="flex flex-col pt-8">
      <div tw="flex flex-col md:w-3/5 md:self-center">
        <div tw="flex w-full md:bg-transparent bg-gray-50">
          <Heading noMargin>{t('get_gifts.title')}</Heading>
          <div tw="flex flex-row ml-auto mr-6">
            <Tooltip
              content={
                <ol className="p-2 md:p-4 max-w-sm min-w-min">
                  <li>
                    <strong>{t('get_gifts.faq.question_one')}</strong>
                    <p>{t('get_gifts.faq.answer_one')}</p>
                  </li>
                  <li>
                    <strong>{t('get_gifts.faq.question_two')}</strong>
                    <p>{t('get_gifts.faq.answer_two')}</p>
                  </li>
                  <li>
                    <strong>{t('get_gifts.faq.question_three')}</strong>
                    <p>{t('get_gifts.faq.answer_three')}</p>
                  </li>
                </ol>
              }
            >
              <button type="button" className="flex flex-row items-center">
                <InfoIcon
                  tw="w-4 h-4 rounded-full bg-alps-blue-100 text-alps-blue-700 flex-shrink-0 mr-2"
                  onClick={(e) => e.preventDefault()}
                />
                {t('get_gifts.how_it_works')}
              </button>
            </Tooltip>
          </div>
        </div>

        <div tw="px-6 mt-2">
          <p tw="text-base md:text-lg text-gray-900">{t('get_gifts.description_one')}</p>
          <p tw="text-base md:text-lg text-gray-900 mt-2">{t('get_gifts.description_two')}</p>
          <ol tw="my-12 flex flex-col gap-4">
            {Array.isArray(steps) &&
              steps.map((step, idx) => {
                return (
                  <li key={idx} tw="flex flex-row gap-4 items-center">
                    <span tw="font-bold text-alps-blue-700 flex flex-shrink-0 items-center justify-center rounded-full w-10 h-10 bg-alps-blue-50 text-lg">
                      {idx + 1}
                    </span>
                    <p tw="text-base text-gray-900">{step}</p>
                  </li>
                );
              })}
          </ol>
        </div>
      </div>

      <div style={{ backgroundImage: `url(${giftBg})`, backgroundPositionY: '24%' }} tw="bg-cover rounded">
        <div
          tw="flex flex-col w-full items-center rounded"
          style={{
            background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
          }}
        >
          <div tw="flex flex-col w-full md:w-3/5 px-6 mt-2">
            <p tw="text-base text-gray-900">{t('get_gifts.message')}</p>
            <textarea
              id="message"
              rows={5}
              tw="text-base md:text-lg text-gray-900 mt-2 block p-2.5 w-full resize-none border-none rounded bg-white/75"
              value={message}
              onChange={(event) => createContribution(event)}
            />

            <div tw="flex flex-col mt-10">
              <div tw="grid grid-cols-12 gap-x-4 gap-y-8 w-full mb-24 justify-items-center sm:justify-items-start">
                <div tw="col-span-12 sm:col-span-9 w-full">
                  <Input
                    readOnly={true}
                    value={contributeLink}
                    suffix={
                      <Button tw="px-2" variant={ButtonVariant.TRANSPARENT} onClick={copyLink}>
                        <ClipboardIcon
                          width={24}
                          height={24}
                          tw="inline-block rounded cursor-pointer text-alps-blue-800 hover:text-alps-blue-300"
                        />
                      </Button>
                    }
                  />
                </div>
                <a
                  href={'/contribute/' + portfolio.id}
                  target="_blank"
                  tw="col-span-3 flex justify-self-start w-full"
                  rel="noopener"
                >
                  <Button variant={ButtonVariant.SECONDARY}>
                    <span tw="mx-3">{t('get_gifts.preview')}</span>
                  </Button>
                </a>
                <div tw="hidden sm:block col-span-3">
                  <Button variant={ButtonVariant.SECONDARY} tw="px-2" onClick={copyLink}>
                    <ClipboardIcon tw="text-alps-blue-800 mr-2" />
                    <span tw="hidden sm:block text-sm">{t('get_gifts.copy_link')}</span>
                  </Button>
                </div>
                <a
                  tw="col-span-3"
                  target="_blank"
                  href={`mailto:?&subject=Gr%C3%BCnfin%20-%20${t('get_gifts.title')}
                        &body=${message && encodeURIComponent(message)}
                        %0A%0AHere%E2%80%99s%20the%20link%3A%20%0A${contributeLink}%3Futm_source%3Dsocial-share%26utm_medium%3Demail%26utm_content%3Dsocial-share-contribute%0A`}
                  rel="noreferrer"
                  onClick={() => trackContributionShare('Email')}
                >
                  <Button tw="px-2" variant={ButtonVariant.SECONDARY}>
                    <EmailIcon tw="text-alps-blue-800 sm:mr-2" />
                    <span tw="hidden sm:block text-sm">{t('get_gifts.email')}</span>
                  </Button>
                </a>
                <a
                  tw="col-span-3"
                  target="_blank"
                  href={`https://twitter.com/intent/tweet?url=${contributeLink}?utm_source=twitter&utm_medium=social&utm_campaign=social-share-contribute&text=${message}`}
                  rel="noreferrer"
                  onClick={() => trackContributionShare('Twitter')}
                >
                  <Button tw="px-2" variant={ButtonVariant.SECONDARY}>
                    <TwitterIcon tw="text-alps-blue-800 sm:mr-2 w-8 h-8 p-1" />
                    <span tw="hidden sm:block text-sm">{t('get_gifts.twitter')}</span>
                  </Button>
                </a>
                <a
                  tw="col-span-3 sm:col-span-3"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${contributeLink}?utm_source=facebook&utm_medium=social&utm_campaign=social-share-contribute`}
                  rel="noreferrer"
                  onClick={() => trackContributionShare('Facebook')}
                >
                  <Button tw="px-2" variant={ButtonVariant.SECONDARY}>
                    <FacebookIcon tw="text-alps-blue-800 sm:mr-2 w-8 h-8 p-1" />
                    <span tw="hidden sm:block text-sm">{t('get_gifts.facebook')}</span>
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
