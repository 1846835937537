import {
  AllocationPie,
  BeneficiaryType,
  FocusArea,
  formatCurrency,
  formatDate,
  InfoTooltip,
  useTranslation,
} from '@grunfin/ui-kit';
import { CSSProperties, ReactNode } from 'react';
import 'twin.macro';

import { AssetsTable } from '../components/AssetsTable';
import FocusAreaList from '../components/FocusAreaList';
import { Risk } from '../components/Risk';
import { usePortfolio } from '../hooks';
import { PricingScheme } from '~/modules/portfolio/types';

enum ListItemIdentifier {
  SUSTAINABILITY_AREAS = 'sustainability_areas',
  UPFRONT_CONTRIBUTION = 'upfront_contribution',
  MONTHLY_CONTRIBUTION = 'monthly_contribution',
  PERIOD = 'period',
  RISK_PREFERENCE = 'risk_preference',
  EMPLOYER_CONTRIBUTION = 'employer_contribution',
  EMPLOYEE_CONTRIBUTION = 'employee_contribution',
  TOTAL_CONTRIBUTIONS = 'total_contributions',
  GAIN = 'gain',
  PORTFOLIO_LOCK_DATE = 'portfolio_lock_date',
}

type ListItem = {
  id?: string;
  title: string;
  value: string | number | undefined | ReactNode;
  visible?: boolean;
  fullWidth?: boolean;
};

const findListItemObjectByIdentifier = (list: ListItem[], identifier: ListItemIdentifier) => {
  return list.find((item) => item.id === identifier);
};

const renderList = (list: ListItem[]) => {
  if (!list || !Array.isArray(list) || list.length === 0) return null;

  const filteredList = list.filter((item) => !('visible' in (item ?? {}) && item.visible === false));

  return (
    <div tw="flex flex-row flex-wrap gap-4 flex-1">
      {filteredList.map((item) => {
        return (
          <PortfolioStructureListItem
            key={item.id}
            item={item}
            style={{ width: item.fullWidth ? '100%' : 'calc(50% - 1rem)' }}
          />
        );
      })}
    </div>
  );
};

export const Structure = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const focuses = portfolio.focus.split(', ') as FocusArea[];

  const retailList: ListItem[] = [
    {
      id: ListItemIdentifier.SUSTAINABILITY_AREAS,
      title: t('sustainability_areas'),
      value: <FocusAreaList focuses={focuses} />,
      fullWidth: true,
    },
    {
      id: ListItemIdentifier.UPFRONT_CONTRIBUTION,
      title: t('upfront_contribution'),
      value:
        portfolio.upfrontInvestmentAmountEur != null
          ? formatCurrency(portfolio.upfrontInvestmentAmountEur, { maxDigits: 2 })
          : undefined,
      visible: portfolio.upfrontInvestmentAmountEur != null,
    },
    {
      id: ListItemIdentifier.MONTHLY_CONTRIBUTION,
      title: t('monthly_contribution'),
      value:
        portfolio.monthlyInvestmentAmountEur != null
          ? formatCurrency(portfolio.monthlyInvestmentAmountEur, { maxDigits: 2 })
          : undefined,
      visible: portfolio.monthlyInvestmentAmountEur != null,
    },
    {
      id: ListItemIdentifier.PERIOD,
      title: t('period'),
      value: t('details.period_years', { count: portfolio.periodInYears }),
      visible: portfolio.pricingScheme === PricingScheme.RETAIL_LEGACY_FLAT,
    },
    {
      id: ListItemIdentifier.RISK_PREFERENCE,
      title: t('risk_preference'),
      value: <Risk level={portfolio.preferredRisk} />,
    },
  ];

  const corporateList: ListItem[] = [
    {
      id: ListItemIdentifier.EMPLOYER_CONTRIBUTION,
      title: t('employer_contributions'),
      value: formatCurrency(portfolio?.depositedAmountByOwnerEur?.EMPLOYER, { maxDigits: 2 }),
    },
    {
      id: ListItemIdentifier.EMPLOYEE_CONTRIBUTION,
      title: t('employee_contributions'),
      value: formatCurrency(portfolio?.depositedAmountByOwnerEur?.CUSTOMER, { maxDigits: 2 }),
    },
    {
      id: ListItemIdentifier.TOTAL_CONTRIBUTIONS,
      title: t('total_contributions'),
      value: formatCurrency(
        portfolio?.depositedAmountByOwnerEur?.CUSTOMER + portfolio?.depositedAmountByOwnerEur?.EMPLOYER,
        { maxDigits: 2 },
      ),
    },
    {
      id: ListItemIdentifier.GAIN,
      title: t('gain'),
      value: portfolio.gainAmountEur != null ? formatCurrency(portfolio.gainAmountEur, { maxDigits: 2 }) : undefined,
      visible: portfolio.gainAmountEur != null,
    },
    {
      id: ListItemIdentifier.PORTFOLIO_LOCK_DATE,
      title: t('lock_date.title'),
      value: portfolio.lockDate != null && (
        <span tw="flex flex-row gap-2 items-center">
          <p>{formatDate(portfolio.lockDate)}</p>
          <InfoTooltip>{t('lock_date.tooltip')}</InfoTooltip>
        </span>
      ),
      visible: portfolio.lockDate != null,
    },
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.RISK_PREFERENCE) as ListItem],
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.SUSTAINABILITY_AREAS) as ListItem],
  ];

  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  const list = isCorporate ? corporateList : retailList;

  return (
    <div tw="flex flex-col gap-14 p-4 sm:p-6 md:p-12">
      <div tw="flex flex-col sm:flex-row flex-wrap items-center justify-center gap-8">
        <div tw="flex sm:flex-1">
          <AllocationPie
            assets={portfolio.assets}
            bonds={portfolio.realComposition.BOND ?? 0}
            shares={portfolio.realComposition.STOCK ?? 0}
            totalValue
          />
        </div>
        {renderList(list)}
      </div>
      <AssetsTable totalValueEur={portfolio.totalValueEur} assets={portfolio.assets} />
    </div>
  );
};

export const PortfolioStructureListItem = ({ item, style = {} }: { item: ListItem; style?: CSSProperties }) => {
  return (
    <div tw="flex flex-col gap-2 text-base md:text-lg" style={style}>
      <h3 tw="text-gray-400 text-base md:text-base font-medium">{item.title}</h3>
      {typeof item.value === 'object' ? item.value : <p>{item.value}</p>}
    </div>
  );
};
