import {
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CheckIcon,
  CloseIcon,
  Input,
  Spinner,
  Tooltip,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { Auth0Error } from 'auth0-js';
import { ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';

import { trackSignupInitiated } from '~/utils/tracking-analytics';
import { AppleButton } from './AppleButton';
import { GoogleButton } from './GoogleButton';
import { useAppleLogin, useGoogleLogin, useNext } from './hooks';
import { useLogin, useSignup } from './queries';
import { useSession } from './SessionProvider';
import { QueryParam, StorageKey } from './types';

interface SignupFormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  newsletters: boolean;
}

type SignupFormProps = {
  redirectToResult?: boolean;
  nextUrl: string; //This needs to be set to LS, as after oauth full page refresh, this is used for redirecting. callbacks don't work
  presetEmail?: string | undefined;
  callback?: () => void;
  useEmailPassAccordion?: boolean;
  showNewsletters?: boolean;
};

type State = 'idle' | 'submitting' | 'error';

export const SignupForm = ({
  presetEmail,
  redirectToResult,
  nextUrl,
  callback,
  useEmailPassAccordion,
  showNewsletters = true,
}: SignupFormProps) => {
  const [newsletters, setNewsletters] = useState(false);
  const [state, setState] = useState<State>('idle');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { i18n, t } = useTranslation('auth');
  const appTranslations = useTranslation('application');
  const googleLogin = useGoogleLogin({ nextUrl });
  const appleLogin = useAppleLogin({ nextUrl });
  const session = useSession();
  const login = useLogin();
  const signup = useSignup();
  const next = useNext();
  const form = useForm<SignupFormValues>({
    criteriaMode: 'all',
    mode: 'onChange',
  });
  presetEmail && form.setValue('email', presetEmail);
  const { formState, handleSubmit, register, watch } = form;
  const [query] = useSearchParams();

  const password = watch('password');
  useEffect(() => {
    newsletters && localStorage.setItem(StorageKey.NEWSLETTERS, 'true');
  }, [newsletters]);

  const formDisabled = formState.isSubmitting || state === 'submitting';

  const handleSignup = async ({ email, password }: SignupFormValues) => {
    try {
      trackSignupInitiated('Email');

      try {
        await signup.mutateAsync({ email, password, lang: i18n.resolvedLanguage });
        await login.mutateAsync({ username: email, password });
        if (typeof callback !== 'undefined') callback();
      } catch (err) {
        if (err instanceof Error) setBackendValidationErrors(err, form.setError);
      }
    } catch (error) {
      setState('error');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setState('submitting');
      redirectToResult && query.set(QueryParam.NEXT, '/onboarding/result');
      trackSignupInitiated('Google');

      await googleLogin();
    } catch (error) {
      const cancelled = (error as Auth0Error).original === 'User closed the popup window';
      setState(cancelled ? 'idle' : 'error');
    }
  };

  const handleAppleLogin = async () => {
    try {
      setState('submitting');
      redirectToResult && query.set(QueryParam.NEXT, '/onboarding/result');
      trackSignupInitiated('Apple');

      await appleLogin();
    } catch (error) {
      const cancelled = (error as Auth0Error).original === 'User closed the popup window';
      setState(cancelled ? 'idle' : 'error');
    }
  };

  if (state === 'error') {
    return (
      <ContactSupportOverlay
        title={t('signup.error')}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={{ name: 'Error', message: signup?.error?.errorMessage }}
        onClose={() => setState('idle')}
      />
    );
  }

  if (session.authenticated) {
    const path = redirectToResult ? '/onboarding/result' : next;
    return <Navigate to={path} replace />;
  }

  const getShowAccordion = () => {
    if (!useEmailPassAccordion) return true;
    if (useEmailPassAccordion && !isAccordionOpen) return false;
    if (isAccordionOpen) return true;
    return false;
  };
  const showAccordion = getShowAccordion();

  const emailErrorMessage =
    'email' in formState.errors
      ? formState.errors?.email?.message != null
        ? t(formState.errors.email.message, { ns: 'general' })
        : t('signup.email.error', { ns: 'auth' })
      : undefined;

  return (
    <div tw="w-full">
      <FormProvider {...form}>
        <div>
          <fieldset tw="flex gap-y-4 flex-col items-center w-full" disabled={formDisabled}>
            {showNewsletters && (
              <div tw="mb-2 text-gray-400 text-sm">
                <Checkbox
                  value={newsletters}
                  onChange={setNewsletters}
                  name="newsletters"
                  label={<span tw="font-light">{t('signup.newsletters')}</span>}
                />
              </div>
            )}
            <GoogleButton onClick={handleGoogleLogin}>{t('signup.google')}</GoogleButton>
            <AppleButton onClick={handleAppleLogin}>{t('signup.apple')}</AppleButton>
            {useEmailPassAccordion && !isAccordionOpen && (
              <Button size={ButtonSize.LARGE} onClick={() => setIsAccordionOpen(true)} data-test-id="set-password">
                {t('signup.set_password')}
              </Button>
            )}
            {!useEmailPassAccordion && showAccordion && <div tw="text-gray-400 text-center">{t('or')}</div>}
            {showAccordion && (
              <>
                <div tw="w-full">
                  <Input
                    type="email"
                    placeholder={t('signup.email.placeholder')}
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    error={'email' in formState.errors}
                    disabled={presetEmail != null}
                    data-test-id="email"
                    {...(presetEmail && { autoComplete: 'off' })}
                  />
                  {emailErrorMessage != null && <div tw="text-sm text-dawn-300 mt-2">{emailErrorMessage}</div>}
                </div>
                <Tooltip
                  content={typeof password !== 'undefined' && <PasswordStrength satisfied={password?.length >= 12} />}
                  /* Prevents tabbing to the floating element */
                  floatingFocusOptions={{ order: ['reference'], initialFocus: -1 }}
                >
                  <div tw="w-full">
                    <Input
                      type="password"
                      placeholder={t('signup.password.placeholder')}
                      error={'password' in formState.errors}
                      {...register('password', { required: true, minLength: 12 })}
                      data-test-id="password"
                    />
                    {formState.errors.password && (
                      <div tw="text-sm text-dawn-300 mt-2">
                        {formState.errors.password.message ?? t('signup.password.error')}
                      </div>
                    )}
                  </div>
                </Tooltip>
                <div tw="w-full">
                  <Input
                    type="password"
                    placeholder={t('signup.password_confirm.placeholder')}
                    {...register('passwordConfirm', { validate: (value) => value === password })}
                    error={'passwordConfirm' in formState.errors}
                    data-test-id="passwordConfirm"
                  />
                  {formState.errors.passwordConfirm && (
                    <div tw="text-sm text-dawn-300 mt-2">
                      {formState.errors.passwordConfirm.message ?? t('signup.password_confirm.error')}
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleSubmit(handleSignup)}
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  type="button"
                  disabled={formState.isSubmitting}
                  data-test-id="login-submit"
                >
                  {formState.isSubmitting ? <Spinner /> : t('signup.submit')}
                </Button>
              </>
            )}
          </fieldset>
        </div>
      </FormProvider>
      {showAccordion && (
        <div tw="mt-6 text-gray-400" css={[redirectToResult && tw`text-center`]}>
          <div tw="text-sm mt-4 text-center">
            <Trans
              i18nKey="signup.agreesToTerms"
              t={t}
              components={[
                <a
                  key="0"
                  tw="hover:underline"
                  href={appTranslations.t('footer.privacy_policy_link')}
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type PasswordProps = {
  satisfied: boolean;
};

const PasswordStrength = ({ satisfied }: PasswordProps) => {
  const { t } = useTranslation('auth');

  return (
    <div tw="space-y-2 p-2">
      <PasswordRequirement satisfied={satisfied}>{t('signup.password.requirement.length')}</PasswordRequirement>
    </div>
  );
};

const PasswordRequirement = ({ satisfied, children }: { satisfied?: boolean; children: ReactNode }) => {
  const Icon = satisfied ? CheckIcon : CloseIcon;

  return (
    <div tw="flex items-start">
      <div css={[tw`flex-shrink-0 mr-2 rounded-full`, satisfied ? tw`bg-forest-green-100` : tw`bg-dawn-100`]}>
        <Icon height={24} width={24} />
      </div>
      <div>{children}</div>
    </div>
  );
};
