import { useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

import { Heading } from '~/modules/portfolio/components/Heading';
import { useCurrentConsent } from '~/modules/portfolio/hooks';

import { DirectDebit } from './DirectDebit/DirectDebit';
import { StandingOrder } from './StandingOrder';

export const PaymentMethod = () => {
  const { t } = useTranslation('portfolio');
  const consent = useCurrentConsent();

  return (
    <div tw="flex flex-col md:w-2/5">
      <Heading>{t('details.payment.method')}</Heading>
      <div tw="px-6 pb-6">{consent ? <DirectDebit consent={consent} /> : <StandingOrder />}</div>
    </div>
  );
};
