import { Button, ButtonSize, ButtonVariant, Spinner, toast, Trans, useTranslation } from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Heading } from '~/modules/portfolio/components/Heading';
import { Instructions } from '~/modules/portfolio/components/Instructions';
import { useCurrentConsent, usePortfolio } from '~/modules/portfolio/hooks';
import { useRevokeConsent } from '~/modules/portfolio/queries';

export const StandingOrder = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const navigate = useNavigate();
  const revoke = useRevokeConsent(portfolio.id);
  const currentConsent = useCurrentConsent();
  const isRevoking = revoke.isLoading;

  const onRevoke = async () => {
    if (!currentConsent) throw new Error('Consent missing');
    await revoke.mutateAsync(currentConsent.id);
    toast.success(t('details.payment.set_up.standing_order.success'));
    navigate(`/portfolio/${portfolio.id}`);
  };

  return (
    <>
      <Heading primary>{t('details.payment.set_up.standing_order.title')}</Heading>
      <div tw="px-6">
        <h4 tw="text-lg mb-10">
          <Trans i18nKey="details.payment.set_up.standing_order.description" t={t} />
        </h4>
        <div tw="md:bg-gray-50 rounded md:px-10 md:py-8">
          <Instructions horizontal />
        </div>
        {currentConsent && (
          <div tw="flex md:justify-center relative mt-12">
            <Button
              onClick={onRevoke}
              type="submit"
              disabled={isRevoking}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
            >
              {isRevoking && (
                <div tw="absolute w-full">
                  <Spinner />
                </div>
              )}
              <span css={[isRevoking && tw`invisible`]}>{t('details.payment.set_up.standing_order.confirm')}</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
