import 'twin.macro';
import { useTranslation } from '@grunfin/ui-kit';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import Member from './Member';
import { default as TableBase } from './Table';
import { AddEmployee } from '~/modules/company/types';

const MemberList = ({ rows }: { rows: AddEmployee[] }) => {
  const { t } = useTranslation('company');
  const columns = useMemo<ColumnDef<AddEmployee>[]>(
    () => [
      {
        header: ' ',
        accessorFn: (row) => row.employerSideId,
        id: 'context',
        cell: (info) => {
          if (info.row.index === 0)
            return (
              <h4 tw="text-base md:text-lg text-gray-400 hidden sm:block">{t('employees.upload.added_employees')}</h4>
            );
          return null;
        },
      },
      {
        header: t('table.name'),
        accessorFn: (row) => `${row.givenName} ${row.lastName}`,
        id: 'name',
        cell: (info) => <Member name={info.getValue() as string} />,
      },
      {
        header: t('table.id'),
        accessorFn: (row) => `${row.employerSideId}`,
        id: 'id',
        cell: (info) => <p>{info.getValue() as string}</p>,
      },
      {
        header: t('table.email'),
        accessorFn: (row) => `${row.email}`,
        id: 'email',
        cell: (info) => <p>{info.getValue() as string}</p>,
      },
    ],
    [t],
  );

  const table = useReactTable({
    data: rows ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div tw="flex w-full max-w-full whitespace-nowrap">
      <TableBase table={table} />
    </div>
  );
};

export default MemberList;
