var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgPowerIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m17 6c0-.55228-.4477-1-1-1s-1 .44772-1 1v8c0 .5523.4477 1 1 1s1-.4477 1-1zm4.9958 2.19686c-.4436-.32905-1.0699-.23621-1.3989.20735-.3291.44356-.2363 1.06988.2073 1.39893 1.9452 1.44296 3.1958 3.73326 3.1958 6.30836 0 4.345-3.5699 7.8885-8 7.8885s-8-3.5435-8-7.8885c0-2.5747 1.25029-4.8648 3.195-6.30778.4435-.3291.5363-.95543.2072-1.39896-.3291-.44352-.9554-.53628-1.399-.20718-2.42626 1.80034-4.0032 4.67332-4.0032 7.91392 0 5.473 4.4889 9.8885 10 9.8885s10-4.4155 10-9.8885c0-3.241-1.5774-6.11435-4.0042-7.91464z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgPowerIcon);
var Memo = memo(ForwardRef);
export default Memo;
