import { Button, ButtonSize, ButtonVariant, Input, useTranslation } from '@grunfin/ui-kit';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Heading } from '../../components/Heading';
import { usePortfolio } from '../../hooks';

export const MonthlyContribution = () => {
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();
  const portfolio = usePortfolio();
  const initialState = portfolio?.monthlyInvestmentAmountEur?.toString() ?? '';
  const [monthlyContribution, setMonthlyContribution] = useState(initialState);

  return (
    <div tw="flex-1">
      <Heading>{t('monthly_contribution')}</Heading>
      <form tw="flex flex-wrap gap-4 md:flex-col md:items-start px-6">
        <div tw="w-2/5 md:mb-7 mr-3 md:mr-0">
          <Input
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            suffix="EUR"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setMonthlyContribution(e.target.value)}
            value={monthlyContribution}
          />
        </div>
        <Button
          disabled={monthlyContribution === initialState}
          onClick={() => navigate(`/portfolio/${portfolio.id}/settings?amount=${monthlyContribution}`)}
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.LARGE}
        >
          {t('details.payment.monthly_contribution.confirm')}
        </Button>
      </form>
    </div>
  );
};
