import { AlertDialog, Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { Fragment } from 'react';

interface Props {
  open: boolean;
  notNowHandler: { (): void };
  editHandler: { (): void };
}

export const MonthlyAmountReminderPopup = ({ open, notNowHandler, editHandler }: Props) => {
  const { t } = useTranslation('onboarding');

  return (
    <AlertDialog
      open={open}
      title={t('monthly_reminder.title')}
      description={t('monthly_reminder.message')}
      actions={(closeFn: () => void) => (
        <div className="flex flex-col md:flex-row">
          <div>
            <Button
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.LARGE}
              onClick={() => {
                closeFn();
                notNowHandler();
              }}
            >
              {t('monthly_reminder.close')}
            </Button>
          </div>
          <div style={{ margin: '1rem' }} />
          <div>
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} onClick={editHandler}>
              {t('monthly_reminder.edit_contribution')}
            </Button>
          </div>
        </div>
      )}
    >
      <Fragment />
    </AlertDialog>
  );
};
