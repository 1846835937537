var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGrunfinLogoType = function (props, ref) { return (React.createElement("svg", __assign({ width: 120, height: 24, viewBox: "0 0 120 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M45.906 9.56042C45.294 6.71642 42.954 5.25842 40.056 5.25842C36.096 5.25842 33.396 7.83242 33.396 11.9544C33.396 16.1304 35.754 18.7584 39.606 18.7584C41.478 18.7584 42.756 18.2184 43.602 17.0844L43.692 18.5244H46.05V11.2524H39.822V13.7724H42.972V14.0244C42.972 15.6804 41.496 16.2204 40.074 16.2204C38.022 16.2204 36.654 14.7084 36.654 11.9544C36.654 9.20042 38.13 7.79642 40.02 7.79642C41.694 7.79642 42.612 8.66042 42.882 9.56042H45.906Z", fill: "currentColor" }),
    React.createElement("path", { d: "M53.4517 13.6104L56.2777 18.5244H59.8957L56.6737 13.1064C58.1677 12.5304 59.2297 11.4324 59.2297 9.50642C59.2297 6.26642 56.6917 5.47442 53.3077 5.47442H48.2857V18.5244H51.3637V13.6104H53.4517ZM51.3637 8.06642H53.3977C54.8557 8.06642 55.9717 8.33642 55.9717 9.54242C55.9717 10.7664 54.8557 11.0184 53.3977 11.0184H51.3637V8.06642Z", fill: "currentColor" }),
    React.createElement("path", { d: "M65.8329 2.57642H63.1869V4.61042H65.8329V2.57642ZM69.9729 2.57642H67.3089V4.61042H69.9729V2.57642ZM64.0149 5.47442H60.9369V12.9444C60.9369 17.2464 62.7009 18.7584 66.6069 18.7584C70.6749 18.7584 72.2229 16.8504 72.2229 12.8364V5.47442H69.1449V13.0884C69.1449 15.0144 68.7669 16.2384 66.6069 16.2384C64.4649 16.2384 64.0149 15.1764 64.0149 13.2684V5.47442Z", fill: "currentColor" }),
    React.createElement("path", { d: "M82.5972 18.5244H86.3412V5.47442H83.3712V14.4744L78.1332 5.47442H74.3892V18.5244H77.3592V9.52442L82.5972 18.5244Z", fill: "currentColor" }),
    React.createElement("path", { d: "M88.6978 5.47442V18.5244H91.7758V13.1784H97.9138V10.4604H91.7758V8.17442H98.3098V5.47442H88.6978Z", fill: "currentColor" }),
    React.createElement("path", { d: "M102.551 18.5244V5.47442H99.4732V18.5244H102.551Z", fill: "currentColor" }),
    React.createElement("path", { d: "M113.113 18.5244H116.857V5.47442H113.887V14.4744L108.649 5.47442H104.905V18.5244H107.875V9.52442L113.113 18.5244Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9912 12.4649C23.997 12.3107 24 12.1557 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 16.4894 2.46533 20.403 6.11603 22.4609C7.8546 23.4409 9.86201 24 12 24C18.2132 24 23.3235 19.278 23.938 13.2269C23.9636 12.9751 23.9814 12.7211 23.9912 12.4649ZM7.06819 20.9306L14.896 8.35025C15.5535 7.29357 16.9805 7.03729 17.9645 7.79916L22.1558 11.044C21.6738 5.85897 17.3109 1.8 12 1.8C6.3667 1.8 1.8 6.3667 1.8 12C1.8 15.8445 3.92689 19.1921 7.06819 20.9306Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.2 6.90001C13.2 8.05981 12.2598 9.00001 11.1 9.00001C9.9402 9.00001 9 8.05981 9 6.90001C9 5.74021 9.9402 4.80001 11.1 4.80001C12.2598 4.80001 13.2 5.74021 13.2 6.90001Z", fill: "currentColor" }),
    React.createElement("path", { opacity: 0.56, d: "M4.33145 21.2312C2.29676 19.5388 0.830512 17.1865 0.26133 14.5027L6.29068 10.3286C6.77239 9.99507 7.42451 10.0582 7.83332 10.4779L9.90014 12.5997L4.33145 21.2312Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgGrunfinLogoType);
var Memo = memo(ForwardRef);
export default Memo;
