var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgSiliconcanalsIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 101, height: 32, viewBox: "0 0 101 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.51725 29.8495C0.901731 25.4488 -1.73712 15.7337 2.57698 8.08644C2.58953 8.06367 2.60234 8.04098 2.61539 8.01835L2.63471 7.98494L2.65464 7.95029C2.66781 7.92747 2.68116 7.90483 2.6947 7.88237C7.15454 0.319741 16.8796 -2.25019 24.4949 2.15047C32.1105 6.55127 34.7494 16.2664 30.4352 23.9137C30.4226 23.9364 30.4098 23.9591 30.3968 23.9816L30.3776 24.0148L30.3576 24.0497C30.3444 24.0726 30.331 24.0952 30.3174 24.1177C25.8576 31.6803 16.1325 34.2502 8.51725 29.8495ZM12.5215 22.9077C8.70253 20.7009 7.38656 15.8212 9.57048 11.9925C11.7912 8.18467 16.6716 6.88528 20.4907 9.09225C24.3096 11.2991 25.6256 16.1786 23.4418 20.0073C21.2212 23.8153 16.3407 25.1148 12.5215 22.9077Z", fill: "currentColor" }),
    React.createElement("path", { d: "M53.0526 13.9078L53.0528 13.9692C53.0533 14.0263 53.0537 14.0834 53.0509 14.1403C53.031 14.5331 52.7372 14.8123 52.3497 14.8085C51.967 14.8049 51.6741 14.5157 51.6586 14.1255C51.657 14.086 51.6573 14.0464 51.6577 14.0067L51.658 13.9563V3.08781C51.658 3.06913 51.6577 3.05041 51.6575 3.03169C51.6568 2.97987 51.6561 2.92791 51.6607 2.8765C51.6939 2.50443 51.9992 2.23347 52.3751 2.2396C52.7401 2.24553 53.0253 2.51987 53.0498 2.89131C53.0537 2.94994 53.0532 3.00894 53.0528 3.06784L53.0526 3.12377V13.9078Z", fill: "currentColor" }),
    React.createElement("path", { d: "M37.2188 13.7185C38.4211 14.5365 39.7459 14.9735 40.9407 15.0041C41.7993 15.0077 42.3816 14.9208 42.9409 14.705C44.2841 14.1866 45.0081 13.0524 44.9218 11.6149C44.881 10.9349 44.5901 10.3866 44.0709 9.95424C43.7652 9.69977 43.4266 9.49333 43.057 9.35203C42.5052 9.14114 41.9459 8.94844 41.3849 8.76209C41.2702 8.72399 41.1548 8.6873 41.0395 8.65062C40.6042 8.51214 40.1689 8.37367 39.7634 8.15947C39.5413 8.04186 39.3292 7.89675 39.135 7.73684C38.9048 7.54732 38.769 7.28884 38.7411 6.98805C38.6969 6.51213 38.8384 6.09775 39.1809 5.75784C39.3899 5.55033 39.6392 5.40904 39.9153 5.31152C40.4688 5.11608 41.0349 5.11565 41.6056 5.2083C42.3228 5.32485 42.9893 5.58396 43.6252 5.9298C43.7233 5.98311 43.8276 6.03514 43.9355 6.05778C44.1671 6.10643 44.37 6.03387 44.5237 5.85302C44.6733 5.67703 44.6972 5.47334 44.6227 5.25737C44.5565 5.06595 44.4171 4.94369 44.2426 4.85167C43.4306 4.42292 42.5701 4.14476 41.6597 4.02843C40.953 3.93811 40.2529 3.97216 39.5682 4.1858C38.2459 4.59848 37.3214 5.66265 37.3969 7.16827C37.4267 7.76413 37.6425 8.27686 38.0695 8.69695C38.3906 9.01275 38.7733 9.23463 39.1811 9.41718C39.617 9.61238 40.0723 9.75015 40.5278 9.888C40.6181 9.91534 40.7086 9.94272 40.7988 9.97052C41.3099 10.1281 41.8184 10.2952 42.3203 10.479C42.6386 10.5956 42.9299 10.7709 43.1799 11.0068C43.4727 11.283 43.6218 11.6208 43.6146 12.0242C43.6023 12.7083 43.2772 13.2022 42.6856 13.526C42.3273 13.7221 41.9364 13.8114 41.5309 13.8321C40.5871 13.8803 39.6957 13.6694 38.8545 13.2536C38.5751 13.1155 38.3081 12.9523 38.0413 12.7893C37.9714 12.7465 37.9015 12.7038 37.8314 12.6615C37.4441 12.4282 36.9485 12.6516 36.9 13.0943C36.8704 13.3653 36.9967 13.5675 37.2188 13.7185Z", fill: "currentColor" }),
    React.createElement("path", { d: "M59.4869 9.495C59.4922 6.95546 61.3166 4.55722 64.052 4.07748C65.8271 3.76612 67.401 4.24014 68.7529 5.438C68.9248 5.59029 69.034 5.76988 69.0278 6.0072C69.0209 6.27732 68.9026 6.48461 68.6619 6.60539C68.425 6.72427 68.1841 6.71348 67.9588 6.56605C67.9083 6.53303 67.8632 6.49169 67.8182 6.45039C67.8007 6.4343 67.7831 6.41813 67.7653 6.40254C66.9604 5.70071 66.0219 5.35233 64.9567 5.36227C62.962 5.38089 61.2375 6.89179 60.9375 8.86466C60.5938 11.1267 62.1174 13.1848 64.3033 13.566C65.6172 13.795 66.7884 13.4479 67.7953 12.5661L67.8141 12.5493L67.8259 12.5386L67.8557 12.511C67.8924 12.4766 67.9295 12.442 67.9696 12.4123C68.2486 12.2052 68.634 12.2408 68.8741 12.4927C69.1076 12.7379 69.1285 13.1152 68.9206 13.3836C68.8821 13.4336 68.8364 13.4786 68.7899 13.5215C67.9021 14.3401 66.8621 14.826 65.66 14.9684C62.8433 15.3021 60.2082 13.4245 59.6248 10.6038C59.5734 10.3554 59.5486 10.1013 59.5238 9.84739L59.5214 9.82347C59.5108 9.71379 59.5001 9.60415 59.4869 9.495Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M70.891 9.46058C70.8652 12.4312 73.2854 15.0065 76.3973 15.0059C79.3481 15.0183 81.8923 12.6448 81.9136 9.53419C81.9346 6.46838 79.4725 3.99738 76.4075 3.98638C73.3795 3.97559 70.9172 6.44681 70.891 9.46058ZM76.403 13.632C74.0797 13.6293 72.2377 11.7038 72.2716 9.48237C72.2498 7.34895 74.0025 5.36593 76.4124 5.36233C78.6917 5.35874 80.5576 7.23685 80.5385 9.52319C80.5186 11.8855 78.5783 13.6346 76.403 13.632Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M84.1958 18.3575L84.1984 18.3171L84.2009 18.2815C84.2047 18.2305 84.2083 18.1804 84.2089 18.1308C84.2099 18.0486 84.2092 17.9663 84.2084 17.884C84.207 17.7264 84.2056 17.5688 84.2165 17.4121C84.2491 16.9444 84.7161 16.6506 85.1525 16.8192C85.4283 16.9256 85.5745 17.1373 85.6026 17.4301C85.6068 17.4744 85.6059 17.5194 85.605 17.5643C85.6046 17.5831 85.6043 17.6018 85.6043 17.6204C85.6043 20.6027 85.6043 23.5852 85.6039 26.5674L85.604 26.6057C85.6044 26.6847 85.6048 26.7647 85.5897 26.8411C85.5201 27.1925 85.2115 27.4224 84.8506 27.4C84.5082 27.3788 84.2353 27.1087 84.215 26.7529C84.2067 26.6054 84.2077 26.4574 84.2088 26.3093C84.2093 26.2316 84.2099 26.1539 84.2091 26.0763C84.2085 26.0241 84.2055 25.9717 84.2021 25.9137C84.2006 25.8881 84.1991 25.8614 84.1976 25.833C84.1075 25.8583 84.0576 25.9129 84.0106 25.9644L83.9949 25.9815L83.9812 25.9959L83.9722 26.005C83.2414 26.7229 82.3826 27.2092 81.3855 27.4529C78.3907 28.1847 75.3427 26.2715 74.7041 23.2563C74.0609 20.2184 76.0117 17.2528 79.0672 16.6872C80.9402 16.3405 82.5808 16.8628 83.9629 18.1801L83.9874 18.2037L84.0187 18.2339C84.0558 18.2698 84.0929 18.3057 84.131 18.3407C84.1365 18.3456 84.1451 18.3472 84.1599 18.3499L84.1762 18.3531C84.182 18.3543 84.1885 18.3557 84.1958 18.3575ZM80.077 26.2294C77.8029 26.2273 75.9576 24.371 75.9588 22.0867C75.9601 19.8112 77.8152 17.9629 80.0969 17.9635C82.3728 17.9642 84.2283 19.8199 84.2283 22.0961C84.2285 24.3816 82.3705 26.2315 80.077 26.2294Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M52.8468 27.5861C54.6134 27.7168 56.1044 27.0979 57.3354 25.8265L57.3776 25.8539C57.3787 25.8789 57.3801 25.9039 57.3814 25.929C57.3842 25.9794 57.3869 26.0298 57.3877 26.0803C57.3889 26.1574 57.3886 26.2347 57.3883 26.3119C57.3876 26.4674 57.387 26.6231 57.3987 26.7777C57.4243 27.1144 57.6877 27.3697 58.0143 27.3981C58.361 27.4281 58.6636 27.2293 58.7523 26.8972C58.7825 26.7838 58.7853 26.6607 58.7853 26.542C58.7865 24.1203 58.7864 21.6986 58.7864 19.2769L58.7863 17.6399L58.7866 17.5881C58.7871 17.5277 58.7876 17.4673 58.7819 17.4074C58.7523 17.0971 58.5262 16.8458 58.2313 16.7902C57.7953 16.7079 57.4209 16.9901 57.3945 17.4334C57.3853 17.5886 57.3864 17.7444 57.3874 17.9002C57.388 17.9842 57.3886 18.0682 57.3875 18.1522C57.3871 18.1954 57.3833 18.2384 57.3782 18.2975C57.3755 18.3287 57.3723 18.3644 57.3691 18.4069C56.6019 17.5993 55.7383 17.0512 54.7122 16.7775C53.6935 16.5057 52.6782 16.5201 51.6688 16.8268C50.6512 17.136 49.796 17.6978 49.1001 18.4999C47.288 20.5887 47.3144 23.7135 49.169 25.7698C50.1518 26.8593 51.383 27.4778 52.8468 27.5861ZM49.1379 22.0812C49.1326 24.3521 50.979 26.2139 53.2313 26.2319C55.5096 26.2501 57.4066 24.4027 57.4095 22.1015C57.4123 19.8351 55.561 17.9679 53.3047 17.9635C51.005 17.9589 49.1432 19.7989 49.1379 22.0812Z", fill: "currentColor" }),
    React.createElement("path", { d: "M63.0395 18.5008C63.0717 18.4706 63.0999 18.4443 63.1254 18.4206C63.1732 18.3761 63.2119 18.3401 63.2491 18.3033C64.0525 17.5071 65.0058 16.9969 66.119 16.7841C68.5626 16.3168 71.0437 17.5746 72.1153 19.8256C72.4725 20.5761 72.6513 21.3678 72.6543 22.1968C72.6592 23.5639 72.657 24.9312 72.6548 26.2985V26.302L72.6543 26.6174C72.6543 26.7219 72.6443 26.8304 72.6151 26.93C72.5208 27.2528 72.2092 27.4421 71.8581 27.4023C71.5455 27.3668 71.3007 27.0994 71.2817 26.7701C71.2788 26.7196 71.2793 26.6689 71.2798 26.6181C71.28 26.5984 71.2802 26.5783 71.2802 26.5586C71.2802 26.1143 71.2807 25.67 71.2812 25.2258V25.2181C71.2824 24.2126 71.2836 23.2069 71.2787 22.2014C71.2698 20.3277 70.0257 18.7039 68.2338 18.2123C65.8352 17.5543 63.4162 19.1314 63.0528 21.5916C63.0355 21.7094 63.0257 21.8298 63.0255 21.9488C63.024 23.1584 63.0241 24.368 63.0242 25.5776L63.0243 26.4962L63.0244 26.5461C63.0246 26.6071 63.0248 26.668 63.0221 26.7288C63.0054 27.1231 62.7161 27.4063 62.3305 27.4076C61.9347 27.4089 61.6439 27.1208 61.632 26.7149C61.6308 26.6743 61.631 26.6335 61.6312 26.5928L61.6314 26.5457V17.7469L61.6312 17.695C61.6309 17.6489 61.6307 17.6027 61.6322 17.5566C61.646 17.1663 61.9402 16.8774 62.3235 16.877C62.7091 16.8765 63.0075 17.16 63.0211 17.5496C63.0261 17.696 63.0255 17.843 63.0249 17.9895C63.0245 18.0752 63.0241 18.1618 63.0249 18.2474C63.0253 18.2918 63.0284 18.3364 63.0325 18.3957L63.0327 18.3974C63.0347 18.4275 63.0371 18.4613 63.0395 18.5008Z", fill: "currentColor" }),
    React.createElement("path", { d: "M85.4884 5.86795L85.6822 5.68002C86.9101 4.49211 88.3825 3.96542 90.0774 4.11581C92.5569 4.33559 94.5901 6.24669 94.9871 8.70438C95.0396 9.0297 95.0558 9.3639 95.0577 9.69409C95.0637 10.8375 95.0624 11.9809 95.0611 13.1242L95.0603 13.9664C95.0603 14.0648 95.0592 14.1648 95.0427 14.2615C94.9903 14.5657 94.7206 14.792 94.4025 14.8077C94.085 14.8233 93.8106 14.642 93.726 14.3434C93.696 14.2374 93.6949 14.1206 93.6947 14.0089C93.6919 13.0919 93.6904 12.175 93.6888 11.2581C93.6879 10.7087 93.687 10.1591 93.6858 9.60969C93.6841 8.85287 93.505 8.14003 93.1054 7.49658C92.335 6.25537 91.2214 5.5639 89.7547 5.47548C87.6849 5.35068 85.7586 6.8343 85.4491 9.04747C85.4359 9.14156 85.4359 9.2379 85.4358 9.33367L85.4358 9.36412C85.4355 9.90157 85.4354 10.439 85.4354 10.9765V10.984C85.4352 11.9951 85.4351 13.0062 85.4335 14.0172C85.4335 14.1282 85.4246 14.245 85.3905 14.3493C85.2873 14.6649 84.9823 14.843 84.6413 14.8026C84.3202 14.7645 84.0774 14.5136 84.0469 14.1849C84.0406 14.1168 84.0407 14.048 84.0408 13.9793L84.0408 13.9525C84.0406 11.0125 84.0406 8.07256 84.0408 5.1326L84.0407 5.09236C84.0404 5.03527 84.0402 4.97795 84.0446 4.92129C84.0744 4.54373 84.3756 4.2679 84.7488 4.27446C85.1189 4.2808 85.4193 4.56974 85.4335 4.94435C85.4394 5.10396 85.4381 5.26398 85.4367 5.42391C85.4361 5.4963 85.4355 5.56985 85.4356 5.64216V5.84797L85.4581 5.85647L85.4884 5.86795Z", fill: "currentColor" }),
    React.createElement("path", { d: "M41.4238 16.6622C38.7688 17.0709 36.7802 19.4133 36.7815 22.0852C36.7893 22.1758 36.7953 22.2667 36.8014 22.3576C36.8144 22.555 36.8276 22.7529 36.8587 22.9474C37.3317 25.8998 40.0142 27.8717 42.8511 27.5772C43.994 27.4586 45.0004 27.0311 45.8776 26.2937C45.9853 26.2034 46.0931 26.1099 46.1843 26.0035C46.4119 25.7378 46.3973 25.3492 46.1608 25.0963C45.9244 24.8433 45.5394 24.8044 45.2564 25.0081C45.2175 25.0361 45.1823 25.0689 45.1471 25.1018C45.1307 25.1171 45.1143 25.1324 45.0975 25.1472C44.0955 26.0331 42.9283 26.3906 41.6116 26.1702C39.4266 25.8044 37.8679 23.7446 38.2211 21.4479C38.5084 19.5804 40.1132 18.0923 42.0012 17.972C43.1682 17.8975 44.1962 18.2508 45.0755 19.0264C45.0894 19.0387 45.1031 19.0513 45.1168 19.0639C45.1551 19.0991 45.1935 19.1345 45.2365 19.1628C45.4685 19.3158 45.7147 19.3295 45.9571 19.1984C46.1928 19.0708 46.3091 18.8633 46.3108 18.5919C46.3123 18.3442 46.1856 18.1676 46.0088 18.0132C44.6876 16.8591 43.1591 16.3951 41.4238 16.6622Z", fill: "currentColor" }),
    React.createElement("path", { d: "M96.4964 16.5842C97.5034 16.6074 98.605 16.9182 99.6404 17.4717C99.9327 17.628 100.074 17.9138 100.016 18.1867C99.9351 18.5691 99.5385 18.7705 99.1598 18.6182C99.1016 18.5948 99.0463 18.5639 98.991 18.533L98.961 18.5163L98.9346 18.5018C98.2336 18.1239 97.4962 17.8554 96.6998 17.7719C96.16 17.7152 95.6281 17.7484 95.1244 17.9716C94.6297 18.1907 94.2618 18.5338 94.1383 19.081C94.0222 19.5963 94.1345 20.0546 94.5601 20.3912C94.768 20.5555 94.9964 20.7116 95.2403 20.81C95.6213 20.9635 96.0101 21.0978 96.3989 21.232C96.562 21.2883 96.726 21.3449 96.8885 21.4027C97.0391 21.4562 97.1901 21.5088 97.3411 21.5614C97.7085 21.6895 98.0759 21.8175 98.4381 21.9588C98.7998 22.0998 99.1323 22.3023 99.4329 22.5521C99.992 23.0164 100.277 23.6099 100.293 24.3382C100.304 24.8395 100.229 25.3213 100.013 25.7761C99.6724 26.493 99.108 26.9702 98.3859 27.2707C97.6957 27.558 96.9704 27.646 96.2313 27.5956C94.9689 27.5098 93.8064 27.1131 92.7441 26.4251L92.7351 26.4192C92.6494 26.3638 92.5629 26.3079 92.4857 26.2419C92.2308 26.0242 92.1864 25.6587 92.3786 25.4053C92.5745 25.1473 92.9294 25.0849 93.2091 25.2735C93.8445 25.7021 94.518 26.0492 95.2611 26.2476C95.8994 26.4181 96.5467 26.4985 97.2066 26.3976C97.5802 26.3405 97.9345 26.2258 98.2448 26.005C98.8146 25.5995 99.0494 25.0445 98.954 24.353C98.9039 23.9896 98.7038 23.7078 98.4151 23.5008C98.1934 23.3419 97.9571 23.1866 97.7044 23.0908C97.1588 22.884 96.6011 22.7084 96.0435 22.5354L95.9555 22.5082L95.8413 22.473C95.3769 22.3303 94.9128 22.1876 94.4706 21.9822C94.0977 21.8092 93.748 21.6004 93.4506 21.3126C93.038 20.9132 92.8112 20.4278 92.7689 19.8543C92.6488 18.2328 93.6452 17.2022 94.8896 16.798C95.3558 16.6466 95.8358 16.5816 96.4964 16.5842Z", fill: "currentColor" }),
    React.createElement("path", { d: "M90.0689 21.1392C90.0689 22.9295 90.0689 24.7198 90.0687 26.5102L90.0687 26.5481C90.0689 26.6272 90.0691 26.7063 90.0615 26.7847C90.0264 27.1462 89.7442 27.4034 89.3825 27.4079C89.0146 27.4125 88.7158 27.1506 88.6762 26.7864C88.67 26.7291 88.6703 26.6709 88.6706 26.6129L88.6707 26.5751V26.4775C88.6705 22.8731 88.6703 19.2685 88.6718 15.6641C88.6718 15.5594 88.6754 15.4509 88.7018 15.3506C88.793 15.0065 89.091 14.811 89.4548 14.8445C89.778 14.8741 90.0325 15.1351 90.0627 15.4725C90.0692 15.5435 90.069 15.6153 90.0687 15.6869L90.0687 15.7259C90.0689 17.5304 90.0689 19.3347 90.0689 21.1392Z", fill: "currentColor" }),
    React.createElement("path", { d: "M55.6796 8.80059C55.6796 7.56747 55.6795 6.33446 55.6809 5.10129C55.6809 5.00357 55.6856 4.90288 55.7093 4.80876C55.7899 4.48999 56.0644 4.29074 56.3978 4.29793C56.7292 4.30512 56.9922 4.51855 57.0598 4.84218C57.077 4.92404 57.0827 5.0097 57.0827 5.09347C57.0837 8.06748 57.0837 11.0415 57.0827 14.0155C57.0827 14.0925 57.0785 14.171 57.0639 14.2463C56.9938 14.605 56.6977 14.8299 56.3314 14.8074C55.9836 14.7863 55.7141 14.5234 55.682 14.1712C55.6778 14.1252 55.6785 14.079 55.6792 14.0327C55.6794 14.0155 55.6797 13.9982 55.6797 13.981L55.6796 8.80059Z", fill: "currentColor" }),
    React.createElement("path", { d: "M49.0262 10.4466C49.0264 11.6363 49.0265 12.8259 49.0245 14.0153C49.0243 14.1333 49.0196 14.2584 48.9815 14.3679C48.8728 14.6814 48.5661 14.8477 48.2211 14.7999C47.9131 14.7569 47.6705 14.5046 47.6394 14.1905C47.634 14.1367 47.6346 14.0822 47.6351 14.0278C47.6352 14.0116 47.6354 13.9953 47.6354 13.9791L47.6354 12.2109C47.6353 9.84794 47.6353 7.48506 47.6358 5.12206L47.6357 5.08218C47.6352 5.00366 47.6348 4.92433 47.6498 4.84856C47.7204 4.49172 48.0424 4.26497 48.4151 4.30177C48.7639 4.33625 49.0177 4.60848 49.0245 4.96807C49.0286 5.19174 49.0278 5.41563 49.0269 5.6395C49.0265 5.73277 49.0262 5.82604 49.0262 5.91929L49.0262 10.4466Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgSiliconcanalsIcon);
var Memo = memo(ForwardRef);
export default Memo;
