import {
  ArrowIcon,
  formatCurrency,
  GiftIcon,
  GrunfinLogo,
  PlusIcon,
  useMedia,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import tw from 'twin.macro';
import { useSession } from '~/modules/auth/SessionProvider';
import { SelectedProgram } from '~/modules/auth/types';

import { useReferralApplied } from '~/modules/onboarding/hooks';
import { ActionListItem, NavigationActions } from './Actions';
import { useNavigationContext } from './Context';

export { NavigationBarProvider, useNavigationContext, useNavigationTitle } from './Context';

const useGetTitle = (lastPart?: boolean) => {
  const { title } = useNavigationContext();
  if (!Array.isArray(title)) return title;

  if (lastPart) {
    const last = title[title.length - 1];
    return typeof last === 'string' ? last : last[0];
  }

  return title
    .map<ReactNode>((text, i) => {
      if (typeof text === 'string') {
        if (i !== title.length - 1) {
          return (
            <span key={text + i} tw="text-gray-300">
              {text}
            </span>
          );
        }
        return text;
      }
      const [label, link] = text;

      return (
        <Link key={label + i} tw="text-alps-blue-400 hover:underline" to={link}>
          {label}
        </Link>
      );
    })
    .reduce((prev, curr, i) => [prev, <ArrowIcon key={i} tw="mx-4 text-gray-300" />, curr]);
};

export const Navigation = () => {
  const mobileTitle = useGetTitle(true);
  const referralApplied = useReferralApplied();
  const { t } = useTranslation('general');

  return (
    <>
      <div tw="bg-white">
        <nav tw="relative p-4 md:p-8 min-h-[56px]">
          <div
            tw="absolute left-0 bottom-0 h-0.5 -my-0.5 transition-all duration-500 bg-alps-blue-400"
            style={{ width: 'var(--nav-progress, 0%)', maxWidth: '100%' }}
          />

          <div tw="flex flex-row items-center justify-between gap-24">
            <div tw="flex flex-row flex-wrap gap-4 items-center">
              <Link to="/" tw="sm:pr-4 md:border-r-2 border-gray-50">
                <GrunfinLogo width={24} height={24} />
              </Link>
              <h1 tw="text-lg xl:text-xl text-alps-blue-800 hidden md:flex items-center font-semibold md:min-h-[40px]">
                {useGetTitle()}
              </h1>
              {referralApplied && (
                <p tw="rounded-full bg-alps-blue-200 px-2 py-1 text-alps-blue-600 text-sm text-center hidden md:block">
                  {t('referral_applied')}
                </p>
              )}
            </div>

            <NavigationActions />
          </div>
        </nav>
      </div>
      {mobileTitle && (
        <div tw="flex flex-col w-full items-center">
          <h1 tw="text-xl font-semibold text-alps-blue-800 md:hidden text-center mt-8 mb-2 mx-5">{mobileTitle}</h1>
          {referralApplied && (
            <p tw="rounded-full bg-alps-blue-200 px-2 py-1 text-alps-blue-600 text-sm text-center block md:hidden max-w-max">
              {t('referral_applied')}
            </p>
          )}
        </div>
      )}
    </>
  );
};

type NavigationListItem = {
  icon?: ReactNode;
  label: string;
  to?: string;
  href?: string;
  disabled?: boolean;
  component?: ReactNode;
};

const mapNavigationItems = (list: NavigationListItem[]) => {
  return list.map((item, index) => {
    const isRouterLink = item.to != null;
    const isComponent = 'component' in item;
    const isDisabled = 'disabled' in item && item.disabled;
    const linkStyle = [tw`flex flex-row items-center gap-2 text-gray-900`, isDisabled && tw`cursor-not-allowed`];

    return (
      <ActionListItem key={index}>
        {isRouterLink && (
          <Link to={item.to as string} css={linkStyle} {...(isDisabled && { onClick: (e) => e.preventDefault() })}>
            {isComponent ? (
              item.component
            ) : (
              <Fragment>
                {item.icon}
                {item.label}
              </Fragment>
            )}
          </Link>
        )}
        {!isRouterLink && (
          <a css={linkStyle} href={item.href} target="_blank" rel="noopener">
            {isComponent ? (
              item.component
            ) : (
              <Fragment>
                {item.icon}
                {item.label}
              </Fragment>
            )}
          </a>
        )}
      </ActionListItem>
    );
  });
};

export const useGetMembershipCompany = (): SelectedProgram | undefined => {
  const match = useMatch('/company/:companyId/benefit/:benefitId/*');
  const { companyId, benefitId } = match?.params ?? {};
  const companies = useSession()?.companies;
  const [selectedProgram, setSelectedProgram] = useState<SelectedProgram>();

  useEffect(() => {
    if (companies && companyId) {
      const company = companies.find((company) => company.companyId === companyId);
      const benefitProgram = company?.accessibleBenefitPrograms?.find((benefit) => benefit.id === benefitId);
      setSelectedProgram({ company, benefitProgram });
    } else if (companies) {
      const company = companies?.filter((c) => !!c.accessibleBenefitPrograms.length)?.[0];
      company && setSelectedProgram({ company, benefitProgram: company?.accessibleBenefitPrograms?.[0] });
    }
  }, [companies, companyId, benefitId]);

  return selectedProgram;
};

export const ApplicationBaseNavigation = () => {
  const { i18n, t } = useTranslation('application');
  const isMobile = useMedia('(max-width: 1024px)');
  const isBusinessRelatedPerson = useSession().companies?.some((value) => value.accessibleBenefitPrograms.length > 0);

  const defaultNavigationList: NavigationListItem[] = [
    {
      icon: <GiftIcon width={24} height={24} tw="bg-dusk-50 text-dusk-700 rounded" />,
      label: t('navigation.gift_card'),
      href: `https://www.grunfin.com/${i18n.resolvedLanguage}/gift`,
    },
    {
      icon: <PlusIcon width={24} height={24} tw="bg-alps-blue-50 text-alps-blue-700 rounded" />,
      label: t('navigation.new_portfolio'),
      to: '/onboarding',
    },
    {
      icon: (
        <span tw="flex items-center justify-center bg-alps-blue-50 w-6 h-6 rounded">
          <p tw="text-alps-blue-700 text-sm font-bold not-italic px-0.5" style={{ fontSize: 10, borderRadius: 4 }}>
            {formatCurrency(20).replace(/\xA0/g, '')}
          </p>
        </span>
      ),
      label: t('navigation.invite_friends', { amount: formatCurrency(20) }),
      to: '/account/referrals',
    },
    {
      icon: <UserIcon width={24} height={24} tw="bg-gray-50 text-gray-700 rounded" />,
      label: t('navigation_title', { ns: 'account' }),
      to: '/account',
    },
    ...(isBusinessRelatedPerson
      ? [
          {
            label: t('navigation.business'),
            to: '/company',
          },
        ]
      : []),
    {
      label: t('sign_out', { ns: 'general' }),
      to: '/logout',
    },
  ];

  isMobile &&
    defaultNavigationList.unshift({
      icon: <GrunfinLogo width={20} height={20} tw="bg-alps-blue-50 text-alps-blue-700 rounded" />,
      label: t('footer.home'),
      to: '/',
    });
  return <Fragment>{mapNavigationItems(defaultNavigationList)}</Fragment>;
};

export const CompanyBenefitBaseNavigation = () => {
  const { t } = useTranslation('company');
  const match = useMatch('/company/:companyId/benefit/:benefitId/*');
  const { companyId, benefitId } = match?.params ?? {};

  const list = [
    // {
    //   label: t('impact_report.title'),
    //   to: '',
    //   disabled: true,
    //   component: (
    //     <Trans
    //       i18nKey="impact_report.title"
    //       t={t}
    //       components={[
    //         <span
    //           key="0"
    //           tw="bg-alps-blue-100 text-alps-blue-800 rounded-full py-1 px-2 flex items-center justify-center text-sm font-bold max-w-max"
    //         />,
    //       ]}
    //     />
    //   ),
    // },
    {
      label: t('journals.title'),
      to: `/company/${companyId}/benefit/${benefitId}/journals`,
    },
    {
      label: t('payments.title'),
      to: `/company/${companyId}/benefit/${benefitId}/payments`,
    },
    {
      label: t('fees.title'),
      to: `/company/${companyId}/benefit/${benefitId}/fees`,
    },
    {
      label: t('assets.title'),
      to: `/company/${companyId}/benefit/${benefitId}/assets`,
    },
    {
      label: t('employees.upload.title'),
      to: `/company/${companyId}/benefit/${benefitId}/memberships/upload`,
    },
  ];

  return <Fragment>{mapNavigationItems(list)}</Fragment>;
};
