var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgTreesIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M20 20V20.4C21.3117 20.9044 22.4046 21.8532 23.0884 23.081C23.7721 24.3087 24.0032 25.7375 23.7413 27.1182C23.4795 28.4989 22.7413 29.7438 21.6555 30.636C20.5697 31.5281 19.2053 32.0109 17.8 32V32H10C8.61154 31.9655 7.27807 31.4503 6.22702 30.5424C5.17597 29.6344 4.47246 28.39 4.23648 27.0213C4.0005 25.6526 4.24666 24.2444 4.93298 23.037C5.61931 21.8295 6.70328 20.8975 8.00001 20.4V20C8.00001 18.4087 8.63215 16.8826 9.75737 15.7574C10.8826 14.6321 12.4087 14 14 14C15.5913 14 17.1174 14.6321 18.2426 15.7574C19.3679 16.8826 20 18.4087 20 20V20Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M14 32V44", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M26 38V44", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24 38H40.6C40.9915 37.9945 41.3727 37.8742 41.6964 37.6541C42.0202 37.434 42.2722 37.1237 42.4213 36.7617C42.5703 36.3997 42.6099 36.0019 42.535 35.6176C42.4601 35.2334 42.2741 34.8795 42 34.6L36 28H36.6C36.9915 27.9945 37.3727 27.8742 37.6964 27.6541C38.0202 27.434 38.2722 27.1237 38.4213 26.7617C38.5703 26.3997 38.6099 26.0019 38.535 25.6176C38.4601 25.2334 38.2741 24.8795 38 24.6L32 18H32.4C32.8085 18.0369 33.2185 17.9472 33.5743 17.7432C33.9302 17.5393 34.2147 17.2308 34.3893 16.8597C34.564 16.4886 34.6203 16.0727 34.5507 15.6685C34.481 15.2643 34.2888 14.8913 34 14.6L26 6L23.2 9", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }))); };
var ForwardRef = forwardRef(SvgTreesIcon);
var Memo = memo(ForwardRef);
export default Memo;
