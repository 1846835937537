import React from 'react';
import 'twin.macro';
import { Button, ButtonVariant, Trans, useTranslation } from '@grunfin/ui-kit';
import { matchPath, useLocation, useOutletContext } from 'react-router-dom';
import { activationMatchHash, ActivationViewType, UBOBasePath } from '~/modules/portfolio/ActivateView/ActivationView';
import { Disclaimers, FooterVariant } from '~/modules/application/Footer';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';

export const WelcomeScreen = () => {
  const { t } = useTranslation('onboarding');
  const context = useOutletContext<OnboardingOutletContext>();
  const { givenName, inviterName, companyName, onContinue } = context ?? {};
  const { pathname } = useLocation();
  const isCompanyUBOViewByRoute =
    matchPath(activationMatchHash[ActivationViewType.COMPANY_UBO], pathname) != null ||
    matchPath(UBOBasePath, pathname) != null;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        typeof onContinue !== 'undefined' && onContinue();
      }}
      tw="flex flex-col flex-grow md:flex-1"
    >
      <div tw="flex flex-1 p-4 mt-10 md:mt-0 md:items-center">
        <div tw="flex flex-col gap-4 text-white max-w-md font-medium text-lg pb-20">
          {isCompanyUBOViewByRoute ? (
            <p tw="text-lg text-neutral-50">
              <Trans
                i18nKey={inviterName ? 'onboarding:ubo.welcome.title' : 'onboarding:ubo.welcome.titleWithoutInviter'}
                components={[<span key="0" tw="text-vivid-green-300" />]}
                values={{ inviterName, companyName }}
              />
            </p>
          ) : (
            <>
              <h2>
                <Trans i18nKey="onboarding:employee.welcome.title" values={{ name: givenName }} />
              </h2>
              <p>
                <Trans i18nKey="onboarding:employee.welcome.invite" values={{ name: companyName }} />
              </p>
              <p>{t('employee.welcome.guide')}</p>
            </>
          )}
          <div tw="w-full md:w-auto">
            <Button variant={ButtonVariant.NEW_PRIMARY} type="submit" data-test-id="onboarding-continue">
              {t('employee.welcome.continue')}
            </Button>
          </div>
        </div>
      </div>
      <div tw="sm:absolute mt-auto mx-auto sm:left-4 sm:right-4 sm:bottom-10 px-4 pb-10 sm:px-0 sm:pb-0 max-w-screen-lg">
        <Disclaimers variant={FooterVariant.DARK} />
      </div>
    </form>
  );
};
