var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDatePicker } from '@rehookify/datepicker';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getFirstDayStartOfWeekByLocale } from '../../utils';
var DatePicker = function (props) {
    var _a;
    var i18n = useTranslation().i18n;
    var _b = useState((_a = props.initialDates) !== null && _a !== void 0 ? _a : []), selectedDates = _b[0], onDatesChange = _b[1];
    var handleDateChange = function (d) {
        onDatesChange(d);
        typeof props.onDateChange === 'function' && props.onDateChange(d);
    };
    var firstDayOfWeek = getFirstDayStartOfWeekByLocale(selectedDates[0]);
    var _c = useDatePicker({
        selectedDates: selectedDates,
        onDatesChange: function (d) { return handleDateChange(d); },
        locale: {
            locale: i18n.language,
        },
        dates: props.dates,
        calendar: {
            startDay: firstDayOfWeek,
        },
    }), _d = _c.data, calendars = _d.calendars, weekDays = _d.weekDays, _e = _c.propGetters, dayButton = _e.dayButton, nextMonthButton = _e.nextMonthButton, previousMonthButton = _e.previousMonthButton;
    var _f = calendars[0], month = _f.month, year = _f.year, days = _f.days;
    var changeButtonStyle = 'h-8 flex w-8 min-w-[2rem] justify-center items-center bg-gray-50 hover:bg-gray-100 text-gray-900 disabled:text-gray-300 disabled:hover:bg-gray-50 disabled:cursor-not-allowed text-lg rounded';
    return (React.createElement("div", { className: "block p-4 rounded shadow" },
        React.createElement("div", { className: "flex flex-col gap-2 select-none" },
            React.createElement("div", { className: "flex flex-row items-center gap-2" },
                React.createElement("button", __assign({ className: changeButtonStyle }, previousMonthButton()), "\u2039"),
                React.createElement("p", { className: "w-full text-sm text-center" },
                    month,
                    " ",
                    year),
                React.createElement("button", __assign({ className: changeButtonStyle }, nextMonthButton()), "\u203A")),
            React.createElement("ol", { className: "grid items-center h-8 grid-cols-7 gap-y-2" }, weekDays.map(function (d) { return (React.createElement("li", { key: d, className: "w-8 text-sm text-center" }, d)); })),
            React.createElement("ol", { className: "grid grid-cols-7 gap-y-2 gap-x-1" }, days.map(function (d) {
                return (React.createElement("li", { key: d.$date.toString() },
                    React.createElement("button", __assign({ className: twMerge('h-8 w-8 flex justify-center items-center rounded text-base text-gray-900', (!d.inCurrentMonth || d.disabled) && "text-gray-300", d.disabled && 'text-gray-200 cursor-not-allowed', d.now && 'bg-gray-50 font-semibold', d.selected && 'bg-alps-blue-800 text-white font-semibold', d.now && d.selected && 'text-white', d.now && !d.selected && 'text-alps-blue-800', !d.disabled && !d.selected && !d.inCurrentMonth && 'hover:bg-gray-50', !d.disabled && !d.selected && d.inCurrentMonth && 'hover:bg-gray-100') }, dayButton(d)), d.day)));
            })))));
};
export default DatePicker;
