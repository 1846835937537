import tw, { styled } from 'twin.macro';

interface Props {
  primary?: boolean;
  noMargin?: boolean;
}

export const Heading = styled.h3<Props>(({ primary, noMargin }) => [
  tw`block px-6 py-2 mb-6 text-lg md:text-alps-blue-700 md:bg-transparent md:font-bold bg-gray-50 md:mb-3`,
  primary && !noMargin && tw`mb-10 md:mb-12`,
  noMargin && tw`mb-0`,
]);
