import React, { useMemo } from 'react';
import { useTranslation } from '@grunfin/ui-kit';
import { compact } from 'lodash-es';
import Cookies from 'js-cookie';

import type { MifidQuestionnaire, PortfolioQuestionnaire, QuestionnaireInvestments, QuestionnaireType } from './types';
import { isDev } from '~/utils';

const getCookieDomain = () => `.grunfin.${isDev ? 'dev' : 'com'}`;

export const setQuestionnaireId = (type: QuestionnaireType, id: string) =>
  localStorage.setItem(`${type}-questionnaire-id`, id);

export const getQuestionnaireId = (type: QuestionnaireType) => {
  const localQuestionnaire = localStorage.getItem(`${type}-questionnaire-id`);
  const cookieQuestionnaire = Cookies.get(`${type}-questionnaire-id`);

  const setAndForget = (questionnaireId: string) => {
    setQuestionnaireId(type, questionnaireId);
    Cookies.remove(`${type}-questionnaire-id`, { path: '/', domain: getCookieDomain() });
    return questionnaireId;
  };

  if (localQuestionnaire != null) {
    if (cookieQuestionnaire != null) return setAndForget(cookieQuestionnaire);
    return localQuestionnaire;
  }

  if (localQuestionnaire == null && cookieQuestionnaire != null) return setAndForget(cookieQuestionnaire);

  return undefined;
};

export const removeQuestionnaireId = (...types: QuestionnaireType[]) =>
  types.forEach((type) => localStorage.removeItem(`${type}-questionnaire-id`));

export const deserializePortfolioAreas = (portfolio: PortfolioQuestionnaire) => {
  const areas = [];
  if (portfolio.areaClimateChange) areas.push('climate_change');
  if (portfolio.areaGenderEquality) areas.push('gender_equality');
  if (portfolio.areaHealth) areas.push('health');
  if (portfolio.areaSP500) areas.push('sp500');
  return areas;
};

export const serializePortfolioAreas = (value: string[]) => ({
  areaClimateChange: value.includes('climate_change'),
  areaGenderEquality: value.includes('gender_equality'),
  areaHealth: value.includes('health'),
  areaSP500: value.includes('sp500'),
});

export const serializePortfolioInvestments = (value: QuestionnaireInvestments) => ({
  monthlyInvestmentAmountEur: value?.monthlyInvestmentAmountEur,
  upfrontInvestmentAmountEur: value?.upfrontInvestmentAmountEur,
});

export const deserializeMifidExperience = (mifid: MifidQuestionnaire) => {
  const experience = [];
  if (mifid.experienceShares) experience.push('shares');
  if (mifid.experienceFunds) experience.push('funds');
  if (mifid.experienceNoInvestmentExperience) experience.push('no_experience');
  return experience;
};

export const serializeMifidExperience = (value: string[]) => ({
  experienceShares: value.includes('shares'),
  experienceFunds: value.includes('funds'),
  experienceNoInvestmentExperience: value.includes('no_experience'),
});

export const useComposeWarningMessages = () => {
  const messages = useWarningMessages();

  return (
    wouldWithdrawOnValueDrop: boolean | null,
    savingsForThreeMonths: boolean | null,
    moreDebtThanAssets: boolean | null,
    notLongTermInvestor: boolean | null,
    noKnowledgeOnPastPerformance: boolean,
  ) => {
    const warnings = [
      noKnowledgeOnPastPerformance && messages.noKnowledgeOnPastPerformance,
      wouldWithdrawOnValueDrop && messages.wouldWithdraw,
      (!savingsForThreeMonths || moreDebtThanAssets) && messages.lackAbilityToBearLoss,
      !savingsForThreeMonths && messages.cantCoverLivingExpenses,
      moreDebtThanAssets && messages.moreDebtThanAssets,
      !notLongTermInvestor && messages.notLongTermInvestor,
    ];

    return { acceptedWarningMessage: compact(warnings).join(' | ') };
  };
};

export const useWarningMessages = () => {
  const { t } = useTranslation('onboarding');

  return useMemo(
    () => ({
      wouldWithdraw: t('warning.would_withdraw'),
      lackAbilityToBearLoss: t('warning.lack_ability_to_bear_loss'),
      cantCoverLivingExpenses: t('warning.cant_cover_living_expenses'),
      moreDebtThanAssets: t('warning.more_debt_than_assets'),
      moreDebtThanAssetsCompany: t('warning.more_debt_than_assets_company'),
      notLongTermInvestor: t('warning.not_long_term_investor'),
      // TODO: translate this message as well
      noKnowledgeOnPastPerformance:
        'To familiarise yourself with different types of risks related to investing, please consult <a href="https://faq.grunfin.com/en/articles/5497-what-are-the-risks-related-to-my-portfolio">our FAQ.</a>',
    }),
    [t],
  );
};

export const getAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
  const amount = parseFloat(e.target.value);
  if (isNaN(amount)) {
    return '';
  } else if (amount < 0) {
    return '0';
  } else {
    return amount.toString();
  }
};
