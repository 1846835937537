import { Button, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Instruction } from '~/modules/portfolio/components/Instruction';
import { usePortfolio } from '~/modules/portfolio/hooks';
import { DirectDebitConsent } from '~/modules/portfolio/types';
import { trackDirectDebitRevoke } from '~/utils/tracking-analytics';

import { RevokeModal, RevokeReason } from './RevokeModal';

interface Props {
  consent: DirectDebitConsent;
}

export const DirectDebit = ({ consent }: Props) => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const navigate = useNavigate();

  const onSubmit = (reason: RevokeReason) => {
    trackDirectDebitRevoke(portfolio.id, reason);

    switch (reason) {
      case RevokeReason.CHANGE_INFO:
        return navigate('set-up/direct-debit');
      case RevokeReason.STANDING_ORDER:
        return navigate('set-up/standing-order');
      case RevokeReason.REDUCE_CONTRIBUTIONS:
        return navigate(`/portfolio/${portfolio.id}/settings?amount=0`);
    }
  };
  return (
    <div>
      <Instruction label={t('details.payment.direct_debit.title')}>
        {consent.nameOnAccount} · {consent.iban}
      </Instruction>
      <div tw="flex space-x-2 mt-6 w-max">
        <RevokeModal onSubmit={onSubmit} portfolio={portfolio}>
          <Button variant={ButtonVariant.SECONDARY}>{t('details.payment.direct_debit.revoke')}</Button>
        </RevokeModal>
        <Button variant={ButtonVariant.SECONDARY} onClick={() => navigate('set-up/direct-debit')}>
          {t('details.payment.direct_debit.update')}
        </Button>
      </div>
    </div>
  );
};
