import {
  BeneficiaryType,
  CheckIcon,
  ContractStatus,
  PaymentStatus,
  Portfolio,
  TimeIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import React, { ReactNode } from 'react';
import tw from 'twin.macro';

import { Link } from 'react-router-dom';
import { useSession } from '~/modules/auth/SessionProvider';
import { VerificationStatus } from '../auth/types';
import { EmployeeOnboardingLink, useEmployeeOnboardingBaseRoute } from '../benefits/enroll/EmployeeOnboardingView';
import { ActivationLink } from './ActivateView/ActivationView';

interface Props {
  portfolio: Portfolio;
}

enum ListItemIdentifier {
  CONTRACT_NOT_SIGNED = 'contract_not_signed',
  CONTRACT_PENDING = 'contract_pending',
  CONTRACT_IN_REVIEW = 'contract_in_review',
  CONTRACT_SIGNED = 'contract_signed',
  PAYMENT_NOT_STARTED = 'payment_not_started',
  PAYMENT_SET_UP = 'payment_set_up',
  PAYMENT_RECEIVED = 'payment_received',
  VERIFICATION_INCOMPLETE = 'verification_incomplete',
  VERIFICATION_PENDING = 'verification_pending',
  VERIFICATION_COMPLETE = 'verification_complete',
}

type ListItem = {
  id: ListItemIdentifier;
  title: string;
  to: string;
  visible?: boolean;
};

const findListObjectByIdentifier = (list: ListItem[], identifier: ListItemIdentifier): ListItem => {
  return list.find((item) => item.id === identifier) as ListItem;
};

export const OnboardingBanner = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');
  const { onboardingStatus, verification } = useSession();
  const enrollBasePath = useEmployeeOnboardingBaseRoute();
  if (!onboardingStatus) return null;
  const { payment, contract } = onboardingStatus;
  const retailBasePath = `/portfolio/${portfolio.id}/activate/`;
  const retailList: ListItem[] = [
    {
      id: ListItemIdentifier.VERIFICATION_INCOMPLETE,
      title: t('onboarding.verification.incomplete'),
      visible: verification === VerificationStatus.NOT_STARTED || verification === VerificationStatus.RESUBMIT,
      to: `${retailBasePath}${ActivationLink.VERIFICATION}`,
    },
    {
      id: ListItemIdentifier.VERIFICATION_PENDING,
      title: t('onboarding.verification.pending'),
      visible: verification === VerificationStatus.PENDING,
      to: `${retailBasePath}${ActivationLink.VERIFICATION}`,
    },
    {
      id: ListItemIdentifier.VERIFICATION_COMPLETE,
      title: t('onboarding.verification.complete'),
      visible: verification === VerificationStatus.COMPLETE,
      to: `${retailBasePath}${ActivationLink.VERIFICATION}`,
    },
    {
      id: ListItemIdentifier.CONTRACT_NOT_SIGNED,
      title: t('onboarding.contract.not_signed'),
      visible: contract === ContractStatus.NOT_SIGNED,
      to: `${retailBasePath}${ActivationLink.CONTRACT}`,
    },
    {
      id: ListItemIdentifier.CONTRACT_PENDING,
      title: t('onboarding.contract.pending'),
      visible: contract === ContractStatus.PENDING,
      to: `${retailBasePath}${ActivationLink.CONTRACT}`,
    },
    {
      id: ListItemIdentifier.CONTRACT_IN_REVIEW,
      title: t('onboarding.contract.in_review'),
      visible: contract === ContractStatus.IN_REVIEW,
      to: `${retailBasePath}${ActivationLink.CONTRACT}`,
    },
    {
      id: ListItemIdentifier.CONTRACT_SIGNED,
      title: t('onboarding.contract.signed'),
      visible: contract === ContractStatus.SIGNED,
      to: `${retailBasePath}${ActivationLink.CONTRACT}`,
    },
    {
      id: ListItemIdentifier.PAYMENT_NOT_STARTED,
      title: t('onboarding.payment.not_started'),
      visible: payment === PaymentStatus.NOT_STARTED,
      to: `${retailBasePath}${ActivationLink.INITIAL_PAYMENT}`,
    },
    {
      id: ListItemIdentifier.PAYMENT_SET_UP,
      title: t('onboarding.payment.set_up'),
      visible: payment === PaymentStatus.SET_UP,
      to: `${retailBasePath}${ActivationLink.INITIAL_PAYMENT}`,
    },
    {
      id: ListItemIdentifier.PAYMENT_RECEIVED,
      title: t('onboarding.payment.received'),
      visible: payment === PaymentStatus.RECEIVED,
      to: `${retailBasePath}${ActivationLink.INITIAL_PAYMENT}`,
    },
  ];

  const employeeList: ListItem[] = [
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.VERIFICATION_INCOMPLETE),
      to: `${enrollBasePath}${EmployeeOnboardingLink.VERIFICATION}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.VERIFICATION_PENDING),
      to: `${enrollBasePath}${EmployeeOnboardingLink.VERIFICATION}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.VERIFICATION_COMPLETE),
      to: `${enrollBasePath}${EmployeeOnboardingLink.VERIFICATION}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.CONTRACT_NOT_SIGNED),
      to: `${enrollBasePath}${EmployeeOnboardingLink.CONTRACT}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.CONTRACT_PENDING),
      to: `${enrollBasePath}${EmployeeOnboardingLink.CONTRACT}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.CONTRACT_IN_REVIEW),
      to: `${enrollBasePath}${EmployeeOnboardingLink.CONTRACT}`,
    },
    {
      ...findListObjectByIdentifier(retailList, ListItemIdentifier.CONTRACT_SIGNED),
      to: `${enrollBasePath}${EmployeeOnboardingLink.CONTRACT}`,
    },
  ];

  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  const list = isCorporate ? employeeList : retailList;
  const filteredList = (list as ListItem[]).filter((item) => item.visible);

  const PENDING_STATES = [
    ListItemIdentifier.CONTRACT_PENDING,
    ListItemIdentifier.CONTRACT_IN_REVIEW,
    ListItemIdentifier.PAYMENT_SET_UP,
    ListItemIdentifier.VERIFICATION_PENDING,
  ];

  const COMPLETED_STATES = [
    ListItemIdentifier.CONTRACT_SIGNED,
    ListItemIdentifier.PAYMENT_RECEIVED,
    ListItemIdentifier.VERIFICATION_COMPLETE,
  ];

  if (filteredList.length === 0) return null;

  return (
    <div tw="flex flex-col sm:flex-row gap-4 md:rounded-2xl bg-alps-blue-300 text-white px-8 md:px-10 py-6 md:flex mb-10">
      <div tw="text-lg mb-6 md:mb-0 md:text-xl md:w-2/6 font-bold md:font-semibold">{t('onboarding.banner.title')}</div>
      <div tw="flex flex-col sm:flex-row gap-8">
        {filteredList.map((item, i) => {
          const isPending = PENDING_STATES.includes(item.id as ListItemIdentifier);
          const isCompleted = COMPLETED_STATES.includes(item.id as ListItemIdentifier);
          const link = isCorporate ? enrollBasePath : retailBasePath;

          return (
            <Link key={i} to={isCompleted ? item.to : link} tw="md:flex items-center justify-between">
              <Step pending={isPending} done={isCompleted}>
                {item.title}
              </Step>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const Step = ({ done, pending, children }: { done?: boolean; pending?: boolean; children: ReactNode }) => (
  <div tw="flex sm:flex-col items-center text-center gap-4">
    {pending ? (
      <TimeIcon width={24} height={24} tw="bg-white rounded text-alps-blue-300" />
    ) : (
      <CheckIcon
        width={24}
        height={24}
        tw="border-2 border-white rounded"
        css={done ? tw`bg-white text-alps-blue-300` : tw`opacity-40`}
      />
    )}
    <div tw="font-semibold md:mt-3">{children}</div>
  </div>
);
