import { Button, ButtonVariant, StarIcon, Trans, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

const PromotionalBanner = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('portfolio');

  return (
    <div tw="flex-row md:flex items-center md:justify-between gap-4 rounded shadow-md p-4 md:p-6 mb-8 md:mb-12 mx-4">
      <div tw="inline-flex items-center">
        <StarIcon width={36} height={36} tw="bg-alps-blue-50 text-alps-blue-700 rounded min-w-[36px]" />

        <p tw="text-base md:text-lg ml-2">
          <Trans t={t} i18nKey="promo_banner.title" />
        </p>
      </div>
      <div tw="flex flex-row md:flex-col ml-11 mt-3 md:mt-0 md:ml-0">
        <Button variant={ButtonVariant.PRIMARY} onClick={onClick}>
          <span tw="whitespace-nowrap">{t('promo_banner.cta')}</span>
        </Button>
      </div>
    </div>
  );
};

export default PromotionalBanner;
