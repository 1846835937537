import { Tabs, useTranslation } from '@grunfin/ui-kit';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'twin.macro';

import { useDirectDebitEnabled } from '~/modules/portfolio/hooks';

import { Container } from './Container';
import { DirectDebit } from './DirectDebit';
import { ManualPayment } from './ManualPayment';
import { PaymentSuccess } from './PaymentSuccess';

enum Method {
  DIRECT = 'direct',
  MANUAL = 'manual',
}

export const TopUp = () => {
  const { t } = useTranslation('portfolio');
  const [query] = useSearchParams();
  const [isSuccess, setSuccess] = useState(false);
  const directDebitEnabled = useDirectDebitEnabled();

  if (isSuccess || query.has('success')) return <PaymentSuccess />;

  const options = [
    {
      label: t('details.payment.top_up.method.direct_debit'),
      value: Method.DIRECT,
      component: <DirectDebit onSuccess={() => setSuccess(true)} />,
    },
    {
      label: t('details.payment.top_up.method.manual_payment'),
      value: Method.MANUAL,
      component: <ManualPayment />,
    },
  ];

  return <Container>{directDebitEnabled ? <Tabs options={options} /> : <ManualPayment />}</Container>;
};
