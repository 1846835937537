var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgCloudIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "-6 -9 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M4.5 14C2.01472 14 0 11.9853 0 9.5C0 7.52288 1.27506 5.84356 3.04774 5.23949C3.42147 2.28485 5.94391 0 9 0C11.277 0 13.2577 1.26836 14.2742 3.13709C14.6684 3.04738 15.0787 3 15.5 3C18.5376 3 21 5.46243 21 8.5C21 11.5376 18.5376 14 15.5 14C11.8353 14 8.16621 14 4.5 14Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgCloudIcon);
var Memo = memo(ForwardRef);
export default Memo;
