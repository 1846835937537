var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { formatDate } from '../../utils';
import { Input } from '../Input';
import { Tooltip } from '../Tooltip';
import DatePicker from '.';
var InputDatePicker = function (props) {
    var _a = useState(props.initialDates), values = _a[0], setValues = _a[1];
    return (React.createElement(Tooltip, { content: React.createElement(DatePicker, __assign({}, props, { onDateChange: function (dates) {
                setValues(dates);
                typeof props.onDateChange === 'function' && props.onDateChange(dates);
            } })), showOnFocus: false, showOnHover: false },
        React.createElement(Input, { readOnly: true, value: values ? values.map(function (value) { return formatDate(value); }).join(', ') : values })));
};
export default InputDatePicker;
