import { i18n } from '@grunfin/ui-kit';

const languages = ['en', 'de', 'et'];
const namespaces = [
  'account',
  'application',
  'auth',
  'countries',
  'general',
  'gift',
  'onboarding',
  'portfolio',
  'referral',
  'support',
  'company',
];

languages.forEach((language) => {
  namespaces.forEach(async (namespace) => {
    const hasResourceBundle = i18n.hasResourceBundle(language, namespace);
    if (hasResourceBundle) return;
    const resource = await import(`../src/locales/${language}/${namespace}.json`);
    i18n.addResourceBundle(language, namespace, resource.default, true, true);
  });
});
