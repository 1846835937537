import 'twin.macro';
import { Button, ButtonVariant, Field, Input, Spinner, useTranslation } from '@grunfin/ui-kit';
import { Dispatch, SetStateAction } from 'react';
import { AddEmployee } from '~/modules/company/types';
import { UseMutationResult } from 'react-query';
import { KyResponse } from 'ky';
import { useForm } from 'react-hook-form';
import { setBackendValidationErrors } from '~/utils';
import tw from 'twin.macro';

const AddEmployeeForm = ({
  setCurrentStep,
  submitMembers,
  setIsSuccess,
}: {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  submitMembers: UseMutationResult<KyResponse, unknown, AddEmployee[]>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(['company', 'account']);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AddEmployee>({ mode: 'onSubmit' });

  const onSubmit = (values: AddEmployee) => {
    try {
      submitMembers.mutateAsync([values]);
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  submitMembers.isSuccess && setIsSuccess(true);

  const disabled = submitMembers.isLoading;
  return (
    <form tw="space-y-3 mx-2" onSubmit={handleSubmit((values) => onSubmit(values))}>
      <Field
        htmlFor="givenName"
        label={t('account:details.given_name.label')}
        help={t('account:details.given_name.help')}
        error={errors?.givenName?.message}
      >
        <Input
          {...register('givenName', { required: true })}
          error={'givenName' in errors}
          key="givenName"
          maxLength={100}
        />
      </Field>
      <Field
        htmlFor="lastName"
        label={t('account:details.last_name.label')}
        help={t('account:details.last_name.help')}
        error={errors?.lastName?.message}
      >
        <Input
          {...register('lastName', { required: true })}
          error={'lastName' in errors}
          key="lastName"
          maxLength={100}
        />
      </Field>

      <Field htmlFor="email" label={t('table.email')} help={t('table.email_help')} error={errors?.email?.message}>
        <Input
          {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          error={'email' in errors}
          key="email"
          maxLength={100}
        />
      </Field>

      <Field
        htmlFor="employerSideId"
        help={t('table.employer_side_id_help')}
        label={t('table.id')}
        error={errors?.employerSideId?.message}
      >
        <Input
          {...register('employerSideId', { required: true })}
          error={'employerSideId' in errors}
          key="employerSideId"
          maxLength={100}
        />
      </Field>

      <br />
      <div tw="flex flex-row flex-wrap justify-between items-center">
        <div>
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={() => setCurrentStep(() => 0)}
            disabled={disabled}
            type="button"
          >
            {t('back', { ns: 'general' })}
          </Button>
        </div>
        <div>
          <Button variant={ButtonVariant.PRIMARY} disabled={disabled} type="submit">
            <span css={[disabled && tw`opacity-0`]}>{t('confirm', { ns: 'general' })}</span>
            {disabled && (
              <div tw="absolute">
                <Spinner />
              </div>
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default AddEmployeeForm;
