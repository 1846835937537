import { DocumentIcon, Spinner, useTranslation } from '@grunfin/ui-kit';
import { MouseEventHandler } from 'react';
import 'twin.macro';

import { useDownloadFile } from '~/modules/account/queries';
import { downloadFileFromObjectUrl } from '~/modules/account/utils';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';

import { Heading } from '../components/Heading';
import { usePortfolio } from '../hooks';
import { useGetPortfolioStatements } from '../queries';
import { PortfolioStatement } from '../types';

export const Reports = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const statements = useGetPortfolioStatements(portfolio.id);

  if (statements.isIdle || statements.isLoading) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (statements.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={statements.refetch} error={statements.error} />;
  }

  const subtitle = statements.data.length ? t('details.reports.subtitle') : t('details.reports.placeholder');

  return (
    <div tw="flex flex-col md:w-3/5 md:self-center pt-8 pb-12">
      <Heading primary>{t('details.reports.title')}</Heading>
      <div tw="px-6">
        <h4 tw="text-lg text-gray-500 mb-14">{subtitle}</h4>
        <div tw="space-y-3">
          {statements.data.map((statement) => (
            <Statement key={statement.id} statement={statement} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Statement = ({ statement }: { statement: PortfolioStatement }) => {
  const { refetch } = useDownloadFile(statement.fileId);

  const onDownload: MouseEventHandler = async (event) => {
    event.preventDefault();
    const { data: blob } = await refetch();
    if (!blob) return;
    const url = URL.createObjectURL(blob);
    downloadFileFromObjectUrl(url, statement.originalFileName);
  };

  return (
    <a tw="flex items-center text-alps-blue-400 hover:cursor-pointer hover:underline" onClick={onDownload}>
      <span tw="text-alps-blue-700 bg-alps-blue-50 rounded p-0.5 pb-0 mr-2">
        <DocumentIcon />
      </span>
      {statement.name}
    </a>
  );
};
