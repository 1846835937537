import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { api } from '~/api';
import { useClaimQuestionnaires } from '~/modules/onboarding/hooks';
import { toFormData } from '~/utils/form';

import { LoginRequest, Session, SignupRequest } from './types';

export const useSessionQuery = (options?: UseQueryOptions<Session, Error>) =>
  useQuery<Session, Error>('session', async () => api.get('session').json<Session>(), {
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    ...options,
  });

export const useLogin = () => {
  const claimQuestionnaires = useClaimQuestionnaires();
  const queryClient = useQueryClient();
  return useMutation(async (request: LoginRequest) => api.post('login', { body: toFormData(request) }), {
    onSuccess: async () => {
      await queryClient.removeQueries('portfolio');
      await claimQuestionnaires();
      await queryClient.invalidateQueries('session');
    },
  });
};

export const useSignup = () => {
  return useMutation(async (request: SignupRequest) => api.post('signup', { body: toFormData(request) }));
};

export const useVerifyEmail = () => {
  const queryClient = useQueryClient();

  return useMutation<Session, Error>(async () => api.post('verify-email').json<Session>(), {
    onSuccess: (session) => {
      if (session.emailVerified) {
        queryClient.setQueryData('session', session);
      }
    },
  });
};
