import { CheckIcon } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import 'twin.macro';

interface Props {
  title: string;
  subtitle?: string;
  children: ReactNode;
}

export const Success = ({ children, subtitle, title }: Props) => {
  return (
    <div tw="flex flex-col items-center">
      <div tw="bg-forest-green-50 rounded-full p-1 mb-8">
        <CheckIcon tw="w-20 h-20 text-forest-green-700" />
      </div>
      <h3 tw="text-lg md:text-xl text-alps-blue-700 font-medium mb-8">{title}</h3>
      {subtitle && <h4 tw="md:text-lg text-gray-400 mb-8 md:mb-20">{subtitle}</h4>}
      {children}
    </div>
  );
};
