var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgInvestmentIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m14 10v1h4v-1c0-.55228-.4477-1-1-1h-2c-.5523 0-1 .44772-1 1zm-6 1h4v-1c0-1.65685 1.3431-3 3-3h2c1.6569 0 3 1.34315 3 3v1h4c1.1046 0 2 .8954 2 2v2h-5c0-.5523-.4477-1-1-1s-1 .4477-1 1h-6c0-.5523-.4477-1-1-1s-1 .4477-1 1h-5v-2c0-1.1046.89543-2 2-2zm-2 5v6c0 1.1046.89543 2 2 2h16c1.1046 0 2-.8954 2-2v-6h-5c0 .5523-.4477 1-1 1s-1-.4477-1-1h-6c0 .5523-.4477 1-1 1s-1-.4477-1-1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgInvestmentIcon);
var Memo = memo(ForwardRef);
export default Memo;
