import { DocumentIcon, formatDate, Trans, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';
import { useGetContractFiles } from '~/modules/account/queries';
import { useDownloadFile } from './queries';
import { ContractFile } from './types';
import { downloadFileFromObjectUrl } from './utils';

export function AccountDocumentsView() {
  const { t } = useTranslation('account');
  const contractFiles = useGetContractFiles();

  return (
    <div tw="m-6 md:m-12">
      <div tw="text-alps-blue-700 text-lg md:text-xl mb-8 md:mb-12 font-bold text-center md:text-left md:font-medium">
        {t('documents.title')}
      </div>

      <p>
        <Trans
          i18nKey="account:documents.contact_us"
          components={[<a key="0" href="mailto:support@grunfin.com" tw="text-alps-blue-400" />]}
        />
      </p>

      <ul tw="mt-10">
        {contractFiles?.data
          ?.sort((a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime())
          ?.map((contractFile) => <ContractFileElement key={contractFile.id} {...contractFile} />)}
      </ul>
    </div>
  );
}

const ContractFileElement = (contractFile: ContractFile) => {
  const { t } = useTranslation('account');
  const { refetch } = useDownloadFile(contractFile.id);

  const handleDownloadFile = async () => {
    const { data: blob } = await refetch();
    if (!blob) return;
    const url = URL.createObjectURL(blob);
    downloadFileFromObjectUrl(url, contractFile.originalFileName);
  };

  return (
    <li tw="mb-6" key={contractFile.id}>
      <a
        target="_blank"
        onClick={async (e) => {
          e.preventDefault();
          await handleDownloadFile();
        }}
        href="#!"
        rel="noreferrer"
      >
        <div tw="flex items-center">
          <DocumentIcon tw="rounded bg-forest-green-50 text-forest-green-700 mr-4" />
          <div>{contractFile.originalFileName}</div>
        </div>
        <div tw="text-gray-400 ml-12 -mt-1">
          {t('documents.signed', {
            date: formatDate(new Date(contractFile.signedAt), 'DD MMM YYYY'),
          })}
        </div>
      </a>
    </li>
  );
};
