import tw, { css, styled } from 'twin.macro';
import { ChangeEvent } from 'react';
import { CheckIcon, Select, useTranslation } from '@grunfin/ui-kit';

interface Props {
  startAge: number;
  endAge: number | undefined;
  onChange: (endAge: number | undefined) => void;
}

const PROMINENT_YEARS = [18, 21, 25];

export const AgePicker = ({ startAge, endAge, onChange }: Props) => {
  const { t } = useTranslation('onboarding');
  const years = Array.from({ length: 22 }, (_, i) => startAge + i + 1);
  const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => onChange(parseFloat(e.target.value));

  return (
    <>
      <div tw="md:hidden w-max">
        <Select autoFocus value={endAge} onChange={handleChange}>
          {years.map((y) => (
            <option key={y} value={y}>
              {t('age_years', { count: y })}
            </option>
          ))}
        </Select>
      </div>

      <Container>
        <div tw="flex justify-center">
          <label tw="text-sm flex items-center flex-col p-2 mt-2 w-10">
            <div tw="text-base">{t('age')}</div>
            <div tw="w-2 h-2 rounded-full bg-gray-900 my-3" />
            <div>{startAge}</div>
          </label>
          {years.map((y) => (
            <YearItem key={y} active={y === endAge} prominent={PROMINENT_YEARS.includes(y)}>
              <CheckIcon />
              <input name="year" type="radio" value={y} checked={y === endAge} onChange={handleChange} />
              <div>{y}</div>
            </YearItem>
          ))}
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  ${tw`hidden sm:block overflow-x-auto -mx-20 py-1 px-12`};

  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position-y: 58px;
  background-image: linear-gradient(90deg, #fff 0, #e5e4e2 5%, #e5e4e2 95%, #fff 100%);
`;

const YearItem = styled.label<{ prominent: boolean; active: boolean }>`
  ${tw`relative px-1 py-1 text-sm hover:bg-gray-100 transition-all`};
  border-radius: 12px;

  &:focus-within {
    ${tw`ring-2 ring-alps-blue-200`}
  }

  svg {
    ${tw`bg-white text-alps-blue-700 mb-7 mx-1 opacity-0 transition-all rounded`};
  }

  input {
    ${tw`absolute left-0 top-0 w-full h-full appearance-none outline-none cursor-pointer`};
  }

  div {
    ${tw`rounded-full w-8 h-8 pt-2 text-center select-none`};
    ${(p) => p.prominent && !p.active && tw`bg-alps-blue-300 text-white`};
  }

  &:hover {
    svg {
      ${tw`opacity-100`};
    }
  }

  ${(p) =>
    p.active &&
    css`
      &,
      &:hover {
        ${tw`bg-alps-blue-700 text-white`};

        svg {
          ${tw`opacity-100`};
        }

        div {
          ${tw`bg-transparent text-white`};
        }
      }
    `}
`;
