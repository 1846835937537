import { Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import 'twin.macro';

import googleLogo from '~/assets/output/google-logo.svg';

interface Props {
  onClick: () => void;
  children: ReactNode;
}

export const GoogleButton = (props: Props) => {
  const { t } = useTranslation('auth');
  const isFbInAppBrowser = /FB(AN|AV|_IAB)/.test(navigator.userAgent);

  return (
    <div tw="inline-block w-full">
      <Button
        variant={ButtonVariant.SECONDARY}
        disabled={isFbInAppBrowser}
        onClick={props.onClick}
        size={ButtonSize.LARGE}
      >
        <>
          <img tw="mr-2" src={googleLogo} alt="Google" />
          {props.children}
        </>
      </Button>
      {isFbInAppBrowser && <div tw="text-center mt-4 text-sm font-semibold">{t('google.disabled')}</div>}
    </div>
  );
};
