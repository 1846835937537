var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgEuropeanUnionIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("circle", { cx: 12, cy: 12, r: 12, fill: "#003399" }),
    React.createElement("path", { d: "M12.0534 5L11.7463 5.94508L12.2189 6.09862L12.0534 5Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.0534 5L12.3605 5.94508L11.8879 6.09862L12.0534 5Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.9985 5.68668L12.0048 5.68668L12.0048 6.18353L12.9985 5.68668Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.9985 5.68663L12.1945 6.27072L11.9025 5.86876L12.9985 5.68663Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.6375 6.79768L12.3304 5.8526L11.8579 6.00614L12.6375 6.79768Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.6375 6.79768L11.8336 6.21359L12.1256 5.81162L12.6375 6.79768Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.1083 5.68668L12.1021 5.68668L12.1021 6.18353L11.1083 5.68668Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.1083 5.68663L11.9123 6.27072L12.2043 5.86876L11.1083 5.68663Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.4693 6.79768L11.7764 5.8526L12.2489 6.00614L11.4693 6.79768Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.4693 6.79768L12.2732 6.21359L11.9812 5.81162L11.4693 6.79768Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.0534 16.9246L11.7463 17.8697L12.2189 18.0232L12.0534 16.9246Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.0534 16.9246L12.3605 17.8697L11.8879 18.0232L12.0534 16.9246Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.9985 17.6113L12.0048 17.6113L12.0048 18.1081L12.9985 17.6113Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.9985 17.6113L12.1945 18.1954L11.9025 17.7934L12.9985 17.6113Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.6375 18.7223L12.3304 17.7772L11.8579 17.9307L12.6375 18.7223Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M12.6375 18.7223L11.8336 18.1382L12.1256 17.7362L12.6375 18.7223Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.1083 17.6113L12.1021 17.6113L12.1021 18.1081L11.1083 17.6113Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.1083 17.6113L11.9123 18.1954L12.2043 17.7934L11.1083 17.6113Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.4693 18.7223L11.7764 17.7772L12.2489 17.9307L11.4693 18.7223Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M11.4693 18.7223L12.2732 18.1382L11.9812 17.7362L11.4693 18.7223Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.09111 10.9623L5.78403 11.9074L6.25658 12.0609L6.09111 10.9623Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.09111 10.9623L6.39819 11.9074L5.92565 12.0609L6.09111 10.9623Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.03618 11.649L6.04246 11.649L6.04246 12.1458L7.03618 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.03618 11.649L6.23225 12.233L5.9402 11.8311L7.03618 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.67519 12.76L6.36811 11.8149L5.89557 11.9684L6.67519 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.67519 12.76L5.87125 12.1759L6.1633 11.7739L6.67519 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.14603 11.649L6.13974 11.649L6.13974 12.1458L5.14603 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.14603 11.649L5.94996 12.233L6.24201 11.8311L5.14603 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.50702 12.76L5.81409 11.8149L6.28663 11.9684L5.50702 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.50702 12.76L6.31095 12.1759L6.01891 11.7739L5.50702 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.48816 7.59648L9.29209 7.01239L9.00005 6.61042L8.48816 7.59648Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.48816 7.59648L8.79523 6.6514L9.26778 6.80494L8.48816 7.59648Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.1272 6.48541L8.93113 7.0695L9.22318 6.66754L8.1272 6.48541Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.1272 6.48544L9.12091 6.48544L9.12091 6.9823L8.1272 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.07227 5.7988L8.76519 6.74388L9.23773 6.89742L9.07227 5.7988Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.07227 5.7988L9.37934 6.74388L8.9068 6.89742L9.07227 5.7988Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.65646 7.59653L8.85253 7.01243L9.14458 6.61047L9.65646 7.59653Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.65646 7.59653L9.34939 6.65145L8.87685 6.80498L9.65646 7.59653Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M10.0173 6.48544L9.21342 7.06954L8.92137 6.66757L10.0173 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M10.0173 6.48544L9.02363 6.48544L9.02363 6.9823L10.0173 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.474 9.77882L7.16692 8.83374L6.69438 8.98728L7.474 9.77882Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.474 9.77882L6.67006 9.19473L6.96211 8.79276L7.474 9.77882Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.30571 9.77888L7.10964 9.19479L6.8176 8.79283L6.30571 9.77888Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.30571 9.77888L6.61278 8.8338L7.08533 8.98734L6.30571 9.77888Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.94481 8.66782L6.74874 9.25191L7.04079 8.84994L5.94481 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.94481 8.66782L6.93853 8.66782L6.93853 9.16467L5.94481 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.83499 8.66782L7.03106 9.25191L6.73901 8.84994L7.83499 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.83499 8.66782L6.84127 8.66782L6.84127 9.16467L7.83499 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.88991 7.98116L7.19698 8.92624L6.72444 9.07977L6.88991 7.98116Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.88991 7.98116L6.58283 8.92624L7.05537 9.07977L6.88991 7.98116Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.83498 14.6301L6.84126 14.6301L6.84126 15.1269L7.83498 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.83498 14.6301L7.03104 15.2141L6.739 14.8122L7.83498 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.474 15.741L7.16692 14.796L6.69438 14.9495L7.474 15.741Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M7.474 15.741L6.67006 15.157L6.96211 14.755L7.474 15.741Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.30582 15.7411L7.10975 15.157L6.8177 14.755L6.30582 15.7411Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.30582 15.7411L6.61289 14.796L7.08543 14.9495L6.30582 15.7411Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.88991 13.9435L7.19699 14.8885L6.72445 15.0421L6.88991 13.9435Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M6.88991 13.9435L6.58284 14.8885L7.05538 15.0421L6.88991 13.9435Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.94481 14.6301L6.93852 14.6301L6.93853 15.127L5.94481 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M5.94481 14.6301L6.74874 15.2142L7.04079 14.8122L5.94481 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M10.0173 16.8124L9.02362 16.8124L9.02362 17.3093L10.0173 16.8124Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M10.0173 16.8124L9.2134 17.3965L8.92135 16.9946L10.0173 16.8124Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.65636 17.9234L9.34928 16.9784L8.87674 17.1319L9.65636 17.9234Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.65636 17.9234L8.85242 17.3393L9.14447 16.9374L9.65636 17.9234Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.48817 17.9235L9.29211 17.3394L9.00006 16.9374L8.48817 17.9235Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.48817 17.9235L8.79525 16.9784L9.26779 17.1319L8.48817 17.9235Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.07227 16.1258L9.37934 17.0709L8.9068 17.2244L9.07227 16.1258Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M9.07227 16.1258L8.76519 17.0709L9.23774 17.2244L9.07227 16.1258Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.12717 16.8125L9.12088 16.8125L9.12088 17.3093L8.12717 16.8125Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M8.12717 16.8125L8.9311 17.3966L9.22315 16.9946L8.12717 16.8125Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.0157 10.9623L18.3228 11.9074L17.8502 12.0609L18.0157 10.9623Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.0157 10.9623L17.7086 11.9074L18.1812 12.0609L18.0157 10.9623Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.0706 11.649L18.0643 11.649L18.0643 12.1458L17.0706 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.0706 11.649L17.8745 12.233L18.1666 11.8311L17.0706 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.4316 12.76L17.7387 11.8149L18.2112 11.9684L17.4316 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.4316 12.76L18.2355 12.1759L17.9435 11.7739L17.4316 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.9608 11.649L17.9671 11.649L17.9671 12.1458L18.9608 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.9608 11.649L18.1569 12.233L17.8648 11.8311L18.9608 11.649Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.5998 12.76L18.2927 11.8149L17.8202 11.9684L18.5998 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.5998 12.76L17.7958 12.1759L18.0879 11.7739L18.5998 12.76Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.6187 7.59648L14.8147 7.01239L15.1068 6.61042L15.6187 7.59648Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.6187 7.59648L15.3116 6.6514L14.839 6.80494L15.6187 7.59648Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.9796 6.48541L15.1757 7.0695L14.8836 6.66754L15.9796 6.48541Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.9796 6.48544L14.9859 6.48544L14.9859 6.9823L15.9796 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.0345 5.7988L15.3416 6.74388L14.8691 6.89742L15.0345 5.7988Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.0345 5.7988L14.7275 6.74388L15.2 6.89742L15.0345 5.7988Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.4503 7.59653L15.2543 7.01243L14.9622 6.61047L14.4503 7.59653Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.4503 7.59653L14.7574 6.65145L15.23 6.80498L14.4503 7.59653Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.0895 6.48544L14.8934 7.06954L15.1854 6.66757L14.0895 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.0895 6.48544L15.0832 6.48544L15.0832 6.9823L14.0895 6.48544Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.6328 9.77882L16.9399 8.83374L17.4124 8.98728L16.6328 9.77882Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.6328 9.77882L17.4367 9.19473L17.1447 8.79276L16.6328 9.77882Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.8011 9.77888L16.9972 9.19479L17.2892 8.79283L17.8011 9.77888Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.8011 9.77888L17.494 8.8338L17.0215 8.98734L17.8011 9.77888Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.162 8.66779L17.358 9.25188L17.066 8.84991L18.162 8.66779Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.162 8.66779L17.1683 8.66779L17.1683 9.16464L18.162 8.66779Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.2718 8.66782L17.0758 9.25191L17.3678 8.84994L16.2718 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.2718 8.66782L17.2656 8.66782L17.2656 9.16467L16.2718 8.66782Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.2169 7.98116L16.9098 8.92624L17.3824 9.07977L17.2169 7.98116Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.2169 7.98116L17.524 8.92624L17.0515 9.07977L17.2169 7.98116Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.2718 14.6301L17.2655 14.6301L17.2655 15.1269L16.2718 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.2718 14.6301L17.0758 15.2141L17.3678 14.8122L16.2718 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.6328 15.741L16.9399 14.796L17.4124 14.9495L16.6328 15.741Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M16.6328 15.741L17.4367 15.157L17.1447 14.755L16.6328 15.741Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.801 15.7411L16.997 15.157L17.2891 14.755L17.801 15.7411Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.801 15.7411L17.4939 14.796L17.0214 14.9495L17.801 15.7411Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.2169 13.9435L16.9098 14.8885L17.3824 15.0421L17.2169 13.9435Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M17.2169 13.9435L17.524 14.8885L17.0514 15.0421L17.2169 13.9435Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.162 14.6301L17.1683 14.6301L17.1683 15.127L18.162 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M18.162 14.6301L17.3581 15.2142L17.066 14.8122L18.162 14.6301Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.0895 16.8124L15.0832 16.8124L15.0832 17.3093L14.0895 16.8124Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.0895 16.8124L14.8934 17.3965L15.1854 16.9946L14.0895 16.8124Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.4504 17.9234L14.7575 16.9784L15.2301 17.1319L14.4504 17.9234Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M14.4504 17.9234L15.2544 17.3393L14.9623 16.9374L14.4504 17.9234Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.6186 17.9235L14.8147 17.3394L15.1067 16.9374L15.6186 17.9235Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.6186 17.9235L15.3115 16.9784L14.839 17.1319L15.6186 17.9235Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.0345 16.1258L14.7275 17.0709L15.2 17.2244L15.0345 16.1258Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.0345 16.1258L15.3416 17.0709L14.8691 17.2244L15.0345 16.1258Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.9796 16.8125L14.9859 16.8125L14.9859 17.3093L15.9796 16.8125Z", fill: "#FFCC00" }),
    React.createElement("path", { d: "M15.9796 16.8125L15.1757 17.3966L14.8836 16.9946L15.9796 16.8125Z", fill: "#FFCC00" }))); };
var ForwardRef = forwardRef(SvgEuropeanUnionIcon);
var Memo = memo(ForwardRef);
export default Memo;
