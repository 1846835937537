var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgReutersIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 127, height: 32, viewBox: "0 0 127 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M17.7793 0C17.3876 0 17.0693 0.318286 17.0693 0.710023C17.0693 1.10176 17.3876 1.42005 17.7793 1.42005C18.171 1.42005 18.4893 1.10176 18.4893 0.710023C18.4893 0.318286 18.171 0 17.7793 0Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.8369 1.07731C13.8369 0.612126 14.2041 0.244873 14.6693 0.244873C15.11 0.244873 15.4528 0.612126 15.4773 1.1018C15.4773 1.5425 15.11 1.90975 14.6693 1.90975C14.2041 1.90975 13.8369 1.5425 13.8369 1.07731Z", fill: "currentColor" }),
    React.createElement("path", { d: "M11.34 1.12622C10.8259 1.12622 10.3852 1.54244 10.3852 2.08108C10.3852 2.59523 10.8014 3.03594 11.34 3.03594C11.8542 3.03594 12.2949 2.61972 12.2949 2.08108C12.2704 1.56692 11.8542 1.12622 11.34 1.12622Z", fill: "currentColor" }),
    React.createElement("path", { d: "M6.76117 4.03977C6.76117 3.37872 7.29981 2.86456 7.93638 2.86456C8.59743 2.86456 9.11159 3.4032 9.11159 4.03977C9.11159 4.70083 8.57295 5.21498 7.93638 5.21498C7.27532 5.21498 6.76117 4.67634 6.76117 4.03977Z", fill: "currentColor" }),
    React.createElement("path", { d: "M4.92525 5.75366C4.43558 5.75366 3.97039 5.9985 3.72556 6.4392C3.48072 6.85542 3.48072 7.39406 3.72556 7.81028C3.97039 8.2265 4.43558 8.49582 4.92525 8.49582C5.65976 8.47133 6.27185 7.88373 6.27185 7.12474C6.24736 6.39023 5.65976 5.77815 4.92525 5.75366Z", fill: "currentColor" }),
    React.createElement("path", { d: "M1.47322 10.2341C1.76702 9.7444 2.30566 9.4506 2.89327 9.4506C3.75019 9.47509 4.46021 10.1851 4.46021 11.042C4.46021 11.899 3.77467 12.609 2.89327 12.6335C2.30566 12.6579 1.76702 12.3397 1.47322 11.85C1.17942 11.3603 1.17942 10.7237 1.47322 10.2341Z", fill: "currentColor" }),
    React.createElement("path", { d: "M2.15854 14.1515C1.13023 14.1515 0.297791 14.984 0.297791 16.0123C0.297791 17.0406 1.13023 17.873 2.15854 17.873C3.18685 17.873 4.01929 17.0406 4.01929 16.0123C3.9948 14.984 3.18685 14.1515 2.15854 14.1515Z", fill: "currentColor" }),
    React.createElement("path", { d: "M1.47322 20.1255C1.76702 19.6358 2.30566 19.3175 2.89327 19.342C3.75019 19.3665 4.46021 20.0765 4.46021 20.9334C4.46021 21.7903 3.77467 22.5004 2.89327 22.5248C2.30566 22.5493 1.76702 22.231 1.47322 21.7414C1.17942 21.2517 1.17942 20.6151 1.47322 20.1255Z", fill: "currentColor" }),
    React.createElement("path", { d: "M32.6897 19.342C32.102 19.342 31.5634 19.6358 31.2696 20.1255C30.9758 20.6151 30.9758 21.2517 31.2696 21.7414C31.5634 22.231 32.102 22.5493 32.6897 22.5248C33.5711 22.5004 34.2566 21.7903 34.2566 20.9334C34.2811 20.0765 33.5711 19.3665 32.6897 19.342Z", fill: "currentColor" }),
    React.createElement("path", { d: "M30.6572 23.5042C29.8982 23.5042 29.2861 24.1163 29.2861 24.8508C29.2861 25.6098 29.8982 26.2219 30.6572 26.2219C31.4161 26.2219 32.0282 25.6098 32.0282 24.8508C32.0282 24.1163 31.4161 23.5042 30.6572 23.5042Z", fill: "currentColor" }),
    React.createElement("path", { d: "M28.8192 27.8951C28.8408 28.5438 28.3325 29.0871 27.6838 29.1087C27.0352 29.1303 26.4918 28.622 26.4702 27.9733C26.4486 27.3247 26.9569 26.7813 27.6056 26.7597C28.2543 26.7381 28.7976 27.2465 28.8192 27.8951Z", fill: "currentColor" }),
    React.createElement("path", { d: "M24.2429 28.964C23.7042 28.964 23.288 29.3802 23.288 29.9189C23.288 30.4575 23.7042 30.8737 24.2429 30.8737C24.7815 30.8737 25.1977 30.4575 25.1977 29.9189C25.1977 29.3802 24.7815 28.964 24.2429 28.964Z", fill: "currentColor" }),
    React.createElement("path", { d: "M20.1049 30.9227C20.1049 30.482 20.4722 30.1147 20.9129 30.1147C21.3781 30.1147 21.7453 30.482 21.7208 30.9227C21.7208 31.3634 21.3536 31.7307 20.9129 31.7307C20.4722 31.7307 20.1049 31.3634 20.1049 30.9227Z", fill: "currentColor" }),
    React.createElement("path", { d: "M17.8032 30.5799C17.4115 30.5799 17.0932 30.8982 17.0932 31.2899C17.0932 31.6817 17.4115 31.9999 17.8032 31.9999C18.195 31.9999 18.5132 31.6817 18.5132 31.2899C18.4888 30.8982 18.1705 30.5799 17.8032 30.5799Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.8618 30.9227C13.8618 30.482 14.229 30.1147 14.6697 30.1147C15.1104 30.1147 15.4777 30.482 15.4777 30.9227C15.4777 31.3634 15.1104 31.7307 14.6697 31.7307C14.229 31.7307 13.8618 31.3634 13.8618 30.9227Z", fill: "currentColor" }),
    React.createElement("path", { d: "M11.3393 28.9395C10.9966 28.9395 10.6783 29.1109 10.5069 29.4047C10.3355 29.6985 10.3355 30.0658 10.5069 30.3596C10.6783 30.6534 10.9966 30.8247 11.3393 30.8247C11.8535 30.8247 12.2942 30.4085 12.2942 29.8699C12.2697 29.3802 11.8535 28.964 11.3393 28.9395Z", fill: "currentColor" }),
    React.createElement("path", { d: "M6.90837 27.3481C7.12872 26.9808 7.52046 26.7605 7.93668 26.7605C8.59773 26.7605 9.11189 27.2991 9.11189 27.9357C9.11189 28.5968 8.57325 29.1109 7.93668 29.1109C7.49597 29.1109 7.12872 28.8906 6.90837 28.5233C6.68801 28.1561 6.68801 27.7154 6.90837 27.3481Z", fill: "currentColor" }),
    React.createElement("path", { d: "M4.92522 23.5042C4.16623 23.5042 3.55414 24.1163 3.55414 24.8753C3.55414 25.6343 4.16623 26.2464 4.92522 26.2464C5.68421 26.2464 6.29629 25.6343 6.29629 24.8753C6.27181 24.1163 5.68421 23.5042 4.92522 23.5042Z", fill: "currentColor" }),
    React.createElement("path", { d: "M20.1049 1.05283C20.1049 0.612126 20.4722 0.244873 20.9129 0.244873C21.3781 0.244873 21.7453 0.612126 21.7208 1.05283C21.7208 1.49353 21.3536 1.86079 20.9129 1.86079C20.4722 1.86079 20.1049 1.49353 20.1049 1.05283Z", fill: "currentColor" }),
    React.createElement("path", { d: "M24.2427 1.12622C23.8999 1.12622 23.5816 1.29761 23.4102 1.59141C23.2388 1.88521 23.2388 2.25246 23.4102 2.54627C23.5816 2.84007 23.8999 3.01145 24.2427 3.01145C24.7568 3.01145 25.1975 2.59523 25.1975 2.0566C25.1975 1.51796 24.7813 1.12622 24.2427 1.12622Z", fill: "currentColor" }),
    React.createElement("path", { d: "M26.4711 4.06431C26.4711 3.40325 27.0098 2.8891 27.6463 2.8891C28.2829 2.8891 28.8215 3.40325 28.8215 4.06431C28.8215 4.72536 28.2829 5.23952 27.6463 5.23952C26.9853 5.23952 26.4711 4.70088 26.4711 4.06431Z", fill: "currentColor" }),
    React.createElement("path", { d: "M30.6572 5.75366C29.8982 5.75366 29.2861 6.36575 29.2861 7.12474C29.2861 7.88373 29.8982 8.49582 30.6572 8.49582C31.4161 8.49582 32.0282 7.88373 32.0282 7.12474C32.0282 6.36575 31.4161 5.75366 30.6572 5.75366Z", fill: "currentColor" }),
    React.createElement("path", { d: "M31.2696 10.2341C31.5634 9.74442 32.102 9.45062 32.6897 9.45062C33.5711 9.45062 34.2566 10.1606 34.2566 11.0421C34.2566 11.899 33.5711 12.609 32.6897 12.6335C32.102 12.658 31.5634 12.3397 31.2696 11.85C30.9758 11.3603 30.9758 10.7238 31.2696 10.2341Z", fill: "currentColor" }),
    React.createElement("path", { d: "M25.3445 18.4361C25.0752 18.4361 24.8304 18.5585 24.708 18.7789C24.5855 18.9992 24.5855 19.293 24.708 19.5134C24.8304 19.7337 25.0752 19.8806 25.3445 19.8562C25.7363 19.8317 26.0301 19.5134 26.0301 19.1461C26.0546 18.7789 25.7363 18.4606 25.3445 18.4361Z", fill: "currentColor" }),
    React.createElement("path", { d: "M22.9209 21.4476C23.0433 21.2272 23.2881 21.1048 23.5574 21.1048C23.9492 21.1293 24.243 21.4476 24.243 21.8148C24.243 22.1821 23.9492 22.5004 23.5574 22.5249C23.3126 22.5493 23.0433 22.4024 22.9209 22.1821C22.7984 21.9617 22.7984 21.6679 22.9209 21.4476Z", fill: "currentColor" }),
    React.createElement("path", { d: "M20.8884 22.8187C20.6191 22.8187 20.3498 22.9656 20.2029 23.2104C20.056 23.4553 20.056 23.7491 20.2029 23.9939C20.3498 24.2387 20.5946 24.3856 20.8884 24.3856C21.3291 24.3856 21.6719 24.0429 21.6719 23.6022C21.6719 23.1615 21.3291 22.8187 20.8884 22.8187Z", fill: "currentColor" }),
    React.createElement("path", { d: "M16.7016 24.3367C16.7016 23.7491 17.1668 23.2839 17.7544 23.2839C18.3175 23.2839 18.7827 23.7491 18.8072 24.3367C18.8072 24.9243 18.342 25.3895 17.7544 25.3895C17.1668 25.3895 16.7016 24.9243 16.7016 24.3367Z", fill: "currentColor" }),
    React.createElement("path", { d: "M14.5966 22.6473C13.9355 22.6473 13.3969 23.1859 13.3969 23.847C13.3969 24.508 13.9355 25.0467 14.5966 25.0467C15.2577 25.0467 15.7963 24.508 15.7963 23.847C15.7963 23.1859 15.2577 22.6473 14.5966 22.6473Z", fill: "currentColor" }),
    React.createElement("path", { d: "M10.434 21.9618C10.434 21.2273 11.0216 20.6396 11.7561 20.6396C12.4906 20.6396 13.0782 21.2273 13.0782 21.9618C13.0782 22.6963 12.4906 23.2839 11.7561 23.2839C11.0216 23.2839 10.434 22.6963 10.434 21.9618Z", fill: "currentColor" }),
    React.createElement("path", { d: "M9.87074 17.9465C9.25865 17.9465 8.76898 18.4361 8.76898 19.0482C8.76898 19.6603 9.25865 20.15 9.87074 20.15C10.4828 20.15 10.9725 19.6603 10.9725 19.0482C10.997 18.4606 10.4828 17.9465 9.87074 17.9465Z", fill: "currentColor" }),
    React.createElement("path", { d: "M8.57318 15.8653C8.57318 15.4001 8.96492 15.0084 9.43011 15.0084C9.91978 15.0084 10.287 15.3756 10.287 15.8653C10.287 16.3305 9.89529 16.7222 9.43011 16.7222C8.96492 16.7222 8.57318 16.3305 8.57318 15.8653Z", fill: "currentColor" }),
    React.createElement("path", { d: "M10.1895 12.1194C9.79772 12.1194 9.47943 12.4377 9.47943 12.8294C9.47943 13.2211 9.79772 13.5394 10.1895 13.5394C10.5812 13.5394 10.8995 13.2211 10.8995 12.8294C10.924 12.4377 10.5812 12.1194 10.1895 12.1194Z", fill: "currentColor" }),
    React.createElement("path", { d: "M11.3642 9.81787C11.4866 9.59752 11.7315 9.4751 12.0008 9.4751C12.368 9.4751 12.6863 9.79338 12.7108 10.1851C12.7108 10.5769 12.3925 10.8951 12.0008 10.8951C11.7559 10.9196 11.4866 10.7727 11.3642 10.5524C11.2418 10.332 11.2418 10.0382 11.3642 9.81787Z", fill: "currentColor" }),
    React.createElement("path", { d: "M14.6452 7.61432C14.2045 7.61432 13.8618 7.95709 13.8618 8.39779C13.8618 8.8385 14.2045 9.18126 14.6452 9.18126C15.0859 9.18126 15.4287 8.8385 15.4287 8.39779C15.4287 7.98157 15.0859 7.61432 14.6452 7.61432Z", fill: "currentColor" }),
    React.createElement("path", { d: "M17.8389 6.5984C18.4199 6.62071 18.8728 7.10983 18.8505 7.69086C18.8282 8.27189 18.3391 8.72483 17.758 8.70251C17.177 8.6802 16.7241 8.19109 16.7464 7.61005C16.7687 7.02902 17.2578 6.57609 17.8389 6.5984Z", fill: "currentColor" }),
    React.createElement("path", { d: "M20.9374 6.95337C20.2764 6.95337 19.7377 7.49201 19.7377 8.15306C19.7377 8.81412 20.2764 9.35276 20.9374 9.35276C21.5985 9.35276 22.1371 8.81412 22.1371 8.15306C22.1371 7.49201 21.5985 6.95337 20.9374 6.95337Z", fill: "currentColor" }),
    React.createElement("path", { d: "M22.4555 10.0382C22.4555 9.30373 23.0431 8.71613 23.7776 8.71613C24.5121 8.71613 25.0997 9.30373 25.0997 10.0382C25.0997 10.7727 24.5121 11.3603 23.7776 11.3603C23.0431 11.3603 22.4555 10.7727 22.4555 10.0382Z", fill: "currentColor" }),
    React.createElement("path", { d: "M25.6627 11.8255C25.0506 11.8255 24.561 12.3152 24.561 12.9273C24.561 13.5393 25.0506 14.029 25.6627 14.029C26.2748 14.029 26.7645 13.5393 26.7645 12.9273C26.7645 12.3152 26.2748 11.8255 25.6627 11.8255Z", fill: "currentColor" }),
    React.createElement("path", { d: "M23.4348 17.3588C23.4348 17.7239 23.1389 18.0199 22.7738 18.0199C22.4087 18.0199 22.1127 17.7239 22.1127 17.3588C22.1127 16.9937 22.4087 16.6978 22.7738 16.6978C23.1389 16.6978 23.4348 16.9937 23.4348 17.3588Z", fill: "currentColor" }),
    React.createElement("path", { d: "M21.4764 18.7544C20.9377 18.7544 20.497 19.1951 20.497 19.7337C20.497 20.2724 20.9377 20.7131 21.4764 20.7131C22.015 20.7131 22.4557 20.2724 22.4557 19.7337C22.4802 19.1951 22.0395 18.7544 21.4764 18.7544Z", fill: "currentColor" }),
    React.createElement("path", { d: "M17.9262 21.1048C17.9262 20.5417 18.3914 20.0765 18.9545 20.0765C19.5176 20.0765 19.9828 20.5417 19.9828 21.1048C19.9828 21.6679 19.5176 22.1331 18.9545 22.1331C18.3914 22.1331 17.9262 21.6679 17.9262 21.1048Z", fill: "currentColor" }),
    React.createElement("path", { d: "M15.9671 20.003C15.6488 20.003 15.3306 20.1744 15.1837 20.4682C15.0123 20.762 15.0123 21.1048 15.1837 21.3741C15.355 21.6679 15.6488 21.8393 15.9671 21.8393C16.4813 21.8393 16.873 21.423 16.873 20.9334C16.8975 20.4192 16.4813 20.003 15.9671 20.003Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.1025 19.1706C13.1025 18.8033 13.3963 18.5095 13.7635 18.5095C14.1308 18.5095 14.4491 18.8278 14.4246 19.1706C14.4246 19.5378 14.1308 19.8316 13.7635 19.8316C13.3963 19.8316 13.1025 19.5378 13.1025 19.1706Z", fill: "currentColor" }),
    React.createElement("path", { d: "M12.8827 16.5754C12.6134 16.5754 12.393 16.7957 12.393 17.065C12.393 17.3344 12.6134 17.5547 12.8827 17.5547C13.152 17.5547 13.3723 17.3344 13.3723 17.065C13.3723 16.7957 13.152 16.5754 12.8827 16.5754Z", fill: "currentColor" }),
    React.createElement("path", { d: "M12.1723 14.7146C12.1723 14.3473 12.4661 14.0535 12.8334 14.0535C13.2006 14.0535 13.4944 14.3473 13.4944 14.7146C13.4944 15.0818 13.2006 15.3756 12.8334 15.3756C12.4661 15.3756 12.1723 15.0818 12.1723 14.7146Z", fill: "currentColor" }),
    React.createElement("path", { d: "M14.1311 11.3359C13.5925 11.3359 13.1518 11.7766 13.1518 12.3152C13.1518 12.8539 13.5925 13.2946 14.1311 13.2946C14.6698 13.2946 15.1105 12.8539 15.1105 12.3152C15.1105 11.7766 14.6698 11.3359 14.1311 11.3359Z", fill: "currentColor" }),
    React.createElement("path", { d: "M15.6245 10.9441C15.6245 10.381 16.0896 9.91583 16.6528 9.91583C17.2159 9.91583 17.6811 10.381 17.6811 10.9441C17.6811 11.5073 17.2159 11.9725 16.6528 11.9725C16.0896 11.9725 15.6245 11.5073 15.6245 10.9441Z", fill: "currentColor" }),
    React.createElement("path", { d: "M19.64 10.2341C19.2973 10.2341 19.0035 10.4055 18.8321 10.6748C18.6607 10.9686 18.6607 11.3114 18.8321 11.6052C19.0035 11.899 19.3218 12.0704 19.64 12.0459C20.1297 12.0214 20.5215 11.6297 20.5215 11.14C20.5215 10.6504 20.1297 10.2586 19.64 10.2341Z", fill: "currentColor" }),
    React.createElement("path", { d: "M21.1577 12.8539C21.1577 12.4866 21.4515 12.1928 21.8187 12.1928C22.2104 12.1928 22.5042 12.4866 22.4798 12.8539C22.4798 13.2211 22.186 13.5149 21.8187 13.5149C21.4515 13.5149 21.1577 13.2211 21.1577 12.8539Z", fill: "currentColor" }),
    React.createElement("path", { d: "M22.7489 14.5187C22.4796 14.5187 22.2592 14.7391 22.2592 15.0084C22.2592 15.2777 22.4796 15.4981 22.7489 15.4981C23.0182 15.4981 23.2386 15.2777 23.2386 15.0084C23.2386 14.7391 23.0182 14.5187 22.7489 14.5187Z", fill: "currentColor" }),
    React.createElement("path", { d: "M25.2465 16.1347C25.2465 15.6695 25.6383 15.2778 26.1034 15.2778C26.5686 15.2778 26.9604 15.6695 26.9604 16.1347C26.9604 16.5999 26.5686 16.9916 26.1034 16.9916C25.6383 16.9916 25.2465 16.5999 25.2465 16.1347Z", fill: "currentColor" }),
    React.createElement("path", { d: "M29.6534 16.3305C29.1392 16.3305 28.7475 16.7467 28.7475 17.2364C28.7475 17.7506 29.1637 18.1423 29.6534 18.1423C30.1675 18.1423 30.5593 17.7261 30.5593 17.2364C30.5593 16.7467 30.1675 16.3305 29.6534 16.3305Z", fill: "currentColor" }),
    React.createElement("path", { d: "M27.8911 20.3948C27.8911 19.9296 28.2828 19.5378 28.748 19.5378C29.2376 19.5378 29.6049 19.9296 29.6049 20.3948C29.6049 20.86 29.2132 21.2517 28.748 21.2517C28.2828 21.2517 27.8911 20.86 27.8911 20.3948Z", fill: "currentColor" }),
    React.createElement("path", { d: "M27.2056 22.231C26.8139 22.231 26.4711 22.5493 26.4711 22.9655C26.4711 23.3573 26.7894 23.7 27.2056 23.7C27.5974 23.7 27.9157 23.3573 27.9157 22.9655C27.9157 22.5738 27.5974 22.2555 27.2056 22.231Z", fill: "currentColor" }),
    React.createElement("path", { d: "M24.7817 24.557C24.9041 24.3611 25.1245 24.2387 25.3448 24.2387C25.6876 24.2387 25.9814 24.5325 25.9814 24.8753C25.9814 25.2425 25.6876 25.5119 25.3448 25.5119C25.1 25.5119 24.9041 25.3894 24.7817 25.1936C24.6593 24.9977 24.6593 24.7529 24.7817 24.557Z", fill: "currentColor" }),
    React.createElement("path", { d: "M23.3857 25.7077C23.0919 25.7077 22.8471 25.9525 22.8471 26.2463C22.8471 26.5401 23.0919 26.785 23.3857 26.785C23.6795 26.785 23.9244 26.5401 23.9244 26.2463C23.9244 25.9525 23.6795 25.7077 23.3857 25.7077Z", fill: "currentColor" }),
    React.createElement("path", { d: "M20.5209 27.1767C20.5209 26.8094 20.8147 26.5401 21.1575 26.5401C21.5003 26.5401 21.7696 26.8094 21.7941 27.1767C21.7941 27.5439 21.5003 27.8132 21.1575 27.8132C20.7903 27.8132 20.5209 27.5194 20.5209 27.1767Z", fill: "currentColor" }),
    React.createElement("path", { d: "M18.5382 26.9564C18.1464 26.9564 17.8036 27.2747 17.8036 27.6909C17.8036 28.0826 18.1219 28.4254 18.5382 28.4254C18.9299 28.4254 19.2727 28.1071 19.2727 27.6909C19.2482 27.2747 18.9299 26.9564 18.5382 26.9564Z", fill: "currentColor" }),
    React.createElement("path", { d: "M14.6205 27.593C14.6205 27.0788 15.0368 26.6626 15.5509 26.6626C16.0651 26.6626 16.4813 27.0788 16.4813 27.593C16.4813 28.1071 16.0651 28.5233 15.5509 28.5233C15.0368 28.5233 14.6205 28.1071 14.6205 27.593Z", fill: "currentColor" }),
    React.createElement("path", { d: "M12.3922 25.5364C11.7801 25.5364 11.2905 26.026 11.2905 26.6381C11.2905 27.2502 11.7801 27.7399 12.3922 27.7399C13.0043 27.7399 13.494 27.2502 13.494 26.6381C13.5185 26.026 13.0043 25.5364 12.3922 25.5364Z", fill: "currentColor" }),
    React.createElement("path", { d: "M8.47556 24.0673C8.72039 23.6756 9.1611 23.4307 9.62628 23.4307C10.3363 23.4552 10.8994 24.0428 10.8994 24.7284C10.8994 25.4384 10.3363 26.0015 9.62628 26.026C9.1611 26.0505 8.72039 25.8056 8.47556 25.3894C8.23072 24.9732 8.23072 24.4835 8.47556 24.0673Z", fill: "currentColor" }),
    React.createElement("path", { d: "M7.29987 20.2969C6.51639 20.2969 5.87982 20.9334 5.87982 21.7169C5.87982 22.5004 6.51639 23.137 7.29987 23.137C8.08334 23.137 8.71991 22.5004 8.71991 21.7169C8.71991 20.909 8.08334 20.2969 7.29987 20.2969Z", fill: "currentColor" }),
    React.createElement("path", { d: "M6.06771 17.007C6.68816 17.0511 7.15539 17.5898 7.1113 18.2103C7.0672 18.8307 6.52849 19.2979 5.90803 19.2538C5.28758 19.2098 4.82035 18.671 4.86444 18.0506C4.90854 17.4301 5.44726 16.9629 6.06771 17.007Z", fill: "currentColor" }),
    React.createElement("path", { d: "M6.82297 14.7941C6.85063 14.2946 6.46809 13.8672 5.96853 13.8395C5.46897 13.8118 5.04157 14.1944 5.0139 14.6939C4.98623 15.1935 5.36878 15.6209 5.86834 15.6486C6.3679 15.6762 6.7953 15.2937 6.82297 14.7941Z", fill: "currentColor" }),
    React.createElement("path", { d: "M5.97797 11.5807C5.97797 11.1156 6.3697 10.7238 6.83489 10.7238C7.30008 10.7238 7.69181 11.1156 7.69181 11.5807C7.69181 12.0459 7.30008 12.4377 6.83489 12.4377C6.3697 12.4377 5.97797 12.0459 5.97797 11.5807Z", fill: "currentColor" }),
    React.createElement("path", { d: "M8.37725 8.29987C8.10793 8.29987 7.8631 8.42228 7.74068 8.66712C7.61826 8.88747 7.61826 9.18127 7.74068 9.40162C7.8631 9.62198 8.13241 9.76888 8.37725 9.76888C8.76899 9.76888 9.08727 9.42611 9.08727 9.03437C9.08727 8.61815 8.76899 8.29987 8.37725 8.29987Z", fill: "currentColor" }),
    React.createElement("path", { d: "M9.60199 7.12467C9.60199 6.75742 9.89579 6.4881 10.2386 6.4881C10.5813 6.4881 10.8751 6.7819 10.8751 7.12467C10.8751 7.49192 10.6058 7.76124 10.2386 7.76124C9.87131 7.76124 9.60199 7.46744 9.60199 7.12467Z", fill: "currentColor" }),
    React.createElement("path", { d: "M12.1963 5.21503C11.9025 5.21503 11.6577 5.45986 11.6577 5.75366C11.6577 6.04747 11.9025 6.2923 12.1963 6.2923C12.4901 6.2923 12.7349 6.04747 12.7349 5.75366C12.7349 5.43538 12.4901 5.21503 12.1963 5.21503Z", fill: "currentColor" }),
    React.createElement("path", { d: "M13.788 4.84775C13.788 4.4805 14.0818 4.21118 14.4246 4.21118C14.7674 4.21118 15.0612 4.4805 15.0612 4.84775C15.0612 5.21501 14.7674 5.48433 14.4246 5.48433C14.0573 5.48433 13.788 5.19052 13.788 4.84775Z", fill: "currentColor" }),
    React.createElement("path", { d: "M17.0442 3.57465C16.7749 3.57465 16.5301 3.69706 16.4077 3.9419C16.2853 4.16225 16.2853 4.45605 16.4077 4.6764C16.5301 4.89676 16.7994 5.04366 17.0442 5.04366C17.436 5.04366 17.7543 4.70089 17.7543 4.30915C17.7543 3.91742 17.436 3.59913 17.0442 3.57465Z", fill: "currentColor" }),
    React.createElement("path", { d: "M19.1993 3.91734C19.3707 3.62353 19.6889 3.45215 20.0317 3.45215C20.5459 3.45215 20.9621 3.86837 20.9621 4.38252C20.9621 4.89668 20.5459 5.33738 20.0317 5.33738C19.6889 5.33738 19.3707 5.166 19.1993 4.87219C19.0279 4.57839 19.0279 4.21114 19.1993 3.91734Z", fill: "currentColor" }),
    React.createElement("path", { d: "M23.092 4.16219C22.4799 4.16219 21.9658 4.65186 21.9658 5.28843C21.9658 5.90052 22.4554 6.41468 23.092 6.41468C23.7041 6.41468 24.2182 5.92501 24.2182 5.28843C24.2182 4.67635 23.7041 4.16219 23.092 4.16219Z", fill: "currentColor" }),
    React.createElement("path", { d: "M24.683 7.22268C24.683 6.51265 25.2462 5.92505 25.9807 5.92505C26.6907 5.92505 27.2783 6.51265 27.2783 7.22268C27.2783 7.9327 26.7152 8.5203 25.9807 8.5203C25.2707 8.5203 24.683 7.9327 24.683 7.22268Z", fill: "currentColor" }),
    React.createElement("path", { d: "M28.2821 8.86304C27.768 8.86304 27.3028 9.13236 27.0335 9.57306C26.7642 10.0138 26.7642 10.5524 27.0335 10.9931C27.3028 11.4338 27.768 11.7031 28.2821 11.7031C29.0656 11.6786 29.6777 11.0666 29.6777 10.2831C29.6532 9.52409 29.0411 8.88752 28.2821 8.86304Z", fill: "currentColor" }),
    React.createElement("path", { d: "M28.6009 13.2946C28.7968 12.9518 29.1885 12.7559 29.5803 12.7559C30.1924 12.7804 30.6576 13.2701 30.6576 13.8577C30.6576 14.4453 30.1924 14.935 29.5803 14.9595C29.1885 14.9839 28.7968 14.7636 28.6009 14.4208C28.4051 14.0781 28.4051 13.6373 28.6009 13.2946Z", fill: "currentColor" }),
    React.createElement("path", { d: "M35.2849 15.9877C35.2849 17.016 34.4525 17.8485 33.4242 17.8485C32.3959 17.8485 31.5634 17.016 31.5634 15.9877C31.5634 14.9594 32.3959 14.127 33.4242 14.127C34.4525 14.1514 35.2849 14.9839 35.2849 15.9877Z", fill: "currentColor" }),
    React.createElement("path", { d: "M124.331 15.5226C125.898 16.1592 126.902 17.0896 126.902 18.8524C126.902 20.7131 125.752 22.7208 122.006 22.6718C120.365 22.6718 118.7 22.378 117.819 22.1821C117.647 22.1576 117.476 22.0107 117.476 21.8149V20.4928C117.476 20.2969 117.623 20.15 117.819 20.15H117.868L117.981 20.1652C118.77 20.271 120.423 20.4928 122.054 20.4928C123.719 20.4928 124.209 19.9052 124.209 18.8279C124.209 17.7465 122.153 17.0307 120.491 16.4521C120.046 16.2971 119.629 16.152 119.288 16.0123C117.672 15.3512 117.109 14.0781 117.109 12.7805C117.109 10.7238 118.749 9.25482 122.177 9.25482C123.94 9.25482 125.556 9.59759 126.192 9.74449C126.388 9.76898 126.535 9.91588 126.535 10.0873V11.4583C126.535 11.6542 126.413 11.8011 126.217 11.8011H126.168C125.863 11.77 125.554 11.7327 125.236 11.6942C124.3 11.581 123.285 11.4583 122.079 11.4583C120.708 11.4583 119.68 11.948 119.68 12.7805C119.68 13.3925 120.39 13.9557 121.198 14.2739C121.698 14.48 122.287 14.7126 122.943 14.9717C123.378 15.1435 123.843 15.3274 124.331 15.5226Z", fill: "currentColor" }),
    React.createElement("path", { d: "M90.6665 11.5562H86.9695V22.1821C86.9695 22.3779 86.7981 22.5493 86.6022 22.5493H84.7659C84.5701 22.5493 84.3987 22.3779 84.3987 22.1821V11.5562H80.7017C80.5058 11.5562 80.3344 11.4338 80.3344 11.2135V9.74444C80.3344 9.54857 80.5058 9.40167 80.7017 9.40167H90.6665C90.8623 9.40167 91.0337 9.54857 91.0337 9.74444V11.2135C91.0337 11.4338 90.8623 11.5562 90.6665 11.5562Z", fill: "currentColor" }),
    React.createElement("path", { d: "M78.7183 9.76898V17.4813C78.7183 21.4231 76.1476 22.6963 73.1851 22.6963C70.2226 22.6963 67.6518 21.3987 67.6518 17.4568V9.76898C67.6518 9.57311 67.8232 9.42621 68.0191 9.42621H69.8553C70.0512 9.42621 70.2226 9.57311 70.2226 9.76898V17.4568C70.2226 19.5624 71.2019 20.5662 73.1851 20.5662C75.1682 20.5662 76.1476 19.5624 76.1476 17.4568V9.76898C76.1476 9.57311 76.319 9.42621 76.5148 9.42621H78.3511C78.547 9.42621 78.7183 9.57311 78.7183 9.76898Z", fill: "currentColor" }),
    React.createElement("path", { d: "M95.2938 16.8447H101.219C101.415 16.8447 101.586 16.6733 101.586 16.4774V15.1308C101.586 14.9349 101.415 14.7636 101.219 14.7636H95.2938V13.0742C95.2938 11.5317 95.3428 11.4828 96.7628 11.4828H101.439C101.635 11.4828 101.806 11.3114 101.806 11.1155V9.94029C101.806 9.67098 101.708 9.59753 101.439 9.54856C100.852 9.45062 99.6518 9.27924 97.4483 9.27924L97.3902 9.27924C94.3671 9.27908 92.7475 9.279 92.7475 13.0742V18.9258C92.7475 22.7209 94.3671 22.7209 97.3902 22.7207L97.4483 22.7207C99.6518 22.7207 100.852 22.5493 101.439 22.4514C101.708 22.4269 101.806 22.329 101.806 22.0596V20.8844C101.806 20.6886 101.635 20.5172 101.439 20.5172H96.7628C95.3672 20.5172 95.2938 20.4682 95.2938 18.9258V16.8447Z", fill: "currentColor" }),
    React.createElement("path", { d: "M64.6651 16.8447H58.7401V18.9258C58.7401 20.4682 58.8135 20.5172 60.2091 20.5172H64.8854C65.0813 20.5172 65.2527 20.6886 65.2527 20.8844V22.0596C65.2527 22.329 65.1548 22.4269 64.8854 22.4514C64.2978 22.5493 63.0981 22.7207 60.8946 22.7207L60.8365 22.7207C57.8134 22.7209 56.1938 22.7209 56.1938 18.9258V13.0742C56.1938 9.279 57.8134 9.27908 60.8365 9.27924L60.8946 9.27924C63.0981 9.27924 64.2978 9.45062 64.8854 9.54856C65.1548 9.59753 65.2527 9.67098 65.2527 9.94029V11.1155C65.2527 11.3114 65.0813 11.4828 64.8854 11.4828H60.2091C58.789 11.4828 58.7401 11.5317 58.7401 13.0742V14.7636H64.6651C64.861 14.7636 65.0323 14.9349 65.0323 15.1308V16.4774C65.0323 16.6733 64.861 16.8447 64.6651 16.8447Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M115.566 22.1821L112.677 17.2364C114.195 16.6488 115.175 15.5716 115.175 13.6129V13.466C115.175 10.3076 112.555 9.2793 109.69 9.2793C108.344 9.2793 106.263 9.37723 105.308 9.52413C104.941 9.5731 104.72 9.72 104.72 10.0873V22.2066C104.72 22.4025 104.892 22.5739 105.087 22.5739H106.948C107.144 22.5739 107.291 22.4025 107.291 22.2066V17.6282C107.707 17.6527 109.201 17.7016 109.69 17.7016H110.107L112.775 22.1821C112.789 22.2064 112.803 22.2312 112.816 22.2559C112.896 22.4051 112.974 22.5494 113.142 22.5494H115.395C115.542 22.5494 115.615 22.4514 115.615 22.329C115.615 22.2801 115.591 22.2311 115.566 22.1821ZM112.604 13.6129C112.604 15.2288 111.576 15.596 109.666 15.596C109.504 15.596 108.959 15.5833 108.426 15.5708C107.913 15.5588 107.41 15.5471 107.266 15.5471V11.5563C107.585 11.5073 109.274 11.4828 109.666 11.4828C111.527 11.4828 112.604 12.0704 112.604 13.466V13.6129Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M51.2725 17.2364L54.1861 22.1821C54.2105 22.2311 54.2105 22.2801 54.2105 22.329C54.2105 22.4514 54.1371 22.5494 53.9902 22.5494H51.7377C51.5696 22.5494 51.4917 22.4051 51.4111 22.2559C51.3977 22.2312 51.3843 22.2064 51.3705 22.1821L48.7017 17.7016H48.2855C47.7959 17.7016 46.3024 17.6527 45.8861 17.6282V22.2066C45.8861 22.4025 45.7392 22.5739 45.5434 22.5739H43.6826C43.4868 22.5739 43.3154 22.4025 43.3154 22.2066V10.0873C43.3154 9.72 43.5357 9.5731 43.903 9.52413C44.8578 9.37723 46.9389 9.2793 48.2855 9.2793C51.1501 9.2793 53.7698 10.3076 53.7698 13.466V13.6129C53.7698 15.5716 52.7905 16.6488 51.2725 17.2364ZM48.31 15.596C50.1952 15.596 51.2236 15.2288 51.2236 13.6129V13.466C51.2236 12.0704 50.1708 11.4828 48.31 11.4828C47.9183 11.4828 46.2289 11.5073 45.9106 11.5563V15.5471C46.0546 15.5471 46.5571 15.5588 47.0697 15.5708C47.6033 15.5833 48.1477 15.596 48.31 15.596Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgReutersIcon);
var Memo = memo(ForwardRef);
export default Memo;
