import {
  BeneficiaryType,
  CheckIcon,
  DocumentIcon,
  EuroIcon,
  FingerprintIcon,
  HeartIcon,
  PadlockIcon,
  Spinner,
  SuitcaseIcon,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { generatePath, matchPath, useLocation, useMatch, useParams } from 'react-router-dom';
import OnboardingWizard, { OnboardingWizardStepProps } from '~/modules/application/OnboardingWizard';
import AuthenticationStep from '~/modules/application/OnboardingWizard/steps/AuthenticationStep';
import CompanyDetailsStep from '~/modules/application/OnboardingWizard/steps/CompanyDetailsStep';
import ContractStep from '~/modules/application/OnboardingWizard/steps/ContractStep';
import InitialPaymentStep from '~/modules/application/OnboardingWizard/steps/InitialPaymentStep';
import MonthlyPaymentStep from '~/modules/application/OnboardingWizard/steps/MonthlyPaymentStep';
import PersonalDetailsStep from '~/modules/application/OnboardingWizard/steps/PersonalDetailsStep';
import VerificationStep from '~/modules/application/OnboardingWizard/steps/VerificationStep';
import CompletedScreen from '~/modules/application/OnboardingWizard/steps/CompletedScreen';
import { useSession } from '~/modules/auth/SessionProvider';
import { useGetPortfolio } from '../queries';
import 'twin.macro';
import { useGetCompanyRelatedPersonInfo } from '~/modules/company/queries';
import { WelcomeScreen } from '~/modules/application/OnboardingWizard/steps/WelcomeScreen';

export enum ActivationLink {
  WELCOME = 'welcome',
  CREDENTIALS = 'credentials',
  PERSONAL_DETAILS = 'personal-details',
  COMPANY_DETAILS = 'company-details',
  VERIFICATION = 'verification',
  CONTRACT = 'contract',
  INITIAL_PAYMENT = 'initial-payment',
  MONTHLY_PAYMENT = 'monthly-payment',
  COMPLETED = 'completed',
}

export enum UBOActivationLink {
  WELCOME = 'welcome',
  CREDENTIALS = 'credentials',
  PERSONAL_DETAILS = 'personal-details',
  VERIFICATION = 'verification',
  COMPLETED = 'completed',
}

export enum VerificationLink {
  VERIFICATION = 'verification',
  COMPLETED = 'completed',
}

export const ActivationLinks = { ...ActivationLink, ...UBOActivationLink };
export type ActivationLinks = typeof ActivationLinks;

const iconStyle = { borderRadius: 999, color: '#031C2A', backgroundColor: '#FFF' };
const inactiveIconStyle = { backgroundColor: '#FFFFFF24', color: '#FFF' };

export enum ActivationViewType {
  PORTFOLIO = 'portfolio',
  COMPANY_UBO = 'company-ubo',
  VERIFICATION = 'kyc',
}

export const UBOBasePath = '/company-person/:id/onboard';
type MatchHash = { [key in ActivationViewType]: string };
export const activationMatchHash: MatchHash = {
  [ActivationViewType.PORTFOLIO]: '/portfolio/:id/activate/:subpath',
  [ActivationViewType.COMPANY_UBO]: `${UBOBasePath}/:subpath`,
  [ActivationViewType.VERIFICATION]: `/kyc/:subpath`,
};

export const getBaseRouteWithParams = (pathname: string, id: string, subpath = '') => {
  const pathPattern = Array.from(Object.values(activationMatchHash)).find((pattern) =>
    matchPath(pattern, pathname),
  ) as string;
  if (pathPattern == null) return pathname.endsWith('/') ? pathname : `${pathname}/`;
  return generatePath(pathPattern, { id, subpath });
};

export const useActivationViewComponents = (type: ActivationViewType) => {
  const match = useMatch(activationMatchHash[type]);
  const { pathname } = useLocation();
  const nextUrl =
    match?.params.id != null
      ? getBaseRouteWithParams(pathname, match?.params.id as string, ActivationLink.PERSONAL_DETAILS)
      : '/';

  const componentsTypeMap: {
    [key in ActivationViewType]: Partial<{ [key in ActivationLink & UBOActivationLink]: JSX.Element }>;
  } = {
    [ActivationViewType.PORTFOLIO]: {
      [ActivationLink.PERSONAL_DETAILS]: <PersonalDetailsStep />,
      [ActivationLink.COMPANY_DETAILS]: <CompanyDetailsStep />,
      [ActivationLink.VERIFICATION]: <VerificationStep />,
      [ActivationLink.CONTRACT]: <ContractStep />,
      [ActivationLink.INITIAL_PAYMENT]: <InitialPaymentStep />,
      [ActivationLink.MONTHLY_PAYMENT]: <MonthlyPaymentStep />,
      [ActivationLink.COMPLETED]: <CompletedScreen />,
    },
    [ActivationViewType.COMPANY_UBO]: {
      [UBOActivationLink.WELCOME]: <WelcomeScreen />,
      [UBOActivationLink.CREDENTIALS]: <AuthenticationStep nextUrl={nextUrl} />,
      [UBOActivationLink.PERSONAL_DETAILS]: <PersonalDetailsStep />,
      [UBOActivationLink.VERIFICATION]: <VerificationStep />,
      [UBOActivationLink.COMPLETED]: <CompletedScreen />,
    },
    [ActivationViewType.VERIFICATION]: {
      [VerificationLink.VERIFICATION]: <VerificationStep />,
      [VerificationLink.COMPLETED]: <CompletedScreen />,
    },
  };

  return componentsTypeMap[type];
};

type ActivationViewProps = {
  authMethods?: string[];
};

const ActivationView = (props: ActivationViewProps) => {
  const { t } = useTranslation('onboarding');
  const { authMethods = [] } = props;
  const { pathname } = useLocation();
  const session = useSession();
  const params = useParams();
  const { id: pathId, ['*']: subpath } = params;
  const baseRoute = getBaseRouteWithParams(pathname, pathId as string);

  const isAuthMethodsAvailable = props != null && Array.isArray(authMethods) && authMethods?.length > 0;
  const isCredentialsDisabled = session.authenticated && session.emailVerified;
  const isCompanyUBOView =
    matchPath(activationMatchHash[ActivationViewType.COMPANY_UBO], pathname) != null ||
    matchPath(UBOBasePath, pathname) != null; // second matchPath check for UBOBasePath because we want to allow redirect to /company-person/:id/onboard and let it automatically redirect to the first step
  const isVerificationFlow = matchPath('/kyc/*', pathname) != null;

  const { data, isIdle, isLoading } = useGetPortfolio(pathId as string, {
    enabled: !isVerificationFlow && !isCompanyUBOView,
  });
  const { beneficiaryType, monthlyInvestmentAmountEur, upfrontInvestmentAmountEur } = data || {};
  const onboardingInfo = useGetCompanyRelatedPersonInfo(pathId || '', {
    enabled: !!pathId && isCompanyUBOView,
  });

  const isMonthlyPaymentStepVisible =
    !isCompanyUBOView && monthlyInvestmentAmountEur != null && monthlyInvestmentAmountEur > 0;

  const isInitialPaymentStepVisible =
    !isCompanyUBOView && upfrontInvestmentAmountEur != null && upfrontInvestmentAmountEur > 0;

  const steps: OnboardingWizardStepProps[] = [
    {
      title: t('steps.welcome_to_grunfin.title'),
      label: t('steps.welcome_to_grunfin.navigation_title'),
      icon: <HeartIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <HeartIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.WELCOME,
      visible: isCompanyUBOView,
    },
    {
      title: authMethods.length > 0 ? t('steps.credentials.log_in') : t('steps.credentials.title'),
      label: isAuthMethodsAvailable
        ? t('steps.credentials.log_in_navigation_title')
        : t('steps.credentials.navigation_title'),
      icon: <PadlockIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <PadlockIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.CREDENTIALS,
      disabled: isCredentialsDisabled,
      visible: isCompanyUBOView || !isCredentialsDisabled,
    },
    {
      title: t('steps.personal_details.title'),
      label: t('steps.personal_details.navigation_title'),
      icon: <UserIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <UserIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.PERSONAL_DETAILS,
      visible: !isVerificationFlow,
    },
    {
      title: t('steps.company_details.title'),
      label: t('steps.company_details.navigation_title'),
      icon: <SuitcaseIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <SuitcaseIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.COMPANY_DETAILS,
      visible: beneficiaryType === BeneficiaryType.COMPANY && !isCompanyUBOView,
    },
    {
      title: t('steps.verification.title'),
      label: t('steps.verification.navigation_title'),
      icon: <FingerprintIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <FingerprintIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.VERIFICATION,
      // TODO: We can disable verification if user is already verified
    },
    {
      title: t('steps.contract.title'),
      label: t('steps.contract.navigation_title'),
      icon: <DocumentIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <DocumentIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.CONTRACT,
      visible: !isCompanyUBOView && !isVerificationFlow,
    },
    {
      title: t('steps.initial_payment.title'),
      label: t('steps.initial_payment.navigation_title'),
      icon: <EuroIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <EuroIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.INITIAL_PAYMENT,
      visible: isInitialPaymentStepVisible,
    },
    {
      title: t('steps.monthly_payment.title'),
      label: t('steps.monthly_payment.navigation_title'),
      icon: <EuroIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <EuroIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.MONTHLY_PAYMENT,
      visible: isMonthlyPaymentStepVisible,
    },
    {
      title: t('steps.completed.title'),
      label: t('steps.completed.navigation_title'),
      icon: <CheckIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <CheckIcon width={32} height={32} style={iconStyle} />,
      link: ActivationLink.COMPLETED,
    },
  ]
    // make sure to filter out non-visible steps
    .filter((item) => !('visible' in (item ?? {}) && item.visible === false));

  if (
    (!isCompanyUBOView && !isVerificationFlow && (isIdle || isLoading)) ||
    (isCompanyUBOView && (onboardingInfo.isIdle || onboardingInfo.isLoading)) ||
    !steps?.length
  ) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  const validRoutes = steps.map((step) => step.link);
  return (
    <OnboardingWizard
      subpath={subpath as string}
      baseRoute={baseRoute}
      validRoutes={validRoutes}
      outletContext={{
        ...onboardingInfo.data,
      }}
      steps={steps}
    />
  );
};

export default ActivationView;
