var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { offset } from '@floating-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { CheckIcon, EstonianIcon, EuropeanUnionIcon, GermanyIcon, GlobeIcon } from '../../icons';
import { Button, ButtonVariant } from '../Button';
import { MenuItem, MenuList } from '../Menu';
import Popover from '../Tooltip/Popover';
var mappedLanguages = {
    en: {
        code: 'en',
        label: 'English',
        icon: React.createElement(EuropeanUnionIcon, null),
    },
    de: {
        code: 'de',
        label: 'Deutsch',
        icon: React.createElement(GermanyIcon, null),
    },
    et: {
        code: 'et',
        label: 'Eesti',
        icon: React.createElement(EstonianIcon, null),
    },
};
export var LanguagePickerTheme;
(function (LanguagePickerTheme) {
    LanguagePickerTheme["DEFAULT"] = "DEFAULT";
    LanguagePickerTheme["LIGHT"] = "LIGHT";
    LanguagePickerTheme["DARK"] = "DARK";
})(LanguagePickerTheme || (LanguagePickerTheme = {}));
export var LanguagePickerVariant;
(function (LanguagePickerVariant) {
    LanguagePickerVariant["DEFAULT"] = "DEFAULT";
    LanguagePickerVariant["GLOBE"] = "GLOBE";
})(LanguagePickerVariant || (LanguagePickerVariant = {}));
export var LanguagePickerSize;
(function (LanguagePickerSize) {
    LanguagePickerSize["DEFAULT"] = "DEFAULT";
    LanguagePickerSize["COMPACT"] = "COMPACT";
    LanguagePickerSize["TINY"] = "TINY";
})(LanguagePickerSize || (LanguagePickerSize = {}));
var LanguagePicker = function (props) {
    var _a, _b, _c;
    var _d = props.size, size = _d === void 0 ? LanguagePickerSize.DEFAULT : _d, _e = props.variant, variant = _e === void 0 ? LanguagePickerVariant.DEFAULT : _e, _f = props.theme, theme = _f === void 0 ? LanguagePickerTheme.DEFAULT : _f, offsetOptions = props.offsetOptions, onLanguageChange = props.onLanguageChange;
    var i18n = useTranslation().i18n;
    var language = i18n.language;
    var locales = Object.keys((_c = (_b = (_a = i18n === null || i18n === void 0 ? void 0 : i18n.services) === null || _a === void 0 ? void 0 : _a.resourceStore) === null || _b === void 0 ? void 0 : _b.data) !== null && _c !== void 0 ? _c : {});
    var currentLanguage = mappedLanguages[language];
    var onSelect = function (language) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, i18n.changeLanguage(language)];
                case 1:
                    _a.sent();
                    typeof onLanguageChange !== 'undefined' && onLanguageChange(mappedLanguages[language]);
                    return [2 /*return*/];
            }
        });
    }); };
    var globe = twMerge(theme === LanguagePickerTheme.DARK && 'text-white', theme !== LanguagePickerTheme.DARK && 'text-alps-blue-600');
    var label = twMerge("text-base font-normal ml-1 mr-2", theme === LanguagePickerTheme.DEFAULT && 'text-alps-blue-600', theme === LanguagePickerTheme.DARK && 'text-white', theme === LanguagePickerTheme.LIGHT && 'text-alps-blue-600', size === LanguagePickerSize.TINY && variant === LanguagePickerVariant.GLOBE && "hidden");
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, __assign({ showOnHover: false, showOnFocus: false }, (offsetOptions != null && { options: { middleware: [offset(offsetOptions)] } }), { content: React.createElement(MenuList, null, locales.map(function (locale, i) {
                var language = mappedLanguages[locale];
                var isCurrentLanguage = i18n.resolvedLanguage === language.code;
                return (React.createElement(MenuItem, { key: i, onClick: function () { return onSelect(language === null || language === void 0 ? void 0 : language.code); } },
                    language.icon != null && (React.createElement("div", { className: "flex w-6 h-6 overflow-hidden rounded-full center" }, language.icon)),
                    React.createElement("span", { className: isCurrentLanguage ? 'text-gray-800' : 'text-gray-500' }, language.label),
                    isCurrentLanguage ? React.createElement(CheckIcon, null) : React.createElement("div", { className: "w-6 h-6" })));
            })) }),
            React.createElement(Button, __assign({ variant: size === LanguagePickerSize.COMPACT || variant === LanguagePickerVariant.GLOBE
                    ? ButtonVariant.TRANSPARENT
                    : ButtonVariant.SECONDARY, className: "px-1 rounded-full max-w-max" }, (theme !== LanguagePickerTheme.DEFAULT && { style: { background: 'none' } })),
                React.createElement("span", { className: "flex w-5 h-5 mx-1 overflow-hidden rounded-full center" }, size === LanguagePickerSize.COMPACT || variant === LanguagePickerVariant.GLOBE ? (React.createElement(GlobeIcon, { height: 20, className: globe })) : (currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.icon)),
                React.createElement("span", { className: label }, currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.label)))));
};
export default LanguagePicker;
