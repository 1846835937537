import { useParams } from 'react-router-dom';

import { useGetDirectDebitConsents, useGetPortfolio } from './queries';
import { DirectDebitConsent } from './types';
import { Portfolio } from '@grunfin/ui-kit';

export const usePortfolio = (): Portfolio => {
  const { id } = useParams<'id'>();
  if (!id) throw new Error('Missing portfolio id');
  const { data: portfolio } = useGetPortfolio(id, { staleTime: 60 * 1000 });
  if (!portfolio) throw new Error('No portfolio provided');
  return portfolio;
};

export const useDirectDebitEnabled = (): boolean => {
  const portfolio = usePortfolio();
  const { data: consents } = useGetDirectDebitConsents(portfolio.id);
  return !!consents?.directDebitMethodVisible;
};

export const useCurrentConsent = (): DirectDebitConsent | undefined => {
  const portfolio = usePortfolio();
  const enabled = useDirectDebitEnabled();
  const { data: consents } = useGetDirectDebitConsents(portfolio.id);
  return enabled ? consents?.availableConsents.find((c) => c.id === consents?.currentlySubscribedConsentId) : undefined;
};
