import { Checkbox, Trans, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

interface Props {
  value?: boolean;
  onChange?: (value: boolean) => void;
}

export const StripeTermsCheckbox = (props: Props) => {
  const { t, i18n } = useTranslation('portfolio');

  return (
    <div tw="mt-4 mb-8 md:mb-20">
      <Checkbox
        {...props}
        name="agreeToTerms"
        label={
          <Trans
            t={t}
            i18nKey="details.payment.direct_debit.agree_to_terms"
            components={[
              <a
                key="0"
                tw="text-alps-blue-400 underline mx-1"
                href={
                  i18n.language === 'de'
                    ? 'https://stripe.com/de/legal/end-users'
                    : 'https://stripe.com/en-gb-ee/legal/end-users'
                }
                target="_blank"
                rel="noopener"
              />,
            ]}
          />
        }
      />
    </div>
  );
};
