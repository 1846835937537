var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useRanger } from 'react-ranger';
import { twMerge } from 'tailwind-merge';
var Slider = function (props) {
    var _a, _b;
    var min = props.min, max = props.max, stepSize = props.stepSize;
    var _c = React.useState([min]), values = _c[0], setValues = _c[1];
    var handleValueChange = function (values) {
        setValues(values);
        if (typeof props.setValues !== 'undefined')
            props.setValues(values);
    };
    var _d = useRanger({
        min: min,
        max: max,
        stepSize: stepSize,
        values: (_a = props.values) !== null && _a !== void 0 ? _a : values,
        onChange: handleValueChange,
        ticks: (_b = props.ticks) !== null && _b !== void 0 ? _b : [0, 200, 400, 600, 800, 1000],
    }), getTrackProps = _d.getTrackProps, ticks = _d.ticks, segments = _d.segments, handles = _d.handles;
    return (React.createElement("div", __assign({}, getTrackProps(), { className: "h-2 rounded-full" }),
        ticks.map(function (_a, i) {
            var value = _a.value, getTickProps = _a.getTickProps;
            return (React.createElement("p", __assign({}, getTickProps(), { key: i, className: twMerge("text-base top-4 !transform text-gray-300", i !== 0 && '!-translate-x-4', i === 0 && '!-translate-x-1', i === ticks.length - 1 && '!-translate-x-5') }), value));
        }),
        segments.map(function (_a, i) {
            var getSegmentProps = _a.getSegmentProps;
            return (React.createElement("div", __assign({}, getSegmentProps(), { key: i, className: twMerge("h-full rounded-full", i === 0 && 'bg-alps-blue-200', i === 1 && 'bg-alps-blue-100') })));
        }),
        handles.map(function (_a, i) {
            var active = _a.active, getHandleProps = _a.getHandleProps;
            return (React.createElement("button", __assign({}, getHandleProps(), { key: i, className: "appearance-none border-none bg-transparent outline-none" }),
                React.createElement("div", { className: twMerge("w-5 h-5 rounded-full border-4 border-white bg-alps-blue-700 shadow-md focus:shadow-lg cursor-grab transition-all duration-75 ease-in", active && 'ring-2 ring-alps-blue-200 scale-110') })));
        })));
};
export default Slider;
