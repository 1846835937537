import 'twin.macro';

import { Button, ButtonSize, ButtonVariant, Input, useTranslation } from '@grunfin/ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { changePassword } from './api';

interface PasswordForm {
  email: string;
}

export const ForgotPasswordView = () => {
  const { t } = useTranslation('auth');
  const [result, setResult] = useState('');

  const { register, handleSubmit, formState } = useForm<PasswordForm>();

  const handleReset = async ({ email }: PasswordForm) => {
    setResult(await changePassword(email));
  };

  return (
    <div tw="rounded-2xl bg-white shadow px-12 py-10 mx-4 max-w-5xl">
      <h2 tw="text-center text-alps-blue-700 text-lg font-bold mb-12">{t('forgot_password.title')}</h2>
      <p tw="mx-auto mb-10 text-center" style={{ maxWidth: '240px' }}>
        {result ? result : t('forgot_password.subtitle')}
      </p>
      <form onSubmit={handleSubmit(handleReset)} tw="flex gap-y-4 flex-col items-center w-full max-w-xs">
        {!result && (
          <>
            <Input
              type="email"
              placeholder="E-mail"
              {...register('email', { required: true })}
              error={'email' in formState.errors}
            />
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} type="submit">
              {t('forgot_password.submit')}
            </Button>
          </>
        )}

        <Link to="/login" tw="text-alps-blue-400 hover:underline mt-6">
          {t('forgot_password.back')}
        </Link>
      </form>
    </div>
  );
};
