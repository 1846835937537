var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGeeniusLogo = function (props, ref) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", id: "Layer_1", x: 0, y: 0, viewBox: "0 0 128.8 30", xmlSpace: "preserve", ref: ref }, props),
    React.createElement("defs", null,
        React.createElement("path", { id: "SVGID_1_", d: "M0 0h128.8v30H0z" })),
    React.createElement("clipPath", { id: "SVGID_2_" },
        React.createElement("use", { xlinkHref: "#SVGID_1_", overflow: "visible" })),
    React.createElement("path", { d: "M123.1 7.1c-3.2 0-6.1 1.9-6.1 5 0 2 1 3.4 2.7 4.3l2.7 1.4c1.4.7 2.3 1.3 2.3 2.5 0 1.3-1 2-2.4 2-2.6 0-4.5-1.7-4.7-4.7h-.8v4.3c1.1.8 3.3 1.4 5.4 1.4 3.5 0 6.4-2.2 6.4-5.5 0-2.2-1.3-3.6-3.3-4.6l-2.5-1.4c-1-.5-1.7-1-1.7-2s.8-1.8 2-1.8c2.5 0 3.5 1.8 3.7 4h.8l.1-3.9c-.8-.5-2.7-1-4.6-1M109 23h7.1v-.7c-1.4 0-1.5-.4-1.5-2.1V7.4h-7.1v.7c1.4 0 1.5.4 1.5 2.1v9.3c-.5.9-1.1 1.3-1.8 1.3-1.3 0-2.1-.9-2.1-3.3V7.4H98v.7c1.4 0 1.5.4 1.5 2.1v8c0 2.8 1.7 5.1 4.9 5.1 2.1 0 3.8-1.3 4.6-2.8V23zM91.1 10.2v9.9c0 1.7-.1 2.1-1.5 2.1v.8h8.7v-.7c-1.4 0-1.5-.4-1.5-2.1V7.4h-7.1v.7c1.3.1 1.4.4 1.4 2.1m-.2-7.3c0 1.6 1.3 2.9 3 2.9s3-1.3 3-2.9c0-1.7-1.4-2.9-3-2.9s-3 1.2-3 2.9M79.4 22.2c-1.4 0-1.5-.4-1.5-2.1V11c.4-1 1.2-1.4 1.9-1.4 1.3 0 2.3.9 2.3 3.3v7.3c0 1.7-.1 2.1-1.5 2.1v.7h8.7v-.7c-1.4 0-1.5-.4-1.5-2.1v-8c0-2.8-1.8-5.1-5-5.1-2.2 0-4 1.5-4.8 3V7.4h-7.1v.7c1.4 0 1.5.4 1.5 2.1v9.9c0 1.7-.1 2.1-1.5 2.1v.8h8.7v-.8zm-8.8-3l-.6-.4c-1 1.3-2 1.9-3.5 1.9-2.9 0-5.2-2.9-5.4-7.3h9.2c0-3-2.4-6.3-6.5-6.3-4.2 0-8 3.7-8 8.5 0 4.6 3.6 7.7 8 7.7 2.8 0 5.3-1.4 6.8-4.1M63.7 8.1c1.5 0 2.4 1.7 2.4 4.3h-4.9c.2-2.9 1.1-4.3 2.5-4.3m-8.1 11.1l-.5-.4c-1 1.3-2 1.9-3.5 1.9-2.9 0-5.2-2.9-5.4-7.3h9.2c0-3-2.4-6.3-6.5-6.3-4.2 0-8 3.7-8 8.5 0 4.6 3.6 7.7 8 7.7 2.8 0 5.3-1.4 6.7-4.1M48.8 8.1c1.5 0 2.4 1.7 2.4 4.3h-4.9c.1-2.9 1-4.3 2.5-4.3m-13 17c1.6.4 2.2.8 2.2 1.8 0 1.5-2.2 2.2-5 2.2-3 0-5-1.1-5-3.2 0-1.2.6-2 1.8-2.2l6 1.4zm5.9-.9c0-2.4-1.7-3.7-5.2-4.3l-4.9-.9c-.5-.1-.9-.3-.9-.7 0-.4.2-.6 1-.8.4 0 .7.1 1.1.1 3.9 0 7-2.2 7-5.3 0-1.2-.5-2.3-1.4-3.2l3.1.1.7-1.9c-1.8.2-3.9.2-6.7.2-.8-.2-1.8-.4-2.7-.4-3.9 0-6.9 2.2-6.9 5.2 0 2.5 2 4.5 4.9 5.1-1.8.5-3.2 1.7-3.2 3.4 0 1.2.8 2.2 1.7 2.5-2.6.1-4.6 1.1-4.6 2.8 0 2.6 4.1 3.9 8.6 3.9 4.1 0 8.4-2.3 8.4-5.8m-6.9-11.9c0 2.6-.9 4.4-2 4.4-1.2 0-2-1.8-2-4.4 0-2.6.8-4.3 2-4.3 1.1 0 2 1.7 2 4.3", clipPath: "url(#SVGID_2_)", fill: "#231f20" }),
    React.createElement("path", { className: "st1", d: "M0 18.9C0 20.6 1.4 22 3.1 22s3.1-1.4 3.1-3.1c0-1.7-1.3-3.1-3.1-3.1S0 17.1 0 18.9" }),
    React.createElement("path", { className: "st1", d: "M1.5 22c0 .9.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-.9-.7-1.5-1.5-1.5s-1.5.6-1.5 1.5" }),
    React.createElement("path", { className: "st2", d: "M7.7 9.6c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.3-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1" }),
    React.createElement("path", { className: "st2", d: "M9.2 12.7c0 .9.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-.9-.7-1.5-1.5-1.5s-1.5.6-1.5 1.5" }),
    React.createElement("path", { className: "st3", d: "M7.7 18.9c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.3-3.1-3.1-3.1-1.7 0-3.1 1.3-3.1 3.1" }),
    React.createElement("path", { className: "st3", d: "M9.2 22c0 .9.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-.9-.7-1.5-1.5-1.5s-1.5.6-1.5 1.5" }),
    React.createElement("path", { className: "st4", d: "M15.4 9.6c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.3-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1" }),
    React.createElement("path", { className: "st4", d: "M16.9 12.7c0 .9.7 1.5 1.5 1.5.9 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5-.8-.1-1.5.6-1.5 1.5" }),
    React.createElement("path", { className: "st5", d: "M0 9.6c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1c0-1.7-1.3-3.1-3.1-3.1S0 7.9 0 9.6" }),
    React.createElement("path", { className: "st5", d: "M1.5 12.7c0 .9.7 1.5 1.5 1.5s1.5-.7 1.5-1.5c0-.9-.7-1.5-1.5-1.5s-1.5.6-1.5 1.5" }),
    React.createElement("path", { className: "st6", d: "M15.4 18.9c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.3-3.1-3.1-3.1-1.7 0-3.1 1.3-3.1 3.1" }),
    React.createElement("path", { className: "st6", d: "M16.9 22c0 .9.7 1.5 1.5 1.5.9 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5-.8-.1-1.5.6-1.5 1.5" }))); };
var ForwardRef = forwardRef(SvgGeeniusLogo);
var Memo = memo(ForwardRef);
export default Memo;
