import 'twin.macro';
import {
  BooleanRadio,
  Button,
  ButtonVariant,
  Combobox,
  ComboboxListItem,
  DateDropdown,
  Field,
  InfoTooltip,
  Input,
  MinusIcon,
  Select as UISelect,
  Trans,
  useDebounce,
  useTranslation,
} from '@grunfin/ui-kit';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, FieldArrayWithId, useFieldArray, useForm, UseFormSetError } from 'react-hook-form';
import { Company } from '~/modules/portfolio/types';
import { Country, ebrCountries } from '~/utils/countries';
import { useGetCompanyRelatedPersons, useGetCustomer, useSearchCompanies } from '~/modules/account/queries';
import { CompanyRelatedPerson } from './types';
import { useSession } from '../auth/SessionProvider';
import { getInitialTaxResidency } from '~/modules/account/utils';
import { useOutletContext } from 'react-router-dom';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  defaultValues: Company;
  onSubmit: (v: Company, setError: UseFormSetError<Company>) => void;
}

export function CompanyDetailsForm({ defaultValues: propDefaultValues, onSubmit }: Props) {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onBack } = context ?? {};
  const { t, i18n } = useTranslation('account');
  const c = useTranslation('countries');
  const session = useSession();
  const getCustomer = useGetCustomer();
  const [initialTaxResidency] = useState(getInitialTaxResidency(i18n.language));
  const defaultValues = {
    ...propDefaultValues,
    ...(!propDefaultValues?.taxResidencyCountryCode && {
      taxResidencyCountryCode: initialTaxResidency,
    }),
  };
  const { personalIdentificationCode, dateOfBirth, givenName, lastName } = getCustomer.data ?? {};
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors, isSubmitting },
    setError,
    watch,
    setValue,
    getValues,
  } = useForm<Company>({ defaultValues });
  const companyTaxResidency = watch('taxResidencyCountryCode');
  const registrationNumber = watch('registrationNumber');
  const manualFill = watch('manualFill');
  const required = t('required', { ns: 'general' }) as string;
  const [search, setSearch] = useState<string>(defaultValues.registrationNumber || '');
  const debouncedSearch = useDebounce(search, 500);
  const companies = useSearchCompanies(debouncedSearch)?.data?.data?.map((company) => ({
    value: company,
    label: company.name,
  }));
  const getRelatedPersons = useGetCompanyRelatedPersons(registrationNumber, !!registrationNumber && !manualFill);
  const {
    fields: relatedPeople,
    replace,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'relatedPersons',
  });

  // Whenever getRelatedPersons gets queried, map company members again
  useEffect(() => {
    if (!getRelatedPersons.isSuccess || !getCustomer.isSuccess) {
      return;
    }

    const people = getRelatedPersons.data
      .map((person) => {
        const exists = relatedPeople.find((r) => r.personalCode === person.personalCode || r.id === person.id);

        if (exists == null) {
          if (isCurrentCustomer(person)) {
            person.email = session.email;
            !person.dateOfBirth && (person.dateOfBirth = dateOfBirth as string);
          }

          return person;
        } else {
          return exists;
        }
      })
      .filter((p) => !!p) as CompanyRelatedPerson[];

    replace(people);
  }, [getRelatedPersons.data, getCustomer.data]);

  const isCurrentCustomer = (person: CompanyRelatedPerson) => person.personalCode === personalIdentificationCode;

  const sortCountries = (list: Country[]) =>
    list.sort((a, b) =>
      c
        .t('country.' + a.code)
        .toString()
        .localeCompare(c.t('country.' + b.code).toString()),
    );

  const handleSelectCompany = async (selectedCompany: ComboboxListItem | null) => {
    const { value: company } = companies?.find((c) => c.value.company_id === selectedCompany?.value) ?? {};
    company?.name && setValue('name', company?.name);
    company?.reg_code && setValue('registrationNumber', company?.reg_code);
    company?.legal_address && setValue('legalAddress', company?.legal_address);
    company?.zip_code && setValue('zipCode', company?.zip_code);
  };

  const personForm = (person: FieldArrayWithId<Company, 'relatedPersons', 'id'>, index: number) => {
    const getErrors = () => errors?.relatedPersons && errors.relatedPersons[index];

    return (
      <li key={person.id ?? person.personalCode} tw="flex flex-row">
        <div tw="flex flex-col w-full">
          <Field htmlFor="givenName" label={t('details.given_name.label')} error={getErrors()?.givenName?.message}>
            <Input
              type="text"
              id="givenName"
              disabled={isCurrentCustomer(person)}
              placeholder={t('details.given_name.label')}
              {...register(`relatedPersons.${index}.givenName`, { required })}
              error={!!getErrors()?.givenName}
            />
          </Field>
          <Field htmlFor="lastName" label={t('details.last_name.label')} error={getErrors()?.lastName?.message}>
            <Input
              type="text"
              id="lastName"
              disabled={isCurrentCustomer(person)}
              placeholder={t('details.last_name.label')}
              {...register(`relatedPersons.${index}.lastName`, { required })}
              error={!!getErrors()?.lastName}
            />
          </Field>

          <Field htmlFor="email" label={t('account:general.email')} error={getErrors()?.email?.message}>
            <Input
              type="text"
              id="email"
              disabled={isCurrentCustomer(person)}
              placeholder={t('account:general.email')}
              {...register(`relatedPersons.${index}.email`, { required: true, pattern: /^\S+@\S+$/i })}
              error={!!getErrors()?.email}
            />
          </Field>
          <Field
            htmlFor="personalCode"
            label={t('details.personalCode.label')}
            error={getErrors()?.personalCode?.message}
          >
            <Input
              id="personalCode"
              disabled={isCurrentCustomer(person)}
              {...register(`relatedPersons.${index}.personalCode`, { required })}
              error={!!getErrors()?.personalCode}
            />
          </Field>
          <Field htmlFor="dateOfBirth" label={t('details.dateOfBirth.label')} error={getErrors()?.dateOfBirth?.message}>
            <Controller
              rules={{ required }}
              control={control}
              name={`relatedPersons.${index}.dateOfBirth`}
              render={({ field }) => (
                <DateDropdown
                  {...field}
                  error={!!getErrors()?.dateOfBirth}
                  birthday
                  disabled={isCurrentCustomer(person)}
                />
              )}
            />
          </Field>
        </div>
        {manualFill && relatedPeople?.length > 1 && (
          <div tw="flex flex-col">
            <MinusIcon
              tw="bg-neutral-100 cursor-pointer rounded-full ml-4 mt-2 h-5 w-5"
              onClick={() => remove(index)}
            />
          </div>
        )}
      </li>
    );
  };

  const isRelatedPeopleVisible = () => (getRelatedPersons.isSuccess && relatedPeople?.length > 0) || manualFill;

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, setError))}>
      <div className="text-sm whitespace-pre-line mb-3">{t('details.company.description')}</div>
      <Combobox
        options={
          companies?.map((c) => ({
            value: c.value.company_id,
            label: c.label,
          })) as ComboboxListItem[]
        }
        disabled={!!manualFill}
        name="companySearch"
        floatingId="companySearchList"
        onInputChange={(value) => {
          clearErrors(); //Clear backend validation errors
          setSearch(value);
        }}
        onItemSelect={handleSelectCompany}
        placeholder={t('details.company.search')}
        noResultsText={t('details.company.no_matches')}
        loadingText={t('details.company.loading')}
      />
      <div
        tw="text-vivid-blue-400 text-sm text-right mt-2 hover:underline cursor-pointer"
        onClick={() => {
          if (!relatedPeople || relatedPeople.length === 0) {
            append({
              givenName,
              lastName,
              personalCode: personalIdentificationCode || undefined,
              dateOfBirth: dateOfBirth || undefined,
              email: session.email,
            });
          }

          setValue('manualFill', !getValues('manualFill'));
        }}
      >
        {t('details.company.didnt_find')}
      </div>
      <div tw="h-[1px] w-full bg-green-100 my-6" />
      {manualFill && <div tw="text-sm mb-6 text-vivid-blue-400">{t('details.company.fill_manually')}</div>}
      <Field htmlFor="companyName" label={t('details.company.name')}>
        <Input type="text" id="companyName" disabled={!manualFill} {...register('name', { required })} />
      </Field>
      <Field htmlFor="registrationNumber" label={t('details.company.regCode')}>
        <Input type="text" id="registrationNumber" disabled={!manualFill} {...register('registrationNumber')} />
      </Field>
      <Field htmlFor="address" label={t('details.company.address')}>
        <Input type="text" id="address" disabled={!manualFill} {...register('legalAddress')} />
      </Field>
      <Field
        htmlFor="lei"
        label={
          <LabelWithTooltip
            label={t('details.company.lei')}
            tooltip={
              <Trans
                i18nKey="details.company.lei_description"
                components={[
                  <a
                    key="0"
                    tw="underline"
                    href="https://www.leiregister.ee/leicert"
                    target="_blank"
                    rel="noreferrer"
                  />,
                  <a key="1" tw="underline" href="https://www.leiregister.ee" target="_blank" rel="noreferrer" />,
                ]}
                t={t}
              />
            }
          />
        }
        error={errors.lei?.message}
      >
        <Input
          type="text"
          id="lei"
          {...register('lei', {
            required,
            setValueAs: (c) => c?.trim(),
            validate: {
              mustBeValidLEI: (code) => /^[0-9A-Z]{18}[0-9]{2}$/.test(code),
            },
          })}
          autoComplete="off"
          error={'lei' in errors}
        />
      </Field>
      <Field
        label={
          <LabelWithTooltip
            label={t('details.company.tax_residency.label')}
            tooltip={t('details.company.tax_residency.tooltip')}
          />
        }
        help={t('details.company.tax_residency.help')}
        error={errors.taxResidencyCountryCode?.message}
      >
        <UISelect
          placeholder={t('choose', { ns: 'general' })}
          autoComplete="country"
          {...register('taxResidencyCountryCode', { required })}
          error={'taxResidencyCountryCode' in errors}
        >
          {sortCountries(ebrCountries).map((country) => (
            <option key={'taxResidencyCountryCode.' + c.t('country.' + country.code).toString()} value={country.code}>
              {c.t('country.' + country.code)}
            </option>
          ))}
        </UISelect>
      </Field>
      {companyTaxResidency !== 'EE' && (
        <Field htmlFor="taxId" label={t('details.company.tax_id')} error={errors.taxId?.message}>
          <Input {...register('taxId', { required })} id="taxId" autoComplete="off" error={'taxId' in errors} />
        </Field>
      )}
      <Field label={t('details.company.us_tax_resident.label')} error={errors.isUSTaxResident?.message}>
        <Controller
          control={control}
          rules={{
            validate: {
              mustBeFilled: (v) => typeof v === 'boolean' || required,
              mustBeFalse: (v) => !v || (t('details.company.us_tax_resident.not_supported') as string),
            },
          }}
          name="isUSTaxResident"
          render={({ field }) => (
            <BooleanRadio
              labelTrue={t('yes', { ns: 'general' })}
              labelFalse={t('no', { ns: 'general' })}
              error={'isUSTaxResident' in errors}
              {...field}
            />
          )}
        />
      </Field>
      <Field
        htmlFor="businessActivityDescription"
        label={
          <LabelWithTooltip
            label={t('details.company.businessActivityDescription')}
            tooltip={t('details.company.businessActivityDescriptionTooltip')}
          />
        }
        help={t('details.company.businessActivityHelp')}
        error={
          errors.businessActivityDescription?.message != null
            ? errors.businessActivityDescription.message.length > 0
              ? errors.businessActivityDescription.message
              : t('details.company.errors.businessActivityDescription')
            : undefined
        }
      >
        <Input
          as="textarea"
          id="businessActivityDescription"
          {...register('businessActivityDescription', {
            required,
            validate: { minLength: (value) => value.length >= 200 },
          })}
          rows={6}
          autoComplete="off"
          error={'businessActivityDescription' in errors}
        />
      </Field>
      <Field
        htmlFor="sourceOfFunds"
        label={
          <LabelWithTooltip
            label={t('details.company.sourceOfFunds')}
            tooltip={t('details.company.sourceOfFundsDescription')}
          />
        }
        help={t('details.company.sourceOfFundsHelp')}
        error={
          errors.sourceOfFunds?.message != null
            ? errors.sourceOfFunds.message.length > 0
              ? errors.sourceOfFunds.message
              : t('details.company.errors.sourceOfFunds')
            : undefined
        }
      >
        <Input
          as="textarea"
          id="sourceOfFunds"
          {...register('sourceOfFunds', {
            required,
            validate: { minLength: (value) => value.length >= 50 },
          })}
          rows={3}
          autoComplete="off"
          error={'sourceOfFunds' in errors}
        />
      </Field>
      {isRelatedPeopleVisible() && (
        <div tw="flex flex-col gap-10 mt-10">
          <div>
            <h3 tw="font-semibold text-neutral-900 text-lg">{t('details.company.members.title')}</h3>
            <p tw="text-sm">{t('details.company.members.description')}</p>
          </div>
          <ol>
            {relatedPeople
              .map((person, index) => personForm(person, index))
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .reduce((prev, curr) => [prev, <div key="0" tw="h-[1px] w-full bg-green-100 my-6" />, curr])}
          </ol>

          <div tw="h-[1px] w-full bg-green-100" />

          {manualFill && (
            <div
              tw="text-vivid-blue-400 text-sm mt-2 hover:underline cursor-pointer"
              onClick={() => {
                append({ id: uuidv4() });
              }}
            >
              {t('details.company.add_person')}
            </div>
          )}
        </div>
      )}
      <div tw="mt-14 flex flex-row w-full">
        <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
          {t('back', { ns: 'general' })}
        </Button>
        <Button
          disabled={isSubmitting}
          tw="ml-auto max-w-max"
          type="submit"
          variant={ButtonVariant.NEW_PRIMARY}
          data-test-id="details-continue"
        >
          {t('continue', { ns: 'general' })}
        </Button>
      </div>
      {errors && (
        <div tw="text-sm text-dawn-300 mt-4">
          {Object.keys(errors)
            .filter((key) => !['sourceOfFunds', 'businessActivityDescription', 'relatedPersons'].includes(key))
            .map((key) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return t('details.company.errors.' + key, { defaultValue: errors[key].message }) + ', ';
            })}
        </div>
      )}
    </form>
  );
}

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string | ReactNode }) => (
  <span tw="inline-flex items-center gap-2">
    <span>{label}</span>
    <InfoTooltip>{typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}</InfoTooltip>
  </span>
);
