import { Spinner, useTranslation } from '@grunfin/ui-kit';
import { cloneElement, useEffect, useState } from 'react';
import 'twin.macro';
import {
  useCreateQuestionnaire,
  useGetLatestQuestionnaire,
  useGetQuestionnaireWithQuestionnaireType,
} from '~/modules/onboarding/queries';
import {
  MifidQuestionnaire,
  PortfolioQuestionnaire,
  Questionnaire,
  QuestionnaireType,
} from '~/modules/onboarding/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { OnboardingStepWrapper } from '..';
import { useEnrollingMembershipId } from '~/modules/onboarding/hooks';

const useQuestionnaire = (questionnaireType: QuestionnaireType) => {
  const membershipId = useEnrollingMembershipId();

  const [recentQuestionnaire, setRecentQuestionnaire] = useState<Questionnaire>();
  const mifid = useGetLatestQuestionnaire({ type: 'mifid' });
  const latestQuestionnairePortfolio = useGetLatestQuestionnaire({
    type: questionnaireType,
    membershipId,
  });
  const [createdPortfolioId, setCreatedPortfolioId] = useState<string | null>(null);
  const { mutateAsync: createQuestionnaire, isLoading, isError } = useCreateQuestionnaire();
  const questionnaire = useGetQuestionnaireWithQuestionnaireType(
    questionnaireType,
    latestQuestionnairePortfolio?.data?.id ?? createdPortfolioId ?? undefined,
  );
  const [set, setter] = useState({ mifid: false, portfolio: false });

  const { isFetched: isMifidFetched, data: mifidData } = mifid;
  useEffect(() => {
    if (isMifidFetched && !mifidData && !set.mifid) {
      createQuestionnaire({ type: 'mifid' }).then(() => mifid.refetch());
      setter((p) => ({ ...p, mifid: true }));
    }
  }, [isMifidFetched, mifidData, createQuestionnaire, set]);

  const {
    isFetched: isLatestQuestionnairePortfolioFetched,
    isError: isLatestQuestionnairePortfolioError,
    data: latestQuestionnairePortfolioData,
    refetch: refetchLatestQuestionnaire,
  } = latestQuestionnairePortfolio;

  useEffect(() => {
    // if the portfolio request fails with 404, there is no latest portfolio questionnaire
    // and we have to make a new one
    if (isLatestQuestionnairePortfolioFetched && isLatestQuestionnairePortfolioError && !set.portfolio) {
      (async () => {
        const response = await createQuestionnaire({ type: questionnaireType });
        setCreatedPortfolioId(response.id);
        setter((p) => ({ ...p, portfolio: true }));
      })();
    }
  }, [
    createQuestionnaire,
    refetchLatestQuestionnaire,
    isLatestQuestionnairePortfolioFetched,
    isLatestQuestionnairePortfolioError,
    set,
    questionnaireType,
  ]);

  // set the questionnaire as the one that is fetched or from previous creation
  const { data: recentQuestionnaireData } = questionnaire;
  useEffect(() => {
    if (latestQuestionnairePortfolioData != null) setRecentQuestionnaire(latestQuestionnairePortfolioData);
    else setRecentQuestionnaire(recentQuestionnaireData);
  }, [latestQuestionnairePortfolioData, recentQuestionnaireData]);

  return {
    mifid,
    latestQuestionnaire: latestQuestionnairePortfolio,
    recentQuestionnaire,
    isLoading,
    isError,
  };
};

type InvestmentProfileStepProps = {
  questionnaireType: QuestionnaireType;
  children: React.ReactElement<{ answers: { mifid: MifidQuestionnaire; portfolio: PortfolioQuestionnaire } }>;
};

const InvestmentProfileStep = (props: InvestmentProfileStepProps) => {
  const { t } = useTranslation('onboarding');
  const { mifid, recentQuestionnaire, latestQuestionnaire, isLoading, isError } = useQuestionnaire(
    props.questionnaireType,
  );

  if (isLoading || (!isError && recentQuestionnaire == null) || mifid.isIdle || mifid.isLoading) {
    return (
      <OnboardingStepWrapper>
        <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
          <Spinner />
        </div>
      </OnboardingStepWrapper>
    );
  }

  if (isError) {
    return (
      <OnboardingStepWrapper>
        <ContactSupportOverlay
          title={t('questionnaire.error')}
          onClose={() => {
            latestQuestionnaire.refetch();
            mifid.refetch();
          }}
          error={latestQuestionnaire.error || mifid.error}
        />
      </OnboardingStepWrapper>
    );
  }
  return (
    <OnboardingStepWrapper>
      {cloneElement(props.children, {
        answers: {
          mifid: mifid.data as MifidQuestionnaire,
          portfolio: recentQuestionnaire as PortfolioQuestionnaire,
        },
      })}
    </OnboardingStepWrapper>
  );
};

export default InvestmentProfileStep;
