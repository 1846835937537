var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgSignOutIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m10 7c.5523 0 1 .44772 1 1v16c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1v-16c0-.55228.44772-1 1-1zm3 9c0-.5523.4477-1 1-1h8.5858l-4.2929-4.2929c-.3905-.3905-.3905-1.02368 0-1.41421.3905-.39052 1.0237-.39052 1.4142 0l6 6.00001c.3905.3905.3905 1.0237 0 1.4142l-6 6c-.3905.3905-1.0237.3905-1.4142 0s-.3905-1.0237 0-1.4142l4.2929-4.2929h-8.5858c-.5523 0-1-.4477-1-1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgSignOutIcon);
var Memo = memo(ForwardRef);
export default Memo;
