import {
  BeneficiaryType,
  formatCurrency,
  formatDate,
  formatPercent,
  InfoTooltip,
  Portfolio,
  Tooltip,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { Fragment } from 'react';
import tw, { TwStyle } from 'twin.macro';

interface Props {
  portfolio: Portfolio;
}

enum ListItemIdentifier {
  PAYMENTS = 'payments',
  IN_SETTLEMENT = 'in_settlement',
  GAIN = 'gain',
  GAIN_PERCENTAGE = 'gain_percentage',
  VALUE = 'value',
  TARGET_PAYMENTS = 'target_payments',
  TARGET = 'target',
  EMPLOYEE_CONTRIBUTIONS = 'employee_contributions',
  EMPLOYER_CONTRIBUTIONS = 'employer_contributions',
}

type ListItem = {
  id?: ListItemIdentifier;
  title: string;
  value: string | number | undefined;
  valueStyle?: TwStyle;
  indicatorStyle?: TwStyle;
  tooltip?: string | any | undefined;
  visible?: boolean;
};

type ProgressBarItem = {
  title: string;
  value: number | undefined;
  visible?: boolean;
  indicatorStyle?: TwStyle;
  barStyle?: TwStyle;
};

const renderList = (list: ListItem[][]) => {
  if (!list || !Array.isArray(list) || list.length === 0) return null;

  const filteredList = list.map((el: ListItem[]) =>
    el.filter((item: ListItem) => ('visible' in (item ?? {}) && item.visible === false ? false : true)),
  );

  return (
    <div tw="flex flex-row flex-wrap justify-between gap-4">
      {filteredList.map((row, i) => (
        <div key={i} tw="flex flex-row flex-wrap items-center gap-4 sm:gap-8">
          {row.map((item, idx) => {
            if (!item) return null;
            return (
              <div key={idx} tw="flex flex-col flex-auto">
                <h6 tw="text-sm text-gray-500 flex items-center gap-x-2 mb-1">
                  {item.title}
                  {item.tooltip != null && <InfoTooltip>{item.tooltip}</InfoTooltip>}
                  {item.indicatorStyle != null && (
                    <div tw="w-2 h-2 inline-block rounded-full" css={item.indicatorStyle} />
                  )}
                </h6>
                <p tw="text-lg" css={item.valueStyle}>
                  {item.value}
                </p>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const renderProgressBar = (list: ProgressBarItem[]) => {
  if (!list || !Array.isArray(list) || list.length === 0) return null;

  const filteredList = list.filter((item: ProgressBarItem) =>
    'visible' in (item ?? {}) && item.visible === false ? false : true,
  );

  return (
    <div tw="bg-gray-100 flex flex-row justify-start overflow-hidden h-6 mt-12 rounded-2xl">
      {filteredList.map((item, i) => {
        return (
          <GraphTooltip key={i} label={item.title} indicatorStyle={item.indicatorStyle}>
            <div
              tw="w-full"
              css={item.barStyle}
              {...(item.value != null && { style: { width: `${item.value * 100}%` } })}
            />
          </GraphTooltip>
        );
      })}
    </div>
  );
};

const findListItemObjectByIdentifier = (list: ListItem[][], identifier: ListItemIdentifier) => {
  const row = list.find((row) => row.find((item) => item.id === identifier));
  return row ? row.find((item) => item.id === identifier) : undefined;
};

export const PortfolioFigures = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');
  const endYear = portfolio.endDate ? formatDate(portfolio.endDate) : undefined;
  const currentYear = formatDate(new Date(), 'YYYY');

  const retailList: ListItem[][] = [
    [
      {
        id: ListItemIdentifier.PAYMENTS,
        title: t('payments'),
        indicatorStyle: tw`bg-sky-blue-300`,
        value: formatCurrency(portfolio.depositedAmountEur, { maxDigits: 2 }),
      },
      {
        id: ListItemIdentifier.IN_SETTLEMENT,
        title: t('in_settlement'),
        value: formatCurrency(portfolio.inSettlementAmountEur, { maxDigits: 2 }),
        visible: portfolio.inSettlementAmountEur > 0,
      },
      {
        id: ListItemIdentifier.GAIN,
        title: t('gain'),
        indicatorStyle: tw`bg-forest-green-500`,
        value: formatCurrency(portfolio.gainAmountEur, { maxDigits: 2 }),
      },
      {
        id: ListItemIdentifier.GAIN_PERCENTAGE,
        title: t('gain') + ' %',
        tooltip: (
          <Trans
            className="select-none"
            i18nKey="modified_dietz"
            components={[<a key="0" tw="underline" href={t('modified_dietz_faq')} target="_blank" rel="noreferrer" />]}
            t={t}
          />
        ),
        value: formatPercent(portfolio.gainPercentage / 100),
      },
      {
        id: ListItemIdentifier.VALUE,
        title: t('value'),
        value: formatCurrency(portfolio.totalValueEur, { maxDigits: 2 }),
        valueStyle: tw`font-bold`,
      },
    ],
    [
      {
        id: ListItemIdentifier.TARGET_PAYMENTS,
        title: t('target_payments'),
        value: formatCurrency(portfolio.targetDepositedAmountEur ?? 0, { maxDigits: 2 }),
        visible: portfolio.targetDepositedAmountEur != null,
      },
      {
        id: ListItemIdentifier.TARGET,
        title: t('target'),
        value: formatCurrency(portfolio.targetValueEur ?? 0, { maxDigits: 2 }),
        visible: portfolio.targetValueEur != null,
        indicatorStyle: tw`bg-gray-100`,
      },
    ],
  ];

  const corporateList: ListItem[][] = [
    [
      {
        id: ListItemIdentifier.EMPLOYEE_CONTRIBUTIONS,
        title: t('your_contributions'),
        value: formatCurrency(portfolio?.depositedAmountByOwnerEur?.CUSTOMER, { maxDigits: 2 }),
      },
      {
        id: ListItemIdentifier.EMPLOYER_CONTRIBUTIONS,
        title: t('employer_contributions'),
        value: formatCurrency(portfolio?.depositedAmountByOwnerEur?.EMPLOYER, { maxDigits: 2 }),
      },
      ...[
        {
          ...findListItemObjectByIdentifier(retailList, ListItemIdentifier.GAIN),
          indicatorStyle: undefined, // remove the indicator, because corporate doesn't have progress bar
        } as ListItem,
        {
          ...findListItemObjectByIdentifier(retailList, ListItemIdentifier.GAIN_PERCENTAGE),
          indicatorStyle: undefined, // remove the indicator, because corporate doesn't have progress bar
        } as ListItem,
      ],
      ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.VALUE) as ListItem],
    ],
  ];

  const progressBarList: ProgressBarItem[] = [
    {
      title: t('payments'),
      barStyle: tw`bg-sky-blue-300 min-w-[12px]`,
      indicatorStyle: tw`bg-sky-blue-300`,
      value: portfolio.targetValueEur != null ? portfolio.depositedAmountEur / portfolio?.targetValueEur : undefined,
      visible: portfolio.depositedAmountEur > 0 && portfolio.targetValueEur != null,
    },
    {
      title: t('gain'),
      barStyle: tw`bg-forest-green-500 min-w-[4px]`,
      indicatorStyle: tw`bg-forest-green-500`,
      value: portfolio.targetValueEur != null ? portfolio.gainAmountEur / portfolio.targetValueEur : undefined,
      visible: portfolio.gainAmountEur > 0 && portfolio.targetValueEur != null,
    },
  ];

  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  const list = isCorporate ? corporateList : retailList;

  return (
    <div tw="pt-8 md:pt-16 mx-6 md:mx-12">
      {renderList(list)}
      {!isCorporate && endYear != null && (
        <Fragment>
          {renderProgressBar(progressBarList)}
          <div tw="flex items-center justify-between mt-3 text-sm text-gray-500">
            <div>{currentYear}</div>
            <div tw="flex items-center">
              <div tw="flex items-center mr-2 md:mr-4">
                <span tw="mr-2 hidden md:inline">{t('goal_reminder')}</span>
                <InfoTooltip>
                  <Trans i18nKey="goal_reminder_tooltip" t={t} />
                </InfoTooltip>
              </div>
              {endYear}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export const SimplePortfolioFigures = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');

  const list = [
    [
      {
        title: t('payments'),
        value: formatCurrency(portfolio.depositedAmountEur, { maxDigits: 2 }),
      },
      {
        title: t('gain'),
        value: formatCurrency(portfolio.gainAmountEur, { maxDigits: 2 }),
      },
      {
        title: t('gain') + ' %',
        value: formatPercent(portfolio.gainPercentage / 100),
      },
      {
        title: t('paid_out_value'),
        value: formatCurrency(portfolio.paidOutAmount, { maxDigits: 2 }),
      },
    ],
  ];

  return <div tw="pt-8 md:pt-16 mx-6 md:mx-12">{renderList(list)}</div>;
};

const GraphTooltip = ({
  label,
  indicatorStyle,
  children,
}: {
  label: string;
  indicatorStyle?: TwStyle;
  children: any;
}) => (
  <Tooltip
    content={
      <div className="p-2 bg-white shadow-inner md:p-4">
        <span tw="w-3 h-3 inline-block rounded-full mr-2" css={indicatorStyle} />
        {label}
      </div>
    }
  >
    {children}
  </Tooltip>
);
