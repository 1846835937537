import {
  AlertDialog,
  AlertNotice,
  BankButtons,
  Button,
  ButtonVariant,
  Dialog,
  DialogProps,
  DisplayError,
  formatCurrency,
  formatDate,
  InfoIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { captureException } from '@sentry/react';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { cloneElement, Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';
import { CopyToClipboard } from '~/modules/portfolio/components/CopyToClipboard';
import { ConditonalWrapper } from '~/utils';
import { default as TableBase } from '.';
import { useGetCompanyJournals, useRemoveJournal } from '../../queries';
import { Journal, JournalStatus } from '../../types';
import { JournalTag } from '../Tag';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from '~/modules/auth/SessionProvider';

const paginationSize = 10;
const JournalsTable = () => {
  const { t, i18n } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  const navigate = useNavigate();
  const [currentSelectedJournal, setCurrentSelectedJournal] = useState<Journal | undefined>();
  const removeJournal = useRemoveJournal(companyId ?? '');

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: paginationSize,
  });
  const { pageIndex, pageSize } = pagination;
  const [sorting, setSorting] = useState<SortingState>([]);

  const getCurrentSort = useCallback(() => {
    if (sorting.length <= 0) return ['createdAt,desc'];

    const sort = sorting.map((item) => {
      const order = item.desc ? 'desc' : 'asc';
      return `${item.id},${order}`;
    });

    return sort;
  }, [sorting]);

  const dataQuery = useGetCompanyJournals({
    companyId: companyId ?? '',
    page: pageIndex,
    size: pageSize,
    sort: getCurrentSort(),
  });

  const rows = useMemo(() => {
    if (dataQuery.data?.content && Array.isArray(dataQuery.data.content)) return dataQuery.data.content;
    return [];
  }, [dataQuery.data?.content]);

  const pageCount = dataQuery.data?.totalPages ?? -1;

  const canFetchMore = useMemo(() => {
    if (dataQuery.isSuccess) {
      if (dataQuery.data.last) return false;
      else return true;
    }
    return false;
  }, [dataQuery?.data?.last, dataQuery.isSuccess]);

  const columns = useMemo<ColumnDef<Journal>[]>(
    () => [
      {
        header: t('payment.payment_reference', { ns: 'portfolio' }),
        accessorFn: (row) => row.paymentReference,
        id: 'paymentReference',
        cell: (info) => {
          return <p tw="text-gray-400 font-mono">{info.getValue() as string}</p>;
        },
        footer: () =>
          canFetchMore && (
            <div tw="max-w-max">
              <Button
                variant={ButtonVariant.TRANSPARENT}
                onClick={() => setPagination((p) => ({ ...p, pageSize: p.pageSize + paginationSize }))}
              >
                {t('payments.show_more')}
              </Button>
            </div>
          ),
      },
      {
        header: t('table.amount'),
        accessorFn: (row) => row.amount,
        id: 'amount',
        cell: (info) => (
          <p tw="text-gray-900 text-base font-bold">{formatCurrency(info.getValue() as number, { digits: 2 })}</p>
        ),
      },
      {
        header: t('table.date'),
        accessorFn: (row) => row.createdAt,
        id: 'createdAt',
        cell: (info) => <p tw="text-gray-400">{formatDate(new Date(info.getValue() as string), 'DD MMM YYYY')}</p>,
      },
      {
        header: t('table.status'),
        accessorFn: (row) => row.status,
        id: 'status',
        cell: (info) => <JournalTag status={info.getValue() as JournalStatus} />,
      },
    ],
    [canFetchMore, i18n.language, t],
  );

  const handleDeleteJournal = async (journal: Journal) => {
    await removeJournal.mutateAsync(journal.id);
    // close the dialog
    setCurrentSelectedJournal(undefined);
    // refetch the data
    dataQuery.refetch();
  };

  const handleSetCurrentRowAsJournal = (row: Row<Journal>) => {
    const selectedJournalId = row.original.id;
    // open the dialog with the selected journal
    setCurrentSelectedJournal(rows.find((journal) => journal.id === selectedJournalId));
  };

  const table = useReactTable({
    data: rows,
    columns,
    pageCount,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  if (dataQuery.isError) {
    return (
      <DisplayError
        title={t('general.errors.journals')}
        buttonText={t('general:retry')}
        onClose={dataQuery.refetch}
        error={dataQuery.error}
      />
    );
  }

  if (dataQuery.data && dataQuery.data.content.length === 0) {
    return (
      <AlertNotice
        icon={<InfoIcon tw="bg-gray-100 rounded-full" />}
        title={t('journals.not_found.title')}
        description={t('journals.not_found.description')}
      >
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => navigate(`/company/${companyId}/benefit/${benefitId}/payments/upload`)}
          tw="max-w-max"
        >
          {t('payments.upload.title')}
        </Button>
      </AlertNotice>
    );
  }

  return (
    <div tw="flex flex-col w-full whitespace-nowrap">
      <TableBase table={table} onRowClick={handleSetCurrentRowAsJournal} />
      <JournalDialog
        open={currentSelectedJournal != null}
        journal={currentSelectedJournal}
        onOpenChange={(isOpen) => {
          // if the dialog is closed, we want to reset the current selected journal
          if (!isOpen) setCurrentSelectedJournal(undefined);
        }}
        handleDeleteJournal={handleDeleteJournal}
      />
    </div>
  );
};

interface JournalDialogProps extends Pick<DialogProps, 'open' | 'children'> {
  journal: Journal | undefined;
  onOpenChange: (open: boolean) => void;
  handleDeleteJournal: (journal: Journal) => Promise<void>;
}

type ListItem = {
  title: string;
  value: string | number | undefined;
  component?: JSX.Element;
};

const mapper = (list: ListItem[]) =>
  list.map((item) => {
    if (!item.value) return null;

    return (
      <li key={item.title} tw="flex flex-row flex-wrap justify-between items-center text-base gap-2">
        <span tw="text-gray-500">{item.title}</span>
        <span tw="text-gray-900 font-medium">{item.component ? cloneElement(item.component) : item.value}</span>
      </li>
    );
  });

export const JournalDialog = ({ open, onOpenChange, journal, handleDeleteJournal }: JournalDialogProps) => {
  const { t, i18n } = useTranslation('company');
  const session = useSession();

  const list: ListItem[] = [
    {
      title: t('table.amount'),
      value: formatCurrency(journal?.amount ?? Number(), { digits: 2 }),
    },
    {
      title: t('table.date'),
      value: formatDate(new Date(journal?.createdAt ?? new Date()), 'DD MMM YYYY'),
    },
    {
      title: t('table.status'),
      value: journal?.status,
      component: <JournalTag status={journal?.status as JournalStatus} />,
    },
    {
      title: t('payments.title'),
      value: journal?.entityIds?.split(',')?.length,
    },
    {
      title: t('payment.beneficiary_name', { ns: 'portfolio' }),
      value: journal?.beneficiaryName,
      component: (
        <span tw="text-gray-900 font-medium">
          {journal?.beneficiaryName} <CopyToClipboard gray value={journal?.beneficiaryName ?? ''} />
        </span>
      ),
    },
    {
      title: t('payment.account_number', { ns: 'portfolio' }),
      value: journal?.accountNumber,
      component: (
        <span tw="text-gray-900 font-medium">
          {journal?.accountNumber} <CopyToClipboard gray value={journal?.accountNumber ?? ''} />
        </span>
      ),
    },
    {
      title: t('payment.payment_reference', { ns: 'portfolio' }),
      value: journal?.paymentReference,
      component: (
        <span tw="text-gray-900 font-medium">
          {journal?.paymentReference} <CopyToClipboard gray value={journal?.paymentReference ?? ''} />
        </span>
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      render={({ close, descriptionId }) => {
        if (!journal) return null;
        return (
          <div tw="flex flex-col">
            <ol id={descriptionId} tw="flex flex-col gap-4 p-8 text-base text-gray-900 w-full md:min-w-[500px]">
              {mapper(list.slice(0, 4))}
              <hr />
              {mapper(list.slice(4))}
              <hr />
              {journal.status !== JournalStatus.ACTIVE && (
                <BankButtons
                  paymentCode={journal?.paymentReference + ', id: ' + uuidv4()}
                  email={session?.email || ''}
                  amount={journal?.amount ?? 0}
                  language={i18n.language}
                  redirectUrl={`${window.location.href}?success=true`}
                  cancelUrl={`${window.location.href}`}
                  disabled={(journal?.amount ?? 0) < 1}
                />
              )}
            </ol>
            <div tw="flex flex-row flex-wrap gap-8 justify-between items-center p-8">
              <ConditonalWrapper
                condition={journal.status === JournalStatus.PREPARED}
                wrap={(children) => (
                  <AlertDialog
                    title={t('journals.remove.title')}
                    description={t('journals.remove.description')}
                    actions={(alertCloseFn) => (
                      <div tw="flex flex-row flex-wrap items-center gap-4">
                        <Button variant={ButtonVariant.SECONDARY} onClick={alertCloseFn} tw="w-max">
                          {t('cancel', { ns: 'general' })}
                        </Button>
                        <Button
                          variant={ButtonVariant.DANGER}
                          onClick={async () => {
                            try {
                              await handleDeleteJournal(journal);
                              alertCloseFn();
                              close();
                            } catch (err) {
                              captureException(err);
                            }
                          }}
                          tw="w-max"
                        >
                          {t('confirm', { ns: 'general' })}
                        </Button>
                      </div>
                    )}
                  >
                    {children}
                  </AlertDialog>
                )}
              >
                <Button
                  variant={ButtonVariant.SECONDARY}
                  tw="w-max"
                  tabIndex={-1}
                  disabled={journal.status !== JournalStatus.PREPARED}
                >
                  {t('journals.remove.remove_journal')}
                </Button>
              </ConditonalWrapper>
              <Button variant={ButtonVariant.SECONDARY} onClick={close} tw="w-max">
                {t('close', { ns: 'general' })}
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Fragment />
    </Dialog>
  );
};

export default JournalsTable;
