var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgAppleIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 20, height: 20, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M16.3974 5.79521C16.2982 5.87234 14.5575 6.85291 14.5575 9.03434C14.5575 11.5574 16.772 12.4498 16.8381 12.4718C16.8271 12.5268 16.4855 13.6947 15.6702 14.8846C14.9431 15.9313 14.1828 16.9779 13.026 16.9779C11.8692 16.9779 11.5717 16.3059 10.2385 16.3059C8.93848 16.3059 8.47571 17 7.41802 17C6.36032 17 5.62211 16.0304 4.77382 14.8405C3.79325 13.4413 3 11.2708 3 9.21057C3 5.9053 5.14847 4.15346 7.26376 4.15346C8.3876 4.15346 9.32401 4.89166 10.0291 4.89166C10.7012 4.89166 11.7479 4.1094 13.0259 4.1094C13.5107 4.10951 15.2516 4.15367 16.3974 5.79521ZM12.4201 2.71033C12.9489 2.08233 13.3235 1.21195 13.3235 0.341579C13.3235 0.220394 13.3125 0.0992093 13.2905 0C12.4311 0.0330698 11.4064 0.572961 10.7894 1.28908C10.3047 1.83996 9.85291 2.71034 9.85291 3.5918C9.85291 3.72398 9.87499 3.85626 9.88598 3.90031C9.94103 3.9113 10.0292 3.9224 10.1174 3.9224C10.8886 3.92229 11.8581 3.40448 12.4201 2.71033Z", fill: "#1A202C" }))); };
var ForwardRef = forwardRef(SvgAppleIcon);
var Memo = memo(ForwardRef);
export default Memo;
