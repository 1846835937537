import { FocusArea, ImpactStatements, Portfolio, TreeCounter, useTranslation } from '@grunfin/ui-kit';
import tw from 'twin.macro';
import { usePortfolioImpact } from '~/modules/portfolio/queries';

interface Props {
  portfolio: Portfolio;
  variant?: FocusArea;
}

export const InvestmentImpact = ({ portfolio, variant }: Props) => {
  const { t } = useTranslation('portfolio');
  const { treeCount } = portfolio.visualisation;
  const { i18n } = useTranslation();
  const impact = usePortfolioImpact(portfolio?.id).data;

  return (
    <div tw="p-2 md:p-8 mt-12 md:mt-8">
      <div
        tw="flex justify-between items-center font-bold px-4 md:px-0"
        css={[variant != null && tw`text-alps-blue-800`]}
      >
        {t('impact.title')}
        {treeCount > 0 && <TreeCounter count={treeCount} variant={variant} />}
      </div>

      <div tw="py-6 px-3 md:px-0 flex gap-x-3 overflow-auto [&::-webkit-scrollbar]:hidden snap-x snap-mandatory">
        <ImpactStatements lang={i18n.resolvedLanguage} impact={impact} app="app" variant={variant} />
      </div>
    </div>
  );
};
