import { MembershipStatus, useTranslation } from '@grunfin/ui-kit';
import tw from 'twin.macro';
import { JournalStatus } from '../types';

type TagProps = {
  status: string;
  options: {
    success?: string[];
    failure?: string[];
    processing?: string[];
    default?: string[];
  };
};

const isIncludedStatus = (status: string, options: string[] | undefined) => (options ?? []).includes(status);

const Tag = ({ status, options }: TagProps) => {
  const { t } = useTranslation();

  let formattedStatus = status;
  if (status.includes('_')) {
    const c = status.split('_').join(' ');
    formattedStatus = c.charAt(0).toUpperCase() + c.slice(1).toLowerCase();
  } else formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const isSuccessStatus = isIncludedStatus(status, options.success);
  const isFailureStatus = isIncludedStatus(status, options.failure);
  const isProcessingStatus = isIncludedStatus(status, options.processing);
  const isDefaultStatus =
    isIncludedStatus(status, options.default) || (!isSuccessStatus && !isFailureStatus && !isProcessingStatus);

  const translatedStatusTag = t('general:status.' + status.toLowerCase());

  return (
    <span
      tw="py-1 px-2 rounded text-sm font-medium"
      css={[
        isSuccessStatus && tw`bg-forest-green-100 text-forest-green-800`,
        isFailureStatus && tw`bg-dawn-700 text-dusk-100`,
        isProcessingStatus && tw`bg-dawn-100 text-dawn-800`,
        isDefaultStatus && tw`bg-gray-100 text-gray-800`,
      ]}
    >
      {translatedStatusTag ?? formattedStatus}
    </span>
  );
};

export const MembershipStatusTag = ({ status }: { status: MembershipStatus }) => {
  return (
    <Tag
      status={status}
      options={{
        success: [MembershipStatus.ACTIVE],
        failure: [MembershipStatus.EXITED],
        processing: [MembershipStatus.INVITED, MembershipStatus.ONBOARDING],
        default: [MembershipStatus.INVITED],
      }}
    />
  );
};

export const JournalTag = ({ status }: { status: JournalStatus }) => {
  return (
    <Tag
      status={status}
      options={{
        success: [JournalStatus.ACTIVE],
        failure: [JournalStatus.REMOVED],
        processing: [JournalStatus.PREPARED],
        default: [JournalStatus.INACTIVE],
      }}
    />
  );
};

export default Tag;
