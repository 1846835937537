var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgCompassIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 49, height: 48, viewBox: "0 0 49 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M24.75 44C35.7957 44 44.75 35.0457 44.75 24C44.75 12.9543 35.7957 4 24.75 4C13.7043 4 4.75 12.9543 4.75 24C4.75 35.0457 13.7043 44 24.75 44Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M33.23 15.5195L28.99 28.2395L16.27 32.4795L20.51 19.7595L33.23 15.5195Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }))); };
var ForwardRef = forwardRef(SvgCompassIcon);
var Memo = memo(ForwardRef);
export default Memo;
