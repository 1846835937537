var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgAwardIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 12C10 8.68629 12.6863 6 16 6C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18C12.6863 18 10 15.3137 10 12ZM16 4C11.5817 4 8 7.58172 8 12C8 14.5264 9.17108 16.7793 11 18.2454V27.1677C11 27.5032 11.388 27.6896 11.6499 27.4801L15.6252 24.2999C15.8443 24.1245 16.1557 24.1245 16.3748 24.2999L20.3501 27.4801C20.612 27.6896 21 27.5032 21 27.1678V18.2454C22.8289 16.7793 24 14.5264 24 12C24 7.58172 20.4183 4 16 4Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgAwardIcon);
var Memo = memo(ForwardRef);
export default Memo;
