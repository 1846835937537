import { formatCurrency, Trans, useTranslation } from '@grunfin/ui-kit';
import { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { AuthContainer } from './AuthContainer';
import { SignupForm } from './SignupForm';

export const SignupView = () => {
  const { t } = useTranslation('auth');
  const colors = [tw`bg-alps-blue-100`, tw`bg-forest-green-100`, tw`bg-dusk-100`, tw`bg-dawn-100`];
  const list = t('signup.features.list', { returnObjects: true });

  return (
    <div tw="grid grid-cols-12 max-w-5xl">
      <div tw="md:col-span-5 lg:col-span-6 hidden md:block">
        <h3 tw="text-xl text-alps-blue-700 mt-4 mb-12 font-semibold">{t('signup.features.title')}</h3>
        <ol tw="text-lg flex flex-col gap-y-6">
          {Array.isArray(list) &&
            list.map((item, index) => {
              return (
                <li tw="flex items-center" key={index}>
                  <StepCounter css={colors[index]}>{index + 1}</StepCounter>
                  <p>
                    <Trans values={{ minDepositAmount: formatCurrency(1) }}>{item}</Trans>
                  </p>
                </li>
              );
            })}
        </ol>
      </div>
      <div tw="lg:col-start-8 lg:col-span-5 md:col-start-7 md:col-span-5 md:max-w-md col-span-full">
        <p tw="text-alps-blue-800 mb-8 mx-2 text-center whitespace-pre-line break-words font-semibold md:hidden">
          {t('signup.mobile_cta')}
        </p>
        <AuthContainer>
          <SignupForm nextUrl="/" />
        </AuthContainer>
      </div>
    </div>
  );
};

const StepCounter = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div tw="rounded-full font-bold w-14 h-14 mr-8 flex items-center justify-center flex-shrink-0" className={className}>
    {children}
  </div>
);
