import { useTranslation } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import 'twin.macro';

import { Heading } from '~/modules/portfolio/components/Heading';

export const Container = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('portfolio');

  return (
    <div tw="flex flex-col md:w-3/5 md:self-center md:pb-12">
      <Heading primary>{t('details.payment.top_up.title')}</Heading>
      <div tw="flex flex-col px-6">{children}</div>
    </div>
  );
};
