import { useTranslation } from '@grunfin/ui-kit';
import { PreferredRiskReturnRatio } from '~/modules/onboarding/types';

interface Props {
  level: PreferredRiskReturnRatio;
}

export const Risk = ({ level }: Props) => {
  const { t } = useTranslation('portfolio');

  switch (level) {
    case PreferredRiskReturnRatio.Low:
      return <>{t('risk.low')}</>;
    case PreferredRiskReturnRatio.Medium:
      return <>{t('risk.medium')}</>;
    case PreferredRiskReturnRatio.High:
      return <>{t('risk.high')}</>;
    case PreferredRiskReturnRatio.Very_High:
      return <>{t('risk.very_high')}</>;
  }
};
