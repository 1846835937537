import tw, { styled } from 'twin.macro';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
  ArrowIcon,
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  ErrorIcon,
  FAQ,
  FAQQuestion,
  Spinner,
  toast,
  useTranslation,
} from '@grunfin/ui-kit';
import { useFormInputFocus, useQuestionnaireFields, useQuestionnaireTitle, useReferralApplied } from './hooks';
import type { Answers, TypedQuestion } from './types';
import { PastPerformance, QuestionnaireInvestments } from './types';

import { useSubmitQuestionnaire } from './queries';
import { useSession } from '~/modules/auth/SessionProvider';
import { useCreatePortfolio } from '~/modules/portfolio/queries';
import {
  removeQuestionnaireId,
  serializeMifidExperience,
  serializePortfolioAreas,
  serializePortfolioInvestments,
  useComposeWarningMessages,
} from './utils';
import { trackAddToCart, trackQuestionnaireAnswer } from '~/utils/tracking-analytics';
import { VOUCHER_KEY } from '~/modules/gift/voucher/VoucherRedeem';
import { MonthlyAmountReminderPopup } from '~/modules/onboarding/components/MonthlyAmountReminderPopup';
import { useQuestions } from '~/modules/onboarding/questions';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import EmailCaptureStep from './EmailCaptureStep';

interface Props {
  answers: Answers;
}

const portfolioTypeParam = 'portfolioType';

export function Questionnaire({ answers }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('onboarding');
  const session = useSession();
  const createPortfolio = useCreatePortfolio('portfolio');
  const submitAnswers = useSubmitQuestionnaire();
  const questionList = useQuestions();
  const [fields, setFields] = useQuestionnaireFields(answers);
  const [page, setPageState] = useState(0);
  const [showZeroMonthlyAmountWarning, setShowZeroMonthlyAmountWarning] = useState(false);
  const formRef = useFormInputFocus(page);
  const composeWarningMessages = useComposeWarningMessages();
  const referralApplied = useReferralApplied();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCompany = fields.portfolioType === BeneficiaryType.COMPANY;
  const shouldShowWarning =
    answers.mifid.wouldWithdrawOnValueDrop ||
    (!answers.mifid.savingsForThreeMonths && !isCompany) ||
    answers.mifid.moreDebtThanAssets ||
    (parseInt(fields.periodInYears) || 0) <= 3 ||
    answers.mifid.pastPerformance !== PastPerformance.NotGuarantee;

  useEffect(() => {
    const portfolioType = searchParams.get(portfolioTypeParam);

    if (
      !!answers['portfolio'] &&
      !!portfolioType &&
      Object.values(BeneficiaryType).includes(portfolioType as BeneficiaryType)
    ) {
      submitAnswers
        .mutateAsync({
          type: 'portfolio',
          id: answers['portfolio'].id,
          body: {
            portfolioType,
          },
        })
        .then(() => {
          searchParams.delete(portfolioTypeParam);
          setSearchParams(searchParams);
          setPageState(1);
        });
    }
  }, [searchParams?.toString()]);

  useEffect(() => {
    if (searchParams.has('success')) {
      searchParams.delete('success');
      setSearchParams(searchParams);
      toast.success(t('questionnaire.email_success'));

      if (answers.portfolio.portfolioType === BeneficiaryType.COMPANY) {
        setPageState(1);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isCompany) {
      setFields((v) => ({
        ...v,
        investments: { monthlyInvestmentAmountEur: '0', upfrontInvestmentAmountEur: '5000' },
      }));
    }
  }, [isCompany]);

  const setPage = (page: number) => {
    setPageState(page);
    if (formRef.current) formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  let questions =
    fields.portfolioType !== BeneficiaryType.CHILD
      ? questionList.filter((q) =>
          'type' in q ? q.field !== 'childAge' && q.field !== 'ageOfChildAtTheEndOfPortfolio' : true,
        )
      : questionList.filter((q) => ('type' in q ? q.field !== 'periodInYears' : true));

  questions = isCompany
    ? questions.filter((q) => ('type' in q ? q.field !== 'savingsForThreeMonths' : true))
    : questions;

  questions = !shouldShowWarning
    ? questions.filter((q) => ('type' in q ? q.field !== 'acceptedWarningMessage' : true))
    : questions;

  if (session.authenticated) {
    questions = questions.filter((q) => ('field' in q ? q.field !== 'signUp' : true));
  }

  useQuestionnaireTitle(page, session.authenticated ? questions.length - 1 : questions.length - 2); // last page is extra warning. Should not be counted as a question page.

  const currentQuestion = questions[page];
  const nextQuestion = questions[page + 1];

  if (!currentQuestion) {
    return <Navigate to="/onboarding/result" />;
  }

  const lastQuestion = !nextQuestion || ('field' in nextQuestion && nextQuestion.field === 'signUp');
  const warningPage = 'field' in currentQuestion && currentQuestion.field === 'acceptedWarningMessage';
  const signUpPage = 'field' in currentQuestion && currentQuestion.field === 'signUp';
  let value: string | true | string[] | QuestionnaireInvestments | boolean = '';
  let setValue: (value: string | string[] | boolean | QuestionnaireInvestments) => void = () => undefined;
  let hasAnswer = true;

  if (createPortfolio.isLoading || submitAnswers.isLoading) {
    return (
      <div tw="flex items-center justify-center transition-all" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if ('type' in currentQuestion) {
    value = fields[currentQuestion.field];

    setValue = (value: string | string[] | boolean | QuestionnaireInvestments) =>
      setFields((v) => ({
        ...v,
        [currentQuestion.field]: value,
      }));

    hasAnswer = Array.isArray(value)
      ? value.length > 0
      : currentQuestion.field === 'investments'
        ? +(value as QuestionnaireInvestments).monthlyInvestmentAmountEur > 0 ||
          +(value as QuestionnaireInvestments).upfrontInvestmentAmountEur > 0
        : value !== '';
  }

  const enableAmountWarning = () => {
    setShowZeroMonthlyAmountWarning(true);
  };
  const disableAmountWarning = () => {
    setShowZeroMonthlyAmountWarning(false);
  };

  const createPortfolioFromQuestionnaire = () =>
    createPortfolio.mutateAsync().then(() => {
      session.authenticated && removeQuestionnaireId('mifid', 'portfolio');
      localStorage.removeItem(VOUCHER_KEY);
      trackAddToCart(createPortfolio.data);
      toast.success(t('questionnaire.success'));

      !nextQuestion && navigate('/onboarding/result'); //Redirect only when next question is not signup. Using function, because component is not re-rendered
    });

  const handleSubmit = async (directValue?: string | string[] | boolean | QuestionnaireInvestments) => {
    if (!('type' in currentQuestion)) return setPage(page + 1);
    const { field, type } = currentQuestion;
    if (!hasAnswer && field !== 'acceptedWarningMessage') return;

    let val;
    if (typeof directValue === 'boolean') {
      val = directValue;
    } else {
      val = directValue || value;
    }
    trackQuestionnaireAnswer(field, type, val, page);

    let body: { [key: string]: unknown } = { [field]: val };
    if (field === 'areas') body = serializePortfolioAreas(val as string[]);
    if (field === 'experience') body = serializeMifidExperience(val as string[]);
    if (field === 'investments') body = serializePortfolioInvestments(val as QuestionnaireInvestments);
    if (
      field === 'investments' &&
      body.monthlyInvestmentAmountEur == '0' &&
      !showZeroMonthlyAmountWarning &&
      !isCompany
    ) {
      enableAmountWarning();
      return;
    } else {
      disableAmountWarning();
    }

    if (field === 'acceptedWarningMessage') {
      body = composeWarningMessages(
        answers.mifid.wouldWithdrawOnValueDrop,
        answers.mifid.savingsForThreeMonths,
        answers.mifid.moreDebtThanAssets,
        (answers.portfolio.periodInYears || 0) <= 3,
        answers.mifid.pastPerformance !== PastPerformance.NotGuarantee,
      );
      answers.mifid.acceptedWarningMessage = '' + body.acceptedWarningMessage;
    }

    await submitAnswers.mutateAsync({ type, id: answers[type].id, body });

    lastQuestion && (await createPortfolioFromQuestionnaire());
    setPage(page + 1);
  };

  const goBack = () => setPage(page - 1);

  if (!session.emailVerified && !session.authenticated) return <EmailCaptureStep />;
  return (
    <div tw="grid grid-cols-12 gap-x-6 px-4 lg:px-0" css={[signUpPage && tw`mt-8 md:mt-0`]}>
      <div tw="flex justify-between items-center md:items-start col-start-1 col-span-full md:col-span-1 text-center relative top-1 row-start-1 mb-4">
        {page > 0 && !warningPage && (
          <div>
            <Button onClick={goBack}>
              <ArrowIcon tw="transform rotate-180" />
            </Button>
          </div>
        )}
        {warningPage && <ErrorIcon tw="bg-alps-blue-100 text-gray-900 rounded" />}
        {referralApplied && (
          <div tw="rounded-2xl bg-gray-100 px-2 py-1 text-gray-600 text-sm mt-2 ml-auto text-center md:hidden">
            {t('referral_applied_short', { ns: 'general' })}
          </div>
        )}
        {page > 0 && page <= questions.length - 2 && (
          <div tw="md:hidden text-gray-400 text-lg select-none">{page + '/' + (questions.length - 2)}</div>
        )}
      </div>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {submitAnswers.isError && (
          <ContactSupportOverlay
            title={t('questionnaire.submit_answer_error')}
            buttonText={t('close', { ns: 'general' })}
            onClose={submitAnswers.reset}
            error={submitAnswers.error}
          />
        )}
        {createPortfolio.isError && (
          <ContactSupportOverlay
            title={t('questionnaire.draft_portfolio_error')}
            buttonText={t('close', { ns: 'general' })}
            onClose={createPortfolio.reset}
            error={createPortfolio.error}
          />
        )}
        {currentQuestion.render({
          answers,
          value,
          setValue,
          setFields,
          submitValue: (v: typeof value) => {
            setValue(v);
            handleSubmit(v);
          },
        } as never)}
        {warningPage ? (
          <div tw="flex flex-row gap-4 mt-20">
            <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={goBack}>
              {t('back', { ns: 'general' })}
            </Button>
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} type="submit" data-test-id="accept-warning">
              {t('yes', { ns: 'general' })}
            </Button>
          </div>
        ) : (
          !currentQuestion.noButton && (
            <div tw="mt-20 w-max">
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                type="submit"
                disabled={!hasAnswer}
                data-test-id="onboarding-continue"
              >
                {page === 0
                  ? t('questionnaire.start')
                  : lastQuestion
                    ? t('questionnaire.finish')
                    : t('continue', { ns: 'general' })}
              </Button>
            </div>
          )
        )}
        <div tw="flex flex-col justify-center items-center mt-20">
          <FAQ
            style="BLUE"
            questions={
              t(`questions.${(currentQuestion as TypedQuestion).type}.${currentQuestion.field}.faq`, {
                returnObjects: true,
              }) as FAQQuestion[]
            }
          />
        </div>
      </Form>

      {showZeroMonthlyAmountWarning && (
        <MonthlyAmountReminderPopup open notNowHandler={handleSubmit} editHandler={disableAmountWarning} />
      )}
    </div>
  );
}

const Form = styled.form`
  ${tw`col-span-full md:col-span-10 row-start-2 md:row-start-1`};

  .mifid-question {
    ${tw`text-xl mb-12`}
  }

  .mifid-question-subtitle {
    ${tw`-mt-5 mb-14 text-sm md:text-base text-gray-500 md:text-gray-400`}
  }
`;
