import { BeneficiaryType, Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Heading } from '../../components/Heading';
import { usePortfolio } from '../../hooks';
import { PortfolioStatus } from '../../types';
import { MonthlyContribution } from './MonthlyContribution';
import { PaymentHistory } from './PaymentHistory';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import { SetUp } from './SetUp/SetUp';
import { TopUp } from './TopUp/TopUp';

export const Payments = () => {
  const portfolio = usePortfolio();
  const navigate = useNavigate();

  // Don't allow exited portfolios to access this route
  if (portfolio.status === PortfolioStatus.EXITED) {
    navigate('/', { replace: true });
    return null;
  }

  return (
    <div tw="flex flex-col py-8 md:px-6">
      <Outlet />
      <Routes>
        <Route index element={<Overview />} />
        <Route path="set-up/*" element={<SetUp />} />
        <Route path="top-up" element={<TopUp />} />
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
    </div>
  );
};

const Overview = () => {
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();
  const portfolio = usePortfolio();

  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  return (
    <>
      <div tw="flex flex-col md:flex-row mb-12 md:mb-20">
        <div tw="flex-1 md:mr-32">
          <Heading>{t('details.payment.overview.top_up.title')}</Heading>
          <div tw="px-6 pb-12">
            <h4 tw="text-lg mb-7">{t('details.payment.overview.top_up.description')}</h4>
            <Button
              onClick={() => navigate('top-up')}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.LARGE}
              disabled={portfolio.status === PortfolioStatus.SUSPENDED}
            >
              {t('details.payment.overview.top_up.begin')}
            </Button>
          </div>
        </div>
        {!isCorporate && <MonthlyContribution />}
      </div>
      <div tw="flex flex-col md:flex-row">
        <PaymentHistory />
        <PaymentMethod />
      </div>
    </>
  );
};
