var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgPadlockIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m13 13v-2c0-1.65685 1.3431-3 3-3s3 1.34315 3 3v2zm-2 0h-1c-1.10457 0-2 .8954-2 2v8c0 1.1046.89543 2 2 2h12c1.1046 0 2-.8954 2-2v-8c0-1.1046-.8954-2-2-2h-1v-2c0-2.76142-2.2386-5-5-5s-5 2.23858-5 5z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgPadlockIcon);
var Memo = memo(ForwardRef);
export default Memo;
