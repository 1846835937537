var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgTrashIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m14 10h4c0-1.10457-.8954-2-2-2s-2 .89543-2 2zm6 0h4c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1.0667l-.8088 12.133c-.0701 1.0506-.9427 1.867-1.9956 1.867h-8.2578c-1.0529 0-1.92552-.8164-1.99556-1.867l-.80887-12.133h-1.06667c-.55228 0-1-.4477-1-1s.44772-1 1-1h4c0-2.20914 1.7909-4 4-4s4 1.79086 4 4zm-7 11.5c-.2761 0-.5-.2239-.5-.5v-6c0-.2761.2239-.5.5-.5s.5.2239.5.5v6c0 .2761-.2239.5-.5.5zm3 0c-.2761 0-.5-.2239-.5-.5v-6c0-.2761.2239-.5.5-.5s.5.2239.5.5v6c0 .2761-.2239.5-.5.5zm2.5-.5c0 .2761.2239.5.5.5s.5-.2239.5-.5v-6c0-.2761-.2239-.5-.5-.5s-.5.2239-.5.5z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgTrashIcon);
var Memo = memo(ForwardRef);
export default Memo;
