import {
  AlertDialog,
  Button,
  ButtonSize,
  ButtonVariant,
  formatCurrency,
  Portfolio,
  Radio,
  RadioGroup,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { ChangeEventHandler, useState } from 'react';

export enum RevokeReason {
  CHANGE_INFO = 'CHANGE_INFO',
  REDUCE_CONTRIBUTIONS = 'REDUCE_CONTRIBUTIONS',
  STANDING_ORDER = 'STANDING_ORDER',
}

interface Props {
  portfolio: Portfolio;
  onSubmit: (reason: RevokeReason) => void;
  children: JSX.Element;
}

export const RevokeModal = ({ children, portfolio, onSubmit }: Props) => {
  const { t } = useTranslation('portfolio');
  const [reason, setReason] = useState<RevokeReason>();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setReason(event.target.value as RevokeReason);
  return (
    <AlertDialog
      title={
        <Trans t={t} i18nKey="details.payment.direct_debit.revoke_modal.warning" values={{ name: portfolio.name }} />
      }
      description={<p>{t('remove.prompt.notice')}</p>}
      content={
        <RadioGroup name="reason" selectedValue={reason} onChange={onChange}>
          <Radio
            value={RevokeReason.CHANGE_INFO}
            label={t('details.payment.direct_debit.revoke_modal.reason.change_info')}
          />
          <Radio
            value={RevokeReason.STANDING_ORDER}
            label={t('details.payment.direct_debit.revoke_modal.reason.manual')}
          />
          <Radio
            value={RevokeReason.REDUCE_CONTRIBUTIONS}
            label={t('details.payment.direct_debit.revoke_modal.reason.stop', { amount: formatCurrency(0) })}
          />
        </RadioGroup>
      }
      actions={(closeFn: () => void) => (
        <div className="flex flex-col md:flex-row">
          <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={closeFn}>
            {t('details.payment.direct_debit.revoke_modal.cancel')}
          </Button>
          <div style={{ margin: '1rem' }} />
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            disabled={!reason}
            onClick={() => {
              if (reason) {
                onSubmit(reason);
                closeFn();
              }
            }}
          >
            {t('continue', { ns: 'general' })}
          </Button>
        </div>
      )}
    >
      {children}
    </AlertDialog>
  );
};
