import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
var Tabs = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState((_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value), value = _f[0], setValue = _f[1];
    return (React.createElement("div", { className: "flex flex-col gap-4" },
        React.createElement("ol", { className: twMerge('flex flex-row gap-1 white overflow-x-auto p-1', (_c = props === null || props === void 0 ? void 0 : props.style) === null || _c === void 0 ? void 0 : _c.list) }, (_d = props.options) === null || _d === void 0 ? void 0 :
            _d.map(function (option) { return (React.createElement("button", { key: option.value, type: "button", value: option.value, onClick: function () {
                    setValue(option.value);
                    typeof props.onValueChange !== 'undefined' && props.onValueChange(option.value);
                }, className: twMerge('rounded px-4 py-2 bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-alps-blue-200', value === option.value && 'bg-alps-blue-600 text-white') }, option.label)); }),
            props.listSuffixComponent), (_e = props.options) === null || _e === void 0 ? void 0 :
        _e.map(function (option) {
            if (value !== option.value)
                return null;
            return React.createElement("div", { key: option.value }, option.component);
        })));
};
export default Tabs;
