import { Route, Routes } from 'react-router-dom';

import { NotFoundView } from '~/modules/application/NotFoundView';
import { CompanyJournalPaymentsUploadView } from './CompanyJournalPaymentsUploadView';

import { CompanyBenefitView } from './CompanyBenefitView';
import CompanyJournalView from './CompanyJournalView';
import { CompanyGeneralView } from './GeneralView';
import { CompanyLayout } from './Layout';
import { CompanyMemberUploadView } from './MemberUploadView';
import { CompanyPaymentsView } from './PaymentsView';
import CompanyAssetsView from './CompanyAssetsView';
import { CompanyIndexView } from './CompanyIndexView';
import { CompanyFeesView } from '~/modules/company/CompanyFeesView';

export function CompanyRoutes() {
  return (
    <CompanyLayout>
      <Routes>
        <Route index element={<CompanyGeneralView />} />
        <Route path=":companyId">
          <Route index element={<CompanyIndexView />} />
          <Route path="benefit">
            <Route index element={<></>} />
            <Route path=":benefitId">
              <Route index element={<CompanyBenefitView />} />
              <Route path="journals">
                <Route index element={<CompanyJournalView />} />
              </Route>
              <Route path="payments">
                <Route index element={<CompanyPaymentsView />} />
                <Route path="upload" element={<CompanyJournalPaymentsUploadView />} />
              </Route>
              <Route path="fees">
                <Route index element={<CompanyFeesView />} />
              </Route>
              <Route path="assets">
                <Route index element={<CompanyAssetsView />} />
              </Route>
              <Route path="memberships">
                <Route index element={<></>} />
                <Route path="upload" element={<CompanyMemberUploadView />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </CompanyLayout>
  );
}
