import { Customer, MembershipStatus } from '@grunfin/ui-kit';

export enum JournalStatus {
  ACTIVE = 'ACTIVE',
  PREPARED = 'PREPARED',
  INACTIVE = 'INACTIVE',
  REMOVED = 'REMOVED',
}

export type Journal = {
  id: string;
  amount: number;
  entityIds: string;
  paymentReference: string;
  beneficiaryName: string;
  accountNumber: string;
  createdAt?: string;
  status?: JournalStatus;
};

export type BenefitMembership = {
  companyId: string;
  companyName: string;
  membershipId: string;
  slug: string;
  status: MembershipStatus;
};

export enum BenefitScheme {
  EMPLOYER_ONLY = 'EMPLOYER_ONLY',
  EMPLOYEE_ONLY = 'EMPLOYEE_ONLY',
  BOTH = 'BOTH',
}

export type BenefitProgram = {
  id: string;
  name: string;
  slug: string;
  scheme: BenefitScheme;
  createdAt: string;
  updatedAt: string;
};

export type Company = {
  addressId: string;
  benefitPrograms: BenefitProgram[];
  createdAt: string;
  id: string;
  name: string;
  registrationNumber: string;
  relatedPersons: Customer[];
  updatedAt: string;
};

export type TransactionPayment = {
  portfolioId: string;
  portfolioRefNumber: string;
  employerSideId: string;
  employerAmount: string;
  employeeAmount: string;
  givenName?: string;
  lastName?: string;
};

export enum TransactionStatus {
  WAITING_FUNDING = 'WAITING_FUNDING',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  FUNDS_AVAILABLE = 'FUNDS_AVAILABLE',
  FUNDS_TO_RETURN = 'FUNDS_TO_RETURN',
  FUNDS_RETURNED = 'FUNDS_RETURNED',
  FUNDS_BOOKED = 'FUNDS_BOOKED',
  FUNDS_CONSUMED = 'FUNDS_CONSUMED',
  DISPUTED = 'DISPUTED',
  DELETED = 'DELETED',
}

export type Transaction = {
  amount: number;
  consumedAt: string | null;
  createdAt: string;
  currencyCode: string;
  givenName: string;
  lastName: string;
  portfolioId: string;
  settledAt: string | null;
  sourceType: 'EMPLOYER' | 'EMPLOYEE';
  status: TransactionStatus;
};

export type CompanyFee = {
  accountTransactionId: string;
  amount: number;
  feeGeneratedAt: string;
  feePaidDate: string;
};

export type AddEmployee = Omit<Customer, 'id'> & {
  email: string;
  employerSideId: string;
  taxResidencyCountryCode?: string;
  taxId?: string;
  language?: string;
};
