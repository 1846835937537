var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgSmartIdIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15 7.05484C15.3281 7.01862 15.6617 7 16 7C20.9706 7 25 11.0294 25 16C25 18.9438 23.5877 21.5575 21.3994 23.2012C20.9578 23.5329 20.8687 24.1597 21.2004 24.6013C21.5321 25.0429 22.159 25.132 22.6006 24.8003C25.2701 22.7951 27 19.5994 27 16C27 9.92487 22.0751 5 16 5C15.2479 5 14.5124 5.07563 13.8011 5.22002C13.2763 5.32655 12.9316 5.826 13.0112 6.34963C13.0038 6.39868 13 6.44889 13 6.5V19C13 19.5523 13.4477 20 14 20C14.5523 20 15 19.5523 15 19L15 7.05484ZM10.5997 8.79949C11.0412 8.46775 11.1303 7.84087 10.7985 7.39933C10.4668 6.95778 9.8399 6.86876 9.39835 7.20051C6.72943 9.20572 5 12.401 5 16C5 22.0751 9.92487 27 16 27C16.7521 27 17.4876 26.9244 18.1989 26.78C18.7237 26.6735 19.0684 26.174 18.9888 25.6504C18.9962 25.6013 19 25.5511 19 25.5L19 17C19 16.4477 18.5523 16 18 16C17.4477 16 17 16.4477 17 17L17 24.9452C16.6719 24.9814 16.3383 25 16 25C11.0294 25 7 20.9706 7 16C7 13.0566 8.41194 10.4432 10.5997 8.79949ZM18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgSmartIdIcon);
var Memo = memo(ForwardRef);
export default Memo;
