import { Auth0Result, Auth0UserProfile, WebAuth } from 'auth0-js';

const auth = new WebAuth({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  redirectUri: import.meta.env.VITE_AUTH0_REDIRECT_URI,
  responseType: 'token',
  scope: 'openid',
});

const connections = {
  default: 'Username-Password-Authentication',
  google: 'google-oauth2',
  apple: 'apple',
};

const popupOptions = { width: 610, height: 750 };

export const googleLogin = () => {
  return new Promise<Auth0Result>((resolve, reject) => {
    auth.popup.authorize(
      { connection: connections.google, owp: true, popupOptions, prompt: 'select_account' } as never,
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
};

export const appleLogin = () => {
  return new Promise<Auth0Result>((resolve, reject) => {
    auth.popup.authorize(
      { connection: connections.apple, owp: true, popupOptions, prompt: 'login' } as never,
      (error, result) => (error ? reject(error) : resolve(result)),
    );
  });
};

export const getUserInfo = (token: string) => {
  return new Promise<Auth0UserProfile>((resolve, reject) => {
    auth.client.userInfo(token, (error, user) => (error ? reject(error) : resolve(user)));
  });
};

export const changePassword = (email: string) => {
  return new Promise<string>((resolve, reject) => {
    auth.changePassword({ email, connection: connections.default }, (error, result) =>
      error ? reject(error) : resolve(result),
    );
  });
};
