import { useCookieScript, useTranslation } from '@grunfin/ui-kit';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { api } from '~/api';
import { useClaimQuestionnaires } from '~/modules/onboarding/hooks';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { identifyUser, initMixpanel, trackRedeemReferral } from '~/utils/tracking-analytics';

import { captureMessage } from '@sentry/react';
import { useSubmitSettings } from '~/modules/account/queries';
import { CustomerSettings } from '../account/types';
import { Loader } from './Loader';
import { useSessionQuery } from './queries';
import { QueryParam, Session, StorageKey } from './types';
import dayjs from 'dayjs';

const SessionContext = createContext<Session | null>(null);

/**
 * Hook for accessing session metadata
 */
export const useSession = () => {
  const session = useContext(SessionContext);
  if (session === null) throw new Error('No session provided');
  return session;
};

const PUBLIC_ROUTES = [
  '/login',
  '/logout',
  '/signup',
  '/forgot-password',
  '/referral',
  '/contribute',
  '/redeem',
  '/support',
  '/onboarding',
  '/benefits',
  '/enroll',
  '/company-person/:id/onboard/*',
];

export const REFERRAL_CODE_KEY = 'referralCode';

const isPublic = (route: string) =>
  PUBLIC_ROUTES.some((p) => {
    if (matchPath(p, route)) return true;
    return route.startsWith(p);
  });

/**
 * Context provider for global session - handles loading, errors and auth redirect
 */
export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [query, setQuery] = useSearchParams();
  const { t } = useTranslation('auth');
  const { i18n } = useTranslation();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const session = useSessionQuery();
  const claimQuestionnaires = useClaimQuestionnaires();
  const { mutateAsync: submitSettings } = useSubmitSettings();
  const { isConsented, isScriptRendered, setIsScriptRendered } = useCookieScript();
  const { authenticated, emailVerified } = session.data ?? {};

  // Initialize mixpanel when cookies are accepted or user presses the cookie banner "Accept" button
  useEffect(() => {
    // Render the cookie script
    const VITE_COOKIE_SCRIPT_ID = import.meta.env.VITE_COOKIE_SCRIPT_ID;
    if (!isScriptRendered && VITE_COOKIE_SCRIPT_ID != null) {
      const script = document.createElement('script');
      script.src = `//cdn.cookie-script.com/s/${VITE_COOKIE_SCRIPT_ID}.js`;
      script.async = true;
      document.body.appendChild(script);
      setIsScriptRendered(true);
    }
    if (isConsented && isScriptRendered) initMixpanel();
  }, [isConsented, isScriptRendered, setIsScriptRendered]);

  // When session data is present, identify the intercom user and boot intercom
  useEffect(() => {
    if (!session.data || !session.data.userId) return;
    identifyUser(session.data.userId);

    if (typeof window !== 'undefined' && 'Intercom' in window) {
      if (session.data) {
        const isValidName = session.data.firstName !== null && session.data.lastName !== null;
        window.Intercom('boot', {
          language_override: i18n.language,
          ...(isValidName && { name: `${session.data.lastName}, ${session.data.firstName}` }),
          ...(session.data.authenticated && {
            email: session.data.email,
            user_id: session.data.customerId,
            user_hash: session.data.customerHmac,
          }),
        });
      }
    }
  }, [session.data, i18n.language]);

  // This is a alternative for inserting campaign cookie to query param instead of cookie
  useEffect(() => {
    // if query has a StorageKey.CAMPAIGN, we should add it to cookies
    if (query.get(StorageKey.CAMPAIGN) != null)
      if (Cookies.get(StorageKey.CAMPAIGN) == null)
        Cookies.set(StorageKey.CAMPAIGN, query.get(StorageKey.CAMPAIGN) as string, { expires: 30 });
  }, [query]);

  useEffect(() => {
    const referralMatch = matchPath('/referral/:referralCode', pathname);

    if (referralMatch?.params?.referralCode) {
      query.delete(REFERRAL_CODE_KEY);
      setQuery(query);
      localStorage.setItem(REFERRAL_CODE_KEY, referralMatch.params.referralCode);
    } else if (query.has(REFERRAL_CODE_KEY)) {
      localStorage.setItem(REFERRAL_CODE_KEY, query.get(REFERRAL_CODE_KEY) as string);
    } else if (localStorage.getItem(REFERRAL_CODE_KEY)) {
      query.set(REFERRAL_CODE_KEY, localStorage.getItem(REFERRAL_CODE_KEY) as string);
      setQuery(query);
    }
  }, [pathname, query, setQuery]);

  // Register referral code when user is authenticated and REFERRAL_CODE_KEY is present
  useEffect(() => {
    const referralCode = localStorage.getItem(REFERRAL_CODE_KEY);

    if (referralCode && authenticated && emailVerified) {
      try {
        api.put(`referral/referralCode/${referralCode}`);
        trackRedeemReferral(referralCode);
        // remove it from local storage and search params
        localStorage.removeItem(REFERRAL_CODE_KEY);
        query.delete(REFERRAL_CODE_KEY);
        setQuery(query);
      } catch (err) {
        captureMessage(`Failed to claim referral code ${referralCode}`, 'info');
      }
    }
  }, [authenticated, emailVerified]);

  // Register newsletter or campaign when user is authenticated
  useEffect(() => {
    if (authenticated && emailVerified) {
      const newsletters = localStorage.getItem(StorageKey.NEWSLETTERS),
        // check for both cookie and query because cookie might not be set at this point when user is authenticated (useEffects run parallel)
        campaign = Cookies.get(StorageKey.CAMPAIGN) ?? query.get(StorageKey.CAMPAIGN);

      const isAnyPresent = newsletters === 'true' || campaign;
      if (isAnyPresent) {
        const data: CustomerSettings = {
          newsletters: newsletters === 'true',
          campaign: campaign ?? undefined,
        };

        submitSettings(data).then(() => {
          localStorage.removeItem(StorageKey.NEWSLETTERS);
          Cookies.remove(StorageKey.CAMPAIGN);
        });
      }
    }
  }, [authenticated, emailVerified, query, submitSettings]);

  // Set the token to local storage when it is present in the query, and claim questionnaires if authenticated and email verified
  useEffect(() => {
    if (query.has(QueryParam.TOKEN)) {
      localStorage.setItem(StorageKey.TOKEN, query.get(QueryParam.TOKEN) as string);
      query.delete(QueryParam.TOKEN);
    }
  }, [authenticated, emailVerified, claimQuestionnaires, query]);

  // Claim questionnaires. This ensures that questionnaires are claimed after token is set
  useEffect(() => {
    if (authenticated && emailVerified && localStorage?.getItem(StorageKey.TOKEN)) {
      claimQuestionnaires();
    }
  }, [authenticated, emailVerified, claimQuestionnaires]);

  // Redirect to the NEXT param, when it is present and user is authenticated
  useEffect(() => {
    let next = localStorage?.getItem(StorageKey.NEXT);
    const queryNext = query.get(QueryParam.NEXT);
    if (queryNext != null) next = queryNext;

    if (authenticated && next != null) {
      query.delete(QueryParam.NEXT);
      localStorage.removeItem(StorageKey.NEXT);
      navigate(next, { replace: true });
    }
  }, [authenticated, query, navigate, setQuery]);

  useEffect(() => {
    // Redirect to login page for non-public urls when logged out
    if (session.isFetched && !authenticated && !isPublic(pathname)) {
      let url = '/login';
      if ((pathname != null || hash != null) && pathname !== '/') url += `?next=${pathname}${hash}`;
      return navigate(url, { replace: true });
    }
  }, [session.isFetched, authenticated, pathname, hash, navigate]);

  useEffect(() => {
    //Redirect to account details, to review or update customer data, if not updated in a year
    if (session.data && dayjs(session.data.updatedAt).isBefore(dayjs().subtract(1, 'year'))) {
      return navigate('/account/details', { replace: true });
    }
  }, [session.isFetched, authenticated, pathname, hash, navigate]);

  if (session.isError) {
    return (
      <ContactSupportOverlay
        title={t('error')}
        buttonText={t('reload')}
        onClose={() => window.location.reload()}
        error={session.error}
      />
    );
  }

  if (!session.data || session.isIdle || session.isLoading) {
    return <Loader />;
  }

  if (!session.data.authenticated && !isPublic(pathname)) {
    return <Loader />;
  }

  return <SessionContext.Provider value={session.data}>{children}</SessionContext.Provider>;
};
