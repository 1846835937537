import { Button, ButtonVariant, CheckIcon, useTranslation } from '@grunfin/ui-kit';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';
import success_image from '~/assets/output/employee_upload_success.webp';

const MemberUploadSuccess = () => {
  const { t } = useTranslation('company');
  const navigate = useNavigate();
  const { companyId, benefitId } = useParams();

  return (
    <div tw="relative">
      <img src={success_image} alt="" tw="rounded md:rounded-2xl min-h-[400px] object-cover" />
      <div
        tw="absolute left-4 md:left-8 lg:left-16 flex flex-col gap-8 md:gap-14"
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          maxWidth: 'calc(100% - 4rem)',
        }}
      >
        <div tw="max-w-max">
          <LargeCheckIcon tw="bg-critical" />
        </div>
        <div tw="max-w-[320px]">
          <h1 tw="text-lg md:text-xl font-bold text-alps-blue-700">{t('general.success.title')}</h1>
          <p tw="text-lg md:text-xl text-alps-blue-700">{t('general.success.subtitle')}</p>
        </div>
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => navigate(`/company/${companyId}/benefit/${benefitId}`)}
          tw="max-w-max"
        >
          {t('general.success.view')}
        </Button>
      </div>
    </div>
  );
};

const LargeCheckIcon = () => (
  <div tw="bg-sky-blue-400 rounded-full p-2 md:p-4">
    <CheckIcon width={48} height={48} tw="text-white" />
  </div>
);

export default MemberUploadSuccess;
