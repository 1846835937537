var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgClipboardIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m15 5c-1.1046 0-2 .89543-2 2v4h4c1.6569 0 3 1.3431 3 3v6h3c1.1046 0 2-.8954 2-2v-11c0-1.10457-.8954-2-2-2zm4 9c0-1.1046-.8954-2-2-2h-4-4c-1.10457 0-2 .8954-2 2v11c0 1.1046.89543 2 2 2h8c1.1046 0 2-.8954 2-2v-5-3.5zm-2-7c-.5523 0-1 .44772-1 1s.4477 1 1 1h4c.5523 0 1-.44772 1-1s-.4477-1-1-1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgClipboardIcon);
var Memo = memo(ForwardRef);
export default Memo;
