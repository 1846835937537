import {
  AlertNotice,
  Button,
  ButtonVariant,
  DisplayError,
  formatDate,
  InfoIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';
import { formatCompanyPayment } from '~/utils';
import { default as TableBase } from '.';
import { useGetCompanyFees } from '../../queries';
import { CompanyFee } from '../../types';

const paginationSize = 10;
const FeesTable = () => {
  const { t, i18n } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: paginationSize,
  });
  const { pageIndex, pageSize } = pagination;

  const dataQuery = useGetCompanyFees(pagination, {
    companyBenefitId: benefitId,
    page: pageIndex,
    size: pageSize,
    sort: [''],
  });

  const rows = useMemo(() => {
    if (dataQuery.data?.content && Array.isArray(dataQuery.data.content)) return dataQuery.data.content;
    return [];
  }, [dataQuery.data?.content]);

  const pageCount = dataQuery.data?.totalPages ?? -1;

  const canFetchMore = useMemo(() => {
    if (dataQuery.isSuccess) {
      if (dataQuery.data.last) return false;
      else return true;
    }
    return false;
  }, [dataQuery?.data?.last, dataQuery.isSuccess]);

  const columns = useMemo<ColumnDef<CompanyFee>[]>(
    () => [
      {
        header: t('table.fee_period'),
        accessorFn: (row) => row.feeGeneratedAt,
        id: 'month',
        cell: (info) => {
          const previousRow = rows[info.row.index - 1];
          let returnValue = '';
          if (previousRow) {
            const previousDate = new Date(previousRow.feeGeneratedAt);
            const currentDate = new Date(info.getValue() as string);
            if (previousDate.getMonth() !== currentDate.getMonth()) {
              returnValue = currentDate.toLocaleString(i18n.language, { month: 'long' });
            }
          } else returnValue = new Date(info.getValue() as string).toLocaleString(i18n.language, { month: 'long' });
          return <p tw="text-gray-400 text-lg min-w-[150px]">{returnValue}</p>;
        },
        enableSorting: false,
      },
      {
        header: t('table.settled_at'),
        accessorFn: (row) => row.feePaidDate,
        id: 'createdAt',
        cell: (info) => <p tw="text-gray-500">{formatDate(new Date(info.getValue() as string), 'DD MMM')}</p>,
      },
      {
        header: t('table.fee'),
        accessorFn: (row) => row.amount,
        id: 'amount',
        cell: (info) => formatCompanyPayment(info.getValue() as string),
      },
    ],
    [rows, canFetchMore, i18n.language, t],
  );

  const table = useReactTable({
    data: rows,
    columns,
    pageCount,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  if (dataQuery.isError) {
    return (
      <DisplayError
        title={t('general.errors.payments')}
        buttonText={t('general:retry')}
        onClose={dataQuery.refetch}
        error={dataQuery.error}
      />
    );
  }

  if (dataQuery.data && dataQuery.data.content.length === 0) {
    return (
      <AlertNotice
        icon={<InfoIcon tw="bg-gray-100 rounded-full" />}
        title={t('payments.not_found.title')}
        description={t('payments.not_found.description')}
      >
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => navigate(`/company/${companyId}/benefit/${benefitId}/payments/upload`)}
          tw="max-w-max"
        >
          {t('payments.upload.title')}
        </Button>
      </AlertNotice>
    );
  }

  return (
    <div tw="flex flex-col w-full whitespace-nowrap">
      <TableBase table={table} />
    </div>
  );
};

export default FeesTable;
