import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Footer, FooterVariant } from './Footer';
import { CompanyBenefitBaseNavigation, Navigation, useGetMembershipCompany, useNavigationContext } from './Navigation';
import { Select, useTranslation } from '@grunfin/ui-kit';
import { useSession } from '~/modules/auth/SessionProvider';
import { SelectedProgram } from '~/modules/auth/types';

export const CompanyLayout = () => {
  const { t } = useTranslation('company');
  const session = useSession();
  const navigate = useNavigate();
  const navState = useNavigationContext();
  const selectedProgram = useGetMembershipCompany();
  const location = useLocation();

  useEffect(() => {
    navState.setCustomActions(() => <CompanyBenefitBaseNavigation />);
  }, []);

  if (!selectedProgram) return null;

  const getUrl = (selectedProgram?: SelectedProgram): string => {
    const filteredPaths = location.pathname.split('/').splice(5).join('/');
    return `/company/${selectedProgram?.company?.companyId}/benefit/${selectedProgram?.benefitProgram?.id}/${filteredPaths}`;
  };

  const companies = session?.companies?.filter((c) => !!c.accessibleBenefitPrograms?.length);

  const options = companies?.map((company) =>
    company?.accessibleBenefitPrograms?.map((benefitProgram) => (
      <option key={benefitProgram.id} value={benefitProgram.id}>
        {company.companyName} - {benefitProgram.name}
      </option>
    )),
  );

  const getSelectedProgram = (e: React.ChangeEvent<HTMLSelectElement>) =>
    companies
      ?.flatMap((company) =>
        company.accessibleBenefitPrograms
          .filter((bp) => bp.id === e.target.value)
          .map((benefitProgram) => ({ company, benefitProgram })),
      )
      .find(Boolean);

  const BenefitProgramSelect = () =>
    options && options.flat()?.length > 1 ? (
      <div tw="flex flex-col lg:gap-16 bg-white rounded-2xl p-4 md:p-8 mb-8 mx-4">
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-2 items-center">
          <h3 tw="text-lg text-alps-blue-700 font-semibold">{t('choose_company')}</h3>
          <Select onChange={(e) => navigate(getUrl(getSelectedProgram(e)))} value={selectedProgram?.benefitProgram?.id}>
            {options}
          </Select>
        </div>
      </div>
    ) : null;

  return (
    <div tw="flex flex-col min-h-full bg-sky-blue-50">
      <Navigation />
      <div tw="flex flex-col flex-grow w-full my-12 md:my-24 max-w-screen-lg mx-auto">
        <BenefitProgramSelect />
        <Outlet />
      </div>
      <Footer variant={FooterVariant.DARK} />
    </div>
  );
};
