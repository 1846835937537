var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { cloneElement, useEffect, useState } from 'react';
import { useFloating, useInteractions, useClick, useRole, useDismiss, FloatingPortal, FloatingOverlay, FloatingFocusManager, useMergeRefs, useTransitionStyles, useFloatingNodeId, useFloatingParentNodeId, FloatingTree, FloatingNode, } from '@floating-ui/react';
import { twMerge } from 'tailwind-merge';
// generate random id
var generateId = function () { return Math.random().toString(36).substr(2, 9); };
/**
 * By default you have to assign a child to the Dialog component.
 * This child will be used as the reference element for the dialog.
 * Whenever the reference element is pressed, the dialog will open.
 * The dialog will close when the user presses the escape key or clicks outside of the dialog (if not configured otherwise)
 */
var Dialog = function (props) {
    var parentId = useFloatingParentNodeId();
    // This is a root, so we wrap it with the tree
    if (parentId === null) {
        return (React.createElement(FloatingTree, null,
            React.createElement(DialogComponent, __assign({}, props))));
    }
    return React.createElement(DialogComponent, __assign({}, props));
};
var DialogComponent = function (props) {
    var render = props.render, _a = props.open, passedOpen = _a === void 0 ? false : _a, _b = props.isDismissable, isDismissable = _b === void 0 ? true : _b, onOpenChange = props.onOpenChange, children = props.children, fullScreen = props.fullScreen;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var nodeId = useFloatingNodeId();
    useEffect(function () {
        setOpen(passedOpen);
    }, [passedOpen]);
    var _d = useFloating({
        nodeId: nodeId,
        open: open,
        onOpenChange: function (value) {
            // notify parent of state change
            if (typeof onOpenChange !== 'undefined')
                onOpenChange(value);
            setOpen(value);
        },
    }), refs = _d.refs, context = _d.context;
    // For accessibility reasons
    var id = generateId();
    var labelId = "".concat(id, "-label");
    var descriptionId = "".concat(id, "-description");
    var _e = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context, { enabled: isDismissable }),
    ]), getReferenceProps = _e.getReferenceProps, getFloatingProps = _e.getFloatingProps;
    var _f = useTransitionStyles(context, {
        initial: {
            opacity: 0,
            transform: 'scale(0.95)',
        },
    }), isMounted = _f.isMounted, styles = _f.styles;
    // Preserve the consumer's ref
    var ref = useMergeRefs([refs.setReference, children.ref]);
    return (React.createElement(React.Fragment, null,
        children != null && cloneElement(children, getReferenceProps(__assign({ ref: ref }, children.props))),
        isMounted && (React.createElement(FloatingNode, { id: nodeId },
            React.createElement(FloatingPortal, null,
                React.createElement(FloatingOverlay, { lockScroll: true, className: "z-0 grid bg-transparent select-none place-items-center backdrop-filter backdrop-blur-sm", style: __assign({}, styles) },
                    React.createElement(FloatingFocusManager, { context: context },
                        React.createElement("div", __assign({ ref: refs.setFloating, className: twMerge("bg-white outline-none rounded-2xl mx-4 text-lg shadow-lg select-text", fullScreen && "w-full h-full"), "aria-labelledby": labelId, "aria-describedby": descriptionId }, getFloatingProps()), render({
                            close: function () {
                                setOpen(false);
                                // notify parent of state change
                                if (typeof onOpenChange !== 'undefined')
                                    onOpenChange(false);
                            },
                            labelId: labelId,
                            descriptionId: descriptionId,
                        })))))))));
};
export default Dialog;
