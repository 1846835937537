import {
  BeneficiaryType,
  BooleanRadio,
  Button,
  ButtonSize,
  ButtonVariant,
  DateDropdown,
  differenceInYears,
  Field,
  InfoTooltip,
  Input,
  Select as UISelect,
  useTranslation,
} from '@grunfin/ui-kit';
import React, { Fragment, useState } from 'react';
import { Controller, useFieldArray, useForm, UseFormSetError, useWatch } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import type { PortfolioChild } from '~/modules/portfolio/types';
import { Company } from '~/modules/portfolio/types';
import { countries, Country, euCountries, isPersonalCodeCountry, isTaxIdCountry } from '~/utils/countries';
import Isikukood from '~/utils/isikukood';
import { useSession } from '~/modules/auth/SessionProvider';
import type { Customer } from './types';
import { getInitialTaxResidency } from './utils';

type Values = Customer & { child?: PortfolioChild } & { company?: Company };

interface Props {
  defaultValues: Values;
  type?: BeneficiaryType;
  onSubmit: (v: Values, setError: UseFormSetError<Values>) => void;
  renderFooter?: () => React.ReactNode;
}

export function PersonalDetailsForm({ defaultValues: propDefaultValues, type, onSubmit, renderFooter }: Props) {
  const { t, i18n } = useTranslation('account');
  const c = useTranslation('countries');
  const session = useSession();
  // we don't want this value to change between rerenders
  const [initialTaxResidency] = useState(getInitialTaxResidency(i18n.language));
  const defaultValues = {
    ...propDefaultValues,
    dateOfBirth: propDefaultValues?.dateOfBirth ?? '1990-01-01',
    ...(!propDefaultValues?.taxResidencies?.length && {
      taxResidencies: [
        {
          taxId: '',
          taxResidencyCountryCode: initialTaxResidency,
        },
      ],
    }),
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    watch,
  } = useForm<Values>({
    defaultValues,
  });
  const { fields: taxResidencyFields, append: addNewTaxResidency } = useFieldArray({
    control,
    name: 'taxResidencies',
  });
  const taxResidencyValues = useWatch({
    control,
    name: 'taxResidencies',
  });
  const phoneNumbers = [...countries].sort((a, b) => (a.dialCode > b.dialCode ? 1 : -1));
  const childErrors = errors.child || {};
  const citizenship = watch('citizenship');
  const politicallyExposedPerson = watch('politicallyExposedPerson');
  const showPersonalCode = isPersonalCodeCountry(citizenship || '');
  const verified = session?.verification === 'COMPLETE';
  const required = t('required', { ns: 'general' }) as string;

  const sortCountries = (list: Country[]) =>
    list.sort((a, b) =>
      c
        .t('country.' + a.code)
        .toString()
        .localeCompare(c.t('country.' + b.code).toString()),
    );

  const taxResidencies = taxResidencyFields.map((field, index) => {
    return {
      ...field,
      ...taxResidencyValues[index],
    };
  });

  const lastTaxResidency = taxResidencies[taxResidencies?.length - 1];
  const isLastTaxResidencyFilled =
    Array.isArray(taxResidencies) && lastTaxResidency?.taxResidencyCountryCode?.length > 0;
  const isLastTaxIdRequired =
    Array.isArray(taxResidencies) && isTaxIdCountry(lastTaxResidency?.taxResidencyCountryCode);
  const isLastTaxIdFilled = isLastTaxIdRequired && lastTaxResidency.taxId?.length > 0;

  return (
    <form
      onSubmit={handleSubmit((obj) => {
        // also make sure we don't send empty taxResidency objects
        if (Array.isArray(obj.taxResidencies) && obj.taxResidencies.length > 0) {
          obj.taxResidencies = obj.taxResidencies.filter(
            (item) =>
              !(
                (item.taxId == null || item.taxId?.length === 0) &&
                (item.taxResidencyCountryCode == null || item.taxResidencyCountryCode?.length === 0)
              ),
          );
        }

        onSubmit(obj as Values, setError);
      })}
    >
      <Field
        label={t('details.given_name.label')}
        help={t('details.given_name.help')}
        error={errors.givenName?.message}
      >
        <Input
          {...register('givenName', { required })}
          error={'givenName' in errors}
          autoComplete="off"
          disabled={verified}
        />
      </Field>
      <Field label={t('details.last_name.label')} help={t('details.last_name.help')} error={errors.lastName?.message}>
        <Input
          {...register('lastName', { required })}
          error={'lastName' in errors}
          autoComplete="off"
          disabled={verified}
        />
      </Field>
      <Field label={t('details.dateOfBirth.label')} error={errors.dateOfBirth?.message}>
        <Controller
          control={control}
          rules={{
            required,
            validate: {
              mustBeAdult: (v) =>
                v
                  ? differenceInYears(new Date(), new Date(v)) >= 18 || (t('details.dateOfBirth.invalid') as string)
                  : false,
            },
          }}
          name="dateOfBirth"
          render={({ field }) => (
            <DateDropdown {...field} disabled={verified} error={'dateOfBirth' in errors} birthday />
          )}
        />
      </Field>
      <Field label={t('details.place_of_birth')} error={errors.placeOfBirth?.message}>
        <UISelect
          placeholder={t('choose', { ns: 'general' })}
          autoComplete="country"
          {...register('placeOfBirth', { required })}
          error={'placeOfBirth' in errors}
        >
          {sortCountries(countries).map((country) => (
            <option key={c.t('country.' + country.code).toString()} value={country.code}>
              {c.t('country.' + country.code).toString()}
            </option>
          ))}
        </UISelect>
      </Field>
      <Field
        label={t('details.phone_number.label')}
        help={t('details.phone_number.help')}
        error={errors.phoneNumber?.message}
      >
        <div tw="flex">
          <UISelect
            {...register('phoneCountryCode', { required })}
            error={'phoneCountryCode' in errors}
            autoComplete="tel-country-code"
          >
            {phoneNumbers.map((country) => (
              <option key={c.t('country.' + country.code).toString()} value={country.dialCode}>
                {country.dialCode} {country.flag}
              </option>
            ))}
          </UISelect>
          <div tw="w-full ml-3">
            <Input
              autoComplete="tel-national"
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              {...register('phoneNumber', { required })}
              error={'phoneNumber' in errors}
            />
          </div>
        </div>
      </Field>
      <Field className="mb-2 md:mb-2" label={t('details.citizenship')} error={errors.citizenship?.message}>
        <UISelect
          placeholder={t('choose', { ns: 'general' })}
          autoComplete="country"
          {...register('citizenship', { required })}
          error={'citizenship' in errors}
          disabled={verified}
        >
          {sortCountries(countries).map((country) => (
            <option key={'citizenship.' + c.t('country.' + country.code).toString()} value={country.code}>
              {c.t('country.' + country.code)}
            </option>
          ))}
        </UISelect>
      </Field>
      <Field
        label={t('details.personalCode.label')}
        help={t('details.personalCode.help')}
        error={errors.personalIdentificationCode?.message}
      >
        <Input
          {...register('personalIdentificationCode', {
            required: showPersonalCode,
            validate: {
              mustMatchEstonianRules: (v) => {
                if (citizenship !== 'EE') return true;
                if (!v) return t('details.personalCode.invalid');
                const ik = new Isikukood(v);
                return ik.validate() || t('details.personalCode.invalid');
              },
            },
          })}
          autoComplete="off"
          error={'personalIdentificationCode' in errors}
          disabled={verified}
        />
      </Field>
      <Field
        label={
          <span tw="inline-flex items-center gap-2">
            {t('details.tax_residency.label')}{' '}
            <InfoTooltip>
              <p>{t('details.tax_residency.tooltip')}</p>
            </InfoTooltip>
          </span>
        }
        help={t('details.tax_residency.help')}
        error={errors.taxResidencies?.message}
      >
        {taxResidencies?.map((field, index) => {
          const showTaxId = isTaxIdCountry(field?.taxResidencyCountryCode || '');
          // we want the customer to be able to save the form even though they havent filled in the newly added fields
          // because they must still retain the ability the delete the previously saved entries
          const isRequired = index === 0;
          if (field == null) return null;

          return (
            <Fragment key={index}>
              <UISelect
                placeholder={t('choose', { ns: 'general' })}
                autoComplete="country"
                {...register(`taxResidencies.${index}.taxResidencyCountryCode` as const, { required: isRequired })}
                error={'taxResidencies' in errors}
              >
                {sortCountries(euCountries).map((country) => (
                  <option
                    key={'taxResidencyCountryCode.' + c.t('country.' + country.code).toString()}
                    value={country.code}
                  >
                    {c.t('country.' + country.code)}
                  </option>
                ))}
              </UISelect>
              <div className="my-2" />
              {showTaxId && (
                <Field error={'taxResidencies' in errors}>
                  <Input
                    placeholder={t('details.tax_id')}
                    {...register(`taxResidencies.${index}.taxId` as const, { required })}
                    autoComplete="off"
                    error={'taxId' in errors}
                  />
                </Field>
              )}
              <div className="my-2" />
            </Fragment>
          );
        })}
        <Field>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            className="p-0"
            disabled={!isLastTaxResidencyFilled || (isLastTaxIdRequired && !isLastTaxIdFilled)}
            onClick={() => {
              addNewTaxResidency({
                id: '',
                taxId: '',
                taxResidencyCountryCode: '',
              });
            }}
          >
            <span tw="text-green-500 text-sm">{t('details.tax_residency.add')}</span>
          </Button>
        </Field>
      </Field>
      {/* This offset hack needs a fix */}
      <Field label={t('details.us_tax_resident.label')} error={errors.isUSTaxResident?.message}>
        <Controller
          control={control}
          rules={{
            validate: {
              mustBeFilled: (v) => typeof v === 'boolean' || required,
              mustBeFalse: (v) => !v || (t('details.us_tax_resident.not_supported') as string),
            },
          }}
          name="isUSTaxResident"
          render={({ field }) => (
            <BooleanRadio
              labelTrue={t('yes', { ns: 'general' })}
              labelFalse={t('no', { ns: 'general' })}
              error={'isUSTaxResident' in errors}
              {...field}
            />
          )}
        />
      </Field>
      {type === BeneficiaryType.COMPANY && (
        <Field
          label={<div tw="relative md:-top-2.5">{t('details.e-resident.label')}</div>}
          error={errors.isEResident?.message}
        >
          <Controller
            control={control}
            rules={{
              validate: {
                mustBeFilled: (v) => typeof v === 'boolean' || required,
              },
            }}
            name="isEResident"
            render={({ field }) => (
              <BooleanRadio
                labelTrue={t('yes', { ns: 'general' })}
                labelFalse={t('no', { ns: 'general' })}
                error={'isEResident' in errors}
                {...field}
              />
            )}
          />
        </Field>
      )}
      <Field
        label={
          <span tw="inline-flex items-center gap-2">
            {t('details.political_exposure.label')}
            <InfoTooltip>
              <p>{t('details.political_exposure.tooltip')}</p>
            </InfoTooltip>
          </span>
        }
        error={errors.politicallyExposedPerson?.message}
      >
        <Controller
          control={control}
          rules={{
            validate: {
              mustBeFilled: (v) => typeof v === 'boolean' || required,
            },
          }}
          name="politicallyExposedPerson"
          render={({ field }) => (
            <BooleanRadio
              labelTrue={t('yes', { ns: 'general' })}
              labelFalse={t('no', { ns: 'general' })}
              error={'politicallyExposedPerson' in errors}
              {...field}
            />
          )}
        />
      </Field>
      {!!politicallyExposedPerson && (
        <Field
          label={t('details.pep_comment.label')}
          help={t('details.pep_comment.help')}
          error={errors.pepComment?.message}
        >
          <Input
            as="textarea"
            {...register('pepComment', {
              required: !!politicallyExposedPerson,
            })}
            autoComplete="off"
            error={'pepComment' in errors}
          />
        </Field>
      )}

      <Subtitle>{t('details.address')}</Subtitle>
      <Field label={t('details.street_address')} error={errors.streetLine1?.message}>
        <Input
          {...register('streetLine1', { required })}
          autoComplete="street-address"
          size={40}
          error={'streetLine1' in errors}
        />
      </Field>
      <Field label={t('details.city')} error={errors.city?.message}>
        <Input {...register('city', { required })} error={'city' in errors} autoComplete="address-level2" />
      </Field>
      <Field label={t('details.postal_code')} error={errors.postalCode?.message}>
        <Input
          {...register('postalCode', { required })}
          size={12}
          error={'postalCode' in errors}
          autoComplete="postal-code"
        />
      </Field>
      <Field label={t('details.state')} error={errors.state?.message}>
        <Input {...register('state')} error={'state' in errors} autoComplete="address-level1" />
      </Field>
      <Field label={t('details.country')} error={errors.countryCode?.message}>
        <UISelect {...register('countryCode', { required })} error={'countryCode' in errors}>
          {sortCountries(euCountries).map((country) => (
            <option key={'countryCode.' + c.t('country.' + country.code).toString()} value={country.code}>
              {c.t('country.' + country.code).toString()}
            </option>
          ))}
        </UISelect>
      </Field>

      {type === BeneficiaryType.CHILD && (
        <>
          <Subtitle paragraphIntro={true}>{t('details.child.title')}</Subtitle>
          <InformativeText>{t('details.child.description')}</InformativeText>
          <Field
            label={t('details.child.given_name.label')}
            help={t('details.child.given_name.help')}
            error={childErrors.givenName?.message}
          >
            <Input {...register('child.givenName')} autoComplete="off" error={'givenName' in childErrors} />
          </Field>
          <Field label={t('details.child.last_name')} error={childErrors.lastName?.message}>
            <Input {...register('child.lastName')} autoComplete="off" error={'lastName' in childErrors} />
          </Field>
          <Field label={t('details.child.date_of_birth')} error={childErrors.dateOfBirth?.message}>
            <Controller
              control={control}
              name="child.dateOfBirth"
              render={({ field }) => (
                <div id="child-date">
                  <DateDropdown {...field} spanBack={50} error={'dateOfBirth' in childErrors} />
                </div>
              )}
            />
          </Field>
        </>
      )}

      <div tw="mt-20 flex justify-between">
        {renderFooter ? (
          renderFooter()
        ) : (
          <div tw="ml-auto flex-1 md:flex-none">
            <Button type="submit" variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
              {t('details.save')}
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}

const Subtitle = styled.h2<{ paragraphIntro?: boolean }>`
  ${tw`p-2 mb-10 text-lg mt-14 md:mt-20 md:mb-12 md:p-0 `};
  ${(p) => (p.paragraphIntro ? tw`mb-4 md:mb-5` : tw`mb-9 md:mb-11`)}
`;

const InformativeText = styled.div`
  ${tw`mb-5 text-sm text-gray-400 whitespace-pre-line`}
`;
