import { CloudIcon, FocusArea, HeartIcon, InvestmentIcon, Tooltip, UserIcon, useTranslation } from '@grunfin/ui-kit';
import { useMemo } from 'react';
import 'twin.macro';

const FocusAreaList = ({ focuses, label = true }: { focuses: FocusArea[]; label?: boolean }) => {
  const { t } = useTranslation('portfolio');

  const focusLabels: Record<FocusArea, string> = useMemo(
    () => ({
      [FocusArea.CLIMATE_CHANGE]: t('climate_change.label'),
      [FocusArea.GENDER_EQUALITY]: t('gender_equality.label'),
      [FocusArea.HEALTH]: t('health.label'),
      [FocusArea.SP500]: t('sp500.label'),
    }),
    [t],
  );

  return (
    <div tw="flex flex-row flex-wrap gap-4" onClick={(e) => e.preventDefault()}>
      {focuses?.map((focus) => (
        <div key={focus} tw="flex flex-row gap-2 items-center">
          <Tooltip
            showOnHover={!label}
            showOnClick={!label}
            content={<span tw="mx-2">{focusLabels[focus] ?? focus}</span>}
          >
            <div tw="flex flex-row">
              <FocusIcon focus={focus} />
              {label && <span tw="ml-2 text-lg text-gray-900">{focusLabels[focus] ?? focus}</span>}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const FocusIcon = ({ focus }: { focus: FocusArea }) => {
  switch (focus) {
    case FocusArea.CLIMATE_CHANGE:
      return (
        <span tw="bg-alps-blue-50 rounded-md">
          <CloudIcon tw="text-sky-blue-400" />
        </span>
      );
    case FocusArea.GENDER_EQUALITY:
      return (
        <span tw="bg-dawn-50 rounded-md">
          <UserIcon tw="text-dawn-400" />
        </span>
      );
    case FocusArea.HEALTH:
      return (
        <span tw="bg-dusk-50 rounded-md">
          <HeartIcon tw="text-dusk-400" />
        </span>
      );
    case FocusArea.SP500:
      return (
        <span tw="bg-forest-green-50 rounded-md">
          <InvestmentIcon tw="text-forest-green-400" />
        </span>
      );
    default:
      return null;
  }
};

export default FocusAreaList;
