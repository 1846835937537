var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgWeltderwunderLogo = function (props, ref) { return (React.createElement("svg", __assign({ width: 100, height: 100, viewBox: "0 0 100 100", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M50.0129 0C22.3998 0 0 22.3998 0 50.0128C0 77.6259 22.3998 100 50.0129 100C77.6259 100 100 77.6259 100 50.0128C100 22.3998 77.6259 0 50.0129 0ZM50.0129 96.3612C24.413 96.3612 3.66453 75.6132 3.66453 50.0128C3.66453 24.413 24.413 3.66454 50.0129 3.66454C75.6132 3.66454 96.3612 24.413 96.3612 50.0128C96.3612 75.6132 75.6132 96.3612 50.0129 96.3612ZM50.0129 7.20011C41.4193 7.20011 33.4191 9.72911 26.7097 14.0904L38.0903 24.2321L50.0129 13.5997L61.9355 24.2321L73.3165 14.0904C66.6065 9.72911 58.6064 7.20011 50.0129 7.20011ZM84.826 25.1097L61.9355 45.5226L50.0129 34.8645L38.0903 45.5226L15.1997 25.1097C10.1674 32.129 7.2258 40.7225 7.2258 50.0128H92.7999C92.7999 40.7225 89.8583 32.129 84.826 25.1097ZM73.858 55.9484L61.9355 66.5809L50.0129 55.9484L38.0903 66.5809L26.142 55.9484L14.2194 66.6065L38.0903 87.8708L50.0129 77.2389L61.9355 87.8708L85.8063 66.6065L73.858 55.9484Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgWeltderwunderLogo);
var Memo = memo(ForwardRef);
export default Memo;
