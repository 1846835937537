import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '~/api';
import { removeQuestionnaireId } from '~/modules/onboarding/utils';
import { trackLogout } from '~/utils/tracking-analytics';

import { Loader } from './Loader';
import { useSession } from './SessionProvider';
import { StorageKey } from './types';

export const LogoutView = () => {
  const navigate = useNavigate();
  const session = useSession();

  useEffect(() => {
    removeQuestionnaireId('mifid', 'portfolio', 'employee-portfolio');
    if (!session.authenticated) {
      return navigate('/login');
    }
    trackLogout();
    api.get('triggerLogout').then((v) => {
      localStorage.removeItem(StorageKey.TOKEN);
      v.text().then((value) => (window.location.href = value));
    });
  }, [navigate, session]);

  return <Loader />;
};
