import React from 'react';
import { InsiderIcon, PaymentBankingIcon, ReutersIcon, SiliconcanalsIcon, YahooIcon } from '../icons';
var reviews = [
    {
        name: 'Reuters',
        icon: React.createElement(ReutersIcon, null),
        href: 'https://www.reuters.com/business/credit-suisse-investor-rebellion-over-climate-action-grows-ahead-annual-meeting-2022-04-25/',
    },
    {
        name: 'Business Insider',
        icon: React.createElement(InsiderIcon, null),
        href: 'https://www.businessinsider.com/grunfin-fintech-founded-by-ex-wise-staffers-raises-23-million-2021-10',
    },
    {
        name: 'Payment & Banking',
        icon: React.createElement(PaymentBankingIcon, null),
        href: 'https://paymentandbanking.com/gruenfin-neues-fintech-fuer-nachhaltige-investments/',
    },
    {
        name: 'Silicon Canals',
        icon: React.createElement(SiliconcanalsIcon, null),
        href: 'https://siliconcanals.com/crowdfunding/grunfi-raises-2m/',
    },
    {
        name: 'Yahoo Finance',
        icon: React.createElement(YahooIcon, null),
        href: 'https://finance.yahoo.com/news/credit-suisse-investor-rebellion-over-131805516.html',
    },
];
export var Reviews = function () { return (React.createElement("div", { className: "flex items-center flex-wrap gap-2 justify-around w-full" }, reviews.map(function (review) {
    return (React.createElement("a", { key: review.name, className: "px-8 py-4", target: "_blank", href: review.href, "aria-label": review.name, rel: "noopener" }, review.icon));
}))); };
