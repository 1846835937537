import 'twin.macro';
import { Button, ButtonSize, ButtonVariant, CheckIcon, useTranslation } from '@grunfin/ui-kit';

export const ContributeSuccess = () => {
  const { t } = useTranslation('gift');

  return (
    <div tw="w-full py-12">
      <div tw="flex flex-col px-6">
        <div tw="flex flex-col items-center">
          <div tw="bg-forest-green-50 rounded-full p-1 mb-8">
            <CheckIcon tw="w-20 h-20 text-forest-green-700" />
          </div>
          <h3 tw="text-xl text-alps-blue-700 font-semibold mb-8">{t('get_gifts.success.title')}</h3>
          <h4 tw="text-center text-lg text-alps-blue-600 mb-8 md:mb-20">{t('get_gifts.success.subtitle')}</h4>
          <a href="https://www.grunfin.com?utm_medium=app&utm_source=getgifts&utm_campaign=checkgrunfin">
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
              {t('get_gifts.success.continue')}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
