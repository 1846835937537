import { captureException } from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { api } from '~/api';
import { isNotFound } from '~/utils/api';

import type {
  ClaimQuestionnairePayload,
  CreateQuestionnairePayload,
  MifidQuestionnaire,
  PortfolioQuestionnaire,
  Questionnaire,
  QuestionnaireProps,
  QuestionnaireType,
  SubmitEmailCollectionPayload,
  SubmitQuestionnairePayload,
} from './types';
import { removeQuestionnaireId, setQuestionnaireId } from './utils';

export const useGetMifidQuestionnaire = (id?: string) => useGetQuestionnaire<MifidQuestionnaire>('mifid', id);

export const useGetPortfolioQuestionnaire = (id?: string) =>
  useGetQuestionnaire<PortfolioQuestionnaire>('portfolio', id);

export const useGetQuestionnaireWithQuestionnaireType = (type: QuestionnaireType, id?: string) =>
  useGetQuestionnaire<PortfolioQuestionnaire>(type, id);

const useGetQuestionnaire = <T extends Questionnaire>(type: QuestionnaireType, id?: string) =>
  useQuery<T, Error>([type, 'questionnaire', id], async () => api.get(`${type}/questionnaire/${id}`).json<T>(), {
    onError: (error) => isNotFound(error) && removeQuestionnaireId(type),
    enabled: !!id,
  });

export const useGetLatestQuestionnaire = (props: QuestionnaireProps) => {
  const isEmployee = props.type.includes('employee');
  let basePath = `${props.type}/questionnaire/latest?employee=${isEmployee}`;
  if (isEmployee) {
    basePath += `&membershipId=${props.membershipId}`;
  }
  return useQuery<Questionnaire, Error>(
    [props.type, 'latest-questionnaire'],
    async () =>
      api
        .get(basePath, {
          timeout: 180000,
        })
        .json<Questionnaire>(),
    {
      onSuccess: (data: Questionnaire) => setQuestionnaireId(props.type, data.id),
      onError(error) {
        captureException(error);
      },
    },
  );
};

export const useCreateQuestionnaire = <T extends Questionnaire>() =>
  useMutation<T, Error, CreateQuestionnairePayload>(async ({ type }) => api.post(`${type}/questionnaire`).json<T>(), {
    onSuccess: (data, { type }) => setQuestionnaireId(type, data.id),
    onError(error) {
      captureException(error);
    },
  });

export const useSubmitQuestionnaire = () => {
  const queryClient = useQueryClient();

  return useMutation<Questionnaire, Error, SubmitQuestionnairePayload>(
    async ({ type, id, body }: SubmitQuestionnairePayload) =>
      api.post(`${type}/questionnaire/${id}/answer`, { json: body, timeout: 26000 }).json<Questionnaire>(),
    {
      onSuccess: (data, { type, id }) => {
        queryClient.setQueryData([type, 'questionnaire', id], (prev: Questionnaire | undefined) => ({
          ...prev,
          ...data,
        }));
      },
      onError(error) {
        captureException(error);
      },
    },
  );
};

export const useClaimQuestionnaire = () => {
  const queryClient = useQueryClient();

  return useMutation<Questionnaire, Error, ClaimQuestionnairePayload>(
    async ({ id, type }) => api.post(`${type}/questionnaire/${id}/claim`).json<Questionnaire>(),
    {
      onError: (error, { type }) => {
        isNotFound(error) && removeQuestionnaireId(type);
      },
      onSuccess: async (data, { id, type }) => {
        await queryClient.invalidateQueries(['portfolio', id, 'status']);
        await queryClient.invalidateQueries('session');
        removeQuestionnaireId(type);
      },
    },
  );
};

export const useSubmitEmailCollection = () =>
  useMutation(async (body: SubmitEmailCollectionPayload) => api.post(`email-collection/create-user`, { json: body }), {
    onError(error) {
      captureException(error);
    },
    onSuccess: async (response) =>
      // Redirect to employee onboarding if email capture sent a 202 and a redirect url
      response.status === 202 &&
      response.json().then((body: any) => {
        if (body?.redirectUrl) {
          window.location.href = body.redirectUrl;
        }
      }),
  });
