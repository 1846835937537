var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGermanyIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("g", { clipPath: "url(#clip0_102_16)" },
        React.createElement("mask", { id: "mask0_102_16", style: {
                maskType: 'alpha',
            }, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 24, height: 24 },
            React.createElement("path", { d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0_102_16)" },
            React.createElement("path", { d: "M0 16.1719L12.0328 14.9766L24 16.1719V24H0V16.1719Z", fill: "#FFDA44" }),
            React.createElement("path", { d: "M0 7.82812L11.9531 6.75L24 7.82812V16.1719H0V7.82812Z", fill: "#D80027" }),
            React.createElement("path", { d: "M0 0H24V7.82812H0V0Z", fill: "#333333" }))),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_102_16" },
            React.createElement("rect", { width: 24, height: 24, fill: "white" }))))); };
var ForwardRef = forwardRef(SvgGermanyIcon);
var Memo = memo(ForwardRef);
export default Memo;
