import { twMerge } from 'tailwind-merge';
import React from 'react';
var isAnchorTag = function (node) {
    return typeof node === 'object' && node !== null && node.type === 'a';
};
export var Field = function (_a) {
    var htmlFor = _a.htmlFor, label = _a.label, className = _a.className, help = _a.help, children = _a.children, error = _a.error;
    var wrapper = twMerge('grid items-start md:grid-cols-7 gap-x-6 gap-y-2 w-full mb-8 md:mb-6', className);
    return (React.createElement("div", { className: wrapper },
        (label || help) && (React.createElement("div", { className: "md:col-span-3 font-medium" },
            label && (React.createElement("label", { htmlFor: htmlFor, className: "text-base font-medium text-gray-900" }, label)),
            help && React.createElement("div", { className: "md:mt-1 text-sm text-gray-400" }, help))),
        React.createElement("div", { className: label != null || help != null ? 'md:col-span-4' : 'md:col-span-7' },
            children,
            error && typeof error !== 'boolean' && (React.createElement("div", { className: "text-sm text-dawn-300 mt-2 ".concat(isAnchorTag(error) && 'underline') }, error)))));
};
