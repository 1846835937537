var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgCalendarIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 10C7 8.89543 7.89543 8 9 8H23C24.1046 8 25 8.89543 25 10V22C25 23.1046 24.1046 24 23 24H9C7.89543 24 7 23.1046 7 22V10ZM9 13C9 12.4477 9.44772 12 10 12H22C22.5523 12 23 12.4477 23 13V21C23 21.5523 22.5523 22 22 22H10C9.44772 22 9 21.5523 9 21V13ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15ZM13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19ZM16 20C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19C15 19.5523 15.4477 20 16 20ZM17 15C17 15.5523 16.5523 16 16 16C15.4477 16 15 15.5523 15 15C15 14.4477 15.4477 14 16 14C16.5523 14 17 14.4477 17 15ZM20 16C20.5523 16 21 15.5523 21 15C21 14.4477 20.5523 14 20 14C19.4477 14 19 14.4477 19 15C19 15.5523 19.4477 16 20 16ZM21 19C21 19.5523 20.5523 20 20 20C19.4477 20 19 19.5523 19 19C19 18.4477 19.4477 18 20 18C20.5523 18 21 18.4477 21 19Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgCalendarIcon);
var Memo = memo(ForwardRef);
export default Memo;
