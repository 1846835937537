var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGiftIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m17 7c0 .55228-.4477 1-1 1s-1-.44772-1-1 .4477-1 1-1 1 .44772 1 1zm-6 2c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1zm5 0c-.5523 0-1 .44772-1 1v2h-1c-.5523 0-1 .4477-1 1h-1v-2c0-.5523-.4477-1-1-1s-1 .4477-1 1v2h-1c-.55228 0-1 .4477-1 1v8c0 1.6569 1.34315 3 3 3h10c1.6569 0 3-1.3431 3-3v-8c0-.5523-.4477-1-1-1h-1v-2c0-.5523-.4477-1-1-1s-1 .4477-1 1v2h-1c0-.5523-.4477-1-1-1h-1v-2c0-.55228-.4477-1-1-1zm5 0c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgGiftIcon);
var Memo = memo(ForwardRef);
export default Memo;
