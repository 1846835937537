var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgMinusCircleIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26ZM10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17C17.6852 17 14.9307 17 22 17C22.5523 17 23 16.5523 23 16C23 15.4477 22.5523 15 22 15C14.9307 15 17.6852 15 10 15Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgMinusCircleIcon);
var Memo = memo(ForwardRef);
export default Memo;
