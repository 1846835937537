var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgPaymentbankingIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 106, height: 32, viewBox: "0 0 106 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M13.4433 22.5104H19.99C22.0993 22.5104 23.8156 20.7632 23.8156 18.616C23.8156 17.0176 22.8486 15.5904 21.398 15.0069C20.4604 16.1856 19.15 16.9285 17.719 17.0283C16.5441 17.0459 15.1217 17.0555 13.4524 17.0565L13.4433 22.5104Z", fill: "currentColor" }),
    React.createElement("path", { d: "M21.3937 11.176C21.2225 8.9968 19.3788 7.28267 17.1462 7.28267L11.0156 7.2832V24.7179L20.2401 24.704C23.6716 24.704 26.4641 21.9344 26.4641 18.5317C26.4641 15.1291 23.6716 12.3605 20.2225 12.3605L20.0481 12.3531H19.702C19.534 12.3376 19.366 12.2645 19.2534 12.1365C19.1308 11.9973 19.0758 11.8117 19.0988 11.6597C19.1025 11.6091 19.1041 11.5637 19.1041 11.52C19.1041 10.6251 18.2406 9.65973 17.4892 9.6448H17.4065L13.3649 9.632V13.4037L20.0049 13.4235C20.4246 13.4235 20.846 13.4779 21.2726 13.5845C21.342 13.5936 21.3942 13.6059 21.4486 13.6272C23.6614 14.2768 25.1809 16.2688 25.1809 18.5419C25.1809 21.3701 22.8614 23.6709 20.0097 23.6709H12.7121C12.5426 23.6714 12.3799 23.6045 12.2597 23.485C12.1395 23.3655 12.0716 23.2031 12.071 23.0336L12.0833 16.3605C12.0833 16.0101 12.3697 15.7269 12.7233 15.7259L17.2876 15.7227C18.254 15.6918 19.1813 15.334 19.918 14.7077C19.5121 14.7045 18.8492 14.7003 17.9425 14.6955C16.3123 14.6875 14.6821 14.6802 13.0518 14.6736L12.7505 14.6725C12.3686 14.6715 12.0828 14.3872 12.0828 14.0368V8.99413C12.083 8.91023 12.0998 8.8272 12.1323 8.74984C12.1648 8.67249 12.2123 8.60234 12.2721 8.54347C12.3935 8.42442 12.557 8.35811 12.727 8.35893H12.8044L15.1478 8.36693L15.3766 8.368C16.3798 8.3712 17.0556 8.37387 17.3702 8.376H17.5004L17.5718 8.37707C18.9734 8.39893 20.1558 9.59733 20.3558 11.0907C20.7034 11.0952 21.0501 11.1237 21.3937 11.176Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.643311 16C0.643311 24.8368 7.80651 32 16.6433 32C25.4801 32 32.6433 24.8368 32.6433 16C32.6433 7.1632 25.4801 0 16.6433 0C7.80651 0 0.643311 7.1632 0.643311 16ZM22.7692 11.4693C25.7548 12.5051 27.7884 15.3472 27.7884 18.5451C27.7884 22.6619 24.4609 26.0144 20.3969 26.0256C20.3132 26.0336 17.3302 26.0379 10.5484 26.0411C10.3793 26.0411 10.2182 25.9739 10.0988 25.8544C9.97931 25.7344 9.91211 25.5717 9.91211 25.4027V6.59787C9.91211 6.24533 10.1969 5.95893 10.5484 5.95893L17.2289 5.9584C20.2886 5.9584 22.7612 8.4208 22.7692 11.4693Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M46.8767 7.66723C46.8767 6.08003 45.6223 4.80003 44.0223 4.80003H40.0159V14.4H42.0127V10.4704H44.0223C45.6223 10.4704 46.8767 9.26723 46.8767 7.66723ZM44.9567 7.69283C44.9567 8.37123 44.4191 8.80643 43.7151 8.80643H42.0127V6.56643H43.7151C44.4191 6.56643 44.9567 7.02723 44.9567 7.69283Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M54.4543 8.00003H52.5343V8.65283C52.0991 8.16643 51.4591 7.87203 50.6143 7.87203C48.8863 7.87203 47.4783 9.34403 47.4783 11.2C47.4783 13.056 48.8863 14.528 50.6143 14.528C51.4591 14.528 52.0991 14.2336 52.5343 13.7472V14.4H54.4543V8.00003ZM52.6239 11.2C52.6239 12.096 51.9071 12.8 51.0111 12.8C50.1151 12.8 49.4111 12.096 49.4111 11.2C49.4111 10.304 50.1151 9.58723 51.0111 9.58723C51.2231 9.58631 51.4333 9.62741 51.6293 9.70813C51.8254 9.78886 52.0036 9.90762 52.1535 10.0576C52.3035 10.2075 52.4222 10.3857 52.503 10.5818C52.5837 10.7778 52.6248 10.988 52.6239 11.2Z", fill: "currentColor" }),
    React.createElement("path", { d: "M62.3903 8.00003H60.2911L58.9087 11.904L57.4879 8.00003H55.3759L57.9743 14.144L56.5663 17.6H58.5503L59.7535 14.5536L62.3903 8.00003Z", fill: "currentColor" }),
    React.createElement("path", { d: "M72.9887 10.3424C72.9887 8.94723 71.9903 7.87203 70.6079 7.87203C69.7759 7.87203 69.1103 8.26883 68.6879 8.90883C68.2783 8.26883 67.5871 7.87203 66.8319 7.87203C66.1791 7.87203 65.6287 8.17923 65.2447 8.66563V8.00003H63.3119V14.4H65.2447V10.6496C65.2447 10.0608 65.5647 9.58723 66.2047 9.58723C66.7935 9.58723 67.1903 9.98403 67.1903 10.5472V14.4H69.1103V10.6496C69.1103 10.0608 69.4303 9.58723 70.0831 9.58723C70.6591 9.58723 71.0559 9.98403 71.0559 10.5472V14.4H72.9887V10.3424Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M81.0015 11.5712L81.0271 11.136C81.0143 9.13923 79.6831 7.85923 77.7887 7.85923C75.6767 7.85923 74.3327 9.34403 74.3327 11.2C74.3327 13.056 75.5103 14.528 77.8655 14.528C79.1839 14.528 80.3231 13.8496 80.8095 12.7744L79.2479 12.1344C78.9663 12.6976 78.4287 12.9792 77.8015 12.9792C77.0207 12.9792 76.4063 12.4544 76.2911 11.5712H81.0015ZM76.4063 10.3168C76.6239 9.74083 77.0463 9.36963 77.7375 9.36963C78.4159 9.36963 78.8255 9.70243 79.0303 10.3168H76.4063Z", fill: "currentColor" }),
    React.createElement("path", { d: "M88.5407 10.304C88.5407 8.92163 87.5295 7.87203 86.2495 7.87203C85.5711 7.87203 84.9183 8.12803 84.4575 8.65283V8.00003H82.5247V14.4H84.4575V10.5728C84.4703 10.0096 84.9439 9.57443 85.5967 9.57443C86.1855 9.57443 86.6079 9.95843 86.6079 10.5344V14.4H88.5407V10.304Z", fill: "currentColor" }),
    React.createElement("path", { d: "M93.9295 9.49763V8.00003H92.7263V5.69603H90.7935V8.00003H89.5903V9.49763H90.7935V14.4H92.7263V9.49763H93.9295Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M104.643 14.5792L105.757 13.4528L104.592 12.288L105.68 11.2L104.566 10.0992L103.478 11.1872L101.993 9.70243C102.096 9.66404 102.198 9.62562 102.301 9.57443C103.145 9.17763 103.709 8.29443 103.709 7.29603C103.709 5.86243 102.531 4.69763 101.072 4.69763C99.6255 4.69763 98.4479 5.86243 98.4479 7.29603C98.4479 7.97443 98.7295 8.67843 99.2159 9.16483L99.3311 9.28003L99.0495 9.56163C98.4863 10.112 98.1919 10.8672 98.1919 11.6224C98.1946 12.3956 98.5029 13.1364 99.0495 13.6832C99.5999 14.2322 100.346 14.5406 101.123 14.5408C101.506 14.5412 101.886 14.4656 102.239 14.3184C102.593 14.1712 102.914 13.9553 103.184 13.6832L103.465 13.4016L104.643 14.5792ZM100.022 7.29603C100.035 6.72003 100.509 6.27203 101.085 6.27203C101.648 6.27203 102.121 6.73283 102.121 7.29603C102.118 7.57002 102.008 7.83189 101.814 8.02564C101.621 8.21939 101.359 8.32965 101.085 8.33283C100.807 8.33498 100.539 8.22725 100.34 8.03307C100.141 7.8389 100.027 7.57401 100.022 7.29603ZM102.352 12.3008L102.083 12.5696C101.814 12.8256 101.469 12.9664 101.136 12.9664C100.79 12.9664 100.445 12.8256 100.176 12.5696C99.9199 12.3136 99.7791 11.9808 99.7791 11.6352C99.7791 11.3024 99.9199 10.9568 100.176 10.688L100.457 10.4064L102.352 12.3008Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M47.2095 24.5888C47.2095 23.3856 46.5823 22.6432 45.6223 22.2976C46.3263 21.9136 46.7487 21.248 46.7487 20.2624C46.7487 18.5856 45.4943 17.6 43.8943 17.6H40.0159V27.2H44.3807C45.9551 27.2 47.2095 26.2528 47.2095 24.5888ZM43.6511 19.3792C44.2655 19.3792 44.7647 19.8528 44.7647 20.4672C44.7647 21.0688 44.2655 21.568 43.6511 21.568H41.9999V19.3792H43.6511ZM45.2127 24.3328C45.2127 24.9344 44.7135 25.4208 44.0863 25.4208H41.9999V23.232H44.0863C44.7135 23.232 45.2127 23.7312 45.2127 24.3328Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M55.1967 20.8H53.2767V21.4528C52.8415 20.9664 52.2015 20.672 51.3567 20.672C49.6287 20.672 48.2207 22.144 48.2207 24C48.2207 25.856 49.6287 27.328 51.3567 27.328C52.2015 27.328 52.8415 27.0336 53.2767 26.5472V27.2H55.1967V20.8ZM53.3663 24C53.3663 24.896 52.6495 25.6 51.7535 25.6C50.8575 25.6 50.1535 24.896 50.1535 24C50.1535 23.104 50.8575 22.3872 51.7535 22.3872C51.9655 22.3863 52.1757 22.4274 52.3717 22.5081C52.5678 22.5889 52.746 22.7076 52.8959 22.8576C53.0459 23.0075 53.1646 23.1857 53.2454 23.3818C53.3261 23.5778 53.3672 23.788 53.3663 24Z", fill: "currentColor" }),
    React.createElement("path", { d: "M63.1583 23.104C63.1583 21.7216 62.1471 20.672 60.8671 20.672C60.1887 20.672 59.5359 20.928 59.0751 21.4528V20.8H57.1423V27.2H59.0751V23.3728C59.0879 22.8096 59.5615 22.3744 60.2143 22.3744C60.8031 22.3744 61.2255 22.7584 61.2255 23.3344V27.2H63.1583V23.104Z", fill: "currentColor" }),
    React.createElement("path", { d: "M69.1487 27.2H71.6575L68.6111 23.6928L71.4271 20.8H69.0975L66.8831 23.0656V17.6H64.9503V27.2H66.8831V24.3072L69.1487 27.2Z", fill: "currentColor" }),
    React.createElement("path", { d: "M72.3103 18.5472C72.3103 19.1232 72.8095 19.6224 73.3855 19.6224C73.9743 19.6224 74.4735 19.1232 74.4735 18.5472C74.4735 17.9456 73.9743 17.472 73.3855 17.472C72.8095 17.472 72.3103 17.9456 72.3103 18.5472Z", fill: "currentColor" }),
    React.createElement("path", { d: "M82.3071 23.104C82.3071 21.7216 81.2959 20.672 80.0159 20.672C79.3375 20.672 78.6847 20.928 78.2239 21.4528V20.8H76.2911V27.2H78.2239V23.3728C78.2367 22.8096 78.7103 22.3744 79.3631 22.3744C79.9519 22.3744 80.3743 22.7584 80.3743 23.3344V27.2H82.3071V23.104Z", fill: "currentColor" }),
    React.createElement("path", { d: "M72.4255 20.8V27.2H74.3455V20.8H72.4255Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M90.7167 20.8H88.7839V21.4528C88.3103 20.9664 87.6575 20.672 86.8255 20.672C85.0463 20.672 83.6511 22.1312 83.6511 24C83.6511 25.856 85.0463 27.328 86.8255 27.328C87.6575 27.328 88.3103 27.072 88.7839 26.6112V27.2128C88.7839 28.2752 87.9647 28.8512 87.0559 28.8512C86.2879 28.8512 85.5711 28.4672 85.0335 27.8144L83.9071 29.0816C84.7519 29.9904 85.9807 30.4384 87.0815 30.4384C89.1039 30.4384 90.7167 29.0816 90.7167 27.2128V20.8ZM88.7967 24C88.7967 24.896 88.0671 25.6 87.1839 25.6C86.2879 25.6 85.5839 24.896 85.5839 24C85.5839 23.104 86.2879 22.3872 87.1839 22.3872C87.6115 22.3877 88.0215 22.5577 88.3238 22.8601C88.6262 23.1624 88.7962 23.5724 88.7967 24Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgPaymentbankingIcon);
var Memo = memo(ForwardRef);
export default Memo;
