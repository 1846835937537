var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgFacebookIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M0 12C0 17.9333 4.33333 22.8667 10 23.8667L10.0668 23.8132C10.0444 23.8089 10.022 23.8045 9.99961 23.8V15.3334H6.99961V12H9.99961V9.33337C9.99961 6.33336 11.9329 4.6667 14.6663 4.6667C15.5329 4.6667 16.4663 4.80003 17.3329 4.93337V8.00003H15.7996C14.3329 8.00003 13.9996 8.73337 13.9996 9.6667V12H17.1996L16.6663 15.3334H13.9996V23.8C13.9774 23.8045 13.9552 23.8088 13.9331 23.8131L14 23.8667C19.6667 22.8667 24 17.9333 24 12C24 5.4 18.6 0 12 0C5.4 0 0 5.4 0 12Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgFacebookIcon);
var Memo = memo(ForwardRef);
export default Memo;
