import { useCallback, useEffect } from 'react';
import { useCookieContext } from '../utils/';
import mixpanel from 'mixpanel-browser';
var delete_cookie = function (name, path, domain) {
    document.cookie =
        name +
            '=' +
            (path ? ';path=' + path : '') +
            (domain ? ';domain=' + domain : '') +
            ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
};
var getDocumentCookies = function () {
    return document.cookie
        .split(';')
        .reduce(function (ac, str) {
        var _a;
        return Object.assign(ac, (_a = {}, _a[str.split('=')[0].trim()] = str.split('=')[1], _a));
    }, {});
};
export var useCookieScript = function () {
    var _a = useCookieContext(), state = _a.state, dispatch = _a.dispatch;
    var isConsented = state.isConsented, isScriptRendered = state.isScriptRendered;
    var setIsConsented = useCallback(function (val) {
        return dispatch({
            type: 'SET_IS_CONSENTED',
            payload: val,
        });
    }, [dispatch]);
    var setIsScriptRendered = function (val) {
        return dispatch({
            type: 'SET_IS_SCRIPT_RENDERED',
            payload: val,
        });
    };
    useEffect(function () {
        var _a;
        if (!isConsented && isScriptRendered) {
            var cookies = getDocumentCookies();
            var consentCookie = (_a = cookies === null || cookies === void 0 ? void 0 : cookies.CookieScriptConsent) !== null && _a !== void 0 ? _a : '{}';
            var cookie = void 0;
            try {
                cookie = JSON.parse(consentCookie !== null && consentCookie !== void 0 ? consentCookie : '{}');
            }
            catch (e) {
                console.warn('Error parsing JSON, deleting broken CookieScriptConsent cookie: ', consentCookie);
                document.cookie = 'CookieScriptConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
            var userHasConsented = cookie && 'action' in cookie && cookie.action === 'accept';
            // We have user consent, render the scripts
            if (userHasConsented)
                setIsConsented(true);
            // Add a listener for consent change
            else {
                var cookieAcceptNode_1 = document.querySelector('#cookiescript_accept');
                var handleConsented_1 = function () { return setIsConsented(true); };
                if (!cookieAcceptNode_1) {
                    // Cookiescript injects a dom element in about 500ms after the script has loaded
                    // And we aren't able to pass a ref to the underlying component or access with via native API (virtual dom)
                    // We have to attach a click event listener to the document and each time try to find the element we are looking for
                    var handleConsentedTrigger_1 = function (e) {
                        var clickedElement = e.target;
                        // The user accepted the cookies by clicking the accept button
                        if ((clickedElement === null || clickedElement === void 0 ? void 0 : clickedElement.id) === 'cookiescript_accept')
                            handleConsented_1();
                    };
                    document.addEventListener('click', handleConsentedTrigger_1);
                    return function () { return document.removeEventListener('click', handleConsentedTrigger_1); };
                }
                else {
                    cookieAcceptNode_1.addEventListener('click', handleConsented_1);
                    return function () { return cookieAcceptNode_1.removeEventListener('click', handleConsented_1); };
                }
            }
        }
        else {
            var mixpanelSessionStorage = sessionStorage.getItem('mixpanel_init');
            // When mixpanel is initialized before cookies are accepted, it will result in an error
            // Remove this key, and it will be reinitialized when 'mixpanel.init' is called
            if (mixpanelSessionStorage)
                sessionStorage.removeItem('mixpanel_init');
        }
    }, [isScriptRendered, isConsented, setIsConsented]);
    // Attach a listener to declining cookies
    useEffect(function () {
        if (isScriptRendered) {
            var cookieRejectNode_1 = document.querySelector('#cookiescript_reject');
            var handleRejected_1 = function () {
                var _a;
                setIsConsented(false);
                // If mixpanel is initialized, reset it
                var sessionStorageMixpanelInit = sessionStorage.getItem('mixpanel_init');
                if (sessionStorageMixpanelInit) {
                    mixpanel.reset();
                    sessionStorage.removeItem('mixpanel_init');
                }
                // Remove mixpanel cookie
                var cookies = getDocumentCookies();
                (_a = Object.keys(cookies !== null && cookies !== void 0 ? cookies : {})) === null || _a === void 0 ? void 0 : _a.forEach(function (key) {
                    // if the cookie is present, remove it
                    if (key.match(/mp_(.*)_mixpanel/))
                        delete_cookie(key, '/', '.grunfin.com');
                });
            };
            if (!cookieRejectNode_1) {
                // Attach a listener to the document if the node is not found
                var handleRejectedTrigger_1 = function (e) {
                    var clickedElement = e.target;
                    // The user rejected the cookies by clicking the decline button
                    if ((clickedElement === null || clickedElement === void 0 ? void 0 : clickedElement.id) === 'cookiescript_reject')
                        handleRejected_1();
                };
                document.addEventListener('click', handleRejectedTrigger_1);
                return function () { return document.removeEventListener('click', handleRejectedTrigger_1); };
            }
            else {
                cookieRejectNode_1.addEventListener('click', handleRejected_1);
                return function () { return cookieRejectNode_1.removeEventListener('click', handleRejected_1); };
            }
        }
    }, [isScriptRendered, setIsConsented]);
    return {
        isScriptRendered: isScriptRendered,
        setIsScriptRendered: setIsScriptRendered,
        isConsented: isConsented,
    };
};
