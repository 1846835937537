import { BeneficiaryType } from '@grunfin/ui-kit';

export enum PastPerformance {
  NotGuarantee = 'IS_NOT_A_GUARANTEE',
  ShowsFuturePerformance = 'SHOWS_FUTURE_PERFORMANCE',
  NotSure = 'I_AM_NOT_SURE',
}

export interface QuestionnaireProps {
  type: QuestionnaireType;
  membershipId?: string;
}

export type QuestionnaireType = 'mifid' | 'portfolio' | 'employee-portfolio';
export type Questionnaire = MifidQuestionnaire | PortfolioQuestionnaire;

export interface MifidQuestionnaire {
  id: string;
  customerId: string;
  experienceFunds: boolean | null;
  experienceNoInvestmentExperience: boolean | null;
  experienceRetirementFunds: boolean | null;
  experienceShares: boolean | null;
  experienceIDontKnow: boolean | null;
  wouldWithdrawOnValueDrop: boolean | null;
  pastPerformance: PastPerformance | null;
  shouldDisplayHighRiskWarning: boolean;
  savingsForThreeMonths: boolean | null;
  moreDebtThanAssets: boolean | null;
  acceptedWarningMessage: string | null;
}

export enum PreferredRiskReturnRatio {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Very_High = 'VERY_HIGH',
}

export enum InvestmentExperience {
  FUNDS = 'funds',
  SHARES = 'shares',
  NO_EXPERIENCE = 'no_experience',
}

export enum InvestmentTerm {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
}

export interface PortfolioQuestionnaire {
  id: string;
  customerId: string;
  areaClimateChange: boolean | null;
  areaGenderEquality: boolean | null;
  areaHealth: boolean | null;
  areaSP500: boolean | null;
  childAge: number | null;
  periodInYears: number | null;
  portfolioType: BeneficiaryType;
  investmentTerm: InvestmentTerm | null;
  ageOfChildAtTheEndOfPortfolio: number | null;
  endDate: string | null;
  monthlyInvestmentAmountEur: number | null;
  upfrontInvestmentAmountEur: number | null;
  investments: QuestionnaireInvestments;
  preferredRisk: PreferredRiskReturnRatio | null;
  signUp: boolean | null;
}

export interface QuestionnaireInvestments {
  monthlyInvestmentAmountEur: string;
  upfrontInvestmentAmountEur: string;
}

export interface CreateQuestionnairePayload {
  type: QuestionnaireType;
  isEmployee?: boolean;
}

export interface SubmitQuestionnairePayload {
  type: QuestionnaireType;
  id: string;
  body: Partial<Omit<Questionnaire, 'id' | 'customerId'>>;
}

export interface SubmitEmailCollectionPayload {
  email: string;
  portfolioQuestionnaireId: string;
  mifidQuestionnaireId: string;
  referralCode: string | null;
}

export interface ClaimQuestionnairePayload {
  type: QuestionnaireType;
  id: string;
}

export interface Answers {
  mifid: MifidQuestionnaire;
  portfolio: PortfolioQuestionnaire;
}

interface QuestionRenderProps<V> {
  value: V;
  setValue: (value: V) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFields: (value: React.SetStateAction<any>) => void;
  submitValue: (value: V, page?: number) => void;
  answers: Answers;
  disabled?: boolean;
}

interface BaseQuestion<V = string> {
  isFilled: (answers: Answers) => boolean;
  noButton?: boolean;
  render: (props: QuestionRenderProps<V>) => React.ReactElement;
  field?: string;
}

export interface TypedQuestion extends BaseQuestion {
  type: 'mifid' | 'portfolio';
}
interface QuestionMifidField extends TypedQuestion {
  type: 'mifid';
  field:
    | 'pastPerformance'
    | 'wouldWithdrawOnValueDrop'
    | 'savingsForThreeMonths'
    | 'moreDebtThanAssets'
    | 'acceptedWarningMessage';
}

export type QuestionMifid = QuestionMifidField | QuestionMifidExperience;

interface QuestionMifidExperience extends BaseQuestion<string[]> {
  type: 'mifid';
  field: 'experience';
}

interface QuestionPortfolioField extends TypedQuestion {
  type: 'portfolio';
  field: 'childAge' | 'ageOfChildAtTheEndOfPortfolio' | 'preferredRisk' | 'signUp' | 'periodInYears';
}

interface QuestionPortfolioForSelf extends BaseQuestion {
  type: 'portfolio';
  field: 'portfolioType';
}
interface QuestionPortfolioAreas extends BaseQuestion<string[]> {
  type: 'portfolio';
  field: 'areas';
}
interface QuestionPortfolioInvestments extends BaseQuestion<QuestionnaireInvestments> {
  type: 'portfolio';
  field: 'investments';
}

export type Question =
  | BaseQuestion
  | QuestionMifidExperience
  | QuestionMifidField
  | QuestionPortfolioField
  | QuestionPortfolioForSelf
  | QuestionPortfolioAreas
  | QuestionPortfolioInvestments;
