var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgEmailIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25 10.8L16 18L7 10.8L7 22H25V10.8ZM16 15.4388L9.20156 10H22.7984L16 15.4388ZM7 8C5.89543 8 5 8.89543 5 10V22C5 23.1046 5.89543 24 7 24H25C26.1046 24 27 23.1046 27 22V10C27 8.89543 26.1046 8 25 8H7Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgEmailIcon);
var Memo = memo(ForwardRef);
export default Memo;
