import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '~/api';
import type { CompanyRelatedPerson, ContractFile, Customer, CustomerSettings, ReferralProgramInstance } from './types';
import { AumSwitchNotification, CompanySearchList } from './types';

export const useGetCustomer = () =>
  useQuery<Customer, Error>('customer', async () => api.get('customer').json<Customer>());

export const useSubmitCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation(async (json: Customer) => api.post('customer', { json }).json<Customer>(), {
    onSuccess: async (data) => queryClient.setQueryData('customer', data),
  });
};

export const useGetContractFiles = () =>
  useQuery<ContractFile[], Error>('contract-file', async () => api.get('file').json<ContractFile[]>());

export const useGetReferralProgram = () =>
  useQuery<ReferralProgramInstance, Error>('referral', async () => api.get('referral').json<ReferralProgramInstance>());

export const useSearchCompanies = (query: string) =>
  useQuery<CompanySearchList, Error>(
    ['company-search-', query],
    async () => api.get('company/search', { searchParams: { query } }).json<CompanySearchList>(),
    { enabled: !!query },
  );

export const useGetCompanyRelatedPersons = (registrationNumber: string, enabled: boolean) =>
  useQuery<CompanyRelatedPerson[], Error>(
    ['company-persons', registrationNumber],
    async () =>
      api.get('company/ubosAndBoardMembers', { searchParams: { registrationNumber } }).json<CompanyRelatedPerson[]>(),
    { enabled },
  );

export const useSubmitSettings = () =>
  useMutation(async (data: CustomerSettings) => api.post('customer/settings', { json: data }).json<CustomerSettings>());

export const useDownloadFile = (fileId: string) =>
  useQuery('document-contract-file', async () => api.get(`file/${fileId}/download`).blob(), { enabled: false });

export const useGetGeneralTermsHtml = (locale: string) =>
  useQuery('generalTermsHtml', async () => api.get('generalTerms/html', { searchParams: { locale } }).text());

export const useGetAumSwitchStatus = () =>
  useQuery<AumSwitchNotification, Error>('aumSwitchNotificationStatus', async () =>
    api.get('pricing/aumSwitchNotificationStatus').json<AumSwitchNotification>(),
  );

export const useMarkAumSwitchNotificationShown = () =>
  useMutation(async () => api.post('pricing/aumSwitchNotificationShown', {}));
export const useAumSwitchInitiate = () => useMutation(async () => api.post('pricing/aumSwitchInitiate', {}));
