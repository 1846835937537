var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgFractoryLogo = function (props, ref) { return (React.createElement("svg", __assign({ width: 158, height: 32, viewBox: "0 0 158 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M31.3866 31.8834H0.40034C0.160136 31.8834 0 31.7232 0 31.483V0.416697C0 0.176493 0.160136 0.0163574 0.40034 0.0163574H31.3866C31.6268 0.0163574 31.787 0.176493 31.787 0.416697V31.403C31.787 31.6432 31.6268 31.8834 31.3866 31.8834Z", fill: "#F6423A" }),
    React.createElement("path", { d: "M3.28284 16.7504V2.65849C3.28284 2.41828 3.52304 2.33822 3.68318 2.49835L10.7292 9.54433C10.8092 9.62439 10.8092 9.78453 10.7292 9.8646L3.68318 16.9106C3.52304 17.0707 3.28284 16.9906 3.28284 16.7504Z", fill: "white" }),
    React.createElement("path", { d: "M10.8092 29.5615V15.4695C10.8092 15.2293 10.569 15.1493 10.4089 15.3094L3.36291 22.3554C3.28284 22.4354 3.28284 22.5956 3.36291 22.6756L10.4089 29.7216C10.569 29.8817 10.8092 29.8017 10.8092 29.5615Z", fill: "white" }),
    React.createElement("path", { d: "M27.8636 22.5956C27.8636 26.2787 24.8211 29.3213 21.1379 29.3213C17.4548 29.3213 14.4122 26.2787 14.4122 22.5956C14.4122 18.9125 17.4548 15.8699 21.1379 15.8699C24.8211 15.8699 27.8636 18.8324 27.8636 22.5956Z", fill: "white" }),
    React.createElement("path", { d: "M17.9352 10.0249C17.9352 10.9857 17.1346 11.7064 16.2538 11.7064C15.293 11.7064 14.5724 10.9057 14.5724 10.0249C14.5724 9.06412 15.3731 8.34351 16.2538 8.34351C17.1346 8.34351 17.9352 9.06412 17.9352 10.0249Z", fill: "white" }),
    React.createElement("path", { d: "M46.9998 8.74364V13.7879H55.1668V17.7913H46.9998V25.9582H42.8363V4.82031H55.407V8.74364H46.9998Z", fill: "#333F48" }),
    React.createElement("path", { d: "M62.2128 13.3878C62.9334 11.4662 64.7749 10.5054 66.7766 10.5054V14.9091C65.5756 14.749 64.5347 14.9892 63.5739 15.6297C62.6131 16.2703 62.2128 17.3111 62.2128 18.7524V25.9585H58.2894V10.8256H62.2128V13.3878Z", fill: "#333F48" }),
    React.createElement("path", { d: "M79.9079 10.8256H83.8312V25.9585H79.9079V24.197C78.7068 25.6382 77.1055 26.4389 75.0237 26.4389C73.022 26.4389 71.2605 25.7183 69.8193 24.1169C68.3781 22.5956 67.6575 20.674 67.6575 18.4321C67.6575 16.1902 68.3781 14.3486 69.8193 12.7473C71.2605 11.226 73.022 10.4253 75.0237 10.4253C77.1055 10.4253 78.7869 11.1459 79.9079 12.6672V10.8256ZM75.7443 22.5956C76.9453 22.5956 77.9862 22.1953 78.7068 21.3946C79.5075 20.5939 79.9079 19.553 79.9079 18.352C79.9079 17.151 79.5075 16.1101 78.7068 15.3094C77.9062 14.5088 76.9453 14.1084 75.7443 14.1084C74.5433 14.1084 73.5024 14.5088 72.7818 15.3094C71.9811 16.1101 71.5808 17.151 71.5808 18.352C71.5808 19.553 71.9811 20.5939 72.7818 21.3946C73.5024 22.2753 74.5433 22.5956 75.7443 22.5956Z", fill: "#333F48" }),
    React.createElement("path", { d: "M87.9145 24.0368C86.3932 22.5155 85.6726 20.5939 85.6726 18.352C85.6726 16.1101 86.3932 14.1885 87.9145 12.6672C89.4358 11.1459 91.3574 10.3452 93.5993 10.3452C96.5618 10.3452 99.124 11.8665 100.325 14.2685L96.9622 16.2702C96.3216 15.0692 95.0405 14.2685 93.5193 14.2685C91.1973 14.2685 89.4358 15.95 89.4358 18.4321C89.4358 19.6331 89.8361 20.5939 90.5567 21.3946C91.2774 22.1152 92.2382 22.5155 93.4392 22.5155C95.0405 22.5155 96.3216 21.7949 96.8821 20.5138L100.245 22.4355C98.8838 24.8375 96.3216 26.3588 93.4392 26.3588C91.3574 26.3588 89.4358 25.5581 87.9145 24.0368Z", fill: "#333F48" }),
    React.createElement("path", { d: "M111.775 14.5888H108.332V20.8341C108.332 22.4355 109.533 22.5155 111.775 22.3554V25.8784C106.41 26.5189 104.489 24.9176 104.489 20.8341V14.5888H101.846V10.8256H104.489V7.78305L108.412 6.58203V10.8256H111.855V14.5888H111.775Z", fill: "#333F48" }),
    React.createElement("path", { d: "M121.223 26.3588C118.981 26.3588 117.059 25.6382 115.538 24.0368C114.017 22.5155 113.216 20.5939 113.216 18.352C113.216 16.1101 113.937 14.2685 115.538 12.6672C117.059 11.1459 118.981 10.3452 121.223 10.3452C123.465 10.3452 125.386 11.1459 126.908 12.6672C128.429 14.1885 129.23 16.1101 129.23 18.352C129.23 20.5939 128.429 22.5155 126.908 24.0368C125.386 25.5581 123.465 26.3588 121.223 26.3588ZM121.223 22.5155C122.424 22.5155 123.385 22.1152 124.185 21.3145C124.986 20.5138 125.386 19.553 125.386 18.352C125.386 17.151 124.986 16.1101 124.185 15.3895C123.385 14.5888 122.424 14.1885 121.223 14.1885C120.022 14.1885 119.061 14.5888 118.26 15.3895C117.54 16.1902 117.139 17.151 117.139 18.352C117.139 19.553 117.54 20.5939 118.26 21.3145C119.061 22.1152 120.022 22.5155 121.223 22.5155Z", fill: "#333F48" }),
    React.createElement("path", { d: "M135.075 13.3878C135.795 11.4662 137.637 10.5054 139.638 10.5054V14.9091C138.437 14.749 137.397 14.9892 136.436 15.6297C135.475 16.2703 135.075 17.3111 135.075 18.7524V25.9585H131.151V10.8256H135.075V13.3878Z", fill: "#333F48" }),
    React.createElement("path", { d: "M152.77 10.8254H156.933L151.408 25.9583C149.807 30.2819 147.325 32.2036 143.402 31.9634V28.3603C145.483 28.3603 146.604 27.4796 147.325 25.4779L141.16 10.9055H145.403L149.327 21.0741L152.77 10.8254Z", fill: "#333F48" }))); };
var ForwardRef = forwardRef(SvgFractoryLogo);
var Memo = memo(ForwardRef);
export default Memo;
