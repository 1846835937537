var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGrunfinLightLogoType = function (props, ref) { return (React.createElement("svg", __assign({ width: 120, height: 24, viewBox: "0 0 120 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M45.906 9.56017C45.294 6.71617 42.954 5.25817 40.056 5.25817C36.096 5.25817 33.396 7.83217 33.396 11.9542C33.396 16.1302 35.754 18.7582 39.606 18.7582C41.478 18.7582 42.756 18.2182 43.602 17.0842L43.692 18.5242H46.05V11.2522H39.822V13.7722H42.972V14.0242C42.972 15.6802 41.496 16.2202 40.074 16.2202C38.022 16.2202 36.654 14.7082 36.654 11.9542C36.654 9.20017 38.13 7.79617 40.02 7.79617C41.694 7.79617 42.612 8.66017 42.882 9.56017H45.906Z", fill: "white" }),
    React.createElement("path", { d: "M53.4517 13.6102L56.2777 18.5242H59.8957L56.6737 13.1062C58.1677 12.5302 59.2297 11.4322 59.2297 9.50617C59.2297 6.26617 56.6917 5.47417 53.3077 5.47417H48.2857V18.5242H51.3637V13.6102H53.4517ZM51.3637 8.06617H53.3977C54.8557 8.06617 55.9717 8.33617 55.9717 9.54217C55.9717 10.7662 54.8557 11.0182 53.3977 11.0182H51.3637V8.06617Z", fill: "white" }),
    React.createElement("path", { d: "M65.8329 2.57617H63.1869V4.61017H65.8329V2.57617ZM69.9729 2.57617H67.3089V4.61017H69.9729V2.57617ZM64.0149 5.47417H60.9369V12.9442C60.9369 17.2462 62.7009 18.7582 66.6069 18.7582C70.6749 18.7582 72.2229 16.8502 72.2229 12.8362V5.47417H69.1449V13.0882C69.1449 15.0142 68.7669 16.2382 66.6069 16.2382C64.4649 16.2382 64.0149 15.1762 64.0149 13.2682V5.47417Z", fill: "white" }),
    React.createElement("path", { d: "M82.5972 18.5242H86.3412V5.47417H83.3712V14.4742L78.1332 5.47417H74.3892V18.5242H77.3592V9.52417L82.5972 18.5242Z", fill: "white" }),
    React.createElement("path", { d: "M88.6978 5.47417V18.5242H91.7758V13.1782H97.9138V10.4602H91.7758V8.17417H98.3098V5.47417H88.6978Z", fill: "white" }),
    React.createElement("path", { d: "M102.551 18.5242V5.47417H99.4732V18.5242H102.551Z", fill: "white" }),
    React.createElement("path", { d: "M113.113 18.5242H116.857V5.47417H113.887V14.4742L108.649 5.47417H104.905V18.5242H107.875V9.52417L113.113 18.5242Z", fill: "white" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.9912 12.4649C23.997 12.3107 24 12.1557 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 16.4894 2.46533 20.403 6.11603 22.4609C7.8546 23.4409 9.86201 24 12 24C18.2132 24 23.3235 19.278 23.938 13.2269C23.9636 12.9751 23.9814 12.7211 23.9912 12.4649ZM7.06819 20.9306L14.896 8.35025C15.5535 7.29357 16.9805 7.03729 17.9645 7.79916L22.1558 11.044C21.6738 5.85897 17.3109 1.8 12 1.8C6.3667 1.8 1.8 6.3667 1.8 12C1.8 15.8445 3.92689 19.1921 7.06819 20.9306Z", fill: "white" }),
    React.createElement("path", { d: "M13.2 6.90001C13.2 8.05981 12.2598 9.00001 11.1 9.00001C9.9402 9.00001 9 8.05981 9 6.90001C9 5.74021 9.9402 4.80001 11.1 4.80001C12.2598 4.80001 13.2 5.74021 13.2 6.90001Z", fill: "white" }),
    React.createElement("path", { opacity: 0.56, d: "M4.33144 21.2312C2.29675 19.5389 0.830505 17.1866 0.261322 14.5028L6.29067 10.3286C6.77239 9.99513 7.4245 10.0582 7.83332 10.4779L9.90014 12.5997L4.33144 21.2312Z", fill: "white" }))); };
var ForwardRef = forwardRef(SvgGrunfinLightLogoType);
var Memo = memo(ForwardRef);
export default Memo;
