import {
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CheckboxGroup,
  DateDropdown,
  differenceInYears,
  Field,
  FocusArea,
  formatCurrency,
  InfoTooltip,
  Input,
  Message,
  MessageLevel,
  Portfolio,
  Select,
  Spinner,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';

import { UpdatePortfolio } from '~/modules/portfolio/types';
import { PreferredRiskReturnRatio } from '~/modules/onboarding/types';

interface Props {
  portfolio: Portfolio;
  onSubmit: (values: UpdatePortfolio, setError: UseFormSetError<UpdatePortfolio>) => void;
}

export const SettingsForm = ({ portfolio, onSubmit }: Props) => {
  const { t } = useTranslation('portfolio');
  const [params] = useSearchParams();
  const form = useForm<UpdatePortfolio>({ defaultValues: defaultValues(portfolio), mode: 'onChange' });
  const {
    register,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
    setError,
    setValue,
    getValues,
    handleSubmit,
  } = form;

  const amount = params.get('amount');

  useEffect(() => {
    amount && setValue('monthlyInvestmentAmountEur', amount, { shouldDirty: true, shouldValidate: true });
  }, [amount, setValue]);

  const isCorporate = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit((data) => onSubmit(data, setError))}>
        <fieldset disabled={isSubmitting}>
          {errors.preferredRisk && (
            <div tw="mb-12">
              <Message level={MessageLevel.WARN}>{errors.preferredRisk?.message}</Message>
            </div>
          )}
          <Field label={t('portfolio_name')} htmlFor="name" error={errors.name?.message}>
            <Input
              id="name"
              {...register('name', { required: t('required', { ns: 'general' }) as string })}
              error={'name' in errors}
              key="name"
              maxLength={100}
            />
          </Field>
          {!isCorporate && (
            <Field
              label={t('monthly_contribution')}
              htmlFor="monthlyInvestmentAmountEur"
              error={errors?.monthlyInvestmentAmountEur?.message}
            >
              <div tw="w-40">
                <Input
                  id="monthlyInvestmentAmountEur"
                  {...register('monthlyInvestmentAmountEur', {
                    required: t('required', { ns: 'general' }) as string,
                    validate: {
                      mustBeZeroOrGreater: (v) =>
                        (v && +v >= 0) ||
                        (t('validation.min_monthly_contribution', { min: formatCurrency(0) }) as string),
                    },
                  })}
                  error={'monthlyInvestmentAmountEur' in errors}
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  suffix={
                    <div
                      onClick={() =>
                        // focus on the input with name="monthlyInvestmentAmountEur"
                        (
                          document.querySelector('input[name="monthlyInvestmentAmountEur"]') as HTMLInputElement
                        )?.focus()
                      }
                    >
                      EUR
                    </div>
                  }
                />
              </div>
            </Field>
          )}
          <Field label={t('sustainability_areas')} htmlFor="focusAreas" error={errors.focusAreas?.message}>
            <Controller
              control={control}
              rules={{ required: t('required', { ns: 'general' }) as string }}
              name="focusAreas"
              render={({ field }) => (
                <CheckboxGroup onChange={field.onChange} value={field.value}>
                  <Checkbox
                    name={FocusArea.CLIMATE_CHANGE}
                    label={t('climate_change.label')}
                    icon={
                      <InfoTooltip>
                        <Trans i18nKey="climate_change.tooltip" t={t} />
                      </InfoTooltip>
                    }
                    disabled={isCorporate}
                  />
                  <div tw="my-4" />
                  <Checkbox
                    name={FocusArea.GENDER_EQUALITY}
                    label={t('gender_equality.label')}
                    icon={
                      <InfoTooltip>
                        <Trans i18nKey="gender_equality.tooltip" t={t} />
                      </InfoTooltip>
                    }
                    disabled={isCorporate}
                  />
                  <div tw="my-4" />
                  <Checkbox
                    name={FocusArea.HEALTH}
                    label={t('health.label')}
                    icon={
                      <InfoTooltip>
                        <Trans i18nKey="health.tooltip" t={t} />
                      </InfoTooltip>
                    }
                    disabled={isCorporate}
                  />
                  <div tw="my-4" />
                  <Checkbox
                    name={FocusArea.SP500}
                    label={t('sp500.label')}
                    icon={
                      <InfoTooltip>
                        <Trans i18nKey="sp500.tooltip" t={t} />
                      </InfoTooltip>
                    }
                    disabled={isCorporate}
                  />
                </CheckboxGroup>
              )}
            />
          </Field>
          {!isCorporate && (
            <Field label={t('target_date')} htmlFor="endDate" error={errors.endDate?.message}>
              <Controller
                control={control}
                rules={{
                  required: t('required', { ns: 'general' }) as string,
                  validate: {
                    mustBeGreaterThanOne: async (v) => {
                      if (!portfolio.endDate || !portfolio.startDate) {
                        return true;
                      }
                      return differenceInYears(v, portfolio.startDate) >= 1 || (t('validation.min_duration') as string);
                    },
                  },
                }}
                name="endDate"
                render={({ field }) => (
                  <DateDropdown {...field} value={portfolio.endDate} spanFuture={50} spanBack={0} />
                )}
              />
            </Field>
          )}
          <Field label={t('risk_preference')} htmlFor="preferredRisk" error={errors.preferredRisk?.message}>
            <div tw="w-60 flex flex-row items-center gap-4">
              <Select
                id="preferredRisk"
                {...register('preferredRisk', {
                  required: true,
                  validate: {
                    mustMatchPeriod(v) {
                      const isValid =
                        portfolio.endDate &&
                        differenceInYears(getValues('endDate'), portfolio.startDate) < 3 &&
                        v !== 'LOW';
                      return !isValid || (t('validation.risk_minimum_period') as string);
                    },
                  },
                })}
                error={'preferredRisk' in errors}
              >
                <option value={PreferredRiskReturnRatio.Very_High}>{t('risk.very_high')}</option>
                <option value={PreferredRiskReturnRatio.High}>{t('risk.high')}</option>
                <option value={PreferredRiskReturnRatio.Medium}>{t('risk.medium')}</option>
                <option value={PreferredRiskReturnRatio.Low}>{t('risk.low')}</option>
              </Select>
              <InfoTooltip>
                <Trans
                  i18nKey="risk_preference_tooltip.text"
                  components={[
                    <a
                      key="0"
                      tw="underline"
                      href={t('risk_preference_tooltip.faq_link')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                  t={t}
                />
              </InfoTooltip>
            </div>
          </Field>
          <div tw="mt-16">{t('edit_disclaimer')}</div>
          <div tw="mt-12 md:mt-16 mb-8 relative w-max">
            <Button
              type="submit"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              disabled={!isDirty || !isValid}
            >
              {isSubmitting && (
                <div tw="absolute">
                  <Spinner />
                </div>
              )}
              <span css={[isSubmitting && tw`invisible`]}>{t('confirm_changes', { ns: 'general' })}</span>
            </Button>
          </div>
        </fieldset>
      </form>
    </FormProvider>
  );
};

const defaultValues = (portfolio: Portfolio): Partial<UpdatePortfolio> => ({
  focusAreas: portfolio.focus.split(', ') as FocusArea[],
  name: portfolio.name,
  preferredRisk: portfolio.preferredRisk,
  monthlyInvestmentAmountEur: String(portfolio.monthlyInvestmentAmountEur),
  endDate: portfolio.endDate,
});
