import 'twin.macro';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import {
  useCreateContribution,
  useDirectDebitThirdPartyPayment,
  useGetPortfolioContributionSettings,
  useGetPublicPortfolio,
} from '../../portfolio/queries';
import {
  ArrowIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  formatCurrency,
  GiftIcon,
  InfoIcon,
  Input,
  PadlockIcon,
  Tooltip,
  useTranslation,
} from '@grunfin/ui-kit';
import { useParams, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Contribution } from '~/modules/portfolio/types';
import { setBackendValidationErrors } from '~/utils';
import { useGetBanks, useMontonioPayment } from '~/modules/gift/contribute/queries';
import { Instruction } from '~/modules/portfolio/components/Instruction';
import { ContributeSuccess } from '~/modules/gift/contribute/ContributeSuccess';
import { useSession } from '~/modules/auth/SessionProvider';
import { Bank } from '~/modules/gift/contribute/types';

export const Contribute = () => {
  const { t, i18n } = useTranslation('gift');
  const [query] = useSearchParams();
  const isSuccess = query.has('success');
  const { id } = useParams();
  if (!id && !isSuccess) throw new Error('Missing portfolio id');
  const session = useSession();
  const publicPortfolio = useGetPublicPortfolio(id, { enabled: !!id })?.data;
  const getSettings = useGetPortfolioContributionSettings(id, { enabled: !!id })?.data;
  const thirdPartyPayment = useDirectDebitThirdPartyPayment(id);
  const createContribution = useCreateContribution(id);
  const [step, setStep] = useState(0);
  const [method, setMethod] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const useBanks = useGetBanks();
  const useMontonio = useMontonioPayment();

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    control,
    formState: { errors },
  } = useForm<Contribution>({ mode: 'onSubmit' });

  useEffect(() => {
    i18n?.language === 'ee' && setMethod(1);
  }, [i18n?.language]);

  useEffect(() => {
    session?.cfIpcountry == 'ee' && setMethod(1);
  }, [session?.cfIpcountry]);

  const initiateDirectDebitPayment = async () => {
    try {
      const currentUrl = window.location.href;
      const values = getValues();

      const res = await thirdPartyPayment.mutateAsync({
        email: values.email,
        amount: values.amount,
        successUrl: `${currentUrl}?success=true`,
        cancelUrl: currentUrl,
      });

      if (res?.url) {
        window.location.href = res?.url;
      }
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  const onSubmit = async (data: Contribution) => {
    setStep(1);
    data.paymentCode = getSettings?.paymentCode;
    data.referenceNumber = getSettings?.referenceNumber;
    await createContribution.mutateAsync(data);
  };

  const PaymentMethods = () => {
    if (method == 0) {
      return (
        <div>
          <p tw="my-8">{t('get_gifts.direct_debit_info')}</p>
          <Button variant={ButtonVariant.PRIMARY} onClick={() => initiateDirectDebitPayment()}>
            {t('get_gifts.direct_debit_start')}
          </Button>
        </div>
      );
    } else if (method === 1) {
      const banks = useBanks?.data?.paymentMethods?.paymentInitiation.setup.EE.paymentMethods;
      return (
        <div tw="grid grid-cols-2 gap-4">
          {getSettings?.paymentCode &&
            banks?.map((bank: Bank, i) => (
              <button
                className={`flex justify-center shadow-inner rounded subpixel-antialiased font-semibold ${
                  i === banks.length - 1 && 'col-span-2'
                }`}
                key={bank.code}
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  useMontonio.mutateAsync({
                    bic: bank.code,
                    amount: getValues().amount,
                    paymentCode: getSettings?.paymentCode,
                    payerEmail: getValues().email,
                    language: i18n.language,
                    referenceNumber: getSettings?.referenceNumber,
                    redirectUrl: `${window.location.protocol}//${window.location.host}/contribute?success=true&reference=${getSettings?.paymentCode}`,
                  });
                }}
              >
                <img tw="object-contain py-4 h-14" src={bank.logoUrl} alt={bank.name} />
              </button>
            ))}
        </div>
      );
    } else if (method === 2) {
      return (
        <div tw="space-y-4">
          <Instruction label={t('payment.beneficiary_name', { ns: 'portfolio' })} copy={getSettings?.beneficiaryName}>
            {getSettings?.beneficiaryName}
          </Instruction>
          <Instruction label={t('payment.account_number', { ns: 'portfolio' })} copy={getSettings?.accountNumber}>
            {getSettings?.accountNumber}
          </Instruction>
          <Instruction label={t('payment.payment_reference', { ns: 'portfolio' })} copy={getSettings?.paymentCode}>
            {getSettings?.paymentCode}
          </Instruction>
          <Instruction label={t('payment.reference_number', { ns: 'portfolio' })} copy={getSettings?.referenceNumber}>
            {getSettings?.referenceNumber}
          </Instruction>
          <Instruction label={t('sum', { ns: 'general' })} copy={'' + getValues().amount}>
            {formatCurrency(getValues().amount, { maxDigits: 2 })}
          </Instruction>
        </div>
      );
    } else {
      throw new Error('Invalid payment method');
    }
  };

  const MethodButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button
      tw="w-full flex justify-center text-center text-base items-center py-1 px-2 md:py-2 md:px-6 rounded subpixel-antialiased transition-colors"
      {...props}
    >
      {props.children}
    </button>
  );

  const isActive = (methodStep: number) => (method === methodStep ? 'bg-alps-blue-600 text-alps-blue-100' : '');

  const SetupView = () => (
    <>
      <div tw="flex w-full">
        <h1 tw="text-lg text-alps-blue-700 font-bold block mb-6 md:mb-3">
          {t('get_gifts.title', { name: publicPortfolio?.firstName || '' })}
        </h1>
        <div tw="flex flex-row ml-auto mt-2">
          <Tooltip
            content={
              <ol className="p-4">
                <li>
                  <strong>{t('get_gifts.how.one')}</strong>
                </li>
                <li>
                  <strong>{t('get_gifts.how.two')}</strong>
                </li>
                <li>
                  <strong>{t('get_gifts.how.three')}</strong>
                </li>
              </ol>
            }
          >
            <div tw="flex flex-row text-alps-blue-700 cursor-pointer">
              <InfoIcon
                tw="w-4 h-4 rounded-full bg-alps-blue-100 text-alps-blue-700 flex-shrink-0 mr-2 mt-1"
                onClick={(e) => e.preventDefault()}
              />
              {t('get_gifts.how_it_works')}
            </div>
          </Tooltip>
        </div>
      </div>
      <div>
        <h2 tw="text-alps-blue-700 md:text-lg whitespace-pre-line">{getSettings?.message}</h2>

        <div tw="flex flex-row my-10">
          <div tw="flex flex-row text-lg">
            <GiftIcon className={step === 0 ? 'bg-alps-blue-50 text-alps-blue-700' : 'bg-gray-50 text-gray-300 '} />
            <p tw="ml-4" className={step !== 0 ? 'text-gray-300' : ''}>
              {t('get_gifts.gift')}
            </p>
          </div>
          <ArrowIcon key={1} tw="mx-10 text-gray-900" />
          <div tw="flex flex-row text-lg">
            <PadlockIcon tw="bg-alps-blue-50 text-alps-blue-700" />
            <p tw="ml-4"> {t('get_gifts.payment')}</p>
          </div>
        </div>
        {step == 0 ? (
          <form tw="space-y-3" onSubmit={handleSubmit((values) => onSubmit(values))}>
            <p>{t('get_gifts.email')}</p>
            <Input
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
              error={'email' in errors}
              key="email"
              maxLength={100}
            />
            {errors.email && <div tw="text-sm text-dawn-300 mt-2">{t('login.email.error', { ns: 'auth' })}</div>}
            <p>{t('get_gifts.message', { name: publicPortfolio?.firstName || '' })} </p>
            <textarea
              tw="block p-4 w-full rounded border-2 focus-within:border-alps-blue-200 resize-none"
              {...register('message')}
              key="message"
              rows={3}
            />
            <p>{t('get_gifts.amount')}</p>
            <div tw="w-1/2">
              <Input
                {...register('amount', { max: 1000, required: t('required', { ns: 'general' }) as string })}
                error={'amount' in errors}
                key="amount"
                type="number"
                suffix="EUR"
              />
            </div>
            <div tw="pt-4 text-gray-400 text-base">
              <Controller
                control={control}
                name="newsletters"
                render={({ field }) => (
                  <Checkbox
                    onChange={field.onChange}
                    value={field.value}
                    name="newsletters"
                    label={<span tw="font-light">{t('get_gifts.newsletters')}</span>}
                  />
                )}
              />
            </div>
            <br />
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              type="submit"
              disabled={!publicPortfolio?.id || !getSettings?.referenceNumber}
            >
              {t('get_gifts.continue')}
            </Button>
          </form>
        ) : (
          <div>
            <div tw="flex flex-row bg-alps-blue-50 mb-10 rounded">
              <MethodButton className={isActive(0)} onClick={() => setMethod(0)}>
                {t('get_gifts.direct_debit')}
              </MethodButton>
              <MethodButton className={isActive(1)} onClick={() => setMethod(1)}>
                {t('get_gifts.bank_link')}
              </MethodButton>
              <MethodButton className={isActive(2)} onClick={() => setMethod(2)}>
                {t('get_gifts.manual')}
              </MethodButton>
            </div>
            <PaymentMethods />
          </div>
        )}
      </div>
    </>
  );

  return (
    <div tw="flex flex-col md:w-3/5 xl:w-2/5 md:self-center bg-white rounded max-w-lg px-6 py-8 md:p-12">
      {isSuccess ? <ContributeSuccess /> : <SetupView />}
      <div tw="text-sm text-gray-400 mt-16">{t('get_gifts.provider_info')}</div>
    </div>
  );
};
