var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgTimeIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m16 27c6.0751 0 11-4.9249 11-11 0-6.07513-4.9249-11-11-11-6.07513 0-11 4.92487-11 11 0 6.0751 4.92487 11 11 11zm0-19c.5523 0 1 .44772 1 1v5.8819c.283.2533.4692.6125.4965 1.0152l3.6174 2.8135c.436.3391.5145.9674.1755 1.4033-.3391.436-.9674.5145-1.4033.1755l-3.6172-2.8134c-.0873.0158-.1771.024-.2689.024-.8284 0-1.5-.6716-1.5-1.5 0-.4443.1931-.8434.5-1.1181v-5.8819c0-.55228.4477-1 1-1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgTimeIcon);
var Memo = memo(ForwardRef);
export default Memo;
