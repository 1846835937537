import 'twin.macro';
import { ChangeEvent, useMemo } from 'react';
import {
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CheckboxGroup,
  InfoTooltip,
  Input,
  Message,
  MessageLevel,
  Radio,
  RadioGroup,
  Tooltip,
  Trans,
  TrustBlock,
  useTranslation,
} from '@grunfin/ui-kit';

import type { Answers, Question } from './types';
import { InvestmentExperience, PastPerformance, PreferredRiskReturnRatio } from './types';
import riskGraph from '~/assets/output/risk-graph.svg';
import { AgePicker } from './components/AgePicker';
import { deserializeMifidExperience, getAmount, useWarningMessages } from './utils';
import { FocusAreas } from './components/FocusAreas';
import { OnboardingAuth, OnboardingStepList, onboardingSteps } from './EmailCaptureStep';

export const useQuestions = (): Question[] => {
  const { t, i18n } = useTranslation('onboarding');
  const messages = useWarningMessages();

  return useMemo(
    () => [
      {
        type: 'portfolio',
        field: 'portfolioType',
        noButton: true,
        isFilled: ({ portfolio }) => portfolio.childAge !== null,
        render: ({ submitValue }) => (
          <>
            <div tw="text-xl">
              <Trans
                i18nKey="questions.portfolio.portfolioType.title"
                t={t}
                components={[<span key="0" tw="text-gray-400" />, <span key="1" tw="text-alps-blue-400" />]}
              />
              <div tw="flex gap-4 mt-14 flex-col md:flex-row">
                <div>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.LARGE}
                    onClick={() => submitValue(BeneficiaryType.SELF)}
                    data-test-id={BeneficiaryType.SELF}
                  >
                    {t('questions.portfolio.portfolioType.myself')}
                  </Button>
                </div>
                <div>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => submitValue(BeneficiaryType.CHILD)}
                    data-test-id={BeneficiaryType.CHILD}
                  >
                    {t('questions.portfolio.portfolioType.child')}
                  </Button>
                </div>
                <div>
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => submitValue(BeneficiaryType.COMPANY)}
                    data-test-id={BeneficiaryType.COMPANY}
                  >
                    {t('questions.portfolio.portfolioType.company')}
                  </Button>
                </div>
              </div>

              <Tooltip
                showOnHover={false}
                placement={'top-start'}
                content={<div tw="p-2">{t('questions.portfolio.portfolioType.join_as_employee_description')}</div>}
              >
                <div tw="text-base md:text-lg text-gray-500 md:text-gray-400 mt-6 md:mt-16 underline cursor-pointer">
                  {t('questions.portfolio.portfolioType.join_as_employee')}
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
      {
        type: 'portfolio',
        field: 'areas',
        isFilled: ({ portfolio: { areaClimateChange, areaGenderEquality, areaHealth, areaSP500 } }) =>
          [areaClimateChange, areaGenderEquality, areaHealth, areaSP500].every((a) => a !== null),
        render: ({ value, setValue }) => <FocusAreas value={value} setValue={setValue} />,
      },
      {
        type: 'portfolio',
        field: 'childAge',
        isFilled: ({ portfolio }) => portfolio.childAge !== null,
        render: ({ value, setFields }) => (
          <>
            <div tw="text-xl mb-9">
              <div tw="mb-6 text-gray-400">{t('questions.portfolio.childAge.start.introduction')}</div>
              <Trans
                i18nKey="questions.portfolio.childAge.start.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <div tw="w-40">
              <Input
                suffix={t('questions.portfolio.childAge.start.suffix')}
                value={value}
                data-test-id="child-age"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const age = parseInt(e.target.value);
                  setFields((v: Record<string, unknown>) => ({
                    ...v,
                    childAge: isNaN(age) ? '' : age.toString(),
                    ageOfChildAtTheEndOfPortfolio: (age >= 15 ? age + 3 : 18).toString(),
                  }));
                }}
              />
            </div>
          </>
        ),
      },
      {
        type: 'portfolio',
        field: 'ageOfChildAtTheEndOfPortfolio',
        isFilled: ({ portfolio }) => portfolio.ageOfChildAtTheEndOfPortfolio !== null,
        render: ({ value, setValue, answers }) => {
          const portfolioTimeframeInYears = parseFloat(value) - (answers.portfolio.childAge || 0);
          return (
            <>
              <div tw="text-xl">
                <Trans
                  i18nKey="questions.portfolio.childAge.end.title"
                  t={t}
                  components={[<span key="0" tw="text-alps-blue-400" />]}
                />
              </div>

              {portfolioTimeframeInYears < 3 && (
                <div tw="mt-10">
                  <Message level={MessageLevel.WARN}>
                    <Trans i18nKey="questions.portfolio.childAge.end.suggestion" t={t} />
                  </Message>
                </div>
              )}

              <div tw="mt-12">
                <AgePicker
                  startAge={answers.portfolio.childAge || 0}
                  endAge={parseFloat(value)}
                  onChange={(v) => setValue(v ? v.toString() : '')}
                />
              </div>
            </>
          );
        },
      },
      {
        isFilled: ({ mifid }: Answers) => deserializeMifidExperience(mifid).length > 0,
        field: 'intro',
        type: 'mifid',
        render: () => (
          <div>
            <div tw="text-xl mb-12">{t('questions.mifid.introduction')}</div>
            <div tw="mb-4 md:text-lg">
              <Trans
                i18nKey="questions.mifid.description"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>

            <div tw="mt-6">
              <a
                target="_blank"
                rel="noreferrer"
                tw="text-gray-400 hover:underline mt-3"
                href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5498-what-is-the-purpose-of-the-onboarding-questions`}
              >
                {t('questions.mifid.faq_link')}
              </a>
            </div>
          </div>
        ),
      },
      {
        type: 'mifid',
        field: 'experience',
        isFilled: ({ mifid }: Answers) => deserializeMifidExperience(mifid).length > 0,
        render: ({ value, setValue, disabled }) => (
          <>
            <div className="mifid-question">
              <Trans
                i18nKey="questions.mifid.experience.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <CheckboxGroup disabled={disabled} value={value} onChange={setValue}>
              <Checkbox
                name={InvestmentExperience.FUNDS}
                label={t('questions.mifid.experience.funds')}
                disabled={value.includes(InvestmentExperience.NO_EXPERIENCE)}
              />
              <div tw="my-4" />
              <Checkbox
                name={InvestmentExperience.SHARES}
                label={t('questions.mifid.experience.shares')}
                disabled={value.includes(InvestmentExperience.NO_EXPERIENCE)}
              />
              <div tw="my-4" />
              <Checkbox
                name={InvestmentExperience.NO_EXPERIENCE}
                label={t('questions.mifid.experience.none')}
                disabled={value.includes(InvestmentExperience.SHARES) || value.includes(InvestmentExperience.FUNDS)}
              />
            </CheckboxGroup>
          </>
        ),
      },
      {
        type: 'mifid',
        field: 'pastPerformance',
        isFilled: ({ mifid }) => mifid.pastPerformance !== null,
        render: ({ value, setValue, disabled }) => (
          <>
            <div className="mifid-question">
              <Trans
                i18nKey="questions.mifid.pastPerformance.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <RadioGroup
              disabled={disabled}
              name="pastPerformance"
              selectedValue={value}
              onChange={(event) => setValue(event.target.value)}
            >
              <Radio
                value={PastPerformance.ShowsFuturePerformance}
                label={t('questions.mifid.pastPerformance.affirmative')}
              />
              <Radio value={PastPerformance.NotGuarantee} label={t('questions.mifid.pastPerformance.negative')} />
              <Radio value={PastPerformance.NotSure} label={t('questions.mifid.pastPerformance.unsure')} />
            </RadioGroup>
          </>
        ),
      },
      {
        type: 'mifid',
        field: 'wouldWithdrawOnValueDrop',
        isFilled: ({ mifid }) => mifid.wouldWithdrawOnValueDrop !== null,
        render: ({ value, setValue, disabled }) => (
          <>
            <div className="mifid-question">
              <Trans
                i18nKey="questions.mifid.wouldWithdrawOnValueDrop.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <RadioGroup
              disabled={disabled}
              name="would_withdraw_on_value_drop"
              selectedValue={value}
              onChange={(event) => setValue(event.target.value)}
            >
              <Radio value="true" label={t('questions.mifid.wouldWithdrawOnValueDrop.affirmative')} />
              <Radio value="false" label={t('questions.mifid.wouldWithdrawOnValueDrop.negative')} />
            </RadioGroup>
          </>
        ),
      },
      {
        type: 'portfolio',
        field: 'preferredRisk',
        isFilled: () => false,
        render: ({ value, setValue, answers }) => {
          const lessThan3Years =
            (answers?.portfolio?.ageOfChildAtTheEndOfPortfolio || 0) - (answers?.portfolio?.childAge || 0) < 3 &&
            answers.portfolio.portfolioType === BeneficiaryType.CHILD;
          if (lessThan3Years && value !== PreferredRiskReturnRatio.Low) setValue(PreferredRiskReturnRatio.Low);
          return (
            <>
              <div tw="text-xl mb-6">
                <Trans
                  i18nKey="questions.portfolio.preferredRisk.title"
                  t={t}
                  components={[<span key="0" tw="text-alps-blue-400" />]}
                />
              </div>
              <div tw="grid md:grid-cols-10 gap-y-10">
                <div tw="col-span-9 text-gray-400 text-sm md:text-base">
                  {t('questions.portfolio.preferredRisk.disclaimer')}
                </div>
                {answers.mifid.shouldDisplayHighRiskWarning && !lessThan3Years && (
                  <div tw="col-span-9 mb-10 hidden md:block">
                    <Message level={MessageLevel.WARN}>
                      {t('questions.portfolio.preferredRisk.risk_averse')}
                      <div>
                        <Trans
                          i18nKey="questions.portfolio.preferredRisk.faq_link"
                          t={t}
                          components={[
                            <a
                              key="0"
                              href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5497-what-are-the-risks-related-to-my-portfolio`}
                              tw="text-alps-blue-400 underline"
                              target="_blank"
                              rel="noreferrer"
                            />,
                          ]}
                        />
                      </div>
                    </Message>
                  </div>
                )}

                {lessThan3Years && (
                  <div tw="col-span-9 mb-10 hidden md:block">
                    <Message level={MessageLevel.WARN}>
                      <div tw="font-semibold">{t('questions.portfolio.preferredRisk.low_risk_period')}</div>
                      <div>
                        <Trans
                          i18nKey="questions.portfolio.preferredRisk.faq_link"
                          t={t}
                          components={[
                            <a
                              key="0"
                              href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5497-what-are-the-risks-related-to-my-portfolio`}
                              tw="text-alps-blue-400 underline"
                              target="_blank"
                              rel="noreferrer"
                            />,
                          ]}
                        />
                      </div>
                    </Message>
                  </div>
                )}
                <div tw="col-span-8 md:col-span-4">
                  <RadioGroup
                    name="risk_tolerance"
                    selectedValue={value}
                    onChange={(event) => setValue(event.target.value)}
                  >
                    <Radio
                      value={PreferredRiskReturnRatio.Low}
                      label={
                        <div tw="flex flex-row items-center gap-3">
                          <div tw="rounded py-0.5 px-2 text-white bg-alps-blue-500">
                            {t('questions.portfolio.preferredRisk.tolerance.low')}
                          </div>
                          <InfoTooltip>
                            <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.low_description" t={t} />
                          </InfoTooltip>
                        </div>
                      }
                    />
                    <Radio
                      value={PreferredRiskReturnRatio.Medium}
                      disabled={lessThan3Years}
                      label={
                        <div tw="flex flex-row items-center gap-3">
                          <div tw="rounded py-0.5 px-2 text-white bg-alps-blue-300">
                            {t('questions.portfolio.preferredRisk.tolerance.medium')}
                          </div>
                          <InfoTooltip>
                            <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.medium_description" t={t} />
                          </InfoTooltip>
                        </div>
                      }
                    />
                    <Radio
                      value={PreferredRiskReturnRatio.High}
                      disabled={lessThan3Years}
                      label={
                        <div tw="flex flex-row items-center gap-3">
                          <div tw="rounded py-0.5 px-2 bg-alps-blue-200">
                            {t('questions.portfolio.preferredRisk.tolerance.high')}
                          </div>
                          <InfoTooltip>
                            <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.high_description" t={t} />
                          </InfoTooltip>
                        </div>
                      }
                    />
                    <Radio
                      value={PreferredRiskReturnRatio.Very_High}
                      disabled={lessThan3Years}
                      label={
                        <div tw="flex flex-row items-center gap-3">
                          <div tw="rounded py-0.5 px-2 bg-alps-blue-200">
                            {t('questions.portfolio.preferredRisk.tolerance.very_high')}
                          </div>
                          <InfoTooltip>
                            <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.very_high_description" t={t} />
                          </InfoTooltip>
                        </div>
                      }
                    />
                  </RadioGroup>
                  <div tw="inline-flex mt-5">
                    <div tw="rounded py-0.5 pr-2 text-dawn-600 font-bold inline-block ml-11">
                      {t('questions.portfolio.preferredRisk.inflation.title')}
                    </div>
                    <div tw="ml-2">
                      <InfoTooltip>{t('questions.portfolio.preferredRisk.inflation.explanation')}</InfoTooltip>
                    </div>
                  </div>
                  <div tw="ml-11 mt-2 md:mr-24 text-gray-400 leading-5 text-sm md:text-base">
                    {t('questions.portfolio.preferredRisk.inflation.example')}
                  </div>
                </div>
                <img tw="col-span-9 md:col-span-5 row-start-1 md:row-start-auto" src={riskGraph} />
              </div>
            </>
          );
        },
      },
      {
        type: 'mifid',
        field: 'savingsForThreeMonths',
        isFilled: ({ mifid }) => mifid.savingsForThreeMonths !== null,
        render: ({ value, setValue, disabled }) => (
          <>
            <div className="mifid-question">
              <Trans
                i18nKey="questions.mifid.savingsForThreeMonths.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <RadioGroup
              disabled={disabled}
              name="savings_for_three_months"
              selectedValue={value}
              onChange={(event) => setValue(event.target.value)}
            >
              <Radio value="true" label={t('questions.mifid.savingsForThreeMonths.affirmative')} />
              <Radio value="false" label={t('questions.mifid.savingsForThreeMonths.negative')} />
            </RadioGroup>
          </>
        ),
      },
      {
        type: 'mifid',
        field: 'moreDebtThanAssets',
        isFilled: ({ mifid }) => mifid.moreDebtThanAssets !== null,
        render: ({ value, setValue, disabled, answers }) => {
          const isCompany = answers?.portfolio?.portfolioType === BeneficiaryType.COMPANY;
          const key = isCompany ? 'moreDebtThanAssets.company' : 'moreDebtThanAssets';
          return (
            <>
              <div className="mifid-question">
                <Trans
                  i18nKey={'questions.mifid.' + key + '.title'}
                  t={t}
                  components={[<span key="0" tw="text-alps-blue-400" />]}
                />
              </div>
              <div className="mifid-question-subtitle">{t('questions.mifid.' + key + '.subtitle')}</div>
              <RadioGroup
                disabled={disabled}
                name="more_debt_than_assets"
                selectedValue={value}
                onChange={(event) => setValue(event.target.value)}
              >
                <Radio value="false" label={t('questions.mifid.' + key + '.more_assets')} />
                <Radio value="true" label={t('questions.mifid.' + key + '.more_debt')} />
              </RadioGroup>
            </>
          );
        },
      },
      {
        type: 'portfolio',
        field: 'investments',
        isFilled: ({
          portfolio: {
            investments: { monthlyInvestmentAmountEur, upfrontInvestmentAmountEur },
          },
        }) => monthlyInvestmentAmountEur !== null && upfrontInvestmentAmountEur !== null,
        render: ({ value, setValue }) => {
          return (
            <>
              <div tw="text-xl mb-12">
                <Trans
                  i18nKey="questions.portfolio.investments.title"
                  t={t}
                  components={[<span key="0" tw="text-alps-blue-400" />]}
                />
              </div>
              <div className="mifid-question-subtitle">{t('questions.portfolio.investments.subtitle')}</div>

              <div tw="grid grid-cols-2 grid-rows-2 gap-y-4 sm:gap-x-4 md:w-1/2">
                <div tw="font-medium text-gray-900 mt-2">{t('questions.portfolio.investments.initial')}</div>
                <div tw="sm:w-40">
                  <Input
                    suffix="EUR"
                    value={value?.upfrontInvestmentAmountEur}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setValue({ ...value, upfrontInvestmentAmountEur: getAmount(e) })
                    }
                    data-test-id="upfront-investment"
                  />
                </div>
                <div tw="font-medium text-gray-900 mt-2">{t('questions.portfolio.investments.monthly')}</div>
                <div tw="sm:w-40">
                  <Input
                    suffix="EUR"
                    value={value?.monthlyInvestmentAmountEur}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setValue({ ...value, monthlyInvestmentAmountEur: getAmount(e) })
                    }
                    data-test-id="monthly-investment"
                  />
                </div>
              </div>
            </>
          );
        },
      },
      {
        type: 'portfolio',
        field: 'periodInYears',
        isFilled: ({ portfolio }) => portfolio.periodInYears !== null,
        render: ({ value, setFields }) => (
          <>
            <div tw="text-xl mb-12">
              <Trans
                i18nKey="questions.portfolio.periodInYears.title"
                t={t}
                components={[<span key="0" tw="text-alps-blue-400" />]}
              />
            </div>
            <div className="mifid-question-subtitle">{t('questions.portfolio.periodInYears.subtitle')}</div>
            <div tw="w-40">
              <Input
                suffix={t('questions.portfolio.periodInYears.suffix')}
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const period = parseInt(e.target.value);
                  setFields((v: Record<string, unknown>) => ({
                    ...v,
                    periodInYears: isNaN(period) ? '' : period.toString(),
                  }));
                }}
              />
            </div>
          </>
        ),
      },
      {
        type: 'mifid',
        field: 'acceptedWarningMessage',
        isFilled: () => true,
        render: ({ answers }) => {
          const isCompany = answers?.portfolio?.portfolioType === BeneficiaryType.COMPANY;

          return (
            <>
              <div tw="text-lg mb-12">
                <Trans
                  i18nKey="questions.mifid.warning.title"
                  t={t}
                  components={[<span key="0" tw="text-alps-blue-400" />]}
                />
              </div>
              <div tw="text-base">
                {answers.mifid.pastPerformance !== PastPerformance.NotGuarantee && (
                  <div>
                    <span tw="text-alps-blue-400 mr-6">—</span>
                    <Trans
                      i18nKey="questions.mifid.warning.faq"
                      t={t}
                      components={[
                        <a
                          key="0"
                          href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5497-what-are-the-risks-related-to-my-portfolio`}
                          tw="text-alps-blue-400 underline"
                          target="_blank"
                          rel="noopener"
                        />,
                      ]}
                    />
                  </div>
                )}
                {answers.mifid.wouldWithdrawOnValueDrop && (
                  <div tw="mt-2">
                    <span tw="text-alps-blue-400 mr-6">—</span>
                    {messages.wouldWithdraw}
                  </div>
                )}
                {(!answers.mifid.savingsForThreeMonths || answers.mifid.moreDebtThanAssets) && (
                  <div tw="mt-2">
                    <span tw="text-alps-blue-400 mr-6">—</span>
                    {messages.lackAbilityToBearLoss}{' '}
                    {!answers.mifid.savingsForThreeMonths && messages.cantCoverLivingExpenses}{' '}
                    {answers.mifid.moreDebtThanAssets &&
                      (isCompany ? messages.moreDebtThanAssetsCompany : messages.moreDebtThanAssets)}
                  </div>
                )}
                {(answers?.portfolio?.periodInYears || 0) <= 3 && (
                  <div tw="mt-2">
                    <span tw="text-alps-blue-400 mr-6">—</span>
                    {messages.notLongTermInvestor}
                  </div>
                )}

                <div tw="mt-8">{t('questions.mifid.warning.proceed')}</div>
              </div>
            </>
          );
        },
      },
      {
        field: 'signUp',
        isFilled: () => false,
        noButton: true,
        render: () => {
          const { steps: rootSteps, titleKeys } = onboardingSteps();

          const steps = rootSteps.map((step, index) => ({
            ...step,
            title: t(titleKeys[index]),
            isCompleted: index <= 1,
            isActive: index === 2,
          }));

          return (
            <div tw="flex flex-col gap-16">
              <div tw="flex w-full flex-col gap-14 md:flex-row justify-between px-4">
                <div tw="flex flex-1 flex-col gap-6">
                  <div tw="flex flex-col justify-center items-center gap-4">
                    <h2 tw="text-gray-900 text-lg lg:text-xl lg:mr-4 text-center mx-auto lg:text-left">
                      <Trans
                        i18nKey="questionnaire.signup"
                        components={[<span key="0" tw="text-alps-blue-400" />]}
                        t={t}
                      />
                    </h2>
                    <p tw="text-gray-400 text-sm md:text-base text-center lg:text-left">
                      {t('questionnaire.signup_description')}
                    </p>
                  </div>
                  <OnboardingAuth />
                </div>
                <div tw="flex-[0.8] w-full">
                  <OnboardingStepList steps={steps} />
                </div>
              </div>
              <TrustBlock />
            </div>
          );
        },
      },
    ],
    [t, messages],
  );
};
