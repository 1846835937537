var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FocusArea } from '../types';
var ImpactStatements = function (_a) {
    var app = _a.app, lang = _a.lang, impact = _a.impact, variant = _a.variant;
    var statements = [];
    if (impact && Object.prototype.hasOwnProperty.call(impact, 'descriptions')) {
        if (Array.isArray(impact.descriptions)) {
            // Only get statements that have 'lang' attribute equal to current language
            if (impact.descriptions.every(function (x) { return 'lang' in x; })) {
                var selectedStatements = impact.descriptions.filter(function (statement) { return (statement.lang === lang ? 1 : 0); });
                if (selectedStatements.length > 0) {
                    // Order statements if the 'orderBy' key is present
                    if (selectedStatements.every(function (x) { return x && 'orderBy' in x; })) {
                        statements = selectedStatements.sort(function (a, b) { return ((a === null || a === void 0 ? void 0 : a.orderBy) && (b === null || b === void 0 ? void 0 : b.orderBy) ? a.orderBy - b.orderBy : 0); });
                    }
                }
                else
                    statements = selectedStatements;
            }
        }
    }
    return (React.createElement(React.Fragment, null, statements.map(function (item, i) { return (React.createElement(ImpactStatement, __assign({ key: i }, item, { app: app, variant: variant }))); })));
};
var getBlogPost = function (lang, img) {
    var url = '';
    if (lang === 'de') {
        if (img === null || img === void 0 ? void 0 : img.includes('CLIMATE'))
            url = 'https://www.grunfin.com/blog/de/posts/der-impact-deines-klimaschutz-portfolios-erklaert/';
        else if (img === null || img === void 0 ? void 0 : img.includes('HEALTH'))
            url = 'https://www.grunfin.com/blog/de/posts/gesundheitsportfolio-der-einfluss-erklaren/';
        else if (img === null || img === void 0 ? void 0 : img.includes('GENDER'))
            url = 'https://www.grunfin.com/blog/de/posts/gleichstellungsportfolio-eine-gleichberechtigtere-welt/';
    }
    else {
        if (img === null || img === void 0 ? void 0 : img.includes('CLIMATE'))
            url = 'https://www.grunfin.com/blog/posts/climate-portfolio-impact/';
        else if (img === null || img === void 0 ? void 0 : img.includes('HEALTH'))
            url = 'https://www.grunfin.com/blog/posts/health-portfolio-impact/';
        else if (img === null || img === void 0 ? void 0 : img.includes('GENDER'))
            url = 'https://www.grunfin.com/blog/posts/gender-equality-portfolio/';
    }
    return url;
};
var statementTextColors = {
    CLIMATE_1: '[&>*_b]:text-alps-blue-700',
    CLIMATE_2: '[&>*_b]:text-forest-green-700',
    CLIMATE_3: '[&>*_b]:text-alps-blue-700',
    HEALTH_1: '[&>*_b]:text-alps-blue-700',
    HEALTH_2: '[&>*_b]:text-sky-blue-700',
    HEALTH_3: '[&>*_b]:text-dusk-700',
    GENDER_1: '[&>*_b]:text-sky-blue-700',
    GENDER_2: '[&>*_b]:text-forest-green-700',
    GENDER_3: '[&>*_b]:text-dawn-700',
    GENERAL_1: '[&>*_b]:text-dusk-700',
    GENERAL_2: '[&>*_b]:text-alps-blue-700',
    GENERAL_3: '[&>*_b]:text-alps-blue-700',
    DEFAULT: '[&>*_b]:text-gray-800',
};
var ImpactStatement = function (props) {
    var _a;
    var _b = useState(''), image = _b[0], setImage = _b[1];
    var imageName = props.image, lang = props.lang, value = props.value, app = props.app, variant = props.variant;
    // TODO: This needs a more sophisticated solution than just using a different implementation for every package
    useEffect(function () {
        if (app === 'landing') {
            if (imageName) {
                (function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response, err_1;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, import("../images/output/".concat(imageName, ".webp"))];
                            case 1:
                                response = _b.sent();
                                if (response.default)
                                    setImage((_a = response.default.src) !== null && _a !== void 0 ? _a : response.default);
                                return [3 /*break*/, 3];
                            case 2:
                                err_1 = _b.sent();
                                setImage('');
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })();
            }
        }
        if (app === 'app') {
            var img = new URL("../images/output/".concat(imageName, ".webp"), import.meta.url).href;
            if (img)
                setImage(img);
        }
    }, [imageName, app]);
    if (!image)
        return null;
    var statementStyle = twMerge('relative p-4 whitespace-pre-line rounded-2xl bg-cover bg-center text-sm flex-1 flex-shrink-0 flex-col gap-4 leading-4 min-w-[250px] ', variant !== null && 'bg-[#FFFFFF32] backdrop-blur-md');
    var statementChildrenStyle = 'flex flex-col gap-3 leading-5 h-full [&>*]:flex [&>*]:flex-col [&>*]:gap-3 [&>*>*]:flex [&_b]:leading-9 [&_b]:flex [&_b]:text-xl';
    var statementTextStyle = twMerge('[&_p]:flex-1 [&_p]:justify-between', variant != null && '[&>*_b]:font-normal', variant === FocusArea.CLIMATE_CHANGE && '[&>*_b]:text-alps-blue-700 text-alps-blue-800', variant === FocusArea.GENDER_EQUALITY && '[&>*_b]:text-gray-700 text-gray-800', variant === FocusArea.HEALTH && '[&>*_b]:text-dusk-700 text-dawn-800', variant === FocusArea.SP500 && '[&>*_b]:text-alps-blue-700 text-alps-blue-800', variant == null &&
        "".concat(statementTextColors[(_a = imageName) !== null && _a !== void 0 ? _a : 'DEFAULT'], " text-gray-900"));
    return (React.createElement(React.Fragment, null,
        React.createElement("a", __assign({ href: getBlogPost(lang, imageName), target: "_blank", rel: "noreferrer", className: statementStyle + ' ' + statementTextStyle }, (variant == null && { style: { backgroundImage: "url(".concat(image, ")") } })),
            React.createElement("div", { className: statementChildrenStyle, dangerouslySetInnerHTML: { __html: value !== null && value !== void 0 ? value : '' } }))));
};
export default ImpactStatements;
