var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgStarGroup = function (props, ref) { return (React.createElement("svg", __assign({ width: 65, height: 12, viewBox: "0 0 65 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 0H0.5V12H12.5V0ZM10.4995 4.90745H7.44458L6.50098 1.99997L5.55443 4.90745L2.49951 4.9045L4.97352 6.70324L4.02697 9.60777L6.50098 7.81198L8.97204 9.60777L8.0452 6.75484L8.02856 6.70329L8.02847 6.70335L8.02844 6.70324L10.4995 4.90745ZM8.02847 6.70335L6.5011 7.81203L8.24087 7.36087L8.0452 6.75484L8.02847 6.70335Z", fill: "currentcolor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M26.5 0H14.5V12H26.5V0ZM24.4995 4.90745H21.4446L20.501 1.99997L19.5544 4.90745L16.4995 4.9045L18.9735 6.70324L18.027 9.60777L20.501 7.81198L22.972 9.60777L22.0452 6.75484L22.0286 6.70329L22.0285 6.70335L22.0284 6.70324L24.4995 4.90745ZM22.0285 6.70335L20.5011 7.81203L22.2409 7.36087L22.0452 6.75484L22.0285 6.70335Z", fill: "currentcolor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M40.5 0H28.5V12H40.5V0ZM38.499 4.90748H35.4441L34.5005 2L33.5539 4.90748L30.499 4.90453L32.973 6.70327L32.0265 9.6078L34.5005 7.81201L36.9716 9.6078L36.0279 6.70327L38.499 4.90748ZM36.24 7.36087L36.0277 6.70329L34.5002 7.81203L36.24 7.36087Z", fill: "currentcolor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M54.5 0H42.5V12H54.5V0ZM52.499 4.90748H49.4441L48.5005 2L47.5539 4.90748L44.499 4.90453L46.973 6.70327L46.0265 9.6078L48.5005 7.81201L50.9716 9.6078L50.0279 6.70327L52.499 4.90748ZM50.24 7.36087L50.0277 6.70329L48.5002 7.81203L50.24 7.36087Z", fill: "currentcolor" }),
    React.createElement("g", { clipPath: "url(#clip0_0_1)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M64.5 12V7.81269L61.2026 9.60777L62.4647 6.70324L59.166 4.9045L63.2392 4.90745L64.5 2.00298V0H56.5V12H64.5Z", fill: "currentcolor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_0_1" },
            React.createElement("rect", { width: 8, height: 12, fill: "currentcolor", transform: "translate(56.5)" }))))); };
var ForwardRef = forwardRef(SvgStarGroup);
var Memo = memo(ForwardRef);
export default Memo;
