import { Button, ButtonVariant, Radio, RadioGroup, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import 'twin.macro';
import { useStartStripeSession, useSubscribePortfolioToDirectDebitConsent } from '~/modules/portfolio/queries';
import { PortfolioDirectDebitResponse } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';

interface DirectDebitPaymentMethodProps {
  portfolioId: string;
  directDebitConsents: UseQueryResult<PortfolioDirectDebitResponse, Error>;
  onNext: () => void;
}

export const DirectDebitPaymentMethod = ({
  portfolioId,
  directDebitConsents,
  onNext,
}: DirectDebitPaymentMethodProps) => {
  const { t } = useTranslation('portfolio');
  const stripeSession = useStartStripeSession(portfolioId);
  const subscribeConsentToPortfolio = useSubscribePortfolioToDirectDebitConsent();
  const [selectedConsentOption, setSelectedConsentOption] = useState<string>();

  useEffect(() => {
    if (directDebitConsents.data) {
      if (directDebitConsents.data.currentlySubscribedConsentId)
        setSelectedConsentOption(directDebitConsents.data.currentlySubscribedConsentId);
    }
  }, [directDebitConsents]);

  if (directDebitConsents.isIdle || directDebitConsents.isLoading) {
    return (
      <div tw="flex items-center justify-center text-alps-blue-700" css={{ minHeight: 220 }}>
        <Spinner width="64" height="64" />
      </div>
    );
  }

  if (directDebitConsents.isError) {
    return (
      <ContactSupportOverlay
        title={t('activation.payment.direct_debit.error')}
        onClose={directDebitConsents.refetch}
        error={directDebitConsents.error}
      />
    );
  }

  const startStripeSession = () => {
    //TODO: add successUrl and cancelUrl from front-end
    stripeSession.mutateAsync({}).then((result) => {
      window.location.href = result.url;
    });
  };

  if (directDebitConsents.data.availableConsents.length == 0) {
    return (
      <div tw="mt-8">
        <div tw="text-lg text-gray-900">{t('activation.payment.direct_debit.description')}</div>
        <div tw="mt-12 mb-4 flex">
          <Button variant={ButtonVariant.NEW_PRIMARY} disabled={!stripeSession.isIdle} onClick={startStripeSession}>
            {(stripeSession.isLoading || stripeSession.isSuccess) && <Spinner />}
            {stripeSession.isIdle && <>{t('activation.payment.direct_debit.proceed')}</>}
          </Button>
        </div>
        <div tw="text-gray-600">{t('activation.payment.direct_debit.disclaimer')}</div>
      </div>
    );
  }

  const submitRadioSelection = () => {
    if (selectedConsentOption === 'new') {
      startStripeSession();
    } else {
      if (selectedConsentOption)
        subscribeConsentToPortfolio.mutateAsync({ portfolioId, consentId: selectedConsentOption }).then(() => onNext());
    }
  };

  if (subscribeConsentToPortfolio.isError) {
    return (
      <ContactSupportOverlay
        title={t('activation.payment.direct_debit.subscribe.error')}
        onClose={directDebitConsents.refetch}
        error={directDebitConsents.error}
      />
    );
  }

  const consentOptions = directDebitConsents.data.availableConsents.map((consent) => ({
    id: consent.id,
    label: consent.nameOnAccount + ' · ' + consent.iban,
  }));

  const availableOptions = [
    ...consentOptions,
    { id: 'new', label: t('activation.payment.direct_debit.subscribe.new') },
  ];

  return (
    <div tw="mt-8">
      <div tw="text-lg text-gray-900 mb-8">{t('activation.payment.direct_debit.subscribe.description')}</div>
      <RadioGroup
        name="past_performance"
        selectedValue={selectedConsentOption}
        onChange={(event) => setSelectedConsentOption(event.target.value)}
      >
        {availableOptions.map((option) => (
          <Radio key={option.id} value={option.id} label={option.label} />
        ))}
      </RadioGroup>

      <div tw="mt-12">
        <Button
          variant={ButtonVariant.NEW_PRIMARY}
          disabled={!stripeSession.isIdle || selectedConsentOption == null}
          onClick={submitRadioSelection}
        >
          {(stripeSession.isLoading || stripeSession.isSuccess) && (
            <div tw="mx-auto">
              <Spinner />
            </div>
          )}
          {stripeSession.isIdle && <>{t('activation.payment.direct_debit.subscribe.submit')}</>}
        </Button>
      </div>
    </div>
  );
};
