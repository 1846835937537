import {
  AllocationPie,
  BeneficiaryType,
  formatCurrency,
  Input,
  Portfolio,
  Select,
  Slider,
  Spinner,
  useDebounce,
  useTranslation,
} from '@grunfin/ui-kit';
import { pick } from 'lodash-es';
import { ReactNode, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import 'twin.macro';

import { setBackendValidationErrors } from '~/utils';
import { trackPlaygroundUpdate } from '~/utils/tracking-analytics';

import { useUpdatePortfolioDraft } from '../queries';
import type { DraftPortfolio } from '../types';
import { PortfolioStatus } from '../types';

interface Props {
  portfolio: Portfolio;
}

export const Playground = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<DraftPortfolio>({
    defaultValues: pick(portfolio, [
      'monthlyInvestmentAmountEur',
      'periodInYears',
      'upfrontInvestmentAmountEur',
      'preferredRisk',
    ]),
    mode: 'onChange',
  });
  const isCompany = portfolio?.beneficiaryType === BeneficiaryType.COMPANY;

  const monthlyInvestmentAmountEur = watch('monthlyInvestmentAmountEur');
  const periodInYears = watch('periodInYears');
  const lessThan3Years = (periodInYears || 0) < 3;
  const upfrontInvestmentAmountEur = watch('upfrontInvestmentAmountEur');
  const preferredRisk = watch('preferredRisk');
  const str = [monthlyInvestmentAmountEur, periodInYears, upfrontInvestmentAmountEur, preferredRisk].join('-');
  const queryKey = useDebounce(str, 500);
  const updateDraft = useUpdatePortfolioDraft(portfolio.id, queryKey);
  const notInitialRender = useRef(false);

  useEffect(() => {
    if (updateDraft.data) reset(updateDraft.data);
  }, [updateDraft.data]);

  useEffect(() => {
    if (queryKey != null && notInitialRender.current) handleSubmit(onSubmit)();
    else notInitialRender.current = true;
  }, [handleSubmit, queryKey]);

  const onSubmit = async (draft: DraftPortfolio) => {
    const noErrors = Object.keys(errors).length === 0;

    if (monthlyInvestmentAmountEur == 0 && portfolio.beneficiaryType !== BeneficiaryType.COMPANY) {
      setError('monthlyInvestmentAmountEur', {
        message: t('playground.monthly_contribution_suggestion'),
        type: 'manual',
      });
    }

    if (periodInYears && periodInYears.toString()?.length > 5) {
      setValue('periodInYears', +periodInYears.toString().substring(0, 5));
    }
    try {
      if (noErrors && portfolio.status === PortfolioStatus.NEW) {
        await updateDraft.mutateAsync(draft);
        trackPlaygroundUpdate(draft);
      }
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  return (
    <div tw="relative grid grid-cols-1 md:grid-cols-12 md:gap-x-16 gap-y-16 mx-6 md:mx-12 md:px-6 py-10 border-b border-gray-100">
      {updateDraft.isLoading && (
        <div tw="absolute bg-white opacity-80 w-full h-full top-0 left-0 z-10 flex justify-center items-center">
          <Spinner />
        </div>
      )}

      <div tw="col-span-4 w-full">
        <AllocationPie
          assets={portfolio.assets}
          bonds={portfolio.idealComposition.BOND.target}
          shares={portfolio.idealComposition.STOCK.target}
        />
      </div>
      <div tw="col-span-8">
        <div tw="flex flex-col gap-4">
          <div tw="flex flex-row items-center flex-wrap gap-4">
            {t('monthly_contribution')}
            <div tw="max-w-sm" style={{ maxWidth: 150 }}>
              <Input
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                suffix="EUR"
                {...register('monthlyInvestmentAmountEur', {
                  required: t('playground.validation.monthly_investment_required', {
                    min: formatCurrency(0),
                  }) as string,
                })}
                error={'monthlyInvestmentAmountEur' in errors}
              />
            </div>
            {errors.monthlyInvestmentAmountEur && (
              <div tw="text-sm text-dawn-300 col-span-full">{errors.monthlyInvestmentAmountEur.message}</div>
            )}
          </div>
          <Slider
            min={0}
            max={isCompany ? 5000 : 1000}
            stepSize={isCompany ? 100 : 50}
            ticks={isCompany ? [0, 1000, 2000, 3000, 4000, 5000] : [0, 200, 400, 600, 800, 1000]}
            values={[monthlyInvestmentAmountEur ?? 0]}
            setValues={([min]) => setValue('monthlyInvestmentAmountEur', min)}
          />
        </div>
        <div tw="grid grid-cols-1 md:grid-cols-3 pt-20 gap-x-6 gap-y-4">
          <PlaygroundField>
            {t('risk_preference')} <br />
            <Select {...register('preferredRisk', { required: true })} error={'preferredRisk' in errors}>
              <option value="VERY_HIGH" disabled={lessThan3Years}>
                {t('risk.very_high')}
              </option>
              <option value="HIGH" disabled={lessThan3Years}>
                {t('risk.high')}
              </option>
              <option value="MEDIUM" disabled={lessThan3Years}>
                {t('risk.medium')}
              </option>
              <option value="LOW">{t('risk.low')}</option>
            </Select>
            {lessThan3Years && (
              <div tw="md:absolute top-full text-sm text-dawn-300 mt-2 col-span-full">
                {t('playground.low_risk_period')}
              </div>
            )}
          </PlaygroundField>
          <PlaygroundField>
            {t('period')} <br />
            <Input
              {...register('periodInYears', {
                required: true,
                validate: {
                  mustBeGreaterThanOne: (v) => (v && v >= 1) || (t('playground.validation.period_short') as string),
                  mustBeFullYear: (v) => (v && v % 1 === 0) || (t('playground.validation.period_fractional') as string),
                },
              })}
              inputMode="numeric"
              pattern="[0-9]*"
              type="number"
              suffix="years"
              error={'periodInYears' in errors}
            />
            {errors.periodInYears && (
              <div tw="md:absolute top-full text-sm text-dawn-300 mt-2 col-span-full">
                {errors.periodInYears.message}
              </div>
            )}
          </PlaygroundField>
          <PlaygroundField>
            {t('initial_investment')}
            <Input
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              suffix="EUR"
              {...register('upfrontInvestmentAmountEur', {
                required: t('playground.validation.initial_investment_required', {
                  min: formatCurrency(0),
                }) as string,
              })}
              error={'upfrontInvestmentAmountEur' in errors}
            />
            {errors.upfrontInvestmentAmountEur && (
              <div tw="md:absolute top-full text-sm text-dawn-300 mt-2 col-span-full">
                {errors.upfrontInvestmentAmountEur.message}
              </div>
            )}
          </PlaygroundField>
        </div>
      </div>
    </div>
  );
};

const PlaygroundField = ({ children }: { children: ReactNode }) => {
  return <div tw="relative grid grid-cols-2 gap-y-2 md:grid-cols-1 items-center">{children}</div>;
};
