import 'twin.macro';
import { Checkbox, CheckboxGroup, InfoTooltip, Trans, useTranslation } from '@grunfin/ui-kit';

interface Props {
  value: string[];
  setValue: (value: string[]) => void;
}

export const FocusAreas = ({ value, setValue }: Props) => {
  const { t } = useTranslation('onboarding');
  return (
    <>
      <div tw="text-xl mb-6">
        <Trans
          i18nKey="questions.portfolio.areas.title"
          t={t}
          components={[<span key="0" tw="text-alps-blue-400" />]}
        />
      </div>
      <div tw="col-span-9 text-gray-400 text-sm md:text-base mb-12">
        <Trans i18nKey="questions.portfolio.areas.description" t={t} components={[<b key="0" />]} />
      </div>

      <CheckboxGroup value={value} onChange={setValue}>
        <Checkbox
          name="sp500"
          label={
            <div tw="flex flex-row items-center gap-3">
              {t('questions.portfolio.areas.sp500.label')}
              <InfoTooltip>
                <Trans
                  i18nKey="questions.portfolio.areas.sp500.tooltip"
                  t={t}
                  components={[
                    <a
                      key="0"
                      tw="underline"
                      href={t('questions.portfolio.areas.sp500.link')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </InfoTooltip>
            </div>
          }
        />
        <div tw="my-4" />
        <Checkbox
          name="climate_change"
          label={
            <div tw="flex flex-row items-center gap-3">
              {t('questions.portfolio.areas.climate_change.label')}
              <InfoTooltip>
                <Trans
                  i18nKey="questions.portfolio.areas.climate_change.tooltip"
                  t={t}
                  components={[
                    <a
                      key="0"
                      tw="underline"
                      href={t('questions.portfolio.areas.climate_change.link')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </InfoTooltip>
            </div>
          }
        />
        <div tw="my-4" />
        <Checkbox
          name="gender_equality"
          label={
            <div tw="flex flex-row items-center gap-3">
              {t('questions.portfolio.areas.gender_equality.label')}
              <InfoTooltip>
                <Trans
                  i18nKey="questions.portfolio.areas.gender_equality.tooltip"
                  t={t}
                  components={[
                    <a
                      key="0"
                      tw="underline"
                      href={t('questions.portfolio.areas.gender_equality.link')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </InfoTooltip>
            </div>
          }
        />
        <div tw="my-4" />
        <Checkbox
          name="health"
          label={
            <div tw="flex flex-row items-center gap-3">
              {t('questions.portfolio.areas.health.label')}
              <InfoTooltip>
                <Trans
                  i18nKey="questions.portfolio.areas.health.tooltip"
                  t={t}
                  components={[
                    <a
                      key="0"
                      tw="underline"
                      href={t('questions.portfolio.areas.health.link')}
                      target="_blank"
                      rel="noreferrer"
                    />,
                  ]}
                />
              </InfoTooltip>
            </div>
          }
        />
      </CheckboxGroup>
    </>
  );
};
