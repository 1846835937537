import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { ApplicationBaseNavigation, Navigation, useNavigationContext } from './Navigation';
import 'twin.macro';
import { useEffect } from 'react';

export const MainLayout = () => {
  const navState = useNavigationContext();

  // Set the navigation for all children components
  // MainLayout is used to paint the dashboard navigation for retail product
  useEffect(() => {
    navState.setCustomActions(() => <ApplicationBaseNavigation />);
  }, []);

  return (
    <div tw="flex flex-col min-h-full">
      <Navigation />
      <div tw="flex flex-col flex-grow w-full my-12 md:my-24 max-w-screen-lg mx-auto">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
