import { useEffect } from 'react';
import type { UseFormSetError } from 'react-hook-form';
import Papa from 'papaparse';

import { ValidationError } from '../api';
import { captureException } from '@sentry/react';
import { formatCurrency } from '@grunfin/ui-kit';

export const isDev = import.meta.env.DEV;
const DEFAULT_TITLE = 'Grünfin';

export const useDocumentTitle = (title?: string | string[]) => {
  useEffect(() => {
    const titleParts = Array.isArray(title) ? title : [title];
    titleParts.push(DEFAULT_TITLE);
    document.title = [...titleParts.filter((p) => !!p)].join(' - ');
  }, [title]);

  useEffect(() => {
    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, []);
};

/**
 * Map validation errors from validation to form errors
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setBackendValidationErrors = (error: Error, setError: UseFormSetError<any>, prefix?: string) => {
  if (!(error instanceof ValidationError)) return;
  error.fields.forEach((f) => {
    const key = prefix ? `${prefix}.${f.fieldKey}` : f.fieldKey;
    setError(key, { type: 'backend', message: f.errorMessage }, { shouldFocus: true });
  });
};

export const createAndDownloadCSVFile = async (data: object[], fileName: string, headers?: string[]) => {
  try {
    // get all object keys to set as headers -- technically by using rows[0] we assume that all objects in the array have same keys
    const fields = data.length > 0 ? Object.keys(data[0]) : headers ?? [];
    const csv = Papa.unparse({ data, fields });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    captureException(err);
  }
};

export const formatCompanyPayment = (value: string | number) => {
  let val = value;
  if (typeof val === 'string') {
    const int = parseFloat(val);
    if (!isNaN(int)) val = int;
  }
  return formatCurrency(val as number, { digits: 2 });
};

type ConditonalWrapperProps = {
  children: React.ReactElement;
  condition: boolean;
  wrap: (children: React.ReactElement) => JSX.Element;
};
export const ConditonalWrapper: React.FC<ConditonalWrapperProps> = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;
