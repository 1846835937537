import { Outlet } from 'react-router-dom';
import 'twin.macro';

import { Footer } from './Footer';
import { Navigation } from './Navigation';

export const HeaderlessLayout = () => {
  return (
    <div tw="flex flex-col min-h-full">
      <Navigation />
      <div tw="flex flex-col flex-grow w-full my-12 md:my-24 max-w-screen-lg mx-auto">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
