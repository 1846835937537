import { Dialog, LanguagePicker } from '@grunfin/ui-kit';
import { cloneElement, ReactNode } from 'react';
import 'twin.macro';

import { useNavigationContext } from './Context';

/**
 * Right side action buttons with icons
 */
export const NavigationActions = () => {
  const { customActions } = useNavigationContext();
  const actions = customActions();

  return <ActionListWithNavigationBurger>{actions}</ActionListWithNavigationBurger>;
};

export const ActionList = ({ children }: { children: ReactNode }) => (
  <ol tw="flex flex-row flex-wrap gap-4 md:gap-6">{children}</ol>
);

export const ActionListWithNavigationBurger = ({ children }: { children: ReactNode }) => {
  if (!children) return null;
  return (
    <div>
      <div tw="hidden lg:block">
        <ActionList>{children}</ActionList>
      </div>
      <div tw="block lg:hidden">
        <Dialog
          render={({ close }) => (
            <div tw="flex flex-col w-full p-4 gap-8 [& ol]:flex-col [& ol li]:min-w-[200px]">
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  // check if the clicked element was a link element, then close the dialog
                  if (e.target instanceof HTMLAnchorElement) close();
                }}
              >
                <ActionList>{children}</ActionList>
              </div>
              <div tw="flex justify-center">
                <LanguagePicker />
              </div>
            </div>
          )}
        >
          <button type="button" tw="flex flex-col justify-between lg:hidden w-7 h-6 p-[6px] rounded shadow">
            <span tw="flex bg-alps-blue-700 w-full h-[2px]"></span>
            <span tw="flex bg-alps-blue-700 w-full h-[2px]"></span>
            <span tw="flex bg-alps-blue-700 w-full h-[2px]"></span>
          </button>
        </Dialog>
      </div>
    </div>
  );
};

export const ActionListItem = (props: any) => cloneElement(<li tw="list-none">{props.children}</li>, { ...props });
