var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgFinancefwdIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", viewBox: "0 0 196 32", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("g", { clipPath: "url(#a)" },
        React.createElement("path", { d: "m85.602 15.3h-4.0579v-15.028h7.1742v3.533h-3.1163v2.1901h2.9002v3.396h-2.9002v5.9084z", fill: "#8A8987" }),
        React.createElement("path", { d: "m94.11 0.2721h-4.0578v15.028h4.0578v-15.028z", fill: "#8A8987" }),
        React.createElement("path", { d: "m95.549 15.3v-15.028h3.8746l2.0341 5.2754c0.109 0.31002 0.234 0.66699 0.374 1.0709 0.139 0.40135 0.293 0.86638 0.463 1.3912l0.419 1.3237c-0.151-1.3044-0.266-2.4203-0.348-3.3478-0.081-0.92748-0.119-1.7064-0.115-2.3367v-3.3767h3.878v15.028h-3.872l-2.046-5.5282c-0.2134-0.59045-0.4063-1.1436-0.5787-1.6594s-0.3229-1.0072-0.4515-1.4742c0.0823 1.0574 0.1434 1.9784 0.1833 2.7631 0.0399 0.78471 0.0598 1.4729 0.0598 2.0646v3.8341h-3.8746z", fill: "#8A8987" }),
        React.createElement("path", { d: "m113.46 10.171-0.263-1.7521c-0.019-0.08642-0.032-0.17412-0.038-0.26242-0.013-0.14279-0.081-0.70751-0.205-1.6942-0.013-0.10999-0.029-0.2605-0.048-0.45152-0.02-0.19103-0.047-0.43416-0.079-0.72552l-0.126-1.4221c-0.013 0.137-0.031 0.28944-0.054 0.45731s-0.048 0.35504-0.073 0.55572c-0.044 0.40135-0.077 0.68114-0.097 0.83743-0.019 0.1563-0.032 0.24892-0.04 0.28172l-0.282 1.8196-0.409 2.356h1.714zm4.974 5.1288h-4.195l-0.299-1.9084h-2.738l-0.332 1.9084h-4.195l3.612-15.028h4.963l3.184 15.028z", fill: "#8A8987" }),
        React.createElement("path", { d: "m118.99 15.3v-15.028h3.872l2.036 5.2754c0.111 0.31002 0.236 0.66699 0.374 1.0709 0.139 0.40135 0.294 0.86638 0.462 1.3912l0.418 1.3237c-0.148-1.3044-0.261-2.4203-0.339-3.3478-0.079-0.92748-0.118-1.7064-0.118-2.3367v-3.3767h3.878v15.028h-3.878l-2.043-5.5282c-0.215-0.59045-0.408-1.1436-0.579-1.6594s-0.32-1.0072-0.446-1.4742c0.085 1.0574 0.149 1.9784 0.193 2.7631 0.044 0.78471 0.063 1.4729 0.058 2.0646v3.8341h-3.888z", fill: "#8A8987" }),
        React.createElement("path", { d: "m139.24 10.676-0.234 4.3416c-0.766 0.3334-1.588 0.518-2.423 0.5441-0.799 0.0144-1.591-0.1432-2.324-0.4621-0.732-0.319-1.388-0.7918-1.921-1.3864-1.287-1.3816-1.93-3.3382-1.93-5.8698 0-2.3682 0.604-4.2688 1.81-5.7019 1.207-1.433 2.819-2.147 4.838-2.1418 0.686 0.0050611 1.367 0.12978 2.01 0.36855v4.2875c-0.603-0.6046-1.223-0.90625-1.86-0.90497-0.389-0.01017-0.774 0.08261-1.116 0.26896s-0.628 0.45967-0.831 0.79231c-0.467 0.71394-0.7 1.7-0.7 2.958-6e-3 0.5498 0.053 1.0984 0.175 1.6344 0.097 0.43144 0.267 0.84324 0.502 1.2175 0.201 0.3153 0.477 0.5758 0.803 0.7583 0.335 0.1801 0.71 0.2711 1.09 0.2644 0.7 0 1.403-0.3216 2.111-0.9648", fill: "#8A8987" }),
        React.createElement("path", { d: "m140.2 15.3v-15.028h7.542v3.533h-3.484v2.1901h3.124v3.396h-3.124v2.2982h3.484v3.6102h-7.542z", fill: "#8A8987" }),
        React.createElement("path", { d: "m48.228 32v-31.73h31.73v31.73h-31.73zm1.6963-1.588h6.302v-22.258h7.0796v-6.2981h-13.382v28.556zm21.254 0h-6.2981v-28.556h13.384l-2e-3 6.2943-7.0834 0.00386v22.258zm1.5744-19.788 5.673 5.5109-5.673 5.5108v-11.022zm-14.956 0 5.6729 5.5109-5.6729 5.5108v-11.022z", clipRule: "evenodd", fill: "#8A8987", fillRule: "evenodd" }),
        React.createElement("path", { d: "m85.602 31.998h-4.0579v-15.028h7.1742v3.533h-3.1163v2.1901h2.9021v3.396h-2.9021v5.9084z", fill: "#8A8987" }),
        React.createElement("path", { d: "m103.35 31.998h-3.9127l-0.9262-5.2658c-0.1814-1.0188-0.3177-1.9013-0.4091-2.6474-0.0443-0.3705-0.0829-0.6792-0.1157-0.9243-0.0328-0.245-0.056-0.436-0.0676-0.5653-0.0328 0.2663-0.0829 0.7718-0.1466 1.4896-0.0328 0.3705-0.0714 0.7583-0.1177 1.1674s-0.1004 0.8413-0.164 1.2947l-0.7719 5.4511h-3.9575l-3.3478-15.028h4.0096l0.6619 4.1351c0.2572 1.6491 0.4328 2.9438 0.5267 3.8842l0.1351 1.3719 0.1274-1.177c0.1286-1.3752 0.3035-2.6828 0.5248-3.9229l0.7603-4.2913h3.4925l0.6835 3.6102c0.324 1.68 0.605 3.6688 0.845 5.9662 0.072-1.0291 0.15-1.9437 0.233-2.7438 0.084-0.8001 0.178-1.5224 0.284-2.1669l0.739-4.6619h3.961l-3.047 15.024z", fill: "#8A8987" }),
        React.createElement("path", { d: "m111.12 28.367c1.454-0.072 2.18-1.3243 2.179-3.7569 0-1.2001-0.185-2.1476-0.554-2.8422-0.369-0.6947-0.869-1.0446-1.498-1.0497h-0.125l-2e-3 7.6488zm-4.052 3.6315v-15.028h3.504c2.006 0 3.566 0.5383 4.681 1.615 1.467 1.4086 2.2 3.3781 2.2 5.9084 0 2.4917-0.619 4.4155-1.858 5.7713-1.058 1.1578-2.635 1.7366-4.732 1.7366l-3.795-0.0038z", fill: "#8A8987" }),
        React.createElement("path", { d: "m124.95 19.606c0.191-0.0364 0.385-0.0538 0.579-0.0521 0.24-0.0225 0.48 0.0411 0.677 0.1794 0.068 0.0504 0.122 0.1164 0.158 0.1924s0.052 0.1597 0.049 0.2437c-3e-3 0.1232-0.046 0.2422-0.121 0.3397s-0.18 0.1685-0.298 0.2025c0.143 0.0289 0.272 0.1063 0.365 0.2191s0.144 0.2542 0.144 0.4003c2e-3 0.0923-0.016 0.1839-0.052 0.2689-0.036 0.0851-0.089 0.1617-0.156 0.2251-0.174 0.1582-0.452 0.2315-0.855 0.2315-0.165 8e-4 -0.33-0.0089-0.494-0.0289l4e-3 -2.4216zm0.318 1.0111h0.29c0.338 0 0.534-0.1756 0.534-0.4149 0-0.2894-0.22-0.4033-0.542-0.4033-0.094-0.0025-0.189 0.0053-0.282 0.0232v0.795zm0 1.1828c0.089 0.0126 0.179 0.0177 0.269 0.0154 0.33 0 0.633-0.1215 0.633-0.4804s-0.29-0.4766-0.637-0.4766h-0.265v0.9416z", fill: "#8A8987" }),
        React.createElement("path", { d: "m127.34 21.266-0.257 0.7718h-0.33l0.84-2.4717h0.386l0.843 2.4717h-0.342l-0.264-0.7718h-0.876zm0.81-0.2489-0.241-0.712c-0.056-0.1602-0.092-0.3068-0.129-0.4496-0.037 0.1466-0.075 0.2971-0.124 0.4457l-0.241 0.7159h0.735z", fill: "#8A8987" }),
        React.createElement("path", { d: "m130.86 21.962c-0.207 0.0845-0.429 0.1246-0.653 0.1177-0.696 0-1.221-0.44-1.221-1.2504-0.01-0.1723 0.017-0.3448 0.078-0.5061 0.062-0.1614 0.156-0.308 0.278-0.4303s0.268-0.2176 0.429-0.2797 0.333-0.0895 0.506-0.0806c0.199-0.0075 0.397 0.0301 0.579 0.11l-0.077 0.2586c-0.157-0.07-0.327-0.1049-0.498-0.1023-0.579 0-0.965 0.3705-0.965 1.0188 0 0.6059 0.349 0.9937 0.949 0.9937 0.179 0.0024 0.357-0.0324 0.521-0.1022l0.074 0.2528z", fill: "#8A8987" }),
        React.createElement("path", { d: "m131.25 19.572h0.318v1.1924h0.012c0.065-0.0964 0.133-0.1929 0.193-0.2643l0.754-0.9281h0.396l-0.894 1.0496 0.965 1.4221h-0.376l-0.814-1.2137-0.236 0.2702v0.9435h-0.318v-2.4717z", fill: "#8A8987" }),
        React.createElement("path", { d: "m134.52 20.884h-0.965v0.8914h1.071v0.2682h-1.387v-2.4717h1.333v0.2682h-1.015v0.7718h0.965l-2e-3 0.2721z", fill: "#8A8987" }),
        React.createElement("path", { d: "m135.04 19.606c0.225-0.0349 0.452-0.0523 0.679-0.0521 0.36-0.0289 0.718 0.0808 1 0.3068 0.118 0.114 0.211 0.2524 0.272 0.4056 0.06 0.1532 0.086 0.3177 0.077 0.482 9e-3 0.1766-0.018 0.3532-0.08 0.519-0.061 0.1659-0.155 0.3175-0.277 0.4458-0.304 0.2562-0.696 0.3836-1.092 0.3551-0.194 6e-4 -0.387-9e-3 -0.579-0.029v-2.4332zm0.318 2.1843c0.107 0.0139 0.215 0.0197 0.323 0.0174 0.681 0 1.051-0.3859 1.051-1.0478 0-0.5789-0.326-0.9532-1.001-0.9532-0.125-0.0015-0.25 0.0094-0.373 0.0328v1.9508z", fill: "#8A8987" }),
        React.createElement("path", { d: "m138.26 19.606c0.191-0.0364 0.385-0.0538 0.579-0.0521 0.24-0.0227 0.48 0.041 0.677 0.1794 0.068 0.0504 0.122 0.1164 0.158 0.1924s0.053 0.1597 0.049 0.2437c-3e-3 0.1232-0.045 0.2422-0.121 0.3397-0.075 0.0975-0.179 0.1685-0.298 0.2025 0.143 0.0289 0.272 0.1063 0.365 0.2191s0.144 0.2542 0.145 0.4003c3e-3 0.0926-0.014 0.1847-0.049 0.2705s-0.087 0.1632-0.154 0.2274c-0.172 0.1582-0.451 0.2315-0.853 0.2315-0.166 7e-4 -0.331-9e-3 -0.496-0.0289l-2e-3 -2.4255zm0.319 1.0111h0.289c0.338 0 0.537-0.1756 0.537-0.4149 0-0.2894-0.22-0.4033-0.543-0.4033-0.095-0.0025-0.19 0.0053-0.283 0.0232v0.795zm0 1.1828c0.089 0.0125 0.178 0.0177 0.268 0.0154 0.33 0 0.635-0.1215 0.635-0.4804s-0.29-0.4766-0.639-0.4766h-0.264v0.9416z", fill: "#8A8987" }),
        React.createElement("path", { d: "m140.74 22.043v-1.0497l-0.772-1.422h0.362l0.348 0.683c0.094 0.193 0.168 0.3358 0.245 0.5094 0.069-0.1621 0.154-0.3222 0.249-0.5094l0.361-0.683h0.362l-0.829 1.4201v1.0516h-0.326z", fill: "#8A8987" }),
        React.createElement("path", { d: "m147.77 27.303v-3.0583c2e-3 -0.0562-8e-3 -0.1121-0.029-0.1643s-0.052-0.0997-0.092-0.1395c-0.04-0.0399-0.087-0.0713-0.139-0.0923-0.052-0.0211-0.108-0.0314-0.164-0.0304h-7.776c-0.057-1e-3 -0.112 0.0093-0.165 0.0304-0.052 0.021-0.099 0.0524-0.139 0.0923-0.039 0.0398-0.07 0.0873-0.091 0.1395s-0.031 0.1081-0.03 0.1643v3.0583c-1e-3 0.0561 9e-3 0.1119 0.03 0.164s0.052 0.0994 0.092 0.1391c0.039 0.0397 0.087 0.0709 0.139 0.0918s0.108 0.0309 0.164 0.0296h6.404v1.3758l1.048-1.3758h0.333c0.057 0.0013 0.112-0.0087 0.164-0.0296 0.053-0.0209 0.1-0.0521 0.14-0.0918 0.039-0.0397 0.07-0.087 0.091-0.1391s0.031-0.1079 0.03-0.164", fill: "#8A8987" }),
        React.createElement("path", { d: "m141.71 25.742c0 0.1822-0.073 0.3569-0.201 0.4858-0.129 0.1288-0.304 0.2012-0.486 0.2012s-0.357-0.0724-0.486-0.2012c-0.129-0.1289-0.201-0.3036-0.201-0.4858-2e-3 -0.0898 0.014-0.1792 0.047-0.2627 0.033-0.0836 0.083-0.1596 0.146-0.2235 0.096-0.0954 0.219-0.1601 0.352-0.1862s0.271-0.0123 0.396 0.0397c0.125 0.0519 0.232 0.1396 0.308 0.2522 0.076 0.1125 0.116 0.2449 0.117 0.3805h8e-3zm0.114 0.8085c0.185-0.187 0.299-0.4324 0.324-0.6945 0.024-0.262-0.042-0.5244-0.19-0.7426-0.147-0.2182-0.365-0.3787-0.617-0.4542s-0.522-0.0614-0.765 0.04c-0.243 0.1015-0.443 0.2839-0.567 0.5163-0.123 0.2323-0.163 0.5003-0.111 0.7583 0.052 0.2581 0.192 0.4902 0.395 0.6569 0.204 0.1667 0.459 0.2577 0.722 0.2575 0.151 0.0018 0.3-0.0273 0.439-0.0853 0.139-0.0581 0.265-0.144 0.37-0.2524zm2.493 0.3184h0.474v-2.2537l-1.157 1.424-1.158-1.424v2.2537h0.476v-0.9494l0.68 0.9108 0.681-0.9108 4e-3 0.9494zm1.7-1.1577 1.001-1.098h-1.943v0.4515h0.924l-0.635 0.6465 1.021 1.1577h0.658l-1.026-1.1577z", fill: "#fff" }),
        React.createElement("path", { d: "m128.94 25.231v0.1505h0.391v2.4814h-0.38v0.1524h1.592v-0.1524h-0.386v-2.6319h-1.217z", fill: "#8A8987" }),
        React.createElement("path", { d: "m135.44 23.628v0.1505h0.392v4.2373h1.206v-0.1524h-0.386v-4.2354h-1.212z", fill: "#8A8987" }),
        React.createElement("path", { d: "m133.9 26.616c-0.79 0-1.299 0.1582-1.299 0.7603 0 0.5499 0.409 0.6965 0.838 0.6965 0.152 0.0163 0.305-0.0149 0.439-0.0891 0.134-0.0743 0.242-0.188 0.309-0.3257 0.058 0.274 0.274 0.4148 0.668 0.4148 0.455 0 0.648-0.2219 0.677-0.8663l-0.133-0.0116c-0.043 0.4689-0.118 0.5615-0.235 0.5615-0.118 0-0.182-0.0811-0.182-0.328v-1.1578c0-0.849-0.32-1.1056-1.134-1.1056-0.668 0-1.118 0.2392-1.118 0.6965-6e-3 0.056 1e-3 0.1127 0.019 0.1659 0.018 0.0533 0.048 0.102 0.087 0.1427 0.039 0.0406 0.086 0.0722 0.138 0.0926 0.053 0.0203 0.109 0.029 0.165 0.0253 0.054 0.0048 0.108-0.0016 0.159-0.0188s0.097-0.0449 0.137-0.0812 0.071-0.0805 0.093-0.1298c0.021-0.0492 0.032-0.1024 0.032-0.1561 0-0.0839-0.015-0.1671-0.046-0.2451h-0.158c-0.011-0.0383-0.017-0.0779-0.018-0.1177 0-0.1582 0.164-0.2277 0.369-0.2277 0.357 0 0.457 0.2393 0.457 0.878v0.4264h-0.264zm-0.164 1.206c-0.193 0-0.28-0.1177-0.28-0.4631 0-0.3859 0.152-0.5962 0.579-0.5962h0.135v0.4862c0 0.3608-0.216 0.5789-0.429 0.5789", fill: "#8A8987" }),
        React.createElement("path", { d: "m129.22 24.276c0 0.1249 0.05 0.2447 0.138 0.333 0.088 0.0882 0.208 0.1379 0.333 0.1379s0.245-0.0497 0.333-0.1379c0.088-0.0883 0.138-0.2081 0.138-0.333 6e-3 -0.0614-3e-3 -0.1233-0.024-0.1811-0.022-0.0579-0.056-0.1101-0.101-0.1528-0.044-0.0427-0.098-0.0747-0.156-0.0937-0.059-0.0191-0.121-0.0246-0.182-0.0162-0.062-0.0073-0.125-0.0011-0.184 0.0181s-0.113 0.051-0.158 0.0932c-0.046 0.0422-0.082 0.0938-0.105 0.1512-0.024 0.0575-0.035 0.1193-0.032 0.1813z", fill: "#8A8987" }),
        React.createElement("path", { d: "m127.36 25.422c0.386 0 0.475 0.3222 0.475 1.2002 0 0.9185-0.112 1.2581-0.509 1.2581-0.398 0-0.579-0.357-0.579-0.6966v-0.9358c0-0.5037 0.316-0.8259 0.619-0.8259h-6e-3zm-0.613-0.1929h-1.206v0.1524h0.386v3.5678h-0.408v0.1524h1.99v-0.1524h-0.755v-1.2099c0.135 0.2046 0.352 0.3338 0.755 0.3338 0.772 0 1.2-0.4804 1.2-1.4626 0-0.9821-0.411-1.4394-1.082-1.4394-0.18-0.0106-0.359 0.0315-0.514 0.1209-0.156 0.0895-0.283 0.2224-0.364 0.3827l-2e-3 -0.4457z", fill: "#8A8987" }),
        React.createElement("path", { d: "m124.04 26.616c-0.791 0-1.298 0.1582-1.298 0.7603 0 0.5499 0.409 0.6965 0.835 0.6965 0.152 0.0163 0.306-0.0149 0.44-0.0891 0.134-0.0743 0.242-0.188 0.309-0.3257 0.06 0.274 0.276 0.4148 0.667 0.4148 0.458 0 0.651-0.2219 0.68-0.8663l-0.135-0.0116c-0.041 0.4689-0.118 0.5615-0.234 0.5615s-0.181-0.0811-0.181-0.328v-1.1578c0-0.849-0.323-1.1056-1.135-1.1056-0.668 0-1.119 0.2392-1.119 0.6965-6e-3 0.0562 0 0.113 0.018 0.1664 0.019 0.0535 0.049 0.1023 0.088 0.1429 0.039 0.0407 0.087 0.0723 0.139 0.0925 0.053 0.0203 0.11 0.0287 0.166 0.0247 0.053 0.0048 0.107-0.0016 0.158-0.0188s0.098-0.0449 0.138-0.0812c0.039-0.0363 0.071-0.0805 0.092-0.1298 0.022-0.0492 0.033-0.1024 0.033-0.1561 0-0.0839-0.016-0.1671-0.047-0.2451h-0.158c-0.011-0.0383-0.017-0.0779-0.017-0.1177 0-0.1582 0.164-0.2277 0.368-0.2277 0.357 0 0.456 0.2393 0.456 0.878v0.4264h-0.263zm-0.164 1.206c-0.193 0-0.282-0.1177-0.282-0.4631 0-0.3859 0.153-0.5962 0.579-0.5962h0.133v0.4862c0 0.3608-0.216 0.5789-0.426 0.5789", fill: "#8A8987" }),
        React.createElement("path", { d: "m121.08 24.037c0.579 0 0.938 0.3975 1.158 1.123h0.129l-0.048-1.2407h-0.081c-0.029 0.0771-0.052 0.1177-0.141 0.1177-0.193 0-0.526-0.1814-1.065-0.1814-1.134 0-1.96 0.7371-1.96 2.136 0 1.4453 0.75 2.082 1.943 2.082 0.579 0 0.941-0.1814 1.129-0.1814 0.083 0 0.117 0.0406 0.146 0.1177h0.089l0.041-1.2754h-0.124c-0.239 0.7487-0.631 1.1577-1.187 1.1577-0.743 0-1.105-0.6676-1.105-1.9295 0-1.4144 0.386-1.918 1.074-1.918", fill: "#8A8987" }),
        React.createElement("path", { d: "m131.77 27.302v-1.9296h0.784v-0.1524h-0.784v-0.9648l-0.831 0.2103v0.7545h-0.386v0.1524h0.386v1.9585c1e-3 0.1064 0.013 0.2124 0.034 0.3165 0.058 0.274 0.274 0.4149 0.668 0.4149 0.455 0 0.648-0.222 0.677-0.8664l-0.133-0.0116c-0.042 0.4689-0.118 0.5615-0.235 0.5615-0.118 0-0.182-0.081-0.182-0.3261v-0.1273l2e-3 0.0096z", fill: "#8A8987" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "a" },
            React.createElement("rect", { transform: "translate(48.228)", width: 99.543, height: 32, fill: "#fff" }))))); };
var ForwardRef = forwardRef(SvgFinancefwdIcon);
var Memo = memo(ForwardRef);
export default Memo;
