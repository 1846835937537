import { Button, ButtonSize, ButtonVariant, PowerIcon, Spinner, Trans, useTranslation } from '@grunfin/ui-kit';
import { Fragment, ReactNode, useCallback, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import 'twin.macro';

import { useNavigationContext } from '~/modules/application/Navigation';
import { useSession } from '~/modules/auth/SessionProvider';
import { PortfolioItem } from '~/modules/portfolio/PortfolioItem';
import { useListPortfolios } from '~/modules/portfolio/queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { useDocumentTitle } from '~/utils';
import { trackPortfolioActivation } from '~/utils/tracking-analytics';

export function ResultView() {
  const { t } = useTranslation('onboarding');
  const navState = useNavigationContext();
  const session = useSession();
  const portfolios = useListPortfolios({ enabled: session.authenticated });
  const portfolio = portfolios.data?.[0];

  useDocumentTitle(t('result.document_title'));

  useEffect(() => {
    navState.setTitle(t('result.navigation_title'));
    document.body.style.setProperty('--app-container-width', 'auto');

    return () => {
      document.body.style.setProperty('--app-container-width', null);
    };
  }, []);

  const getActivationUrl = useCallback(
    (id: string) => {
      const path = `/portfolio/${id}/activate`;
      return session.authenticated ? path : `/signup?next=${path}`;
    },
    [session.authenticated],
  );

  const createActivateButton = (title?: string | ReactNode) => {
    if (!portfolio?.id) return null;
    return (
      <Link
        to={getActivationUrl(portfolio.id)}
        onClick={() => trackPortfolioActivation(portfolio.id)}
        data-test-id="portfolio-activate"
      >
        <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
          {title ?? t('result.activate')}
        </Button>
      </Link>
    );
  };

  if (portfolios.isIdle || portfolios.isLoading)
    return (
      <div tw="mx-auto w-max">
        <Spinner />
      </div>
    );

  if (portfolios.isError) {
    return <ContactSupportOverlay title={t('result.error')} onClose={portfolios.refetch} error={portfolios.error} />;
  }

  if (!portfolio) {
    (async () => {
      const value = await portfolios.refetch();
      if (Array.isArray(value.data) && value.data.length <= 0)
        return <Navigate to={session.portfolioCount === 0 ? '/onboarding' : '/'} />;
    })();

    return null;
  }

  return (
    <div tw="flex flex-col gap-8">
      <div tw="flex flex-row flex-wrap gap-4 justify-between items-end px-4">
        <h2 tw="text-gray-900 text-lg lg:text-xl lg:mr-4 sm:flex-1">
          <Trans i18nKey="result.preview" components={[<span key="0" tw="text-alps-blue-400" />]} t={t} />
        </h2>
        {createActivateButton(
          <Fragment>
            <PowerIcon className="text-white mr-2" /> {t('result.activate_portfolio')}
          </Fragment>,
        )}
      </div>
      <div tw="max-w-screen-lg mx-auto relative">
        <PortfolioItem onboarding portfolio={portfolio} />
        <div tw="flex flex-row flex-wrap items-center gap-4 justify-around px-4">
          {createActivateButton(t('result.activate_portfolio'))}
          <p tw="text-gray-300 text-base text-center w-full">
            <Trans
              i18nKey="result.disclaimer"
              components={[
                <a key="0" tw="underline" target="_blank" href={t('result.disclaimer_link')} rel="noopener" />,
              ]}
              t={t}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
