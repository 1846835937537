import {
  AlertNotice,
  Button,
  ButtonVariant,
  DisplayError,
  Field,
  InfoIcon,
  InputDatePicker,
  PortfolioAssetOwner,
  UploadIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import 'twin.macro';
import { createAndDownloadCSVFile, useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '../application/Navigation';
import { AssetsTable } from '../portfolio/components/AssetsTable';
import { getCompanyAssets, getCompanyInstrumentTransactions, useGetCompanyAssets } from './queries';

const CompanyAssetsView = () => {
  const { t } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  const baseUrl = `/company/${companyId}/benefit/${benefitId}`;
  useNavigationTitle([
    [t('navigation_title'), baseUrl],
    [t('assets.navigation_title'), `${baseUrl}/assets`],
  ]);
  useDocumentTitle(t('assets.document_title'));

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-col gap-8 items-start">
        <Assets companyBenefitId={benefitId as string} />
        <Transactions companyBenefitId={benefitId as string} />
      </div>
    </div>
  );
};

const today = new Date();
const defaultTab = [PortfolioAssetOwner.CUSTOMER, PortfolioAssetOwner.EMPLOYER];

const Assets = ({ companyBenefitId }: { companyBenefitId: string }) => {
  const { t } = useTranslation('company');
  const [selectedDate, setSelectedDate] = useState<Date>(today);
  const [currentAssetOwner, setCurrentAssetOwner] = useState(defaultTab);
  const response = useGetCompanyAssets({
    companyBenefitId,
    date: selectedDate,
    assetOwners: currentAssetOwner,
  });
  const assets = response.data?.instrumentValues ?? [];

  if (response.isError) {
    return (
      <DisplayError
        title={t('general.errors.assets')}
        buttonText={t('general:retry')}
        onClose={response.refetch}
        error={response.error}
      />
    );
  }

  if (response.isFetched && assets.length === 0) {
    return (
      <AlertNotice
        icon={<InfoIcon tw="bg-gray-100 rounded-full" />}
        title={t('assets.not_found.title')}
        description={t('assets.not_found.description')}
      />
    );
  }

  const handleDownloadAssets = async () => {
    if (assets == null) return;

    const date = selectedDate;
    const dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    const response = await getCompanyAssets({
      companyBenefitId,
      date,
      assetOwners: [PortfolioAssetOwner.CUSTOMER, PortfolioAssetOwner.EMPLOYER],
    });

    const rows = response.instrumentValues.map((asset) => ({
      isin: asset.isin,
      class: asset.assetClass,
      name: asset.name,
      owner: asset.assetOwner,
      units: asset.units,
      price: asset.price,
      value: asset.totalValue,
    }));

    createAndDownloadCSVFile(rows, t('assets.download.file_name') + '_' + dateString);
  };

  if (!Array.isArray(assets)) return null;

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 w-full">
      <div tw="flex flex-row flex-wrap items-center justify-between gap-4">
        <h2 tw="text-alps-blue-700 text-lg md:text-xl font-bold">{t('assets.title')}</h2>
        {assets.length > 0 && (
          <div tw="flex flex-row flex-wrap gap-4">
            <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={handleDownloadAssets}>
              <UploadIcon tw="rotate-180" /> {t('assets.download.title')}
            </Button>
          </div>
        )}
      </div>
      <AssetsTable
        uninvestedFundsSum={response.data?.uninvestedFundsSum}
        assets={assets}
        alwaysShowAssetOwnerTabs
        onTabChange={(tab) => setCurrentAssetOwner(tab == null || Array.isArray(tab) ? defaultTab : [tab])}
        header={
          <div tw="ml-auto">
            <InputDatePicker
              initialDates={[new Date(selectedDate) ?? today]}
              onDateChange={(dates) => setSelectedDate(dates[0])}
              dates={{ maxDate: today }}
            />
          </div>
        }
      />
    </div>
  );
};

const Transactions = ({ companyBenefitId }: { companyBenefitId: string }) => {
  const { t } = useTranslation('company');
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(today);

  const handleDownloadInstrumentTransactions = async () => {
    const response = await getCompanyInstrumentTransactions({
      companyBenefitId,
      from: startDate,
      to: endDate,
    });

    const rows = response.map((txn) => ({
      isin: txn.isin,
      name: txn.name,
      units: txn.units,
      price: txn.price,
      value: txn.totalValue,
      transactionDate: txn.transactionDate,
      type: txn.type,
      owner: txn.assetOwner,
    }));

    createAndDownloadCSVFile(rows, t('transactions.download.file_name'));
  };

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 w-full">
      <div tw="flex flex-row flex-wrap items-center justify-between gap-4">
        <h2 tw="text-alps-blue-700 text-lg md:text-xl font-bold">{t('transactions.title')}</h2>
      </div>

      <div tw="flex flex-row flex-wrap items-center justify-between gap-4 md:flex-nowrap">
        <Field label={t('transactions.from')} htmlFor="from">
          <InputDatePicker
            initialDates={[new Date(startDate) ?? today]}
            onDateChange={(dates) => setStartDate(dates[0])}
            dates={{ maxDate: today }}
          />
        </Field>
        <Field label={t('transactions.to')} htmlFor="to">
          <InputDatePicker
            initialDates={[new Date(endDate) ?? today]}
            onDateChange={(dates) => setEndDate(dates[0])}
            dates={{ maxDate: today }}
          />
        </Field>
        <Field>
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={handleDownloadInstrumentTransactions}>
            <UploadIcon tw="rotate-180" /> {t('transactions.download.title')}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default CompanyAssetsView;
