import {
  Button,
  ButtonVariant,
  CheckIcon,
  EyeIcon,
  FAQ,
  FAQQuestion,
  Field,
  FingerprintIcon,
  Input,
  PadlockIcon,
  SuitcaseIcon,
  toast,
  Trans,
  TrustBlock,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import { api } from '~/api';
import { setBackendValidationErrors } from '~/utils';
import { trackEmailCollection } from '~/utils/tracking-analytics';
import { useSessionQuery } from '../auth/queries';
import { REFERRAL_CODE_KEY, useSession } from '../auth/SessionProvider';
import { SignupForm } from '../auth/SignupForm';
import { useSubmitEmailCollection } from './queries';
import { getQuestionnaireId } from './utils';

type OnboardingStep = {
  title?: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  completedIcon: React.ReactNode;
  isActive?: boolean;
  isCompleted?: boolean;
};

export const onboardingSteps = () => {
  const steps: OnboardingStep[] = [
    {
      icon: <PadlockIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <PadlockIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <PadlockIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <SuitcaseIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <SuitcaseIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <SuitcaseIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <PadlockIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <PadlockIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <PadlockIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <EyeIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <EyeIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <EyeIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <UserIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <UserIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <UserIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <FingerprintIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <FingerprintIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <FingerprintIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
    {
      icon: <CheckIcon width={32} height={32} tw="text-alps-blue-300 bg-white rounded-full" />,
      activeIcon: <CheckIcon width={32} height={32} tw="bg-alps-blue-700 text-white rounded-full" />,
      completedIcon: <CheckIcon width={32} height={32} tw="text-alps-blue-700 bg-alps-blue-200 rounded-full" />,
    },
  ];

  const titleKeys = [
    'steps.account.navigation_title',
    'steps.investment_profile.navigation_title',
    'steps.credentials.navigation_title',
    'steps.preview_portfolio.navigation_title',
    'steps.personal_details.navigation_title',
    'steps.verification.navigation_title',
    'steps.contract.navigation_title',
  ];

  return {
    steps,
    titleKeys,
  };
};

type Values = {
  email: string;
};

const EmailCaptureStep = () => {
  const { t } = useTranslation('onboarding');
  const submitEmail = useSubmitEmailCollection();
  const mifidQuestionnaireId = getQuestionnaireId('mifid');
  const portfolioQuestionnaireId = getQuestionnaireId('portfolio');
  const [isEnabled, setIsEnabled] = useState(false);
  useSessionQuery({ enabled: isEnabled });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
    watch,
  } = useForm<Values>();
  const email = watch('email');

  const createSteps = () => {
    const { steps, titleKeys } = onboardingSteps();

    return steps.map((step, index) => {
      return {
        ...step,
        title: t(titleKeys[index]),
        isActive: index === 0,
      };
    });
  };

  const steps = createSteps();

  const onSubmit = async () => {
    try {
      await submitEmail.mutateAsync({
        email,
        mifidQuestionnaireId: mifidQuestionnaireId as string,
        portfolioQuestionnaireId: portfolioQuestionnaireId as string,
        referralCode: localStorage.getItem(REFERRAL_CODE_KEY) ?? null,
      });
      trackEmailCollection(email);
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
    }
  };

  if (isSubmitSuccessful) {
    return (
      <div tw="flex flex-col justify-center items-center gap-8 px-4 flex-grow">
        <CheckIcon width={80} height={80} tw="bg-alps-blue-600 text-white rounded-full" />
        <div tw="flex flex-col items-center justify-center gap-4 text-center">
          <h2 tw="text-lg md:text-xl text-alps-blue-700 font-semibold">{t('questions.capture.check_email')}</h2>
          <h2 tw="text-base text-gray-500">{t('questions.capture.email_sent', { email })}</h2>
        </div>
        <input
          tw="absolute"
          style={{ left: '999999rem' }}
          data-test-id="refetchSession"
          onClick={() => setIsEnabled(true)}
          tabIndex={-1}
        />
      </div>
    );
  }

  const emailErrorMessage =
    'email' in errors ? (
      errors?.email?.message != null ? (
        <a href="/login">{t(errors.email.message, { ns: 'general' })}</a>
      ) : (
        t('signup.email.error', { ns: 'auth' })
      )
    ) : undefined;

  return (
    <div tw="flex flex-col gap-16">
      <div tw="flex w-full flex-col-reverse gap-14 md:flex-row justify-between md:px-4">
        <form tw="flex flex-[0.7] flex-col gap-12 px-4 md:px-0" onSubmit={handleSubmit(onSubmit)}>
          <h2 tw="text-xl">
            <Trans i18nKey="questions.capture.title" t={t} components={[<span key="0" tw="text-alps-blue-400" />]} />
          </h2>
          <div tw="flex flex-col">
            <div tw="max-w-sm">
              <Field error={emailErrorMessage}>
                <Input
                  id="email"
                  type="email"
                  autoComplete="email"
                  placeholder={t('questions.capture.email')}
                  {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                  error={'email' in errors}
                  disabled={isSubmitting}
                  data-test-id="email"
                />
              </Field>
            </div>
            <div tw="flex flex-col sm:flex-row gap-4 md:gap-8">
              <Button
                type="submit"
                variant={ButtonVariant.PRIMARY}
                tw="whitespace-nowrap max-w-max"
                data-test-id="verify-email"
              >
                {t('questions.capture.verify_email')}
              </Button>
              <p tw="text-sm text-gray-400 max-w-md">{t('questions.capture.disclaimer')}</p>
            </div>
          </div>
        </form>
        <div tw="flex-[0.4] w-full">
          <OnboardingStepList steps={steps} />
        </div>
      </div>
      <TrustBlock />
      <div tw="flex flex-col justify-center items-center px-4">
        <FAQ
          viewAll={false}
          style="BLUE"
          questions={t('questions.capture.faq', { returnObjects: true }) as FAQQuestion[]}
        />
      </div>
    </div>
  );
};

export const OnboardingStepList = ({ steps }: { steps: OnboardingStep[] }) => {
  return (
    <ol tw="flex flex-row gap-6 bg-alps-blue-50 p-4 w-full overflow-x-auto whitespace-nowrap [&::-webkit-scrollbar]:hidden absolute left-0 top-14 md:whitespace-normal md:sticky md:top-4 md:flex-col md:rounded-xl md:p-6">
      {steps.map((step, idx) => {
        const icon = step.isActive ? step.activeIcon : step.isCompleted ? step.completedIcon : step.icon;
        return (
          <li key={idx} tw="flex flex-row gap-4 items-center">
            <span
              tw="relative"
              css={[
                step.isCompleted &&
                  tw`md:before:content-[''] before:absolute before:left-1/2 before:transform before:-translate-x-1/2 before:translate-y-full before:w-[2px] md:before:h-10 before:bg-alps-blue-200 before:bottom-0`,
              ]}
            >
              {icon}
            </span>
            <span
              css={[tw`text-alps-blue-400 font-medium`, (step.isActive || step.isCompleted) && tw`text-alps-blue-700`]}
            >
              {step.title}
            </span>
            {step.isCompleted && (
              <CheckIcon
                width={16}
                height={16}
                tw="bg-alps-blue-200 text-alps-blue-700 rounded-full"
                style={{ minWidth: 16 }}
              />
            )}
          </li>
        );
      })}
    </ol>
  );
};

export const OnboardingAuth = () => {
  const { t } = useTranslation('auth');
  const session = useSession();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState('');
  const [isReferralCodeError, setIsReferralCodeError] = useState(false);

  useEffect(() => {
    setReferralCode(localStorage.getItem(REFERRAL_CODE_KEY) ?? '');
  }, []);

  const handleSubmitReferralCode = async () => {
    setIsReferralCodeError(false);
    try {
      await api.get(`referral/referralCode/${referralCode}`);
      searchParams.set(REFERRAL_CODE_KEY, referralCode);
      setSearchParams(searchParams);
      toast.success(t('referral.success'));
    } catch (err) {
      toast.error(t('referral.invalid_code'));
      setIsReferralCodeError(true);
    }
  };

  return (
    <div tw="flex flex-col items-center md:items-start w-full gap-10">
      <div tw="flex flex-row gap-2">
        <Input
          value={referralCode}
          onChange={(e) => setReferralCode((e.target as HTMLInputElement).value)}
          name={REFERRAL_CODE_KEY}
          placeholder={t('referral.code')}
          autoFocus={false}
          error={isReferralCodeError}
        />
        <Button
          variant={ButtonVariant.SECONDARY}
          className="max-w-max"
          onClick={handleSubmitReferralCode}
          disabled={referralCode.length <= 0}
        >
          {t('referral.apply_code')}
        </Button>
      </div>
      <SignupForm
        useEmailPassAccordion={true}
        showNewsletters={!session.email}
        redirectToResult={true}
        callback={() => navigate('/onboarding/result')}
        presetEmail={session.email}
        nextUrl="/onboarding/result"
      />
    </div>
  );
};

export default EmailCaptureStep;
