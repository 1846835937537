import { useOutletContext, useParams } from 'react-router-dom';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';
import { PaymentStep } from '../components/PaymentStep';
import { useEffect } from 'react';
import { trackActivationSteps } from '~/utils/tracking-analytics';
import { ActivationLink } from '~/modules/portfolio/ActivateView/ActivationView';

const InitialPaymentStep = () => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onContinue } = context;
  const params = useParams();
  const { id } = params ?? {};

  useEffect(() => {
    if (id) trackActivationSteps(ActivationLink.INITIAL_PAYMENT, id || '');
  }, [id]);

  return (
    <OnboardingStepWrapper>
      <PaymentStep isUpfront onNext={onContinue} />
    </OnboardingStepWrapper>
  );
};

export default InitialPaymentStep;
