var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgUploadIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M5.25 17.25C5.25 16.8358 5.58579 16.5 6 16.5L18 16.5C18.4142 16.5 18.75 16.8358 18.75 17.25C18.75 17.6642 18.4142 18 18 18H6C5.58579 18 5.25 17.6642 5.25 17.25Z", fill: "#252324" }),
    React.createElement("path", { d: "M12 15C11.5858 15 11.25 14.6642 11.25 14.25V7.81066L8.03033 11.0303C7.73744 11.3232 7.26256 11.3232 6.96967 11.0303C6.67678 10.7374 6.67678 10.2626 6.96967 9.96967L11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967L17.0303 9.96967C17.3232 10.2626 17.3232 10.7374 17.0303 11.0303C16.7374 11.3232 16.2626 11.3232 15.9697 11.0303L12.75 7.81066V14.25C12.75 14.6642 12.4142 15 12 15Z", fill: "#252324" }))); };
var ForwardRef = forwardRef(SvgUploadIcon);
var Memo = memo(ForwardRef);
export default Memo;
