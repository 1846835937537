import { formatCurrency, InfoTooltip, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';
import { CopyToClipboard } from '~/modules/portfolio/components/CopyToClipboard';
import { StandingOrderInstruction } from '~/modules/portfolio/types';

type Props = StandingOrderInstruction & { isUpfront?: boolean };
export const PaymentInformation = ({
  beneficiaryName,
  accountNumber,
  referenceNumber,
  monthlyFee,
  totalMonthlyAmount,
  upfrontInvestmentAmount,
  isUpfront,
}: Props) => {
  const { t } = useTranslation('portfolio');

  const list = [
    {
      label: t('payment.beneficiary_name'),
      value: beneficiaryName,
      copy: true,
    },
    {
      label: t('payment.account_number'),
      value: accountNumber,
      copy: true,
    },
    {
      label: t('payment.payment_reference'),
      value: referenceNumber,
      copy: true,
    },
    {
      label: t('sum', { ns: 'general' }),
      value: isUpfront
        ? formatCurrency(upfrontInvestmentAmount, { digits: 2 })
        : formatCurrency(totalMonthlyAmount, { digits: 2 }),
      copy: true,
      children: !isUpfront && !!monthlyFee && monthlyFee > 0 && (
        <InfoTooltip>
          <b>{formatCurrency(monthlyFee, { digits: 2 })}</b>
          <p tw="mt-2">{t('payment.invest_fee', { maxPortfolioValue: formatCurrency(1000) })}</p>
        </InfoTooltip>
      ),
    },
  ];

  return (
    <div tw="flex flex-col gap-8">
      <ol tw="flex flex-col flex-wrap justify-between w-full gap-6">
        {list.map((item) => {
          return (
            <li key={item.value} tw="flex flex-row flex-wrap items-start sm:flex-nowrap md:gap-8 w-full">
              <div tw="w-2/3">
                <p tw="font-medium text-green-600 text-base">{item.label}</p>
              </div>
              <div tw="inline-flex sm:justify-end gap-2 w-full">
                <div tw="inline-flex">
                  <p tw="font-medium text-neutral-900 text-base">{item.value}</p>
                  {item.copy && <CopyToClipboard value={item.value?.toString()} />}
                </div>
                {item.children}
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
