export var instrumentColorConfigs = {
    LU1377382368: { background: '#426842', style: "bg-forest-green-700 text-white" }, // BNP Paribas Easy Low Carbon 100 Europe
    LU2195226068: { background: '#749B74', style: "bg-forest-green-500 text-white" }, // Lyxor NetZero2050 Eurozone Paris Aligned & Climate Transition
    IE00BN4Q0933: { background: '#92BA92', style: "bg-forest-green-400 text-white" }, // UBS MSCI Europe Climate Paris Aligned
    LU1659681586: { background: '#92BA92', style: "bg-forest-green-400 text-white" }, // BNP Paribas Easy MSCI USA Paris Aligned
    IE00BYZK4776: { background: '#E6948E', style: "bg-dusk-400 text-white" }, // iShares Healthcare Innovation
    LU1861137484: { background: '#E6948E', style: "bg-dusk-400 text-white" }, // Amundi Index MSCI Europe SRI
    LU0484968812: { background: '#F0D960', style: "bg-dawn-200 text-gray-900" }, // XTRACKERS II ESG EUR Corp Bond
    LU1437018168: { background: '#F0D960', style: "bg-dawn-200 text-gray-900" }, // Amundi Index Euro Corporate SRI UCITS ETF DR
    LU1691909508: { background: '#4D878F', style: "bg-alps-blue-500 text-white" }, // AMUNDI GLOBAL GENDER EQUALITY ETF ACC
    LU1981859819: { background: '#E09431', style: "bg-dawn-400 text-white" }, // Lyxor Green Bond ESG Screened ETF
    LU2300295396: { background: '#E09431', style: "bg-dawn-400 text-white" }, // Amundi iCPR Euro Corp Climate Paris Aligned
    LU1829219127: { background: '#E09431', style: "bg-dawn-400 text-white" }, // Amundi EUR Corporate Bond Climate Net Zero Ambition
};
export var instrumentColorMap = {
    LU1377382368: instrumentColorConfigs['LU1377382368'].background, // BNP Paribas Easy Low Carbon 100 Europe
    LU2195226068: instrumentColorConfigs['LU2195226068'].background, // Lyxor NetZero2050 Eurozone Paris Aligned & Climate Transition
    IE00BN4Q0933: instrumentColorConfigs['IE00BN4Q0933'].background, // UBS MSCI Europe Climate Paris Aligned
    LU1659681586: instrumentColorConfigs['LU1659681586'].background, // BNP Paribas Easy MSCI USA Paris Aligned
    IE00BYZK4776: instrumentColorConfigs['IE00BYZK4776'].background, // iShares Healthcare Innovation
    LU1861137484: instrumentColorConfigs['LU1861137484'].background, // Amundi Index MSCI Europe SRI
    LU0484968812: instrumentColorConfigs['LU0484968812'].background, // XTRACKERS II ESG EUR Corp Bond
    LU1437018168: instrumentColorConfigs['LU1437018168'].background, // Amundi Index Euro Corporate SRI UCITS ETF DR
    LU1691909508: instrumentColorConfigs['LU1691909508'].background, // AMUNDI GLOBAL GENDER EQUALITY ETF ACC
    LU1981859819: instrumentColorConfigs['LU1981859819'].background, // Lyxor Green Bond ESG Screened ETF
    LU2300295396: instrumentColorConfigs['LU2300295396'].background, // Amundi iCPR Euro Corp Climate Paris Aligned
    LU1829219127: instrumentColorConfigs['LU1829219127'].background, // Amundi EUR Corporate Bond Climate Net Zero Ambition
};
