import * as Sentry from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    ignoreErrors: ['ValidationError', 'getReadModeConfig', 'getReadModeExtract', 'getReadModeRender'],
    tracesSampleRate: 1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['app.grunfin.com', 'api.grunfin.com', '.grunfin.dev'], // also matches test env-s
      }),
      new ExtraErrorDataIntegration(),
    ],
  });
}
