import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

/**
 * Plain text or segments of text or links (tuple of text, link)
 */
type NavigationTitle = string | (string | string[])[] | (string | [string, string])[];

type CustomActionFn = () => React.ReactNode;

export const NavigationBarContext = React.createContext<null | {
  title: NavigationTitle | null;
  setTitle: (v: NavigationTitle) => void;
  customActions: CustomActionFn;
  setCustomActions: (c: CustomActionFn) => void;
}>(null);

/**
 * Context provider for store global navigation bar state
 */
export const NavigationBarProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState<NavigationTitle>('');
  const [customActions, setCustomActions] = useState<CustomActionFn>(() => () => null);

  return useMemo(
    () => (
      <NavigationBarContext.Provider
        value={{ title, setTitle, customActions, setCustomActions: (fn) => setCustomActions(() => fn) }}
      >
        {children}
      </NavigationBarContext.Provider>
    ),
    [JSON.stringify(title), customActions],
  );
};

export const useNavigationContext = () => {
  const navState = useContext(NavigationBarContext);
  if (navState === null) throw new Error('No navigation session provided');
  return navState;
};

/**
 * Hook for setting custom page title
 */
export const useNavigationTitle = (title: NavigationTitle) => {
  const { setTitle } = useNavigationContext();

  useEffect(() => {
    setTitle(title);
    return () => {
      setTitle('');
    };
  });
};
