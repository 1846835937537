import { Trans } from '@grunfin/ui-kit';
import Papa from 'papaparse';
import { ReactNode, useCallback } from 'react';
import 'twin.macro';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AddEmployee } from '~/modules/company/types';

export type DataPoint<T> = {
  row: T;
  valid: boolean;
  missingHeaders: string[];
  invalidFields?: string[];
};

type DropzoneProps = {
  onComplete: (points: any[], errors: Papa.ParseError[]) => void;
  requiredHeaders: string[];
  title: string;
  icon: ReactNode;
  handleTemplateDownload: () => void;
};

const processRow = (row: AddEmployee): DataPoint<AddEmployee> => {
  let valid = true;
  const invalidFields: string[] = [];
  if (row?.employerSideId == '999' && row?.givenName == 'John' && row?.lastName == 'Doe') {
    invalidFields.push('id');
    invalidFields.push('givenName');
    invalidFields.push('lastName');
    valid = false;
  }
  if (row?.dateOfBirth) {
    dayjs.extend(customParseFormat);
    let date = dayjs(row.dateOfBirth, 'YYYY-MM-DD', true);
    if (!date.isValid()) {
      date = dayjs(row.dateOfBirth, 'DD.MM.YYYY', true);
      if (date.isValid()) {
        row = { ...row, dateOfBirth: date.format('YYYY-MM-DD') };
      } else {
        invalidFields.push('dateOfBirth');
        valid = false;
      }
    }
  }

  return { row, valid, missingHeaders: [], invalidFields };
};

const CSVImporter = ({ onComplete, requiredHeaders, title, icon, handleTemplateDownload }: DropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const rows = results.data as AddEmployee[];
            const errors = results.errors;

            if (!results.meta.fields?.includes('employerSideId')) {
              errors.push({
                code: 'TooFewFields',
                message: 'No employerSideId field found in file',
                row: 0,
                type: 'FieldMismatch',
              });
            } else if (rows.length === 0) {
              errors.push({
                code: 'TooFewFields',
                message: 'No rows found in file',
                row: 0,
                type: 'FieldMismatch',
              });
            }

            // for each row construct a DataPoint which will be used to check if the row is valid
            const data_points: DataPoint<AddEmployee>[] = [];
            rows.forEach((row) => {
              const dataPoint = processRow(row);
              requiredHeaders.forEach((header) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!row[header]) {
                  dataPoint.valid = false;
                  dataPoint.missingHeaders.push(header);
                }
              });
              data_points.push(dataPoint);
            });

            // Call the onComplete callback with the data points and errors
            onComplete(data_points, errors);
          },
        });
      });
    },
    [onComplete],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: { 'text/csv': ['.csv'] } });

  return (
    <div {...getRootProps()} tw="w-full border-2 border-gray-200 border-dashed rounded md:rounded-2xl cursor-pointer">
      <input {...getInputProps()} />
      <div tw="flex flex-col items-center justify-center gap-6 p-4 md:py-8 lg:py-16">
        {icon}
        <h2 tw="text-gray-400 text-center text-base md:text-lg">{title}</h2>
        <p tw="text-gray-400 text-center text-base">
          <Trans
            i18nKey="company:general.upload.template"
            components={[
              <a
                key="0"
                tw="text-sky-blue-700"
                onClick={(e) => {
                  // stop the event from bubbling to 'useDropzone'
                  e.stopPropagation();
                  handleTemplateDownload();
                }}
              />,
            ]}
          />
        </p>
      </div>
    </div>
  );
};

export default CSVImporter;
