var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import InfoIcon from '../../icons/InfoIcon';
import Popover, { offset } from './Popover';
export var Tooltip = function (props) {
    return (React.createElement(Popover, __assign({}, props, { content: React.createElement("div", { className: "rounded bg-white text-gray-900 text-base shadow-md" }, props.content), options: __assign({ middleware: [offset(10)] }, props.options) }), props.children));
};
export var InfoTooltip = function (_a) {
    var _b = _a.showOnHover, showOnHover = _b === void 0 ? false : _b, children = _a.children;
    return (React.createElement(Popover, { showOnHover: showOnHover, content: React.createElement("div", { className: "rounded bg-white text-gray-900 text-base shadow-inner p-2 max-w-sm w-full whitespace-pre-line" }, children), options: {
            middleware: [offset(10)],
        } },
        React.createElement("button", { type: "button", className: "inline-flex items-center justify-center rounded-full w-5 h-5 bg-green-100" },
            React.createElement(InfoIcon, { className: "text-green-800" }))));
};
