import { Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Heading } from '~/modules/portfolio/components/Heading';
import { Success } from '~/modules/portfolio/components/Success';
import { usePortfolio } from '~/modules/portfolio/hooks';

export const SetUpSuccess = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const navigate = useNavigate();

  return (
    <div tw="w-full md:pb-12">
      <Heading primary>{t('details.payment.set_up.direct_debit.title')}</Heading>
      <div tw="flex flex-col px-6">
        <Success title={t('details.payment.set_up.direct_debit.success')}>
          <Button
            onClick={() => navigate(`/portfolio/${portfolio.id}`)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
          >
            {t('details.payment.top_up.success.back')}
          </Button>
        </Success>
      </div>
    </div>
  );
};
