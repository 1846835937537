import { ErrorOverlay, Trans, useTranslation } from '@grunfin/ui-kit';
import { captureMessage } from '@sentry/browser';
import { useEffect } from 'react';
import 'twin.macro';

interface Props {
  title: string;
  buttonText?: string;
  onClose: () => unknown;
  error?: Error | null;
}

export function ContactSupportOverlay({ buttonText, ...props }: Props) {
  const { t } = useTranslation('support');

  const formatError = (err: Error) => {
    if (err.name === 'HTTPError') {
      const { status, url } = (err as any).response;
      return `HTTPError ${status} ${url.replace(window.location.origin, '')}`;
    }

    return `${err.name}: ${err.message}`;
  };

  useEffect(() => {
    props?.error && captureMessage('Error page - ' + window.location.href + ' Error: ' + formatError(props?.error));
  }, [props.error]);

  return (
    <ErrorOverlay buttonText={buttonText ?? t('retry', { ns: 'general' })} {...props}>
      <Trans
        i18nKey="contact_support"
        t={t}
        components={[<a key="0" href="/support" tw="text-alps-blue-400 underline" />]}
      />
    </ErrorOverlay>
  );
}
