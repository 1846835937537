import {
  BeneficiaryType,
  Button,
  ButtonVariant,
  Checkbox,
  DateDropdown,
  FocusArea,
  formatCurrency,
  formatDate,
  formatPercent,
  Spinner,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import 'twin.macro';
import { apiRoot } from '~/api';
import {
  useGetPortfolio,
  useGetPortfolioContract,
  useSignContract,
  useUpdatePortfolio,
} from '~/modules/portfolio/queries';
import { SigningAttemptStatus } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { trackActivationSteps, trackContractSigning, trackEmployeeContractSigning } from '~/utils/tracking-analytics';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';
import { ActivationLink } from '~/modules/portfolio/ActivateView/ActivationView';

const createDateAndAddDays = (days: number) => dayjs().add(days, 'day').toDate();

enum ListItemIdentifier {
  CONTRACT_DATE = 'contract_date',
  TARGET_DATE = 'target_date',
  LOCK_DATE = 'lock_date',
  FOCUS = 'focus',
  RISK_PREFERENCE = 'risk_preference',
  MANAGEMENT_FEE = 'management_fee',
  TARGET_ALLOCATION = 'target_allocation',
  INSTRUMENTS = 'instruments',
  MONTHLY_PAYMENT = 'monthly_payment',
  MONTHLY_FEE = 'monthly_fee',
  ONE_TIME_PAYMENT = 'one_time_payment',
  TARGET_DEPOSIT = 'target_deposit',
  TARGET_GAIN = 'target_gain',
  TARGET_VALUE = 'target_value',
}

type ListItem = {
  id: ListItemIdentifier;
  label: string;
  value: string | number | boolean | JSX.Element | undefined;
};

const renderList = (list: ListItem[]) => {
  return (
    <ol tw="flex flex-col gap-4">
      {list.map((item, index) => {
        const isComponent = typeof item.value === 'function' || typeof item.value === 'object';

        if (!item.value) return null;

        return (
          <li key={index} tw="flex flex-col gap-2 sm:flex-row text-base text-gray-900">
            <p tw="sm:flex-[0.5] text-gray-500">{item.label}</p>
            {isComponent && item.value}
            {!isComponent && <p tw="sm:flex-1 font-medium">{item.value}</p>}
          </li>
        );
      })}
    </ol>
  );
};

const findListItemObjectByIdentifier = (list: ListItem[], identifier: ListItemIdentifier): ListItem => {
  return list.find((item) => item.id === identifier) as ListItem;
};

const ContractStep = () => {
  const context = useOutletContext<OnboardingOutletContext>();
  const params = useParams();
  const { id: paramsPortfolioId, membershipId } = params;
  /* 
    portfolioId is expected to be available when:
    - the user has created a portfolio in the same state context (state hasn't been overwritten)
    - the user has been redirected to this component outside the flow (GET /enroll must have portfolioId present)
   */
  const { portfolioId: contextPortfolioId, onContinue, onBack } = context || {};
  const { t } = useTranslation('portfolio');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [agreedData, setAgreedData] = useState(false);
  // We expect the portfolioId to be present in the context
  const portfolioId = contextPortfolioId ?? paramsPortfolioId;
  const contract = useGetPortfolioContract(portfolioId as string);
  const portfolio = useGetPortfolio(portfolioId as string, { enabled: portfolioId != null });
  const [endDate, setEndDate] = useState('');
  const updatePortfolio = useUpdatePortfolio(portfolioId as string);
  const sign = useSignContract();

  const isSigned =
    (sign.data != null && sign.data.status === SigningAttemptStatus.SIGNED) || contract.data?.signedAt != null;
  const endDateFormatted = endDate != null && endDate.length !== 0 ? formatDate(endDate) : undefined;

  const isFlatPricing = portfolio.data?.pricingScheme === 'RETAIL_LEGACY_FLAT';

  // update end date value when portfolio is done fetching
  useEffect(() => {
    if (portfolio.isSuccess && portfolio.data.endDate != null && endDate.length === 0)
      setEndDate(portfolio.data.endDate);
  }, [portfolio.isSuccess, portfolio?.data?.endDate, endDate]);

  useEffect(() => {
    if (portfolio.isSuccess) trackActivationSteps(ActivationLink.CONTRACT, portfolioId || '');
  }, [portfolio.isSuccess, portfolio.data]);

  if (contract.isError || sign.isError || portfolio.isError || (portfolio.isSuccess && portfolio.data == null)) {
    return (
      <OnboardingStepWrapper>
        <ContactSupportOverlay
          title={t('activation.contract.error')}
          onClose={contract.refetch}
          error={contract.error}
        />
      </OnboardingStepWrapper>
    );
  }

  if (contract.isIdle || contract.isLoading || sign.isLoading || portfolio.isIdle || portfolio.isLoading)
    return (
      <OnboardingStepWrapper>
        <div tw="mx-auto">
          <Spinner />
        </div>
      </OnboardingStepWrapper>
    );

  const retailList: ListItem[] = [
    {
      id: ListItemIdentifier.CONTRACT_DATE,
      label: t('activation.contract.contract_date'),
      value: formatDate(contract.data.contractDate),
    },
    {
      id: ListItemIdentifier.TARGET_DATE,
      label: t('activation.contract.target_date'),
      value: isSigned ? (
        endDateFormatted
      ) : (
        <div tw="flex flex-1">
          <DateDropdown
            value={endDate}
            onChange={async (endDate) => {
              setEndDate(endDate);
              await updatePortfolio.mutateAsync({
                focusAreas: portfolio.data.focus.split(', ') as FocusArea[],
                areaClimateChange: portfolio.data.focus.includes(FocusArea.CLIMATE_CHANGE),
                areaSP500: portfolio.data.focus.includes(FocusArea.SP500),
                areaGenderEquality: portfolio.data.focus.includes(FocusArea.GENDER_EQUALITY),
                areaHealth: portfolio.data.focus.includes(FocusArea.HEALTH),
                monthlyInvestmentAmountEur: String(portfolio.data.monthlyInvestmentAmountEur),
                name: portfolio.data.name,
                preferredRisk: portfolio.data.preferredRisk,
                endDate,
              });
            }}
            spanFuture={50}
            spanBack={1}
          />
        </div>
      ),
    },
    {
      id: ListItemIdentifier.MONTHLY_PAYMENT,
      label: t('activation.contract.monthly_payment'),
      value:
        portfolio.data.monthlyInvestmentAmountEur != null &&
        formatCurrency(portfolio.data.monthlyInvestmentAmountEur, { maxDigits: 2 }),
    },
    {
      id: ListItemIdentifier.ONE_TIME_PAYMENT,
      label: t('activation.contract.one_time_payment'),
      value:
        portfolio.data.upfrontInvestmentAmountEur != null &&
        formatCurrency(portfolio.data.upfrontInvestmentAmountEur, { maxDigits: 2 }),
    },
    {
      id: ListItemIdentifier.MONTHLY_FEE,
      label: t('activation.contract.monthly_fee.label'),
      value: isFlatPricing
        ? t('activation.contract.monthly_fee.text', {
            monthlyFee: formatCurrency(3.9, { digits: 2 }),
            maxPortfolioValue: formatCurrency(1000),
          })
        : t('activation.contract.monthly_fee.aum_text'),
    },
    {
      id: ListItemIdentifier.FOCUS,
      label: t('activation.contract.focus'),
      value: portfolio.data.focus
        .split(',')
        ?.map((focus) => t(focus?.trim()?.toLowerCase()?.replace(' ', '_') + '.label'))
        .join(', '),
    },
    {
      id: ListItemIdentifier.RISK_PREFERENCE,
      label: t('activation.contract.risk_preference'),
      value: t('risk.' + portfolio.data.preferredRisk.toLowerCase()),
    },
    {
      id: ListItemIdentifier.TARGET_ALLOCATION,
      label: t('activation.contract.target_allocation.label'),
      value: t('activation.contract.target_allocation.text', {
        shares: formatPercent(portfolio.data.idealComposition.STOCK.target),
        bonds: formatPercent(portfolio.data.idealComposition.BOND.target),
      }),
    },
    {
      id: ListItemIdentifier.INSTRUMENTS,
      label: t('activation.contract.instruments'),
      value: (
        <ol tw="flex flex-col gap-1 flex-1">
          {[...portfolio.data.assets] /* copy array to not modify original */
            .sort((a, b) => {
              if (a.targetShare != null && b.targetShare != null) {
                if (a.targetShare === b.targetShare) return a.isin < b.isin ? -1 : 1; // if equal, sort by isin
                return b.targetShare - a.targetShare;
              }
              return 0;
            })
            .map((asset) => (
              <li key={asset.instrumentId}>{asset.name}</li>
            ))}
        </ol>
      ),
    },
  ];

  const employeeList: ListItem[] = [
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.CONTRACT_DATE)],
    {
      id: ListItemIdentifier.LOCK_DATE,
      label: t('activation.contract.lock_date'),
      value: context.lockPeriodDays != null ? formatDate(createDateAndAddDays(context.lockPeriodDays)) : '-',
    },
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.FOCUS)],
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.RISK_PREFERENCE)],
    {
      id: ListItemIdentifier.MANAGEMENT_FEE,
      label: t('activation.contract.management_fee'),
      value:
        context.managementFeePercentage != null
          ? `${formatPercent(context.managementFeePercentage / 100)} ${t('activation.contract.per_annum')}`
          : '',
    },
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.TARGET_ALLOCATION)],
    ...[findListItemObjectByIdentifier(retailList, ListItemIdentifier.INSTRUMENTS)],
  ];

  const calculationList: ListItem[] = [
    {
      id: ListItemIdentifier.TARGET_DEPOSIT,
      label: t('activation.contract.deposit_target'),
      value: portfolio.data.targetDepositedAmountEur != null && (
        <div tw="flex tabular-nums font-medium text-gray-900">
          {formatCurrency(portfolio.data.targetDepositedAmountEur, { display: 'code' })}
        </div>
      ),
    },
    {
      id: ListItemIdentifier.TARGET_GAIN,
      label: t('activation.contract.indicative_gain'),
      value: portfolio.data.targetGainEur != null && (
        <div tw="flex tabular-nums font-medium text-gray-900">
          {formatCurrency(portfolio.data.targetGainEur, { display: 'code' })}
        </div>
      ),
    },
    {
      id: ListItemIdentifier.TARGET_VALUE,
      label: t('activation.contract.target_value'),
      value: portfolio.data.targetValueEur != null && (
        <div tw="flex tabular-nums font-medium text-gray-900">
          {formatCurrency(portfolio.data.targetValueEur, { display: 'code' })}
        </div>
      ),
    },
  ];

  const handleSignContract = async () => {
    const response = await sign.mutateAsync(portfolioId as string);

    if (portfolio.data.beneficiaryType === BeneficiaryType.EMPLOYEE)
      trackEmployeeContractSigning(membershipId as string);
    else trackContractSigning();

    if (response.status === SigningAttemptStatus.SIGNED && typeof onContinue !== 'undefined') onContinue();
  };

  const lists: { [key in BeneficiaryType]: ListItem[] } = {
    [BeneficiaryType.SELF]: retailList,
    [BeneficiaryType.CHILD]: retailList,
    [BeneficiaryType.COMPANY]: retailList,
    [BeneficiaryType.EMPLOYEE]: employeeList,
  };
  const list = lists[portfolio.data.beneficiaryType];

  const showTargetCalculation = [BeneficiaryType.SELF, BeneficiaryType.CHILD].includes(portfolio.data.beneficiaryType);

  return (
    <OnboardingStepWrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!isSigned) handleSignContract();
          else if (typeof onContinue !== 'undefined') onContinue();
        }}
        tw="flex flex-col gap-14 md:gap-20"
      >
        <div tw="text-gray-900">{t('activation.contract.details_note')}</div>
        <div tw="max-w-max">{renderList(list)}</div>
        <div tw="max-w-xl">
          {isSigned ? (
            <p tw="text-gray-400">{t('activation.contract.signed')}</p>
          ) : (
            <>
              {showTargetCalculation ? (
                <>
                  <p tw="text-base md:text-lg font-bold mt-12 mb-6 md:mb-16">
                    {t('activation.contract.example_calculation')}
                  </p>
                  {renderList(calculationList)}
                  <div tw="text-sm text-gray-400 mt-12 mb-16">
                    {isFlatPricing ? (
                      <>
                        <p tw="mb-2">{t('activation.contract.note.payments')}</p>
                        <p tw="mb-2">{t('activation.contract.note.indicative_gain')}</p>
                      </>
                    ) : (
                      <></>
                    )}
                    {t('activation.contract.note.agreement')}
                  </div>
                </>
              ) : (
                <div tw="text-sm text-gray-400 mt-12 mb-6 md:mb-16">{t('activation.contract.note.agreement')}</div>
              )}
              <p tw="text-base md:text-lg font-bold mt-12 mb-6 md:mb-16">{t('activation.contract.sign_agreement')}</p>
              <ContractCheckboxes
                termsChecked={agreedTerms}
                onTermsChange={setAgreedTerms}
                dataChecked={agreedData}
                onDataChange={setAgreedData}
                beneficiaryType={portfolio.data.beneficiaryType}
              />
            </>
          )}
        </div>
        <div tw="flex flex-row w-full">
          <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
            {t('back', { ns: 'general' })}
          </Button>
          <Button
            variant={ButtonVariant.NEW_PRIMARY}
            type="submit"
            tw="max-w-max ml-auto"
            disabled={isSigned ? false : !agreedTerms || !agreedData}
            data-test-id="contract-continue"
          >
            {isSigned ? t('continue', { ns: 'general' }) : t('activation.contract.agree_and_sign')}
          </Button>
        </div>
      </form>
    </OnboardingStepWrapper>
  );
};

type ContractCheckboxesProps = {
  termsChecked: boolean;
  dataChecked: boolean;
  onTermsChange: (checked: boolean) => void;
  onDataChange: (checked: boolean) => void;
  beneficiaryType: BeneficiaryType;
};

export const ContractCheckboxes = (props: ContractCheckboxesProps) => {
  const { t, i18n } = useTranslation('portfolio');
  const { termsChecked, dataChecked, onTermsChange, onDataChange, beneficiaryType /*  = BeneficiaryType.SELF */ } =
    props;
  const params = new URLSearchParams({
    locale: i18n?.language,
    ...(beneficiaryType === BeneficiaryType.EMPLOYEE && {
      employerProgram: String(beneficiaryType === BeneficiaryType.EMPLOYEE),
    }),
  }).toString();

  const link: { [key in BeneficiaryType]: string } = {
    [BeneficiaryType.COMPANY]: `${apiRoot}/generalTerms/company`,
    [BeneficiaryType.EMPLOYEE]: `${apiRoot}/generalTerms/`,
    [BeneficiaryType.SELF]: `${apiRoot}/generalTerms/`,
    [BeneficiaryType.CHILD]: `${apiRoot}/generalTerms/`,
  };

  const getLink = (beneficiaryType: BeneficiaryType) => {
    return `${link[beneficiaryType]}?${params}`;
  };

  return (
    <Fragment>
      <Checkbox
        label={
          <div tw="block">
            <Trans
              i18nKey="activation.contract.agree_to_terms"
              t={t}
              components={[
                <a
                  key="0"
                  tw="text-green-400 hover:underline"
                  target={'_blank'}
                  href={getLink(beneficiaryType)}
                  rel="noreferrer"
                />,
              ]}
            />
          </div>
        }
        value={termsChecked}
        onChange={onTermsChange}
        name="agree-terms"
      />
      <div tw="my-6" />
      <Checkbox
        label={t('activation.contract.data_valid')}
        value={dataChecked}
        onChange={onDataChange}
        name="agree-validity"
      />
    </Fragment>
  );
};

export default ContractStep;
