var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var CountStateContext = React.createContext(undefined);
var initialState = {
    isConsented: null,
    isScriptRendered: false,
};
function cookieReducer(state, action) {
    switch (action.type) {
        case 'SET_IS_CONSENTED': {
            return __assign(__assign({}, state), { isConsented: action.payload });
        }
        case 'SET_IS_SCRIPT_RENDERED': {
            return __assign(__assign({}, state), { isScriptRendered: action.payload });
        }
        default: {
            throw new Error("Unhandled action type: ".concat(action.type));
        }
    }
}
function CookieContextProvider(_a) {
    var children = _a.children;
    var _b = React.useReducer(cookieReducer, initialState), state = _b[0], dispatch = _b[1];
    var value = { state: state, dispatch: dispatch };
    return React.createElement(CountStateContext.Provider, { value: value }, children);
}
function useCookieContext() {
    var context = React.useContext(CountStateContext);
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider');
    }
    return context;
}
export { CookieContextProvider, useCookieContext };
