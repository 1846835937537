import { useTranslation } from '@grunfin/ui-kit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';
import { useDocumentTitle } from '~/utils';
import { useSession } from '~/modules/auth/SessionProvider';

export function CompanyGeneralView() {
  const { t } = useTranslation('company');
  const navigate = useNavigate();
  useDocumentTitle(t('general.document_title'));

  const company = useSession().companies?.filter((c) => !!c.accessibleBenefitPrograms.length)?.[0];
  useEffect(() => {
    if (!company) {
      navigate('/', { replace: true });
    } else {
      navigate(`/company/${company.companyId}/benefit/${company.accessibleBenefitPrograms?.[0]?.id}`, {
        replace: true,
      });
    }
  }, [company, navigate]);

  return null;
}
