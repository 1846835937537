var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { autoUpdate, flip, FloatingFocusManager, FloatingOverlay, FloatingPortal, offset, size, useDismiss, useFloating, useInteractions, useListNavigation, useRole, useTransitionStyles, } from '@floating-ui/react';
import { Input } from './Input';
import { twMerge } from 'tailwind-merge';
export var Combobox = function (props) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(''), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(null), activeIndex = _c[0], setActiveIndex = _c[1];
    var _d = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip({ padding: 10 }),
            size({
                apply: function (_a) {
                    var _b;
                    var rects = _a.rects, availableHeight = _a.availableHeight, elements = _a.elements;
                    Object.assign((_b = elements.floating) === null || _b === void 0 ? void 0 : _b.style, {
                        width: "".concat(rects.reference.width, "px"),
                        maxHeight: "".concat(availableHeight, "px"),
                    });
                },
                padding: 10,
            }),
        ],
    }), x = _d.x, y = _d.y, strategy = _d.strategy, refs = _d.refs, context = _d.context;
    var _e = useTransitionStyles(context, {
        initial: function (_a) {
            var side = _a.side;
            return ({
                opacity: 0,
                transform: {
                    top: 'translateY(10px)',
                    bottom: 'translateY(-10px)',
                    left: 'translateX(10px)',
                    right: 'translateX(-10px)',
                }[side],
            });
        },
        common: function (_a) {
            var side = _a.side;
            return ({
                transformOrigin: {
                    top: 'bottom',
                    bottom: 'top',
                    left: 'right',
                    right: 'left',
                }[side],
            });
        },
    }), isMounted = _e.isMounted, styles = _e.styles;
    var listRef = React.useRef([]);
    var role = useRole(context, { role: 'listbox' });
    var dismiss = useDismiss(context);
    var listNav = useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true,
    });
    var _f = useInteractions([role, dismiss, listNav]), getReferenceProps = _f.getReferenceProps, getFloatingProps = _f.getFloatingProps, getItemProps = _f.getItemProps;
    var onChange = function (event) {
        var value = event.target.value;
        setInputValue(value);
        if (typeof props.onInputChange === 'function')
            props.onInputChange(value);
        if (value) {
            setIsOpen(true);
            setActiveIndex(0);
        }
        else {
            setIsOpen(false);
        }
    };
    var onItemSelect = props.onItemSelect;
    var handleSelect = function (item) {
        setInputValue(item.label);
        setIsOpen(false);
        if (typeof onItemSelect === 'function')
            onItemSelect(item);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, __assign({}, getReferenceProps({
            ref: refs.setReference,
            onChange: onChange,
            disabled: props.disabled,
            value: inputValue,
            placeholder: props.placeholder,
            name: props.name,
            'aria-autocomplete': 'list',
            onKeyDown: function (event) {
                // Set selected item on pressing Enter key
                if (event.key === 'Enter' && activeIndex != null && !!props.options && props.options[activeIndex]) {
                    event.preventDefault();
                    handleSelect(props.options[activeIndex]);
                    setActiveIndex(null);
                }
            },
        }))),
        isMounted && (React.createElement(FloatingPortal, null,
            React.createElement(FloatingOverlay, { style: styles },
                React.createElement(FloatingFocusManager, { context: context, initialFocus: -1, visuallyHiddenDismiss: true },
                    React.createElement("div", __assign({}, getFloatingProps({
                        ref: refs.setFloating,
                        className: 'bg-white shadow-md rounded-md',
                        id: props.floatingId,
                        style: {
                            position: strategy,
                            left: x !== null && x !== void 0 ? x : 0,
                            top: y !== null && y !== void 0 ? y : 0,
                            overflowY: 'auto',
                        },
                    })),
                        Array.isArray(props.options) &&
                            props.options.map(function (item, index) { return (React.createElement("div", __assign({ key: item.value }, getItemProps({
                                ref: function (node) {
                                    listRef.current[index] = node;
                                },
                                onClick: function () {
                                    var _a;
                                    handleSelect(item);
                                    (_a = refs.domReference.current) === null || _a === void 0 ? void 0 : _a.focus();
                                },
                            }), { role: "option", id: item.label, "aria-selected": index === activeIndex, className: twMerge('text-left p-3 text-base text-green-800 flex w-full font-medium select-none', index === activeIndex && 'bg-green-100 hover:bg-green-100', index === 0 && 'rounded-t-md', index === props.options.length - 1 && 'rounded-b-md') }), item.label)); }),
                        Array.isArray(props.options) && props.options.length === 0 && (React.createElement("p", { className: "flex items-center justify-center p-3 text-base text-center text-gray-900 w-full font-medium select-none" }, props.noResultsText)),
                        !Array.isArray(props.options) && (React.createElement("p", { className: "flex items-center justify-center  p-3 text-base text-center text-gray-900 w-full font-medium select-none" }, props.loadingText)))))))));
};
