var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Checkbox, CheckboxGroup, FocusArea, Input, PreferredRiskReturnRatio, Select, Spinner, trackPublicCalcUpdate, useDebounce, } from '@grunfin/ui-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResultSection from './ResultSection';
import { API_URL, numberBetweenTreshhold } from '../../utils/utils';
import { twMerge } from 'tailwind-merge';
var yearOptions = Array.from({ length: 30 }, function (_, i) { return i + 1; });
var defaultFields = {
    upfrontInvestmentAmountEur: 1000,
    monthlyInvestmentAmountEur: 200,
    periodInYears: 12,
    focusArray: [FocusArea.CLIMATE_CHANGE],
    preferredRisk: PreferredRiskReturnRatio.High,
};
var MAX_UPFRONT_INVESTMENT = 1000000;
var MAX_MONTHLY_INVESTMENT = 10000;
export var Calculator = function () {
    var _a;
    var t = useTranslation('calculator').t;
    var calculatorRef = useRef(null);
    var _b = useState(defaultFields), fields = _b[0], setFields = _b[1];
    var errors = useState({})[0];
    var _c = useState(null), calculations = _c[0], setCalculations = _c[1];
    var lessThan3Years = ((fields === null || fields === void 0 ? void 0 : fields.periodInYears) || 0) < 3;
    var debouncedFields = useDebounce(fields, 500);
    var handleKeyChange = function (key, value) {
        setFields(function (prevState) {
            var _a, _b;
            if (prevState)
                return __assign(__assign({}, prevState), (_a = {}, _a[key] = value, _a));
            else
                return _b = {}, _b[key] = value, _b;
        });
    };
    var handleChange = function (e) {
        return handleKeyChange(e.target.name, e.target.value);
    };
    var calculate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, response, data, err_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (((_a = debouncedFields === null || debouncedFields === void 0 ? void 0 : debouncedFields.focusArray) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                        setFields(defaultFields);
                        return [2 /*return*/];
                    }
                    payload = __assign(__assign({}, debouncedFields), { areaClimateChange: (_b = debouncedFields === null || debouncedFields === void 0 ? void 0 : debouncedFields.focusArray) === null || _b === void 0 ? void 0 : _b.includes(FocusArea.CLIMATE_CHANGE), areaGenderEquality: (_c = debouncedFields === null || debouncedFields === void 0 ? void 0 : debouncedFields.focusArray) === null || _c === void 0 ? void 0 : _c.includes(FocusArea.GENDER_EQUALITY), areaHealth: (_d = debouncedFields === null || debouncedFields === void 0 ? void 0 : debouncedFields.focusArray) === null || _d === void 0 ? void 0 : _d.includes(FocusArea.HEALTH), areaSP500: (_e = debouncedFields === null || debouncedFields === void 0 ? void 0 : debouncedFields.focusArray) === null || _e === void 0 ? void 0 : _e.includes(FocusArea.SP500) });
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(API_URL + '/portfolioPreview/calculate', {
                            method: 'POST',
                            headers: new Headers({
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            }),
                            body: JSON.stringify(payload),
                        })];
                case 2:
                    response = _f.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _f.sent();
                    trackPublicCalcUpdate(payload);
                    if (data)
                        setCalculations(data);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _f.sent();
                    console.log('err', err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [debouncedFields]);
    /* Whenever debounced values update, we send a request to calculate */
    useEffect(function () {
        if (debouncedFields) {
            var noErrors = Object.keys(errors).length === 0;
            if (noErrors)
                calculate();
        }
    }, [debouncedFields, calculate, errors]);
    return (React.createElement("div", { className: "bg-forest-green-50 p-6 md:px-10 md:py-12", ref: calculatorRef, style: { borderRadius: '32px' } },
        React.createElement(CalculatorSection, { no: 1, label: t('step_one') },
            React.createElement(CheckboxGroup, { value: (_a = fields === null || fields === void 0 ? void 0 : fields.focusArray) !== null && _a !== void 0 ? _a : [], onChange: function (val) { return handleKeyChange('focusArray', val); } },
                React.createElement(Checkbox, { name: FocusArea.CLIMATE_CHANGE, label: React.createElement("span", { className: "md:text-lg font-normal" }, t('climate.title')) }),
                React.createElement("div", { className: "my-6" }),
                React.createElement(Checkbox, { name: FocusArea.GENDER_EQUALITY, label: React.createElement("span", { className: "md:text-lg font-normal" }, t('gender_equality.title')) }),
                React.createElement("div", { className: "my-6" }),
                React.createElement(Checkbox, { name: FocusArea.HEALTH, label: React.createElement("span", { className: "md:text-lg font-normal" }, t('health.title')) }),
                React.createElement("div", { className: "my-6" }),
                React.createElement(Checkbox, { name: FocusArea.SP500, label: React.createElement("span", { className: "md:text-lg font-normal" }, t('sp500.title')) }))),
        React.createElement(Separator, null),
        React.createElement(CalculatorSection, { no: 2, label: t('step_two'), className: "flex flex-col gap-y-10 md:pr-10" },
            React.createElement("div", { className: "flex flex-row items-center md:mr-4 mb-6" },
                React.createElement("div", { className: "md:mb-2 flex-shrink-0 w-44 md:mr-4" }, t('initial_investment')),
                React.createElement("div", { className: "max-w-xs md:w-40" },
                    React.createElement(Input, { name: "upfrontInvestmentAmountEur", type: "number", step: 1, max: MAX_UPFRONT_INVESTMENT, value: typeof fields.upfrontInvestmentAmountEur === 'string'
                            ? parseFloat(fields.upfrontInvestmentAmountEur)
                            : fields.upfrontInvestmentAmountEur === 0
                                ? 0
                                : fields.upfrontInvestmentAmountEur || 1000, onChange: function (e) {
                            return handleKeyChange('upfrontInvestmentAmountEur', numberBetweenTreshhold(+e.target.value, 0, MAX_UPFRONT_INVESTMENT));
                        }, suffix: "EUR" }))),
            React.createElement("div", { className: "flex flex-row items-center md:mr-4 mb-6" },
                React.createElement("div", { className: "md:mb-2 flex-shrink-0 w-44 md:mr-4" }, t('monthly_contribution')),
                React.createElement("div", { className: "max-w-xs md:w-40" },
                    React.createElement(Input, { type: "number", step: 1, max: MAX_MONTHLY_INVESTMENT, inputMode: "numeric", pattern: "[0-9]*", value: typeof fields.monthlyInvestmentAmountEur === 'string'
                            ? parseFloat(fields.monthlyInvestmentAmountEur)
                            : fields.monthlyInvestmentAmountEur === 0
                                ? 0
                                : fields.monthlyInvestmentAmountEur || 200, onChange: function (e) {
                            return handleKeyChange('monthlyInvestmentAmountEur', numberBetweenTreshhold(+e.target.value, 0, MAX_MONTHLY_INVESTMENT));
                        }, suffix: "EUR" })))),
        React.createElement(Separator, null),
        React.createElement(CalculatorSection, { no: 3, label: t('step_three') },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: "flex flex-col md:flex-row" },
                    React.createElement("div", { className: "flex flex-row md:flex-col items-center col-span-full md:col-span-2 gap-x-4 md:block md:mr-4 mb-6 md:mb-0" },
                        React.createElement("div", { className: "md:mb-2 flex-shrink-0 w-40" }, t('risk_preference')),
                        React.createElement(Select, { name: "preferredRisk", value: fields === null || fields === void 0 ? void 0 : fields.preferredRisk, onChange: handleChange, required: true, error: 'preferredRisk' in errors }, Object.keys(PreferredRiskReturnRatio).map(function (key) {
                            var isDisabled = lessThan3Years &&
                                (PreferredRiskReturnRatio[key] === PreferredRiskReturnRatio.High ||
                                    PreferredRiskReturnRatio[key] === PreferredRiskReturnRatio.Medium);
                            return (React.createElement("option", { key: key, value: key.toUpperCase(), disabled: isDisabled }, t("risk.".concat(key.toLowerCase()))));
                        }))),
                    React.createElement("div", { className: "col-span-full md:col-span-2 flex items-center md:block gap-x-4" },
                        React.createElement("div", { className: "md:mb-2 flex-shrink-0 w-40" }, t('period')),
                        React.createElement(Select, { name: "periodInYears", value: fields === null || fields === void 0 ? void 0 : fields.periodInYears, onChange: function (e) {
                                return handleKeyChange(e.target.name, +e.target.value);
                            }, required: true, error: 'periodInYears' in errors }, yearOptions.map(function (y) { return (React.createElement("option", { key: y, value: y }, t('period_years', { count: y }))); })))),
                lessThan3Years && (React.createElement("div", { className: "text-sm text-dawn-300 mt-2 col-span-full" }, t('playground.low_risk_period'))))),
        React.createElement(Separator, null),
        React.createElement(CalculatorSection, { no: 4, label: t('step_four') }, calculations ? (React.createElement(ResultSection, { portfolio: calculations })) : (React.createElement("div", { className: "flex w-full items-center justify-center my-8" },
            React.createElement(Spinner, null))))));
};
var Separator = function () { return React.createElement("hr", { className: "border-gray-200 my-8" }); };
var CalculatorSection = function (_a) {
    var no = _a.no, label = _a.label, children = _a.children;
    var noClass = twMerge("w-10 h-10 md:w-12 md:h-12 md:mb-3 rounded-full inline-flex md:flex items-center justify-center mr-4 md:mr-0", no === 1 ? 'bg-forest-green-100' : no === 2 ? 'bg-sky-blue-100' : no === 3 ? 'bg-dusk-100' : 'bg-gray-100');
    return (React.createElement("div", { className: "grid grid-cols-12" },
        React.createElement("div", { className: "md:text-lg font-bold md:font-semibold col-span-full md:col-span-5 gap-y-8 mb-8 md:mb-0" },
            React.createElement("div", { className: noClass }, no),
            label),
        React.createElement("div", { className: "col-span-full md:col-span-7" }, children)));
};
