var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgFingerprintIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m12.0007 7.20357c1.3264-.76435 2.8578-1.20357 4.4993-1.20357 4.9723 0 9 4.0277 9 9 0 2.5644-.3634 5.0561-1.0278 7.4025-.1505.5314-.7033.8401-1.2347.6897-.5314-.1505-.8401-.7033-.6897-1.2347.6156-2.1736.9522-4.4819.9522-6.8575 0-3.8677-3.1323-7-7-7-1.2785 0-2.4671.34078-3.5007.93643-.4785.27575-1.09.11138-1.3657-.36713-.2758-.47852-.1114-1.08997.3671-1.36573zm4.4993 4.79643c-1.6577 0-3 1.3423-3 3 0 2.9073-.8212 5.6204-2.2574 7.915-.015.03-.029.0548-.0401.0739-.0245.0421-.0497.0797-.0586.0931l-.0001.0001-.0018.0026c-.3063.4595-.9272.5837-1.3867.2774-.45137-.301-.57919-.9053-.29327-1.3622.01589-.0324.03352-.0639.05285-.0945 1.26062-1.9928 1.98512-4.3579 1.98512-6.9054 0-2.7623 2.2377-5 5-5s5 2.2377 5 5c0 1.0659-.0732 2.1099-.2087 3.1315-.0726.5475-.5753.9324-1.1228.8598s-.9324-.5753-.8598-1.1228c.1245-.9384.1913-1.8944.1913-2.8685 0-1.6577-1.3423-3-3-3zm0 2c.5523 0 1 .4477 1 1 0 3.714-1.0662 7.1776-2.9032 10.1019-.2938.4677-.911.6087-1.3787.3149s-.6087-.9111-.3149-1.3787c1.643-2.6157 2.5968-5.7121 2.5968-9.0381 0-.5523.4477-1 1-1zm4.1315 7.2747c.1517-.531-.1558-1.0845-.6868-1.2362s-1.0845.1557-1.2362.6868c-.3634 1.2717-.8411 2.5039-1.4229 3.6675-.247.494-.0468 1.0946.4472 1.3416s1.0946.0468 1.3416-.4472c.6382-1.2764 1.1605-2.6242 1.5571-4.0125zm-10.1951-9.7754c.2758-.4785.1114-1.09-.3671-1.3657-.47852-.27578-1.08998-.1114-1.36573.3671-.76435 1.3264-1.20357 2.8578-1.20357 4.4993 0 1.4047-.3239 2.7359-.89981 3.9237-.24095.497-.03342 1.0952.46354 1.3361.49695.241 1.09514.0334 1.33608-.4635.70409-1.4522 1.10019-3.081 1.10019-4.7963 0-1.2785.34078-2.4671.9364-3.5007z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgFingerprintIcon);
var Memo = memo(ForwardRef);
export default Memo;
