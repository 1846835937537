import React from 'react';
import InfoIcon from '../icons/InfoIcon';
import CheckIcon from '../icons/CheckIcon';
import { twMerge } from 'tailwind-merge';
export var MessageLevel;
(function (MessageLevel) {
    MessageLevel["SUCCESS"] = "success";
    MessageLevel["INFO"] = "info";
    MessageLevel["WARN"] = "warn";
    MessageLevel["ERROR"] = "error";
})(MessageLevel || (MessageLevel = {}));
export var Message = function (_a) {
    var _b = _a.level, level = _b === void 0 ? 'info' : _b, className = _a.className, children = _a.children;
    var messageClass = twMerge("rounded py-4 px-8 md:px-4 flex gap-4 items-start md:items-start font-medium", level === MessageLevel.INFO && 'bg-gray-50', level === MessageLevel.WARN && 'bg-dawn-50', level === MessageLevel.ERROR && 'bg-dawn-200', level === MessageLevel.SUCCESS && 'bg-forest-green-200');
    var iconClass = twMerge("rounded flex-shrink-0", level === MessageLevel.SUCCESS && 'bg-white text-forest-green-700', level === MessageLevel.WARN && 'bg-dawn-200 text-gray-900', level === MessageLevel.ERROR && 'bg-dawn-400 text-white', level === MessageLevel.INFO && 'bg-alps-blue-400 text-white');
    var childClass = twMerge("self-center", className);
    return (React.createElement("div", { className: messageClass },
        level === MessageLevel.SUCCESS ? (React.createElement(CheckIcon, { width: 32, height: 32, className: iconClass })) : (React.createElement(InfoIcon, { width: 32, height: 32, className: iconClass })),
        React.createElement("div", { className: childClass }, typeof children === 'string' ? React.createElement("p", null, children) : children)));
};
