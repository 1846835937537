import { Spinner, useTranslation } from '@grunfin/ui-kit';
import { captureMessage } from '@sentry/react';
import { useParams } from 'react-router-dom';
import 'twin.macro';
import { CampaignReferral } from '~/modules/referral/CampaignReferral';
import { PersonalReferral } from '~/modules/referral/PersonalReferral';
import { useGetReferralProgramInfo } from '~/modules/referral/queries';
import { ContactSupportOverlay } from '../support/ContactSupportOverlay';

export const ReferralPage = () => {
  const { t } = useTranslation('referral');
  const referralCode = useParams<'*'>()['*'];
  const referralInfo = useGetReferralProgramInfo(referralCode ?? '');
  const referrerName = referralInfo?.data?.creatorGivenName;

  if (referralInfo.isIdle || referralInfo.isLoading) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (referralInfo.isError || !referralCode) {
    if (typeof referralCode === 'string' && referralCode.length > 0)
      captureMessage(`referral code {${referralCode}} query failed or does not exist`, 'info');

    return (
      <ContactSupportOverlay title={t('invalid_code')} onClose={referralInfo.refetch} error={referralInfo.error} />
    );
  }

  return referrerName ? (
    <PersonalReferral referralInfo={referralInfo} />
  ) : (
    <CampaignReferral referralInfo={referralInfo} />
  );
};
