var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgHeartIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M11.9263 9C9.10144 9 7 11.1164 7 14.1132C7 18.7354 12.0986 22.7481 15.0957 24.5937C15.3885 24.763 15.7847 25 16.0258 25C16.267 25 16.6287 24.763 16.9043 24.5937C19.867 22.7143 25 18.7354 25 14.1132C25 11.1164 22.8986 9 20.0565 9C18.2306 9 16.801 10.0328 15.9914 11.472C15.1818 10.0328 13.7866 9 11.9263 9Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgHeartIcon);
var Memo = memo(ForwardRef);
export default Memo;
