import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { DecisionStatus, Portfolio, useTranslation } from '@grunfin/ui-kit';
import type { HTTPError } from 'ky';

import { getQuestionnaireId } from '~/modules/onboarding/utils';

import type {
  Company,
  DraftPortfolio,
  IdentityVerificationStatus,
  PortfolioChild,
  PortfolioContract,
  PortfolioDirectDebitResponse,
  PortfolioImpactResponse,
  PortfolioPerformancePaymentsResponse,
  PortfolioPerformancePredictionResponse,
  PortfolioPerformanceResponse,
  PortfolioStatement,
  PortfolioStatusResponse,
  PortfolioTransaction,
  PublicPortfolio,
  SigningAttempt,
  StandingOrderInstruction,
  StripeSessionRequest,
  StripeSessionResponse,
  SubscribeConsentToPortfolioPayload,
  TopUpRequest,
  TopUpResponse,
  UpdatePortfolio,
} from './types';
import { Contribution, ContributionSettings, OneTimePayment } from './types';
import { api, ServerError } from '~/api';
import { QuestionnaireType } from '../onboarding/types';

export const useGetPortfolioContract = (id: string) =>
  useQuery<PortfolioContract, Error>(
    ['portfolio-contract', id],
    async () => api.get(`portfolio/${id}/contract`).json<PortfolioContract>(),
    { enabled: !!id },
  );

export const useSignContract = () => {
  const queryClient = useQueryClient();

  return useMutation<SigningAttempt, ServerError, string>(
    async (portfolioId) => api.post(`portfolio/${portfolioId}/contract/sign`).json<SigningAttempt>(),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('portfolio');
      },
    },
  );
};

export const useUpdatePortfolioDraft = (id: string, queryKey: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (json: DraftPortfolio) => api.post(`portfolio/${id}/updateDraft`, { json }).json<Portfolio>(),
    {
      mutationKey: ['portfolio', queryKey, 'updateDraft'],
      onSuccess: (data) => {
        queryClient.setQueryData<Portfolio[]>('portfolio', (oldData = []) => {
          const idx = oldData.findIndex((r) => r.id === data.id);
          if (idx === -1) return oldData;
          oldData[idx] = data;
          return oldData;
        });
      },
    },
  );
};

export const useUpdatePortfolio = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<Portfolio, Error, UpdatePortfolio>(
    async (json) => api.put(`portfolio/${id}`, { json }).json<Portfolio>(),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['portfolio', id], data);
        queryClient.setQueryData<Portfolio[]>('portfolio', (oldData = []) => {
          const idx = oldData.findIndex((r) => r.id === data.id);
          if (idx === -1) return oldData;
          oldData[idx] = data;
          return oldData;
        });
      },
    },
  );
};

export const useCreatePortfolio = (type: QuestionnaireType) => {
  const queryClient = useQueryClient();

  return useMutation<Portfolio, Error>(
    async () => api.post(`portfolio/create`, { json: { questionnaireId: getQuestionnaireId(type) } }).json<Portfolio>(),
    {
      onSuccess: async (portfolio) => {
        queryClient.setQueryData<Portfolio[]>('portfolio', (portfolios = []) => [portfolio, ...portfolios]);
        await queryClient.invalidateQueries('session');
      },
    },
  );
};

export const useDeletePortfolio = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(async () => api.delete(`portfolio/${id}`).json(), {
    onSuccess: () => {
      queryClient.setQueryData<Portfolio[]>('portfolio', (oldData = []) => {
        const newList = oldData.filter((p) => p.id !== id);
        if (newList.length === 0) queryClient.refetchQueries('session');
        return newList;
      });
    },
  });
};

export const useExitPortfolio = (id: string) => useMutation(async () => api.post(`portfolio/${id}/exit`).json());

export const useGetExitStatus = (id: string) =>
  useQuery<DecisionStatus[], Error>(['portfolio_decisions', id], async () =>
    api.get(`portfolio/${id}/exit`).json<DecisionStatus[]>(),
  );

export const useListPortfolios = (options?: UseQueryOptions<Portfolio[], Error>) =>
  useQuery<Portfolio[], Error>(
    'portfolio',
    async () => api.get('portfolio/', { timeout: 180000 }).json<Portfolio[]>(),
    options,
  );

export const useGetPortfolio = (id: string, options?: UseQueryOptions<Portfolio, Error>) =>
  useQuery<Portfolio, Error>(
    ['portfolio', id],
    async () => api.get(`portfolio/${id}`, { timeout: 180000 }).json<Portfolio>(),
    options,
  );

export const useGetPublicPortfolio = (id?: string, options?: UseQueryOptions<PublicPortfolio, Error>) =>
  useQuery<PublicPortfolio, Error>(
    ['portfolio', id, 'public'],
    async () => api.get(`portfolio/${id}/public`).json<PublicPortfolio>(),
    options,
  );

export const usePortfolioStatus = (id: string, options?: UseQueryOptions<PortfolioStatusResponse, HTTPError>) =>
  useQuery<PortfolioStatusResponse, HTTPError>(
    ['portfolio', id, 'status'],
    async () => api.get(`portfolio/${id}/activationStatus`).json<PortfolioStatusResponse>(),
    options,
  );

export const usePortfolioImpact = (id: string, options?: UseQueryOptions<PortfolioImpactResponse, HTTPError>) =>
  useQuery<PortfolioImpactResponse, HTTPError>(
    ['portfolio', id, 'impact'],
    async () => api.get(`portfolio/${id}/impact`).json<PortfolioImpactResponse>(),
    options,
  );

export const usePortfolioPerformanceValue = (portfolioId: string) =>
  useQuery<PortfolioPerformanceResponse[], Error>(['portfolio-value', portfolioId], async () =>
    api.get(`portfolio/${portfolioId}/performance/value`).json<PortfolioPerformanceResponse[]>(),
  );

export const usePortfolioPerformanceValuePrediction = (portfolioId: string, endDate: string) =>
  useQuery<PortfolioPerformancePredictionResponse[], Error>(['portfolio-value-prediction', portfolioId], async () =>
    api
      .get(`portfolio/${portfolioId}/performance/valuePrediction?endDate=${endDate}`)
      .json<PortfolioPerformancePredictionResponse[]>(),
  );

export const usePortfolioPerformanceCumulativePayments = (portfolioId: string) =>
  useQuery<PortfolioPerformancePaymentsResponse[], Error>(['portfolio-value-payments', portfolioId], async () =>
    api.get(`portfolio/${portfolioId}/performance/cumulativePayments`).json<PortfolioPerformancePaymentsResponse[]>(),
  );

export const useGetPortfolioStandingOrderInstruction = (id?: string) =>
  useQuery<StandingOrderInstruction, Error>(['standing-order-instruction', id], async () =>
    api.get(`portfolio/${id}/standing-order-instruction`).json<StandingOrderInstruction>(),
  );

export const useGetDirectDebitConsents = (id: string) =>
  useQuery<PortfolioDirectDebitResponse, Error>(['direct-debit', id], async () =>
    api.get(`portfolio/${id}/direct-debit`).json<PortfolioDirectDebitResponse>(),
  );

export const useStartStripeSession = (portfolioId: string) =>
  useMutation(async (payload: StripeSessionRequest) =>
    api
      .post(`portfolio/${portfolioId}/direct-debit/stripe/session`, { json: payload, timeout: 180000 })
      .json<StripeSessionResponse>(),
  );

export const useDirectDebitTopUp = (id: string) =>
  useMutation(async (payload: TopUpRequest) =>
    api.post(`portfolio/${id}/direct-debit/top-up`, { json: payload, timeout: 180000 }).json<TopUpResponse>(),
  );

export const useDirectDebitThirdPartyPayment = (id?: string) =>
  useMutation(async (payload: OneTimePayment) =>
    api.post(`portfolio/${id}/direct-debit/one-time-payment`, { json: payload, timeout: 180000 }).json<TopUpResponse>(),
  );

export const useRevokeConsent = (portfolioId: string) =>
  useMutation(async (consentId: string) =>
    api.delete(`portfolio/${portfolioId}/direct-debit/subscription/consent/${consentId}`, { timeout: 180000 }),
  );

export const useSubscribePortfolioToDirectDebitConsent = () =>
  useMutation<unknown, Error, SubscribeConsentToPortfolioPayload>(async ({ portfolioId, consentId }) =>
    api.post(`portfolio/${portfolioId}/direct-debit/subscription/consent/${consentId}`, { timeout: 180000 }),
  );

export const useGetPortfolioTransactions = (id?: string) => {
  const { i18n } = useTranslation();
  return useQuery<PortfolioTransaction[], Error>(['payments', id, i18n.resolvedLanguage], async () =>
    api.get(`portfolio/${id}/payments`).json<PortfolioTransaction[]>(),
  );
};

export const useGetPortfolioStatements = (id: string) =>
  useQuery<PortfolioStatement[], Error>(['statements', id], async () =>
    api.get(`portfolio/${id}/statements`).json<PortfolioStatement[]>(),
  );

export const useGetPortfolioContributionSettings = (
  id?: string,
  options?: UseQueryOptions<ContributionSettings, Error>,
) =>
  useQuery<ContributionSettings, Error>(
    ['contributionSettings', id],
    async () => api.get(`portfolio/${id}/contributionSettings`, { timeout: 180000 }).json<ContributionSettings>(),
    options,
  );

export const useUpdatePortfolioContributionSettings = (id?: string) =>
  useMutation(async (payload: ContributionSettings) =>
    api.put(`portfolio/${id}/contributionSettings`, { json: payload, timeout: 180000 }),
  );

export const useCreateContribution = (id?: string) =>
  useMutation(async (payload: Contribution) => api.put(`portfolio/${id}/contribution`, { json: payload }));

export const useGetIdentityVerificationStatus = (options?: UseQueryOptions<IdentityVerificationStatus, Error>) =>
  useQuery<IdentityVerificationStatus, Error>(
    'verification-status',
    async () => api.get('verification/id-verification/status').json<IdentityVerificationStatus>(),
    options,
  );

export const useGetPortfolioChild = (id: string, enabled: boolean) =>
  useQuery(['portfolio-child', id], async () => api.get(`portfolio/${id}/child`).json<PortfolioChild>(), { enabled });

export const useGetPortfolioCompany = (id: string, enabled: boolean) =>
  useQuery(['portfolio-company', id], async () => api.get(`portfolio/${id}/company`).json<Company>(), { enabled });

export const useSubmitPortfolioChild = () =>
  useMutation(async ({ id, ...json }: PortfolioChild) =>
    api.post(`portfolio/${id}/child`, { json }).json<PortfolioChild>(),
  );

export const useCreateCompany = () =>
  useMutation(async ({ portfolioId, company }: any) =>
    api.post(`portfolio/${portfolioId}/company`, { json: company }).json<Company>(),
  );
