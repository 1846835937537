import { Portfolio, PortfolioStatus, useTranslation } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';
import tw from 'twin.macro';

interface Props {
  portfolio: Portfolio;
  children: ReactNode;
}

export const Title = ({ portfolio, children }: Props) => {
  const { t } = useTranslation('portfolio');

  const name = portfolio?.name || t('portfolio');
  const isExited = portfolio.status === PortfolioStatus.EXITED;
  return (
    <div tw="flex justify-between items-center px-6 md:px-12 pt-12">
      <Link
        to={portfolio?.id ? `/portfolio/${portfolio.id}` : '/'}
        tw="text-lg font-bold md:font-semibold md:text-xl text-alps-blue-700 hover:underline decoration-gray-200"
        css={[portfolio?.id && tw`cursor-pointer`]}
      >
        {name}
        <span tw="text-gray-300"> {portfolio?.referenceNumber}</span>
        {isExited && <span tw="text-gray-300"> {t(`status.${PortfolioStatus.EXITED}`)}</span>}
      </Link>
      {children}
    </div>
  );
};
