import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { EuropeanUnionIcon, PadlockIcon } from '../icons';
import { twMerge } from 'tailwind-merge';
export var TrustBlock = function (_a) {
    var reverse = _a.reverse, _b = _a.showSecureConnection, showSecureConnection = _b === void 0 ? true : _b;
    var _c = useTranslation(), t = _c.t, i18n = _c.i18n;
    var ref = React.useRef(null);
    useEffect(function () {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    var className = twMerge('w-full flex flex-wrap lg:flex-nowrap items-center gap-4 p-4 justify-center md:justify-between bg-red backdrop-blur-lg bg-opacity-25 bg-white rounded-2xl', reverse && 'flex-col-reverse sm:flex-row-reverse');
    var getLink = function () {
        switch (i18n.resolvedLanguage) {
            case 'en':
                return 'https://faq.grunfin.com/en/articles/5499-is-my-money-safe-in-grunfin';
            case 'de':
                return 'https://faq.grunfin.com/de/articles/5499-ist-mein-geld-bei-grunfin-sicher';
            case 'et':
                return 'https://faq.grunfin.com/et/articles/5499-kas-minu-vara-on-grunfinis-turvaliselt-hoitud';
            default:
                return 'https://faq.grunfin.com/en/articles/5499-is-my-money-safe-in-grunfin';
        }
    };
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { style: { marginRight: '1rem', display: 'flex', overflow: 'hidden', borderRadius: '50%' } },
                React.createElement(EuropeanUnionIcon, { width: 44, height: 44 })),
            React.createElement("a", { className: "text-gray-900 font-medium text-base", href: getLink(), target: "_blank", rel: "noopener noreferrer" }, t('assets_protected'))),
        showSecureConnection && (React.createElement("div", { className: "flex flex-row items-center gap-4" },
            React.createElement(PadlockIcon, { className: "text-dawn-900 bg-dawn-100 rounded-md" }),
            React.createElement("p", { className: "text-gray-900 font-medium text-base" }, t('secure_connection')))),
        React.createElement("div", { ref: ref, className: "trustpilot-widget min-w-max bg-white rounded p-2 [&>*>svg]:overflow-visible", "data-locale": i18n.resolvedLanguage == 'de' ? 'de' : 'en', "data-template-id": "5419b732fbfb950b10de65e5", "data-businessunit-id": "618a709c605f91ac2e088e62", "data-style-height": "20px", "data-style-width": "100%", "data-theme": "light", "data-text-color": "#252324" },
            React.createElement("a", { href: 'https://' + i18n.resolvedLanguage == 'de' ? 'de' : 'www' + '.trustpilot.com/review/www.grunfin.com', target: "_blank", rel: "noopener noreferrer" }, "Trustpilot"))));
};
export default TrustBlock;
