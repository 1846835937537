import { useMutation } from 'react-query';
import { api } from '~/api';
import { ApplyVoucherInput } from '~/modules/gift/voucher/types';

export const useValidateVoucher = () =>
  useMutation<unknown, Error, string>(async (voucherCode: string) =>
    api.post(`gift/voucher/validate`, {
      searchParams: { voucherCode },
    }),
  );

export const useApplyVoucher = () =>
  useMutation<unknown, Error, ApplyVoucherInput>(async (input) =>
    api.post(`gift/voucher/apply`, {
      json: input,
    }),
  );
