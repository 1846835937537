import React, { useId, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { trackNavigation } from '../utils';
import { MoreIcon, PlusIcon } from '../icons';
import { twMerge } from 'tailwind-merge';
var DEFAULT_PREVIEW_COUNT = 3;
export var FAQ = function (_a) {
    var questions = _a.questions, _b = _a.previewItemsCount, previewItemsCount = _b === void 0 ? DEFAULT_PREVIEW_COUNT : _b, onOpenFAQItem = _a.onOpenFAQItem, onCloseFAQItem = _a.onCloseFAQItem, _c = _a.style, style = _c === void 0 ? 'GREEN' : _c, _d = _a.viewAll, viewAll = _d === void 0 ? true : _d;
    var isBlue = style === 'BLUE';
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var id = useId();
    var _f = useState(false), isExpanded = _f[0], setIsExpanded = _f[1];
    if (!Array.isArray(questions) || !questions.length)
        return null;
    var canExpand = questions.length > previewItemsCount;
    var showExpandButton = canExpand && !isExpanded;
    var showViewAll = viewAll && (questions.length <= previewItemsCount || isExpanded);
    var previewQuestions = isExpanded ? questions : questions.slice(0, previewItemsCount);
    return (React.createElement("div", { className: twMerge('flex flex-col gap-6 w-full p-4', isBlue && ' bg-alps-blue-50 rounded-xl') },
        previewQuestions.map(function (question, i) {
            var _a;
            return (React.createElement("details", { key: i, className: "rounded-md", id: "".concat(id, "-").concat(i) },
                React.createElement("summary", { className: "flex [&::marker]:hidden [&::-webkit-details-marker]:hidden rounded-md focus:ring-2 focus:ring-alps-blue-200 focus:bg-neutral-50 hover:bg-neutral-50", onClick: function () {
                        var el = document.getElementById("".concat(id, "-").concat(i));
                        if (el != null) {
                            if (!el.hasAttribute('open')) {
                                if (typeof onOpenFAQItem === 'function')
                                    onOpenFAQItem(question);
                            }
                            else {
                                if (typeof onCloseFAQItem === 'function')
                                    onCloseFAQItem(question);
                            }
                        }
                    } },
                    React.createElement(FAQTitle, { style: style, title: question.title })),
                React.createElement("div", { className: twMerge("py-4 px-2 sm:!p-6 md:!px-14 max-w-3xl", isBlue && "text-base") },
                    React.createElement(Trans, { components: (_a = question === null || question === void 0 ? void 0 : question.links) === null || _a === void 0 ? void 0 : _a.map(function (link, i) { return (React.createElement("a", { href: link, target: link.includes('http') ? '_blank' : '_self', rel: "noreferrer", className: "text-base font-semibold text-alps-blue-600 underline", key: i, onClick: function () { return trackNavigation(link); } })); }) }, question.content))));
        }),
        showExpandButton && (React.createElement("button", { type: "button", className: "flex [&_h4]:text-neutral-500", onClick: function () { return setIsExpanded(true); } },
            React.createElement(FAQTitle, { style: style, icon: React.createElement(MoreIcon, null), title: t('default:view_all') }))),
        showViewAll && (React.createElement("a", { href: "https://faq.grunfin.com/".concat(i18n.language), target: "_blank", rel: "noopener", className: "flex [&_h4]:text-neutral-500", onClick: function () { return setIsExpanded(true); } },
            React.createElement(FAQTitle, { style: style, icon: React.createElement(MoreIcon, null), title: t('default:view_all_faq') })))));
};
var FAQTitle = function (_a) {
    var icon = _a.icon, style = _a.style, title = _a.title;
    var isBlue = style === 'BLUE';
    var iconClass = twMerge("rounded-full w-6 h-6 min-w-[24px] md:w-8 md:h-8 bg-green-50 text-green-700", isBlue && "bg-white text-alps-blue-600");
    return (React.createElement("div", { className: "rounded flex flex-row items-center gap-4 cursor-pointer w-full" },
        icon != null ? React.cloneElement(icon, { className: iconClass }) : React.createElement(PlusIcon, { className: iconClass }),
        React.createElement("p", { className: twMerge("font-semibold text-neutral-900", isBlue && "text-base") }, title)));
};
