var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgGrunfinLogo = function (props, ref) { return (React.createElement("svg", __assign({ width: 20, height: 20, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.9882 16.6199C31.9961 16.4142 32 16.2076 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 21.9859 3.28711 27.204 8.15471 29.9478C10.4728 31.2545 13.1493 32 16 32C24.2843 32 31.098 25.704 31.9174 17.6359C31.9515 17.3002 31.9752 16.9614 31.9882 16.6199ZM9.42425 27.9075L19.8613 11.1337C20.7379 9.72476 22.6406 9.38305 23.9527 10.3989L29.5411 14.7253C28.8984 7.81196 23.0812 2.4 16 2.4C8.48893 2.4 2.4 8.48893 2.4 16C2.4 21.1259 5.23585 25.5895 9.42425 27.9075Z", fill: "#2B6A74" }),
    React.createElement("path", { d: "M17.6 9.20001C17.6 10.7464 16.3464 12 14.8 12C13.2536 12 12 10.7464 12 9.20001C12 7.65362 13.2536 6.40001 14.8 6.40001C16.3464 6.40001 17.6 7.65362 17.6 9.20001Z", fill: "#2B6A74" }),
    React.createElement("path", { opacity: 0.56, d: "M5.77527 28.3082C3.06234 26.0518 1.10735 22.9153 0.348438 19.3369L8.38757 13.7714C9.02986 13.3267 9.89934 13.4109 10.4444 13.9705L13.2002 16.7995L5.77527 28.3082Z", fill: "#2B6A74" }))); };
var ForwardRef = forwardRef(SvgGrunfinLogo);
var Memo = memo(ForwardRef);
export default Memo;
