import { Link, Outlet } from 'react-router-dom';
import 'twin.macro';

import { LanguagePicker, LanguagePickerSize } from '@grunfin/ui-kit';
import splashBg from '~/assets/output/splash-bg.webp';

export const SplashLayout = ({ bg }: { bg?: string }) => (
  <div tw="flex flex-col h-screen overflow-x-hidden">
    <div tw="flex items-center justify-between gap-4 p-4 md:p-8">
      <Link to="/">
        <img src="/logo-full.svg" alt="logo" />
      </Link>
      <LanguagePicker size={LanguagePickerSize.COMPACT} offsetOptions={{ mainAxis: 20, crossAxis: -15 }} />
    </div>
    <div tw="py-4 mx-3 md:mx-4 flex flex-1 flex-col justify-center items-center">
      <Outlet />
    </div>

    <img tw="absolute w-full h-full min-h-screen object-cover object-center -z-10" src={bg ?? splashBg} />
  </div>
);
