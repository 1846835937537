import {
  Button,
  ChevronIcon,
  instrumentColorMap,
  useTranslation,
  formatPercent,
  PortfolioAsset,
  ButtonVariant,
  ButtonSize,
} from '@grunfin/ui-kit';
import { useState } from 'react';
import tw from 'twin.macro';

import { trackWhitepaperOpen } from '~/utils/tracking-analytics';

interface Props {
  instruments: PortfolioAsset[];
}

export const PortfolioInstruments = ({ instruments }: Props) => {
  const { t } = useTranslation('portfolio');
  const [isOpen, setOpen] = useState(false);

  return (
    <div tw="mx-6 md:mx-12 py-8 grid grid-cols-1 md:grid-cols-2 gap-16">
      {isOpen &&
        instruments.map((i) => (
          <div key={i.instrumentId} tw="col-span-1">
            <div tw="flex justify-between items-center">
              <div
                css={[
                  tw`rounded text-white px-2 py-0.5 mr-6`,
                  { backgroundColor: instrumentColorMap[i?.isin] || '#92BA92' },
                ]}
              >
                {i.name}
              </div>
              <span tw="font-semibold">{i.targetShare != null && formatPercent(i.targetShare)}</span>
            </div>
            <div tw="mt-4">{i.description}</div>
            <div tw="mt-4 w-max">
              <a
                href={i.whitePaperUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackWhitepaperOpen(i.whitePaperUrl)}
              >
                <Button variant={ButtonVariant.SECONDARY}>{t('read_more', { ns: 'general' })}</Button>
              </a>
            </div>
          </div>
        ))}

      <div
        tw="flex items-center justify-center text-lg cursor-pointer select-none col-span-full w-max mx-auto"
        onClick={() => setOpen(!isOpen)}
      >
        <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE}>
          <ChevronIcon css={[tw`transform transition-transform -ml-2`, isOpen && tw` rotate-180`]} />
          <span>{t('instruments')}</span>
        </Button>
      </div>
    </div>
  );
};
