var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgEstonianIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("mask", { id: "mask0_3887_16283", style: {
            maskType: 'alpha',
        }, maskUnits: "userSpaceOnUse", x: 0, y: 0, width: 24, height: 24 },
        React.createElement("circle", { cx: 12, cy: 12, r: 12, fill: "white" })),
    React.createElement("g", { mask: "url(#mask0_3887_16283)" },
        React.createElement("rect", { width: 24, height: 8, fill: "#0072CE" }),
        React.createElement("rect", { y: 16, width: 24, height: 8, fill: "white" }),
        React.createElement("rect", { y: 8, width: 24, height: 8, fill: "black" })))); };
var ForwardRef = forwardRef(SvgEstonianIcon);
var Memo = memo(ForwardRef);
export default Memo;
