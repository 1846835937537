import { BankButtons, Button, ButtonVariant, CheckIcon, formatCurrency, useTranslation } from '@grunfin/ui-kit';
import { useNavigate, useParams } from 'react-router-dom';
import 'twin.macro';
import { CompanyPaymentsResponse } from '../queries';
import { TransactionPayment } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from '~/modules/auth/SessionProvider';

const JournalPaymentsUploadSuccess = ({
  rows,
  payments,
}: {
  rows: TransactionPayment[];
  payments: CompanyPaymentsResponse | undefined;
}) => {
  const { t, i18n } = useTranslation('company');
  const navigate = useNavigate();
  const { companyId, benefitId } = useParams();
  const session = useSession();

  const employeeAmountTotal = rows.reduce((acc, row) => acc + parseFloat(row.employeeAmount), 0);
  const employerAmountTotal = rows.reduce((acc, row) => acc + parseFloat(row.employerAmount), 0);

  return (
    <div tw="bg-white rounded-2xl flex flex-col md:flex-row gap-4">
      <div tw="flex flex-1 flex-col gap-8 md:gap-14 p-4 md:p-8 lg:p-14">
        <div tw="max-w-max">
          <LargeCheckIcon tw="bg-critical" />
        </div>
        <div tw="max-w-[320px]">
          <h1 tw="text-lg md:text-xl font-bold text-alps-blue-700">{t('payments.upload.success.title')}</h1>
          <p tw="text-lg md:text-xl text-alps-blue-700">{t('payments.upload.success.subtitle')}</p>
        </div>
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => navigate(`/company/${companyId}/benefit/${benefitId}`)}
          tw="max-w-max"
        >
          {t('general.success.view')}
        </Button>
      </div>
      <div tw="flex flex-1 flex-col gap-14 bg-gray-50 p-4 md:p-8 lg:p-14 rounded-b-2xl md:rounded-b-none md:rounded-tr-2xl md:rounded-br-2xl">
        <div tw="flex flex-col gap-4">
          <h4 tw="font-bold text-lg">{t('payments.upload.success.payment')}</h4>
          <hr tw="border-2 border-gray-900" />
          <ol tw="flex flex-col gap-4">
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payments.upload.success.employees_covered')}</span>
              <span tw="text-gray-900 font-medium">{rows.length}</span>
            </li>
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payments.upload.success.employee_sum')}</span>
              <span tw="text-gray-900 font-medium">{formatCurrency(employeeAmountTotal, { digits: 2 })}</span>
            </li>
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payments.upload.success.employer_sum')}</span>
              <span tw="text-gray-900 font-medium">{formatCurrency(employerAmountTotal, { digits: 2 })}</span>
            </li>
          </ol>
          <hr tw="border-2 border-gray-900" />
          <div tw="flex flex-row justify-between items-center">
            <span tw="font-normal text-lg">{t('payments.upload.success.total')}</span>
            <span tw="font-bold text-lg">{formatCurrency(payments?.amount ?? 0, { digits: 2 })}</span>
          </div>
        </div>
        <div tw="flex flex-col gap-4">
          <h4 tw="font-bold text-lg">{t('payments.upload.success.bank')}</h4>
          <hr tw="border-2 border-gray-900" />
          <ol tw="flex flex-col gap-4">
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payment.beneficiary_name', { ns: 'portfolio' })}</span>
              <span tw="text-gray-900 font-medium">{payments?.beneficiaryName}</span>
            </li>
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payment.account_number', { ns: 'portfolio' })}</span>
              <span tw="text-gray-900 font-medium">{payments?.accountNumber}</span>
            </li>
            <li tw="flex flex-row justify-between items-center text-base">
              <span tw="text-gray-500">{t('payment.payment_reference', { ns: 'portfolio' })}</span>
              <span tw="text-gray-900 font-medium">{payments?.paymentReference}</span>
            </li>
          </ol>

          <hr tw="border-2 border-gray-900" />
          <div tw="flex flex-col gap-4">
            <BankButtons
              white={true}
              paymentCode={payments?.paymentReference + ', id: ' + uuidv4()}
              email={session?.email || ''}
              amount={payments?.amount ?? 0}
              language={i18n.language}
              redirectUrl={`${window.location.origin}/company/${companyId}/benefit/${benefitId}/journals?success=true`}
              cancelUrl={`${window.location.href}`}
              disabled={(payments?.amount ?? 0) < 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LargeCheckIcon = () => (
  <div tw="bg-sky-blue-400 rounded-full p-2 md:p-4">
    <CheckIcon width={48} height={48} tw="text-white" />
  </div>
);

export default JournalPaymentsUploadSuccess;
