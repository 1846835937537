import { BeneficiaryType, Button, ButtonVariant, Spinner, Trans, useTranslation } from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import { UseQueryResult } from 'react-query';
import 'twin.macro';
import { StandingOrderInstruction } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { PaymentInformation } from './PaymentInformation';
import { BankWithLink } from './PaymentStep';
import { useOutletContext } from 'react-router-dom';
import { OnboardingOutletContext } from '~/modules/application/OnboardingWizard';

interface StandingOrderPaymentMethodProps {
  banks?: BankWithLink[];
  orderInstructions: UseQueryResult<StandingOrderInstruction, Error>;
  onNext: () => void;
}

enum ListItemIdentifier {
  SET_UP = 'SET_UP',
  DETAILS = 'DETAILS',
  NOTICE = 'NOTICE',
}

type ListItem = {
  id: ListItemIdentifier;
  title: string;
  component?: ReactNode;
};

const findListObjectByIdentifier = (list: ListItem[], identifier: ListItemIdentifier): ListItem => {
  return list.find((item) => item.id === identifier) ?? { id: identifier, title: '' };
};

export const StandingOrderPaymentMethod = ({ banks, orderInstructions, onNext }: StandingOrderPaymentMethodProps) => {
  const { t } = useTranslation('portfolio');
  const context = useOutletContext<OnboardingOutletContext>();
  const { onBack } = context ?? {};
  if (orderInstructions.isIdle || orderInstructions.isLoading) {
    return (
      <div tw="flex items-center justify-center text-alps-blue-700" css={{ minHeight: 220 }}>
        <Spinner width="64" height="64" />
      </div>
    );
  }

  if (orderInstructions.isError) {
    return (
      <ContactSupportOverlay
        title={t('activation.payment.standing_order.error')}
        onClose={orderInstructions.refetch}
        error={orderInstructions.error}
      />
    );
  }

  const defaultList: ListItem[] = [
    {
      id: ListItemIdentifier.SET_UP,
      title:
        orderInstructions.data.portfolioType === BeneficiaryType.COMPANY
          ? t('activation.payment.standing_order.set_up_business')
          : t('activation.payment.standing_order.set_up'),
      component: Array.isArray(banks) && (
        <ol tw="flex flex-row flex-wrap gap-4 md:p-4 max-w-lg">
          {banks.map((bank) => (
            <li key={bank.bic} tw="w-[calc(50%-1rem)]">
              <Button
                variant={ButtonVariant.TRANSPARENT}
                as="a"
                href={bank.link}
                target="_blank"
                rel="noopener"
                className="w-full bg-neutral-50 rounded-full px-8 py-3"
              >
                <img src={bank.logo_url} alt={bank.name} tw="object-contain h-6" />
              </Button>
            </li>
          ))}
        </ol>
      ),
    },
    {
      id: ListItemIdentifier.DETAILS,
      title: t('activation.payment.standing_order.details'),
      component: (
        <div tw="rounded-lg my-4 bg-green-50 p-4 md:p-8 max-w-lg">
          <PaymentInformation {...orderInstructions.data} />
        </div>
      ),
    },
    {
      id: ListItemIdentifier.NOTICE,
      title: t('activation.payment.standing_order.notice'),
    },
  ];

  const standingOrderWithBanksList: ListItem[] = [
    {
      ...findListObjectByIdentifier(defaultList, ListItemIdentifier.SET_UP),
    },
    {
      ...findListObjectByIdentifier(defaultList, ListItemIdentifier.DETAILS),
      title: t('activation.payment.standing_order.details'),
    },
    {
      id: ListItemIdentifier.NOTICE,
      title: t('activation.payment.standing_order.notice'),
    },
  ];

  const list = Array.isArray(banks) && banks.length > 0 ? standingOrderWithBanksList : defaultList;

  return (
    <div tw="flex flex-col gap-4 mt-14">
      <ol tw="flex flex-col gap-8">
        {list.map((item, idx) => {
          return (
            <li key={idx} tw="flex flex-col gap-6">
              {item.title != null && item.title.length > 0 && (
                <div tw="flex flex-row items-start gap-4">
                  <span tw="flex flex-shrink-0 items-center justify-center rounded-full w-10 h-10 bg-alps-blue-50 font-semibold text-alps-blue-700 text-lg">
                    {idx + 1}
                  </span>
                  <h3 tw="text-lg text-gray-900">
                    <Trans i18nKey={item.title} />
                  </h3>
                </div>
              )}
              {item.component != null && item.component}
            </li>
          );
        })}
      </ol>
      <div tw="mt-12 flex flex-row w-full">
        <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
          {t('back', { ns: 'general' })}
        </Button>
        <Button variant={ButtonVariant.NEW_PRIMARY} onClick={onNext} data-test-id="payment-done" tw="ml-auto max-w-max">
          {t('activation.payment.standing_order.proceed')}
        </Button>
      </div>
    </div>
  );
};
