import { Button, ButtonVariant, toast, UploadIcon, useTranslation } from '@grunfin/ui-kit';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'twin.macro';
import { useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '../application/Navigation';
import JournalsTable from './components/Table/JournalsTable';
import { useEffect } from 'react';

const CompanyJournalView = () => {
  const { t } = useTranslation('company');
  const navigate = useNavigate();
  const { companyId, benefitId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const baseUrl = `/company/${companyId}/benefit/${benefitId}`;
  useNavigationTitle([
    [t('navigation_title'), baseUrl],
    [t('journals.navigation_title'), `${baseUrl}/journals`],
  ]);
  useDocumentTitle(t('journals.document_title'));

  useEffect(() => {
    if (searchParams.has('success')) {
      searchParams.delete('success');
      setSearchParams(searchParams);
      toast.success(t('payments.upload.success.completed'));
    }
  }, [searchParams]);

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-row flex-wrap items-center justify-between gap-4">
        <h2 tw="text-alps-blue-700 text-lg md:text-xl font-bold">{t('journals.title')}</h2>
        <div tw="flex flex-row flex-wrap gap-4">
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={() => navigate(`${baseUrl}/payments/upload`)}>
            <UploadIcon /> {t('payments.upload.title')}
          </Button>
        </div>
      </div>
      <div tw="flex flex-col gap-8 items-start">
        <JournalsTable />
      </div>
    </div>
  );
};

export default CompanyJournalView;
