import mixpanel from 'mixpanel-browser';
import { isDev } from './utils';
var gtagDisabled = function () { return !('gtag' in window) || !window.gtag; };
var track = function (event, props) {
    try {
        mixpanel.track(event, props);
    }
    catch (err) {
        // Just silently catch, this probably means that mixpanel is not initialized
        if (isDev)
            console.log('Sending MP event', event, props);
    }
};
export var trackPublicCalcUpdate = function (values) {
    return track('Customer updated public calculator values', values);
};
export var trackNavigation = function (url) {
    var lastPathElement = url === null || url === void 0 ? void 0 : url.split('/').pop();
    track("User navigated to app path", { lastPathElement: lastPathElement, url: url });
    if (gtagDisabled())
        return;
    window.gtag('event', 'page_view', { page_location: url });
    if (!('fbq' in window))
        return;
    window.fbq('track', 'PageView');
};
