import React, { useEffect, useState } from 'react';
import { Button, ButtonVariant, Field, Select, Spinner } from '@grunfin/ui-kit';
import { getBanks, makePayment } from './queries';
import { useTranslation } from 'react-i18next';
var BankButtons = function (_a) {
    var _b, _c;
    var paymentCode = _a.paymentCode, referenceNumber = _a.referenceNumber, email = _a.email, amount = _a.amount, language = _a.language, redirectUrl = _a.redirectUrl, cancelUrl = _a.cancelUrl, white = _a.white, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    var t = useTranslation().t;
    var c = useTranslation('countries');
    var _e = useState(disabled), isDisabled = _e[0], setIsDisabled = _e[1];
    var _f = useState('EE'), country = _f[0], setCountry = _f[1];
    var _g = useState(null), banksResponse = _g[0], setBanksResponse = _g[1];
    useEffect(function () {
        getBanks().then(setBanksResponse);
    }, []);
    useEffect(function () {
        setIsDisabled(disabled);
    }, [disabled]);
    var doPayment = function (bank) {
        setIsDisabled(true);
        makePayment({
            bic: bank.code,
            amount: amount,
            paymentCode: paymentCode,
            referenceNumber: referenceNumber,
            payerEmail: email,
            language: language,
            redirectUrl: redirectUrl,
            cancelUrl: cancelUrl,
        });
    };
    if (!banksResponse) {
        return (React.createElement("div", { className: "flex mx-auto my-16 md:my-32" },
            React.createElement(Spinner, null)));
    }
    var getBankNames = function () {
        var _a;
        var a = [];
        for (var countryCode in (_a = banksResponse.paymentMethods) === null || _a === void 0 ? void 0 : _a.paymentInitiation.setup) {
            a.push(React.createElement("option", { key: countryCode, value: countryCode }, c.t('country.' + countryCode)));
        }
        return a;
    };
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: "grid grid-cols-12 gap-4 my-2" }, (_c = (_b = banksResponse.paymentMethods) === null || _b === void 0 ? void 0 : _b.paymentInitiation.setup[country].paymentMethods) === null || _c === void 0 ? void 0 : _c.map(function (bank) { return (React.createElement("div", { key: country + bank.name, className: "col-span-6 ".concat(white ? 'sm:col-span-4' : 'sm:col-span-6', " w-full") },
            React.createElement(Button, { variant: ButtonVariant.TRANSPARENT, key: country + bank.name, disabled: isDisabled, onClick: function () { return doPayment(bank); }, className: "rounded-lg px-6 py-3 ".concat(white ? 'bg-white' : 'bg-neutral-50') },
                React.createElement("img", { className: "object-contain h-6", src: bank.logoUrl, alt: bank.name })))); })),
        React.createElement("div", { className: "flex flex-row w-full sm:w-1/3 ml-auto mt-4" },
            React.createElement(Field, { label: React.createElement("span", { className: "text-gray-300 text-sm" }, t('details.country')), htmlFor: "amount", className: "flex flex-col items-stretch gap-y-0" },
                React.createElement(Select, { value: country, onChange: function (e) { return setCountry(e.target.value); }, className: "text-gray-300 border-gray-100 text-sm" }, getBankNames())))));
};
export default BankButtons;
