var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgSiftedLogo = function (props, ref) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 220.626 49", ref: ref }, props),
    React.createElement("polygon", { fill: "currentColor", points: "0 0 18.61 49 22.037 49 3.427 0 0 0" }),
    React.createElement("path", { fill: "currentColor", d: "M39.71,24.505q1.664.555,3.049,1.134a10.487,10.487,0,0,1,2.419,1.386,5.779,5.779,0,0,1,1.613,1.94,5.867,5.867,0,0,1,.58,2.7v.05a7.457,7.457,0,0,1-2.545,6q-2.546,2.166-7.132,2.167a16.672,16.672,0,0,1-5.645-.991,19.4,19.4,0,0,1-5.191-2.821l-.051-.051.051-.051,3.427-5.41.051.05a10.266,10.266,0,0,0,3.427,2.445,10.139,10.139,0,0,0,4.082.831q2.973,0,2.974-1.911a1.45,1.45,0,0,0-.958-1.356,17.1,17.1,0,0,0-2.268-.856,1.988,1.988,0,0,0-.479-.125,1.768,1.768,0,0,1-.478-.126q-1.512-.4-3.024-.879a10.207,10.207,0,0,1-2.722-1.307A6.852,6.852,0,0,1,28.9,25.207a6.244,6.244,0,0,1-.781-3.293v-.1a7.759,7.759,0,0,1,.655-3.218,7.241,7.241,0,0,1,1.865-2.514,8.513,8.513,0,0,1,2.923-1.633,11.746,11.746,0,0,1,3.78-.579,17.175,17.175,0,0,1,4.889.731,16.874,16.874,0,0,1,4.586,2.142v.1l-3.074,5.594-.05-.05a8.386,8.386,0,0,0-2.873-1.84,9.757,9.757,0,0,0-3.629-.68,7.281,7.281,0,0,0-.806.051,2.28,2.28,0,0,0-.832.251,1.94,1.94,0,0,0-.63.529,1.5,1.5,0,0,0-.252.933,1.124,1.124,0,0,0,.4.832,4.642,4.642,0,0,0,1.033.705,13.941,13.941,0,0,0,1.412.631q.78.3,1.537.554a1.216,1.216,0,0,1,.327.05c.118.034.227.068.328.1" }),
    React.createElement("path", { fill: "currentColor", d: "M49.89,14.274h6.7v25.2h-6.7Zm0-10.08h6.7v5.9h-6.7Z" }),
    React.createElement("path", { fill: "currentColor", d: "M70.251,18.608H77.66V24.2H73.326ZM77.66,4.193v5.9H72.166A2.312,2.312,0,0,0,70.15,11.1a4.764,4.764,0,0,0-.705,2.772v25.6h-6.7V24.2H59.113V18.608h3.629v-5.04a9.159,9.159,0,0,1,.73-3.629,9.393,9.393,0,0,1,5.015-5.015,9.3,9.3,0,0,1,3.679-.731Z" }),
    React.createElement("path", { fill: "currentColor", d: "M97.719,16.9v5.594H93.99L90.865,16.9ZM90.008,30.856a2.63,2.63,0,0,0,.832,1.965,2.755,2.755,0,0,0,1.99.807h4.889v5.846H92.83a9.447,9.447,0,0,1-3.7-.731A9.334,9.334,0,0,1,86.1,36.727a9.773,9.773,0,0,1-2.041-3,9,9,0,0,1-.756-3.679v-7.56H79.676V16.9h3.629V7.772h6.7Z" }),
    React.createElement("path", { fill: "currentColor", d: "M111.579,13.87a12.472,12.472,0,0,1,5.04,1.034,13.184,13.184,0,0,1,6.93,6.929,12.612,12.612,0,0,1,1.033,5.091,13,13,0,0,1-.3,2.822l-.051.1h-12.8L108.2,24.706h9.576a7.514,7.514,0,0,0-2.344-3.478,5.846,5.846,0,0,0-3.855-1.36,6.106,6.106,0,0,0-2.571.553,6.665,6.665,0,0,0-2.091,1.481,6.749,6.749,0,0,0-1.386,2.21,7.538,7.538,0,0,0-.5,2.787,6.862,6.862,0,0,0,.454,2.385,7.291,7.291,0,0,0,1.31,2.235,7.53,7.53,0,0,0,2.066,1.682,5.473,5.473,0,0,0,2.722.678,7.853,7.853,0,0,0,2.5-.378,4.655,4.655,0,0,0,2.142-1.689l.05-.05.1.05,5.846,2.575-.1.1a12.584,12.584,0,0,1-4.611,3.964,12.883,12.883,0,0,1-5.922,1.424,13.047,13.047,0,0,1-5.116-1.009,12.85,12.85,0,0,1-4.158-2.772,13.278,13.278,0,0,1-2.8-4.132,12.464,12.464,0,0,1-1.033-5.04,12.6,12.6,0,0,1,1.033-5.091,13.116,13.116,0,0,1,6.955-6.929,12.768,12.768,0,0,1,5.116-1.034" }),
    React.createElement("path", { fill: "currentColor", d: "M154.217,4.193v35.28h-6.754l.051-12.65a6.325,6.325,0,0,0-.58-2.671,7.509,7.509,0,0,0-1.512-2.192,6.725,6.725,0,0,0-2.217-1.463,7.161,7.161,0,0,0-2.747-.529,6.971,6.971,0,0,0-2.722.529,6.6,6.6,0,0,0-2.192,1.487,7.44,7.44,0,0,0-1.487,2.218,6.962,6.962,0,0,0,0,5.443,7.028,7.028,0,0,0,3.679,3.679,6.659,6.659,0,0,0,2.722.555,9.857,9.857,0,0,0,1.411-.1,4.237,4.237,0,0,0,1.31-.4h.1l2.47,4.536-.051.05a9.563,9.563,0,0,1-6.048,1.916,12.949,12.949,0,0,1-9.223-3.781,13.278,13.278,0,0,1-2.8-4.132,12.812,12.812,0,0,1,0-10.08,13.294,13.294,0,0,1,2.8-4.133,12.943,12.943,0,0,1,9.223-3.78,9.052,9.052,0,0,1,7.863,3.83V4.193Z" }),
    React.createElement("polygon", { fill: "currentColor", points: "179.609 0 161 49 164.427 49 183.037 0 179.609 0" }),
    React.createElement("path", { fill: "currentColor", d: "M193.2,4.38a.805.805,0,0,0-.724.3,1.708,1.708,0,0,0-.2.929v.024a1.769,1.769,0,0,0,.2.927.794.794,0,0,0,.736.32.735.735,0,0,0,.657-.323,1.681,1.681,0,0,0,.217-.936q0-1.241-.886-1.241m-.923-1.76V3.751c0,.139,0,.268-.009.388s-.014.215-.021.284h.03a1.151,1.151,0,0,1,.385-.348,1.39,1.39,0,0,1,1.611.284,1.887,1.887,0,0,1,.37,1.268,1.89,1.89,0,0,1-.373,1.272,1.381,1.381,0,0,1-1.608.287,1.2,1.2,0,0,1-.385-.336h-.043l-.11.416h-.385V2.62Z" }),
    React.createElement("path", { fill: "currentColor", d: "M197.382,5.664l-.483.018a1.632,1.632,0,0,0-.846.2.562.562,0,0,0-.236.483.473.473,0,0,0,.168.4.688.688,0,0,0,.431.129,1.042,1.042,0,0,0,.688-.23.861.861,0,0,0,.278-.7Zm-.642-1.73a1.278,1.278,0,0,1,.886.263,1.084,1.084,0,0,1,.288.837V7.266h-.391l-.1-.465h-.025a1.5,1.5,0,0,1-.452.4,1.383,1.383,0,0,1-.648.128,1.145,1.145,0,0,1-.74-.236.887.887,0,0,1-.293-.736.863.863,0,0,1,.385-.755,2.226,2.226,0,0,1,1.186-.29l.556-.019V5.1a.742.742,0,0,0-.177-.568.732.732,0,0,0-.5-.159,1.549,1.549,0,0,0-.488.077,3.5,3.5,0,0,0-.434.174l-.166-.4a2.493,2.493,0,0,1,.508-.2,2.242,2.242,0,0,1,.611-.083" }),
    React.createElement("path", { fill: "currentColor", d: "M200.243,7.327a1.642,1.642,0,0,1-.776-.177,1.24,1.24,0,0,1-.529-.551,2.058,2.058,0,0,1-.193-.953,2.112,2.112,0,0,1,.2-.984,1.26,1.26,0,0,1,.547-.557,1.716,1.716,0,0,1,.789-.177,2.246,2.246,0,0,1,.479.052,1.725,1.725,0,0,1,.382.125l-.165.447a3.162,3.162,0,0,0-.342-.1,1.5,1.5,0,0,0-.367-.049q-.966,0-.965,1.241a1.5,1.5,0,0,0,.235.908.818.818,0,0,0,.7.315,1.866,1.866,0,0,0,.47-.055,2.3,2.3,0,0,0,.379-.135V7.15a1.516,1.516,0,0,1-.363.131,2.146,2.146,0,0,1-.48.046" }),
    React.createElement("path", { fill: "currentColor", d: "M202.394,2.62V5.047c0,.065,0,.151-.009.257s-.011.2-.015.274h.024c.025-.032.061-.079.11-.14l.15-.187c.051-.063.095-.114.131-.156l1.046-1.106h.629l-1.326,1.4,1.418,1.877H203.9l-1.137-1.529-.373.324V7.266h-.532V2.62Z" }),
    React.createElement("path", { fill: "currentColor", d: "M206.386,4.368a.776.776,0,0,0-.608.248,1.161,1.161,0,0,0-.266.688h1.669a1.159,1.159,0,0,0-.2-.676.692.692,0,0,0-.6-.26m.006-.44a1.366,1.366,0,0,1,.721.184,1.2,1.2,0,0,1,.468.516,1.763,1.763,0,0,1,.162.779v.324H205.5a1.234,1.234,0,0,0,.284.847.99.99,0,0,0,.761.29,2.359,2.359,0,0,0,.553-.058,2.982,2.982,0,0,0,.5-.168v.471a2.6,2.6,0,0,1-.5.162,2.877,2.877,0,0,1-.581.052,1.718,1.718,0,0,1-.825-.19,1.327,1.327,0,0,1-.553-.562,1.939,1.939,0,0,1-.2-.923,2.146,2.146,0,0,1,.181-.923,1.364,1.364,0,0,1,.507-.593,1.394,1.394,0,0,1,.761-.208" }),
    React.createElement("path", { fill: "currentColor", d: "M209.822,6.881a.852.852,0,0,0,.73-.284,1.444,1.444,0,0,0,.211-.859v-.1a1.791,1.791,0,0,0-.2-.938.8.8,0,0,0-.746-.327.717.717,0,0,0-.651.345,1.731,1.731,0,0,0-.217.926,1.631,1.631,0,0,0,.217.911.738.738,0,0,0,.658.324m-.086.446a1.226,1.226,0,0,1-.978-.424,1.9,1.9,0,0,1-.367-1.269,1.909,1.909,0,0,1,.37-1.275,1.368,1.368,0,0,1,1.6-.29,1.327,1.327,0,0,1,.394.342h.037c-.009-.053-.017-.131-.025-.235s-.012-.187-.012-.248V2.62h.538V7.266h-.434l-.08-.44h-.024a1.258,1.258,0,0,1-.392.354,1.2,1.2,0,0,1-.629.147" }),
    React.createElement("path", { fill: "currentColor", d: "M215.384,4.38a.805.805,0,0,0-.724.3,1.708,1.708,0,0,0-.2.929v.024a1.769,1.769,0,0,0,.2.927.794.794,0,0,0,.736.32.735.735,0,0,0,.657-.323,1.68,1.68,0,0,0,.218-.936q0-1.241-.887-1.241m-.923-1.76V3.751c0,.139,0,.268-.009.388s-.014.215-.021.284h.03a1.151,1.151,0,0,1,.385-.348,1.39,1.39,0,0,1,1.611.284,1.887,1.887,0,0,1,.37,1.268,1.89,1.89,0,0,1-.373,1.272,1.237,1.237,0,0,1-.978.428,1.25,1.25,0,0,1-.63-.141,1.2,1.2,0,0,1-.385-.336h-.043l-.11.416h-.385V2.62Z" }),
    React.createElement("path", { fill: "currentColor", d: "M217.047,3.989h.575l.709,1.865q.092.25.165.47a3.179,3.179,0,0,1,.11.422h.024c.025-.1.064-.235.117-.4s.107-.331.164-.5l.667-1.859h.58l-1.411,3.729a2.126,2.126,0,0,1-.447.74,1,1,0,0,1-.746.275,1.918,1.918,0,0,1-.256-.015c-.074-.011-.137-.022-.19-.034V8.256c.045.008.1.016.162.025a1.809,1.809,0,0,0,.2.011.642.642,0,0,0,.452-.158,1.133,1.133,0,0,0,.275-.422l.172-.434Z" }),
    React.createElement("rect", { fill: "currentColor", x: "191.141", y: "10.39", width: "29.485", height: "29.489" }),
    React.createElement("path", { fill: "#FFF", d: "M198.757,15.144v5.49h1.616c1.749,0,1.838-.288,1.971-1.66h.509v4.007h-.509c-.133-1.373-.222-1.639-1.971-1.639h-1.616V26.1c0,.974.266,1.2,2.059,1.2v.6h-6.309v-.6c1.306,0,1.771-.066,1.771-1.128V16.206c0-1.062-.465-1.129-1.771-1.129v-.6h8.39a9.653,9.653,0,0,0,1.882-.111v4.14h-.554c-.287-2.878-.863-3.365-3.431-3.365Z" }),
    React.createElement("path", { fill: "#FFF", d: "M216.238,14.48a9.653,9.653,0,0,0,1.882-.111v4.14h-.554c-.287-2.878-.863-3.365-3.431-3.365h-.708V26.1c0,1.041.4,1.2,2.058,1.2v.6h-6.619v-.6c1.66,0,2.081-.155,2.081-1.2V15.144h-.708c-2.568,0-3.144.487-3.432,3.365h-.553v-4.14a9.645,9.645,0,0,0,1.882.111Z" }))); };
var ForwardRef = forwardRef(SvgSiftedLogo);
var Memo = memo(ForwardRef);
export default Memo;
