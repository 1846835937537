var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Spinner = function (props) { return (React.createElement("svg", __assign({ height: "32", width: "32", viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg", className: "animate-spin" }, props),
    React.createElement("path", { className: "fill-current", clipRule: "evenodd", d: "M16 6a10 10 0 107.8 3.75 1 1 0 011.57-1.25A12 12 0 1116 4a1 1 0 110 2z", fillRule: "evenodd" }))); };
