import { Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

import { useNavigationTitle } from '~/modules/application/Navigation';
import { useDocumentTitle } from '~/utils';

export function SupportView() {
  const { t, i18n } = useTranslation('support');

  useNavigationTitle([[t('home', { ns: 'general' }), '/'], t('navigation_title')]);
  useDocumentTitle(t('document_title'));

  return (
    <div tw="flex justify-evenly flex-wrap">
      <div tw="bg-forest-green-50 rounded-2xl p-8 pt-10 m-2" css={{ maxWidth: '400px' }}>
        <div tw="text-center">
          <div tw="text-lg font-bold md:font-semibold md:text-xl text-alps-blue-700">{t('faq.title')}</div>
          <div tw="md:text-lg pt-6 md:pt-10">{t('faq.subtitle')}</div>
        </div>

        <div tw="flex justify-evenly mt-10 md:mt-14">
          <a href={`https://faq.grunfin.com/${i18n.resolvedLanguage}`} target="_blank" rel="noopener">
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
              {t('faq.button')}
            </Button>
          </a>
        </div>
      </div>

      <div tw="bg-alps-blue-50 rounded-2xl p-8 pt-10 m-2" css={{ maxWidth: '400px' }}>
        <div tw="text-center">
          <div tw="text-lg font-bold md:font-semibold md:text-xl text-alps-blue-700">{t('contact.title')}</div>
          <div tw="md:text-lg pt-6 md:pt-10">{t('contact.subtitle')}</div>
        </div>

        <div tw="flex justify-evenly mt-10 md:mt-14">
          <a href="mailto:support@grunfin.com">
            <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE}>
              {t('contact.button')}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
