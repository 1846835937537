import { Spinner, useTranslation } from '@grunfin/ui-kit';
import { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import 'twin.macro';
import { CompanyDetailsForm } from '~/modules/account/CompanyDetailsForm';
import { useGetCustomer } from '~/modules/account/queries';
import { useCreateCompany, useGetPortfolioCompany } from '~/modules/portfolio/queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';
import { trackActivationSteps } from '~/utils/tracking-analytics';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';
import { ActivationLink } from '~/modules/portfolio/ActivateView/ActivationView';

const CompanyDetailsStep = () => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onContinue } = context ?? {};
  const { t } = useTranslation('account');
  const getCustomer = useGetCustomer();
  const createCompany = useCreateCompany();
  const params = useParams();
  const { id } = params ?? {};
  const getPortfolioCompany = useGetPortfolioCompany(id as string, id != null);

  const { isSuccess } = getCustomer;
  useEffect(() => {
    if (isSuccess) trackActivationSteps(ActivationLink.COMPANY_DETAILS, id || '');
  }, [isSuccess]);

  if (getCustomer.isLoading || getCustomer.isIdle || getPortfolioCompany.isLoading || getPortfolioCompany.isIdle) {
    return (
      <OnboardingStepWrapper>
        <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
          <Spinner />
        </div>
      </OnboardingStepWrapper>
    );
  }

  if (getCustomer.isError || getPortfolioCompany.isError) {
    return (
      <OnboardingStepWrapper>
        <ContactSupportOverlay title={t('details.error')} onClose={getCustomer.refetch} error={getCustomer.error} />
      </OnboardingStepWrapper>
    );
  }

  if (!getCustomer.data) return null;

  return (
    <OnboardingStepWrapper>
      <div tw="max-w-xl">
        <CompanyDetailsForm
          defaultValues={getPortfolioCompany.data}
          onSubmit={async (company, setError): Promise<void> => {
            try {
              await createCompany.mutateAsync({ portfolioId: params.id, company });
            } catch (err) {
              if (err instanceof Error) setBackendValidationErrors(err, setError);
              throw err;
            } finally {
              await getPortfolioCompany.refetch();
            }

            if (typeof onContinue !== 'undefined') onContinue();
          }}
        />
      </div>
    </OnboardingStepWrapper>
  );
};

export default CompanyDetailsStep;
