import {
  formatCurrency,
  formatNumber,
  InfoTooltip,
  instrumentColorConfigs,
  PortfolioAsset,
  PortfolioAssetOwner,
  Tabs,
  useTranslation,
} from '@grunfin/ui-kit';
import tw, { TwStyle } from 'twin.macro';
import { ColumnDef, flexRender, getCoreRowModel, Row, Table as TableType, useReactTable } from '@tanstack/react-table';
import { trackWhitepaperOpen } from '~/utils/tracking-analytics';

import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import FocusAreaList from '~/modules/portfolio/components/FocusAreaList';

const TableHeader = ({ children, style }: { children: ReactNode; style?: TwStyle }) => (
  <span
    tw="hidden sm:flex justify-end uppercase text-sm font-bold text-gray-400 tabular-nums text-right sm:p-2"
    css={style}
  >
    {children}
  </span>
);

const TableCell = ({ children }: { children: ReactNode }) => (
  <span tw="table-cell sm:flex sm:justify-end text-base text-right tabular-nums whitespace-nowrap mx-1">
    {children}
  </span>
);

const MobileTableHeader = ({ children }: { children: ReactNode }) => (
  <span tw="uppercase text-sm font-bold text-gray-400 tabular-nums mr-2 sm:hidden">{children}</span>
);

const parseNum = (number: number) => +number?.toFixed(2);

type Props = {
  assets: PortfolioAsset[];
  totalValueEur?: number;
  uninvestedFundsSum?: number;
  onTabChange?: (assetOwner?: PortfolioAssetOwner | PortfolioAssetOwner[]) => void;
  header?: ReactNode;
  alwaysShowAssetOwnerTabs?: boolean;
};

const TOTAL_VALUE = [PortfolioAssetOwner.CUSTOMER, PortfolioAssetOwner.EMPLOYER];
const TOTAL_VALUE_ID = 'total_value';

export const AssetsTable = ({
  assets = [],
  totalValueEur,
  uninvestedFundsSum,
  onTabChange,
  header,
  alwaysShowAssetOwnerTabs,
}: Props) => {
  const { t } = useTranslation('portfolio');
  const [rows, setRows] = useState<PortfolioAsset[]>([]);
  const [selectedAssetOwner, setSelectedAssetOwner] = useState<PortfolioAssetOwner | PortfolioAssetOwner[]>(
    TOTAL_VALUE,
  );

  // Set default assets if the prop is available
  useEffect(() => {
    if (assets != null) setRows(assets);
  }, [assets]);

  const getPortfolioAssetsByOwner = useMemo(
    () => (assetOwner: PortfolioAssetOwner | PortfolioAssetOwner[]) =>
      assets.filter((asset) => asset.assetOwner === assetOwner),
    [assets],
  );

  const handleTabChange = (assetOwner: PortfolioAssetOwner | PortfolioAssetOwner[]) => {
    setSelectedAssetOwner(assetOwner);
    if (typeof onTabChange === 'function') onTabChange(assetOwner);
    if (assetOwner != null && assetOwner !== TOTAL_VALUE) setRows(getPortfolioAssetsByOwner(assetOwner));
    else setRows(assets);
  };

  const getTotalAssetsValueByAssetOwner = useCallback(
    (assetOwner: PortfolioAssetOwner | PortfolioAssetOwner[]) =>
      getPortfolioAssetsByOwner(assetOwner).reduce((acc, row) => acc + row.totalValue, 0),
    [getPortfolioAssetsByOwner],
  );

  const getCashWaitingToBeInvested = useCallback(() => {
    // Do not calculate remaining sum if the prop is available
    if (uninvestedFundsSum != null) return uninvestedFundsSum;
    if (totalValueEur == null) return 0;

    let totalAssetValueByAssetOwner =
      getTotalAssetsValueByAssetOwner(PortfolioAssetOwner.CUSTOMER) +
      getTotalAssetsValueByAssetOwner(PortfolioAssetOwner.EMPLOYER);

    // if the combined value is not the portfolio total value
    // we need to calculate the amount waiting to be invested
    if (parseNum(totalAssetValueByAssetOwner) !== parseNum(totalValueEur)) {
      if (selectedAssetOwner != null && selectedAssetOwner !== TOTAL_VALUE)
        totalAssetValueByAssetOwner = totalValueEur - getTotalAssetsValueByAssetOwner(selectedAssetOwner);
    }

    if (totalAssetValueByAssetOwner <= 0) return totalAssetValueByAssetOwner;

    // calculate how much cash is waiting to be invested from totalValueEur against the combined assets value
    return parseNum(totalValueEur) - parseNum(totalAssetValueByAssetOwner);
  }, [selectedAssetOwner, uninvestedFundsSum, totalValueEur, getTotalAssetsValueByAssetOwner]);

  const getCurrentTabTotalValue = useCallback(() => {
    if (selectedAssetOwner == null || selectedAssetOwner === TOTAL_VALUE)
      return [PortfolioAssetOwner.CUSTOMER, PortfolioAssetOwner.EMPLOYER]
        .map(getTotalAssetsValueByAssetOwner)
        .reduce((a, b) => a + b, 0);
    return getTotalAssetsValueByAssetOwner(selectedAssetOwner);
  }, [selectedAssetOwner, getTotalAssetsValueByAssetOwner]);

  const columns = useMemo<ColumnDef<PortfolioAsset>[]>(
    () => [
      {
        header: ' ',
        accessorFn: (row) => row.name,
        id: 'isin',
        cell: (info) => {
          const asset = info.row.original;
          let style = 'bg-forest-green-400 text-white';
          if (asset.isin != null) {
            const instrumentColor = instrumentColorConfigs?.[asset.isin];
            if (instrumentColor != null) style = instrumentColor.style;
          }

          return (
            <div key={asset.isin + asset.assetOwner} tw="flex flex-row items-center gap-2">
              <a
                href={asset.whitePaperUrl}
                target="_blank"
                onClick={() => trackWhitepaperOpen(asset.whitePaperUrl)}
                tw="flex flex-row text-sm sm:text-base"
                rel="noreferrer"
              >
                <span
                  className={`${style} max-w-xs rounded-lg px-2 p-1 py-0.5 whitespace-nowrap overflow-hidden`}
                  style={{ textOverflow: 'ellipsis' }}
                >
                  {asset?.name}
                </span>
              </a>
              <InfoTooltip showOnHover={true}>
                <div tw="flex flex-col text-sm">
                  <b tw="text-base">{asset?.name}</b>
                  <br />
                  <a href={asset.whitePaperUrl} target="_blank" rel="noopener">
                    {asset.whitePaperUrl}
                  </a>
                  <br />
                  <a href={asset.kiidUrl} target="_blank" rel="noopener">
                    {asset.kiidUrl}
                  </a>
                </div>
              </InfoTooltip>
              <FocusAreaList focuses={asset.focuses} label={false} />
            </div>
          );
        },
        footer: () => (
          <div tw="flex flex-col gap-2">
            {selectedAssetOwner === TOTAL_VALUE && getCashWaitingToBeInvested() > 0 && (
              <span tw="font-normal text-gray-900">{t('assets.footer.cash_available')}</span>
            )}
            <span tw="px-2 py-1 rounded bg-gray-50 font-normal max-w-max">{t('assets.footer.total')}</span>
          </div>
        ),
      },
      {
        header: () => <TableHeader>{t('assets.header.gain')}</TableHeader>,
        accessorFn: (row) => row.totalValue - (row.investedAmount ?? 0),
        id: 'gain',
        cell: (info) => (
          <TableCell>
            <MobileTableHeader>{t('assets.gain')}</MobileTableHeader>
            {!isNaN(info.getValue() as number) && formatCurrency(info.getValue() as number, { digits: 2 })}
          </TableCell>
        ),
      },
      {
        header: () => <TableHeader>{t('assets.header.units')}</TableHeader>,
        accessorFn: (row) => row.units ?? 0,
        id: 'units',
        cell: (info) => (
          <TableCell>
            <MobileTableHeader>{t('assets.units')}</MobileTableHeader>
            {formatNumber(info.getValue() as number)}
          </TableCell>
        ),
      },
      {
        header: () => <TableHeader>{t('assets.header.price')}</TableHeader>,
        accessorFn: (row) => row.price ?? 0,
        id: 'price',
        cell: (info) => (
          <TableCell>
            <MobileTableHeader>{t('assets.price')}</MobileTableHeader>
            {formatCurrency(info.getValue() as number, { digits: 2 })}
          </TableCell>
        ),
      },
      {
        header: () => <TableHeader>{t('assets.header.value')}</TableHeader>,
        accessorFn: (row) => row.totalValue,
        id: TOTAL_VALUE_ID,
        cell: (info) => (
          <TableCell>
            <MobileTableHeader>{t('assets.total')}</MobileTableHeader>
            {formatCurrency(info.getValue() as number, { digits: 2 })}
          </TableCell>
        ),
        footer: () => {
          const cashWaitingToBeInvested = getCashWaitingToBeInvested();
          return (
            <div tw="flex flex-col gap-2">
              {selectedAssetOwner === TOTAL_VALUE && cashWaitingToBeInvested > 0 && (
                <span tw="font-normal text-right tabular-nums">
                  {formatCurrency(cashWaitingToBeInvested, { digits: 2 })}
                </span>
              )}
              <span tw="font-bold text-right tabular-nums">
                {formatCurrency(getCurrentTabTotalValue(), { digits: 2 })}
              </span>
            </div>
          );
        },
      },
    ],
    [t, getCurrentTabTotalValue, getCashWaitingToBeInvested],
  );

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // check how many different assetOwner types there are
  const assetOwnerTypes = useMemo(() => {
    if (alwaysShowAssetOwnerTabs) return [TOTAL_VALUE, PortfolioAssetOwner.CUSTOMER, PortfolioAssetOwner.EMPLOYER];
    const assetOwners = assets?.map((row) => row.assetOwner)?.filter(Boolean);
    return Array.from(new Set([TOTAL_VALUE, ...assetOwners]));
  }, [assets, alwaysShowAssetOwnerTabs]);

  return (
    <div tw="flex flex-col gap-8 w-full">
      {assetOwnerTypes.length > 2 ? (
        <Tabs
          onValueChange={(value) => handleTabChange(value as PortfolioAssetOwner)}
          options={assetOwnerTypes.map((owner) => {
            let label = typeof owner === 'string' && owner?.toLowerCase();
            if (owner === TOTAL_VALUE) label = TOTAL_VALUE_ID;

            return {
              label: t(label as string),
              value: owner as string,
              component: <TableBase table={table} />,
            };
          })}
          listSuffixComponent={header}
          {...(header == null && { style: { list: 'flex justify-center' } })}
        />
      ) : (
        <TableBase table={table} />
      )}
    </div>
  );
};

type TableRow = any;
type TableProps<T> = {
  table: TableType<T>;
};

const TableBase = ({ table }: TableProps<TableRow>) => {
  return (
    <div tw="w-full overflow-auto [&::-webkit-scrollbar]:hidden">
      <table tw="w-full border-collapse text-left" style={{ borderSpacing: 0 }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (header.isPlaceholder) return null;
                if (!header.column.columnDef.header) return null;
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: Row<any>) => {
            return (
              <tr key={row.id} tw="flex flex-row gap-2 flex-wrap flex-1 sm:table-row">
                {row.getVisibleCells().map((cell) => {
                  if (cell.getValue() == null) return null;
                  const isIsin = cell.column.id === 'isin';
                  return (
                    <td key={cell.id} css={[tw`py-1 sm:p-2 w-full`, !isIsin && tw`max-w-full`]}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot tw="border-t-[20px] border-transparent">
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} tw="sm:p-2">
                    {flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};
