var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgRiskProfileIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m16 7.5c.8284 0 1.5-.67157 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.67157-1.5 1.5.6716 1.5 1.5 1.5zm0 1.5c1.5723 0 3.0236.5184 4.1922 1.3936l-4.8993 4.8993c-.3905.3905-.3905 1.0237 0 1.4142s1.0237.3905 1.4142 0l4.8993-4.8993c.8752 1.1686 1.3936 2.6199 1.3936 4.1922 0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7zm-8.5 7c0 .8284-.67157 1.5-1.5 1.5s-1.5-.6716-1.5-1.5.67157-1.5 1.5-1.5 1.5.6716 1.5 1.5zm18.5 1.5c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5zm-1.8683-7.5104c-.5858.5858-1.5355.5858-2.1213 0-.5858-.58579-.5858-1.53554 0-2.12132.5858-.58579 1.5355-.58579 2.1213 0 .5858.58578.5858 1.53553 0 2.12132zm-16.26341 14.1421c.58579.5858 1.53554.5858 2.12133 0 .58578-.5858.58578-1.5355 0-2.1213-.58579-.5858-1.53554-.5858-2.12133 0-.58578.5858-.58578 1.5355 0 2.1213zm2.12133-14.1421c-.58579.5858-1.53554.5858-2.12133 0-.58578-.58579-.58578-1.53554 0-2.12132.58579-.58579 1.53554-.58579 2.12133 0 .58578.58578.58578 1.53553 0 2.12132zm12.02078 14.1421c.5858.5858 1.5355.5858 2.1213 0s.5858-1.5355 0-2.1213-1.5355-.5858-2.1213 0-.5858 1.5355 0 2.1213z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgRiskProfileIcon);
var Memo = memo(ForwardRef);
export default Memo;
