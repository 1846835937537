import { Button, ButtonSize, ButtonVariant, useTranslation } from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Success } from '~/modules/portfolio/components/Success';
import { usePortfolio } from '~/modules/portfolio/hooks';

import { Container } from './Container';

export const PaymentSuccess = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const navigate = useNavigate();

  return (
    <Container>
      <Success
        title={t('details.payment.top_up.success.title')}
        subtitle={t('details.payment.top_up.success.description')}
      >
        <Button
          onClick={() => navigate(`/portfolio/${portfolio.id}`)}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
        >
          {t('details.payment.top_up.success.back')}
        </Button>
      </Success>
    </Container>
  );
};
