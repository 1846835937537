import {
  dayjsRange,
  differenceInYears,
  formatCurrency,
  formatDate,
  InfoTooltip,
  Portfolio,
  useTranslation,
} from '@grunfin/ui-kit';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import tw from 'twin.macro';
import {
  usePortfolioPerformanceCumulativePayments,
  usePortfolioPerformanceValue,
  usePortfolioPerformanceValuePrediction,
} from '../queries';
import {
  PortfolioPerformancePaymentsResponse,
  PortfolioPerformancePredictionResponse,
  PortfolioPerformanceResponse,
} from '../types';
import { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface CombinedPrediction
  extends Partial<PortfolioPerformanceResponse>,
    Partial<PortfolioPerformancePredictionResponse>,
    Partial<PortfolioPerformancePaymentsResponse> {}

type ChartValues = {
  date: string | undefined;
  shortTime: number | false | undefined;
  currentPayments: number | undefined;
  currentTotal: number | undefined;
  predictedPayments: number | undefined;
  predictedGain: number | undefined;
  predictedTotal: number | undefined;
};

enum GraphVariant {
  SIX_MONTHS = 'SIX_MONTHS',
  YTD = 'YTD',
  FULL_PERIOD = 'FULL_PERIOD',
  PREDICTION = 'PREDICTION',
}

const PREDICTION_10_YEARS = 10;

const InvestmentPredictionGraph = ({ portfolio }: { portfolio: Portfolio }) => {
  const { t } = useTranslation('portfolio');
  const [activeVariant, setActiveVariant] = useState<GraphVariant>(GraphVariant.PREDICTION);
  const currentYear = new Date().getFullYear();
  const performanceValue = usePortfolioPerformanceValue(portfolio.id);
  const performanceValuePrediction = usePortfolioPerformanceValuePrediction(
    portfolio.id,
    dayjs().add(PREDICTION_10_YEARS, 'years').format('YYYY-MM-DD'),
  );
  const performanceCumulativePayments = usePortfolioPerformanceCumulativePayments(portfolio.id);
  const isReady =
    Array.isArray(performanceValue.data) &&
    !!performanceValue.data.length &&
    Array.isArray(performanceValuePrediction.data) &&
    !!performanceValuePrediction.data.length &&
    Array.isArray(performanceCumulativePayments.data) &&
    !!performanceCumulativePayments.data.length;

  const getChartValues = () => {
    // combine all data entries into one array
    // we have to do some mapping here to find the performanceValue object with same date as cumulativePayments
    // and merge the two objects into one
    const valueAndPayments = performanceValue.data?.map((value) => {
      const cumulativePayments = performanceCumulativePayments.data?.find((payment) => payment.date === value.date);
      return {
        ...cumulativePayments,
        ...value,
      };
    });
    let array: CombinedPrediction[] = [];

    switch (activeVariant) {
      case GraphVariant.SIX_MONTHS:
        array = (valueAndPayments ?? []).filter((e) => dayjs(e.date).isAfter(dayjs().subtract(6, 'month')));
        break;
      case GraphVariant.YTD:
        array = (valueAndPayments ?? []).filter((e) => dayjs(e.date).isAfter(new Date(currentYear, 0, 1)));
        break;
      case GraphVariant.FULL_PERIOD:
        array = valueAndPayments ?? [];
        break;
      case GraphVariant.PREDICTION:
        array = [...(valueAndPayments ?? []), ...(performanceValuePrediction?.data ?? [])];
        break;
    }

    // sort the array by date
    array.sort((a, b) => {
      if (!a.date || !b.date) return 0;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    // create a new array with the values we need
    const chartValues: ChartValues[] = [];
    array.forEach((value) => {
      chartValues.push({
        date: value.date,
        shortTime: value?.date != null && new Date(value.date).getTime(),
        currentPayments: value.value,
        currentTotal: value.totalValue,
        predictedPayments: value.predictedTotalPayments,
        predictedGain:
          value.predictedPortfolioValue != null && value.predictedTotalPayments != null
            ? value.predictedPortfolioValue - value.predictedTotalPayments
            : value.predictedPortfolioValue,
        predictedTotal: value.predictedPortfolioValue,
      });
    });

    return chartValues;
  };

  const getPeriodStart = (): Date => {
    const chartValues = getChartValues();
    if (chartValues.length <= 0) return new Date();

    const dates = chartValues.map((value) => new Date(value.date as string).getTime());
    return new Date(Math.min(...dates));
  };

  const getEndDate = (): Date => {
    switch (activeVariant) {
      case GraphVariant.PREDICTION:
        return dayjs().add(PREDICTION_10_YEARS, 'years').toDate();
      default:
        return new Date();
    }
  };

  const createTicks = () => {
    const startDate = getPeriodStart();
    const endDate = getEndDate();

    if (differenceInYears(endDate, startDate) < 1) {
      return dayjsRange(dayjs(startDate), dayjs(endDate), 'month').map((value) => value.toDate());
    } else {
      return dayjsRange(dayjs(startDate), dayjs(endDate), 'year').map((value) => value.toDate());
    }
  };

  const legends = [
    {
      title: t('graph.long_term_prediction.portfolio_value'),
      color: tw`bg-alps-blue-800`,
    },
    {
      title: t('graph.long_term_prediction.portfolio_payments'),
      color: tw`bg-sky-blue-400`,
    },
  ];

  const VariantButton = ({ variant, children }: { variant: GraphVariant; children: ReactNode }) => (
    <button
      onClick={() => setActiveVariant(variant)}
      tw="rounded-2xl bg-blue-100 px-4 py-2 text-blue-800 text-sm"
      css={[variant !== activeVariant && tw`opacity-40`]}
    >
      {children}
    </button>
  );

  if (!isReady) return null;

  return (
    <div tw="flex flex-col gap-6 pt-8 md:pt-16 mx-6 md:mx-12 select-none">
      <div tw="flex flex-row flex-wrap items-center justify-between gap-4">
        <div tw="flex flex-col md:flex-row flex-nowrap md:items-center gap-2 w-full">
          <div tw="flex flex-row gap-2">
            <h4 tw="text-lg font-semibold text-alps-blue-800 w-max break-normal max-w-full text-center md:text-left">
              {t('graph.long_term_prediction.title')}
            </h4>
            <span tw="pt-2">
              <InfoTooltip>{t('graph.long_term_prediction.disclaimer')}</InfoTooltip>
            </span>
          </div>
          <div tw="flex gap-3 flex-wrap md:ml-auto">
            <VariantButton variant={GraphVariant.SIX_MONTHS}> {t('graph.long_term_prediction.6_months')}</VariantButton>
            <VariantButton variant={GraphVariant.YTD}> {t('graph.long_term_prediction.ytd')}</VariantButton>
            <VariantButton variant={GraphVariant.FULL_PERIOD}>
              {t('graph.long_term_prediction.full_period')}
            </VariantButton>
            <VariantButton variant={GraphVariant.PREDICTION}>
              {t('graph.long_term_prediction.predication')}
            </VariantButton>
          </div>
        </div>

        <div tw="flex flex-row justify-end items-end w-full">
          <ol tw="flex flex-row gap-2">
            {legends.map((legend) => {
              return (
                <li key={legend.title} tw="flex flex-row gap-2 items-center">
                  <div tw="flex items-center justify-center w-4 h-4 rounded-full border border-gray-200">
                    <div tw="w-2 h-2 rounded-full" css={[legend.color]} />
                  </div>
                  <span tw="text-sm text-gray-500">{legend.title}</span>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
      <div tw="z-[1]">
        <ResponsiveContainer width="100%" aspect={1} maxHeight={300}>
          <ComposedChart
            data={getChartValues()}
            tw="bg-transparent rounded"
            margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
          >
            <CartesianGrid stroke="#000" strokeDasharray="4" vertical={false} strokeOpacity={0.1} />

            {/* Define the linear gradient for the lines */}
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FFF" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#FFF" stopOpacity={0.01} />
              </linearGradient>
            </defs>

            <Line
              type="monotone"
              dataKey="currentPayments"
              stroke="#4BB6CD"
              strokeLinejoin="round"
              strokeWidth={2}
              dot={false}
            />
            <Area type="monotone" dataKey="currentPayments" stroke="" fillOpacity={1} fill="url(#colorUv)" />
            <Line
              type="monotone"
              dataKey="currentTotal"
              stroke="#073942"
              strokeLinejoin="round"
              strokeWidth={2}
              dot={false}
            />
            <Area type="monotone" dataKey="currentTotal" stroke="" fillOpacity={1} fill="url(#colorUv)" />
            <Line
              type="monotone"
              dataKey="predictedPayments"
              strokeDasharray="3 3"
              stroke="#4BB6CD"
              strokeLinejoin="round"
              strokeWidth={2}
              dot={false}
            />
            <Area type="monotone" dataKey="predictedPayments" stroke="" fillOpacity={1} fill="url(#colorUv)" />
            <Line
              type="monotone"
              dataKey="predictedTotal"
              strokeDasharray="3 3"
              stroke="#073942"
              strokeLinejoin="round"
              strokeWidth={2}
              dot={false}
            />
            <Area type="monotone" dataKey="predictedTotal" stroke="" fillOpacity={1} fill="url(#colorUv)" />
            <Tooltip content={<CustomTooltip />} cursor={{ opacity: 0.2 }} wrapperStyle={{ outline: 'none' }} />
            <XAxis
              dataKey="shortTime"
              axisLine={false}
              tickSize={15}
              tickLine={false}
              type="number"
              domain={['dataMin', 'dataMax']}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ticks={createTicks()}
              tickFormatter={(value) => {
                const startYear = getPeriodStart().getFullYear();
                const endYear = getEndDate().getFullYear();
                const formatString = startYear === endYear ? 'MMM' : 'YYYY';
                return formatDate(value, formatString) as string;
              }}
              tick={{ fontSize: 12, fill: '#4D878F', fontFamily: 'SuisseIntl, sans-serif', fontWeight: 600 }}
            />
            <YAxis tickCount={10} hide={true} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

enum NumberVariant {
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

const getGraphNumberVariant = (value: number) => {
  if (value === 0) return NumberVariant.NEUTRAL;
  if (value > 0) return NumberVariant.POSITIVE;
  return NumberVariant.NEGATIVE;
};

const getActiveColorForVariant = (variant: NumberVariant) => {
  switch (variant) {
    case NumberVariant.POSITIVE:
      return tw`text-forest-green-600`;
    case NumberVariant.NEGATIVE:
      return tw`text-dawn-600`;
    default:
      return tw`text-gray-900`;
  }
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('portfolio');

  if (active && payload && payload.length) {
    const data: ChartValues = payload[0]?.payload;
    if (!data) return null;

    let title = label;
    const baseDate = data.date != null && new Date(data.date);
    if (baseDate) title = formatDate(baseDate, 'DD MMM YYYY');
    if (data.predictedTotal != null && baseDate) title = formatDate(baseDate, 'MMM YYYY');

    const isCurrentGainValid = data.currentTotal != null && data.currentPayments != null;
    const isPredictedGainValid = data.predictedTotal != null && data.predictedPayments != null;
    const currentGain = isCurrentGainValid ? data.currentTotal! - data.currentPayments! : undefined;

    const currentVariant = getGraphNumberVariant(data.currentTotal! - data.currentPayments!);
    const predictedVariant = getGraphNumberVariant(data.predictedTotal! - data.predictedPayments!);

    const list = [
      {
        label: t('graph.long_term_prediction.portfolio_value'),
        labelStyle: tw`text-white bg-sky-blue-700`,
        value: data.currentTotal,
      },
      {
        label: t('graph.long_term_prediction.predicted_portfolio_value'),
        labelStyle: tw`text-white bg-sky-blue-700`,
        value: data.predictedTotal,
      },
      {
        label: t('graph.long_term_prediction.portfolio_payments'),
        value: data.currentPayments,
      },
      {
        label: t('graph.long_term_prediction.predicted_portfolio_payments'),
        value: data.predictedPayments,
      },
      // hiding gain for now, until we decide what and if we really want to show here
      // {
      //   label: t('graph.long_term_prediction.gain'),
      //   value: isCurrentGainValid ? currentGain : undefined,
      //   prefix: currentVariant === NumberVariant.POSITIVE && '+',
      //   valueStyle: getActiveColorForVariant(currentVariant),
      // },
      // {
      //   label: t('graph.long_term_prediction.predicted_gain'),
      //   value: isPredictedGainValid ? data.predictedGain : undefined,
      //   prefix: predictedVariant === NumberVariant.POSITIVE && '+',
      //   valueStyle: getActiveColorForVariant(predictedVariant),
      // },
    ];

    return (
      <div tw="rounded bg-white text-gray-900 text-base shadow-inner max-w-sm w-full">
        <p tw="text-sm md:text-base text-gray-400 text-center p-4 border-gray-200 border-b">{title}</p>
        <ol tw="p-3 flex flex-col gap-4">
          {list.map((item, idx) => {
            if (item.value == null || isNaN(item.value)) return null;
            return (
              <li key={idx}>
                <h6 tw="text-sm p-1 rounded-sm" css={[item.labelStyle]}>
                  {item.label}
                </h6>
                <span tw="text-base font-mono font-semibold p-1 rounded-sm" css={[item.valueStyle]}>
                  {item.prefix}
                  {formatCurrency(item.value ?? Number(), { maxDigits: 2 })}
                </span>
              </li>
            );
          })}
        </ol>
      </div>
    );
  }

  return null;
};

export default InvestmentPredictionGraph;
