import { useOutletContext, useParams } from 'react-router-dom';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';
import VerificationForm from '../components/VerificationForm';
import { useEffect } from 'react';
import { trackActivationSteps } from '~/utils/tracking-analytics';
import { ActivationLink } from '~/modules/portfolio/ActivateView/ActivationView';

const VerificationStep = () => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onContinue } = context ?? {};
  const params = useParams();
  const { id } = params ?? {};

  useEffect(() => {
    if (id) trackActivationSteps(ActivationLink.VERIFICATION, id || '');
  }, [id]);

  return (
    <OnboardingStepWrapper>
      <VerificationForm onNext={onContinue} />
    </OnboardingStepWrapper>
  );
};

export default VerificationStep;
