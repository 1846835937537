var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var MenuList = function (props) {
    return (React.createElement("ol", __assign({}, props, { className: "flex flex-col text-base font-medium whitespace-nowrap border-none rounded shadow-md bg-white overflow-hidden" })));
};
export var MenuItem = function (props) {
    return (React.createElement("li", null,
        React.createElement("button", __assign({ type: "button" }, props, { className: "w-full flex gap-4 items-center cursor-pointer disabled:cursor-not-allowed px-3 py-2 text-gray-900 hover:bg-gray-50" }))));
};
