import { Button, ButtonVariant, UploadIcon, useTranslation } from '@grunfin/ui-kit';
import { useMemo, useState } from 'react';
import 'twin.macro';
import { createAndDownloadCSVFile, useDocumentTitle } from '~/utils';
import { useGetMembershipCompany, useNavigationTitle } from '../application/Navigation';
import MemberTable, { IMember } from './components/Table/MemberTable';
import { getBenefitMemberships, useGetCompanyStatistics } from './queries';
import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export function CompanyBenefitView() {
  const { t } = useTranslation('company');
  const [employeesCount, setEmployeesCount] = useState(0);
  const selectedProgram = useGetMembershipCompany();
  const { benefitId } = useParams();
  useDocumentTitle(selectedProgram?.company?.companyName ?? t('general.document_title'));
  useNavigationTitle(selectedProgram?.company?.companyName ?? t('general.navigation_title'));

  const getAllEmployees = async () => {
    let result: IMember[] = [];
    if (!benefitId) return result;

    const fetchEmployees = async (page: number) => {
      try {
        const response = await getBenefitMemberships({ benefitId, page, size: 1000, sort: [''] });
        if ('content' in response && Array.isArray(response.content) && response.content.length > 0)
          result = [...result, ...response.content];
        // if it's not the last page, fetch next page
        if ('last' in response && !response.last) await fetchEmployees(page + 1);
      } catch (err) {
        captureException(err);
      }
    };

    await fetchEmployees(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return result.map(({ taxResidencies: _unused, ...rest }) => {
      return rest as IMember;
    });
  };

  const handleDownload = async () => {
    const rows = await getAllEmployees();
    if (rows.length === 0) return;

    await createAndDownloadCSVFile(rows, `${t('employees.file_name')}_${dayjs().format('YYYY-MM-DD')}`);
  };

  return (
    <div tw="flex flex-col gap-8 lg:gap-16 bg-white rounded-2xl p-4 md:p-8">
      {selectedProgram?.benefitProgram && (
        <Statistics employeesCount={employeesCount} companyBenefitId={selectedProgram.benefitProgram.id} />
      )}
      <div tw="flex flex-col-reverse gap-8 lg:flex-row items-start">
        <MemberTable setEmployeesCount={setEmployeesCount} />
      </div>
      {employeesCount > 0 && (
        <div tw="flex flex-row items-end justify-end">
          <Button variant={ButtonVariant.SECONDARY} tw="gap-2" onClick={handleDownload}>
            <UploadIcon tw="rotate-180" /> {t('fees.download_csv')}
          </Button>
        </div>
      )}
    </div>
  );
}

type Stat = {
  label: string;
  value: string;
  background: string;
};

const formatCompactCurrency = (num: number, locale: string) => {
  const formatter = Intl.NumberFormat(locale, { notation: 'compact' });
  return formatter.format(num);
};

const Statistics = ({ employeesCount, companyBenefitId }: { employeesCount: number; companyBenefitId: string }) => {
  const { t, i18n } = useTranslation('company');
  const statistics = useGetCompanyStatistics(companyBenefitId);

  const list: Stat[] = useMemo(
    () => [
      {
        label: t('table.pending_invites'),
        value: formatCompactCurrency(statistics.data?.pendingMembershipCount ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(247,235,171,1) 0%, rgba(247,219,218,1) 50%, rgba(242,199,197,1) 100%)',
      },
      {
        label: t('table.employees'),
        value: formatCompactCurrency(employeesCount, i18n.language),
        background: 'linear-gradient(90deg, rgba(207,231,204,1) 0%, rgba(211,239,239,1) 50%, rgba(219,240,246,1) 100%)',
      },
      {
        label: t('table.funded_portfolios'),
        value: formatCompactCurrency(statistics.data?.fundedPortfolioCount ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(247,235,171,1) 0%, rgba(247,235,171,1) 50%, rgba(252,249,229,1) 100%)',
      },
      {
        label: t('table.first_time_investors'),
        value: formatCompactCurrency(statistics.data?.firstTimeInvestorCount ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(239,247,238,1) 0%, rgba(207,231,204,1) 50%, rgba(224,240,222,1) 100%)',
      },
      {
        label: t('table.employee_contributions'),
        value: formatCompactCurrency(statistics.data?.employeePayments ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(211,239,239,1) 50%, rgba(240,249,248,1) 100%)',
      },
      {
        label: t('table.employer_contributions'),
        value: formatCompactCurrency(statistics.data?.employerPayments ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(247,235,171,1) 0%, rgba(247,219,218,1) 50%, rgba(242,199,197,1) 100%)',
      },
      {
        label: t('table.total_contributions'),
        value: formatCompactCurrency(statistics.data?.totalPayments ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(207,231,204,1) 0%, rgba(211,239,239,1) 50%, rgba(219,240,246,1) 100%)',
      },
      {
        label: t('table.employee_value'),
        value: formatCompactCurrency(statistics.data?.customerValue ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(247,235,171,1) 0%, rgba(247,235,171,1) 50%, rgba(252,249,229,1) 100%)',
      },
      {
        label: t('table.employer_value'),
        value: formatCompactCurrency(statistics.data?.employerValue ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(239,247,238,1) 0%, rgba(207,231,204,1) 50%, rgba(224,240,222,1) 100%)',
      },
      {
        label: t('table.total_value'),
        value: formatCompactCurrency(statistics.data?.totalValue ?? Number(), i18n.language),
        background: 'linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(211,239,239,1) 50%, rgba(240,249,248,1) 100%)',
      },
    ],
    [statistics.data, employeesCount, i18n.language, t],
  );

  return (
    <div tw="flex flex-row flex-wrap gap-4">
      {list.map((item, i) => {
        return (
          <div
            tw="flex flex-1 md:flex-[0 1 calc(20% - 1rem)] flex-col gap-2 w-full rounded-2xl p-4"
            key={i}
            style={{ background: item.background }}
          >
            <div tw="text-sky-blue-700 font-light text-xl md:text-4xl mix-blend-multiply">{item.value}</div>
            <div tw="text-gray-400 text-base mix-blend-multiply">{item.label}</div>
          </div>
        );
      })}
    </div>
  );
};
