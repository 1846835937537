import { Button, ButtonVariant, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';

import { Instructions } from '~/modules/portfolio/components/Instructions';
import { usePortfolio } from '~/modules/portfolio/hooks';
import { useGetDirectDebitConsents } from '~/modules/portfolio/queries';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';

export const StandingOrder = () => {
  const { t } = useTranslation('portfolio');
  const navigate = useNavigate();
  const portfolio = usePortfolio();
  const directDebitConsents = useGetDirectDebitConsents(portfolio.id);

  if (directDebitConsents.isIdle || directDebitConsents.isLoading) {
    return (
      <div tw="flex flex-col w-1/3 items-center">
        <Spinner />
      </div>
    );
  }

  if (directDebitConsents.isError) {
    return (
      <ContactSupportOverlay
        title={t('details.error')}
        onClose={directDebitConsents.refetch}
        error={directDebitConsents.error}
      />
    );
  }

  return (
    <div tw="flex-1">
      <Instructions />
      {directDebitConsents.data.directDebitMethodVisible && (
        <div tw="self-start mt-7">
          <Button variant={ButtonVariant.SECONDARY} onClick={() => navigate('set-up/direct-debit')}>
            {t('details.payment.standing_order.switch')}
          </Button>
        </div>
      )}
    </div>
  );
};
