var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgEurIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 49, height: 48, viewBox: "0 0 49 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M8.75 20H32.75", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M8.75 28H26.75", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M38.7501 12.0001C35.9042 9.41535 32.1945 7.98854 28.3501 8.00007C26.2751 8.02617 24.2257 8.46074 22.3187 9.27897C20.4117 10.0972 18.6846 11.2831 17.236 12.7688C15.7874 14.2546 14.6456 16.0112 13.8759 17.9383C13.1062 19.8653 12.7236 21.9251 12.7501 24.0001C12.7501 32.8001 19.7501 40.0001 28.3501 40.0001C32.3501 40.0001 35.9501 38.4001 38.7501 36.0001", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }))); };
var ForwardRef = forwardRef(SvgEurIcon);
var Memo = memo(ForwardRef);
export default Memo;
