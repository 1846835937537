import {
  AlertDialog,
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  FocusArea,
  formatCurrency,
  formatPercent,
  MenuItem,
  MenuList,
  MoreIcon,
  offset,
  Popover,
  Portfolio,
  PowerIcon,
  toast,
  TrashIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { useSession } from '~/modules/auth/SessionProvider';
import { trackPortfolioActivation, trackPortfolioDelete } from '~/utils/tracking-analytics';
import FocusAreaList from '../components/FocusAreaList';
import { PortfolioStructureListItem } from '../DetailsView/Structure';

import { useDeletePortfolio } from '../queries';
import { PortfolioInstruments } from './Instruments';
import { Playground } from './Playground';
import { Title } from './Title';

enum ListItemIdentifier {
  TARGET_VALUE = 'target_value',
  PAYMENTS = 'payments',
  DEPOSITED_AMOUNT = 'deposited_amount',
  TARGET_GAIN = 'target_gain',
  SUSTAINABILITY_AREAS = 'sustainability_areas',
}

type ListItem = {
  id?: ListItemIdentifier;
  title: string;
  value: string | number | undefined | ReactNode;
  visible?: boolean;
  fullWidth?: boolean;
};

interface Props {
  onboarding?: boolean;
  portfolio: Portfolio;
}

export const DraftItem = ({ portfolio, onboarding = false }: Props) => {
  const deleteQuery = useDeletePortfolio(portfolio.id);
  const { t } = useTranslation('portfolio');

  const handleDeletion = () => {
    trackPortfolioDelete(portfolio.id);

    toast.promise(
      deleteQuery.mutateAsync(),
      {
        loading: t('remove.loading'),
        success: t('remove.success'),
        error: t('remove.error'),
      },
      { style: { minWidth: '184px' } },
    );
  };

  const indicativeGain =
    portfolio.targetDepositedAmountEur == 0
      ? ''
      : portfolio.targetGainEur != null && portfolio.targetDepositedAmountEur != null
        ? formatPercent(portfolio.targetGainEur / portfolio.targetDepositedAmountEur)
        : '';

  const list: ListItem[] = [
    {
      id: ListItemIdentifier.DEPOSITED_AMOUNT,
      title: t('deposited_amount'),
      value:
        portfolio.targetDepositedAmountEur != null
          ? formatCurrency(portfolio.targetDepositedAmountEur, { digits: 2 })
          : undefined,
      visible: portfolio.targetDepositedAmountEur != null,
    },
    {
      id: ListItemIdentifier.TARGET_GAIN,
      title: t('indicative_gain', { amount: indicativeGain }),
      value: portfolio.targetGainEur != null ? formatCurrency(portfolio.targetGainEur, { digits: 2 }) : undefined,
      visible: portfolio.targetGainEur != null,
    },
    {
      id: ListItemIdentifier.TARGET_VALUE,
      title: t('indicative_value'),
      value:
        portfolio.targetValueEur != null ? (
          <p tw="font-bold">{formatCurrency(portfolio.targetValueEur, { digits: 2 })}</p>
        ) : undefined,
      visible: portfolio.targetValueEur != null,
    },
    {
      id: ListItemIdentifier.SUSTAINABILITY_AREAS,
      title: t('sustainability_areas'),
      value: <FocusAreaList focuses={portfolio.focus.split(', ') as FocusArea[]} />,
    },
  ];

  const filteredList = list.filter((item: ListItem) => !('visible' in (item ?? {}) && item.visible === false));

  const isBenefitProgram = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;

  return (
    <>
      {!onboarding && (
        <Title portfolio={portfolio}>
          <div tw="flex flex-row gap-4">
            {!isBenefitProgram && (
              <ActivateLink id={portfolio.id}>
                <Button variant={ButtonVariant.PRIMARY} data-test-id="portfolio-activate">
                  <PowerIcon tw="-ml-2" width="24" height="24" />
                  <span>{t('activate', { ns: 'general' })}</span>
                </Button>
              </ActivateLink>
            )}
            <Popover
              showOnHover={false}
              options={{ middleware: [offset(20)] }}
              content={
                <MenuList>
                  <AlertDialog
                    title={t('remove.prompt.title')}
                    description={t('remove.prompt.notice')}
                    actions={(closeFn: () => void) => (
                      <div tw="flex flex-col md:flex-row gap-y-2 md:whitespace-nowrap">
                        <Button variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} onClick={closeFn}>
                          {t('remove.prompt.cancel')}
                        </Button>
                        <div tw="my-2 md:mx-2" />
                        <Button
                          variant={ButtonVariant.PRIMARY}
                          size={ButtonSize.LARGE}
                          onClick={() => {
                            handleDeletion();
                            closeFn();
                          }}
                        >
                          {t('remove.prompt.confirm')}
                        </Button>
                      </div>
                    )}
                  >
                    <MenuItem>
                      <TrashIcon tw="rounded-md bg-dusk-50 text-dusk-700" /> {t('remove.button')}
                    </MenuItem>
                  </AlertDialog>
                </MenuList>
              }
            >
              <div className="ml-6">
                <Button variant={ButtonVariant.SECONDARY} className="rounded-lg ml-4">
                  <MoreIcon />
                </Button>
              </div>
            </Popover>
          </div>
        </Title>
      )}

      <ol tw="flex flex-col sm:flex-row flex-wrap sm:justify-between gap-4 sm:gap-8 p-6 md:p-12">
        {filteredList.map((item) => (
          <PortfolioStructureListItem key={item.id} item={item} />
        ))}
      </ol>

      <div tw="w-full px-6 md:px-12">
        <div tw="w-full h-[1px] bg-gray-100" />
      </div>

      {!isBenefitProgram && <Playground portfolio={portfolio} />}
      <PortfolioInstruments instruments={portfolio.assets} />
    </>
  );
};

const ActivateLink = ({ id, children }: { id: string; children: ReactNode }) => {
  const session = useSession();
  const path = `/portfolio/${id}/activate`;
  const to = session.authenticated ? path : `/signup?next=${path}`;

  return (
    <Link to={to} tw="flex items-center text-lg" onClick={() => trackPortfolioActivation(id)}>
      {children}
    </Link>
  );
};
