import { instrumentColorMap, Tooltip } from '@grunfin/ui-kit';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
var fallbackColors = Object.values(instrumentColorMap);
var AllocationPie = function (_a) {
    var assets = _a.assets, bonds = _a.bonds, shares = _a.shares, totalValue = _a.totalValue;
    var t = useTranslation('calculator').t;
    var _b = useState(), activeIdx = _b[0], setActiveIdx = _b[1];
    var totalAssetValueEur = assets.reduce(function (acc, row) { return acc + row.totalValue; }, 0);
    var data = useMemo(function () {
        return assets.map(function (r, i) { return ({
            color: instrumentColorMap[r === null || r === void 0 ? void 0 : r.isin] || fallbackColors[i % assets.length] || '#bada55',
            value: totalValue && totalAssetValueEur
                ? r.totalValue / totalAssetValueEur
                : r.targetShare != null
                    ? r.targetShare
                    : 0,
        }); });
    }, [assets]);
    var name = activeIdx !== undefined && (React.createElement(React.Fragment, null,
        assets[activeIdx].name,
        React.createElement("span", { className: "ml-2 text-neutral-400" }, t("allocation.".concat(assets[activeIdx].assetClass.toLowerCase())))));
    return (React.createElement(Tooltip, { content: name },
        React.createElement("div", { className: "relative mx-auto max-w-[270px]" },
            React.createElement(PieChart, { data: data, lineWidth: 14, onMouseOver: function (_, index) { return setActiveIdx(index); }, onMouseOut: function () { return setActiveIdx(undefined); }, paddingAngle: 10, rounded: true }),
            React.createElement("div", { className: "absolute top-0 left-0 w-full h-full flex flex-col md:flex-row space-y-6 md:space-y-0 justify-center items-center pointer-events-none" },
                React.createElement(Indicator, { label: t('allocation.stock') },
                    Math.round(shares * 100),
                    "%"),
                React.createElement(Indicator, { label: t('allocation.bond') },
                    Math.round(bonds * 100),
                    "%")))));
};
var Indicator = function (props) { return (React.createElement("div", { className: "px-3 text-center" },
    React.createElement("div", { className: "text-xl" }, props.children),
    React.createElement("div", { className: "text-sm text-gray-300" }, props.label))); };
export default AllocationPie;
