var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, memo } from 'react';
var SvgEuroIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26ZM19 11.8C19.4418 11.8 19.8 11.4418 19.8 11C19.8 10.5582 19.4418 10.2 19 10.2H17C14.4996 10.2 12.3688 11.7822 11.5541 14H10C9.72386 14 9.5 14.2239 9.5 14.5C9.5 14.7761 9.72386 15 10 15H11.2859C11.2294 15.3249 11.2 15.659 11.2 16C11.2 16.341 11.2294 16.6751 11.2859 17H10C9.72386 17 9.5 17.2239 9.5 17.5C9.5 17.7761 9.72386 18 10 18H11.5541C12.3688 20.2178 14.4996 21.8 17 21.8H19C19.4418 21.8 19.8 21.4418 19.8 21C19.8 20.5582 19.4418 20.2 19 20.2H17C15.4045 20.2 14.0168 19.3103 13.3058 18H18C18.2761 18 18.5 17.7761 18.5 17.5C18.5 17.2239 18.2761 17 18 17H12.9198C12.8415 16.6795 12.8 16.3446 12.8 16C12.8 15.6554 12.8415 15.3205 12.9198 15H18C18.2761 15 18.5 14.7761 18.5 14.5C18.5 14.2239 18.2761 14 18 14H13.3058C14.0168 12.6897 15.4045 11.8 17 11.8H19Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgEuroIcon);
var Memo = memo(ForwardRef);
export default Memo;
