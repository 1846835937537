import { i18n } from '@grunfin/ui-kit';
import ky, { HTTPError, NormalizedOptions } from 'ky';

import { StorageKey } from '~/modules/auth/types';
import { trackException } from '~/utils/tracking-analytics';
import { isDev } from './utils';

enum Header {
  ACCEPT_LANGUAGE = 'Accept-Language',
  X_AUTH_TOKEN = 'X-Auth-Token',
}

interface ValidationErrorField {
  errorMessage: string;
  fieldKey: string;
  rejectedValue: string | null;
}

export class ValidationError extends HTTPError {
  constructor(response: Response, request: Request, options: NormalizedOptions, fields: ValidationErrorField[]) {
    super(response, request, options);
    this.name = 'ValidationError';
    this.message = `${request.method} ${request.url}`;
    this.fields = fields;
  }

  fields: ValidationErrorField[];

  toJSON() {
    return { fields: this.fields };
  }
}

export class ServerError extends HTTPError {
  constructor(response: Response, request: Request, options: NormalizedOptions, errorMessage: string) {
    super(response, request, options);
    this.name = 'ServerError';
    this.message = `${request.method} ${request.url}`;
    this.errorMessage = errorMessage;
  }

  errorMessage: string;

  toJSON() {
    return { errorMessage: this.errorMessage };
  }
}

export const apiRoot = `${import.meta.env.VITE_API_ORIGIN}/api`;

const getToken = () => {
  let token;
  try {
    token = localStorage?.getItem(StorageKey.TOKEN);
  } catch (e) {
    console.log(e);
  }
  return token;
};

export const api = ky.extend({
  prefixUrl: apiRoot,
  credentials: 'include',
  timeout: 60000,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set(Header.ACCEPT_LANGUAGE, i18n.resolvedLanguage);
        const token = getToken();
        if (token) {
          request.headers.set(Header.X_AUTH_TOKEN, token);
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.ok) {
          return response;
        } else if (response.status === 422) {
          const { errors } = await response.clone().json();
          throw new ValidationError(response, request, options, errors);
        } else if (response.status === 401 && !request.url.endsWith('/api/login')) {
          window.location.href = '/';
        } else {
          let errorMessage;
          try {
            const { message, exception, error } = JSON.parse(await response.clone().text());
            errorMessage = message || exception || error;
          } catch (e) {
            errorMessage = (await response.clone().text()) || response.status;
          }

          if (isDev) console.log(errorMessage);
          trackException(errorMessage);
          throw new ServerError(response, request, options, errorMessage);
        }
      },
      (request, options, response) => {
        if (response.headers.has(Header.X_AUTH_TOKEN)) {
          const token = response.headers.get(Header.X_AUTH_TOKEN) as string;
          try {
            localStorage.setItem(StorageKey.TOKEN, token);
          } catch (e) {
            console.log(e);
          }
        }
      },
    ],
  },
});
