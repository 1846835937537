var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgInfoIcon = function (props, ref) { return (React.createElement("svg", __assign({ fill: "none", height: 32, viewBox: "0 0 32 32", width: 32, xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { clipRule: "evenodd", d: "m16 11c1.1046 0 2-.8954 2-2 0-1.10457-.8954-2-2-2s-2 .89543-2 2c0 1.1046.8954 2 2 2zm1 4c0-.5523-.4477-1-1-1s-1 .4477-1 1v9c0 .5523.4477 1 1 1s1-.4477 1-1z", fill: "currentColor", fillRule: "evenodd" }))); };
var ForwardRef = forwardRef(SvgInfoIcon);
var Memo = memo(ForwardRef);
export default Memo;
