import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
var TreeCounter = function (_a) {
    var count = _a.count, whiteBg = _a.whiteBg, variant = _a.variant;
    var t = useTranslation('calculator').t;
    var wrapper = twMerge('flex items-center gap-x-2 font-semibold bg-forest-green-50 text-forest-green-700 rounded pl-2 pr-4', whiteBg && 'bg-white', variant != null && 'bg-[#FFFFFF32] backdrop-blur-md');
    var plantIcon = (React.createElement("svg", { width: "32", height: "32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#a)" },
            React.createElement("path", { d: "m15.58 31.94.18-4.48a20 20 0 0 1 5.84-13.33l1.2-1.2M15.58 31.94v-2.56A19 19 0 0 0 9.3 15.3l-.44-.4", stroke: "#A9CBA8", strokeWidth: "1.6", strokeLinecap: "round" }),
            React.createElement("path", { d: "M26.8 17.12a5.92 5.92 0 0 1-9.43-7.16c1.97-2.6 9.91-5.02 10.75-4.38.84.63.66 8.93-1.32 11.54ZM12.87 12.88a4.16 4.16 0 0 1-6.16 5.58c-1.54-1.7-2.17-7.5-1.63-8 .55-.49 6.25.72 7.8 2.42Z", fill: "#92BA92", style: { mixBlendMode: 'multiply' } })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "a" },
                React.createElement("path", { d: "M0 13.76C0 8.37 0 5.68 1.25 3.71A8 8 0 0 1 3.7 1.25C5.68 0 8.37 0 13.76 0h4.48c5.39 0 8.08 0 10.05 1.25a8 8 0 0 1 2.46 2.46C32 5.68 32 8.37 32 13.76v4.48c0 5.39 0 8.08-1.25 10.05a8 8 0 0 1-2.46 2.46C26.32 32 23.63 32 18.24 32h-4.48c-5.39 0-8.08 0-10.05-1.25a8 8 0 0 1-2.46-2.46C0 26.32 0 23.63 0 18.24v-4.48Z", fill: "#fff" })))));
    var christmasTreeIcon = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 128 128", style: { width: '32px', height: '32px' } },
        React.createElement("path", { d: "M57.86 106.24s-.49 3.99-1.4 7c-.77 2.55-1.99 4.52-2.1 7.14c-.09 2.16 1.61 3.84 9.59 3.99c6.78.12 9.13-2.01 8.92-4.95c-.2-2.73-1.36-4.78-1.99-7.93c-.45-2.27-.77-4.97-.77-4.97l-12.25-.28z", fill: "#835d4e" }),
        React.createElement("path", { d: "M67.01 27.93l-17.47 6.48l-11.62 2s-1.39 1.76.42 3.83c1.31 1.49 3.51 2.56 6.52 2.59c3.39.03 4.76-1.3 5.14-.17c.38 1.13-4.3 3.92-6.87 5.43c-2.57 1.51-10.97 4.11-11.34 7.87c-.28 2.89 2.6 4.81 4.02 5.26c1.81.57 4.38.45 5.13.53c.75.08.98 1.51-.08 2.41c-1.06.91-3.92 1.89-5.88 2.64c-1.96.75-9.96 3.4-11.02 3.85c-1.06.45-2.26 2.34-1.36 5.13c.91 2.79 4.15 4.9 7.09 5.96c2.94 1.06 3.77.6 3.77.6s-.41 1.6-1.88 2.93s-6.12 6.28-6.12 6.28s-8.79.23-9.56.86c-.77.63-1.26 2.17-.91 3.64c.35 1.47 1.47 5.18 8.47 7.21c7 2.03 11.69 0 11.69 0s-2.32 4.46 3.01 6.58c4.02 1.6 10.15-.28 11.69-.7c1.54-.42 6.93-2.66 6.93-2.66s1.47 3.52 7.42 3.78c6.3.28 10.71-2.8 10.71-2.8s8.72 5.33 15.4 2.52c4.83-2.03 3.29-6.86 3.29-6.86s8.47 1.47 14.63-.98s7.14-5.81 7.14-7.49s-2.45-3.1-3.57-3.03c-1.12.07-4.41-.33-6.37-1.17s-4.6-2.21-4.6-2.21s-4.58-1.5-7.58-4.09c-1.25-1.07-1.12-2.17.7-2.24c1.82-.07 7.34-.02 10.5-2.31c3.32-2.41 3.32-4.56 3.15-5.53c-.25-1.39-1.73-1.95-1.73-1.95s-12.68-4-12.96-4.35c-.28-.35-2.31-2.66-2.52-2.87c-.21-.21-3.78-1.54-3.01-2.59s4.82-1.08 7.14-3.5c2.71-2.83 1.96-5.39 1.05-6.37s-2.55-1.49-2.55-1.49s-3.08.91-4.13.42c-1.05-.49-6.05-5.29-6.05-5.29s-4.25-2.37-4.2-3.09c.07-.98 4.36.03 7.91-1.12c3.19-1.03 3.07-3.23 2.87-4.06c-.31-1.3-1.46-1.84-2.23-2.4c-.78-.55-20.18-7.48-20.18-7.48z", fill: "#0a7c1f" }),
        React.createElement("path", { d: "M59.89 15.19s-2.75 5.72-7.42 10.92c-7.42 8.26-14.63 10.36-14.63 10.36s.91 2.66 5.74 2.52c5.46-.16 12.6-4.06 12.6-4.06s2.1 5.04 4.69 7.98c3.11 3.53 6.77 6.1 10.43 4.2c1.38-.72 1.66-3.72 0-5.81c-4.08-5.14-1.45-4.76-1.16-4.5c0 0 3.19 2.47 9.06 2.4s7.98-3.78 7.98-3.78s-7.28-4.06-12.04-9.59c-4.76-5.53-8.68-10.29-8.68-10.29l-6.57-.35z", fill: "#02ab48" }),
        React.createElement("path", { d: "M56.88 45.28c-.01.01-3.4 2.19-7.21 1.89c-1.65-.13-2.82-1.36-2.82-1.36s-3.78 2.22-5.65 3.18c-2.31 1.19-5.27 2.37-6.83 3.5c-.77.56-1.85 1.75-1.85 1.75s.56 2.59 5.46 2.59s8.19-.63 11.2.21s7.7 5.53 10.99 5.53s5.6-4.41 8.75-4.41s3.99 1.61 8.82 2.1s12.46-2.31 14.21-3.99c2.37-2.28 1.08-5.32 1.08-5.32s-3.07-1.23-5.83-2.57c-2.39-1.16-4.34-2.26-4.34-2.26s-1.12 1.26-2.38 1.19c-1.26-.07-3.07-.79-4.54.33s-2.35 2.88-5.26 3.31c-7.15 1.05-13.8-5.67-13.8-5.67z", fill: "#02ab48" }),
        React.createElement("path", { d: "M23.92 70.69s-1.02 3.4 3.68 5.51s13.53.24 13.94.65c.41.41-7.24 4.55-3.24 7.94c4.78 4.05 17.1-8.02 17.91-8.02s1.22 9.97 6.16 11.75s9.8-5.51 12.8-6.4c3-.89 5.19 1.78 9.07 1.94c5.12.21 7.62-1.13 7.94-3.24c.32-2.11-4.21-5.91-3.24-6.72c.97-.81 2.67 2.19 9.48 1.7c6.81-.49 7.49-3.68 7.49-3.68l-15.56-7.21s-2.14 2.55-5.54 2.63c-3.4.08-11.99-1.38-15.39-.41c-3.4.97-4.46 4.86-10.13 4.62c-5.67-.24-9.48-4.05-13.37-2.19c-3.89 1.86-6.74 4.24-8.9 2.91c-1.24-.77-1.11-1.87-1-3.58c.08-1.3.58-2.67.58-2.67l-12.68 4.47z", fill: "#02ab48" }),
        React.createElement("path", { d: "M15.86 92.45s2.18 5.96 12.81 5.96c9.08 0 12.27-5.04 13.99-6.48c1.71-1.44 2.95-3.46 3.5-2.57c.55.89-5.49 7.41-7.96 9.81c-1.38 1.34-3.09 3.91-.48 5.56c2.61 1.65 8.71.89 13.65-1.23s6.38-6.52 6.38-6.52s-3.7-1.44-4.39-5.9c-.69-4.46-.89-6.35-2.33-6.55c-1.44-.21-11.7 8.16-18.05 5.45c-3.02-1.29-1.45-4.66-1.45-4.66s-2.91 2.3-6.82 3.79c-2.99 1.13-8.85 3.34-8.85 3.34z", fill: "#02ab48" }),
        React.createElement("path", { d: "M59.75 97.32s-1.28 4-1.02 4.98c.49 1.87 2.32 3.8 6.92 3.45c4.6-.34 7.13-2.06 7.07-3.57s-3.02-5.28-1.58-6.24s2.47 1.23 3.91 3.57s7.68 9.88 14.27 6.31c6.03-3.27-2.06-9.74-.41-10.77s5.56 2.6 11.52 2.33c10.56-.48 12-5.69 12-5.69s-4.05-1.03-6.38-1.65s-5.22-1.79-5.22-1.79s-4.73 2.41-11.31 2.2s-10.5-2.81-13.31-2.33c-2.81.48-4.94 4.45-8.44 6.72c-4.87 3.16-8.02 2.48-8.02 2.48z", fill: "#02ab48" }),
        React.createElement("path", { d: "M67.4 17.53c-.06.18-1.68 5.99-3.41 5.99c-1.74 0-4.61-6.23-4.61-6.23s-6.07-.19-6.35-1.02c-.36-1.08 4.01-5.69 4.01-5.69s-.78-6.35-.06-6.95c.81-.68 6.47 3.11 6.47 3.11s5.76-3.29 6.71-2.58c.96.72-.78 6.17-.78 6.17s4.61 3.77 4.25 4.79c-.36 1.03-6.23 2.41-6.23 2.41z", fill: "#fcc11a" }),
        React.createElement("path", { d: "M59.73 5.54c-.25.2.23 1.46 1.47 2.93s2.11 2.69 2.38 2.73c.27.03 2.08-2.28 2.71-2.98c.64-.7 1.39-2.09 1.17-2.31c-.4-.4-3.95 2.08-3.95 2.08s-3.36-2.78-3.78-2.45z", fill: "#fee56b" }),
        React.createElement("path", { d: "M53.76 15.57c.29.21 7.37-2.45 7.47-2.81c.11-.42-2.11-1.37-2.78-1.57c-.67-.2-1.88.84-3.01 2.08c-.97 1.04-1.92 2.13-1.68 2.3z", fill: "#fee56b" }),
        React.createElement("path", { d: "M65.45 25.97c-3.56.05-5.14 2.49-5.1 5.43s2.15 4.93 5.47 4.89s5.05-2.32 4.93-5.51c-.1-2.78-2.07-4.85-5.3-4.81z", fill: "#ff5f5e" }),
        React.createElement("path", { d: "M79.17 45.8c-3.56.05-5.63 2.69-5.59 5.63c.04 2.94 2.28 5.22 5.84 5.18c3.65-.04 5.47-2.65 5.34-5.84c-.1-2.77-2.36-5.01-5.59-4.97z", fill: "#fcd81e" }),
        React.createElement("path", { d: "M55.26 46.83c-3.73.05-5.72 2.63-5.68 5.76c.04 3.13 2.54 5.35 6.01 5.3c3.47-.04 5.39-2.53 5.26-5.92c-.11-2.96-2.21-5.18-5.59-5.14z", fill: "#df0e2b" }),
        React.createElement("path", { d: "M45.41 65.77c-3.77.04-5.63 2.82-5.59 6.09c.05 3.27 2.33 5.43 6 5.38c3.67-.05 5.6-2.58 5.47-6.13c-.12-3.08-2.3-5.38-5.88-5.34z", fill: "#ffd2b1" }),
        React.createElement("path", { d: "M66.44 71.46c-3.96.05-5.71 2.76-5.66 6.03c.05 3.27 2.39 5.48 6.07 5.43c3.68-.05 5.61-2.58 5.48-6.12c-.12-3.08-2.3-5.38-5.89-5.34z", fill: "#fcd81e" }),
        React.createElement("path", { d: "M85.44 65.81c-3.99.05-5.94 2.82-5.9 6.13c.05 3.31 2.37 5.76 6.09 5.72c3.71-.05 5.89-2.84 5.75-6.43c-.11-3.13-2.31-5.46-5.94-5.42z", fill: "#ff5f5d" }),
        React.createElement("path", { d: "M87.49 94.01c.18-3.65-1.98-5.57-4.98-5.75s-5.27 1.65-5.48 5.04c-.21 3.39 1.84 5.66 5.1 5.77c2.84.1 5.19-1.57 5.36-5.06z", fill: "#c9c9c9" }),
        React.createElement("path", { d: "M47.05 88.6c-3.71.05-5.35 2.55-5.31 5.56c.04 3.01 2.24 5.05 5.69 5.01s5.26-2.38 5.13-5.64c-.1-2.86-2.14-4.98-5.51-4.93z", fill: "#ff5f5e" }),
        React.createElement("path", { d: "M43.55 94.06c1.08.58 1.49-.99 2.03-1.53c.54-.54 1.82-1.45 1.2-2.24c-.62-.79-2.2-.34-2.94.5c-1 1.12-1.17 2.8-.29 3.27z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M79.01 93.9c1.08.58 1.54-1 1.99-1.62c.58-.79 1.99-1.33 1.37-2.11c-.62-.79-2.12-.29-2.86.54c-1 1.12-1.38 2.71-.5 3.19z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M81.76 71.65c1.19.61 1.64-1.11 2.22-1.82c.52-.63 2.04-1.31 1.35-2.14c-.69-.83-2.31-.39-3.13.48c-1.1 1.18-1.41 2.98-.44 3.48z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M62.94 77.37c1.08.58 1.49-.99 2.03-1.53c.54-.54 1.82-1.74 1.2-2.53c-.62-.79-2.15-.29-2.94.79c-.88 1.21-1.17 2.8-.29 3.27z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M41.85 71.69c1.08.58 1.76-1.06 2.23-1.66c.91-1.16 1.78-1.78 1.16-2.57s-2.28-.12-3.1.95c-.9 1.2-1.16 2.81-.29 3.28z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M51.74 52.55c1.08.58 1.6-.91 2.03-1.53c.66-.95 1.9-1.74 1.28-2.53s-2.11-.37-3.02.79c-.92 1.17-1.17 2.79-.29 3.27z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M75.84 52.04c1.18.64 1.63-1.09 2.22-1.68c.59-.59 2-1.59 1.32-2.45c-.68-.86-2.41-.37-3.22.54c-1.1 1.23-1.29 3.08-.32 3.59z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M61.89 31.31c1.08.58 1.49-.99 2.03-1.53s1.82-1.45 1.2-2.24c-.62-.79-1.87-.37-2.78.7c-.96 1.15-1.33 2.6-.45 3.07z", fill: "#f9ffff" }),
        React.createElement("path", { d: "M50.51 32.26c-1.54 0-2.24.66-2.36 2.03c-.12 1.33.71 2.15 1.95 2.24c1.74.12 2.28-1.32 2.28-2.24c-.01-1.04-.84-2.03-1.87-2.03z", fill: "#f9c319" }),
        React.createElement("path", { d: "M57.12 38.13c-1.3-.82-2.42-.27-2.96.4c-.77.95-.65 2.4.43 2.99c1.41.77 2.54.18 3-.51c.7-1.04.49-2.27-.47-2.88z", fill: "#f9c319" }),
        React.createElement("path", { d: "M63.02 40.9c-1.33.25-1.95 1.32-1.69 2.67c.2 1.06 1.37 1.81 2.57 1.52c1.7-.4 1.78-1.95 1.5-2.82c-.3-.98-1.36-1.55-2.38-1.37z", fill: "#f9c319" }),
        React.createElement("path", { d: "M79.8 34.7c-1.21-.95-2.45-.59-3.08.2c-.72.9-.78 2.07.13 2.91c1.08.99 2.59.47 3.12-.21c.61-.77.64-2.26-.17-2.9z", fill: "#f9c319" }),
        React.createElement("path", { d: "M72.92 40.21c-1.55 0-2.26.66-2.39 2.02c-.12 1.32.76 2.18 2.01 2.27c1.76.12 2.26-1.36 2.26-2.27c.01-1.03-.83-2.02-1.88-2.02z", fill: "#f9c319" }),
        React.createElement("path", { d: "M44.99 54.71c-1.58 0-2.3.67-2.43 2.05c-.12 1.34.73 2.17 2 2.26c1.79.13 2.34-1.34 2.34-2.26c0-1.05-.85-2.05-1.91-2.05z", fill: "#f9c319" }),
        React.createElement("path", { d: "M52.11 60.16c-1.52-.48-2.34-.02-2.96 1.25c-.5 1.03 0 2.4 1.22 2.81c1.76.58 2.66-.58 2.94-1.48c.33-1.02-.18-2.26-1.2-2.58z", fill: "#f9c319" }),
        React.createElement("path", { d: "M61.55 66.61c-.04-1.6-.78-2.31-2.25-2.4c-1.44-.09-2.29.8-2.35 2.1c-.08 1.82 1.49 2.34 2.47 2.31c1.12-.04 2.16-.93 2.13-2.01z", fill: "#f9c319" }),
        React.createElement("path", { d: "M69.11 63.82c-1.65 0-2.4.7-2.54 2.15c-.13 1.41.76 2.27 2.09 2.37c1.87.13 2.45-1.4 2.45-2.37c0-1.1-.89-2.15-2-2.15z", fill: "#f9c319" }),
        React.createElement("path", { d: "M78.41 60.96c-1.54 0-2.24.66-2.36 2.03c-.12 1.33.71 2.15 1.95 2.24c1.74.12 2.28-1.32 2.28-2.24c-.01-1.03-.84-2.03-1.87-2.03z", fill: "#f9c319" }),
        React.createElement("path", { d: "M84.57 59.03c.31 1.37 1.37 1.89 2.7 1.55c1.29-.33 1.79-1.38 1.46-2.58c-.46-1.68-2.01-1.71-2.87-1.4c-.97.34-1.51 1.42-1.29 2.43z", fill: "#f9c319" }),
        React.createElement("path", { d: "M97.96 73.12c-1.46 0-2.13.64-2.25 1.95c-.12 1.28.67 2.07 1.86 2.15c1.66.12 2.17-1.27 2.17-2.15c0-.99-.79-1.95-1.78-1.95z", fill: "#f9c319" }),
        React.createElement("path", { d: "M93.39 80.86c.15-1.69-.5-2.52-1.98-2.79c-1.44-.26-2.41.57-2.63 1.92c-.31 1.9 1.22 2.63 2.22 2.72c1.13.1 2.29-.71 2.39-1.85z", fill: "#f9c319" }),
        React.createElement("path", { d: "M83.08 81.9c-1.62.07-2.5 1.18-2.4 2.78c.1 1.56 1.23 2.35 2.7 2.22c2.06-.18 2.43-1.95 2.26-3.01c-.19-1.19-1.33-2.05-2.56-1.99z", fill: "#f9c319" }),
        React.createElement("path", { d: "M73.7 85.24c-1.65-.21-2.64.76-2.79 2.34c-.14 1.53.84 2.45 2.3 2.58c2.36.22 2.69-1.52 2.69-2.58c0-1.19-.99-2.19-2.2-2.34z", fill: "#f9c319" }),
        React.createElement("path", { d: "M61.97 86.52c-1.62.07-2.5 1.18-2.4 2.78c.1 1.56 1.23 2.35 2.7 2.22c2.06-.18 2.43-1.95 2.26-3.01c-.18-1.19-1.33-2.04-2.56-1.99z", fill: "#f9c319" }),
        React.createElement("path", { d: "M51.75 83.38c-1.59.07-2.42 1.33-2.35 2.71c.07 1.51 1.2 2.28 2.64 2.15c2.02-.18 2.38-1.9 2.22-2.92c-.19-1.17-1.31-2-2.51-1.94z", fill: "#f9c319" }),
        React.createElement("path", { d: "M42.16 79.51c-1.59.07-2.45 1.15-2.35 2.7c.1 1.51 1.19 2.27 2.63 2.14c2.02-.18 2.38-1.89 2.22-2.92c-.18-1.15-1.3-1.97-2.5-1.92z", fill: "#f9c319" }),
        React.createElement("path", { d: "M36.16 71.92c-1.37.06-2.11 1-2.02 2.36c.09 1.32 1.03 1.99 2.27 1.88c1.74-.15 2.05-1.65 1.9-2.55c-.15-1.01-1.12-1.73-2.15-1.69z", fill: "#f9c319" })));
    var month = new Date().getMonth();
    return (React.createElement("div", { className: wrapper },
        month === 10 || month === 11 ? christmasTreeIcon : plantIcon,
        React.createElement(Trans, { i18nKey: "trees_planted", t: t, count: count },
            { count: count },
            " ",
            React.createElement("span", { className: "hidden md:inline" }, "trees planted"))));
};
export default TreeCounter;
