import { BenefitMembership, BenefitProgram } from '../company/types';

export enum QueryParam {
  NEXT = 'next',
  TOKEN = 'token',
}

export enum StorageKey {
  NEXT = 'next',
  TOKEN = 'token',
  NEWSLETTERS = 'newsletters',
  CAMPAIGN = 'campaign',
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface SignupRequest {
  email: string;
  password: string;
  lang: string;
}

export enum AuthMethod {
  GOOGLE = 'google-oauth2',
  EMAIL = 'auth0',
  APPLE = 'apple',
}

export enum RelatedPersonRole {
  REPRESENTATIVE = 'REPRESENTATIVE',
  ADMIN = 'ADMIN',
  FINANCE = 'FINANCE',
  HR = 'HR',
}

export type SessionCompany = {
  companyId: string;
  companyName: string;
  accessibleBenefitPrograms: BenefitProgram[];
  role: RelatedPersonRole;
};

export type SelectedProgram = {
  company: SessionCompany | undefined;
  benefitProgram: BenefitProgram | undefined;
};

export enum VerificationStatus {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  RESUBMIT = 'RESUBMIT',
  COMPLETE = 'COMPLETE',
}

export interface Session {
  authenticated: boolean;
  email: string | undefined;
  emailVerified: boolean;
  firstName: string | null;
  lastName: string | null;
  personalDataComplete: boolean;
  userId: string | undefined;
  cfIpcountry: string | undefined;
  referralCode: string | undefined;
  referralProgram: string | undefined;
  verification: VerificationStatus;
  onboardingStatus: {
    contract: 'SIGNED' | 'PENDING' | 'IN_REVIEW' | 'NOT_SIGNED';
    payment: 'NOT_STARTED' | 'SET_UP' | 'RECEIVED';
  } | null;
  pendingPortfolioId: string | null;
  portfolioCount: number;
  authMethods: AuthMethod[] | null;
  customerHmac: string;
  customerId: string;
  updatedAt: string;
  referralPending: boolean;
  companies?: SessionCompany[];
  benefitMemberships: BenefitMembership[];
}

export interface UserMetadata {
  lang?: string;
}
