import { FocusArea, PortfolioStatus } from '@grunfin/ui-kit';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { api } from '~/api';
import { AuthMethod } from '../auth/types';
import { InvestmentTerm } from '../onboarding/types';

export interface EnrollmentResponse {
  givenName?: string | undefined;
  email?: string | undefined;
  companyName?: string | undefined;
  portfolioId?: string | undefined;
  portfolioStatus?: PortfolioStatus | undefined;
  focuses?: FocusArea[] | undefined;
  lockPeriodDays?: number | undefined;
  // only valid if portfolio scheme is MATCHING
  //risk: PreferredRiskReturnRatio | undefined;
  investmentTerm?: InvestmentTerm | undefined;
  managementFeePercentage?: number | undefined;
  authMethods?: AuthMethod[];
}

export const useGetEnrollment = (membershipId?: string, options?: UseQueryOptions<EnrollmentResponse, Error>) =>
  useQuery<EnrollmentResponse, Error>(
    ['enrollment', membershipId],
    async () => api.get(`company/benefits/enroll?membershipId=${membershipId}`).json<EnrollmentResponse>(),
    options,
  );

export const useSubmitEnrollment = () =>
  useMutation('enrollment', async (membershipId: string) =>
    api.post(`company/benefits/enroll`, { json: { membershipId } }),
  );
