import {
  BeneficiaryType,
  Button,
  ButtonVariant,
  ContractStatus,
  InfoIcon,
  PaymentStatus,
  Portfolio,
  Spinner,
  useTranslation,
} from '@grunfin/ui-kit';
import 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';

import { Link } from 'react-router-dom';
import { useSession } from '~/modules/auth/SessionProvider';
import { usePortfolioStatus } from '../queries';
import { PaymentInstructions } from './PaymentInstructions';
import { VerificationStatus } from '~/modules/auth/types';
import { isValidElement } from 'react';
import { ActivationLink } from '../ActivateView/ActivationView';
import {
  EmployeeOnboardingLink,
  useEmployeeOnboardingBaseRoute,
} from '~/modules/benefits/enroll/EmployeeOnboardingView';
import { CompanyStatus, PortfolioStatus } from '~/modules/portfolio/types';

interface Props {
  portfolio: Portfolio;
}

type ListItem = {
  title: string;
  description?: string;
  to?: string;
  buttonText?: string;
  visible?: boolean;
  component?: JSX.Element;
};

export const StatusBanner = ({ portfolio }: Props) => {
  const { t } = useTranslation('portfolio');
  const status = usePortfolioStatus(portfolio.id);
  const { verification } = useSession();
  const enrollBasePath = useEmployeeOnboardingBaseRoute();

  if (status.isLoading || status.isIdle) {
    return (
      <div tw="absolute top-1/2 left-1/2">
        <Spinner />
      </div>
    );
  }

  if (status.isError) {
    return <ContactSupportOverlay title={t('status.error')} onClose={status.refetch} error={status.error} />;
  }

  const { contract, payment, company } = status.data;

  const isContractSigned = contract === ContractStatus.SIGNED;
  const isCustomerVerified = verification === VerificationStatus.COMPLETE;
  const isCompanyVerified = company === CompanyStatus.VALID;
  const isFirstPaymentReceived = payment !== PaymentStatus.NOT_STARTED;
  const isPaymentPending = payment === PaymentStatus.SET_UP;
  const isManualReview = verification === VerificationStatus.PENDING;

  const activateLink = `/portfolio/${portfolio.id}/activate/`;
  const retailList: ListItem[] = [
    {
      title: t('status.needs_signing'),
      visible: !isContractSigned,
      to: `${activateLink}${ActivationLink.CONTRACT}`,
      buttonText: t('status.view_signing'),
    },
    {
      title: t('status.needs_verification'),
      description: isManualReview ? undefined : t('status.needs_verification_description'),
      visible: !isCustomerVerified,
      to: isManualReview ? undefined : `${activateLink}${ActivationLink.VERIFICATION}`,
      buttonText: isManualReview ? undefined : t('status.view_verification'),
    },
    {
      title: t('status.company.needs_verification'),
      description: t('status.company.needs_verification_description'),
      visible: !isCompanyVerified,
      to: `${activateLink}${ActivationLink.COMPANY_DETAILS}`,
      buttonText: t('status.company.view'),
    },
    {
      title: t('status.needs_payment'),
      description: t('status.needs_payment_description'),
      visible: !isFirstPaymentReceived,
      component: <PaymentInstructions portfolioId={portfolio.id} />,
    },
    {
      title: t('status.payment_pending_description'),
      visible: isPaymentPending,
    },
  ];

  const isEmployeePortfolio = portfolio.beneficiaryType === BeneficiaryType.EMPLOYEE;
  const employeeList: ListItem[] = [
    {
      title: t('status.needs_signing'),
      visible: !isContractSigned,
      to: `${enrollBasePath}${EmployeeOnboardingLink.CONTRACT}`,
      buttonText: t('status.view_signing'),
    },
    {
      title: t('status.needs_verification'),
      description: isManualReview ? undefined : t('status.needs_verification_description'),
      visible: !isCustomerVerified,
      to: isManualReview
        ? undefined
        : portfolio.status === PortfolioStatus.ACTIVE
          ? '/kyc/verification'
          : `${enrollBasePath}${EmployeeOnboardingLink.VERIFICATION}`,
      buttonText: isManualReview ? undefined : t('status.view_verification'),
    },
    {
      title: t('status.needs_payment_corporate'),
      visible: !isFirstPaymentReceived,
    },
    {
      title: t('status.payment_pending_description'),
      visible: isPaymentPending,
    },
  ];

  const list = isEmployeePortfolio ? employeeList : retailList;

  const filteredAndSliced = list.filter((v) => !('visible' in v && v.visible == false)).slice(0, 1);

  if (filteredAndSliced.length === 0) return null;

  return (
    <div tw="p-6 md:px-12 md:py-8 bg-gray-50 mt-6 md:mt-12">
      <ol tw="flex flex-row flex-wrap gap-6">
        {filteredAndSliced.map((item, index) => {
          return (
            <li key={index} tw="flex flex-col">
              <div tw="flex flex-row flex-wrap sm:flex-nowrap items-center gap-4">
                <InfoIcon tw="w-12 h-12 rounded-full bg-alps-blue-100 text-forest-green-900 flex-shrink-0" />
                <div tw="flex flex-col gap-2">
                  <h3 tw="text-lg font-semibold text-alps-blue-900">{item.title}</h3>
                  <p tw="text-base text-gray-500">{item.description}</p>
                  {item.to != null && (
                    <Link to={item.to} tw="max-w-max">
                      <Button variant={ButtonVariant.PRIMARY}>{item.buttonText}</Button>
                    </Link>
                  )}
                </div>
              </div>
              {isValidElement(item.component) && item.component}
            </li>
          );
        })}
      </ol>
    </div>
  );
};
