import { Button, ButtonSize, ButtonVariant, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { useGetLatestQuestionnaire, useSubmitQuestionnaire } from '~/modules/onboarding/queries';
import { useQuestions } from '~/modules/onboarding/questions';
import type { MifidQuestionnaire, QuestionMifid } from '~/modules/onboarding/types';
import { deserializeMifidExperience, serializeMifidExperience } from '~/modules/onboarding/utils';

type MifidField =
  | 'experience'
  | 'pastPerformance'
  | 'wouldWithdrawOnValueDrop'
  | 'savingsForThreeMonths'
  | 'moreDebtThanAssets'
  | 'acceptedWarningMessage';

export function RiskProfileView() {
  const { t } = useTranslation('account');
  const mifid = useGetLatestQuestionnaire({ type: 'mifid' });
  const submitAnswers = useSubmitQuestionnaire();
  const questionList = useQuestions();
  const [result, setResult] = useState<MifidQuestionnaire | undefined>();
  const [disabled, setDisabled] = useState(true);
  const toggle = () => setDisabled(!disabled);

  const questions = (questionList as QuestionMifid[]).filter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (q) => 'type' in q && q.type === 'mifid' && q.field !== 'acceptedWarningMessage' && q.field !== 'intro',
  );

  if (mifid.isIdle || mifid.isLoading) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  } else if (!result && mifid.data) {
    setResult(mifid.data as MifidQuestionnaire);
  }

  const deserializeValue = (field: MifidField) => {
    if (field === 'experience') {
      return deserializeMifidExperience(result as MifidQuestionnaire);
    } else if (result) {
      return result[field]?.toString();
    }
  };

  const QuestionField = (props: { question: QuestionMifid }) => {
    const question = props.question;
    const setValue = (value: string | string[] | boolean | null) => {
      if (question.field === 'experience') {
        if (Array.isArray(value)) {
          const serializedMifid = serializeMifidExperience(value) as MifidQuestionnaire;
          setResult({ ...result, ...serializedMifid });
        }
      } else if (result) {
        result[question.field] = value as never;
        setResult({ ...result });
      }
    };

    return question.render({
      value: deserializeValue(question.field),
      setValue,
      disabled,
    } as never);
  };

  const save = () => {
    result && submitAnswers.mutate({ type: 'mifid', id: result.id, body: result });
    toggle();
  };

  return (
    <div tw="m-6 md:m-12">
      <div tw="flex justify-between items-center h-10 mb-8 md:mb-12">
        <div tw="w-full text-alps-blue-700 text-lg md:text-xl font-bold md:text-left md:font-medium">
          {t('risk_profile.title')}
        </div>
        {disabled && (
          <div>
            <Button variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} type="submit" onClick={toggle}>
              {t('edit', { ns: 'general' })}
            </Button>
          </div>
        )}
      </div>
      <MifidQuestions>
        {result && questions.map((question, i) => <QuestionField question={question} key={i} />)}
      </MifidQuestions>
      {!disabled && (
        <div tw="flex justify-between mt-16">
          <div>
            <Button size={ButtonSize.LARGE} onClick={toggle}>
              {t('cancel', { ns: 'general' })}
            </Button>
          </div>
          <div>
            <Button size={ButtonSize.LARGE} variant={ButtonVariant.PRIMARY} onClick={save}>
              {t('confirm_changes', { ns: 'general' })}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const MifidQuestions = styled.div`
  .mifid-question {
    ${tw`text-gray-400 text-base mb-6 font-semibold mt-12`}
  }
  .mifid-question-subtitle {
    ${tw`text-gray-400 text-sm mb-6 font-normal -mt-5`}
  }
`;
