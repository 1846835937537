var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { twMerge } from 'tailwind-merge';
import React from 'react';
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["NEW_PRIMARY"] = "NEW_PRIMARY";
    ButtonVariant["PRIMARY"] = "PRIMARY";
    ButtonVariant["SECONDARY"] = "SECONDARY";
    ButtonVariant["DANGER"] = "DANGER";
    ButtonVariant["TRANSPARENT"] = "TRANSPARENT";
    ButtonVariant["NEW_SECONDARY"] = "NEW_SECONDARY";
    ButtonVariant["NEW_MEDIUM"] = "NEW_MEDIUM";
})(ButtonVariant || (ButtonVariant = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["SMALL"] = "SMALL";
    ButtonSize["LARGE"] = "LARGE";
})(ButtonSize || (ButtonSize = {}));
/**
 * Primary UI component for user interaction
 */
export var Button = React.forwardRef(function (props, ref) {
    var _a;
    var _b = props.as, Component = _b === void 0 ? 'button' : _b, variant = props.variant, size = props.size, className = props.className, rest = __rest(props, ["as", "variant", "size", "className"]);
    var classes = twMerge('py-1 px-4 shadow-inner w-[inherit] text-base font-normal text-center flex justify-center items-center rounded-lg select-none', 'subpixel-antialiased transition-colors disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-alps-blue-200 active:ring-0', variant === ButtonVariant.NEW_PRIMARY &&
        'bg-vivid-green-400 text-vivid-green-900 rounded-full disabled:text-vivid-green-900 disabled:text-opacity-40', variant === ButtonVariant.NEW_MEDIUM &&
        'bg-green-700 text-green-100 rounded-full disabled:text-vivid-green-900 disabled:text-opacity-40', variant === ButtonVariant.PRIMARY && 'bg-sky-blue-600 text-alps-blue-100 disabled:text-alps-blue-400', variant === ButtonVariant.SECONDARY && 'bg-gray-50 text-gray-900 disabled:text-gray-300', variant === ButtonVariant.NEW_SECONDARY &&
        'bg-neutral-50 rounded-full text-neutral-900 disabled:text-neutral-300 !shadow-none', variant === ButtonVariant.DANGER && 'bg-dawn-600 text-dawn-50 disabled:bg-dusk-500', variant === ButtonVariant.TRANSPARENT &&
        'shadow-none disabled:text-gray-300 focus:ring-none active:ring-none ring-transparent', size === ButtonSize.LARGE && 'px-6 text-lg', className);
    return (React.createElement(Component, __assign({ ref: ref, className: classes }, (props.as !== 'a' && { type: (_a = props.type) !== null && _a !== void 0 ? _a : 'button' }), rest)));
});
Button.displayName = 'Button';
