import { Radio, RadioGroup, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';

import { usePortfolio } from '~/modules/portfolio/hooks';
import { useGetDirectDebitConsents } from '~/modules/portfolio/queries';

interface Props {
  selectedValue?: string;
  onChange: (consentId: string) => void;
}

export const Consents = ({ selectedValue, onChange }: Props) => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const consents = useGetDirectDebitConsents(portfolio.id);

  return (
    <RadioGroup name="consentId" selectedValue={selectedValue} onChange={(event) => onChange(event.target.value)}>
      {consents.data?.availableConsents.map((consent) => (
        <Radio
          key={consent.id}
          value={consent.id}
          label={
            <span>
              {consent.nameOnAccount}
              <span tw="ml-1 text-gray-500">{consent.iban}</span>
            </span>
          }
        />
      ))}
      <Radio value="new" label={t('details.payment.top_up.iban.new')} />
    </RadioGroup>
  );
};
