import {
  AlertDialog,
  AlertNotice,
  ArrowIcon,
  Button,
  ButtonVariant,
  ContractStatus,
  Customer,
  Dialog,
  DialogProps,
  DisplayError,
  formatDate,
  InfoIcon,
  MembershipStatus,
  PortfolioStatus,
  Spinner,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import { captureException } from '@sentry/react';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { cloneElement, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { default as TableBase } from '.';
import { useGetCustomerCompanyMemberships, useRemoveMember } from '../../queries';
import { AvatarVariant, default as Avatar } from '../Member';
import { MembershipStatusTag } from '../Tag';

export interface IMember extends Customer {
  employerSideId: string;
  email: string;
  membershipId: string;
  lockDate: string | undefined;
  referenceNumber: string | undefined;
  contractStatus: ContractStatus | null;
  portfolioStatus: PortfolioStatus | null;
  membershipStatus: MembershipStatus | null;
  verificationStatus: string;
}

const paginationRange = 2;
const paginationSize = 20;
const MemberTable = ({ setEmployeesCount }: { setEmployeesCount: (count: number) => void }) => {
  const { t } = useTranslation('company');
  const [currentSelectedMember, setCurrentSelectedMember] = useState<IMember | undefined>();
  const { companyId, benefitId } = useParams();
  const navigate = useNavigate();
  const removeMember = useRemoveMember(benefitId ?? '');

  const columns = useMemo<ColumnDef<IMember>[]>(
    () => [
      {
        header: t('table.employee'),
        accessorFn: (row) => `${row.givenName} ${row.lastName}`,
        id: 'givenName',
        cell: (info) => <Avatar name={info.getValue() as string} />,
      },
      {
        header: t('table.email'),
        accessorFn: (row) => row.email,
        id: 'u.email',
        cell: (info) => <p tw="text-base text-gray-500">{info.getValue() as string}</p>,
      },
      {
        header: t('table.id'),
        accessorFn: (row) => row.employerSideId ?? ' ',
        id: 'employerSideId',
        cell: (info) => <p tw="text-base text-gray-500 font-mono">{info.getValue() as string}</p>,
      },
      {
        header: t('table.status'),
        accessorFn: (row) => {
          return row.membershipStatus;
        },
        id: 'status',
        cell: (info) => <MembershipStatusTag status={info.getValue() as MembershipStatus} />,
        enableSorting: false,
      },
    ],
    [t],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: paginationSize,
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const getCurrentSort = useCallback(() => {
    if (sorting.length <= 0) return ['createdAt,desc'];

    const sort = sorting.map((item) => {
      const order = item.desc ? 'desc' : 'asc';
      return `${item.id},${order}`;
    });

    return sort;
  }, [sorting]);

  const dataQuery = useGetCustomerCompanyMemberships({
    benefitId: benefitId ?? '',
    page: pageIndex,
    size: pageSize,
    sort: getCurrentSort(),
  });

  useEffect(() => {
    if (dataQuery.data) {
      if (dataQuery.data.totalElements) setEmployeesCount(dataQuery.data.totalElements);
    }
  }, [dataQuery.data, setEmployeesCount]);

  const rows = dataQuery.data?.content ?? [];
  const pageCount = dataQuery.data?.totalPages ?? 0;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleDeleteMember = async (member: IMember) => {
    await removeMember.mutateAsync(member.membershipId);
    // refetch the data
    dataQuery.refetch();
    // close the active dialog
    setCurrentSelectedMember(undefined);
  };

  const handleSetCurrentRowAsMember = (row: Row<IMember>) => {
    const selectedMemberId = row.original.id;
    // open the dialog with the selected member
    setCurrentSelectedMember(rows.find((member) => member.id === selectedMemberId));
  };

  const table = useReactTable({
    data: rows,
    columns,
    pageCount,
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  if (dataQuery.isFetching) {
    return (
      <div tw="flex mx-auto my-16 md:my-32">
        <Spinner />
      </div>
    );
  }

  if (dataQuery.isError) {
    return (
      <DisplayError
        title={t('general.errors.employees')}
        buttonText={t('general:retry')}
        onClose={dataQuery.refetch}
        error={dataQuery.error}
      />
    );
  }

  if (dataQuery.data && dataQuery.data.content.length === 0) {
    return (
      <AlertNotice
        icon={<InfoIcon tw="bg-gray-100 rounded-full" />}
        title={t('employees.not_found.title')}
        description={t('employees.not_found.description')}
      >
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => navigate(`/company/${companyId}/benefit/${benefitId}/memberships/upload`)}
          tw="max-w-max"
        >
          {t('employees.upload.title')}
        </Button>
      </AlertNotice>
    );
  }

  return (
    <div tw="flex flex-col w-full whitespace-nowrap">
      <TableBase table={table} onRowClick={handleSetCurrentRowAsMember} />
      <MemberDialog
        open={currentSelectedMember != null}
        member={currentSelectedMember}
        onOpenChange={(isOpen) => {
          // if the dialog is closed, we want to reset the current selected member
          if (!isOpen) setCurrentSelectedMember(undefined);
        }}
        handleDeleteMember={handleDeleteMember}
      />
      {pageCount > 1 && <TablePagination table={table} pageCount={pageCount} />}
    </div>
  );
};

type TablePaginationProps = {
  table: Table<IMember>;
  pageCount: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
};

const TablePagination = ({ table, pageCount }: TablePaginationProps) => {
  return (
    <div tw="py-4 flex flex-row items-center justify-between">
      <button
        type="button"
        className="rounded p-1 bg-gray-50"
        onClick={table.previousPage}
        disabled={!table.getCanPreviousPage()}
      >
        <ArrowIcon
          width={32}
          height={32}
          tw="rotate-180 text-sky-blue-700"
          css={[!table.getCanPreviousPage() && tw`text-gray-400 cursor-not-allowed`]}
        />
      </button>
      <div tw="flex flex-row gap-2 items-center text-gray-500">
        {Array.from({ length: pageCount ?? 0 }, (_, idx) => {
          const isLastValue = idx === pageCount - 1;
          const isFirstValue = idx === 0;
          const isInRange =
            idx >= table.getState().pagination.pageIndex - paginationRange &&
            idx <= table.getState().pagination.pageIndex + paginationRange;
          if (!isInRange && !isFirstValue && !isLastValue) return '.';
          return (
            <button
              key={idx}
              tw="p-2 rounded-md border-2 border-gray-50 cursor-pointer text-base text-gray-500 w-10"
              css={[idx === table.getState().pagination.pageIndex && tw`text-gray-900 bg-gray-50`]}
              onClick={() => table.setPageIndex(idx)}
              disabled={idx === table.getState().pagination.pageIndex}
            >
              {idx + 1}
            </button>
          );
        })}
      </div>
      <button
        type="button"
        className="rounded p-1 bg-gray-50"
        onClick={table.nextPage}
        disabled={!table.getCanNextPage()}
      >
        <ArrowIcon
          width={32}
          height={32}
          tw="text-sky-blue-700"
          css={[!table.getCanNextPage() && tw`text-gray-400 cursor-not-allowed`]}
        />
      </button>
    </div>
  );
};

interface MemberDialogProps extends Pick<DialogProps, 'open' | 'children'> {
  member: IMember | undefined;
  onOpenChange: (open: boolean) => void;
  handleDeleteMember: (member: IMember) => Promise<void>;
}

export const MemberDialog = ({ open, onOpenChange, handleDeleteMember, member }: MemberDialogProps) => {
  const { t } = useTranslation('company');

  const list = [
    {
      title: t('table.id'),
      value: member?.employerSideId,
    },
    {
      title: t('table.email'),
      value: member?.email,
    },
    {
      title: t('table.status'),
      value: member?.membershipStatus,
      component: <MembershipStatusTag status={member?.membershipStatus as MembershipStatus} />,
    },
    {
      title: t('activation.contract.lock_date', { ns: 'portfolio' }),
      value: member?.lockDate != null && formatDate(member?.lockDate),
    },
    {
      title: t('employees.portfolio_ids'),
      value: member?.referenceNumber || '',
    },
  ];

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      render={({ close, labelId, descriptionId }) => {
        if (!member) return null;
        return (
          <div tw="flex flex-col">
            <div id={labelId} tw="p-4 sm:p-8 md:px-14 bg-sky-blue-100 rounded-t-2xl">
              <Avatar variant={AvatarVariant.LARGE} name={`${member.givenName} ${member.lastName}`} />
            </div>
            <ol id={descriptionId} tw="flex flex-col gap-4 p-8 text-base text-gray-900 w-full md:min-w-[500px]">
              {list.map((item) => {
                if (!item.value) return null;

                return (
                  <li key={item.title} tw="flex flex-row flex-wrap justify-between items-center text-base gap-2">
                    <span tw="text-gray-500">{item.title}</span>
                    <span tw="text-gray-900 font-medium">
                      {item.component ? cloneElement(item.component) : item.value}
                    </span>
                  </li>
                );
              })}
            </ol>
            <div tw="flex flex-row flex-wrap gap-8 justify-between items-center p-8">
              <AlertDialog
                title={
                  <Trans
                    i18nKey="company:employees.remove.title"
                    values={{ name: `${member.givenName} ${member.lastName}` }}
                  />
                }
                description={t('employees.remove.description')}
                actions={(alertCloseFn) => (
                  <div tw="flex flex-row flex-wrap items-center gap-4">
                    <Button variant={ButtonVariant.SECONDARY} onClick={alertCloseFn} tw="w-max">
                      {t('cancel', { ns: 'general' })}
                    </Button>
                    <Button
                      variant={ButtonVariant.DANGER}
                      onClick={async () => {
                        try {
                          await handleDeleteMember(member);
                          alertCloseFn();
                          close();
                        } catch (err) {
                          captureException(err);
                        }
                      }}
                      tw="w-max"
                    >
                      {t('confirm', { ns: 'general' })}
                    </Button>
                  </div>
                )}
              >
                <Button variant={ButtonVariant.SECONDARY} tw="w-max" tabIndex={-1}>
                  {t('employees.remove.remove_employee')}
                </Button>
              </AlertDialog>
              <Button variant={ButtonVariant.SECONDARY} onClick={close} tw="w-max">
                {t('close', { ns: 'general' })}
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Fragment />
    </Dialog>
  );
};

export default MemberTable;
