import { Button, ButtonVariant, CheckIcon, Trans, useTranslation } from '@grunfin/ui-kit';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import 'twin.macro';

import { trackNewSignUp } from '~/utils/tracking-analytics';

import { Loader } from './Loader';
import { useSessionQuery, useVerifyEmail } from './queries';
import { useSession } from './SessionProvider';

export const VerifyEmailView = () => {
  const { t } = useTranslation('auth');
  const { email, emailVerified, pendingPortfolioId, portfolioCount, ...session } = useSession();
  const { mutateAsync: verifyEmail, isLoading } = useVerifyEmail();
  const [isEnabled, setIsEnabled] = useState(false);
  useSessionQuery({ enabled: isEnabled });

  useEffect(() => {
    if (!emailVerified) {
      verifyEmail().then((result) => result.emailVerified && trackNewSignUp());
    }
  }, [emailVerified, verifyEmail]);

  if (emailVerified) {
    if (portfolioCount === 1 && session.verification !== 'COMPLETE' && pendingPortfolioId) {
      return <Navigate to={`/portfolio/${pendingPortfolioId}/activate`} replace />;
    }
    return <Navigate to={portfolioCount === 0 ? '/onboarding' : '/'} replace />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div tw="relative overflow-hidden">
      <div tw="flex flex-col text-center items-center justify-center">
        <CheckIcon tw="rounded-full text-alps-blue-200 bg-alps-blue-700 w-20 h-20 mb-10 mx-auto" />
        <h1 tw="text-xl text-alps-blue-700 font-semibold mb-6">
          <Trans i18nKey="email_verification.check_email" t={t} />
        </h1>
        <small tw="text-sm text-gray-500">{t('email_verification.email_sent', { email })}</small>
        <div tw="mt-6">
          <Button as="a" href="/logout" variant={ButtonVariant.SECONDARY}>
            {t('sign_out', { ns: 'general' })}
          </Button>
        </div>
      </div>
      <input
        tw="absolute"
        style={{ left: '99999rem' }}
        data-test-id="refetchSession"
        onClick={() => setIsEnabled(true)}
        tabIndex={-1}
      />
    </div>
  );
};
