import { ClipboardIcon, EmailIcon, FacebookIcon, Input, toast, TwitterIcon, useTranslation } from '@grunfin/ui-kit';
import tw from 'twin.macro';
import { useSession } from '~/modules/auth/SessionProvider';
import { trackReferralShare } from '~/utils/tracking-analytics';

interface Props {
  iconsCenter?: boolean;
}

export const useReferralLinks = () => {
  const { t } = useTranslation('account');
  const session = useSession();
  const referralLink = `${window.location.origin}/referral/${session.referralCode}`;

  const links = [
    {
      label: t('referrals.method.email'),
      icon: <EmailIcon width={32} height={32} tw="rounded text-alps-blue-700" />,
      href: `mailto:?&subject=Found%20this%20new%20sustainable%20investing%20startup%20called%20Gr%C3%BCnfin
    &body=%0AHey%20-%20there%E2%80%99s%20a%20new%20sustainable%20investing%20startup%20called%20Gr%C3%BCnfin.%20It%20allows%20you%20to%20invest%20and%20earn%20market%20returns%2C%20but%20the%20investments%20will%20also%20make%20a%20positive%20impact%20on%20the%20world.%20For%20example%2C%20you%E2%80%99ll%20invest%20in%20companies%20that%20are%20actively%20fighting%20against%20climate%20change%20or%20making%20efforts%20to%20have%20more%20women%20in%20their%20leadership.%0A%0AHere%E2%80%99s%20the%20link%20if%20you%E2%80%99re%20interested%3A%20%0A${referralLink}%3Futm_source%3Dsocial-share%26utm_medium%3Demail%26utm_content%3Dsocial-share-prod%0A`,
      onClick: () => trackReferralShare('Initiated sharing link through email'),
    },
    {
      label: t('referrals.method.twitter'),
      icon: <TwitterIcon width={24} height={24} tw="rounded  text-alps-blue-700" />,
      href: `https://twitter.com/intent/tweet?url=${referralLink}?utm_source=twitter&utm_medium=social&utm_campaign=social-share-prod&text=Just%20joined%20Gr%C3%BCnfin,%20a%20sustainable%20investing%20startup%20that%20allows%20you%20to%20earn%20market%20returns%20while%20making%20a%20positive%20impact%20on%20the%20world.%20Here%E2%80%99s%20the%20link:`,
      onClick: () => trackReferralShare('Initiated sharing link through Twitter'),
    },
    {
      label: t('referrals.method.facebook'),
      icon: <FacebookIcon width={24} height={24} tw="rounded text-alps-blue-700" />,
      href: `https://www.facebook.com/sharer/sharer.php?u=${referralLink}?utm_source=facebook&utm_medium=social&utm_campaign=social-share-prod`,
      onClick: () => trackReferralShare('Initiated sharing link through Facebook'),
    },
  ];

  return links;
};

export function ReferralLink({ iconsCenter = false }: Props) {
  const { t } = useTranslation('account');
  const session = useSession();
  const referralLink = `${window.location.origin}/referral/${session.referralCode}`;
  const links = useReferralLinks();

  return (
    <div tw="flex flex-col gap-6">
      <div tw="flex gap-2 items-center flex-row mt-6">
        <div tw="w-full max-w-md">
          <Input readOnly={true} value={referralLink} />
        </div>
        <ClipboardIcon
          tw="inline-block bg-alps-blue-700 rounded cursor-pointer text-alps-blue-100 hover:text-alps-blue-300"
          onClick={() => {
            navigator.clipboard.writeText(referralLink);
            toast.success(t('copied', { ns: 'general' }));
            trackReferralShare('copied link');
          }}
        />
      </div>
      <div tw="flex flex-row flex-wrap gap-4" css={[iconsCenter && tw`justify-center md:justify-start`]}>
        {links.map((link, index) => {
          return (
            <a
              key={index}
              tw="flex flex-row gap-3 items-center text-sm"
              target="_blank"
              rel="noreferrer"
              href={link.href}
              onClick={link.onClick}
            >
              <span tw="bg-alps-blue-100 rounded w-8 h-8 flex items-center justify-center">{link.icon}</span>
              <span tw="hidden sm:block">{link.label}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
}
