import { useTranslation } from '@grunfin/ui-kit';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';
import 'twin.macro';

import { useNavigationTitle } from '~/modules/application/Navigation';
import { Loader } from '~/modules/auth/Loader';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { useDocumentTitle } from '~/utils';

import { useGetPortfolio } from '../queries';
import { ContributionSettings } from './ContributionSettings';
import { MobileNavigation, Navigation } from './Navigation';
import { Payments } from './Payments/Payments';
import { Reports } from './Reports';
import { Settings } from './Settings/Settings';
import { Structure } from './Structure';

export const DetailsView = () => {
  const { id } = useParams<'id'>();
  if (!id) throw new Error('Missing portfolio id');
  const { t } = useTranslation('portfolio');
  const portfolio = useGetPortfolio(id);

  const isLoading = !portfolio.data || portfolio.isIdle || portfolio.isLoading;
  const name = (isLoading ? t('loading', { ns: 'general' }) : portfolio.data.name ?? t('portfolio')) as string;

  useDocumentTitle(name);
  useNavigationTitle([[t('home', { ns: 'general' }), '/'], name]);

  if (portfolio.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={portfolio.refetch} error={portfolio.error} />;
  }

  if (!portfolio.data || portfolio.isIdle || portfolio.isLoading) {
    return <Loader />;
  }

  return (
    <div tw="flex flex-col">
      <MobileNavigation />
      <div tw="flex flex-col rounded-2xl shadow-md mx-2 lg:mx-0">
        <Navigation />
        <Outlet />
        <Routes>
          <Route path="structure/*" element={<Structure />} />
          <Route path="payments/*" element={<Payments />} />
          <Route path="reports/*" element={<Reports />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="contribution/*" element={<ContributionSettings />} />
          <Route path="*" element={<Navigate to="structure" replace />} />
        </Routes>
      </div>
    </div>
  );
};
