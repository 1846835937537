import { Route, Routes } from 'react-router-dom';
import { NotFoundView } from '~/modules/application/NotFoundView';
import { IndexView } from './IndexView';
import { ResultView } from './ResultView';

export const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<IndexView />} />
      <Route path="result" element={<ResultView />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};
