var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgTwitterIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 20, viewBox: "0 0 24 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M23.206 2.93695C22.371 3.30695 21.474 3.55695 20.531 3.66995C21.493 3.09395 22.231 2.17995 22.579 1.09195C21.679 1.62595 20.682 2.01395 19.621 2.22195C18.771 1.31795 17.561 0.751953 16.221 0.751953C13.649 0.751953 11.563 2.83795 11.563 5.41195C11.563 5.77595 11.605 6.12995 11.683 6.47195C7.81 6.27695 4.379 4.42195 2.081 1.60395C1.681 2.29395 1.451 3.09395 1.451 3.94595C1.451 5.56195 2.274 6.98895 3.523 7.82395C2.759 7.79895 2.041 7.58995 1.413 7.24095V7.30095C1.413 9.55795 3.018 11.441 5.15 11.869C4.758 11.975 4.347 12.031 3.923 12.031C3.623 12.031 3.33 12.003 3.046 11.949C3.639 13.799 5.359 15.147 7.398 15.183C5.803 16.433 3.794 17.178 1.612 17.178C1.236 17.178 0.865 17.156 0.5 17.113C2.562 18.436 5.01 19.206 7.64 19.206C16.21 19.206 20.895 12.108 20.895 5.95195C20.895 5.75195 20.89 5.54995 20.881 5.34995C21.791 4.69195 22.581 3.87295 23.204 2.93995L23.206 2.93695Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgTwitterIcon);
var Memo = memo(ForwardRef);
export default Memo;
