var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { autoUpdate, flip, FloatingFocusManager, FloatingPortal, offset, shift, size, arrow, useClick, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useMergeRefs, useTransitionStyles, FloatingOverlay, FloatingArrow, safePolygon, useFloatingNodeId, FloatingNode, useFloatingParentNodeId, FloatingTree, } from '@floating-ui/react';
var getPopoverMiddlewares = function (props, arrowRef) {
    var middleware = [flip(), shift()];
    // Add middleware from props
    if (props.options != null && Array.isArray(props.options.middleware) && props.options.middleware.length > 0)
        middleware.push.apply(middleware, props.options.middleware);
    // Scale the popover to the width of the reference element
    if (props.scaleWidth)
        middleware.push(size({
            apply: function (_a) {
                var rects = _a.rects, elements = _a.elements;
                Object.assign(elements.floating.style, {
                    width: "".concat(rects.reference.width, "px"),
                });
            },
        }));
    if (props.showArrow)
        middleware.push(arrow({
            element: arrowRef,
        }));
    return middleware;
};
var Popover = function (props) {
    var parentId = useFloatingParentNodeId();
    // This is a root, so we wrap it with the tree
    if (parentId === null) {
        return (React.createElement(FloatingTree, null,
            React.createElement(PopoverComponent, __assign({}, props))));
    }
    return React.createElement(PopoverComponent, __assign({}, props));
};
var PopoverComponent = function (props) {
    var _a;
    var arrowRef = useRef(null);
    var children = props.children, content = props.content, options = props.options, _b = props.placement, placement = _b === void 0 ? 'top' : _b, _c = props.showOnClick, showOnClick = _c === void 0 ? true : _c, _d = props.toggleOnClick, toggleOnClick = _d === void 0 ? true : _d, _e = props.showOnFocus, showOnFocus = _e === void 0 ? true : _e, _f = props.showOnHover, showOnHover = _f === void 0 ? true : _f, onOpen = props.onOpen, onClose = props.onClose;
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    var nodeId = useFloatingNodeId();
    var _h = useFloating(__assign(__assign({}, options), { nodeId: nodeId, placement: placement, open: open, onOpenChange: setOpen, middleware: getPopoverMiddlewares(props, arrowRef), whileElementsMounted: autoUpdate })), x = _h.x, y = _h.y, refs = _h.refs, strategy = _h.strategy, context = _h.context;
    var _j = useTransitionStyles(context, {
        initial: function (_a) {
            var side = _a.side;
            return ({
                opacity: 0,
                transform: {
                    top: 'translateY(10px)',
                    bottom: 'translateY(-10px)',
                    left: 'translateX(10px)',
                    right: 'translateX(-10px)',
                }[side],
            });
        },
        common: function (_a) {
            var side = _a.side;
            return ({
                transformOrigin: {
                    top: 'bottom',
                    bottom: 'top',
                    left: 'right',
                    right: 'left',
                }[side],
            });
        },
    }), isMounted = _j.isMounted, styles = _j.styles;
    // Preserve the consumer's ref
    var ref = useMergeRefs([refs.setReference, children.ref]);
    var _k = useInteractions([
        useClick(context, { enabled: showOnClick, toggle: toggleOnClick }),
        useHover(context, { enabled: showOnHover, handleClose: safePolygon() }),
        useFocus(context, { enabled: showOnFocus }),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]), getReferenceProps = _k.getReferenceProps, getFloatingProps = _k.getFloatingProps;
    // Notify parent of open state change
    useEffect(function () {
        if (open) {
            if (onOpen)
                onOpen();
        }
        else if (onClose)
            onClose();
    }, [onOpen, onClose, open]);
    return (React.createElement(React.Fragment, null,
        cloneElement(children, getReferenceProps(__assign(__assign({ ref: ref }, children.props), { style: __assign(__assign({}, (_a = children.props) === null || _a === void 0 ? void 0 : _a.style), { outline: 'none' }) }))),
        isMounted && (React.createElement(FloatingNode, { id: nodeId },
            React.createElement(FloatingPortal, null,
                React.createElement(FloatingOverlay, { style: __assign(__assign({}, styles), { 
                        // Allow clicks to pass through the overlay to the content behind it
                        // This eliminates the useDismiss "enabled" feature
                        pointerEvents: 'none' }) },
                    React.createElement(FloatingFocusManager, __assign({ context: context, modal: false, order: ['floating', 'content'] }, props.floatingFocusOptions),
                        React.createElement("div", __assign({ ref: refs.setFloating, style: {
                                position: strategy,
                                top: y !== null && y !== void 0 ? y : 0,
                                left: x !== null && x !== void 0 ? x : 0,
                                zIndex: 10,
                                outline: 'none',
                                pointerEvents: 'all',
                            } }, getFloatingProps()),
                            content,
                            props.showArrow && React.createElement(FloatingArrow, __assign({}, props.arrowOptions, { ref: arrowRef, context: context }))))))))));
};
export { offset };
export default Popover;
