import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Select } from './Select';
import { formatDate } from '../utils';
export var DateDropdown = React.forwardRef(function (_a, ref) {
    var value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, _b = _a.spanBack, spanBack = _b === void 0 ? 80 : _b, _c = _a.spanFuture, spanFuture = _c === void 0 ? 0 : _c, error = _a.error, birthday = _a.birthday, disabled = _a.disabled;
    var startYear = new Date().getFullYear() - spanBack;
    var date = useMemo(function () { return (value ? dayjs(value, 'YYYY-MM-DD') : dayjs()); }, [value]);
    var _d = useState([
        value ? date.year() : '',
        value ? date.month() : '',
        value ? date.date() : '',
    ]), values = _d[0], setValues = _d[1];
    useEffect(function () {
        setValues([value ? date.year() : '', value ? date.month() : '', value ? date.date() : '']);
    }, [value]);
    var currentYear = values[0], currentMonth = values[1], currentDay = values[2];
    var padZero = function (n) { return n.toString().padStart(2, '0'); };
    var updatePos = function (idx) { return function (e) {
        var parts = [currentYear, currentMonth, currentDay];
        var newValue = parseFloat(e.target.value);
        parts[idx] = Number.isNaN(newValue) ? '' : newValue;
        if (parts.join('') === '') {
            setValues(parts);
            return onChange('');
        }
        if (parts[0] === '' || parts[1] === '' || parts[2] === '')
            return setValues(parts);
        var maxDays = dayjs(new Date(parts[0], parts[1], 1)).daysInMonth();
        if (parts[2] > maxDays)
            parts[2] = maxDays;
        setValues(parts);
        onChange("".concat(parts[0], "-").concat(padZero(parts[1] + 1), "-").concat(padZero(parts[2])));
    }; };
    var years = Array.from({ length: spanBack + spanFuture }, function (_, i) { return startYear + i + 1; });
    var months = Array.from({ length: 12 }, function (_, i) { return [i, formatDate(new Date(2020, i, 1), 'MMM')]; });
    var days = Array.from({
        length: value
            ? dayjs(new Date(currentYear, currentMonth, currentDay)).daysInMonth()
            : 31,
    }, function (_, i) { return [i + 1, i + 1]; });
    return (React.createElement("div", { className: "w-full flex col-span-4" },
        React.createElement("input", { type: "text", className: "sr-only", tabIndex: -1, ref: ref, disabled: disabled }),
        React.createElement(Select, { placeholder: "", value: currentDay, onChange: updatePos(2), onBlur: onBlur, error: error, disabled: disabled, autoComplete: birthday ? 'bday-day' : 'off', name: "date-day" }, days.map(function (_a) {
            var value = _a[0], label = _a[1];
            return (React.createElement("option", { key: value, value: value }, label));
        })),
        React.createElement("div", { className: "ml-3 mt-3" }),
        React.createElement(Select, { placeholder: "", value: currentMonth, onChange: updatePos(1), onBlur: onBlur, error: error, disabled: disabled, autoComplete: birthday ? 'bday-month' : 'off', name: "date-month" }, months.map(function (_a) {
            var value = _a[0], label = _a[1];
            return (React.createElement("option", { key: value, value: value }, label));
        })),
        React.createElement("div", { className: "mr-3 mb-3" }),
        React.createElement(Select, { placeholder: "", value: currentYear, onChange: updatePos(0), onBlur: onBlur, error: error, disabled: disabled, autoComplete: birthday ? 'bday-year' : 'off', name: "date-year" }, years.map(function (y) { return (React.createElement("option", { key: y, value: y }, y)); }))));
});
DateDropdown.displayName = 'DateDropdown';
