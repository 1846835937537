import {
  AlertDialog,
  Button,
  ButtonVariant,
  CheckIcon,
  DocumentIcon,
  FingerprintIcon,
  HeartIcon,
  PadlockIcon,
  RiskProfileIcon,
  Trans,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OnboardingWizard, {
  OnboardingContainer,
  OnboardingWizardStepProps,
} from '~/modules/application/OnboardingWizard';
import bg from '~/assets/output/employee-onboarding-background.webp';
import AuthenticationStep from '~/modules/application/OnboardingWizard/steps/AuthenticationStep';
import ContractStep from '~/modules/application/OnboardingWizard/steps/ContractStep';
import InvestmentProfileStep from '~/modules/application/OnboardingWizard/steps/InvestmentProfileStep';
import PersonalDetailsStep from '~/modules/application/OnboardingWizard/steps/PersonalDetailsStep';
import VerificationStep from '~/modules/application/OnboardingWizard/steps/VerificationStep';
import { useSession } from '~/modules/auth/SessionProvider';
import EmployeeOnboardingQuestionnaire from '~/modules/benefits/enroll/EmployeeOnboardingQuestionnaire';
import { trackStartEmployeeEnrollment } from '~/utils/tracking-analytics';
import { EnrollmentResponse, useGetEnrollment, useSubmitEnrollment } from '../queries';
import CompletedScreen from '~/modules/application/OnboardingWizard/steps/CompletedScreen';
import { WelcomeScreen } from '~/modules/application/OnboardingWizard/steps/WelcomeScreen';

export enum EmployeeOnboardingLink {
  WELCOME = 'welcome',
  CREDENTIALS = 'credentials',
  PERSONAL_DETAILS = 'personal-details',
  INVESTMENT_PROFILE = 'investment-profile',
  VERIFICATION = 'verification',
  CONTRACT = 'contract',
  COMPLETED = 'completed',
}

export interface EmployeeOnboardingOutletContext extends EnrollmentResponse, OnboardingWizardStepProps {
  onContinue: (step?: OnboardingWizardStepProps) => void;
  setCreatedPortfolioId: (id: string) => void;
}

const iconStyle = { borderRadius: 999, color: '#031C2A', backgroundColor: '#FFF' };
const inactiveIconStyle = { backgroundColor: '#FFFFFF24', color: '#FFF' };

export const useEmployeeOnboardingBaseRoute = () => {
  const session = useSession();
  const params = useParams();

  const { membershipId, slug } = params;
  const { benefitMemberships } = session;
  const membership = benefitMemberships?.[0] ?? {};
  const finalMembershipId = membershipId ?? membership.membershipId;
  const finalSlug = slug ?? membership.slug;

  return `/enroll/${finalSlug ? finalSlug : ''}/id/${finalMembershipId ? finalMembershipId : ''}/`;
};

export const useEmployeeOnboardingViewComponents = () => ({
  [EmployeeOnboardingLink.WELCOME]: <WelcomeScreen />,
  [EmployeeOnboardingLink.CREDENTIALS]: <AuthenticationStep />,
  [EmployeeOnboardingLink.PERSONAL_DETAILS]: <PersonalDetailsStep />,
  [EmployeeOnboardingLink.INVESTMENT_PROFILE]: (
    <InvestmentProfileStep questionnaireType="employee-portfolio">
      <EmployeeOnboardingQuestionnaire answers={undefined as any} />
    </InvestmentProfileStep>
  ),
  [EmployeeOnboardingLink.VERIFICATION]: <VerificationStep />,
  [EmployeeOnboardingLink.CONTRACT]: <ContractStep />,
  [EmployeeOnboardingLink.COMPLETED]: <CompletedScreen />,
});

const EmployeeOnboardingView = () => {
  const { t } = useTranslation('onboarding');
  const session = useSession();
  const navigate = useNavigate();
  const params = useParams();
  const { membershipId, ['*']: subpath } = params;
  const baseRoute = useEmployeeOnboardingBaseRoute();
  const submitEnrollment = useSubmitEnrollment();
  const [hasSubmittedEnrollment, setHasSubmittedEnrollment] = useState(false);
  const [hoistedCreatedPortfolioId, setHoistedCreatedPortfolioId] = useState<string | undefined>(undefined);

  const membership = session?.benefitMemberships?.[0] ?? {};
  const finalMembershipId = membershipId ?? membership.membershipId;
  const enroll = useGetEnrollment(finalMembershipId, {
    enabled: !!finalMembershipId && !!finalMembershipId !== undefined,
  });

  const authMethods = enroll.data?.authMethods ?? [];
  const isAuthMethodsAvailable = Array.isArray(authMethods) && authMethods?.length > 0;
  const isCredentialsDisabled = session.authenticated && session.emailVerified;

  const steps: OnboardingWizardStepProps[] = [
    {
      title: t('steps.welcome_to_grunfin.title'),
      label: t('steps.welcome_to_grunfin.navigation_title'),
      icon: <HeartIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <HeartIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.WELCOME,
    },
    {
      title: authMethods.length > 0 ? t('steps.credentials.log_in') : t('steps.credentials.title'),
      label: isAuthMethodsAvailable
        ? t('steps.credentials.log_in_navigation_title')
        : t('steps.credentials.navigation_title'),
      icon: <PadlockIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <PadlockIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.CREDENTIALS,
      disabled: isCredentialsDisabled,
    },
    {
      title: t('steps.personal_details.title'),
      label: t('steps.personal_details.navigation_title'),
      icon: <UserIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <UserIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.PERSONAL_DETAILS,
    },
    {
      title: t('steps.investment_profile.title'),
      label: t('steps.investment_profile.navigation_title'),
      icon: <RiskProfileIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <RiskProfileIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.INVESTMENT_PROFILE,
    },
    {
      title: t('steps.verification.title'),
      label: t('steps.verification.navigation_title'),
      icon: <FingerprintIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <FingerprintIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.VERIFICATION,
      // TODO: We can disable verification if user is already verified
    },
    {
      title: t('steps.contract.title'),
      label: t('steps.contract.navigation_title'),
      icon: <DocumentIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <DocumentIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.CONTRACT,
    },
    {
      title: t('steps.completed.title'),
      label: t('steps.completed.navigation_title'),
      icon: <CheckIcon width={32} height={32} style={{ ...iconStyle, ...inactiveIconStyle }} />,
      activeIcon: <CheckIcon width={32} height={32} style={iconStyle} />,
      link: EmployeeOnboardingLink.COMPLETED,
    },
  ];

  const validRoutes = steps.map((step) => step.link);

  // Send the membership id to enrollment
  const { mutateAsync: submitEnrollmentAsync } = submitEnrollment;
  const { isSuccess: isEnrollSuccess } = enroll;
  useEffect(() => {
    if (isEnrollSuccess && finalMembershipId != null && !hasSubmittedEnrollment) {
      submitEnrollmentAsync(finalMembershipId);
      trackStartEmployeeEnrollment(finalMembershipId);
      setHasSubmittedEnrollment(true);
    }
  }, [isEnrollSuccess, submitEnrollmentAsync, finalMembershipId, hasSubmittedEnrollment]);

  // If the membership id is not present, redirect the user home
  useEffect(() => {
    if (finalMembershipId == null) navigate('/', { replace: true });
  }, [finalMembershipId, navigate]);

  if (enroll.isError) {
    const isGone = (enroll.error as any)?.response?.status === 410;
    if (isGone) {
      return (
        <OnboardingContainer>
          <AlertDialog
            open
            isDismissable={false}
            title={t('onboarding:employee.welcome.invite_expired')}
            actions={() => (
              <Button variant={ButtonVariant.PRIMARY} onClick={() => navigate('/')}>
                {t('general:home')}
              </Button>
            )}
            description={
              <Trans
                i18nKey="support:contact_support"
                t={t}
                components={[<a key="0" href="/support" tw="text-alps-blue-400 underline" tabIndex={-1} />]}
              />
            }
          >
            <Fragment />
          </AlertDialog>
        </OnboardingContainer>
      );
    } else navigate('/');
  }

  return (
    <OnboardingWizard
      subpath={subpath as string}
      baseRoute={baseRoute}
      validRoutes={validRoutes}
      steps={steps}
      outletContext={{
        ...enroll.data,
        // if we have hoisted the portfolio id, pass it down to the step
        portfolioId: hoistedCreatedPortfolioId ?? enroll.data?.portfolioId,
        // handle hoisting the portfolio id up the tree
        setCreatedPortfolioId: (id: string) => setHoistedCreatedPortfolioId(id),
      }}
      background={bg}
    />
  );
};

export default EmployeeOnboardingView;
