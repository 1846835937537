import { BankButtons, ChevronIcon, Field, Input, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';
import { Instructions } from '~/modules/portfolio/components/Instructions';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useSession } from '~/modules/auth/SessionProvider';
import { usePortfolio } from '~/modules/portfolio/hooks';

export const ManualPayment = () => {
  const { t, i18n } = useTranslation('portfolio');
  const [amount, setAmount] = useState<number>(0);
  const session = useSession();
  const portfolio = usePortfolio();
  const [isOpen, setIsOpen] = useState(i18n.language === 'de');
  return (
    <>
      <h4 tw="text-lg mb-12">{t('details.payment.top_up.manual.description')}</h4>

      <Field label={t('details.payment.top_up.manual.sum')} htmlFor="amount" className="flex flex-col items-stretch">
        <div tw="sm:w-1/3">
          <Input
            id="amount"
            type="number"
            value={Number(amount).toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(+e.target.value)}
            suffix="EUR"
          />
        </div>
      </Field>
      {i18n.language !== 'de' && (
        <BankButtons
          referenceNumber={portfolio.referenceNumber}
          paymentCode={'Portfolio: ' + portfolio.referenceNumber + ', id: ' + uuidv4()}
          email={session?.email || ''}
          amount={amount}
          language={i18n.language}
          redirectUrl={`${window.location.href}?success=true`}
          cancelUrl={`${window.location.href}`}
          disabled={amount < 1}
        />
      )}

      <div tw="flex items-center my-4 cursor-pointer select-none" onClick={() => setIsOpen(!isOpen)}>
        <ChevronIcon key="arrow" width={16} height={16} tw="rounded-full min-w-[16px] -rotate-90 bg-gray-50" />
        <p tw="ml-4" data-test-id="not_listed">
          {t('activation.payment.initial_payment.not_listed')}
        </p>
      </div>
      {isOpen && <Instructions amount={false} compact horizontal />}
    </>
  );
};
