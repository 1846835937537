import { flexRender, Row, Table as TableType } from '@tanstack/react-table';
import tw from 'twin.macro';

type TableRow = any;
type TableProps<T> = {
  table: TableType<T>;
  onRowClick?: (row: T) => void;
};

const Table = ({ table, onRowClick }: TableProps<TableRow>) => {
  return (
    <div tw="w-full overflow-auto [&::-webkit-scrollbar]:hidden">
      <table tw="w-full border-collapse text-left" style={{ borderSpacing: 0 }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (header.isPlaceholder) return null;
                if (!header.column.columnDef.header) return null;
                return (
                  <th key={header.id} colSpan={header.colSpan} tw="p-2">
                    <span
                      tw="flex items-center gap-2 text-base text-gray-500 text-left font-normal whitespace-pre-line"
                      css={[
                        header.column.getCanSort() && tw`cursor-pointer select-none`,
                        header.column.getIsSorted() && tw`text-sky-blue-500`,
                      ]}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: (
                          <span tw="w-4 h-4 flex items-center justify-center">
                            <Asc />
                          </span>
                        ),
                        desc: (
                          <span tw="w-4 h-4 flex items-center justify-center">
                            <Desc />
                          </span>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: Row<any>) => {
            const isActionable = typeof onRowClick !== 'undefined';
            return (
              <tr
                key={row.id}
                {...(isActionable && { onClick: () => onRowClick(row) })}
                css={[isActionable && tw`cursor-pointer hover:bg-sky-blue-50`]}
              >
                {row.getVisibleCells().map((cell) => {
                  if (!cell.getValue()) return null;
                  return (
                    <td key={cell.id} tw="p-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} tw="p-2">
                    {flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

const Desc = () => (
  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.6 0.8C0.352786 0.470382 0.587977 0 1 0H5C5.41202 0 5.64721 0.470382 5.4 0.8L3.4 3.46667C3.2 3.73333 2.8 3.73333 2.6 3.46667L0.6 0.8Z"
      fill="#053854"
    />
  </svg>
);

const Asc = () => (
  <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4 3.2C5.64721 3.52962 5.41202 4 5 4L1 4C0.587977 4 0.352786 3.52962 0.6 3.2L2.6 0.533333C2.8 0.266666 3.2 0.266666 3.4 0.533333L5.4 3.2Z"
      fill="#053854"
    />
  </svg>
);

export default Table;
