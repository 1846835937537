import {
  Button,
  ButtonVariant,
  CheckIcon,
  Customer,
  EuroIcon,
  Spinner,
  Tooltip,
  UserIcon,
  useTranslation,
} from '@grunfin/ui-kit';
import { captureException, captureMessage } from '@sentry/react';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ParseError } from 'papaparse';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { createAndDownloadCSVFile, formatCompanyPayment, useDocumentTitle } from '~/utils';
import { useGetMembershipCompany, useNavigationTitle } from '../application/Navigation';
import CSVImporter, { DataPoint } from './components/CSV/CSVImporter';
import CSVUploadErrors from './components/CSV/CSVUploadErrors';
import Member from './components/Member';
import JournalPaymentsUploadSuccess from './components/JournalPaymentsUploadSuccess';
import StepHeader from './components/StepHeader';
import { default as TableBase } from './components/Table';
import {
  CompanyPaymentsResponse,
  getCustomerCompanyMembersByEmployerSideIds,
  useSubmitCompanyPayments,
} from './queries';
import { BenefitScheme, TransactionPayment } from './types';

export function CompanyJournalPaymentsUploadView() {
  const { t } = useTranslation('company');
  const { companyId, benefitId } = useParams();
  useNavigationTitle([
    [t('navigation_title'), `/company/${companyId}/benefit/${benefitId}`],
    [t('payments.navigation_title'), `/company/${companyId}/benefit/${benefitId}/payments`],
    t('payments.upload.navigation_title'),
  ]);
  useDocumentTitle(t('payments.upload.document_title'));

  if (!companyId || !benefitId) return null;

  return (
    <div tw="flex flex-col gap-8">
      <PaymentsForm companyBenefitId={benefitId} />
    </div>
  );
}

interface PaymentDataPoint extends Omit<DataPoint<Customer>, 'row'> {
  row: TransactionPayment;
}

type CSVPayment = Omit<TransactionPayment, 'porfolioId'>;

const PaymentsForm = ({ companyBenefitId }: { companyBenefitId: string }) => {
  const { t } = useTranslation('company');
  const [currentStep, setCurrentStep] = useState(1);
  const [rows, setRows] = useState<TransactionPayment[]>([]);
  const [invalidRows, setInvalidRows] = useState<PaymentDataPoint[]>([]);
  const [errors, setErrors] = useState<ParseError[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [payments, setPayments] = useState<CompanyPaymentsResponse>();
  const submitPayments = useSubmitCompanyPayments(companyBenefitId);
  const selectedProgram = useGetMembershipCompany();

  const fetchEmployees = async (csvRows: TransactionPayment[]) => {
    const employerSideIds = csvRows.map((row) => row.employerSideId);
    try {
      return await getCustomerCompanyMembersByEmployerSideIds({
        companyBenefitId,
        employerSideIds,
        page: 0,
        size: 1000,
        sort: [],
      });
    } catch (err) {
      captureException(err);
    }
  };

  const handleUpload = async (data_points: PaymentDataPoint[], errors: ParseError[]) => {
    // reset the state
    setRows([]);
    setInvalidRows([]);
    setErrors([]);

    // notify the user if there are no data points
    if (data_points.length <= 0) {
      setInvalidRows([
        {
          row: {} as TransactionPayment,
          valid: false,
          missingHeaders: [t('general.errors.parse_failed_empty')],
        },
      ]);
      return;
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // check if all rows are valid
    const allValid = data_points.length > 0 && data_points.every((dataPoint) => dataPoint.valid);

    // if all rows are not valid, set the invalid rows
    if (!allValid) {
      const invalidRows = data_points.filter((point) => !point.valid);
      if (invalidRows.length > 0) setInvalidRows(invalidRows);

      // do not continue
      return;
    }

    const payments = data_points.map((dataPoint) => dataPoint.row);
    // if there are no payments found, there are probably no rows entered in the csv
    if (payments.length <= 0) return;

    // check if there are any duplicate entries
    const duplicatePayments = payments.filter(
      (payment, index, self) =>
        index !==
        self.findIndex(
          (t) =>
            t.employerSideId === payment.employerSideId &&
            ((t.portfolioRefNumber != null && t.portfolioRefNumber === payment.portfolioRefNumber) ||
              t.portfolioRefNumber == null),
        ),
    );

    // if there are duplicate entries, set them as invalid rows
    if (duplicatePayments.length > 0) {
      setInvalidRows(
        duplicatePayments.map((payment) => {
          let name = payment.givenName;
          if (name) {
            if (payment.lastName) name += ` ${payment.lastName}`;
          } else if (payment.employerSideId) name = payment.employerSideId;
          if (payment.portfolioRefNumber) name += ` (${payment.portfolioRefNumber})`;
          return {
            row: payment,
            valid: false,
            missingHeaders: [t('general.errors.parse_failed_payment_duplicate', { row: name })],
          };
        }),
      );

      // do not continue
      return;
    }

    // check if all the rows have a match for their employerSideId
    const query = await fetchEmployees(payments);
    if (!query?.content || !Array.isArray(query.content)) {
      // this should not happen
      captureMessage(`Failed to fetch employees for company ${companyBenefitId} with stack ${JSON.stringify(query)}}`);

      // do not continue
      return;
    }

    // try to match a payment to an employee
    const invalidPayments = payments
      .map((payment) => {
        const result = query.content.find((employee) => employee.employerSideId === payment.employerSideId);
        if (!result) return payment;
        return false;
      })
      .filter(Boolean);

    // if there are invalid payments, set them as invalid rows
    if (invalidPayments.length > 0) {
      setInvalidRows(
        (invalidPayments as TransactionPayment[]).map((payment) => {
          const member = query.content.find((employee) => employee.employerSideId === payment.employerSideId);
          const obj = { ...member, ...payment };
          let name = obj.givenName;
          if (name) {
            if (obj.lastName) name += ` ${obj.lastName}`;
          } else {
            if (obj.employerSideId) name = obj.employerSideId;
            else if (obj.email) name = obj.email;
          }
          return {
            row: payment,
            valid: false,
            missingHeaders: [t('general.errors.parse_failed_employee_not_found', { row: name })],
          };
        }),
      );
      // do not continue
      return;
    }

    // combine rows with employee data
    const members = payments.map((payment) => {
      const member = query.content.find((member) => member.employerSideId === payment.employerSideId);
      // If the amounts include commas, replace them with dots
      // This is not locale based, but it should work for most cases
      payment.employeeAmount = payment.employeeAmount?.replace(/,/g, '.') || '0';
      payment.employerAmount = payment.employerAmount?.replace(/,/g, '.') || '0';
      return { ...member, ...payment };
    });
    setRows(members);
    setCurrentStep(2);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (rows.length > 0) {
      try {
        const response = await submitPayments.mutateAsync(rows);
        if (response) setPayments(response);
        setIsSuccess(true);
      } catch (err) {
        console.error(err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getAmountHeaders = (): (keyof CSVPayment)[] => {
    const scheme = selectedProgram?.benefitProgram?.scheme;

    const headers: (keyof CSVPayment)[] = [];
    if (scheme === BenefitScheme.EMPLOYEE_ONLY) {
      headers.push('employeeAmount');
    } else if (scheme === BenefitScheme.EMPLOYER_ONLY) {
      headers.push('employerAmount');
    } else {
      headers.push('employeeAmount', 'employerAmount');
    }
    return headers;
  };

  const handleDownloadCSVTemplate = () => {
    let headers: (keyof CSVPayment)[] = ['employerSideId', 'portfolioRefNumber', 'givenName', 'lastName'];
    headers = headers.concat(getAmountHeaders());

    // We can prefill this with member data, but needs to be done on the backend
    createAndDownloadCSVFile([], t('payments.file_name'), headers);
  };

  if (isSuccess) return <JournalPaymentsUploadSuccess rows={rows} payments={payments} />;

  const instructions = t('payments.upload.tooltip.instructions', { returnObjects: true });

  return (
    <div tw="bg-white rounded-2xl p-4 md:p-8">
      <div tw="flex flex-col gap-8">
        <div tw="flex flex-row flex-wrap gap-4 items-center justify-between">
          <StepHeader
            currentStep={currentStep}
            onCurrentStepChange={setCurrentStep}
            steps={[
              {
                title: t('payments.upload.steps.step_1'),
                icon: <UserIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
                activeIcon: <UserIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />,
                index: 0,
              },
              {
                title: t('payments.upload.steps.step_2'),
                icon: <CheckIcon width={32} height={32} tw="text-gray-500 bg-gray-50 rounded" />,
                activeIcon: <CheckIcon width={32} height={32} tw="text-sky-blue-700 bg-sky-blue-100 rounded" />,
                index: 1,
              },
            ]}
          />
          {currentStep === 1 && (
            <div tw="ml-auto">
              <Tooltip
                showOnHover={false}
                content={
                  <div tw="flex flex-col gap-2 p-4 max-w-lg">
                    <p>{t('payments.upload.tooltip.description')}</p>
                    <ol tw="flex flex-col gap-2">
                      {Array.isArray(instructions) &&
                        instructions.map((item, idx) => (
                          <li key={item}>
                            <strong>{idx + 1}.</strong> {item}
                          </li>
                        ))}
                    </ol>
                    <Button onClick={handleDownloadCSVTemplate} variant={ButtonVariant.PRIMARY} tw="max-w-max">
                      {t('payments.upload.tooltip.download')}
                    </Button>
                  </div>
                }
              >
                <Button variant={ButtonVariant.SECONDARY}>{t('payments.upload.tooltip.title')}</Button>
              </Tooltip>
            </div>
          )}
        </div>
        <CSVUploadErrors data_points={invalidRows} errors={errors} />
        <div css={[currentStep !== 1 && tw`hidden`]}>
          <CSVImporter
            title={t('payments.upload.dropzone')}
            icon={<EuroIcon width={64} height={64} tw="text-sky-blue-700 bg-sky-blue-50 rounded" />}
            handleTemplateDownload={handleDownloadCSVTemplate}
            onComplete={handleUpload}
            requiredHeaders={['employerSideId'].concat(getAmountHeaders())}
          />
        </div>
        <div css={[currentStep !== 2 && tw`hidden`]}>
          <PaymentsList rows={rows} />
        </div>
        {currentStep === 2 && (
          <div tw="flex flex-row flex-wrap justify-between items-center">
            <div>
              <Button onClick={() => setCurrentStep((p) => p - 1)} disabled={isSubmitting}>
                {t('back', { ns: 'general' })}
              </Button>
            </div>
            <div>
              <Button variant={ButtonVariant.PRIMARY} onClick={handleSubmit} disabled={isSubmitting}>
                <span css={[isSubmitting && tw`opacity-0`]}>{t('confirm', { ns: 'general' })}</span>
                {isSubmitting && (
                  <div tw="absolute">
                    <Spinner />
                  </div>
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentsList = ({ rows }: { rows: TransactionPayment[] }) => {
  const { t } = useTranslation('company');
  const columns = useMemo<ColumnDef<TransactionPayment>[]>(
    () => [
      {
        header: ' ',
        accessorFn: (row) => row.employerSideId,
        id: 'context',
        cell: (info) => {
          if (info.row.index === 0)
            return (
              <h4 tw="text-base md:text-lg text-gray-400 hidden sm:block">{t('payments.upload.added_payments')}</h4>
            );
          return null;
        },
      },
      {
        header: ' ',
        accessorFn: (row) =>
          `${row.givenName != '' ? row.givenName + ' ' + row.lastName : row.employerSideId} 
           ${row.portfolioRefNumber != '' ? '(' + row.portfolioRefNumber + ')' : ''} `,
        id: 'name',
        cell: (info) => (
          <div tw="whitespace-nowrap">
            <Member name={info.getValue() as string} />
          </div>
        ),
        footer: t('payments.total'),
      },
      {
        header: () => <p tw="text-right w-full">{t('payments.employee')}</p>,
        accessorFn: (row) => row.employeeAmount,
        id: 'employee_payments',
        cell: (info) => <p tw="text-right">{formatCompanyPayment(info.getValue() as string)}</p>,
        footer: () => {
          const employeeTotal = rows.reduce((acc, row) => acc + parseFloat(row.employeeAmount || '0'), 0);
          return <p tw="text-right">{formatCompanyPayment(employeeTotal)}</p>;
        },
      },
      {
        header: () => <p tw="text-right w-full">{t('payments.employer')}</p>,
        accessorFn: (row) => row.employerAmount,
        id: 'employer_payments',
        cell: (info) => <p tw="text-right">{formatCompanyPayment(info.getValue() as string)}</p>,
        footer: () => {
          const employerTotal = rows.reduce((acc, row) => acc + parseFloat(row.employerAmount || '0'), 0);
          return <p tw="text-right">{formatCompanyPayment(employerTotal)}</p>;
        },
      },
      {
        header: () => <p tw="text-right w-full">{t('payments.total')}</p>,
        accessorFn: (row) => parseFloat(row.employeeAmount) + parseFloat(row.employerAmount),
        id: 'total',
        cell: (info) => <p tw="text-right">{formatCompanyPayment(info.getValue() as string)}</p>,
        footer: () => {
          const employerTotal = rows.reduce((acc, row) => acc + parseFloat(row.employerAmount || '0'), 0);
          const employeeTotal = rows.reduce((acc, row) => acc + parseFloat(row.employeeAmount || '0'), 0);
          return <p tw="text-right">{formatCompanyPayment(employerTotal + employeeTotal)}</p>;
        },
      },
    ],
    [t, rows],
  );

  const table = useReactTable({
    data: rows ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div tw="flex w-full">
      <TableBase table={table} />
    </div>
  );
};
