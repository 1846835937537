var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import I18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import calculatorDe from '../../locales/de/calculator.json';
import defaultDe from '../../locales/de/default.json';
import competitorComparisonDe from '../../locales/de/competitor_comparison.json';
import countriesDe from '../../locales/de/countries.json';
import calculatorEn from '../../locales/en/calculator.json';
import defaultEn from '../../locales/en/default.json';
import competitorComparisonEn from '../../locales/en/competitor_comparison.json';
import countriesEn from '../../locales/en/countries.json';
import calculatorEt from '../../locales/et/calculator.json';
import defaultEt from '../../locales/et/default.json';
import competitorComparisonEt from '../../locales/et/competitor_comparison.json';
import countriesEt from '../../locales/et/countries.json';
// import from dayjs from date.ts because it needs to be initialized in order to be executed
import { dayjs } from './date';
// export these functions and components from ui-kit
export { I18nextProvider, useTranslation, Trans } from 'react-i18next';
var dev = process.env.NODE_ENV === 'development';
var supportedLanguages = ['en', 'de', 'et'];
var namespaces = ['calculator', 'default', 'competitor_comparison', 'countries'];
var resources = {
    en: {
        calculator: calculatorEn,
        default: defaultEn,
        competitor_comparison: competitorComparisonEn,
        countries: countriesEn,
    },
    de: {
        calculator: calculatorDe,
        default: defaultDe,
        competitor_comparison: competitorComparisonDe,
        countries: countriesDe,
    },
    et: {
        calculator: calculatorEt,
        default: defaultEt,
        competitor_comparison: competitorComparisonEt,
        countries: countriesEt,
    },
};
var create = function (overrides) {
    var instance = I18next.createInstance();
    instance.use(Backend).use(LanguageDetector);
    //.use(initReactI18next)
    instance.on('initialized', function () {
        if (dev)
            console.log('UI-kit initialized i18n instance');
    });
    instance.on('languageChanged', function (lang) {
        dayjs.locale(lang);
        if (typeof window !== 'undefined')
            document.documentElement.setAttribute('lang', lang);
    });
    instance.init(__assign({ 
        // debug: dev,
        defaultNS: 'default', detection: {
            caches: ['cookie'],
            cookieOptions: { domain: "grunfin.".concat(dev ? 'dev' : 'com') },
            lookupCookie: 'NEXT_LOCALE',
            lookupFromPathIndex: 0,
            order: ['querystring', 'path', 'cookie', 'navigator', 'htmlTag'],
        }, fallbackLng: supportedLanguages[0], interpolation: { escapeValue: false }, transSupportBasicHtmlNodes: true, ns: namespaces, supportedLngs: supportedLanguages, resources: resources }, overrides));
    return instance;
};
// TODO: How to use dynamic imports in monorepo so that they get imported on the module, not submodule level?
/* languages.forEach((language) => {
  namespaces.forEach(async (namespace) => {
    const hasResourceBundle = i18n.hasResourceBundle(language, namespace);
    if (hasResourceBundle) return;
    const resource = await import(`../../locales/${language}/${namespace}.json`);
    i18n.addResourceBundle(language, namespace, resource.default, true);
  });
}); */
export var i18n = create();
export var createI18n = create;
