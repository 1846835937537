import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'twin.macro';
import { useGetMembershipCompany } from '../application/Navigation';

export function CompanyIndexView() {
  const navigate = useNavigate();
  const selectedProgram = useGetMembershipCompany();

  useEffect(() => {
    if (!selectedProgram?.company || !selectedProgram.benefitProgram) {
      navigate('/', { replace: true });
    } else {
      navigate(`/company/${selectedProgram.company.companyId}/benefit/${selectedProgram.benefitProgram}`, {
        replace: true,
      });
    }
  }, [selectedProgram, navigate]);

  return null;
}
