export var PreferredRiskReturnRatio;
(function (PreferredRiskReturnRatio) {
    PreferredRiskReturnRatio["High"] = "HIGH";
    PreferredRiskReturnRatio["Medium"] = "MEDIUM";
    PreferredRiskReturnRatio["Low"] = "LOW";
    PreferredRiskReturnRatio["Very_High"] = "VERY_HIGH";
})(PreferredRiskReturnRatio || (PreferredRiskReturnRatio = {}));
export var FocusArea;
(function (FocusArea) {
    FocusArea["CLIMATE_CHANGE"] = "Climate";
    FocusArea["GENDER_EQUALITY"] = "Gender Equality";
    FocusArea["HEALTH"] = "Health";
    FocusArea["SP500"] = "SP500";
})(FocusArea || (FocusArea = {}));
export var MembershipStatus;
(function (MembershipStatus) {
    MembershipStatus["INVITED"] = "INVITED";
    MembershipStatus["ONBOARDING"] = "ONBOARDING";
    MembershipStatus["ACTIVE"] = "ACTIVE";
    MembershipStatus["EXITED"] = "EXITED";
})(MembershipStatus || (MembershipStatus = {}));
export var DecisionStatus;
(function (DecisionStatus) {
    DecisionStatus["CREATED"] = "CREATED";
    DecisionStatus["DISMISSED"] = "DISMISSED";
    DecisionStatus["APPROVED"] = "APPROVED";
    DecisionStatus["FULFILLED"] = "FULFILLED";
})(DecisionStatus || (DecisionStatus = {}));
