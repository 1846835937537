import {
  AlertDialog,
  BeneficiaryType,
  Button,
  ButtonSize,
  ButtonVariant,
  MembershipStatus,
  Message,
  PricingScheme,
  Radio,
  RadioGroup,
  toast,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import ContentLoader from 'react-content-loader';
import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import 'twin.macro';

import { useNavigationTitle } from '~/modules/application/Navigation';
import { useSession } from '~/modules/auth/SessionProvider';
import { useApplyVoucher, useValidateVoucher } from '~/modules/gift/voucher/queries';
import { VOUCHER_KEY } from '~/modules/gift/voucher/VoucherRedeem';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { useDocumentTitle } from '~/utils';

import { OnboardingBanner } from './OnboardingBanner';
import { PortfolioItem } from './PortfolioItem';
import PromotionalBanner from './PromotionalBanner';
import { useListPortfolios } from './queries';
import { DRAFT_PORTFOLIO_STATUSES, PortfolioStatus } from './types';
import employerOnboardingBackground from '~/assets/output/enroll-background.webp';
import {
  useAumSwitchInitiate,
  useGetAumSwitchStatus,
  useGetGeneralTermsHtml,
  useMarkAumSwitchNotificationShown,
} from '~/modules/account/queries';
import { VerificationStatus } from '~/modules/auth/types';
import { EmployeeOnboardingLink } from '~/modules/benefits/enroll/EmployeeOnboardingView';

export function DashboardView() {
  const { t, i18n } = useTranslation('portfolio');
  const session = useSession();
  const validateVoucher = useValidateVoucher();
  const aumSwitchStatus = useGetAumSwitchStatus();
  const generalTermsHtml = useGetGeneralTermsHtml(i18n?.language);
  const dismissNewPricing = useMarkAumSwitchNotificationShown();
  const acceptNewPricing = useAumSwitchInitiate();
  const applyVoucher = useApplyVoucher();
  const [showGiftPrompt, setShowGiftPrompt] = useState(false);
  const [showPricingChange, setShowPricingChange] = useState(false);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<string>();
  const voucherCode = localStorage.getItem(VOUCHER_KEY);
  const portfolios = useListPortfolios({ enabled: session.authenticated });
  const activePortfolios = portfolios?.data?.filter((p) => p.status === PortfolioStatus.ACTIVE);
  const hasOldPricing = portfolios?.data?.some(
    (p) => p.status !== PortfolioStatus.EXITED && p.pricingScheme === PricingScheme.RETAIL_LEGACY_FLAT,
  );
  const pricingStatus = aumSwitchStatus?.data?.status;
  useDocumentTitle(t('dashboard.document_title'));
  useNavigationTitle(t('dashboard.navigation_title'));

  useEffect(() => {
    voucherCode && validateVoucher.mutateAsync(voucherCode).then(() => setShowGiftPrompt(true));
  }, [voucherCode]);

  useEffect(() => {
    if (hasOldPricing && pricingStatus === 'WAITING') {
      setShowPricingChange(true);
    }
  }, [portfolios?.data]);

  if (portfolios.isError) {
    return (
      <ContactSupportOverlay
        title={t('dashboard.portfolios.error.title')}
        onClose={portfolios.refetch}
        error={portfolios.error}
      />
    );
  }

  if (Array.isArray(portfolios.data) && portfolios.data.length === 0 && !session?.companies?.length) {
    const benefitMembership = session.benefitMemberships?.filter((membership) =>
      [MembershipStatus.INVITED, MembershipStatus.ONBOARDING].includes(membership?.status),
    )?.[0];
    if (benefitMembership?.slug && benefitMembership?.membershipId) {
      return <Navigate to={`/enroll/${benefitMembership.slug}/id/${benefitMembership.membershipId}`} replace />;
    } else {
      return <Navigate to="/onboarding" replace />;
    }
  }

  if (session.authenticated && !session.emailVerified) {
    return <Navigate to="/verify-email" replace />;
  }

  const applyGiftToPortfolio = async () => {
    if (voucherCode && selectedPortfolioId) {
      setShowGiftPrompt(false);
      await applyVoucher.mutateAsync({ voucherCode, portfolioId: selectedPortfolioId });
      localStorage.removeItem(VOUCHER_KEY);
      await portfolios.refetch();
      toast.success(t('dashboard.gift_card.success'));
    }
  };

  const isAnyPortfolioActivated =
    Array.isArray(portfolios.data) && portfolios.data.some((p) => !DRAFT_PORTFOLIO_STATUSES.includes(p.status));

  const PortfolioGiftSelector = () => (
    <AlertDialog
      open
      title={t('dashboard.gift_card.code_entered')}
      description={t('dashboard.gift_card.select_portfolio')}
      content={
        activePortfolios?.length ? (
          <RadioGroup
            name="select_portfolio_for_gift"
            onChange={(event) => setSelectedPortfolioId(event.target.value)}
            selectedValue={selectedPortfolioId}
          >
            {activePortfolios?.map((portfolio) => (
              <Radio
                key={portfolio.id}
                value={portfolio.id}
                label={`${portfolio.name} - ${portfolio.referenceNumber}`}
              />
            ))}
          </RadioGroup>
        ) : (
          <p className="max-w-sm">{t('dashboard.gift_card.no_portfolio')}</p>
        )
      }
      actions={(closeFn: () => void) => (
        <>
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.LARGE}
            onClick={() => {
              closeFn();
              setShowGiftPrompt(false);
            }}
          >
            {t('close', { ns: 'general' })}
          </Button>
          <div style={{ margin: '1rem' }} />
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={() => {
              applyGiftToPortfolio();
              closeFn();
            }}
            disabled={!selectedPortfolioId}
          >
            {t('confirm', { ns: 'general' })}
          </Button>
        </>
      )}
    >
      <Fragment />
    </AlertDialog>
  );

  const PricingChangeModal = () => (
    <AlertDialog
      open
      isDismissable={false}
      title={<div tw="md:ml-8 max-w-xs md:max-w-screen-lg">{t('dashboard.price_change.title')}</div>}
      content={
        <div tw="max-w-xs md:max-w-screen-lg">
          <div tw="text-base font-bold md:ml-8 mt-8">{t('dashboard.price_change.intro')}</div>
          <ul tw="grid list-disc mx-4 md:mx-12 mt-2 marker:text-neutral-300 text-base">
            <li tw="list-item">{t('dashboard.price_change.success_fee')}</li>
            <li tw="list-item">{t('dashboard.price_change.monthly')}</li>
            <li tw="list-item">{t('dashboard.price_change.other_fees')}</li>
          </ul>
          <div tw="text-sm md:ml-8 mt-2 text-gray-400">{t('dashboard.price_change.summary')}</div>
          <div
            tw="px-2 md:px-16 mt-8 bg-neutral-50 max-h-80 overflow-auto text-sm md:mx-[-32px] leading-relaxed"
            dangerouslySetInnerHTML={{ __html: generalTermsHtml.data || '' }}
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '-webkit-mask-image': 'linear-gradient(180deg, #000 80%, transparent)',
            }}
          />
        </div>
      }
      actions={(closeFn: () => void) => (
        <>
          <Button
            className="text-gray-400"
            variant={ButtonVariant.TRANSPARENT}
            size={ButtonSize.SMALL}
            onClick={async () => {
              setShowPricingChange(false);
              await dismissNewPricing.mutateAsync();
              closeFn();
            }}
          >
            {t('not_now', { ns: 'general' })}
          </Button>
          <div style={{ margin: '1rem' }} />
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={async () => {
              setShowPricingChange(false);
              await acceptNewPricing.mutateAsync();
              toast.success(t('completed', { ns: 'general' }));

              await portfolios.refetch();
              closeFn();
            }}
          >
            {t('agree', { ns: 'general' })}
          </Button>
        </>
      )}
    >
      <Fragment />
    </AlertDialog>
  );

  return (
    <div>
      {showGiftPrompt && <PortfolioGiftSelector />}
      {showPricingChange && <PricingChangeModal />}
      {hasOldPricing && pricingStatus === 'SHOWN' && <PromotionalBanner onClick={() => setShowPricingChange(true)} />}
      {portfolios.data != null && Array.isArray(portfolios.data) && portfolios.data.length > 0 && (
        <OnboardingBanner portfolio={portfolios.data[0]} />
      )}
      {Array.isArray(portfolios.data) && !isAnyPortfolioActivated && (
        <div tw="my-8 md:text-lg">
          <Message>{t('dashboard.portfolios.activate')}</Message>
        </div>
      )}

      {(!Array.isArray(portfolios.data) || portfolios.isLoading) && (
        <div tw="flex flex-col px-4 gap-10">
          {Array.from({ length: 4 }, (_, i) => {
            return <PortfolioItemLoader viewBox="0 0 400 160" key={i} />;
          })}
        </div>
      )}

      {Array.isArray(portfolios.data) &&
        portfolios.data.map((p) => {
          if (p.beneficiaryType === BeneficiaryType.EMPLOYEE) {
            if (DRAFT_PORTFOLIO_STATUSES.includes(p.status)) return <EmployeeOnboardingBanner key={p.id} />;
          }
          return <PortfolioItem key={p.id} portfolio={p} />;
        })}

      {Array.isArray(portfolios.data) &&
        portfolios.data.some(
          (p) => DRAFT_PORTFOLIO_STATUSES.includes(p.status) && p.beneficiaryType !== BeneficiaryType.EMPLOYEE,
        ) && (
          <div tw="text-center text-gray-400 col-span-full mx-8 text-sm md:text-base">
            {t('dashboard.gift_card.disclaimer')}
          </div>
        )}
    </div>
  );
}

const EmployeeOnboardingBanner = () => {
  const { t } = useTranslation('portfolio');
  const session = useSession();
  const benefitMembershipCompanyName = session.benefitMemberships?.[0]?.companyName;

  const subpath = !session.personalDataComplete
    ? EmployeeOnboardingLink.PERSONAL_DETAILS
    : session.verification === VerificationStatus.NOT_STARTED
      ? EmployeeOnboardingLink.VERIFICATION
      : EmployeeOnboardingLink.CONTRACT;

  return (
    <div tw="mx-2 my-8">
      <div
        tw="flex flex-row flex-wrap sm:flex-nowrap items-center justify-between gap-4 p-4 rounded-lg bg-white"
        style={{
          backgroundImage: `url(${employerOnboardingBackground})`,
          backgroundColor: 'rgba(43, 106, 116, 1)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'soft-light',
          backgroundRepeat: 'no-repeat',
          zIndex: -1,
        }}
      >
        <p tw="md:text-lg text-white">
          <Trans
            i18nKey="portfolio:dashboard.portfolios.employee_activate"
            values={{ name: benefitMembershipCompanyName }}
          />
        </p>
        <div>
          <Button variant={ButtonVariant.SECONDARY} as="a" href={'/enroll/' + subpath} className="max-w-max">
            {t('continue', { ns: 'general' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

const PortfolioItemLoader = (props: any) => (
  <div tw="rounded-lg shadow-md p-6 py-12 md:p-12 bg-white">
    <div tw="hidden sm:block">
      <ContentLoader viewBox="0 0 400 100" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
        <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
        <rect x="370" y="0" rx="3" ry="3" width="30" height="15" />
        <rect x="330" y="0" rx="3" ry="3" width="30" height="15" />
        <rect x="0" y="30" rx="3" ry="3" width="75" height="25" />
        <rect x="90" y="30" rx="3" ry="3" width="75" height="25" />
        <rect x="180" y="30" rx="3" ry="3" width="75" height="25" />
        <rect x="325" y="30" rx="3" ry="3" width="75" height="25" />
        <rect x="0" y="75" rx="10" ry="10" width="400" height="15" />
      </ContentLoader>
    </div>
    <div tw="sm:hidden">
      <ContentLoader viewBox="0 0 400 400" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
        <rect x="0" y="0" rx="3" ry="3" width="200" height="40" />
        <rect x="325" y="0" rx="3" ry="3" width="75" height="40" />
        <rect x="0" y="85" rx="3" ry="3" width="180" height="75" />
        <rect x="225" y="85" rx="3" ry="3" width="180" height="75" />
        <rect x="0" y="185" rx="3" ry="3" width="180" height="75" />
        <rect x="225" y="185" rx="3" ry="3" width="180" height="75" />
        <rect x="0" y="325" rx="25" ry="25" width="400" height="50" />
      </ContentLoader>
    </div>
  </div>
);
