var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button, ButtonVariant } from './Button';
import { ErrorIcon } from '../icons';
import { useTranslation } from 'react-i18next';
export var ErrorOverlay = function (props) {
    var t = useTranslation('general').t;
    return (React.createElement("div", { className: "flex items-center justify-center fixed top-0 left-0 w-full h-full z-10", style: { backdropFilter: 'blur(16px)', WebkitBackdropFilter: 'blur(16px)' } },
        React.createElement(DisplayError, __assign({ buttonText: t('close') }, props))));
};
export var DisplayError = function (_a) {
    var children = _a.children, error = _a.error, _b = _a.buttonText, buttonText = _b === void 0 ? 'Close' : _b, onClose = _a.onClose, title = _a.title;
    return (React.createElement("div", { className: "flex flex-col items-start p-8" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement(ErrorIcon, { className: "rounded-full bg-dawn-600 text-white md:w-20 md:h-20 mr-4 md:mr-10 flex-shrink-0" }),
            error && (React.createElement("div", { className: "text-sm text-gray-200 max-w-xl" },
                new Date().toISOString(),
                React.createElement("br", null),
                formatError(error)))),
        (title || children) && (React.createElement("div", { className: "text-lg md:text-xl mt-5 md:mt-10 max-w-xl" },
            title && React.createElement("div", { className: "text-alps-blue-800" }, title),
            React.createElement("div", { className: "text-alps-blue-300" }, children))),
        React.createElement("div", { className: "mt-10" },
            React.createElement(Button, { variant: ButtonVariant.SECONDARY, onClick: onClose }, buttonText))));
};
/**
 * Format error message
 */
export var formatError = function (err) {
    if (err.name === 'HTTPError') {
        var _a = err.response, status_1 = _a.status, url = _a.url;
        return "HTTPError ".concat(status_1, " ").concat(url.replace(window.location.origin, ''));
    }
    return "".concat(err.name, ": ").concat(err.message);
};
