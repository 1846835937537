import { Button, ButtonSize, ButtonVariant, Field, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';

import { Consents } from '~/modules/portfolio/components/Consents';
import { Heading } from '~/modules/portfolio/components/Heading';
import { StripeModal } from '~/modules/portfolio/components/StripeModal';
import { StripeTermsCheckbox } from '~/modules/portfolio/components/StripeTermsCheckbox';
import { useCurrentConsent, usePortfolio } from '~/modules/portfolio/hooks';
import { useStartStripeSession, useSubscribePortfolioToDirectDebitConsent } from '~/modules/portfolio/queries';
import { ConditonalWrapper } from '~/utils';

import { SetUpSuccess } from './SetUpSuccess';

interface Values {
  consentId?: string;
  agreeToTerms?: boolean;
}

export const DirectDebit = () => {
  const { t } = useTranslation('portfolio');
  const [isModalOpen, setModalOpen] = useState(false);
  const [query] = useSearchParams();
  const { id: portfolioId } = usePortfolio();
  const currentConsent = useCurrentConsent();
  const subscribeConsentToPortfolio = useSubscribePortfolioToDirectDebitConsent();
  const stripeSession = useStartStripeSession(portfolioId);
  const { handleSubmit, watch, setValue } = useForm<Values>({
    defaultValues: { consentId: currentConsent?.id },
  });
  const consentId = watch('consentId');
  const agreeToTerms = watch('agreeToTerms');
  const isValid = !!consentId && agreeToTerms;
  const isSubmitting = stripeSession.isLoading || subscribeConsentToPortfolio.isLoading;
  const isSuccess = subscribeConsentToPortfolio.isSuccess || query.has('success');

  useEffect(() => {
    if (!consentId && currentConsent) setValue('consentId', currentConsent.id);
  }, [consentId, currentConsent, setValue]);

  const onSubmit = async (data: Values) => {
    if (data.consentId === 'new') setModalOpen(true);
    else await setUp();
  };

  const setUp = async () => {
    if (!consentId) throw new Error('Missing consent ID');

    if (consentId === 'new') {
      const currentUrl = window.location.href;
      const { url } = await stripeSession.mutateAsync({
        successUrl: `${currentUrl}?success=true`,
        cancelUrl: currentUrl,
      });
      window.location.href = url;
    } else await subscribeConsentToPortfolio.mutateAsync({ portfolioId, consentId });
  };

  if (isSuccess) return <SetUpSuccess />;

  return (
    <>
      <Heading primary>{t('details.payment.set_up.direct_debit.title')}</Heading>
      <form onSubmit={handleSubmit(onSubmit)} tw="px-6">
        <Field label={t('details.payment.top_up.iban.label')}>
          <Consents onChange={(id) => setValue('consentId', id)} selectedValue={consentId} />
        </Field>
        <StripeTermsCheckbox onChange={(value) => setValue('agreeToTerms', value)} value={agreeToTerms} />
        <div tw="relative mt-12 flex md:justify-center">
          <ConditonalWrapper
            condition={consentId === 'new'}
            wrap={(wrappedChildren) => (
              <StripeModal open={isModalOpen} onClose={() => setModalOpen(false)} onSubmit={setUp}>
                {wrappedChildren}
              </StripeModal>
            )}
          >
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
            >
              {isSubmitting && (
                <div tw="absolute flex items-center justify-center w-full">
                  <Spinner />
                </div>
              )}
              <span css={[isSubmitting && tw`invisible`]}>{t('details.payment.set_up.direct_debit.confirm')}</span>
            </Button>
          </ConditonalWrapper>
        </div>
      </form>
    </>
  );
};
