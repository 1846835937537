import { Route, Routes } from 'react-router-dom';

import { SupportView } from '~/modules/support/SupportView';

export function SupportRoutes() {
  return (
    <Routes>
      <Route path="*" element={<SupportView />} />
    </Routes>
  );
}
