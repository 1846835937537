import {
  Button,
  ButtonVariant,
  CheckIcon,
  ChevronIcon,
  GrunfinLogoType,
  Language,
  LanguagePicker,
  LanguagePickerTheme,
  LanguagePickerVariant,
  useTranslation,
} from '@grunfin/ui-kit';
import { captureMessage } from '@sentry/react';
import React, { cloneElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Link, matchPath, Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import bg from '~/assets/output/onboarding-background.webp';
import { useSession } from '~/modules/auth/SessionProvider';
import { EnrollmentResponse } from '~/modules/benefits/queries';
import { useGetIdentityVerificationStatus, useGetPortfolioContract } from '~/modules/portfolio/queries';
import { VerificationState } from '~/modules/portfolio/types';
import { Footer, FooterVariant } from '../Footer';
import { PageLoader } from '../PageLoader';
import { RelatedPersonInvite } from '~/modules/account/types';
import {
  ActivationLink,
  ActivationLinks,
  activationMatchHash,
  ActivationViewType,
  UBOActivationLink,
  UBOBasePath,
} from '~/modules/portfolio/ActivateView/ActivationView';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useFloating,
  useTransitionStyles,
} from '@floating-ui/react';
import { useSubmitSettings } from '~/modules/account/queries';

export type OnboardingWizardStepProps = {
  title: string;
  label: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;
  link: string;
  disabled?: boolean;
  visible?: boolean;
};

export interface OnboardingOutletContext extends EnrollmentResponse, OnboardingWizardStepProps, RelatedPersonInvite {
  onContinue: (step?: OnboardingWizardStepProps) => void;
  setCreatedPortfolioId: (id: string) => void;
  currentStepIndex: number;
  steps: OnboardingWizardStepProps[];
  onBack: () => void;
  handleStepChange: (step: OnboardingWizardStepProps) => void;
}

type OnboardingWizardProps = {
  steps: OnboardingWizardStepProps[];
  baseRoute: string;
  validRoutes: string[];
  subpath: string;
  outletContext?: Partial<OnboardingOutletContext>;
  background?: string;
};

const isValidSubpathLink = (step: OnboardingWizardStepProps, validRoutes: string[], subpath?: string) => {
  let result = false;
  if (!subpath || step == null) return result;
  // check for match
  if (validRoutes.includes(subpath)) result = true;
  else captureMessage(`Employee onboarding: Invalid subpath link {{${subpath}}} with step {{${step}}}`);
  return result;
};

const OnboardingWizard = (props: OnboardingWizardProps) => {
  const { steps, subpath, baseRoute, validRoutes } = props;
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { membershipId, id: portfolioId } = useParams();
  const session = useSession();
  const submitSettings = useSubmitSettings();
  const stepContainerRef = useRef<HTMLDivElement>(null);
  const isCredentialsDisabled = session.authenticated && session.emailVerified;
  const [initialRedirect, setInitialRedirect] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const finalMembershipId = membershipId ?? session.benefitMemberships?.[0]?.membershipId;
  const getStatus = useGetIdentityVerificationStatus({ enabled: Boolean(finalMembershipId) && session.authenticated });
  const isVerificationDisabled = getStatus.data?.verificationState === VerificationState.APPROVED;
  const getContract = useGetPortfolioContract(portfolioId as string);
  const isContractCompleted = getContract.data?.signedAt != null;
  const isLastStep = currentStep?.link === steps[steps.length - 1]?.link;
  const currentStepIndex = steps?.findIndex((s) => s.link === currentStep?.link);
  const isCompanyUBOView =
    matchPath(activationMatchHash[ActivationViewType.COMPANY_UBO], pathname) != null ||
    matchPath(UBOBasePath, pathname) != null;

  const getStep = (link: string) => steps.find((s) => s.link === link);

  useEffect(() => {
    if (subpath !== currentStep.link) {
      const step = getStep(subpath);
      step && setCurrentStep(step);
    }
  }, [subpath]);

  const handleStepChange = (step: OnboardingWizardStepProps, replace = false) => {
    // if the incoming step is larger than the length of steps, we are done
    if (isLastStep) return;
    setCurrentStep(step);
    const stepLink = getStep(step.link)?.link;
    // TODO: Sort out dynamic routing with library instead of manual work
    if (stepLink) navigate(`${baseRoute}${baseRoute.endsWith('/') ? '' : '/'}${stepLink}`, { replace });

    // scroll the step into view (useful for mobile devices)
    // we use timeout because the components gets rerendered and the ref is lost
    // between the rendering cycles, which means we will trigger it
    // once the underlying component has been rerendered
    setTimeout(() => {
      if (stepContainerRef.current != null) stepContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  const getNextStep = (currentStep: OnboardingWizardStepProps): OnboardingWizardStepProps | undefined => {
    if (currentStep.link === ActivationLink.WELCOME) {
      if (!isCredentialsDisabled) return getStep(ActivationLink.CREDENTIALS);
      else return getStep(ActivationLink.PERSONAL_DETAILS);
    }
    if (currentStep.link === ActivationLink.CREDENTIALS) {
      if (session != null && session.emailVerified) return getStep(ActivationLink.PERSONAL_DETAILS);
    }

    if (currentStep.link === ActivationLink.COMPLETED) {
      navigate('/');
    }

    const nextStep = steps[currentStepIndex + 1];

    // if there is no next step, return the current one (this only happens when handleStepChange is called on the last step)
    if (nextStep == null) return currentStep;

    return nextStep;
  };

  const getPreviousStep = (currentStep: OnboardingWizardStepProps): OnboardingWizardStepProps => {
    const previousStep = steps[currentStepIndex - 1];

    // TODO: or go recursive getPreviousStep is a missing step in the middle?
    if (previousStep == null) return currentStep;

    return previousStep;
  };

  const handleContinueStep = (currentStep?: OnboardingWizardStepProps) => {
    if (!currentStep) return;
    const next = getNextStep(currentStep);
    next && handleStepChange(next);
  };
  const handleBackStep = (currentStep?: OnboardingWizardStepProps) => {
    if (!currentStep) return;
    const previous = getPreviousStep(currentStep);
    handleStepChange(previous);
  };

  const handleInitialRoute = (step: OnboardingWizardStepProps) => {
    const restart = (step?: OnboardingWizardStepProps) => {
      if (!step) step = steps[0];
      handleStepChange(step, true);
    };

    if (!isValidSubpathLink(step, validRoutes, subpath)) {
      // if the subpath is not valid, redirect to the first step
      restart();
      // do not continue
      return;
    }

    const isAuthenticated = session != null && session.authenticated && session.emailVerified;
    // in either case, if the user is not authenticated, they must start the flow again
    if (!isAuthenticated && subpath === UBOActivationLink.CREDENTIALS) {
      // if the user is trying to access the CREDENTIALS step, let them
      restart(step);
      return;
    } else if (!isAuthenticated) {
      restart();
      return;
    }

    const stepIndex = steps.findIndex((s) => s.link === step.link);
    const nextStep = steps[stepIndex + 1];

    // we will perform checks (flow gates) to determine if the route can be accessed at this point in time (user is authenticated at this point)
    switch (subpath) {
      case ActivationLinks.CREDENTIALS:
        // if the user is trying to access the credentials step, redirect them to the next step
        restart(nextStep);
        break;
      case ActivationLinks.VERIFICATION:
        // if user is already verified, redirect them to the next step
        if (isVerificationDisabled) restart(nextStep);
        else restart(step);
        break;
      case ActivationLinks.INITIAL_PAYMENT:
      case ActivationLinks.MONTHLY_PAYMENT:
      case ActivationLinks.COMPLETED:
        if (!isContractCompleted && !isCompanyUBOView)
          restart(getStep(ActivationLinks.CONTRACT) as OnboardingWizardStepProps);
        else restart(step);
        break;
      default:
        restart(step);
    }
  };

  useEffect(() => {
    if (!initialRedirect && (getContract.isFetched || getContract.isIdle)) {
      const step = getStep(subpath);
      handleInitialRoute(step as OnboardingWizardStepProps);
      setInitialRedirect(true);
    }
  }, [initialRedirect, subpath, steps, getContract.isFetched, getContract.isIdle]);

  if (!initialRedirect) return <PageLoader />;

  const isWelcomeStep = currentStep?.link === ActivationLink.WELCOME;
  const hasBackground = isLastStep || isWelcomeStep;

  const context = {
    currentStepIndex,
    steps,
    handleStepChange,
    onBack: () => handleBackStep(currentStep),
    ...props.outletContext,
    ...currentStep,
    onContinue: () => handleContinueStep(currentStep),
  } as OnboardingOutletContext;

  const handleLanguageChange = (lang: Language) => {
    if (!session.authenticated) return;
    submitSettings.mutate({ locale: lang.code });
  };

  return (
    <OnboardingContainer background={props.background} hasBackground={hasBackground}>
      <div tw="flex flex-col h-full flex-grow" ref={stepContainerRef}>
        <div tw="w-full flex flex-row py-6 px-8 justify-between">
          <Link to="/">
            <GrunfinLogoType
              width={24}
              height={24}
              tw="w-28"
              className={hasBackground ? 'text-green-100' : 'text-forest-green-700'}
            />
          </Link>
          {!hasBackground ? (
            <Link to="/">
              <Button variant={ButtonVariant.NEW_SECONDARY}>{t('steps.return_portfolio')}</Button>
            </Link>
          ) : (
            <div tw="flex justify-end">
              <LanguagePicker
                theme={LanguagePickerTheme.DARK}
                variant={LanguagePickerVariant.GLOBE}
                offsetOptions={{ mainAxis: 20, crossAxis: -15 }}
                onLanguageChange={handleLanguageChange}
              />
            </div>
          )}
        </div>
        <div tw="flex flex-row flex-grow">
          <div tw="flex flex-col" css={[!isLastStep && tw`md:flex-[0.7]`, isLastStep && tw`w-0`]}>
            {!isLastStep && (
              <div
                tw="overflow-hidden sticky top-52 md:pr-2 md:p-4 items-center flex [&::-webkit-scrollbar]:hidden"
                css={[isWelcomeStep && tw`hidden h-full md:flex`]}
              >
                <div tw="w-full flex flex-row">
                  <div tw="hidden md:flex md:items-start md:flex-col md:gap-4 md:max-w-xs md:mx-auto ">
                    {stepsMenu(context)}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div tw="flex flex-col flex-grow md:flex-1">
            {currentStep != null && cloneElement(<Outlet context={context} />)}
          </div>
        </div>
      </div>
    </OnboardingContainer>
  );
};

type NavigationStepProps = {
  steps: OnboardingWizardStepProps[];
  currentStepIndex: number;
  setCurrentStep?: (step: OnboardingWizardStepProps) => void;
  handleStepChange: (step: OnboardingWizardStepProps) => void;
};

export const OnboardingContainer = ({
  background = bg,
  children,
  hasBackground = false,
}: {
  background?: string;
  children: ReactNode;
  hasBackground?: boolean;
}) => {
  return (
    <div tw="flex flex-col min-h-full">
      {children}
      <div tw="select-none">
        {hasBackground ? (
          <>
            <div
              tw="fixed w-full min-h-screen top-0 left-0"
              style={{
                background:
                  'radial-gradient(52.16% 54.96% at 31.38% 6.31%, rgba(240, 217, 96, 0.24) 0%, rgba(240, 217, 96, 0) 100%)',
                zIndex: -2,
              }}
            />
            <div
              tw="fixed w-full min-h-screen left-0 top-0"
              style={{
                backgroundImage: `url(${background})`,
                backgroundColor: '#306948',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundBlendMode: 'soft-light',
                backgroundRepeat: 'no-repeat',
                opacity: 0.9,
                zIndex: -3,
              }}
            />
          </>
        ) : (
          <div tw="mt-32">
            <Footer variant={FooterVariant.DARK_GREEN} showLinks={false} />
          </div>
        )}
      </div>
    </div>
  );
};

const stepsMenu = ({ steps, handleStepChange, currentStepIndex }: NavigationStepProps) => {
  return steps
    ?.filter((s) => !s.disabled)
    .map((step) => {
      const stepIndex = steps.findIndex((s) => s?.link === step?.link);
      const disabled = step?.disabled || stepIndex > currentStepIndex;
      const isWelcomeStep = steps[currentStepIndex]?.link === ActivationLink.WELCOME;

      const getColor = () => {
        if (disabled) return tw`before:bg-neutral-100 text-neutral-400`;
        else if (isWelcomeStep) return tw`before:bg-vivid-green-400 text-vivid-green-400`;
        else return tw`text-green-500 before:bg-green-500`;
      };

      return (
        <div key={step?.link}>
          <Button
            variant={ButtonVariant.TRANSPARENT}
            tw="flex flex-row gap-6 shadow-none text-left p-0"
            disabled={disabled}
            onClick={() => handleStepChange(step)}
          >
            <span
              tw="relative"
              css={[
                steps.length - 1 !== stepIndex &&
                  tw`before:content-[''] before:absolute before:left-1/2 before:transform before:-translate-x-1/2 before:translate-y-full before:w-[2px] before:h-5 before:bottom-0`,
                getColor(),
                disabled && isWelcomeStep && tw`opacity-30`,
              ]}
            >
              <CheckIcon
                tw="rounded-full w-5 h-5"
                css={[
                  disabled
                    ? tw`text-neutral-100 bg-neutral-100`
                    : isWelcomeStep
                      ? tw`text-vivid-green-900 bg-vivid-green-400`
                      : tw`text-white bg-green-500`,
                ]}
              />
            </span>
            <p css={[getColor(), disabled && isWelcomeStep && tw`text-white opacity-50 `]}>{step.label}</p>
          </Button>
        </div>
      );
    });
};

export const OnboardingStepWrapper = ({ children }: { children: ReactNode }) => {
  const { title, steps, currentStepIndex, handleStepChange } = useOutletContext<OnboardingOutletContext>() ?? {};
  const progress = currentStepIndex + 1 + '/' + steps?.length;
  const [numerator, denominator] = progress.split('/').map(Number);
  const progressPercentage = (numerator / denominator) * 100;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    isOpen && (document.body.style.overflow = 'hidden');
    !isOpen && (document.body.style.overflow = 'unset');
  }, [isOpen]);

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: ({ side }) => ({
      opacity: 0,
      transform: {
        top: 'translateY(10px)',
        bottom: 'translateY(-10px)',
        left: 'translateX(10px)',
        right: 'translateX(-10px)',
      }[side],
    }),
    common: ({ side }) => ({
      backdropFilter: 'blur(1px)',
      WebkitBackdropFilter: 'blur(1px)',
      marginTop: '64px',
      background: 'white 0.48',
      transformOrigin: {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
      }[side],
    }),
  });

  return (
    <div tw="bg-white relative h-full flex flex-grow flex-col gap-10 md:gap-14 lg:gap-16 p-6 md:p-12 lg:p-16 lg:py-32 rounded-t-lg md:rounded-t-none">
      <div tw="flex flex-row md:pointer-events-none" onClick={() => setIsOpen(!isOpen)} ref={refs.setReference}>
        {!isOpen ? (
          <h3
            tw="col-span-4 font-semibold text-vivid-green-500 text-xl lg:text-3xl select-none"
            onClick={(e) => e.preventDefault()}
          >
            {title}
          </h3>
        ) : (
          <div tw="md:hidden absolute">
            {isMounted && (
              <FloatingPortal>
                <FloatingOverlay style={styles}>
                  <FloatingFocusManager context={context} modal={false}>
                    <div tw="bg-white grid gap-4 col-span-4 p-6" ref={refs.setFloating}>
                      {stepsMenu({ steps, handleStepChange, currentStepIndex })}
                    </div>
                  </FloatingFocusManager>
                </FloatingOverlay>
              </FloatingPortal>
            )}
          </div>
        )}
        <div tw="col-span-2 ml-auto md:hidden">
          <div tw="flex flex-row items-center">
            <div tw="w-10 h-10">
              <CircularProgressbar
                value={progressPercentage}
                text={progress}
                styles={{
                  text: {
                    fill: '#3C393B',
                    fontSize: '36px',
                    fontWeight: 600,
                    lineHeight: '145%',
                  },
                  path: {
                    stroke: '#439565',
                    strokeLinecap: 'butt',
                  },
                }}
              />
            </div>
            <ChevronIcon
              width={16}
              height={16}
              css={[tw`ml-2 transition-transform transform text-neutral-700`, isOpen && tw`rotate-180 `]}
            />
          </div>
        </div>
      </div>
      <div tw="max-w-xl">{children}</div>
    </div>
  );
};

export default OnboardingWizard;
