import { formatDate, PortfolioAsset, PortfolioAssetOwner } from '@grunfin/ui-kit';
import { PaginationState } from '@tanstack/react-table';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { api } from '~/api';
import { IMember } from './components/Table/MemberTable';
import { AddEmployee, Company, CompanyFee, Journal, Transaction, TransactionPayment } from './types';
import { RelatedPersonInvite } from '~/modules/account/types';

type Pageable = {
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
};

interface MembershipResponse extends Pageable {
  content: IMember[];
}

interface PaymentResponse extends Pageable {
  content: Transaction[];
}

interface FeesResponse extends Pageable {
  content: CompanyFee[];
}

interface JournalResponse extends Pageable {
  content: Journal[];
}

type MembershipOptions = {
  benefitId: string;
  page: number;
  size: number;
  sort: string[];
};

export const getBenefitMemberships = (options: MembershipOptions) =>
  api
    .get(`company/benefits/${options.benefitId}/members?page=${options.page}&size=${options.size}&sort=${options.sort}`)
    .json<MembershipResponse>();

export const useGetCustomerCompanyMemberships = (options: MembershipOptions) =>
  useQuery<MembershipResponse, Error>(['memberships', options], async () => getBenefitMemberships(options));

export const getCustomerCompanyMembersByEmployerSideIds = async (options: {
  companyBenefitId: string;
  employerSideIds: string[];
  page: number;
  size: number;
  sort: string[];
}) => {
  let url = `company/benefits/${options.companyBenefitId}/employee?page=${options.page}&size=${options.size}&sort=${options.sort}`;
  if (options.employerSideIds.length > 0)
    url += `&employerSideIds=${options.employerSideIds.map((id) => id).join(',')}`;

  return await api.get(url).json<MembershipResponse>();
};

export const useRemoveMember = (benefitId: string) =>
  useMutation(async (membershipId: string) =>
    api.delete(`company/benefits/${benefitId}/members/${membershipId}`, { timeout: 180000 }),
  );

type PageOptions = {
  page: number;
  size: number;
  sort?: string[];
};

type BenefitOptions = PageOptions & {
  companyBenefitId?: string;
};

type CompanyOptions = PageOptions & {
  companyId: string;
};

export type CompanyPaymentsResponse = {
  amount: number;
  entityIds: string;
  paymentReference: string;
  beneficiaryName: string;
  accountNumber: string;
};

export const useGetCompanyJournals = (options: CompanyOptions) =>
  useQuery<JournalResponse, Error>(
    [options.companyId, 'journal', options.page, options.size, options.sort],
    async () =>
      await api
        .get(`company/${options.companyId}/journal?page=${options.page}&size=${options.size}&sort=${options.sort}`)
        .json<JournalResponse>(),
  );
export const useGetCompany = (companyId?: string) =>
  useQuery<Company, Error>(['company', companyId], async () => await api.get(`company/${companyId}`).json<Company>(), {
    enabled: !!companyId,
  });

export const useRemoveJournal = (companyId: string) =>
  useMutation(async (journalId: string) =>
    api.delete(`company/${companyId}/journal/${journalId}`, { timeout: 180000 }),
  );

export const getCompanyPayments = async (options: BenefitOptions) =>
  await api
    .get(
      `company/benefits/${options.companyBenefitId}/payment?page=${options.page}&size=${options.size}&sort=${options.sort}`,
    )
    .json<PaymentResponse>();

export const useGetCompanyPayments = (pagination: PaginationState, options: BenefitOptions) =>
  useQuery<PaymentResponse, Error>(['payments', pagination, options], async () => getCompanyPayments(options), {
    enabled: !!options.companyBenefitId,
    keepPreviousData: true,
  });

export const getCompanyFees = async (options: BenefitOptions) =>
  await api
    .get(
      `company/benefits/${options.companyBenefitId}/fees?page=${options.page}&size=${options.size}&sort=${options.sort}`,
    )
    .json<FeesResponse>();

export const useGetCompanyFees = (pagination: PaginationState, options: BenefitOptions) =>
  useQuery<FeesResponse, Error>(['payments', pagination, options], async () => getCompanyFees(options), {
    enabled: !!options.companyBenefitId,
    keepPreviousData: true,
  });

export const useSubmitCompanyMembers = (benefitId?: string) =>
  useMutation(async (data: AddEmployee[]) => api.post(`company/benefits/${benefitId}/members`, { json: data }));

export const useSubmitCompanyPayments = (companyBenefitId: string) =>
  useMutation(async (data: TransactionPayment[]) =>
    api.post(`company/benefits/${companyBenefitId}/payment`, { json: data }).json<Journal>(),
  );

type CompanyStatisticsResponse = {
  pendingMembershipCount?: number;
  membershipCount?: number;
  fundedPortfolioCount?: number;
  totalPayments?: number;
  employerPayments?: number;
  employeePayments?: number;
  totalValue?: number;
  employerValue?: number;
  customerValue?: number;
  firstTimeInvestorCount?: number;
};

export const useGetCompanyStatistics = (companyBenefitId: string) =>
  useQuery<CompanyStatisticsResponse, Error>(
    ['company-statistics', companyBenefitId],
    async () => await api.get(`company/benefits/${companyBenefitId}/statistics`).json<CompanyStatisticsResponse>(),
  );

type CompanyAssetsResponse = {
  uninvestedFundsSum: number;
  instrumentValues: PortfolioAsset[];
};

type CompanyInstrumentTransaction = {
  name: string;
  isin: string;
  assetOwner: string;
  units: number;
  price: number;
  totalValue: number;
  transactionDate: string;
  type: string;
};

type CompanyAssetsOptions = {
  companyBenefitId: string;
  date: string | Date;
  assetOwners?: PortfolioAssetOwner[];
};

type CompanyInstrumentTransactionsOptions = {
  companyBenefitId: string;
  from: string | Date;
  to: string | Date;
};

export const getCompanyAssets = async (options: CompanyAssetsOptions) => {
  const searchParams = new URLSearchParams({
    ...(options as any),
    date: formatDate(options.date, 'YYYY-MM-DD'),
  });
  return await api
    .get(`company/benefits/${options.companyBenefitId}/assets`, { searchParams })
    .json<CompanyAssetsResponse>();
};

export const getCompanyInstrumentTransactions = async (options: CompanyInstrumentTransactionsOptions) => {
  const searchParams = new URLSearchParams({
    ...(options as any),
    from: formatDate(options.from, 'YYYY-MM-DD'),
    to: formatDate(options.to, 'YYYY-MM-DD'),
  });
  return await api
    .get(`company/benefits/${options.companyBenefitId}/instrumentTransactions`, { searchParams })
    .json<CompanyInstrumentTransaction[]>();
};

export const useGetCompanyAssets = (options: CompanyAssetsOptions) =>
  useQuery<CompanyAssetsResponse, Error>(['assets', options], async () => await getCompanyAssets(options));

export const useGetCompanyRelatedPersonInfo = (
  relatedPersonId: string,
  options?: UseQueryOptions<RelatedPersonInvite, Error>,
) =>
  useQuery<RelatedPersonInvite, Error>(
    ['company-related-person', relatedPersonId],
    async () => await api.get(`company/relatedPerson/${relatedPersonId}`).json<RelatedPersonInvite>(),
    options,
  );
