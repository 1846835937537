import {
  Checkbox,
  CheckboxGroup,
  InfoTooltip,
  Message,
  MessageLevel,
  Radio,
  RadioGroup,
  Trans,
  useTranslation,
} from '@grunfin/ui-kit';
import React from 'react';
import {
  InvestmentExperience,
  InvestmentTerm,
  PastPerformance,
  PreferredRiskReturnRatio,
} from '~/modules/onboarding/types';
import { EmployeeQuestionnaire } from './EmployeeOnboardingQuestionnaire';
import 'twin.macro';

type Props = {
  values: EmployeeQuestionnaire;
  handleTargetValueUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValues: React.Dispatch<React.SetStateAction<EmployeeQuestionnaire>>;
};

export interface EmployeeOnboardingQuestion {
  title: string;
  description?: string;
  warningMessage?: string | React.ReactNode;
  component?: React.ReactNode;
}

const useQuestions = (props: Props): EmployeeOnboardingQuestion[] => {
  const { values, handleTargetValueUpdate, setValues } = props;
  const { t, i18n } = useTranslation('onboarding');

  const showMinimumInvestmentPeriodWarning = values.investmentTerm === InvestmentTerm.SHORT;
  const showInvestmentRelatedRisksWarning =
    values.experienceNoInvestmentExperience &&
    [PastPerformance.ShowsFuturePerformance, PastPerformance.NotSure].includes(
      values.pastPerformance as PastPerformance,
    );
  const showWouldWithdrawOnValueDropWarning = Boolean(values.wouldWithdrawOnValueDrop);
  const showHighRiskNotRecommendedWarning =
    showWouldWithdrawOnValueDropWarning && values.preferredRisk !== PreferredRiskReturnRatio.Low;
  const showMoreDebtThanAssetsWarning = values.moreDebtThanAssets === true;

  return [
    {
      title: t('questions.portfolio.investment_term.title'),
      warningMessage: showMinimumInvestmentPeriodWarning && (
        <Message level={MessageLevel.WARN}>
          <Trans i18nKey="onboarding:questions.portfolio.childAge.end.suggestion" />
        </Message>
      ),
      component: (
        <RadioGroup
          name="investmentTerm"
          selectedValue={values.investmentTerm ?? undefined}
          onChange={handleTargetValueUpdate}
        >
          <Radio value={InvestmentTerm.SHORT} label={t('questions.portfolio.investment_term.options.short')} />
          <Radio value={InvestmentTerm.MEDIUM} label={t('questions.portfolio.investment_term.options.medium')} />
          <Radio value={InvestmentTerm.LONG} label={t('questions.portfolio.investment_term.options.long')} />
        </RadioGroup>
      ),
    },
    {
      title: t('questions.mifid.experience.title'),
      component: (
        <CheckboxGroup
          value={[
            ...(values.experienceFunds ? [InvestmentExperience.FUNDS] : []),
            ...(values.experienceShares ? [InvestmentExperience.SHARES] : []),
            ...(values.experienceNoInvestmentExperience ? [InvestmentExperience.NO_EXPERIENCE] : []),
          ]}
          onChange={(value) => {
            // we have to do this kind of mapping because checkbox names are not 1:1 to object keys in 'values'
            const fields = ['experienceFunds', 'experienceShares', 'experienceNoInvestmentExperience'];
            const result = [] as string[];
            if (value.includes(InvestmentExperience.FUNDS)) result.push('experienceFunds');
            if (value.includes(InvestmentExperience.SHARES)) result.push('experienceShares');
            if (value.includes(InvestmentExperience.NO_EXPERIENCE)) result.push('experienceNoInvestmentExperience');

            const resultObj = fields
              .map((field) => ({ [field]: result.includes(field) }))
              .reduce((a, b) => ({ ...a, ...b }));
            setValues((prevState) => ({ ...prevState, ...resultObj }));
          }}
        >
          <Checkbox name={InvestmentExperience.FUNDS} label={t('questions.mifid.experience.funds')} />
          <div tw="my-4" />
          <Checkbox name={InvestmentExperience.SHARES} label={t('questions.mifid.experience.shares')} />
          <div tw="my-4" />
          <Checkbox name={InvestmentExperience.NO_EXPERIENCE} label={t('questions.mifid.experience.none')} />
        </CheckboxGroup>
      ),
    },
    {
      title: t('questions.mifid.pastPerformance.title'),
      warningMessage: showInvestmentRelatedRisksWarning && (
        <Message level={MessageLevel.WARN}>
          <Trans
            i18nKey="questions.mifid.warning.faq"
            t={t}
            components={[
              <a
                key="0"
                href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5497-what-are-the-risks-related-to-my-portfolio`}
                tw="text-alps-blue-600 underline"
                target="_blank"
                rel="noopener"
              />,
            ]}
          />
        </Message>
      ),
      component: (
        <RadioGroup
          name="pastPerformance"
          selectedValue={values.pastPerformance ?? undefined}
          onChange={handleTargetValueUpdate}
        >
          <Radio
            value={PastPerformance.ShowsFuturePerformance}
            label={t('questions.mifid.pastPerformance.affirmative')}
          />
          <Radio value={PastPerformance.NotGuarantee} label={t('questions.mifid.pastPerformance.negative')} />
          <Radio value={PastPerformance.NotSure} label={t('questions.mifid.pastPerformance.unsure')} />
        </RadioGroup>
      ),
    },
    {
      title: t('questions.mifid.wouldWithdrawOnValueDrop.title'),
      component: (
        <RadioGroup
          name="wouldWithdrawOnValueDrop"
          selectedValue={String(values.wouldWithdrawOnValueDrop)}
          onChange={handleTargetValueUpdate}
        >
          <Radio value="true" label={t('questions.mifid.wouldWithdrawOnValueDrop.affirmative')} />
          <Radio value="false" label={t('questions.mifid.wouldWithdrawOnValueDrop.negative', "No, I wouldn't")} />
        </RadioGroup>
      ),
      warningMessage: showWouldWithdrawOnValueDropWarning && (
        <Message level={MessageLevel.WARN}>
          <p>
            <Trans i18nKey="warning.would_withdraw_company" t={t} />
          </p>
        </Message>
      ),
    },
    {
      title: t('questions.mifid.moreDebtThanAssets.title'),
      description: t('questions.mifid.moreDebtThanAssets.subtitle'),
      warningMessage: showMoreDebtThanAssetsWarning && (
        <Message level={MessageLevel.WARN}>
          {showMoreDebtThanAssetsWarning && <p>{t('warning.lack_ability_to_bear_loss_benefit_program')}</p>}
        </Message>
      ),
      component: (
        <RadioGroup
          name="moreDebtThanAssets"
          selectedValue={String(values.moreDebtThanAssets)}
          onChange={handleTargetValueUpdate}
        >
          <Radio value="false" label={t('questions.mifid.moreDebtThanAssets.more_assets')} />
          <Radio value="true" label={t('questions.mifid.moreDebtThanAssets.more_debt')} />
        </RadioGroup>
      ),
    },
    {
      title: t('questions.portfolio.preferredRisk.title'),
      description: t('questions.portfolio.preferredRisk.disclaimer'),
      warningMessage: showHighRiskNotRecommendedWarning && (
        <Message level={MessageLevel.WARN}>
          <p>{t('questions.portfolio.preferredRisk.risk_averse')}</p>
          <p>
            <Trans
              i18nKey="questions.portfolio.preferredRisk.faq_link"
              t={t}
              components={[
                <a
                  key="0"
                  href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5497-what-are-the-risks-related-to-my-portfolio`}
                  tw="text-alps-blue-600 underline"
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
            />
          </p>
        </Message>
      ),
      component: (
        <RadioGroup
          name="preferredRisk"
          selectedValue={values.preferredRisk ?? undefined}
          onChange={handleTargetValueUpdate}
        >
          <Radio
            value={PreferredRiskReturnRatio.Low}
            label={
              <div tw="flex flex-row items-center gap-3">
                {t('questions.portfolio.preferredRisk.tolerance.low')}
                <InfoTooltip>
                  <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.low_description" t={t} />
                </InfoTooltip>
              </div>
            }
          />
          <Radio
            value={PreferredRiskReturnRatio.Medium}
            label={
              <div tw="flex flex-row items-center gap-3">
                {t('questions.portfolio.preferredRisk.tolerance.medium')}
                <InfoTooltip>
                  <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.medium_description" t={t} />
                </InfoTooltip>
              </div>
            }
          />
          <Radio
            value={PreferredRiskReturnRatio.High}
            label={
              <div tw="flex flex-row items-center gap-3">
                {t('questions.portfolio.preferredRisk.tolerance.high')}
                <InfoTooltip>
                  <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.high_description" t={t} />
                </InfoTooltip>
              </div>
            }
          />
          <Radio
            value={PreferredRiskReturnRatio.Very_High}
            label={
              <div tw="flex flex-row items-center gap-3">
                {t('questions.portfolio.preferredRisk.tolerance.very_high')}
                <InfoTooltip>
                  <Trans i18nKey="questions.portfolio.preferredRisk.tolerance.very_high_description" t={t} />
                </InfoTooltip>
              </div>
            }
          />
        </RadioGroup>
      ),
    },
  ];
};

export default useQuestions;
