var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { twMerge } from 'tailwind-merge';
export var Select = React.forwardRef(function (_a, ref) {
    var error = _a.error, placeholder = _a.placeholder, children = _a.children, value = _a.value, className = _a.className, props = __rest(_a, ["error", "placeholder", "children", "value", "className"]);
    var inputClass = twMerge("w-full relative items-center inline-flex border-2 border-gray-200 rounded placeholder-gray-300 focus-within:border-alps-blue-200 bg-white", error && 'border-dawn-200 focus-within:border-dawn-400', className);
    var svgClass = twMerge("absolute pointer-events-none right-3 rounded-full bg-gray-100 text-gray-700", props.disabled && 'text-white');
    return (React.createElement("div", { className: inputClass },
        React.createElement("select", __assign({ className: "rounded outline-none py-2 px-3 w-full appearance-none bg-white cursor-pointer disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-50", ref: ref, value: value === null ? '' : value }, props),
            typeof placeholder === 'string' && React.createElement("option", { value: "" }, placeholder),
            children),
        React.createElement("svg", { width: "20", height: "20", fill: "none", className: svgClass },
            React.createElement("path", { d: "M4.7 8.43l5.3 5.3 5.3-5.3", className: "stroke-current stroke-2", strokeLinecap: "round", strokeLinejoin: "round" }))));
});
Select.displayName = 'Select';
