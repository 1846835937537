var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgRocketIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M9 32.9987C6 35.5187 5 42.9987 5 42.9987C5 42.9987 12.48 41.9987 15 38.9987C16.42 37.3187 16.4 34.7387 14.82 33.1787C14.0426 32.4367 13.0186 32.008 11.9445 31.9747C10.8703 31.9415 9.82175 32.3062 9 32.9987V32.9987Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24 30.0005L18 24.0005C19.0643 21.2394 20.4044 18.5926 22 16.1005C24.3304 12.3745 27.5752 9.30659 31.426 7.18868C35.2768 5.07076 39.6053 3.97324 44 4.0005C44 9.4405 42.44 19.0005 32 26.0005C29.4738 27.5979 26.7937 28.938 24 30.0005V30.0005Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M18 23.9991H8C8 23.9991 9.1 17.9391 12 15.9991C15.24 13.8391 22 15.9991 22 15.9991", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24 30V40C24 40 30.06 38.9 32 36C34.16 32.76 32 26 32 26", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }))); };
var ForwardRef = forwardRef(SvgRocketIcon);
var Memo = memo(ForwardRef);
export default Memo;
