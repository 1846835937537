import { BankButtons, formatCurrency, Tabs, useTranslation } from '@grunfin/ui-kit';
import React, { ReactNode } from 'react';
import 'twin.macro';

import { ContactSupportOverlay } from '../../support/ContactSupportOverlay';
import { CopyToClipboard } from '../components/CopyToClipboard';

import { useGetPortfolioStandingOrderInstruction } from '../queries';
import { StandingOrderInstruction } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from '~/modules/auth/SessionProvider';

enum AmountType {
  UPFRONT = 'upfront',
  MONTHLY = 'monthly',
}

interface InstructionProps {
  amountType: AmountType;
  amount: number;
  data: StandingOrderInstruction;
  portfolioId: string;
}

const InstructionsByType = ({ amount, amountType, data, portfolioId }: InstructionProps) => {
  const session = useSession();
  const { t, i18n } = useTranslation('portfolio');
  return (
    <div tw="flex flex-col gap-4">
      <div>
        <Field label={t('sum', { ns: 'general' })}>
          {formatCurrency(amount, { maxDigits: 2 })}{' '}
          {amountType === AmountType.MONTHLY && !!data.monthlyFee && data.monthlyFee > 0 && (
            <div tw="text-gray-400 inline">
              {t('payment.amount_plus_fee', {
                amount: formatCurrency(data.monthlyInvestmentAmount, { maxDigits: 2 }),
                fee: formatCurrency(data.monthlyFee, { digits: 2 }),
                max: formatCurrency(1000),
              })}
            </div>
          )}
          <CopyToClipboard gray value={amount.toString()} />
        </Field>
        <Field label={t('payment.account_number')}>
          {data.accountNumber}
          <CopyToClipboard gray value={data.accountNumber} />
        </Field>
        <Field label={t('payment.beneficiary_name')}>
          {data.beneficiaryName}
          <CopyToClipboard gray value={data.beneficiaryName} />
        </Field>
        <Field label={t('payment.reference_number')}>
          {data.referenceNumber}
          <CopyToClipboard gray value={data.referenceNumber} />
        </Field>
      </div>

      {amountType === AmountType.UPFRONT && i18n.language !== 'de' && (
        <BankButtons
          referenceNumber={data.referenceNumber}
          paymentCode={'Portfolio: ' + data.referenceNumber + ', id: ' + uuidv4()}
          email={session?.email || ''}
          amount={data.upfrontInvestmentAmount}
          language={i18n.language}
          redirectUrl={`${window.location.origin}/portfolio/${portfolioId}/payments/top-up?success=true`}
          cancelUrl={`${window.location.href}`}
          white={true}
        />
      )}
      <p tw="text-neutral-500 text-sm">{t('payment.settles')}</p>
    </div>
  );
};

export const PaymentInstructions = ({ portfolioId }: { portfolioId: string }) => {
  const { t } = useTranslation('portfolio');
  const payment = useGetPortfolioStandingOrderInstruction(portfolioId);

  if (payment.isError) {
    return <ContactSupportOverlay title={t('payment.error')} onClose={payment.refetch} error={payment.error} />;
  }

  const { data } = payment;
  if (!data) return null;

  const showToggle = !!data.upfrontInvestmentAmount;

  const options = [
    {
      value: AmountType.UPFRONT,
      label: t('payment.upfront_contribution'),
      component: (
        <InstructionsByType
          amountType={AmountType.UPFRONT}
          amount={data.upfrontInvestmentAmount}
          data={payment.data}
          portfolioId={portfolioId}
        />
      ),
    },
    {
      value: AmountType.MONTHLY,
      label: t('payment.monthly_payment'),
      component: (
        <InstructionsByType
          amountType={AmountType.MONTHLY}
          amount={data.monthlyInvestmentAmount}
          data={payment.data}
          portfolioId={portfolioId}
        />
      ),
    },
  ];

  return <div tw="mt-10 md:mt-12 md:ml-14">{showToggle && <Tabs options={options} />}</div>;
};

const Field = ({ label, children }: { label: string; children: ReactNode }) => (
  <div tw="inline-grid md:grid-cols-12 gap-x-3 gap-y-2 w-full items-center mb-2">
    <div tw="text-gray-400 md:col-span-3">{label}</div>
    <div tw="font-semibold md:col-span-7">{children}</div>
  </div>
);
