var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgLinkedinIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M20.4498 20.4496H16.8937V14.8805C16.8937 13.5525 16.87 11.8429 15.0441 11.8429C13.1919 11.8429 12.9085 13.2899 12.9085 14.7838V20.4492H9.35234V8.99689H12.7663V10.562H12.8141C13.1557 9.97782 13.6494 9.49726 14.2426 9.17149C14.8357 8.84572 15.5062 8.68693 16.1824 8.71203C19.7868 8.71203 20.4513 11.0828 20.4513 14.167L20.4498 20.4496ZM5.33981 7.43144C4.93165 7.43151 4.53262 7.31055 4.19321 7.08385C3.8538 6.85715 3.58925 6.53489 3.43299 6.15783C3.27672 5.78077 3.23577 5.36584 3.31533 4.96551C3.39488 4.56517 3.59137 4.19743 3.87993 3.90876C4.16849 3.6201 4.53617 3.42348 4.93647 3.34378C5.33678 3.26408 5.75171 3.30488 6.12883 3.46101C6.50595 3.61713 6.8283 3.88158 7.05512 4.22091C7.28195 4.56025 7.40306 4.95922 7.40313 5.36738C7.40318 5.63839 7.34984 5.90675 7.24617 6.15715C7.14251 6.40754 6.99055 6.63507 6.79896 6.82674C6.60736 7.0184 6.37987 7.17045 6.12951 7.27421C5.87915 7.37796 5.61081 7.43139 5.33981 7.43144ZM7.11789 20.4496H3.55802V8.99689H7.11789V20.4496ZM22.2227 0.00163516L1.77105 0.00163516C1.30685 -0.00360329 0.859547 0.175663 0.527458 0.500042C0.195369 0.824421 0.00566525 1.26737 0 1.73156L0 22.2681C0.00547135 22.7325 0.195063 23.1758 0.527141 23.5005C0.859219 23.8252 1.30662 24.0048 1.77105 23.9999H22.2227C22.6881 24.0057 23.1367 23.8266 23.4701 23.5019C23.8035 23.1772 23.9944 22.7334 24.0008 22.2681V1.73008C23.9942 1.26497 23.8032 0.821515 23.4698 0.497144C23.1364 0.172773 22.6878 -0.00598143 22.2227 0.000152822", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgLinkedinIcon);
var Memo = memo(ForwardRef);
export default Memo;
