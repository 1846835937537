import { Button, ButtonSize, ButtonVariant, Field, Input, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Consents } from '~/modules/portfolio/components/Consents';
import { StripeModal } from '~/modules/portfolio/components/StripeModal';
import { StripeTermsCheckbox } from '~/modules/portfolio/components/StripeTermsCheckbox';
import { useCurrentConsent, usePortfolio } from '~/modules/portfolio/hooks';
import { useDirectDebitTopUp } from '~/modules/portfolio/queries';
import { ConditonalWrapper, setBackendValidationErrors } from '~/utils';

interface Values {
  amount?: number;
  consentId?: string | 'new';
  agreeToTerms?: boolean;
}

interface Props {
  onSuccess: () => void;
}

export const DirectDebit = (props: Props) => {
  const { t } = useTranslation('portfolio');
  const [isModalOpen, setModalOpen] = useState(false);
  const currentConsent = useCurrentConsent();
  const navigate = useNavigate();
  const portfolio = usePortfolio();
  const directDebitTopUp = useDirectDebitTopUp(portfolio.id);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm<Values>({ defaultValues: { consentId: currentConsent?.id } });
  const agreeToTerms = watch('agreeToTerms');
  const amount = watch('amount');
  const consentId = watch('consentId');

  const isValid = amount && consentId && agreeToTerms;
  const isSubmitting = directDebitTopUp.isLoading;
  const required = t('required', { ns: 'general' }) as string;

  const handleCloseModal = () => {
    setModalOpen(false);

    // Workaround for modal buggie
    if (consentId === 'new') {
      setValue('consentId', currentConsent?.id);
      setTimeout(() => {
        setValue('consentId', 'new');
      }, 1);
    }
  };

  const onSubmit = async (data: Values) => {
    if (data.consentId === 'new') setModalOpen(true);
    else await topUp();
  };

  const topUp = async () => {
    if (!consentId || !amount) throw new Error('Invalid form state');
    const currentUrl = window.location.href;
    try {
      const { url } = await directDebitTopUp.mutateAsync({
        consentId: consentId === 'new' ? undefined : consentId,
        amount,
        successUrl: `${currentUrl}?success=true`,
        cancelUrl: currentUrl,
      });

      if (url) window.location.href = url;
      else props.onSuccess();
    } catch (err) {
      if (err instanceof Error) setBackendValidationErrors(err, setError);
      handleCloseModal();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label={t('sum', { ns: 'general' })} error={errors.amount?.message}>
          <div tw="w-36">
            <Input
              {...register('amount', {
                required,
              })}
              error={'amount' in errors}
              disabled={isSubmitting}
              name="amount"
              type="number"
              inputMode="numeric"
              suffix="EUR"
              pattern="[0-9]*"
              placeholder="0"
              required
            />
          </div>
        </Field>
        <Field label={t('details.payment.top_up.iban.label')}>
          <Consents onChange={(consentId) => setValue('consentId', consentId)} selectedValue={consentId} />
        </Field>
        <StripeTermsCheckbox value={agreeToTerms} onChange={(value) => setValue('agreeToTerms', value)} />
        <div tw="flex flex-row flex-wrap items-center space-y-2 md:space-y-0 justify-between">
          <div tw="w-max">
            <Button
              type="button"
              onClick={() => navigate(-1)}
              disabled={isSubmitting}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.LARGE}
            >
              {t('back', { ns: 'general' })}
            </Button>
          </div>
          <div tw="relative w-max">
            <ConditonalWrapper
              condition={consentId === 'new'}
              wrap={(wrappedChildren) => (
                <StripeModal open={isModalOpen} onClose={() => setModalOpen(false)} onSubmit={topUp}>
                  {wrappedChildren}
                </StripeModal>
              )}
            >
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
              >
                {isSubmitting && (
                  <div tw="absolute flex items-center justify-center w-full">
                    <Spinner />
                  </div>
                )}
                <span css={[isSubmitting && tw`invisible`]}>{t('details.payment.top_up.start')}</span>
              </Button>
            </ConditonalWrapper>
          </div>
        </div>
      </form>
    </>
  );
};
