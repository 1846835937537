import {
  Button,
  ButtonVariant,
  formatCurrency,
  formatDate,
  PortfolioTransactionType,
  Spinner,
  useTranslation,
} from '@grunfin/ui-kit';
import { ColumnDef, getCoreRowModel, useReactTable, Table as TableType, flexRender, Row } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import tw from 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { Heading } from '../../components/Heading';
import { usePortfolio } from '../../hooks';
import { useGetPortfolioTransactions } from '../../queries';
import { PortfolioTransaction } from '../../types';

export const PaymentHistory = () => {
  const { t } = useTranslation('portfolio');
  const portfolio = usePortfolio();
  const payments = useGetPortfolioTransactions(portfolio.id);

  if (payments.isIdle || payments.isLoading) {
    return (
      <div tw="flex flex-1 items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (payments.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={payments.refetch} error={payments.error} />;
  }

  return (
    <div tw="flex-1 md:mr-14">
      <Heading>{t('details.payment.title')}</Heading>
      <div tw="px-6 pb-12">
        <PaymentsTable payments={payments.data} />
      </div>
    </div>
  );
};

const PaymentsTable = ({ payments }: { payments: PortfolioTransaction[] }) => {
  const { t, i18n } = useTranslation('portfolio');
  const [preview, setPreview] = useState(true);

  const columns = useMemo<ColumnDef<PortfolioTransaction>[]>(
    () => [
      {
        header: '',
        accessorFn: (row) => row.amount,
        id: 'amount',
        cell: (info) => {
          const isFeeType = info.row.original.type === PortfolioTransactionType.FEE;

          return (
            <p tw="font-mono font-bold text-gray-900 sm:text-right whitespace-nowrap">
              {isFeeType && '-'}
              {formatCurrency(info.getValue() as number, { digits: 2 })}
            </p>
          );
        },
      },
      {
        header: '',
        accessorFn: (row) => row.description,
        id: 'description',
        cell: (info) => <p tw="text-base text-gray-900">{info.getValue() as string}</p>,
      },
      {
        header: '',
        accessorFn: (row) => row.date,
        id: 'date',
        cell: (info) => (
          <p tw="text-gray-400 text-base sm:text-right whitespace-nowrap">
            {formatDate(new Date(info.getValue() as string), 'DD MMM YYYY')}
          </p>
        ),
      },
    ],
    [i18n.language],
  );

  const previewCount = 8;
  const hasMore = preview && payments.length > previewCount;
  const count = preview ? previewCount : payments.length;

  const rows = useMemo(() => payments.slice(0, count - 1), [payments, count]);
  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!payments.length) {
    return <p>{t('details.payment.list.placeholder')}</p>;
  }

  return (
    <div tw="flex flex-col gap-4 w-full max-w-md">
      <TableBase table={table} />
      {hasMore && (
        <Button variant={ButtonVariant.TRANSPARENT} onClick={() => setPreview(false)} className="max-w-max">
          {t('details.payment.list.view_more')}
        </Button>
      )}
    </div>
  );
};
type TableRow = any;
type TableProps<T> = {
  table: TableType<T>;
};

const TableBase = ({ table }: TableProps<TableRow>) => {
  return (
    <div tw="w-full overflow-auto [&::-webkit-scrollbar]:hidden">
      <table tw="w-full border-collapse text-left" style={{ borderSpacing: 0 }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (header.isPlaceholder) return null;
                if (!header.column.columnDef.header) return null;
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody tw="flex flex-col gap-4 sm:gap-0 sm:block">
          {table.getRowModel().rows.map((row: Row<any>) => {
            return (
              <tr key={row.id} tw="flex flex-row gap-1 sm:gap-0 flex-wrap flex-1 sm:table-row">
                {row.getVisibleCells().map((cell) => {
                  if (cell.getValue() == null) return null;
                  const isAmount = cell.column.id === 'amount';
                  return (
                    <td key={cell.id} css={[tw`sm:py-1 sm:p-2 sm:w-full`, isAmount && tw`w-full sm:w-auto`]}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
