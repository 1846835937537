var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { twMerge } from 'tailwind-merge';
import CheckIcon from '../icons/CheckIcon';
var cycleOptions = function (event, idx, total) {
    var _a, _b;
    if (!['ArrowDown', 'ArrowUp'].includes(event.key))
        return;
    event.preventDefault();
    var newIdx = idx + (event.key === 'ArrowDown' ? 1 : -1);
    if (newIdx < 0)
        newIdx = total - 1;
    if (newIdx > total - 1)
        newIdx = 0;
    (_b = (_a = event.target.parentElement.parentElement.children[newIdx]) === null || _a === void 0 ? void 0 : _a.querySelector('input')) === null || _b === void 0 ? void 0 : _b.focus();
};
/**
 * Grouping helper for checkboxes
 */
export var CheckboxGroup = function (_a) {
    var children = _a.children, onChange = _a.onChange, disabled = _a.disabled, _b = _a.value, value = _b === void 0 ? [] : _b;
    var total = React.Children.count(children);
    return (React.createElement("div", { role: "group" }, React.Children.map(children, function (child, idx) {
        return React.cloneElement(child, __assign(__assign({}, child.props), { onChange: function (checked) {
                return onChange(checked ? value.concat([child.props.name || '']) : value.filter(function (v) { return v !== child.props.name; }));
            }, onKeyDown: function (e) { return cycleOptions(e, idx, total); }, disabled: disabled || child.props.disabled, value: value.includes(child.props.name || '') }));
    })));
};
/**
 * Styled <input type="checkbox"> component
 */
export var Checkbox = React.forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, icon = _a.icon, _b = _a.value, value = _b === void 0 ? false : _b, disabled = _a.disabled, onChange = _a.onChange, onKeyDown = _a.onKeyDown, label = _a.label;
    var labelClass = twMerge("relative flex gap-4 cursor-pointer items-start", disabled && 'cursor-not-allowed');
    var iconClass = twMerge("absolute -mt-1 pointer-events-none w-8 h-8 text-gray-700", disabled && 'text-white');
    return (React.createElement("label", { id: id, className: labelClass },
        React.createElement("input", { type: "checkbox", ref: ref, name: name, className: "-mt-1 appearance-none flex-shrink-0 outline-none cursor-pointer disabled:cursor-not-allowed rounded border-2 border-gray-200 w-8 h-8 disabled:bg-gray-200 focus:border-alps-blue-200 bg-white", checked: value || false, disabled: disabled, onChange: function (e) { return onChange && onChange(e.target.checked); }, onKeyDown: onKeyDown }),
        value && React.createElement(CheckIcon, { className: iconClass }),
        React.createElement("div", { className: "flex flex-row items-center gap-4" },
            label && React.createElement("div", { className: "select-none font-normal text-base" }, label),
            icon != null && icon)));
});
Checkbox.displayName = 'Checkbox';
