export var getAllCookies = function () {
    return typeof document !== 'undefined'
        ? document.cookie
            .split(';')
            .reduce(function (ac, str) {
            var _a;
            return Object.assign(ac, (_a = {}, _a[str.split('=')[0].trim()] = str.split('=')[1], _a));
        }, {})
        : {};
};
var getLocaleFromCookie = function () {
    // the formatting is be based on the browser locale
    // we will use our NEXT_LOCALE to determine this instead
    var cookies = getAllCookies();
    var cookielang = 'NEXT_LOCALE' in cookies ? cookies.NEXT_LOCALE : undefined;
    return cookielang;
};
export var formatCurrency = function (value, options, lang) {
    var _a, _b, _c, _d;
    var cookielang = getLocaleFromCookie();
    var formatter = new Intl.NumberFormat(cookielang !== null && cookielang !== void 0 ? cookielang : lang, {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: (_a = options === null || options === void 0 ? void 0 : options.display) !== null && _a !== void 0 ? _a : 'symbol',
        minimumFractionDigits: (_b = options === null || options === void 0 ? void 0 : options.digits) !== null && _b !== void 0 ? _b : 0,
        maximumFractionDigits: (_d = (_c = options === null || options === void 0 ? void 0 : options.maxDigits) !== null && _c !== void 0 ? _c : options === null || options === void 0 ? void 0 : options.digits) !== null && _d !== void 0 ? _d : 0,
    });
    return formatter.format(value);
};
var percentFormatter = new Intl.NumberFormat(getLocaleFromCookie(), { style: 'percent', maximumFractionDigits: 2 });
export var formatPercent = percentFormatter.format;
var numberFormatter = new Intl.NumberFormat(getLocaleFromCookie(), { minimumFractionDigits: 0 });
export var formatNumber = numberFormatter.format;
