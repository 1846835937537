import { ArrowIcon } from '@grunfin/ui-kit';
import React from 'react';
import tw from 'twin.macro';

type Step = {
  index: number;
  title: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
};

type StepHeaderProps = {
  currentStep: number;
  onCurrentStepChange: (step: number) => void;
  steps: Step[];
};

const StepHeader = ({ currentStep, steps, onCurrentStepChange }: StepHeaderProps) => (
  <div tw="flex flex-row items-center overflow-x-auto py-4 px-6 -ml-6 -mr-4 [&::-webkit-scrollbar]:hidden">
    {steps
      .map((step, index) => {
        const isCurrentStep = step.index === currentStep;
        const isDisabled = step.index > currentStep;
        return (
          <button
            key={index}
            type="button"
            disabled={isDisabled}
            onClick={() => onCurrentStepChange(step.index)}
            tw="flex flex-row items-center gap-4 disabled:cursor-not-allowed cursor-pointer"
          >
            <span>{isCurrentStep ? step.activeIcon : step.icon}</span>
            <h4
              css={[
                tw`text-base font-medium text-gray-400 md:text-lg whitespace-nowrap`,
                isCurrentStep && tw`text-sky-blue-700`,
              ]}
            >
              {step.title}
            </h4>
          </button>
        );
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce((prev, curr) => [
        prev,
        <ArrowIcon
          key="arrow"
          width={24}
          height={24}
          style={{ minWidth: 24, minHeight: 24 }} // fix for safari
          tw="text-gray-500 bg-white rounded mx-4 md:mx-8 min-w-min"
        />,
        curr,
      ])}
  </div>
);

export default StepHeader;
