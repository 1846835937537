var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgCalculatorIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 49, height: 48, viewBox: "0 0 49 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M36.25 4H12.25C10.0409 4 8.25 5.79086 8.25 8V40C8.25 42.2091 10.0409 44 12.25 44H36.25C38.4591 44 40.25 42.2091 40.25 40V8C40.25 5.79086 38.4591 4 36.25 4Z", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M16.25 12H32.25", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M32.25 28V36", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M32.25 20H32.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24.25 20H24.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M16.25 20H16.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24.25 28H24.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M16.25 28H16.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M24.25 36H24.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }),
    React.createElement("path", { d: "M16.25 36H16.27", stroke: "#094B58", strokeWidth: 2.4, strokeLinecap: "round", strokeLinejoin: "round" }))); };
var ForwardRef = forwardRef(SvgCalculatorIcon);
var Memo = memo(ForwardRef);
export default Memo;
