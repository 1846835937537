var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgPareLogo = function (props, ref) { return (React.createElement("svg", __assign({ width: 69, height: 24, viewBox: "0 0 69 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.1126 0.0551758C13.5298 0.0551758 16.3513 1.76798 17.9761 4.37398V15.1172C16.3513 17.7236 13.5298 19.4364 10.1126 19.4364C7.94028 19.4364 6.10485 18.6536 4.56969 17.4236H4.49492V24H0V0.427976H4.49492V2.10478H4.56969C6.10485 0.837576 7.94028 0.0551758 10.1126 0.0551758ZM28.1215 0.0551758C30.2938 0.0551758 32.1292 0.837576 33.6644 2.10478H33.7391V0.427976H38.2341V19.0636H33.7391V17.4236H33.6644C32.1292 18.6536 30.2938 19.4364 28.1215 19.4364C24.7212 19.4364 21.9085 17.7416 20.2801 15.1568V4.33438C21.9085 1.74998 24.7212 0.0551758 28.1215 0.0551758ZM57.226 0.283656C62.1454 -1.02795 66.7826 2.36606 68.0915 7.22646C68.3435 8.16247 68.5714 9.29767 68.5714 9.29767L55.8002 12.7029C56.7352 14.3061 58.9091 15.4629 61.0794 14.8837C62.217 14.5805 63.1359 14.1113 63.8405 13.5309C64.1529 13.2737 64.6212 13.4089 64.7261 13.7989L65.7017 17.4213C64.6988 18.1077 63.5342 18.6669 62.1944 19.0245C56.8397 20.4521 51.9886 17.2693 50.5929 12.0849C49.2937 7.26086 51.4729 1.81766 57.226 0.283656ZM45.3083 2.36602C46.0093 1.38322 47.7607 -0.138785 51.0963 0.112416C51.5602 0.147216 51.758 0.716417 51.4268 1.04122C50.4077 2.04122 49.5652 3.24322 48.9514 4.59122C46.3044 4.99242 45.2335 7.41682 45.2335 9.22403V19.0636H40.739V0.428016H45.2335V2.36602H45.3083ZM28.4953 4.34118C25.4246 4.34118 23.2523 6.61478 23.2523 9.74559C23.2523 12.8764 25.4246 15.15 28.4953 15.15C31.5669 15.15 33.7018 12.8764 33.7018 9.74559C33.7018 6.61478 31.5669 4.34118 28.4953 4.34118ZM9.73874 4.34118C6.66722 4.34118 4.53231 6.61478 4.53231 9.74559C4.53231 12.8764 6.66722 15.15 9.73874 15.15C12.8094 15.15 14.9817 12.8764 14.9817 9.74559C14.9817 6.61478 12.8094 4.34118 9.73874 4.34118ZM62.8235 6.81726C62.0831 4.93086 60.0841 3.84326 57.9134 4.42206C55.7789 4.99126 54.6465 6.83686 54.7558 8.96807L62.8235 6.81726Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgPareLogo);
var Memo = memo(ForwardRef);
export default Memo;
