var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgStarIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M11.1115 6.22075C11.4841 5.49916 12.5159 5.49916 12.8885 6.22075L14.3118 8.97697C14.4607 9.26542 14.7404 9.46362 15.0619 9.50854L18.1694 9.94273C19.0033 10.0592 19.3287 11.0912 18.7125 11.665L16.5242 13.7025C16.2754 13.9341 16.1613 14.2763 16.2213 14.611L16.7454 17.5318C16.8909 18.3428 16.0481 18.972 15.3119 18.6019L12.4492 17.1627C12.1666 17.0206 11.8334 17.0206 11.5508 17.1627L8.68807 18.6019C7.95188 18.972 7.10912 18.3428 7.25463 17.5318L7.77868 14.611C7.83872 14.2763 7.72465 13.9341 7.47584 13.7025L5.28752 11.665C4.67128 11.0912 4.99668 10.0592 5.83058 9.94273L8.93809 9.50854C9.2596 9.46362 9.5393 9.26542 9.68825 8.97697L11.1115 6.22075Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgStarIcon);
var Memo = memo(ForwardRef);
export default Memo;
