import { Route, Routes } from 'react-router-dom';

import { NotFoundView } from '~/modules/application/NotFoundView';

import { AccountDocumentsView } from './DocumentsView';
import { AccountGeneralView } from './GeneralView';
import { AccountLayout } from './Layout';
import { AccountPersonalDetailsView } from './PersonalDetailsView';
import { ReferralsView } from './ReferralsView';
import { RiskProfileView } from './RiskProfileView';

export function AccountRoutes() {
  return (
    <AccountLayout>
      <Routes>
        <Route path="" element={<AccountGeneralView />} />
        <Route path="details" element={<AccountPersonalDetailsView />} />
        <Route path="risk-profile" element={<RiskProfileView />} />
        <Route path="documents" element={<AccountDocumentsView />} />
        <Route path="referrals" element={<ReferralsView />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </AccountLayout>
  );
}
