import { useTranslation } from '@grunfin/ui-kit';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { trackAppInstall, trackAppInstalled, trackNavigation, trackPWAInstallPrompt } from '~/utils/tracking-analytics';
import { captureException } from '@sentry/react';

export const Application = ({ children }: { children: any }) => {
  const { t } = useTranslation('application');
  const { pathname } = useLocation();

  useEffect(() => {
    // Set up a listener to track Add to Homescreen events.
    window.addEventListener('beforeinstallprompt', (e) => {
      try {
        trackPWAInstallPrompt();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e?.userChoice?.then((choiceResult) => {
          trackAppInstall(choiceResult);
        });
      } catch (ex) {
        captureException(ex);
      }
    });

    window.addEventListener('appinstalled', () => {
      trackAppInstalled();
    });
  }, []);

  useEffect(() => {
    if (document.title.startsWith(t('loading', { ns: 'general' }))) {
      document.title = t('title.short');
    }
  }, [t]);

  useEffect(() => {
    trackNavigation(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
