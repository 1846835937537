import { formatCurrency, Trans, useTranslation } from '@grunfin/ui-kit';
import 'twin.macro';
import { useGetReferralProgram } from '~/modules/account/queries';
import { useSession } from '~/modules/auth/SessionProvider';
import { ReferralLink } from './components/ReferralLink';

export function ReferralsView() {
  const { t, i18n } = useTranslation('account');
  const session = useSession();
  const referral = useGetReferralProgram();
  const program = t('referrals.bonus.' + `${session.referralProgram}`, { amount: formatCurrency(20) });

  return referral.data ? (
    <>
      <div tw="my-8 md:my-14 mx-8 md:mx-14">
        <div tw="text-alps-blue-700 text-lg md:text-xl mb-8 md:mb-12 font-bold md:font-medium text-center">
          {t('referrals.heading')}
        </div>
        <p tw="text-base md:text-lg text-gray-900">{program}</p>
        <p tw="mt-6 text-sm text-gray-400">
          <Trans
            i18nKey="account:referrals.terms"
            components={[
              <a
                key="0"
                tw="underline"
                href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5500-grunfin-s-referral-terms`}
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        </p>
      </div>

      <div tw="bg-gray-50">
        <div tw="px-8 md:px-14 py-8">
          <div tw="text-base md:text-lg text-gray-900">{t('referrals.link')}</div>
          <ReferralLink />
        </div>
      </div>

      <div tw="flex sm:flex-row my-8 md:my-12 mx-6 md:mx-14 text-base md:text-lg">
        <div tw="text-alps-blue-700  flex flex-row items-center">
          <div tw="bg-alps-blue-100 rounded-full h-16 w-16 flex items-center justify-center font-bold md:font-normal">
            {referral.data?.completedReferrals}
          </div>
          <div tw="ml-4">{t('referrals.friends_joined')}</div>
        </div>
        <div tw="ml-6 xl:ml-12 flex flex-row items-center">
          <div tw="bg-forest-green-100 text-forest-green-700 rounded-full h-16 w-16 flex items-center justify-center font-bold md:font-normal">
            {referral.data?.amountEarned !== undefined &&
              typeof referral.data?.amountEarned === 'number' &&
              formatCurrency(referral.data?.amountEarned)}
          </div>
          <div tw="ml-4 text-alps-blue-700">{t('referrals.amount_earned')}</div>
        </div>
      </div>
    </>
  ) : (
    <div tw="my-8 md:my-14 mx-8 md:mx-14">
      <div tw="text-alps-blue-700 text-lg md:text-xl mb-8 md:mb-12 font-bold md:font-medium text-center">
        {t('referrals.heading')}
      </div>
      <p tw="text-base md:text-lg text-gray-900">{t('referrals.unavailable')}</p>
      <p tw="mt-6 text-sm text-gray-400">
        <Trans
          i18nKey="account:referrals.terms"
          components={[
            <a
              key="0"
              tw="underline"
              href={`https://faq.grunfin.com/${i18n.resolvedLanguage}/articles/5500-grunfin-s-referral-terms`}
              target="_blank"
              rel="noreferrer"
            />,
          ]}
        />
      </p>
    </div>
  );
}
