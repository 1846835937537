import 'twin.macro';
import React, { useEffect } from 'react';
import { BeneficiaryType, Button, ButtonVariant, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useOutletContext, useParams } from 'react-router-dom';
import { PersonalDetailsForm } from '~/modules/account/PersonalDetailsForm';
import { useGetCustomer, useSubmitCustomer } from '~/modules/account/queries';
import { useGetPortfolio, useGetPortfolioChild, useSubmitPortfolioChild } from '~/modules/portfolio/queries';
import { PortfolioChild } from '~/modules/portfolio/types';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { setBackendValidationErrors } from '~/utils';
import { trackBeginCheckout } from '~/utils/tracking-analytics';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';

const PersonalDetailsStep = () => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { onContinue, portfolioId, onBack } = context ?? {};
  const { t } = useTranslation('account');
  const getCustomer = useGetCustomer();
  const submitCustomer = useSubmitCustomer();
  const submitPortfolioChild = useSubmitPortfolioChild();
  const { id: paramPortfolioId } = useParams();

  const finalPortfolioId = portfolioId ?? paramPortfolioId;
  const getPortfolio = useGetPortfolio(finalPortfolioId as string, { enabled: Boolean(finalPortfolioId) });
  const hasChildFields = getPortfolio?.data?.beneficiaryType === BeneficiaryType.CHILD;
  const getPortfolioChild = useGetPortfolioChild(finalPortfolioId as string, hasChildFields);

  const { isSuccess } = getCustomer;
  useEffect(() => {
    if (isSuccess) trackBeginCheckout();
  }, [isSuccess]);

  if (
    getCustomer.isLoading ||
    getCustomer.isIdle ||
    getPortfolio.isLoading ||
    (hasChildFields && getPortfolioChild.isLoading)
  ) {
    return (
      <OnboardingStepWrapper>
        <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
          <Spinner />
        </div>
      </OnboardingStepWrapper>
    );
  }

  if (getCustomer.isError) {
    return (
      <OnboardingStepWrapper>
        <ContactSupportOverlay title={t('details.error')} onClose={getCustomer.refetch} error={getCustomer.error} />
      </OnboardingStepWrapper>
    );
  }

  if (!getCustomer.data) return null;

  return (
    <OnboardingStepWrapper>
      <div tw="max-w-xl">
        <PersonalDetailsForm
          defaultValues={{ ...getCustomer.data, child: getPortfolioChild.data }}
          type={getPortfolio.data?.beneficiaryType}
          onSubmit={async ({ child, ...values }, setError) => {
            try {
              await submitCustomer.mutateAsync(values);
              // refetch the new customer data to refill the default values
              await getCustomer.refetch();
            } catch (err) {
              if (err instanceof Error) setBackendValidationErrors(err, setError);
              throw err;
            }

            if (hasChildFields) {
              try {
                await submitPortfolioChild.mutateAsync({ id: finalPortfolioId, ...child } as PortfolioChild);
                // refetch the new child data to refill the default values
                await getPortfolioChild.refetch();
              } catch (err) {
                if (err instanceof Error) setBackendValidationErrors(err, setError, 'child');
                throw err;
              }
            }

            if (typeof onContinue !== 'undefined') onContinue();
          }}
          renderFooter={() => (
            <div tw="flex flex-row w-full">
              <Button variant={ButtonVariant.NEW_SECONDARY} onClick={() => onBack()} tw="max-w-max">
                {t('back', { ns: 'general' })}
              </Button>
              <Button
                type="submit"
                tw="ml-auto max-w-max"
                variant={ButtonVariant.NEW_PRIMARY}
                data-test-id="details-continue"
              >
                {t('continue', { ns: 'general' })}
              </Button>
            </div>
          )}
        />
      </div>
    </OnboardingStepWrapper>
  );
};

export default PersonalDetailsStep;
