var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Dialog from './Dialog';
export var AlertDialog = function (props) {
    var title = props.title, description = props.description, actions = props.actions, content = props.content, children = props.children;
    return (React.createElement(Dialog, __assign({}, props, { render: function (_a) {
            var close = _a.close, labelId = _a.labelId, descriptionId = _a.descriptionId;
            return (React.createElement("div", { className: "p-6 md:p-8" },
                React.createElement("h2", { id: labelId, className: "text-lg font-bold text-gray-900 max-w-lg" }, title),
                description && (React.createElement("p", { id: descriptionId, className: "mb-8 text-base text-gray-900 mt-1 max-w-sm" }, description)),
                content,
                React.createElement("div", { className: "flex gap-y-2 justify-center md:justify-end mt-11" }, actions(close))));
        } }), children));
};
export var AlertNotice = function (props) {
    return (React.createElement("div", { className: "flex flex-col flex-wrap p-4 gap-4 bg-gray-50 rounded-lg" },
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4" },
            props.icon,
            React.createElement("h3", { className: "text-lg font-medium text-gray-900" }, props.title)),
        React.createElement("div", { className: "flex flex-col gap-4" },
            props.description && React.createElement("div", { className: "text-base text-gray-500" }, props.description),
            props.children)));
};
