var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { twMerge } from 'tailwind-merge';
import React from 'react';
export var Input = React.forwardRef(function (props, ref) {
    var _a = props.as, Component = _a === void 0 ? 'input' : _a, error = props.error, size = props.size, suffix = props.suffix, button = props.button, className = props.className, rest = __rest(props, ["as", "error", "size", "suffix", "button", "className"]);
    var wrapperClass = twMerge("w-full relative inline-flex border-2 border-gray-200 rounded placeholder-gray-300 focus-within:border-alps-blue-200 bg-white appear", error && 'border-dawn-200 focus-within:border-dawn-200', className);
    var inputClass = twMerge("[&::-webkit-inner-spin-button]:appearance-none m-0 [&[type=\"number\"]]:appearance-[textfield] autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)]", 'rounded outline-none py-2 px-3 min-w-0 w-full bg-white disabled:text-gray-400 disabled:bg-gray-50 disabled:cursor-not-allowed', suffix != null && 'pr-14');
    return (React.createElement("div", { className: wrapperClass },
        React.createElement(Component, __assign({ ref: ref, size: size, className: inputClass }, rest)),
        suffix && (React.createElement("div", { className: "absolute top-1/2 -translate-y-1/2 right-2 flex items-center text-gray-400 whitespace-nowrap cursor-text text-base select-none" }, suffix)),
        button));
});
Input.displayName = 'Input';
