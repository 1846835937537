import { Button, ButtonSize, ButtonVariant, Input, Spinner, useTranslation } from '@grunfin/ui-kit';
import { Auth0Error } from 'auth0-js';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import 'twin.macro';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { trackLoginInitiated } from '~/utils/tracking-analytics';

import { AppleButton } from './AppleButton';
import { AuthContainer } from './AuthContainer';
import { GoogleButton } from './GoogleButton';
import { useAppleLogin, useGoogleLogin, useNext } from './hooks';
import { useLogin } from './queries';
import { useSession } from './SessionProvider';
import { AuthMethod } from './types';
import { setBackendValidationErrors } from '~/utils';

export const LoginView = () => {
  const session = useSession();
  const next = useNext();

  if (session.authenticated) {
    return <Navigate to={next} replace />;
  }

  return (
    <div tw="grid grid-cols-12 min-w-full">
      <div tw="sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 col-span-full max-w-md flex justify-center items-center">
        <AuthContainer>
          <LoginForm />
        </AuthContainer>
      </div>
    </div>
  );
};

interface LoginForm {
  email: string;
  password: string;
}

type LoginProps = {
  presetEmail?: string | undefined;
  presetAuthMethods?: AuthMethod[] | undefined;
  nextUrl?: string;
  callback?: () => void;
};

type State = 'idle' | 'submitting' | 'error';

export const LoginForm = ({ presetEmail, presetAuthMethods, nextUrl, callback }: LoginProps) => {
  const { t } = useTranslation('auth');
  const [state, setState] = useState<State>('idle');
  const { register, handleSubmit, formState, setError } = useForm<LoginForm>({
    ...(presetEmail != null && { defaultValues: { email: presetEmail } }),
  });
  const googleLogin = useGoogleLogin({ nextUrl });
  const appleLogin = useAppleLogin({ nextUrl });
  const login = useLogin();
  const formDisabled = formState.isSubmitting || state === 'submitting';

  const handleLogin = async ({ email, password }: LoginForm) => {
    try {
      trackLoginInitiated('Email');

      try {
        await login.mutateAsync({ username: email, password });
      } catch (err) {
        if (err instanceof Error) setBackendValidationErrors(err, setError);
      }

      if (typeof callback !== 'undefined' && !formState.errors && Object.keys(formState.errors).length === 0) {
        callback();
      }
    } catch (error) {
      console.log(error);
      setState('error');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setState('submitting');
      trackLoginInitiated('Google');
      await googleLogin();
    } catch (error) {
      const cancelled = (error as Auth0Error).original === 'User closed the popup window';
      setState(cancelled ? 'idle' : 'error');
    }
  };

  const handleAppleLogin = async () => {
    try {
      setState('submitting');
      trackLoginInitiated('Apple');
      await appleLogin();
    } catch (error) {
      const cancelled = (error as Auth0Error).original === 'User closed the popup window';
      setState(cancelled ? 'idle' : 'error');
    }
  };

  if (state === 'error') {
    return <ContactSupportOverlay title={t('login.error')} onClose={() => setState('idle')} />;
  }

  let VALID_AUTH_METHODS = [AuthMethod.EMAIL, AuthMethod.GOOGLE, AuthMethod.APPLE];
  const isValidAuthMethod = (method: AuthMethod) => VALID_AUTH_METHODS.includes(method);

  if (presetAuthMethods != null && presetAuthMethods.length > 0) VALID_AUTH_METHODS = presetAuthMethods;

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <fieldset tw="flex gap-y-4 flex-col items-center w-full" disabled={formDisabled}>
        {isValidAuthMethod(AuthMethod.EMAIL) && (
          <Fragment>
            <div tw="w-full">
              <Input
                type="email"
                data-test-id="email"
                placeholder={t('login.email.placeholder')}
                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                error={'email' in formState.errors}
                disabled={presetEmail != null}
                {...(presetEmail && { autoComplete: 'off' })}
              />
              {formState.errors.email && <div tw="text-sm text-dawn-300 mt-2">{t('login.email.error')}</div>}
            </div>
            <div tw="w-full">
              <Input
                type="password"
                data-test-id="password"
                placeholder={t('login.password.placeholder')}
                {...register('password', { required: true, minLength: 8 })}
                error={'password' in formState.errors}
              />
              {formState.errors.password && <div tw="text-sm text-dawn-300 mt-2">{t('login.password.error')}</div>}
            </div>
            <Button type="submit" variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} data-test-id="login-submit">
              {formState.isSubmitting ? <Spinner /> : t('login.submit')}
            </Button>
          </Fragment>
        )}
        {isValidAuthMethod(AuthMethod.EMAIL) &&
          (isValidAuthMethod(AuthMethod.GOOGLE) || isValidAuthMethod(AuthMethod.APPLE)) && (
            <div tw="text-gray-400">{t('or')}</div>
          )}
        {isValidAuthMethod(AuthMethod.GOOGLE) && (
          <GoogleButton onClick={handleGoogleLogin}>{t('login.google')}</GoogleButton>
        )}
        {isValidAuthMethod(AuthMethod.APPLE) && (
          <AppleButton onClick={handleAppleLogin}>{t('login.apple')}</AppleButton>
        )}
        {isValidAuthMethod(AuthMethod.EMAIL) && (
          <Link to="/forgot-password" target="_blank" tw="text-alps-blue-400 hover:underline mt-6">
            {t('login.forgot_password')}
          </Link>
        )}
      </fieldset>
    </form>
  );
};
