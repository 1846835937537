import { AlertDialog, Button, ButtonSize, ButtonVariant, Spinner, useTranslation } from '@grunfin/ui-kit';
import { useState } from 'react';
import tw from 'twin.macro';

import stripe from '~/assets/output/stripe.svg';

interface Props {
  open: boolean;
  children: JSX.Element;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export const StripeModal = ({ open, children, onClose, ...props }: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslation('portfolio');

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      await props.onSubmit();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AlertDialog
      open={open}
      title={<img src={stripe} alt="Stripe" className="flex mb-4" />}
      description={t('details.payment.top_up.stripe.confirm')}
      actions={(closeFn: () => void) => (
        <div className="flex flex-col md:flex-row">
          <div>
            <Button
              onClick={() => {
                closeFn();
                onClose();
              }}
              disabled={isSubmitting}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.LARGE}
            >
              {t('cancel', { ns: 'general' })}
            </Button>
          </div>
          <div style={{ margin: '1rem' }} />
          <div tw="relative">
            <Button onClick={onSubmit} disabled={isSubmitting} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
              {isSubmitting && (
                <div tw="absolute flex items-center justify-center w-full">
                  <Spinner />
                </div>
              )}
              <span css={[isSubmitting && tw`invisible`]}> {t('details.payment.top_up.stripe.continue')}</span>
            </Button>
          </div>
        </div>
      )}
    >
      {children}
    </AlertDialog>
  );
};
