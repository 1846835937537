import { Spinner, Trans, useTranslation } from '@grunfin/ui-kit';
import { useOutletContext } from 'react-router-dom';
import 'twin.macro';
import { LoginForm } from '~/modules/auth/LoginView';
import { useSessionQuery } from '~/modules/auth/queries';
import { SignupForm } from '~/modules/auth/SignupForm';
import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { OnboardingOutletContext, OnboardingStepWrapper } from '..';
import {
  EmployeeOnboardingLink,
  useEmployeeOnboardingBaseRoute,
} from '~/modules/benefits/enroll/EmployeeOnboardingView';

const AuthenticationStep = ({ nextUrl }: { nextUrl?: string }) => {
  const context = useOutletContext<OnboardingOutletContext>();
  const { email, onContinue } = context ?? {};
  const { t } = useTranslation('auth');
  const session = useSessionQuery();
  const baseRoute = useEmployeeOnboardingBaseRoute();

  nextUrl = !nextUrl ? `${baseRoute}${EmployeeOnboardingLink.PERSONAL_DETAILS}` : nextUrl;

  if (session.isLoading || session.isIdle) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (session.isError) {
    return <ContactSupportOverlay title={t('details.error')} onClose={session.refetch} error={session.error} />;
  }

  // If the user is logged in and email is verified, continue to next step
  if (session.data.authenticated && session.data.emailVerified) {
    typeof onContinue !== 'undefined' && onContinue();
    return null;
  }

  if (session.data.authenticated && !session.data.emailVerified) {
    return (
      <OnboardingStepWrapper>
        <div tw="flex flex-col gap-4 max-w-md">
          <h3 tw="text-gray-900 text-lg">
            <Trans i18nKey="auth:email_verification.check_email" t={t} />
          </h3>
          <p tw="text-base text-gray-900">
            {t('email_verification.email_sent', { ns: 'auth', email: email ?? session.data.email })}
          </p>
        </div>
      </OnboardingStepWrapper>
    );
  }

  if (!session.data) return null;

  return (
    <OnboardingStepWrapper>
      <div tw="max-w-md">
        {context.authMethods != null && Array.isArray(context.authMethods) && context.authMethods?.length > 0 ? (
          <LoginForm
            presetEmail={email}
            presetAuthMethods={context.authMethods}
            nextUrl={nextUrl}
            callback={onContinue}
          />
        ) : (
          <SignupForm presetEmail={email} nextUrl={nextUrl} callback={onContinue} />
        )}
      </div>
    </OnboardingStepWrapper>
  );
};

export default AuthenticationStep;
