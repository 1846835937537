import { ClipboardIcon, toast } from '@grunfin/ui-kit';
import tw from 'twin.macro';

interface Props {
  value: string;
  gray?: boolean;
}

export const CopyToClipboard = ({ value, gray }: Props) => {
  return (
    <ClipboardIcon
      tw="inline-block w-6 h-6 rounded cursor-pointer ml-3 -mt-0.5 text-gray-700 hover:text-gray-800"
      css={gray ? tw`bg-gray-100` : tw`bg-white`}
      onClick={() => {
        navigator.clipboard.writeText(value);
        toast.success('Copied!');
      }}
    />
  );
};
