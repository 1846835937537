var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgShareIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M7 23C7 22.4477 7.44772 22 8 22L24 22C24.5523 22 25 22.4477 25 23C25 23.5523 24.5523 24 24 24H8C7.44772 24 7 23.5523 7 23Z", fill: "currentColor" }),
    React.createElement("path", { d: "M16 20C15.4477 20 15 19.5523 15 19V10.4142L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289L22.7071 13.2929C23.0976 13.6834 23.0976 14.3166 22.7071 14.7071C22.3166 15.0976 21.6834 15.0976 21.2929 14.7071L17 10.4142V19C17 19.5523 16.5523 20 16 20Z", fill: "currentColor" }))); };
var ForwardRef = forwardRef(SvgShareIcon);
var Memo = memo(ForwardRef);
export default Memo;
