var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { forwardRef, memo } from 'react';
var SvgAripaevIcon = function (props, ref) { return (React.createElement("svg", __assign({ width: 94, height: 28, viewBox: "0 0 94 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref: ref }, props),
    React.createElement("path", { d: "M4.40449 0H7.60674V3.31461H4.40449V0ZM11.6517 23.0787L10.8989 18.9551H5.96629L5.23595 23.0787H0L3.57303 6.80899C3.98876 4.93258 4.50562 4.67416 6.65168 4.67416H10.4607C12.6517 4.67416 13.0112 4.83146 13.4607 6.8427L17.0337 23.0899L11.6517 23.0787ZM8.96629 7.95506H7.89887L6.46067 15.6517H10.3933L8.96629 7.95506ZM9.22472 0H12.427V3.31461H9.22472V0ZM53.0899 4.73034H56.2921V8.04494H53.0899V4.73034ZM57.9101 4.73034H61.1123V8.04494H57.9101V4.73034ZM23.0449 12.6404V23.0674H18.2472V12.8427C18.2472 10.8315 19.191 9.5618 21.5618 9.5618H27.5281V12.6404H23.0449ZM29.0112 8.31461V4.92135H33.8315V8.31461H29.0112ZM33.8315 23.0787H29.0112V9.57303H33.8315V23.0787ZM45.4157 23.0787H40.3034V28H35.5618V9.57303H45.4719C47.8427 9.57303 48.7528 10.7978 48.7528 12.8876V19.764C48.764 21.9551 47.8314 23.0787 45.4157 23.0787ZM43.955 12.618H40.2809V20H43.955V12.618ZM61.2359 23.0787C61.2359 23.0787 57.9775 23.1348 57.3034 23.1348C56.6292 23.1348 53.3932 23.0787 53.3932 23.0787C51.3034 23.0562 50.1348 22.0562 50.1348 19.764V17.9663C50.1348 15.1461 51.9326 14.7079 53.4157 14.7079H58.5505V12.5169H51.2809V9.57303H60.0674C62.7753 9.57303 63.3483 11.2135 63.3483 12.8315V20.1798C63.3483 22.3708 62.7078 23.0449 61.2359 23.0787ZM58.6292 17.4157H54.7865V20.1573H58.6404V17.4157H58.6292ZM69.6629 17.3146V19.8202H77.2472V23.0787H68.1685C65.4606 23.0787 64.8876 21.2809 64.8876 19.7978V13.0225C64.8876 10.8315 65.955 9.60674 68.146 9.58427C68.146 9.58427 70.7303 9.52809 71.4045 9.52809C72.0786 9.52809 74.6067 9.58427 74.6067 9.58427C76.8988 9.60674 77.809 10.7303 77.809 12.8652V17.3258L69.6629 17.3146ZM73.0449 12.4045H69.6292V15.5393H73.0449V12.4045ZM89.4606 23.0787H83.1685L78.7415 9.57303H83.955L86.3033 18.8202H86.6966L89.0449 9.57303H93.8876L89.4606 23.0787Z", fill: "#252324" }))); };
var ForwardRef = forwardRef(SvgAripaevIcon);
var Memo = memo(ForwardRef);
export default Memo;
