import { useCallback, useEffect, useRef, useState } from 'react';
import { useMedia, useTranslation } from '@grunfin/ui-kit';

import { useDocumentTitle } from '~/utils';
import { useNavigationTitle } from '~/modules/application/Navigation';

import { useClaimQuestionnaire } from './queries';
import type { Answers } from './types';
import { deserializeMifidExperience, deserializePortfolioAreas, getQuestionnaireId } from './utils';
import { REFERRAL_CODE_KEY, useSession } from '~/modules/auth/SessionProvider';
import { useParams } from 'react-router-dom';

/**
 * Set up questionnaire dynamic title and progress bar
 */
export const useQuestionnaireTitle = (page: number, total: number) => {
  const pageNrToShow = page > total ? total : page;
  const isMobile = useMedia('(max-width: 768px)');
  const { t } = useTranslation('onboarding');
  const title = t('questionnaire.title');

  useDocumentTitle(title);
  useNavigationTitle(isMobile ? '' : page === 0 ? title : [title, `${pageNrToShow}/${total}`]);

  useEffect(() => {
    document.body.style.setProperty('--nav-progress', `${(page / total) * 100}%`);
    window.scrollTo(0, 0);

    return () => {
      document.body.style.removeProperty('--nav-progress');
    };
  }, [page]);
};

/**
 * State for the whole questionnaire
 */
export const useQuestionnaireFields = ({ mifid, portfolio }: Answers) =>
  useState({
    childAge: portfolio.childAge?.toString() || '',
    periodInYears: portfolio.periodInYears?.toString() || '15',
    portfolioType: portfolio.portfolioType,
    ageOfChildAtTheEndOfPortfolio: portfolio.ageOfChildAtTheEndOfPortfolio?.toString() || '18',
    investments: {
      monthlyInvestmentAmountEur: portfolio.monthlyInvestmentAmountEur?.toString() || '200',
      upfrontInvestmentAmountEur: portfolio.upfrontInvestmentAmountEur?.toString() || '1000',
    },
    areas: deserializePortfolioAreas(portfolio),
    experience: deserializeMifidExperience(mifid),
    pastPerformance: mifid.pastPerformance || '',
    wouldWithdrawOnValueDrop: extractBooleanString(mifid.wouldWithdrawOnValueDrop),
    savingsForThreeMonths: extractBooleanString(mifid.savingsForThreeMonths),
    moreDebtThanAssets: extractBooleanString(mifid.moreDebtThanAssets),
    preferredRisk: portfolio.preferredRisk || '',
    acceptedWarningMessage: '',
    signUp: false,
  });

const extractBooleanString = (value: boolean | null) => {
  return value == null ? '' : value ? 'true' : 'false';
};

/**
 * Focus first filled input or button in questionnaire form
 */
export const useFormInputFocus = (page: number) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const el: HTMLElement | null | undefined =
      formRef.current?.querySelector('input[checked]') ||
      formRef.current?.querySelector('input:not(:disabled),select') ||
      formRef.current?.querySelector('button');
    if (el) el.focus();
  }, [page]);

  return formRef;
};

export const useClaimQuestionnaires = () => {
  const { mutateAsync: claimQuestionnaire } = useClaimQuestionnaire();

  return useCallback(async () => {
    try {
      const [pqId, epqId, mqId] = [
        getQuestionnaireId('portfolio'),
        getQuestionnaireId('employee-portfolio'),
        getQuestionnaireId('mifid'),
      ];
      await Promise.allSettled([
        pqId && (await claimQuestionnaire({ id: pqId, type: 'portfolio' })),
        epqId && (await claimQuestionnaire({ id: epqId, type: 'employee-portfolio' })),
        mqId && (await claimQuestionnaire({ id: mqId, type: 'mifid' })),
      ]);
    } catch (error) {
      console.warn('Failed to claim questionnaire', error);
    }
  }, [claimQuestionnaire]);
};

export const useReferralApplied = () => {
  const session = useSession();
  const code = localStorage.getItem(REFERRAL_CODE_KEY);
  const [applied, setApplied] = useState(false);

  useEffect(() => setApplied(session.referralPending || !!code), [session.referralPending, code]);
  return applied;
};

export const useEnrollingMembershipId = () => {
  const session = useSession();
  const params = useParams();

  const { membershipId } = params;
  const { benefitMemberships } = session;
  const membership = benefitMemberships?.[0] ?? {};
  return membershipId ?? membership.membershipId;
};
