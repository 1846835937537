import { Route, Routes } from 'react-router-dom';

import { NotFoundView } from '~/modules/application/NotFoundView';
import { DetailsView } from './DetailsView';

export function PortfolioRoutes() {
  return (
    <Routes>
      <Route path=":id/*" element={<DetailsView />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
}
