import {
  BankIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  CalculatorIcon,
  CompassIcon,
  EurIcon,
  FAQ,
  FAQQuestion,
  formatCurrency,
  Reviews,
  RocketIcon,
  Trans,
  TreesIcon,
  TrustBlock,
  useMedia,
  useTranslation,
} from '@grunfin/ui-kit';
import { useNavigate } from 'react-router-dom';

import 'twin.macro';

import { UseQueryResult } from 'react-query';
import { ReferralProgramInfo } from '~/modules/referral/types';
import heroImage from '~/assets/output/campaign-hero.webp';
import climateImage from '~/assets/output/Climate-change.webp';
import equalityImage from '~/assets/output/Equality.webp';
import healthImage from '~/assets/output/Health.webp';
import mobileHeroImage from '~/assets/output/mobile-referral-hero.webp';

type Props = {
  referralInfo?: UseQueryResult<ReferralProgramInfo, Error>;
};

export const CampaignReferral = ({ referralInfo }: Props) => {
  const resolvedLanguage = useTranslation().i18n.resolvedLanguage;
  const { t } = useTranslation('referral');
  const navigate = useNavigate();
  const productShot = new URL(`../../assets/output/product-tilted-${resolvedLanguage}.webp`, import.meta.url).href;
  const referralData = referralInfo?.data;
  const disabled = referralInfo?.isError || !referralData?.canJoin;
  const isMobile = useMedia('(max-width: 768px)');
  const reward = referralData?.reward;
  const isCompanyReferral = referralData?.type === 'OU_GETS_3_MONTHS_FREE';
  const questions = (
    isCompanyReferral ? t('company.questions', { returnObjects: true }) : t('questions', { returnObjects: true })
  ) as FAQQuestion[];

  const getCTA = () => {
    if (disabled) return t('invalid_code');
    else if (isCompanyReferral) {
      return t('company.cta');
    } else if (reward) {
      return t('personal.cta', { reward: formatCurrency(parseInt(reward)) });
    }

    return t('create_portfolio');
  };

  const SetUp = () => (
    <div tw="mt-10 md:w-full max-w-md md:max-w-min whitespace-nowrap">
      <Button
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={() => navigate('/onboarding')}
        disabled={disabled}
        tw="text-alps-blue-100 rounded-2xl"
      >
        {getCTA()}
      </Button>
    </div>
  );

  const HeroContent = () => (
    <div tw=" text-center md:text-left pb-2 md:pb-0 mx-6 md:mx-0 mt-2 md:mt-0">
      <h1 tw="text-5xl font-light mb-10 text-alps-blue-600 md:text-white">
        {isCompanyReferral ? t('company.title') : t('campaign.title')}
      </h1>

      <h2 tw="text-alps-blue-700 md:text-white font-semibold">
        {isCompanyReferral
          ? t('company.description_1')
          : t('campaign.description_1', { reward: reward ? formatCurrency(parseInt(reward)) : '' })}
      </h2>
      <h2 tw="text-alps-blue-700 md:text-white font-semibold">
        {isCompanyReferral ? t('company.description_2') : t('campaign.description_2')}
      </h2>
    </div>
  );

  const getStatementBlock = (background: string, title: string, description: string, icon: JSX.Element) => (
    <div tw="grid md:grid-cols-6 md:gap-x-24 col-span-1 md:col-span-3 relative place-items-center">
      <div tw="absolute z-10 left-[35%] md:left-[7%] top-[15%] md:top-[35%] ">{icon}</div>
      <div
        tw="w-32 h-32 justify-center max-w-5xl blur-2xl relative md:min-w-[30%] col-span-1 md:col-span-2"
        style={{ background }}
      />
      <div tw="md:-ml-6 col-span-1 md:col-span-4 mt-12 md:mt-1 place-self-start">
        <h3 tw="text-center md:text-left text-lg text-alps-blue-600 font-semibold">{t(title)}</h3>
        <p tw="text-center md:text-left font-medium mt-3">{t(description)}</p>
      </div>
    </div>
  );

  const getBlockCTA = () => {
    if (isCompanyReferral) {
      return t('company.block_cta');
    } else if (reward) {
      return t('block_cta', { reward: formatCurrency(parseInt(reward)) });
    } else {
      return t('create_portfolio');
    }
  };

  return (
    <div tw="flex flex-col">
      <div tw="md:hidden pb-8">
        <HeroContent />
      </div>
      <div tw="flex justify-center md:justify-start mt-20 md:mt-0">
        <div
          tw="flex justify-center md:justify-start px-20 relative h-[320px] w-[320px] md:h-auto md:w-auto bg-center"
          style={{ backgroundImage: `url('${isMobile ? mobileHeroImage : heroImage}')`, borderRadius: '240px' }}
        >
          <img src={productShot} tw="max-h-80 -mt-20 md:-mt-28 absolute min-h-[140%]" alt={t('product_image')} />
          <div tw="ml-80 py-20 hidden md:block">
            <HeroContent />
            <div tw="hidden md:block">
              <SetUp />
            </div>
          </div>
        </div>
      </div>

      <div tw="md:hidden flex justify-center pt-16">
        <SetUp />
      </div>

      <div tw="mt-16 md:mt-32 md:mx-12">
        <TrustBlock reverse />
      </div>

      <div tw="grid grid-cols-2 md:grid-cols-6 gap-x-20 md:gap-x-24 gap-y-20 md:gap-y-14 mt-32 mx-8">
        {getStatementBlock(
          'linear-gradient(180deg, #CFE7CC 0%, #F7EBAB 100%)',
          'campaign_statements.title_one',
          'campaign_statements.description_one',
          <TreesIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #D3EFEF 0%, #CAC9C7 100%)',
          'campaign_statements.title_two',
          'campaign_statements.description_two',
          <CompassIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #F7DBDA 0%, #C7E8F0 100%)',
          'campaign_statements.title_three',
          'campaign_statements.description_three',
          <RocketIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #D3EFEF 0%, #F0D960 100%)',
          'campaign_statements.title_four',
          'campaign_statements.description_four',
          <CalculatorIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #C7E8F0 0%, #E7D9C5 100%)',
          'campaign_statements.title_five',
          'campaign_statements.description_five',
          <BankIcon width="48" height="48" />,
        )}
        {getStatementBlock(
          'linear-gradient(180deg, #F7DBDA 0%, #C7E8F0 100%)',
          'campaign_statements.title_six',
          'campaign_statements.description_six',
          <EurIcon width="48" height="48" />,
        )}
      </div>

      <h2 tw="text-4xl md:text-5xl text-alps-blue-600 text-center my-32 md:mt-40 md:mb-28 font-light mx-6">
        <Trans i18nKey="campaign.impact_title" components={[<span tw="text-alps-blue-300" key="0" />]} t={t} />
      </h2>

      <div tw="grid md:grid-cols-6 gap-x-24 mb-28 md:mb-40">
        <div tw="col-span-2">
          <div
            tw="flex justify-center px-20 text-center min-h-[300px] min-w-[200px]  bg-center bg-contain bg-no-repeat items-center"
            style={{ backgroundImage: `url('${climateImage}')` }}
          >
            <h2 tw="text-white text-lg font-bold">{t('focus.title_one')}</h2>
          </div>
          <p tw="text-center px-12">{t('focus.description_one')}</p>
        </div>
        <div tw="col-span-2">
          <div
            tw="flex justify-center px-20 text-center min-h-[300px] min-w-[200px]  bg-center bg-contain bg-no-repeat  items-center"
            style={{ backgroundImage: `url('${equalityImage}')` }}
          >
            <h2 tw="text-white text-lg font-bold">{t('focus.title_two')}</h2>
          </div>
          <p tw="text-center px-12">{t('focus.description_two')}</p>
        </div>
        <div tw="col-span-2">
          <div
            tw="flex justify-center px-20 text-center min-h-[300px] min-w-[200px]  bg-center bg-contain bg-no-repeat  items-center"
            style={{ backgroundImage: `url('${healthImage}')` }}
          >
            <h2 tw="text-white text-lg font-bold">{t('focus.title_three')}</h2>
          </div>
          <p tw="text-center px-12">{t('focus.description_three')}</p>
        </div>
      </div>

      <div tw="flex flex-col justify-center items-center px-4">
        <FAQ questions={questions} />
      </div>

      <div tw="flex flex-col justify-center items-center text-sm mt-10 mb-24">
        <Button
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          className="rounded-full"
          onClick={() => navigate('/onboarding')}
        >
          {getBlockCTA()}
        </Button>
        <p tw="mt-6 px-6 text-center">
          {isCompanyReferral
            ? t('company.cta_description')
            : t('campaign.cta_description', { reward: reward ? formatCurrency(parseInt(reward)) : '' })}
        </p>
      </div>

      <Reviews />

      <div tw="flex justify-center pt-8 md:mt-12 mb-14 px-6">
        <div tw="text-sm text-gray-400 text-center max-w-md md:max-w-2xl">
          <Trans
            i18nKey="review_terms"
            components={[
              <a key="0" tw="underline" href={t('general_terms_link')} target="_blank" rel="noreferrer" />,
              <a key="1" tw="underline" href={t('referral_terms_link')} target="_blank" rel="noreferrer" />,
            ]}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};
