var isDev = process.env.NODE_ENV === 'development';
var isProd = process.env.NODE_ENV === 'production';
// Reading 'location' must be in a function because it only available in a browser environment
var getBaseApi = function () {
    var isStaging = isProd && (location.hostname.includes('test.grunfin') || location.hostname.includes('test-app'));
    var BASE_API = isDev
        ? 'https://api.grunfin.dev:3010/api'
        : isStaging
            ? 'https://test-api.grunfin.com/api'
            : 'https://api.grunfin.com/api';
    return BASE_API;
};
export var getBanks = function () {
    return fetch(getBaseApi() + "/payments/montonio/banks", {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }).then(function (response) { return response.json(); });
};
export var makePayment = function (params) {
    return fetch(getBaseApi() + "/payments/montonio", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    })
        .then(function (response) { return response.text(); })
        .then(function (redirectUrl) {
        if (isJsonString(redirectUrl)) {
            throw new Error('Initiating montonio payment failed');
        }
        window.location.href = redirectUrl;
    });
};
function isJsonString(str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
}
