import 'twin.macro';
import { useEffect, useState } from 'react';
import { Spinner, useTranslation } from '@grunfin/ui-kit';

import { ContactSupportOverlay } from '~/modules/support/ContactSupportOverlay';
import { useNavigationContext } from '~/modules/application/Navigation';

import { useCreateQuestionnaire, useGetMifidQuestionnaire, useGetPortfolioQuestionnaire } from './queries';
import { Questionnaire } from './Questionnaire';
import { getQuestionnaireId } from './utils';

export function IndexView() {
  const { t } = useTranslation('onboarding');
  const navState = useNavigationContext();
  const mifidQuestionnaireId = getQuestionnaireId('mifid');
  const mifidQuestionnaire = useGetMifidQuestionnaire(mifidQuestionnaireId);
  const portfolioQuestionnaireId = getQuestionnaireId('portfolio');
  const portfolioQuestionnaire = useGetPortfolioQuestionnaire(portfolioQuestionnaireId);
  const { mutateAsync: createQuestionnaire, isLoading } = useCreateQuestionnaire();
  const [set, setter] = useState({ mifid: false, portfolio: false });

  // Create a MiFiD questionnaire when it does not exist
  const { isFetched: isMifidFetched, data: mifidData } = mifidQuestionnaire;
  useEffect(() => {
    if (((isMifidFetched && mifidData == null) || !mifidQuestionnaireId) && !set.mifid) {
      createQuestionnaire({ type: 'mifid' });
      setter((p) => ({ ...p, mifid: true }));
    }
  }, [createQuestionnaire, isMifidFetched, mifidData, set, mifidQuestionnaireId]);

  // Create a portfolio questionnaire when it does not exist
  const { isFetched: isPortfolioFetched, data: portfolioData } = portfolioQuestionnaire;
  useEffect(() => {
    if (((isPortfolioFetched && portfolioData == null) || !portfolioQuestionnaireId) && !set.portfolio) {
      createQuestionnaire({ type: 'portfolio' });
      setter((p) => ({ ...p, portfolio: true }));
    }
  }, [createQuestionnaire, isPortfolioFetched, portfolioData, set, portfolioQuestionnaireId]);

  useEffect(() => {
    return () => navState.setCustomActions(() => null);
  }, []);

  if (
    isLoading ||
    portfolioQuestionnaire.isIdle ||
    portfolioQuestionnaire.isLoading ||
    mifidQuestionnaire.isIdle ||
    mifidQuestionnaire.isLoading
  ) {
    return (
      <div tw="flex items-center justify-center" css={{ minHeight: 320 }}>
        <Spinner />
      </div>
    );
  }

  if (portfolioQuestionnaire.isError || mifidQuestionnaire.isError) {
    return (
      <ContactSupportOverlay
        title={t('questionnaire.error')}
        onClose={() => {
          portfolioQuestionnaire.refetch();
          mifidQuestionnaire.refetch();
        }}
        error={portfolioQuestionnaire.error || mifidQuestionnaire.error}
      />
    );
  }

  return <Questionnaire answers={{ mifid: mifidQuestionnaire.data, portfolio: portfolioQuestionnaire.data }} />;
}
